import {
    GameType,
    WorksheetType,
    FlashcardActivityType,
    InteractiveWorksheetType,
    ToolType,
    InteractiveWorksheet,
    Tool,
    FlashcardActivity,
    Worksheet,
    Quiz,
    Game,
} from "models";
import Activity from "models/activities/Activity";
import {
    CategoryType,
    Category,
    VocabularyCategoryItem,
    ExpressionCategoryItem,
    LessonCategoryItem,
    QuizType,
} from "models";
import KeyboardLayout from "components/common/KeyboardLayout";

class AlphabetDictionary {
    [key: string]: string;
}

const alphabetDictionary: AlphabetDictionary = createAlphabetDictionary();

function createAlphabetDictionary() {
    let alphabet = "abcdefghijklmnopqrstuvwxyz";
    let dictionary: AlphabetDictionary = {};
    for (var x = 0; x < alphabet.length; x++) {
        dictionary[alphabet.charAt(x)] = alphabet.charAt(x);
    }
    return dictionary;
}

const flashcardActivityTable = new Map<FlashcardActivityType, Array<boolean>>();
const interactiveWorksheetTable = new Map<InteractiveWorksheetType, Array<boolean>>();
const gameTable = new Map<GameType, Array<boolean>>();
const worksheetTable = new Map<WorksheetType, Array<boolean>>();
const quizTable = new Map<QuizType, Array<boolean>>();
const toolTable = new Map<ToolType, Array<boolean>>();

// FlashcardActivityType                                          vocabTextOnly     expression  multiLanguage   hasTeams    SWF     usesKeyboard,   studentListSnackbar
// prettier-ignore
flashcardActivityTable[FlashcardActivityType.Interactive]        = [true,            true,       true,           false,      false,  false,          true      ];
// prettier-ignore
flashcardActivityTable[FlashcardActivityType.Printable]          = [true,            true,       true,           false,      false,   false,          false      ];

// InteractiveWorksheetType                       vocabTextOnly     expression  multiLanguage   hasTeams    SWF     usesKeyboard,   studentListSnackbar
// prettier-ignore
interactiveWorksheetTable[InteractiveWorksheetType.MissingLetters]  = [true,            true,       false,          true,       false,  true,          true     ];
// prettier-ignore
interactiveWorksheetTable[InteractiveWorksheetType.Matching]        = [true,            true,       true,           true,       false,  true,          true     ];

// GameType                                       vocabTextOnly     expression  multiLanguage       hasTeams    SWF     usesKeyboard    studentListSnackbar     singlePlayer
// prettier-ignore
gameTable[GameType.AngryWords]                      = [true,            true,       true,           true,       false,   false,          true,                   false      ];
// prettier-ignore
gameTable[GameType.Bingo]                           = [true,            true,      true,           false,      false,    false,          true,                   false      ];
// prettier-ignore
gameTable[GameType.Battleship]                      = [true,            true,       true,           true,       false,   false,          true,                   true      ];
// prettier-ignore
gameTable[GameType.CardMatch]                       = [true,           false,      true,           true,       false,   false,          true,                   false      ];
// prettier-ignore
gameTable[GameType.CrazyCups]                       = [true,            true,       true,           true,       false,   false,          true,                   false      ];
// prettier-ignore
gameTable[GameType.Englishopoly]                    = [false,           false,      true,           true,       true,    false,          true,                   false      ];
// prettier-ignore
gameTable[GameType.Hangman]                         = [true,            true,       true,           true,       false,   true,           true,                   false      ];
// prettier-ignore
gameTable[GameType.HotOrCold]                       = [false,           true,       true,           true,       false,    false,          true,                   false      ];
// prettier-ignore
gameTable[GameType.HiddenPicture]                   = [false,           false,      true,           true,       false,   false,          true,                   false      ];
// prettier-ignore
gameTable[GameType.MemoryWheel]                     = [true,            true,      true,           true,       false,    false,          true,                   false      ];
// prettier-ignore
gameTable[GameType.SharkBait]                       = [false,           true,       true,           true,       false,    false,          true,                   false      ];
// prettier-ignore
gameTable[GameType.Speed]                           = [true,            true,       true,           true,       false,    false,          true,                   false      ];
// prettier-ignore
gameTable[GameType.TeamSpelling]                    = [true,            true,       true,           true,       false,    false,          true,                   false      ];
// prettier-ignore
gameTable[GameType.TicTacToe]                       = [true,            true,      true,            true,       false,   false,          true,                   false      ];
// prettier-ignore
gameTable[GameType.TheWheel]                        = [true,            true,       true,           true,       false,    false,          true,                   false      ];
// prettier-ignore
gameTable[GameType.Wordscramble]                    = [true,            false,      true,           true,       false,    false,          true,                   false      ];

// WorksheetType                                         vocabTextOnly     expression  multiLanguage   hasTeams    SWF     usesKeyboard,   studentListSnackbar
// prettier-ignore
worksheetTable[WorksheetType.Bingo]                      = [true,             true,      true,           false,      false,   false,          false      ];
// prettier-ignore
worksheetTable[WorksheetType.ConnectTheLetters]          = [false,           true,      false,          false,      false,   false,          false      ];
// prettier-ignore
worksheetTable[WorksheetType.Crossword]                  = [false,           false,      false,          false,      false,   false,          false      ];
// prettier-ignore
worksheetTable[WorksheetType.LaddersAndSlides]           = [true,            true,      true,           false,      false,   false,          false      ];
// prettier-ignore
worksheetTable[WorksheetType.Matching]                   = [false,           true,      true,           false,      false,   false,          false      ];
// prettier-ignore
worksheetTable[WorksheetType.MissingLetters]             = [false,           false,      true,           false,      false,   false,          false      ];
// prettier-ignore
worksheetTable[WorksheetType.QAndA]                      = [true,             true,      true,           false,      false,   false,          false      ];
// prettier-ignore
worksheetTable[WorksheetType.Racetrack]                  = [true,            true,      true,           false,      false,   false,          false      ];
// prettier-ignore
worksheetTable[WorksheetType.Reading]                    = [false,           false,      true,           false,      false,   false,          false      ];
// prettier-ignore
worksheetTable[WorksheetType.SentenceDecoder]            = [true,            true,       false,           false,      false,   false,          false      ];
// prettier-ignore
worksheetTable[WorksheetType.SentenceScramble]           = [true,            true,       true,            false,      false,   false,          false      ];
// prettier-ignore
worksheetTable[WorksheetType.TicTacToe]                  = [false,           false,      true,           false,      false,  false,          false      ];
// prettier-ignore
worksheetTable[WorksheetType.Tracing]                    = [false,           false,      true,           false,      false,   false,          false      ];
// prettier-ignore
worksheetTable[WorksheetType.YellowBrickRoad]            = [false,           false,      true,           false,      true,   false,          false      ];
// prettier-ignore
worksheetTable[WorksheetType.WordScramble]               = [true,            true,       true,            false,      false,   false,          false      ];
// prettier-ignore
worksheetTable[WorksheetType.Wordsearch]                 = [true,             true,      false,          false,      false,   false,          false      ];

// QuizType                                      vocabTextOnly     expression  multiLanguage   hasTeams    SWF      usesKeyboard    studentListSnackbar
// prettier-ignore
quizTable[QuizType.MultipleChoice]                  = [true,           true,      true,           false,      false,   false,          true     ];

// ToolType                                     vocabTextOnly     expression  multiLanguage   hasTeams      SWF     usesKeyboard    studentListSnackbar
// prettier-ignore
toolTable[ToolType.GroupMaker]                      = [false,           false,      true,           false,      false,   false,          false     ];
// prettier-ignore
toolTable[ToolType.DartboardSelector]               = [false,           false,      true,           false,      false,   false,          true      ];
// prettier-ignore
toolTable[ToolType.RandomNamePicker]                = [false,           false,      true,           false,      false,   false,          true      ];
// prettier-ignore
toolTable[ToolType.Scoreboard]                      = [false,           false,      true,           true,      false,   false,          false      ];
// prettier-ignore
toolTable[ToolType.Stopwatch]                       = [false,           false,      true,           false,      false,   false,          false      ];

const VOCAB_TEXT_ONLY = 0;
const EXPRESSION = 1;
const MULTI_LANGUAGE = 2;
const HAS_TEAMS = 3;
const SWF = 4;
const USES_KEYBOARD = 5;
const USES_STUDENT_LIST_SNACKBAR = 6;
const SINGLE_PLAYER = 7;

export default class ActivitySupport {
    static getTableForType(activity: Activity) {
        if (activity instanceof FlashcardActivity) {
            return flashcardActivityTable;
        } else if (activity instanceof Game) {
            return gameTable;
        } else if (activity instanceof InteractiveWorksheet) {
            return interactiveWorksheetTable;
        } else if (activity instanceof Worksheet) {
            return worksheetTable;
        } else if (activity instanceof Tool) {
            return toolTable;
        } else if (activity instanceof Quiz) {
            return quizTable;
        }
    }
    static categoryTypeSupported(activity: Activity, category: Category) {
        // Check category type is supported
        let table = ActivitySupport.getTableForType(activity);
        switch (category.type) {
            case CategoryType.Vocabulary:
            case CategoryType.Lesson:
                return true;

            case CategoryType.VocabularyTextOnly:
                return table[activity.activityType][VOCAB_TEXT_ONLY];

            case CategoryType.Expression: // eslint-disable-line no-fallthrough
            case CategoryType.ExpressionTextOnly:
                return table[activity.activityType][EXPRESSION];

            default:
                return false;
        }
    }

    static categoryCharactersSupported(activity: Activity, category: Category) {
        let table = ActivitySupport.getTableForType(activity);
        if (!this.categoryContainsUnsupportedCharacters(category)) {
            return true;
        } else {
            return table[activity.activityType][MULTI_LANGUAGE];
        }
    }

    static supportsDifferentLessonTypes(activity: Activity) {
        let table = ActivitySupport.getTableForType(activity);
        return table[activity.activityType][EXPRESSION];
    }

    static supportsTeams(activity: Activity) {
        let table = ActivitySupport.getTableForType(activity);
        return table[activity.activityType][HAS_TEAMS];
    }

    static supportsSinglePlayer(activity: Activity) {
        let table = ActivitySupport.getTableForType(activity);
        return table[activity.activityType][SINGLE_PLAYER];
    }

    static keyboardsSupportLanguage(activity: Activity, category: Category) {
        let table = ActivitySupport.getTableForType(activity);
        if (!table[activity.activityType][USES_KEYBOARD]) {
            return true;
        }

        for (let item of category.activityItems) {
            if (item.question !== undefined && item.question.text.length > 0) {
                if (!KeyboardLayout.anyKeyboardContainsAllChars(item.question.text)) {
                    return false;
                }
            }
            if (item.wrong1 !== undefined && item.wrong1.text && item.wrong1.text.length > 0) {
                if (!KeyboardLayout.anyKeyboardContainsAllChars(item.wrong1.text)) {
                    return false;
                }
            }
            if (item.wrong2 !== undefined && item.wrong2.text && item.wrong2.text.length > 0) {
                if (!KeyboardLayout.anyKeyboardContainsAllChars(item.wrong2.text)) {
                    return false;
                }
            }
            if (item.wrong3 !== undefined && item.wrong3.text && item.wrong3.text.length > 0) {
                if (!KeyboardLayout.anyKeyboardContainsAllChars(item.wrong3.text)) {
                    return false;
                }
            }

            if (!KeyboardLayout.anyKeyboardContainsAllChars(item.answer.text)) {
                return false;
            }
        }
        return true;
    }

    static usesStudentListSnackbar(activity: Activity) {
        let table = ActivitySupport.getTableForType(activity);
        return table[activity.activityType][USES_STUDENT_LIST_SNACKBAR];
    }

    static usesCategory(activity: Activity) {
        return !(activity instanceof Tool);
    }

    static requiresFlash(activity: Activity) {
        let table = ActivitySupport.getTableForType(activity);

        try {
            return table[activity.activityType][SWF] && !(activity instanceof InteractiveWorksheet);
        } catch (error) {
            return false;
        }
    }

    static isMultiLanguageActivity(activity: Activity) {
        let table = ActivitySupport.getTableForType(activity);
        try {
            return table[activity.activityType][MULTI_LANGUAGE];
        } catch (error) {
            return false;
        }
    }

    private static categoryContainsUnsupportedCharacters(category: Category) {
        for (let item of category.items) {
            if (item instanceof VocabularyCategoryItem || item instanceof LessonCategoryItem) {
                for (var i = 0; i < item.text.length; i++) {
                    if (!alphabetDictionary[item.text[i]]) {
                        return true;
                    }
                }
            }

            if (item instanceof ExpressionCategoryItem || item instanceof LessonCategoryItem) {
                for (i = 0; i < item.question.length; i++) {
                    if (!alphabetDictionary[item.question[i]]) {
                        return true;
                    }
                }
                for (i = 0; i < item.answer.length; i++) {
                    if (!alphabetDictionary[item.answer[i]]) {
                        return true;
                    }
                }
            }
            return false;
        }
        return false;
    }
}
