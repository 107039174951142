import * as React from "react";

import { Fonts } from "../../core/utils/Fonts";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { DynamicTypography, DynamicTypographyType } from "core/utils/DynamicTypography";
import { Colors } from "core/utils/Colors";
import { Row } from "layout";
import { Button } from "@material-ui/core";

interface ButtonMenuDisplayComponentProps {
    label?: string;
    icon?: JSX.Element;
    value: string;
}

export default class ButtonMenuDisplayComponent extends React.Component<ButtonMenuDisplayComponentProps> {
    render() {
        let label = this.props.label ? this.props.label + ": " : "";
        return (
            <Button variant="contained">
                {this.props.icon}
                {`${label}${this.props.value}`}
            </Button>
        );
    }
}
