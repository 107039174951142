import * as React from "react";

/* eslint-disable */
export class StringUtils {
    public static limitStringToChars(text: String, maxChars: number) {
        if (text.length <= maxChars) {
            return text;
        }

        return text.slice(0, maxChars) + "...";
    }

    public static isEmptyOrUndefined(text: string): boolean {
        if (text === undefined || text === "" || text === null) {
            return true;
        } else {
            return false;
        }
    }

    public static isValidEmail(text: string): boolean {
        // tslint:disable

        if (text.length == 0) {
            return false;
        }

        let regexp = new RegExp(
            [
                '^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)',
                '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.',
                "[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+",
                "[a-zA-Z]{2,}))$",
            ].join("")
        );
        // tslint:enable
        return regexp.test(text);
    }

    public static isValidUsername(username: string): boolean {
        let regexp = /^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$/;
        return regexp.test(username);
    }

    public static isValidPassword(password: string): boolean {
        return password.length > 5;
    }

    public static createHighlightedText = (text: string, highlightColor: any) => {
        text = text.toUpperCase();
        if (text.indexOf("{{") === -1 || text.indexOf("}}") === -1) {
            return <span>{text}</span>;
        } else {
            let openingIndex = text.indexOf("{{");
            let closingIndex = text.indexOf("}}");

            let component1 = text.slice(0, openingIndex);
            let component2 = text.slice(openingIndex + 2, closingIndex);
            let component3 = text.slice(closingIndex + 2);
            return [
                <span key="1">{component1}</span>,
                <span key="2" style={{ color: highlightColor }}>
                    {component2 + " "}
                </span>,
                <span key="3">{component3}</span>,
            ];
        }
    };

    public static isSpecialCharacter = (character: string) => {
        return "!@#$%^&*()_-?',./".split("").includes(character);
    };
}
