import * as React from "react";
import Paper from "@material-ui/core/Paper";

interface ClickableCardProps {
    children?: any;
    style?: any;
    onClickCallback?: (event: any) => void;
}

export default class ClickableCard extends React.Component<ClickableCardProps> {
    render() {
        let defaultStyle = { margin: "4px 0 4px 0", cursor: "pointer" };
        let style = this.props.style ? this.props.style : defaultStyle;
        return (
            <Paper
                style={style}
                children={
                    <div style={{ position: "relative" }}>
                        <div id="background" onClick={this.props.onClickCallback}>
                            {this.props.children}
                        </div>
                    </div>
                }
            />
        );
    }
}
