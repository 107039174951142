export function defaultFor(arg: any, val: any) {
    return typeof arg !== "undefined" ? arg : val;
}

export function degreesToRadians(degrees: number) {
    return (degrees * Math.PI) / 180;
}

export function radiansToDegrees(radians: number) {
    return (radians * 180) / Math.PI;
}

export function getRandomNumber(min: number, max: number, seed: number | string = undefined) {
    if (seed !== undefined) {
        var seedrandom = require("seedrandom");
        var random = seedrandom(seed);
        return Math.floor(random() * (max + 1 - min)) + min;
    }
    return Math.floor(Math.random() * (max + 1 - min)) + min;
}

export function getRandomPositiveNegativeNumber(minMax: number, seed: number | string = undefined) {
    if (seed !== undefined) {
        var seedrandom = require("seedrandom");
        var random = seedrandom(seed);
        return Math.floor(random() * (minMax * 2)) - minMax;
    }
    return Math.floor(Math.random() * (minMax * 2)) - minMax;
}

export function getRandomSign() {
    return Math.floor(Math.random() * 2) === 1 ? 1 : -1;
}

export function shuffleArray(array: any[]) {
    var currentIndex = array.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export function parseXML(xmlStr: string) {
    var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(xmlStr, "text/xml");
    return xmlDoc;
}

export function getCenteredPosition(
    width: number,
    height: number,
    position: number,
    numColumns: number,
    horzSpacing: number = 0,
    vertSpacing: number = 0
) {
    var newPosition: any = {};
    newPosition.x = (position % numColumns) * (width + horzSpacing) + width / 2;
    newPosition.y = Math.floor(position / numColumns) * (height + vertSpacing) + height / 2;
    return newPosition;
}

export function getPosition(
    width: number,
    height: number,
    position: number,
    numColumns: number,
    horzSpacing: number = 0,
    vertSpacing: number = 0
) {
    var newPosition: any = {};
    newPosition.x = (position % numColumns) * (width + horzSpacing);
    newPosition.y = Math.floor(position / numColumns) * (height + vertSpacing);
    return newPosition;
}

export function getPositionCenterInBoundries(
    width: number,
    height: number,
    index: number,
    boundryWidth: number,
    boundryHeight: number,
    totalItems: number,
    numColumns: number,
    horizSpacing: number = 0,
    vertSpacing: number = 0
) {
    var numRows = Math.ceil(totalItems / numColumns);
    var spacingBetweenX = (boundryWidth - numColumns * width + horizSpacing) / (numColumns + 1);
    var spacingBetweenY = (boundryHeight - numRows * height + vertSpacing) / (numColumns + 1);
    var currentColumn = (index % numColumns) + 1;
    var currentRow = Math.floor(index / numColumns) + 1;
    var newPosition: any = {};
    newPosition.x = currentColumn * spacingBetweenX + width * currentColumn - width / 2 - horizSpacing / 2;
    newPosition.y = currentRow * spacingBetweenY + height * currentRow - height / 2 - vertSpacing / 2;
    return newPosition;
}

export function setPositionFillBoundries_old(
    object: any,
    position: number,
    boundryWidth: number,
    boundryHeight: number,
    totalItems: number
) {
    var horizSpacing = 20;
    var vertSpacing = 20;
    var bounds = object.getBounds();

    var width = bounds.width;
    var height = bounds.height;
    var numColumns = 1;
    var numRows = Math.ceil(totalItems / numColumns);
    var totalWidth = numColumns * width + (numColumns - 1) * horizSpacing;
    var totalHeight = numRows * height + (numRows - 1) * vertSpacing;
    var boundryWidthHeightRatio = boundryWidth / boundryHeight;
    var ratio = (numColumns * (width + horizSpacing)) / (numRows * (height + vertSpacing));
    if (width > 0 && height > 0) {
        while (ratio < boundryWidthHeightRatio) {
            numColumns++;
            numRows = Math.ceil(totalItems / numColumns);
            ratio = (numColumns * (width + horizSpacing)) / (numRows * (height + vertSpacing));
        }
        if (totalItems < numColumns) {
            totalWidth = totalItems * (width + horizSpacing);
        }
        // First check if they need to be shrunk
        if (totalWidth > boundryWidth && totalWidth / boundryWidth > totalHeight / boundryHeight) {
            while (numColumns * object.getBounds().width + numColumns * (horizSpacing - 1) > boundryWidth) {
                object.scaleX *= 0.99;
                object.scaleY *= 0.99;
            }
        } else if (totalHeight > boundryHeight) {
            while ((totalHeight = numRows * object.getBounds().height + numRows * (vertSpacing - 1)) > boundryHeight) {
                object.scaleX *= 0.99;
                object.scaleY *= 0.99;
            }
        }
        // Next check if they need to grow
        if (boundryWidth > totalWidth) {
            while (numColumns * (object.getBounds().width + horizSpacing) < boundryWidth) {
                object.scaleX *= 1.01;
                object.scaleY *= 1.01;
            }
            object.scaleX *= 0.9;
            object.scaleY *= 0.9;
        } else if (boundryHeight > totalHeight) {
            while (numRows * (object.getBounds().height + vertSpacing) < boundryHeight) {
                object.scaleX *= 1.01;
                object.scaleY *= 1.01;
            }
            object.scaleX *= 0.9;
            object.scaleY *= 0.9;
        }

        // var spacingX = (boundryWidth - (numColumns - 1) * object.getBounds().width) / numColumns;
        // var spacingY = (boundryHeight - (numRows - 1) * object.getBounds().height) / numRows;
        // var spacingX = boundryWidth / (numColumns + 1);
        // var spacingY = boundryHeight / (numRows + 1);
        // var spacingX = 0;
        // var spacingY = 0;
        var currentColumn = (position % numColumns) + 1;
        var currentRow = Math.floor(position / numColumns) + 1;
        var columnWidth = boundryWidth / numColumns;
        var columnHeight = boundryHeight / numRows;
        bounds = object.getBounds();

        let xPos = currentColumn * columnWidth - columnWidth / 2 - boundryWidth / 2;
        let yPos = currentRow * columnHeight - columnHeight / 2 - boundryHeight / 2;
        // let yPos = (currentRow - 1) * vertSpacing + bounds.height * currentRow - bounds.height / 2 - boundryHeight / 2;
        console.log(
            `Boundry Width: ${boundryWidth}, Column: ${currentColumn}, Column width: ${columnWidth}, xPos: ${xPos}`
        );

        console.log(bounds.width);
        console.log(boundryWidth);
        object.x = xPos;
        object.y = yPos;
    }
}

export function setPositionFillBoundries(
    object: any,
    position: number,
    boundryWidth: number,
    boundryHeight: number,
    totalItems: number
) {
    var horizSpacing = 20;
    var vertSpacing = 20;
    var bounds = object.getBounds();

    var width = bounds.width;
    var height = bounds.height;
    var numColumns = 1;
    var numRows = Math.ceil(totalItems / numColumns);

    var boundryWidthHeightRatio = boundryWidth / boundryHeight;
    var ratio = (numColumns * (width + horizSpacing)) / (numRows * (height + vertSpacing));
    if (width > 0 && height > 0) {
        while (ratio <= boundryWidthHeightRatio) {
            numColumns++;
            numRows = Math.ceil(totalItems / numColumns);
            ratio = (numColumns * (width + horizSpacing)) / (numRows * (height + vertSpacing));
        }

        var totalWidth = numColumns * width + (numColumns - 1) * horizSpacing;
        var totalHeight = numRows * height + (numRows - 1) * vertSpacing;
        if (totalItems < numColumns) {
            totalWidth = totalItems * (width + horizSpacing);
        }

        var columnWidth = boundryWidth / numColumns;
        var rowHeight = boundryHeight / numRows;
        // First check if they need to be shrunk
        if (
            (totalWidth > boundryWidth && totalWidth / boundryWidth > totalHeight / boundryHeight) ||
            totalHeight > boundryHeight
        ) {
            while (object.getBounds().width > columnWidth || object.getBounds().height > rowHeight) {
                object.scaleX *= 0.99;
                object.scaleY *= 0.99;
            }
        }
        // Next check if they need to grow
        else if (boundryWidth > totalWidth || boundryHeight > totalHeight) {
            while (object.getBounds().width < columnWidth && object.getBounds().height < rowHeight) {
                object.scaleX *= 1.01;
                object.scaleY *= 1.01;
            }
            object.scaleX *= 0.9;
            object.scaleY *= 0.9;
        }

        var currentColumn = (position % numColumns) + 1;
        var currentRow = Math.floor(position / numColumns) + 1;
        let xPos = currentColumn * columnWidth - columnWidth / 2 - boundryWidth / 2;
        let yPos = currentRow * rowHeight - rowHeight / 2 - boundryHeight / 2;

        object.x = xPos;
        object.y = yPos;
    }
}
