import { CreditCard } from "./CreditCard";

export enum BillingFrequency {
    Monthly = "month",
    Annually = "year",
}

export enum SubscriptionDetailsStatus {
    NotLoaded = "notLoaded",
    None = "none",
    Updating = "updating",
    Loaded = "loaded",
    Error = "error",
}

export interface PendingSubscription {
    currentPeriodEnd: number;
    subscriptionFee?: number;
    amountDueNow?: number;
    billingFrequency: BillingFrequency;
    quantity: number;
}

export interface CanceledSubscription {
    dateCanceled: Date;
    periodEnd: Date;
    quantity: number;
    billingFrequency: BillingFrequency;
}

export class Subscription {
    public id: string;
    public trialEnd: Date;
    public amountDue: number;
    public subtotal: number;
    public discount: number;
    public discountPercentage: number;
    public quantity: number;
    public billingFrequency: BillingFrequency;
    public currentPeriodEnd: Date;
    public cancelAtPeriodEnd: boolean;
    public endedAt: Date;
    public status: string;

    constructor(obj: any = undefined) {
        if (obj === undefined) {
            return;
        } else {
            Object.assign(this, obj);

            if (obj.currentPeriodEnd !== undefined && obj.currentPeriodEnd !== null) {
                this.currentPeriodEnd = new Date(obj.currentPeriodEnd * 1000);
            }

            if (obj.endedAt !== undefined && obj.endedAt !== null) {
                this.endedAt = new Date(obj.endedAt * 1000);
            }

            if (obj.trialEnd !== undefined && obj.trialEnd !== null) {
                this.trialEnd = new Date(obj.trialEnd * 1000);
            }
            return this;
        }
    }
}

export class StripeCustomerDetails {
    public subscription: Subscription;
    public card: CreditCard;

    constructor(obj: any = undefined) {
        if (obj !== undefined) {
            Object.assign(this, obj);
            if (obj.subscription) {
                this.subscription = new Subscription(obj.subscription);
            }
        }
        return this;
    }

    get noCardForSubscription(): boolean {
        if (!this.card || !this.card.id) {
            return true;
        }
        return false;
    }

    get accountExpired(): boolean {
        if (!this.subscription) {
            return true;
        }

        if (!this.subscription.currentPeriodEnd) {
            return false;
        }

        return Date.now() > this.subscription.currentPeriodEnd.getTime();
    }
}
