// Firestore
export const BASIC_CREDITS_COLLECTION = "basicCredits";
export const CATEGORIES_COLLECTION = "categories";
export const CATEGORY_IMAGES_COLLECTION = "categoryImages";
export const CATEGORY_AUDIO_COLLECTION = "categoryAudio";
export const ACTIVITY_LINKS = "activityLinks";
export const ACTIVITY_LINK_VISITORS = "activityLinkVisitors";
export const PINNED_ACTIVITES = "pinnedActivities";
export const MANAGED_USERS_AUTH_COLLECTION = "managedUsersAuth";
export const STUDENTS_COLLECTION = "students";
export const USERS_COLLECTION = "users";
export const ERRORS_COLLECTION = "errors";
export const ANGRY_WORDS_LEVELS = "angryWordsLevels";
export const USER_PERSISTENT_STATE = "persistentState";
export const PENDING_MANAGED_USERS_COLLECTION = "pendingManagedUsers";

// Storage
export const FIREBASE_STORAGE_URL = "gs://kinteractivelearning.appspot.com/";
export const DOWNLOAD_URL_PREFIX = "https://firebasestorage.googleapis.com/v0/b/kinteractivelearning.appspot.com/o/";
export const CATEGORY_IMAGES_REF = "categoryImages/";
export const IMAGES_STORAGE_PATH = "customLists/images/";
export const AUDIO_STORAGE_PATH = "customLists/audio/";
export const PROFILE_IMAGES_PATH = "profileImages/";
export const STUDENT_PROFILE_TEMP_PATH = "profileImages/temp/students/";
export const MEMBER_PROFILE_TEMP_PATH = "profileImages/temp/users/";
export const TEMP_CUSTOM_IMAGES_PATH = "customLists/imagesTemp";

// Page Sizes
export const MD_PAGE = 960;
