import * as React from "react";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";

// Material UI
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

// Components
import OptionsMenu, { MenuOption } from "./common/OptionsMenu";
import HomeIcon from "components/HomeIcon";
import SiteLangaugeMenu from "components/menus/SiteLanguageMenu";

// Models
import { LanguageType, FlashcardActivity, InteractiveWorksheet, Game, Quiz, Tool, Worksheet } from "models";

// Stores
import { RootStore } from "stores";

// Utils
import * as Icons from "core/utils/Icons";
import { Colors } from "core/utils/Colors";
import { strings } from "core/localizedStrings";
import * as DeviceSize from "core/utils/DeviceSize";

import { MY_ACCOUNT_PATH, REGISTRATION_PATH, CONTACT_PATH } from "../constants";
import { APP_BAR_INDEX } from "layers";
import NavigationDropdown from "./navigation/NavigationDropdown";
import Activity from "models/activities/Activity";
import { Hidden, Slide, useScrollTrigger } from "@material-ui/core";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

interface SiteAppBarProps {
    rootStore?: RootStore;
    location?: any;
}

const styles = {
    appBar: { minHeight: 40, backgroundColor: Colors.veryLightGrey, zIndex: APP_BAR_INDEX },
    logo: { paddingLeft: 10, height: 40, cursor: "pointer" },
};

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

@inject("rootStore")
@observer
export class SiteAppBar extends React.Component<SiteAppBarProps> {
    private hideLoginLogoutButtons: boolean = false;

    public render() {
        let { rootStore, location } = this.props;
        if (!rootStore) {
            return null;
        }

        let shouldShowMenuIcon = true;
        if (location.pathname === REGISTRATION_PATH) {
            shouldShowMenuIcon = false;
            this.hideLoginLogoutButtons = true;
        }

        return (
            <AppBar position="relative" style={styles.appBar} title="KinteractiveLearning">
                {rootStore.appBarTitle ? (
                    <Row middle="xs">
                        <Col xs={2}>{this.renderMenuIcon()}</Col>
                        <Col xs={8}>
                            <Row center="xs">
                                <Typography variant="button" style={{ color: Colors.black }}>
                                    {this.props.rootStore.appBarTitle}
                                </Typography>
                            </Row>
                        </Col>
                        <Col xs={2} style={{ whiteSpace: "nowrap" }} />
                        {this.renderLoginLogoutButtons()}
                    </Row>
                ) : (
                    <Row middle="xs">
                        <Col xs={10}>
                            {rootStore?.activityStore && !rootStore.activityStore.activityLinkVisitorKey && (
                                <Row middle="xs" style={{ position: "relative" }}>
                                    {shouldShowMenuIcon && this.renderMenuIcon()}
                                    {this.renderSiteLogo()}
                                    <DeviceSize.BigScreen>{this.renderNavigation()}</DeviceSize.BigScreen>
                                </Row>
                            )}
                        </Col>
                        <Col xs={2} style={{ whiteSpace: "nowrap" }} />
                        {this.renderLoginLogoutButtons()}
                    </Row>
                )}
            </AppBar>
        );
    }

    renderMenuIcon() {
        return (
            <IconButton onClick={this.handleMenuIconClicked}>
                <Icons.Menu />
            </IconButton>
        );
    }

    renderNavigation() {
        return (
            <Row style={{ position: "absolute", right: 0 }}>
                <NavigationDropdown
                    items={FlashcardActivity.allItems}
                    description={strings.flashcards}
                    onActivitySelected={this.onActivitySelected}
                />
                <NavigationDropdown
                    items={Game.allItems}
                    description={strings.games}
                    onActivitySelected={this.onActivitySelected}
                />
                <NavigationDropdown
                    description={strings.worksheets}
                    items={InteractiveWorksheet.allItems}
                    itemsDescription={strings.interactive}
                    items2={Worksheet.allItems}
                    items2Description={strings.printable}
                    onActivitySelected={this.onActivitySelected}
                />

                <NavigationDropdown
                    items={Quiz.allItems}
                    description={strings.quiz}
                    onActivitySelected={this.onActivitySelected}
                />
                <NavigationDropdown
                    items={Tool.allItems}
                    description={strings.tools}
                    onActivitySelected={this.onActivitySelected}
                />
            </Row>
        );
    }

    renderSiteLogo() {
        return (
            <>
                <Hidden smDown>{<HomeIcon variant="logo" />}</Hidden>
                <Hidden mdUp>
                    <HomeIcon variant="icon" />
                </Hidden>
            </>
        );
    }

    renderLoginLogoutButtons() {
        let options: MenuOption[] = [];
        if (
            this.props.rootStore.userStore &&
            this.props.rootStore.userStore.user &&
            this.props.location &&
            this.props.location.pathname !== MY_ACCOUNT_PATH
        ) {
            options.push(MenuOption.MyAccount);
        }
        options.push(MenuOption.Support);
        options.push(MenuOption.Logout);

        let isLoggingInOrSignedIn =
            this.props.rootStore.authStore !== undefined &&
            (this.props.rootStore.authStore.isSignedIn || this.props.rootStore.authStore.isLoggingIn);
        let isAnonymous = this.props.rootStore.authStore !== undefined && this.props.rootStore.authStore.isAnonymous;

        let showLogin = !isLoggingInOrSignedIn || isAnonymous;
        let showLogout = !showLogin && this.props.rootStore.authStore.isSignedIn;

        if (this.hideLoginLogoutButtons) {
            return null;
        } else {
            return (
                <div style={{ position: "absolute", top: -2, right: 3 }}>
                    {showLogin && (
                        <Row end="xs" middle="xs" style={{ minHeight: 50 }}>
                            <Button onClick={this.signIn}>
                                <span style={{ color: Colors.lightBlue }}>{strings.signInRegister}</span>
                            </Button>
                            <SiteLangaugeMenu
                                rootStore={this.props.rootStore}
                                onChangedCallback={this.handleLanguageChange}
                            />
                        </Row>
                    )}
                    {showLogout && (
                        <Row end="xs" middle="xs">
                            <SiteLangaugeMenu
                                rootStore={this.props.rootStore}
                                onChangedCallback={this.handleLanguageChange}
                            />
                            <OptionsMenu
                                rootStore={this.props.rootStore}
                                options={options}
                                onMenuSelectionChangedCallback={this.onMenuSelectionChanged}
                            />
                        </Row>
                    )}
                </div>
            );
        }
    }

    private handleMenuIconClicked = () => {
        let { rootStore } = this.props;
        rootStore.setShouldShowNavigationDrawer(!rootStore.shouldShowNavigationDrawer);
    };

    private handleLanguageChange = (language: LanguageType) => {
        this.props.rootStore.setSiteLanguage(language);
    };

    private signIn = () => {
        this.props.rootStore.authStore.setShouldShowSignInDialog(true);
    };

    private onActivitySelected = (activity: Activity) => {
        let { rootStore } = this.props;
        let { activityStore } = rootStore;
        activityStore.setCurrentActivity(activity);
        rootStore.setShouldShowNavigationDrawer(false);
    };

    private onMenuSelectionChanged = (menuSelection: MenuOption) => {
        switch (menuSelection) {
            case MenuOption.MyAccount:
                this.props.rootStore.setRoute(MY_ACCOUNT_PATH);
                break;

            case MenuOption.Logout:
                this.props.rootStore.logout();
                break;

            case MenuOption.Support:
                this.props.rootStore.setRoute(CONTACT_PATH);
                break;

            default:
                break;
        }
    };
}

export default withRouter((props) => <SiteAppBar {...props} />);
