export enum AccountRole {
    UNKNOWN = "unknown",
    ADMIN = "admin",
    TRANSLATOR = "translator",
    ANONYMOUS = "anonymous",
    BASIC = "basic",
    PREMIUM = "premium",
    TRIAL = "trial",
    GROUP = "group",
    STUDENT_GROUP = "studentGroup"
}
