import * as React from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";

// Material UI
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";

// Components
import RecentCategoriesMenu from "components/menus/RecentCategoriesMenu";

// Models
import { FlashcardActivity, InteractiveWorksheet, Game, Quiz, Tool, Worksheet } from "models";
import Activity from "models/activities/Activity";

// Stores
import { RootStore } from "stores";

// Utils
import { Row, Col } from "layout";
import NavigationMenu, { NavigationMenuType } from "./NavigationMenu";
import { strings } from "core/localizedStrings";
import { Colors } from "core/utils/Colors";

interface NavigationDrawerProps extends React.Props<any> {
    rootStore: RootStore;
    location?: any;
}

@observer
export class NavigationDrawer extends React.Component<NavigationDrawerProps> {
    // private isAppRoot = false;

    constructor(props: NavigationDrawerProps) {
        super(props);
        // this.isAppRoot = this.props.location.pathname === "/";
    }

    render() {
        let { rootStore } = this.props;
        let {
            activityStore,
            // authStore,
            introduceExpanded,
            playExpanded,
            practiceExpanded,
            quizExpanded,
            toolsExpanded,
        } = rootStore;

        if (!rootStore || !activityStore) {
            return null;
        }

        let drawerWidth = rootStore.windowSize.width * 0.8;
        return (
            <Drawer
                PaperProps={{ style: { backgroundColor: Colors.veryLightGrey } }}
                variant="temporary"
                anchor="left"
                open={this.props.rootStore.shouldShowNavigationDrawer}
                onClose={this.handleDrawerClose}
            >
                <Col xs={true} style={{ width: drawerWidth }}>
                    <Row middle="xs" style={{ paddingTop: 50 }} />
                    <Divider />
                    {activityStore.currentActivity && !(activityStore.currentActivity instanceof Tool) && (
                        <List>
                            <RecentCategoriesMenu
                                key={1}
                                rootStore={rootStore}
                                recentCategories={activityStore.recentCategories}
                            />
                            {activityStore.currentActivity && activityStore.currentActivity.supportsSecondActivity && (
                                <RecentCategoriesMenu
                                    key={2}
                                    isSecond={true}
                                    rootStore={rootStore}
                                    recentCategories={activityStore.recentCategories}
                                />
                            )}
                        </List>
                    )}
                    <Divider />
                    <List>
                        <NavigationMenu
                            type={NavigationMenuType.Introduce}
                            text={strings.introduce}
                            description={strings.learnDescription}
                            items={FlashcardActivity.allItems}
                            initialStateExpanded={introduceExpanded}
                            onActivitySelected={this.onActivitySelected}
                            onExpansionChanged={this.onExpansionChanged}
                        />
                        <NavigationMenu
                            type={NavigationMenuType.Practice}
                            text={strings.practice}
                            description={strings.practiceDescription}
                            items={InteractiveWorksheet.allItems}
                            itemsDescription={strings.interactive}
                            items2={Worksheet.allItems}
                            items2Description={strings.printable}
                            initialStateExpanded={practiceExpanded}
                            onActivitySelected={this.onActivitySelected}
                            onExpansionChanged={this.onExpansionChanged}
                        />
                        <NavigationMenu
                            type={NavigationMenuType.Play}
                            text={strings.play}
                            description={strings.playDescription}
                            items={Game.allItems}
                            initialStateExpanded={playExpanded}
                            onActivitySelected={this.onActivitySelected}
                            onExpansionChanged={this.onExpansionChanged}
                        />
                        <NavigationMenu
                            type={NavigationMenuType.Quiz}
                            text={strings.quiz}
                            description={strings.reviewDescription}
                            items={Quiz.allItems}
                            initialStateExpanded={quizExpanded}
                            onActivitySelected={this.onActivitySelected}
                            onExpansionChanged={this.onExpansionChanged}
                        />
                        <NavigationMenu
                            type={NavigationMenuType.Tools}
                            text={strings.tools}
                            description={strings.toolsDescription}
                            items={Tool.allItems}
                            initialStateExpanded={toolsExpanded}
                            onActivitySelected={this.onActivitySelected}
                            onExpansionChanged={this.onExpansionChanged}
                        />
                    </List>
                </Col>
            </Drawer>
        );
    }

    private handleDrawerClose = (event: any) => {
        this.props.rootStore.setShouldShowNavigationDrawer(false);
    };

    private onExpansionChanged = (type: NavigationMenuType, value: boolean) => {
        switch (type) {
            case NavigationMenuType.Introduce:
                this.props.rootStore.setIntroduceExpanded(value);
                break;
            case NavigationMenuType.Practice:
                this.props.rootStore.setPracticeExpanded(value);
                break;
            case NavigationMenuType.Play:
                this.props.rootStore.setPlayExpanded(value);
                break;
            case NavigationMenuType.Quiz:
                this.props.rootStore.setQuizExpanded(value);
                break;
            case NavigationMenuType.Tools:
                this.props.rootStore.setToolsExpanded(value);
                break;
            default:
                break;
        }
    };

    private onActivitySelected = (activity: Activity) => {
        let { rootStore } = this.props;
        let { activityStore } = rootStore;
        activityStore.setCurrentActivity(activity);
        rootStore.setShouldShowNavigationDrawer(false);
    };
}

export default withRouter((props) => <NavigationDrawer {...props} />);
