// import { log } from "../../";

import * as React from "react";
import { runInAction, observable } from "mobx";
import { observer, inject } from "mobx-react";

// Material UI
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

// Components
import InfoRow from "./InfoRow";
import AudioClipsInfoRow from "./AudioClipsInfoRow";
import ContactRow from "./ContactRow";
import Footer from "./Footer";

// Stores
import RootStore from "../../stores/RootStore";

// Utils
import { strings } from "../../core/localizedStrings";
import { Colors } from "../../core/utils/Colors";
import * as Icons from "../../core/utils/Icons";
import { DynamicTypographyType, DynamicTypography } from "core/utils/DynamicTypography";
import { BACKGROUND_IMAGE_INDEX } from "layers";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

export interface LandingPageProps extends React.Props<any> {
    rootStore: RootStore;
    location?: any;
}

let styles = {
    container: {
        position: "relative",
        width: "100%",
        margin: "0 auto",
        textAlign: "center",
        backgroundColor: Colors.white,
    },
    mainImageText: {
        maxWidth: 1000,
        width: "100%",
        padding: "80px 60px 20px 60px",
        color: Colors.white,
        fontSize: 42,
        fontWeight: "bold",
        lineHeight: "48px",
        textShadow: "2px 2px #000",
    } as React.CSSProperties,
    mainImageTextMobile: {
        maxWidth: 1000,
        width: "100%",
        padding: "80px 60px 20px 60px",
        color: Colors.white,
        fontSize: 22,
        fontWeight: "bold",
        lineHeight: "28px",
        textShadow: "2px 2px #000",
    } as React.CSSProperties,
    mainImageButtons: {
        width: "100%",
        paddingBottom: 80,
    },
};

@inject("rootStore")
@observer
export default class LandingPage extends React.Component<LandingPageProps> {
    @observable private sentEmailText: string;

    constructor(props: LandingPageProps) {
        super(props);
        this.props.rootStore.authStore.setIsLoggingIn(false);
    }

    render() {
        let backgroundImage = require("../../assets/images/homePage/homeBackground.jpg");
        return (
            <Col xs={12}>
                <Row center="xs">
                    <Col style={styles.container}>
                        <Row
                            center="xs"
                            style={{
                                zIndex: BACKGROUND_IMAGE_INDEX,
                                backgroundImage: `url(${backgroundImage})`,
                            }}
                        >
                            <Row center="xs">
                                <DynamicTypography variant={DynamicTypographyType.H1} style={styles.mainImageText}>
                                    {strings.mainPageText.toUpperCase()}
                                </DynamicTypography>
                            </Row>
                            <Row center="xs" style={styles.mainImageButtons}>
                                {(!this.props.rootStore.authStore.isSignedIn ||
                                    (this.props.rootStore.firebaseUser &&
                                        this.props.rootStore.firebaseUser.isAnonymous)) &&
                                    this.renderSignInRegisterButtons()}
                            </Row>
                            {this.renderCheckEmailMessageIfNecessary()}
                        </Row>
                    </Col>
                    {/* <div
                            style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: Colors.black,
                                opacity: 0.2,
                                position: "absolute",
                                zIndex: -1,
                                top: 0,
                                left: 0
                            }}
                        /> */}
                </Row>
                <InfoRow
                    rootStore={this.props.rootStore}
                    textHeader={strings.customizeHeader}
                    text={strings.customizeText}
                    imagePath={"customize.png"}
                    imageHeight={300}
                    variant="rightImage"
                />
                <InfoRow
                    rootStore={this.props.rootStore}
                    textHeader={strings.forClassroomHeader}
                    text={strings.forClassroomText}
                    imagePath={"forClassroom.png"}
                    variant="leftImage"
                />
                <InfoRow
                    rootStore={this.props.rootStore}
                    textHeader={strings.thousandsOfHeader}
                    text={strings.thousandsOfText}
                    imagePath={"thousandsOfLists.png"}
                    variant="rightImage"
                />
                <AudioClipsInfoRow />
                <ContactRow store={this.props.rootStore} />
                <Footer />
            </Col>
        );
    }

    private renderCheckEmailMessageIfNecessary() {
        if (
            (this.props.rootStore.firebaseUser && this.props.rootStore.firebaseUser.isAnonymous) ||
            !this.props.rootStore.firebaseUser ||
            this.props.rootStore.firebaseUser.emailVerified
        ) {
            return null;
        }
        return (
            <Row center="xs" style={styles.mainImageButtons}>
                <Paper style={{ padding: 30 }}>
                    <Row middle="xs">
                        <Icons.Email style={{ marginRight: 5 }} />
                        {strings.checkEmailForVerifcation}
                    </Row>
                    <Row center="xs">
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ width: 150, margin: 10 }}
                            onClick={this.onResendEmailVerificationClicked}
                        >
                            {strings.resendEmail}
                        </Button>
                    </Row>
                    {this.sentEmailText && (
                        <Row center="xs" style={{ whiteSpace: "pre-line" }}>
                            {this.sentEmailText}
                        </Row>
                    )}
                </Paper>
            </Row>
        );
    }

    private renderSignInRegisterButtons() {
        return (
            <Col>
                <Row>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ width: 270, height: 50, margin: 10, fontSize: 16 }}
                        onClick={this.onStartNowClicked}
                    >
                        {strings.getStarted}
                    </Button>
                </Row>
            </Col>
        );
    }

    private onResendEmailVerificationClicked = async () => {
        let success = await this.props.rootStore.authStore.sendEmailVerification();
        if (success) {
            let message = strings.formatString(strings.emailSentTo, this.props.rootStore.userStore.user.email);
            runInAction(() => {
                if (this.sentEmailText) {
                    this.sentEmailText += "\n" + message;
                } else {
                    this.sentEmailText = message as string;
                }
            });
        }
    };

    private onStartNowClicked = () => {
        this.props.rootStore.authStore.setShouldShowSignInDialog(true);
    };
}
