import { observable, action } from "mobx";

import { Category, CategoryImage, StudentList } from "../models";
import { removeUndefinedFields } from "../core/utils/ObjectUtils";

export type FolderItem = Category | CategoryImage | StudentList;
export type ListItem = FolderItem | Folder;

export enum SearchOnType {
    Title,
    Keyword,
}
export default class Folder {
    isParentFolderLink: boolean = false;
    key: string;
    title: string;
    parentKey: string;
    parentID: number; // legacy parentID
    created: number;
    modified: number;

    @observable
    subfolders: Folder[] = [];

    @observable
    items: FolderItem[] = [];

    lastFetchedFolder: any;
    lastFetchedItem: any;

    allFoldersLoaded: boolean = false;
    allItemsLoaded: boolean = false;
    needsUpdate: boolean = false;

    folderUnsubscribeCallback?: () => void;
    subfoldersUnsubscribeCallbacks: (() => void)[] = [];
    itemsUnsubscribeCallbacks: (() => void)[] = [];

    // totalItems: number;

    get objectForFirebase() {
        let copy: any = Object.assign({}, this);
        delete copy.subfolders;
        delete copy.items;
        delete copy.lastFetchedFolder;
        delete copy.lastFetchedItem;
        delete copy.folderUnsubscribeCallback;
        delete copy.subfoldersUnsubscribeCallbacks;
        delete copy.itemsUnsubscribeCallbacks;
        delete copy.allFoldersLoaded;
        delete copy.allItemsLoaded;
        delete copy.needsUpdate;
        return removeUndefinedFields(copy);
    }

    constructor(object: any = undefined) {
        if (object) {
            Object.assign(this, object);
        }
    }

    public reset() {
        this.allFoldersLoaded = false;
        this.allItemsLoaded = false;
        this.lastFetchedFolder = undefined;
        this.lastFetchedItem = undefined;
        for (let callback of this.subfoldersUnsubscribeCallbacks) {
            callback();
        }
        for (let callback of this.itemsUnsubscribeCallbacks) {
            callback();
        }
        this.items = [];
        this.subfolders = [];
    }

    @action
    public sortItemsByTitle() {
        this.items = this.items.sort((a: FolderItem, b: FolderItem) => {
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
                return 1;
            } else if (a.title.toLowerCase() < b.title.toLowerCase()) {
                return -1;
            } else {
                return 0;
            }
        });
    }

    @action
    public addSubfolder = (addedFolder: Folder, parentFolderKey: string) => {
        let found =
            this.subfolders.filter(item => {
                return item.key === addedFolder.key;
            }).length > 0;

        if (!found) {
            this.subfolders.push(addedFolder);
        }
    };

    @action
    public updateSubfolder = (updatedFolder: Folder, parentFolderKey: string) => {
        for (var i = 0; i < this.subfolders.length; i++) {
            let item = this.subfolders[i];
            if (item.key === updatedFolder.key) {
                this.subfolders[i] = updatedFolder;
                break;
            }
        }
    };

    @action
    public removeSubfolder = (removedFolder: Folder) => {
        this.subfolders = this.subfolders.filter(item => {
            return item.key !== removedFolder.key;
        });
    };

    @action
    public addItem(item: FolderItem) {
        let found = false;
        for (var i = 0; i < this.items.length; i++) {
            let thisItem = this.items[i];
            if (thisItem.key === item.key) {
                this.items[i] = item;
                found = true;
                break;
            }
        }

        if (!found) {
            this.items.push(item);
        }
    }

    @action
    public removeItem(item: FolderItem) {
        this.items = this.items.filter(element => {
            return element.key !== item.key;
        });
    }
}
