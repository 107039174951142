import * as React from "react";
import { observer, inject } from "mobx-react";

// Material UI
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

// Components
import RegisterForm from "../components/RegisterForm";
import AuthProviderButtons from "../components/AuthProviderButtons";

// Stores
import RootStore from "../stores/RootStore";

// Utils
import { Fonts } from "../core/utils/Fonts";
import { strings } from "../core/localizedStrings";
import * as Icons from "../core/utils/Icons";
import { Colors } from "../core/utils/Colors";

// Styles
import { GlobalStyles } from "../styles/GlobalStyles";

import { BILLED_ANNUALLY_AMOUNT, BILLED_MONTHLY_AMOUNT } from "../constants";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

const checkIcon = <Icons.Check style={{ color: Colors.green }} />;
const styles = {
    table: { fontSize: 16, fontFamily: Fonts.BalsamiqSans },
    evenTableRow: { backgroundColor: Colors.white },
    oddTableRow: { backgroundColor: Colors.veryLightGrey },
    tableHeading: {
        textAlign: "center",
        backgroundColor: Colors.lightBlue,
        color: Colors.white,
        fontFamily: Fonts.Roboto,
    } as React.CSSProperties,
    tableCell: { textAlign: "center", fontFamily: Fonts.Roboto } as React.CSSProperties,
};

export interface RegistrationPageProps extends React.Props<any> {
    rootStore: RootStore;
}

@inject("rootStore")
@observer
export default class RegistrationPage extends React.Component<RegistrationPageProps> {
    private data: any[] = [
        { type: strings.interactiveFlashcards, basic: strings.creditNeeded, premium: strings.unlimited },
        { type: strings.games, basic: strings.creditNeeded, premium: strings.unlimited },
        { type: strings.worksheetCreators, basic: strings.creditNeeded, premium: strings.unlimited },
        { type: strings.teachingTools, basic: strings.unlimited, premium: strings.unlimited },
        { type: strings.createCustomLists, basic: checkIcon, premium: checkIcon },
        { type: strings.uploadImages, basic: checkIcon, premium: checkIcon },
        { type: strings.createStudentNameLists, basic: checkIcon, premium: checkIcon },
    ];

    render() {
        let monthlyPrice = strings.formatString(strings.premiumPriceFormatMonth, BILLED_MONTHLY_AMOUNT / 100);
        let annualPrice = strings.formatString(strings.premiumPriceFormatYear, BILLED_ANNUALLY_AMOUNT / 100);
        return (
            <Paper style={GlobalStyles.paper}>
                <Col xs={12}>
                    <Row>
                        <Typography style={GlobalStyles.pageHeading}>{strings.accountRegistration}</Typography>
                    </Row>
                    <Row style={{ paddingBottom: 20 }}>
                        <span style={GlobalStyles.pageDescription}>{strings.signUpForFreeMessage}&nbsp;</span>
                        <span style={GlobalStyles.pageDescription}>
                            <b>{strings.noCreditCardRequired}</b>
                            &nbsp;
                        </span>
                        <span style={GlobalStyles.pageDescription}>{strings.afterTrial}</span>
                    </Row>
                    <Row style={{ paddingBottom: 20 }}>
                        <Col xs={12} sm={6} style={{ paddingTop: 20 }}>
                            <Row center="xs">
                                <AuthProviderButtons
                                    providerButtonPrefix={"Register with"}
                                    rootStore={this.props.rootStore}
                                />
                            </Row>
                        </Col>
                        <Col xs={12} sm={6} style={{ paddingTop: 20, paddingBottom: 30 }}>
                            <Row center="xs">
                                <RegisterForm rootStore={this.props.rootStore} />
                            </Row>
                        </Col>
                    </Row>
                    <Row xs="center">
                        <Row>
                            <Typography style={GlobalStyles.secondaryHeading}>{strings.membershipTypes}</Typography>
                        </Row>
                        <Table style={styles.table}>
                            <TableRow>
                                <TableCell style={{ backgroundColor: Colors.white }} />
                                <TableCell style={styles.tableHeading}>{strings.basic}</TableCell>
                                <TableCell style={styles.tableHeading}>{strings.premium}</TableCell>
                            </TableRow>
                            {this.data.map((element, index) => {
                                return (
                                    <TableRow key={index} style={index % 2 ? styles.oddTableRow : styles.evenTableRow}>
                                        <TableCell>{element.type}</TableCell>
                                        <TableCell style={styles.tableCell}>{element.basic}</TableCell>
                                        <TableCell style={styles.tableCell}>{element.premium}</TableCell>
                                    </TableRow>
                                );
                            })}
                            <TableRow style={styles.evenTableRow}>
                                <TableCell>{strings.price}</TableCell>
                                <TableCell style={styles.tableCell}>{strings.free}</TableCell>
                                <TableCell style={styles.tableCell}>
                                    <div>{monthlyPrice}</div>
                                    <div>{annualPrice}</div>
                                </TableCell>
                            </TableRow>
                        </Table>
                        <Typography variant="h6" style={GlobalStyles.pageDescription}>
                            <div style={{ padding: 20 }}>{strings.basicMemberCredits}</div>
                        </Typography>
                    </Row>
                </Col>
            </Paper>
        );
    }
}
