enum LanguageNum {
    ENGLISH_US = 0,
    ENGLISH_BRITISH = 1,
    ENGLISH_AUSTRALIAN = 2,
    CHINESE = 3,
    DUTCH = 4,
    FRENCH = 5,
    GERMAN = 6,
    GREEK = 7,
    ITALIAN = 8,
    KOREAN = 9,
    JAPANESE = 10,
    RUSSIAN = 11,
    SPANISH = 12,
    SPANISH_EUROPEAN = 13,
    OTHER = 14,
    PORTUGUESE = 15,
    TURKISH = 16,
}

export enum LanguageType {
    ENGLISH = "english",
    SPANISH = "spanish",
    FRENCH = "french",
    GERMAN = "german",
    CHINESE = "chinese",
    DUTCH = "dutch",
    GREEK = "greek",
    ITALIAN = "italian",
    KOREAN = "korean",
    JAPANESE = "japanese",
    RUSSIAN = "russian",
    PORTUGUESE = "portuguese",
    TURKISH = "turkish",
}

export const allLanguages = [
    LanguageType.CHINESE,
    LanguageType.DUTCH,
    LanguageType.ENGLISH,
    LanguageType.FRENCH,
    LanguageType.GERMAN,
    LanguageType.GREEK,
    LanguageType.ITALIAN,
    LanguageType.JAPANESE,
    LanguageType.KOREAN,
    LanguageType.PORTUGUESE,
    LanguageType.RUSSIAN,
    LanguageType.SPANISH,
    LanguageType.TURKISH,
];

export const localizedLanguages = [
    LanguageType.ENGLISH,
    LanguageType.CHINESE,
    LanguageType.DUTCH,
    LanguageType.FRENCH,
    LanguageType.GERMAN,
    LanguageType.GREEK,
    LanguageType.ITALIAN,
    LanguageType.JAPANESE,
    LanguageType.KOREAN,
    LanguageType.PORTUGUESE,
    LanguageType.RUSSIAN,
    LanguageType.SPANISH,
    LanguageType.TURKISH,
];

export enum AudioClipVoice {
    ENGLISH_US = "usenglish",
    ENGLISH_BRITISH = "ukenglish",
    ENGLISH_AUSTRALIAN = "auenglish",
    CHINESE = "chchinese",
    DUTCH = "eurdutch",
    FRENCH = "eurfrench",
    GERMAN = "eurgerman",
    GREEK = "eurgreek",
    ITALIAN = "euritalian",
    KOREAN = "krkorean",
    JAPANESE = "jpjapanese",
    RUSSIAN = "rurussian",
    SPANISH = "usspanish",
    SPANISH_EUROPEAN = "eurspanish",
    PORTUGUESE = "brportuguese",
    TURKISH = "eurturkish",
    OTHER = "",
}

export const allAudioClipVoices = [
    AudioClipVoice.ENGLISH_US,
    AudioClipVoice.ENGLISH_BRITISH,
    AudioClipVoice.ENGLISH_AUSTRALIAN,
    AudioClipVoice.CHINESE,
    AudioClipVoice.DUTCH,
    AudioClipVoice.FRENCH,
    AudioClipVoice.GERMAN,
    AudioClipVoice.GREEK,
    AudioClipVoice.ITALIAN,
    AudioClipVoice.JAPANESE,
    AudioClipVoice.KOREAN,
    AudioClipVoice.RUSSIAN,
    AudioClipVoice.SPANISH,
    AudioClipVoice.SPANISH_EUROPEAN,
    AudioClipVoice.PORTUGUESE,
    AudioClipVoice.TURKISH,
];

export interface GoogleLanguageAndVoice {
    languageCode: string;
    voice: string;
    gender: string;
}

export class Languages {
    public static audioClipVoiceToGoogleLanguageAndVoice(language: AudioClipVoice): GoogleLanguageAndVoice {
        let returnValue: GoogleLanguageAndVoice = { languageCode: "en-US", voice: "en-US-Wavenet-F", gender: "FEMALE" };
        switch (language) {
            case AudioClipVoice.ENGLISH_US:
                returnValue.languageCode = "en-US";
                returnValue.voice = "en-US-Wavenet-F";
                break;
            case AudioClipVoice.ENGLISH_BRITISH:
                returnValue.languageCode = "en-GB";
                returnValue.voice = "en-GB-Wavenet-A";
                break;
            case AudioClipVoice.ENGLISH_AUSTRALIAN:
                returnValue.languageCode = "en-US";
                returnValue.voice = "en-US-Wavenet-F";
                break;
            case AudioClipVoice.DUTCH:
                returnValue.languageCode = "en-AU";
                returnValue.voice = "en-AU-Wavenet-A";
                break;
            case AudioClipVoice.CHINESE:
                returnValue.languageCode = "cmn-CN";
                returnValue.voice = "cmn-CN-Wavenet-A";
                break;
            case AudioClipVoice.FRENCH:
                returnValue.languageCode = "fr-FR";
                returnValue.voice = "fr-FR-Wavenet-C";
                break;
            case AudioClipVoice.GERMAN:
                returnValue.languageCode = "de-DE";
                returnValue.voice = "de-DE-Wavenet-C";
                break;
            case AudioClipVoice.GREEK:
                returnValue.languageCode = "el-GR";
                returnValue.voice = "el-GR-Wavenet-A";
                break;
            case AudioClipVoice.ITALIAN:
                returnValue.languageCode = "it-IT";
                returnValue.voice = "it-IT-Wavenet-B";
                break;
            case AudioClipVoice.KOREAN:
                returnValue.languageCode = "ko-KR";
                returnValue.voice = "ko-KR-Wavenet-A";
                break;
            case AudioClipVoice.JAPANESE:
                returnValue.languageCode = "ja-JP";
                returnValue.voice = "ja-JP-Wavenet-B";
                break;
            case AudioClipVoice.RUSSIAN:
                returnValue.languageCode = "ru-RU";
                returnValue.voice = "ru-RU-Wavenet-C";
                break;
            case AudioClipVoice.SPANISH:
                returnValue.languageCode = "es-ES";
                returnValue.voice = "es-ES-Standard-A";
                break;
            case AudioClipVoice.SPANISH_EUROPEAN:
                returnValue.languageCode = "es-ES";
                returnValue.voice = "es-ES-Standard-A";
                break;
            case AudioClipVoice.PORTUGUESE:
                returnValue.languageCode = "en-US";
                returnValue.voice = "es-ES-Standard-A";
                break;
            case AudioClipVoice.TURKISH:
                returnValue.languageCode = "tr-TR";
                returnValue.voice = "tr-TR-Wavenet-C";
                break;
            case AudioClipVoice.OTHER:
                returnValue.languageCode = "en-US";
                returnValue.voice = "en-US-Wavenet-F";
                break;
            default:
                break;
        }
        return returnValue;
    }

    public static getLanguageString(language: AudioClipVoice, gender: string = "female"): string {
        return language === AudioClipVoice.OTHER ? "" : language + gender;
    }

    public static getFriendlyString(language: AudioClipVoice): string {
        var languageString: string = "";
        switch (language) {
            case AudioClipVoice.ENGLISH_US:
                languageString = "English (US)";
                break;
            case AudioClipVoice.ENGLISH_BRITISH:
                languageString = "English (British)";
                break;
            case AudioClipVoice.ENGLISH_AUSTRALIAN:
                languageString = "English (Australian)";
                break;
            case AudioClipVoice.DUTCH:
                languageString = "Dutch";
                break;
            case AudioClipVoice.CHINESE:
                languageString = "Chinese";
                break;
            case AudioClipVoice.FRENCH:
                languageString = "French";
                break;
            case AudioClipVoice.GERMAN:
                languageString = "German";
                break;
            case AudioClipVoice.GREEK:
                languageString = "Greek";
                break;
            case AudioClipVoice.ITALIAN:
                languageString = "Italian";
                break;
            case AudioClipVoice.KOREAN:
                languageString = "Korean";
                break;
            case AudioClipVoice.JAPANESE:
                languageString = "Japanese";
                break;
            case AudioClipVoice.RUSSIAN:
                languageString = "Russian";
                break;
            case AudioClipVoice.SPANISH:
                languageString = "Spanish";
                break;
            case AudioClipVoice.SPANISH_EUROPEAN:
                languageString = "Spanish (European)";
                break;
            case AudioClipVoice.PORTUGUESE:
                languageString = "Portuguese";
                break;
            case AudioClipVoice.TURKISH:
                languageString = "Turkish";
                break;
            case AudioClipVoice.OTHER:
                languageString = "No Audio";
                return languageString;
            default:
                return "No Audio";
        }

        return languageString;
    }

    public static getLanguageFriendlyString(language: LanguageType): string {
        var languageString: string = "";
        switch (language) {
            case LanguageType.ENGLISH:
                languageString = "English";
                break;
            case LanguageType.DUTCH:
                languageString = "Dutch";
                break;
            case LanguageType.CHINESE:
                languageString = "Chinese";
                break;
            case LanguageType.FRENCH:
                languageString = "French";
                break;
            case LanguageType.GERMAN:
                languageString = "German";
                break;
            case LanguageType.GREEK:
                languageString = "Greek";
                break;
            case LanguageType.ITALIAN:
                languageString = "Italian";
                break;
            case LanguageType.KOREAN:
                languageString = "Korean";
                break;
            case LanguageType.JAPANESE:
                languageString = "Japanese";
                break;
            case LanguageType.RUSSIAN:
                languageString = "Russian";
                break;
            case LanguageType.SPANISH:
                languageString = "Spanish";
                break;
            case LanguageType.PORTUGUESE:
                languageString = "Portuguese";
                break;
            case LanguageType.TURKISH:
                languageString = "Turkish";
                break;
            default:
                return "No Audio";
        }

        return languageString;
    }

    public static languageNumToType(languageNum: any) {
        languageNum = parseInt(languageNum);
        switch (languageNum) {
            case LanguageNum.ENGLISH_US:
                return AudioClipVoice.ENGLISH_US;

            case LanguageNum.ENGLISH_BRITISH:
                return AudioClipVoice.ENGLISH_BRITISH;

            case LanguageNum.ENGLISH_AUSTRALIAN:
                return AudioClipVoice.ENGLISH_AUSTRALIAN;

            case LanguageNum.CHINESE:
                return AudioClipVoice.CHINESE;

            case LanguageNum.DUTCH:
                return AudioClipVoice.DUTCH;

            case LanguageNum.FRENCH:
                return AudioClipVoice.FRENCH;

            case LanguageNum.GERMAN:
                return AudioClipVoice.GERMAN;

            case LanguageNum.GREEK:
                return AudioClipVoice.GREEK;

            case LanguageNum.ITALIAN:
                return AudioClipVoice.ITALIAN;

            case LanguageNum.JAPANESE:
                return AudioClipVoice.JAPANESE;

            case LanguageNum.KOREAN:
                return AudioClipVoice.KOREAN;

            case LanguageNum.RUSSIAN:
                return AudioClipVoice.RUSSIAN;

            case LanguageNum.PORTUGUESE:
                return AudioClipVoice.PORTUGUESE;

            case LanguageNum.TURKISH:
                return AudioClipVoice.TURKISH;

            case LanguageNum.SPANISH:
                return AudioClipVoice.SPANISH;

            case LanguageNum.SPANISH_EUROPEAN:
                return AudioClipVoice.SPANISH_EUROPEAN;

            case LanguageNum.OTHER:
                return AudioClipVoice.OTHER;

            default:
                return languageNum;
        }
    }

    public static languageTypeToVoice(languageType: LanguageType) {
        switch (languageType) {
            case LanguageType.ENGLISH:
                return AudioClipVoice.ENGLISH_US;

            case LanguageType.CHINESE:
                return AudioClipVoice.CHINESE;

            case LanguageType.DUTCH:
                return AudioClipVoice.DUTCH;

            case LanguageType.FRENCH:
                return AudioClipVoice.FRENCH;

            case LanguageType.GERMAN:
                return AudioClipVoice.GERMAN;

            case LanguageType.GREEK:
                return AudioClipVoice.GREEK;

            case LanguageType.ITALIAN:
                return AudioClipVoice.ITALIAN;

            case LanguageType.JAPANESE:
                return AudioClipVoice.JAPANESE;

            case LanguageType.KOREAN:
                return AudioClipVoice.KOREAN;

            case LanguageType.RUSSIAN:
                return AudioClipVoice.RUSSIAN;

            case LanguageType.PORTUGUESE:
                return AudioClipVoice.PORTUGUESE;

            case LanguageType.TURKISH:
                return AudioClipVoice.TURKISH;

            case LanguageType.SPANISH:
                return AudioClipVoice.SPANISH_EUROPEAN;

            default:
                return languageType;
        }
    }

    public static languageTypeToCode(languageType: LanguageType) {
        switch (languageType) {
            case LanguageType.ENGLISH:
                return "en";

            case LanguageType.CHINESE:
                return "zh-CN";

            case LanguageType.DUTCH:
                return "nl";

            case LanguageType.FRENCH:
                return "fr";

            case LanguageType.GERMAN:
                return "de";

            case LanguageType.GREEK:
                return "el";

            case LanguageType.ITALIAN:
                return "it";

            case LanguageType.JAPANESE:
                return "ja";

            case LanguageType.KOREAN:
                return "ko";

            case LanguageType.RUSSIAN:
                return "ru";

            case LanguageType.PORTUGUESE:
                return "pt";

            case LanguageType.TURKISH:
                return "tr";

            case LanguageType.SPANISH:
                return "es";

            default:
                return languageType;
        }
    }

    public static voiceToLanguageType(voice: AudioClipVoice) {
        switch (voice) {
            case AudioClipVoice.ENGLISH_US:
                return LanguageType.ENGLISH;

            case AudioClipVoice.ENGLISH_BRITISH:
                return LanguageType.ENGLISH;

            case AudioClipVoice.ENGLISH_AUSTRALIAN:
                return LanguageType.ENGLISH;

            case AudioClipVoice.CHINESE:
                return LanguageType.CHINESE;

            case AudioClipVoice.DUTCH:
                return LanguageType.DUTCH;

            case AudioClipVoice.FRENCH:
                return LanguageType.FRENCH;

            case AudioClipVoice.GERMAN:
                return LanguageType.GERMAN;

            case AudioClipVoice.GREEK:
                return LanguageType.GREEK;

            case AudioClipVoice.ITALIAN:
                return LanguageType.ITALIAN;

            case AudioClipVoice.JAPANESE:
                return LanguageType.JAPANESE;

            case AudioClipVoice.KOREAN:
                return LanguageType.KOREAN;

            case AudioClipVoice.RUSSIAN:
                return LanguageType.RUSSIAN;

            case AudioClipVoice.PORTUGUESE:
                return LanguageType.PORTUGUESE;

            case AudioClipVoice.TURKISH:
                return LanguageType.TURKISH;

            case AudioClipVoice.SPANISH:
                return LanguageType.SPANISH;

            case AudioClipVoice.SPANISH_EUROPEAN:
                return LanguageType.SPANISH;

            default:
                return undefined;
        }
    }

    public static languageTypeToNum(language: AudioClipVoice) {
        switch (language) {
            case AudioClipVoice.ENGLISH_US:
                return LanguageNum.ENGLISH_US;

            case AudioClipVoice.ENGLISH_BRITISH:
                return LanguageNum.ENGLISH_BRITISH;

            case AudioClipVoice.ENGLISH_BRITISH:
                return LanguageNum.ENGLISH_BRITISH;

            case AudioClipVoice.CHINESE:
                return LanguageNum.CHINESE;

            case AudioClipVoice.DUTCH:
                return LanguageNum.DUTCH;

            case AudioClipVoice.FRENCH:
                return LanguageNum.FRENCH;

            case AudioClipVoice.GERMAN:
                return LanguageNum.GERMAN;

            case AudioClipVoice.GREEK:
                return LanguageNum.GREEK;

            case AudioClipVoice.ITALIAN:
                return LanguageNum.ITALIAN;

            case AudioClipVoice.JAPANESE:
                return LanguageNum.JAPANESE;

            case AudioClipVoice.KOREAN:
                return LanguageNum.KOREAN;

            case AudioClipVoice.RUSSIAN:
                return LanguageNum.RUSSIAN;

            case AudioClipVoice.PORTUGUESE:
                return LanguageNum.PORTUGUESE;

            case AudioClipVoice.TURKISH:
                return LanguageNum.TURKISH;

            case AudioClipVoice.SPANISH:
                return LanguageNum.SPANISH;

            case AudioClipVoice.SPANISH_EUROPEAN:
                return LanguageNum.SPANISH_EUROPEAN;

            default:
                return AudioClipVoice.OTHER;
        }
    }

    public static imageForLanguage(language: LanguageType) {
        switch (language) {
            case LanguageType.ENGLISH:
                return require("../assets/images/languages/english.png");

            case LanguageType.CHINESE:
                return require("../assets/images/languages/chinese.png");

            case LanguageType.DUTCH:
                return require("../assets/images/languages/dutch.png");

            case LanguageType.FRENCH:
                return require("../assets/images/languages/french.png");

            case LanguageType.GERMAN:
                return require("../assets/images/languages/german.png");

            case LanguageType.GREEK:
                return require("../assets/images/languages/greek.png");

            case LanguageType.ITALIAN:
                return require("../assets/images/languages/italian.png");

            case LanguageType.JAPANESE:
                return require("../assets/images/languages/japanese.png");

            case LanguageType.KOREAN:
                return require("../assets/images/languages/korean.png");

            case LanguageType.RUSSIAN:
                return require("../assets/images/languages/russian.png");

            case LanguageType.PORTUGUESE:
                return require("../assets/images/languages/portuguese.png");

            case LanguageType.TURKISH:
                return require("../assets/images/languages/turkish.png");

            case LanguageType.SPANISH:
                return require("../assets/images/languages/spanish.png");

            default:
                return "";
        }
    }

    public static imageForVoice(voice: AudioClipVoice) {
        switch (voice) {
            case AudioClipVoice.ENGLISH_US:
                return require("../assets/images/flags/usenglish.png");

            case AudioClipVoice.ENGLISH_BRITISH:
                return require("../assets/images/flags/ukenglish.png");

            case AudioClipVoice.ENGLISH_AUSTRALIAN:
                return require("../assets/images/flags/auenglish.png");

            case AudioClipVoice.SPANISH:
                return require("../assets/images/flags/usspanish.png");

            case AudioClipVoice.SPANISH_EUROPEAN:
                return require("../assets/images/flags/eurspanish.png");

            case AudioClipVoice.CHINESE:
            case AudioClipVoice.DUTCH:
            case AudioClipVoice.FRENCH:
            case AudioClipVoice.GERMAN:
            case AudioClipVoice.GREEK:
            case AudioClipVoice.ITALIAN:
            case AudioClipVoice.JAPANESE:
            case AudioClipVoice.KOREAN:
            case AudioClipVoice.RUSSIAN:
            case AudioClipVoice.PORTUGUESE:
            case AudioClipVoice.TURKISH:
                let language = Languages.voiceToLanguageType(voice);
                return Languages.imageForLanguage(language);

            default:
                return "";
        }
    }
}
