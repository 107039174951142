import * as React from "react";

// Material UI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";

// Components
import SimpleMenu from "../common/SimpleMenu";

// Models
import { QuelledMessageType, StudentList } from "../../models";

// Utils
import { strings } from "../../core/localizedStrings";
import { Colors } from "../../core/utils/Colors";
import { RootStore } from "stores";
import { Button, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import * as Icons from "core/utils/Icons";
import * as DeviceSize from "core/utils/DeviceSize";
import ActivitySupport from "core/utils/ActivitySupport";
import { ConfirmationMessage } from "components/MessageBoxDialogs";
import { StringUtils } from "core/utils/StringUtils";
import { RecentClassList } from "stores/ActivityStore";

export interface RecentStudentListsMenuProps extends React.Props<any> {
    rootStore: RootStore;
    currentClassList: StudentList;
    recentClassLists: RecentClassList[];
}

const RecentStudentListsMenu = (props: RecentStudentListsMenuProps) => {
    const onClassListOptionChanged = (classList: StudentList) => {
        props.rootStore.activityStore.setCurrentStudentList(classList.key);
    };

    let displayComponent = (
        <Grid container alignItems="center">
            <Grid item>
                <DeviceSize.Desktop>
                    <BigScreenButton
                        title={StringUtils.limitStringToChars(
                            props.currentClassList && props.currentClassList.key !== undefined
                                ? props.currentClassList.title
                                : strings.studentList,
                            25
                        )}
                    />
                </DeviceSize.Desktop>
                <DeviceSize.Tablet>
                    <SmallScreenButton />
                </DeviceSize.Tablet>
            </Grid>
        </Grid>
    );

    return (
        <SimpleMenu displayComponent={displayComponent}>
            <RecentStudentListsOptions
                currentClassList={props.currentClassList}
                recentClassLists={props.recentClassLists}
                rootStore={props.rootStore}
                onClassListOptionChanged={onClassListOptionChanged}
            />
        </SimpleMenu>
    );
};
export default RecentStudentListsMenu;

interface RecentStudentListsOptionsProps {
    rootStore: RootStore;
    currentClassList: StudentList;
    recentClassLists: RecentClassList[];
    onClassListOptionChanged: (StudentList) => void;
}

const RecentStudentListsOptions = (props: RecentStudentListsOptionsProps) => {
    const handleStudentListCleared = () => {
        let { rootStore } = props;
        let { activityStore, authStore, userStore } = rootStore;
        let messageType = QuelledMessageType.ChangeClassList;
        if (
            activityStore.didGameEnd ||
            userStore.user.isMessageQuelled(messageType) ||
            !authStore.isSignedIn ||
            !activityStore.currentStudentList ||
            !ActivitySupport.requiresFlash(activityStore.currentActivity)
        ) {
            activityStore.setCurrentStudentList(undefined);
        } else {
            let message = new ConfirmationMessage(
                strings.confirmChangeClassList,
                strings.quellMessage,
                (result, shouldQuell) => {
                    if (shouldQuell) {
                        userStore.quellMessage(messageType);
                    }
                    if (result === "yes") {
                        activityStore.setCurrentStudentList(undefined);
                    }
                }
            );
            rootStore.showConfirmationMessage(message);
        }
    };

    return (
        <>
            <List
                component="nav"
                subheader={
                    props.recentClassLists.length > 0 ? (
                        <ListSubheader style={{ marginTop: -10, marginBottom: -20 }}>{strings.recent}</ListSubheader>
                    ) : null
                }
            />
            {props.recentClassLists ? (
                props.recentClassLists.map((classList: RecentClassList, index) => (
                    <ListItem
                        key={classList.key}
                        button={true}
                        onClick={props.onClassListOptionChanged.bind(this, classList)}
                    >
                        <ListItemText primary={classList.title} />
                    </ListItem>
                ))
            ) : (
                <ListItem button={true} onClick={props.onClassListOptionChanged.bind(this, undefined)}>
                    <ListItemText primary={strings.edit} />
                </ListItem>
            )}
            {props.recentClassLists.length > 0 ? <Divider /> : null}
            <ListItem
                button={true}
                onClick={() => {
                    props.rootStore.activityStore.shouldShowStudentListSelector = true;
                }}
            >
                <ListItemText primary={<span style={{ color: Colors.lightBlue }}>{strings.browseAllLists}</span>} />
            </ListItem>
            {props.currentClassList && props.currentClassList.students.length > 0 && (
                <ListItem button={true} onClick={handleStudentListCleared}>
                    <Typography style={{ color: Colors.red }}>{strings.clear}</Typography>
                </ListItem>
            )}
        </>
    );
};

const SmallScreenButton = () => (
    <Tooltip title={strings.studentList} placement="bottom">
        <IconButton style={{ padding: 2 }} size="medium">
            <Icons.StudentLists
                style={{
                    color: Colors.lightBlue,
                    width: 30,
                    height: 30,
                }}
            />
        </IconButton>
    </Tooltip>
);

const BigScreenButton = (props: any) => (
    <Button style={{ padding: "5px 10px 5px 10px" }}>
        <Icons.StudentLists
            style={{
                color: Colors.lightBlue,
                width: 30,
                height: 30,
            }}
        />
        {props.title}
    </Button>
);
