/* tslint:disable */
export var russian = {
    absent: "Нет на месте",
    absentStudentsFormat: "{0} отсутствует",
    accountRegistration: "Регистрация аккаунта",
    active: "активный",
    activityPreview: "Предварительный просмотр активности",
    activityPreviewMessage: "Пожалуйста, войдите или зарегистрируйтесь для бесплатной учетной записи.",
    add: "добавлять",
    addAccount: "Добавить аккаунт",
    addCard: "Добавить карту",
    addCards: "Добавить карты",
    addFolder: "Добавить папку",
    addGroupStudentAccount: "Добавить учетную запись студента группы",
    addKeyword: "Добавить ключевое слово",
    addName: "Добавить имя",
    addTeacherAccount: "Добавить учительский аккаунт",
    addToFavorites: "Добавить в избранное",
    addWithPlus: "+ Добавить",
    additionalResources: "Дополнительные ресурсы",
    admin: "Администратор",
    affirmative: "утвердительный",
    afterTrial:
        "После 30-дневной пробной версии вы можете продолжать использовать сайт в качестве основного участника, или обновить до Premium.",
    all: "Все",
    allFieldsRequired: "Все поля обязательны для заполнения.",
    allowCardsToBeFlipped: "Разрешить переворачивать карты",
    allowedToChangePassword: "учитель может сменить пароль",
    annual: "годовой",
    annualSubscription: "Годовая подписка",
    answer: "Ответ",
    audioClipLanguageLabel: "Язык аудиоклипов",
    backToWorksheets: "Вернуться к рабочим листам",
    basic: "основной",
    basicMemberCredits:
        "* Базовые участники получают 8 ежемесячных кредитов. После того, как все кредиты были использованы, доступ к сайту будет ограничен до первого дня следующего месяца.",
    billedAnnually: "Ежегодный счет",
    billedAnnuallyFormat: "Ежегодно оплачивается ({0})",
    billedMonthly: "Ежемесячно",
    billedMonthlyFormat: "Ежемесячный счет ({0})",
    billingFrequency: "Частота выставления счетов",
    boy: "мальчик",
    boys: "мальчиков",
    browseAllCategories: "Просмотр / редактирование категорий",
    browseAllLists: "Просмотр / редактирование списков",
    cancel: "отменить",
    cancelSubscription: "Отменить подписку",
    cannotAddManagedAccountsInTrial: "Управляемые учетные записи не могут быть добавлены в течение пробного периода.",
    cannotBeEmpty: "Не может быть пустым.",
    card: "Карта",
    cardLower: "карта",
    cardsLower: "карты",
    category: "категория",
    category2: "Категория2",
    categoryCharactersNotSupported: "Эта категория содержит символы, которые еще не поддерживаются этим действием.",
    categoryTypeNotSupported: "Этот тип категории еще не поддерживается этой категорией.",
    changeCategory: "Изменить категорию",
    changeEditStudents: "Изменить / Редактировать студентов",
    changePassword: "Изменить пароль",
    checkEmailForVerifcation:
        "Пожалуйста, проверьте свою электронную почту, чтобы завершить активацию своей учетной записи.",
    chinese: "китайский язык",
    className: "Имя класса",
    clear: "Очистить",
    clearFilters: "Сбросить фильтры",
    clearSelectedImage: "Очистить выбранное изображение",
    clearSelectedImages: "Очистить выбранные изображения",
    clickHereTo: "Нет аккаунта? Нажмите здесь, чтобы",
    confirmChangeCategory: "Изменение категории приведет к сбросу активности. Вы уверены, что хотите изменить это?",
    confirmChangeClassList:
        "Изменение списка студентов приведет к сбросу активности. Вы уверены, что хотите изменить это?",
    confirmChangeTeamNumber:
        "Изменение количества команд приведет к сбросу активности. Вы уверены, что хотите изменить это?",
    confirmRestartSubscription:
        "Вы уверены, что хотите возобновить подписку? Ваша кредитная карта не будет списана до {0}.",
    contact: "контакт",
    contactAdminMessage: " Если проблема не устранена, обратитесь к администратору сайта.",
    contactDescription:
        "Пожалуйста, дайте нам знать, какие у вас есть вопросы или проблемы. Просто отправьте сообщение ниже, и мы свяжемся с вами как можно скорее.",
    contactFormSubmitted: "Спасибо! Ваше сообщение отправлено.",
    copy: "копия",
    copyLower: "копия",
    createCardFromSelectedImage: "Создать открытку из выбранного изображения",
    createCardsFromSelectedImages: "Создать карты из {0} выбранных изображений",
    createCopyIn: "Создать копию в:",
    createCustomLists: "Создание пользовательских списков",
    createNewList: "Создать новый список",
    createSelectCategory: "Создать или выбрать урок",
    createSelectCategoryDescription:
        "Создайте пользовательский урок за считанные секунды или выполните поиск среди тысяч существующих.",
    createSelectClassList: "Создать или выбрать список студентов",
    createStudentNameLists: "Создать списки имен учеников",
    createTeams: "Создавать команды",
    createdBy: "Создано",
    createdOn: "Создано на",
    creditCard: "Кредитная карта",
    creditNeeded: "1 кредит за использование",
    currentSubscriptionRequiredForManagedAccounts:
        "Активная подписка обязательна. Пожалуйста, перезапустите свой индекс, чтобы добавить дополнительные учетные записи.",
    customizeHeader: "{{Customize}} для любого урока",
    customizeText:
        "Возьмите простой словарный список или список вопросов и превратите его в увлекательный активность в секундах. После создания списка его можно использовать с любым действием на сайте.",
    dataBeingMigratedFromBarryFunEnglish:
        "Данные вашей учетной записи переносятся с BarryFunEnglish. Это нужно сделать только один раз. В зависимости от объема данных, это может занять от\n        От нескольких секунд до нескольких часов. Если вы не видите все свои данные через несколько часов, пожалуйста, свяжитесь с\n        администратор сайта.",
    delete: "удалять",
    deleteCategoryConfirmation: "Вы уверены, что хотите удалить эту категорию навсегда?",
    deleteFavoriteConfirmation: "Вы уверены, что хотите удалить это из избранного?",
    deleteFolderConfirmation: "Вы уверены, что хотите навсегда удалить эту папку и все ее содержимое?",
    deleteStudentListConfirmation: "Вы уверены, что хотите удалить этот список?",
    discount: "Скидка ({0}%):",
    discounts: "Скидки:",
    done: "Готово",
    dutch: "Голландский",
    edit: "редактировать",
    editProfile: "Редактировать профиль",
    email: "Эл. адрес",
    emailAlreadyRegistered: "Адрес электронной почты уже зарегистрирован",
    emailOrUsername: "Электронная почта или имя пользователя",
    emailRequiredError: "Имя пользователя требуется.",
    emailSentTo: "Письмо отправлено на {0}.",
    endGame: "Конец игры",
    english: "английский",
    enterEmailUsedToRegister:
        "Пожалуйста, введите адрес электронной почты, который вы использовали для регистрации на сайте:",
    enterIncorrectAnswers: "Введите мои собственные неправильные ответы",
    enterQuestion: "Введите вопрос",
    enterText: "Введите текст:",
    enterValidEmail: "Пожалуйста, введите действительный адрес электронной почты.",
    enterVocabAndQuestion: "Введите словарный запас слово и вопрос",
    enterVocabulary: "Введите словарь",
    existingAccount: " существующий аккаунт",
    expirationDateFormat: "Дата истечения срока действия: {0}",
    expired: "Истекший",
    expires: "Истекает",
    expressionInputTextField1Label: "Текстовое поле 1 (вопрос)",
    expressionInputTextField2Label: "Текстовое поле 2 (ответ)",
    favorites: "Избранные",
    flashcards: "Флэшкарточки",
    folderName: "Имя папки:",
    forClassroomHeader: "Предназначен для {{classroom}}",
    forClassroomText:
        "Игры и мероприятия предназначены для использования в классе и отлично работают с большими или маленькими классами. В играх может быть от двух до пяти команд, и для произвольного выбора можно вводить списки имен учеников.",
    forgotPassword: "Забыли пароль?",
    free: "Свободно",
    french: "Французский",
    games: "Игры",
    gender: "Пол",
    german: "Немецкий",
    girl: "девушка",
    girls: "девочки",
    girlsVsBoys: "Девочки против Мальчиков",
    greek: "греческий",
    groupNumberFormat: "Группа {0}",
    homePageContactMessage: "Есть {{вопросы}} для нас?",
    imageOverlayGraphic: "Графика с наложением изображений",
    imageWithText: "Изображение с одним текстовым полем",
    imageWithTextDescription:
        "Эти списки отлично подходят для отработки словарного запаса, чтения и разговорной речи. Они работают практически с любой активностью на сайте.",
    imageWithTwoText: "Изображение с двумя текстовыми полями",
    imageWithTwoTextDescription:
        "Эти списки используются для вопросов об изображении или для перевода между двумя языками",
    imagesSelectorInstructions: "Выберите или загрузите изображения для создания карт из:",
    interactive: "интерактивный",
    interactiveFlashcards: "Интерактивные карточки",
    introduce: "Вводить",
    invalidEmail: "Неверный адрес электронной почты.",
    invalidPassword: "Пароли должны быть длиной не менее 6 символов.",
    invalidUsername: "Неверное имя пользователя.",
    invalidUsernameOrPassword: "Вы ввели неверное имя пользователя или пароль",
    italian: "итальянский",
    japanese: "японский язык",
    keywordsLabel: "Ключевые слова",
    korean: "корейский язык",
    language: "язык",
    languageTitle: "язык",
    learn: "Учить",
    learnDescription: "Введите новый урок или обзор с помощью интерактивных или печатных флэш-карт.",
    lesson: "Урок",
    lessonDescription: "Сочетание одного изображения и изображения с двумя текстовыми полями",
    lessonTypeSelectorTitle: "Хотели бы вы попрактиковаться в лексике или задать вопросы из этого урока?",
    listAccessibility: "Доступность списка",
    listTitleLabel: "Заголовок списка",
    listTypeTitle: "Тип списка",
    loggingIn: "Вход в систему",
    loginToCopy: "Вы должны войти в систему, чтобы копировать списки.",
    loginToCreateFolders: "Вы должны войти в систему для создания папок.",
    loginToCreateLists: "Вы должны войти в систему для создания пользовательских списков.",
    loginToEditLists: "Вы должны войти в систему для редактирования списков.",
    loginToFavorite: "Вы должны войти в систему, чтобы сохранить избранное.",
    logout: "Выйти",
    mainPageText: "Индивидуальные занятия по изучению языка для любого урока",
    managedAccounts: "Управляемые счета",
    managedAccountsDescription:
        "Добавьте управляемые учетные записи, если вы хотите, чтобы в одной подписке было несколько участников.\n        Скидки предоставляются в зависимости от общего количества счетов.",
    maxFilesUploadAttempt: "Вы можете загружать не более {0} файлов одновременно.",
    membershipTypes: "Типы членства",
    message: "Сообщение",
    misses: "Пропускает",
    monthly: "ежемесячно",
    monthlySubscription: "Месячная подписка",
    move: "Переехать",
    moveFolderTo: "Переместить папку в:",
    moveListTo: "Переместить список в:",
    multipleChoiceInstructions:
        "Что вы хотите использовать для неправильных ответов в случае с несколькими вариантами ответов?",
    multipleLanguageSupport: "Поддерживается несколько языков",
    mustHaveOneIncorrectAnswers: "Вы должны ввести хотя бы один неверный ответ.",
    myAccount: "Мой аккаунт",
    myClasses: "Мои занятия",
    myImages: "Мои изображения",
    myLists: "Мои списки",
    name: "название",
    negative: "отрицательный",
    newFolder: "Новая папка",
    nextBillingDate: "Дата следующего выставления счета: {0} ({1})",
    no: "нет",
    noAcceptableFilesFound: "Действительные файлы не найдены. Максимальный размер файлов: {0} МБ.",
    noCardOnFile: "Нет карты в файле",
    noCreditCardRequired: "Кредитная карта не требуется.",
    noRemainingCredits:
        "Нет оставшихся кредитов в этом месяце. Зарегистрируйте премиум аккаунт для неограниченного доступа.",
    none: "Никто",
    notActivated: "Не активирован",
    notActive: "Не активен",
    notChargedUntilFormat: "Кредитная карта не будет разделена до {0}.",
    notChargedUntilTrialComplete: "Ваша кредитная карта не будет снята до конца пробного периода.",
    numberOfTeams: "Количество команд",
    off: "от",
    ok: "Хорошо",
    on: "На",
    oneRemainingCredit: "У вас есть 1 оставшийся кредит в этом месяце.",
    options: "Опции",
    or: "или же",
    page: "страница",
    pageNotFound: "Страница не найдена",
    password: "пароль",
    passwordLengthError: "Пароль должен содержать не менее 6 символов.",
    passwordRequiredError: "необходим пароль",
    passwordResetEmailHasBeenSendMessage:
        "Пожалуйста, проверьте свою электронную почту на наличие ссылки для сброса пароля.",
    pay: "платить",
    payBalance: "Платежный баланс",
    paymentMethod: "Способ оплаты",
    pendingSubscription: "Подписка в ожидании",
    play: "Играть",
    playDescription: "Захватывающие игры для больших и маленьких классов",
    pleaseAddCardForRestart: "Вы должны добавить кредитную карту, прежде чем возобновить подписку.",
    portuguese: "португальский",
    practice: "практика",
    practiceDescription: "Интерактивные и печатные листы",
    premium: "премия",
    premiumPriceFormatMonth: "$ 0} {/ месяц",
    premiumPriceFormatYear: "$ {0} / год",
    premiumTrial: "Премиум (пробная версия)",
    preview: "предварительный просмотр",
    price: "Цена",
    print: "Распечатать",
    printDescription: "Печать карточек или рабочих листов для практики в автономном режиме",
    printable: "Версия для печати",
    printableFlashcards: "Карточки для печати",
    private: "Частный",
    problemSubmittingContactForm: "При отправке вашего сообщения произошла ошибка.",
    problemUpdatingUserErrorMessage: "Произошла ошибка при обновлении данных пользователя.",
    problemUsingCredit: "При использовании ваших кредитов возникла проблема.",
    profile: "Профиль",
    proratedAmountDue: "Сумма к оплате сейчас: {0}",
    public: "общественного",
    publicLists: "Списки сообщества",
    quellMessage: "Не показывать это сообщение снова",
    question: "Вопрос",
    questions: "Вопросы",
    quiz: "викторина",
    randomize: "Перемешайте",
    randomizeAndReset: "Перемешать и сбросить",
    recent: "последний",
    register: "регистр",
    registerUsernamePassword: "Зарегистрируйтесь с именем пользователя / паролем",
    rejectedFileFormat:
        "Файл {0} не был загружен. Файл может быть слишком большим или неправильный тип файла. Принимаются только файлы изображений размером 500 КБ или меньше.",
    rejectedFilesFormat:
        "{0} файлы не были загружены. Файлы могут быть слишком большими или неправильный тип файла. Принимаются только файлы изображений размером 500 КБ или меньше.",
    remainingCreditsFormat: "У вас осталось {0} кредитов в этом месяце.",
    removeAllLetters: "Удалить все буквы",
    removeFromFavorites: "Удалить из Избранного",
    removeManagedUserAccountConfirmation:
        "Эта учетная запись будет удалена, и пользователь больше не будет разрешено войти Вы уверены, что хотите навсегда удалить эту учетную запись?",
    resendEmail: "Отправить письмо",
    reset: "Сброс",
    restartSubscription: "Перезапустить подписку",
    restartSubscriptionMessage: "Пожалуйста, выберите частоту выставления счетов для вашей подписки.",
    review: "Обзор",
    reviewDescription: "Многочисленные тесты для прослушивания, чтения, письма и разговорной речи",
    russian: "русский",
    sameAsTextField1Label: "То же, что текстовое поле 1",
    sampleClass: "Образец Класса",
    save: "Сохранить",
    search: "Поиск",
    select: "Выбрать...",
    selectACategory: "Выбрать категорию",
    selectCategoryBeforeActivity: "Вы должны выбрать категорию, прежде чем выбрать деятельность.",
    selectFolderToAddFavoriteTo: "Выберите папку для добавления в избранное:",
    selectStudentList: "Выберите список студентов",
    showCategoryDetails: "показать детали",
    signIn: "Авторизоваться",
    signInRegister: "Войти / Регистрация",
    signInUsernamePassword: "Войти с именем пользователя / паролем",
    signUpForFree: "Бесплатно зарегестрироваться",
    signUpForFreeMessage: "Зарегистрируйтесь сейчас на бесплатную Премиум аккаунт на 30 дней!",
    singleTeacher: "Один учитель",
    singleText: "Одно текстовое поле",
    singleTextDescription:
        "Эти списки отлично подходят для практики чтения и правописания. Они также полезно для действий, которые не требуют изображения.",
    siteImages: "Изображения сайта",
    siteLists: "Списки сайтов",
    sortByPopularity: "популярность",
    sortByRecentlyCreated: "Недавно созданный",
    sortByRelevance: "Актуальность",
    sortByTitle: "заглавие",
    sortByTypeTitle: "Сортировать по",
    spanish: "испанский",
    start: "Начните",
    startSubscription: "Начать подписку",
    startSubscriptionAfterTrial: "Начните подписку, чтобы продолжить подписку «Премиум» после испытание завершено.",
    studentGroup: "студенческая группа",
    studentGroups: "студенческие группы",
    studentList: "Список студентов",
    subject: "Предмет",
    submit: "Отправить",
    subscribe: "Подписывайся",
    subscription: "Подписка",
    subscriptionDescriptionFormat: "Тип подписки: {0}",
    subscriptionType: "Тип подписки",
    subtotal: "Промежуточный итог:",
    support: "Контактная поддержка",
    teacher: "учитель",
    teacherCountFormat: "Количество учителей: {0}",
    teachers: "учителей",
    teachingTools: "Инструменты обучения",
    teamNumberFormat: "Команда {0}",
    tenOrMore: "Десять или больше",
    textField1Label: "(Вопрос)",
    textField2Label: "(Ответ)",
    textToSpeechSupportFormat:
        "Аудиоклипы, созданные автоматически с использованием технологии преобразования текста в речь",
    thousandsOfHeader: "{{Тысячи}} категорий на выбор",
    thousandsOfText:
        "Не хотите создавать свои собственные категории? Просмотреть через тысячи созданных сообществом KinteractiveLearning. Больше добавляются каждый день!",
    threeOrMore: "Три или больше",
    titleCannotBeEmptyInfoMessage: "Название не может быть пустым. Пожалуйста, введите название.",
    tools: "инструменты",
    toolsDescription: "Создатели групп и команд, случайный выбор студентов, секундомер и табло",
    totalDue: "Итого к оплате:",
    totalManagedAccounts: "Всего управляемых аккаунтов:",
    totalStudentsFormat: "{0} студенты",
    turkish: "турецкий",
    twoTextOnly: "Два текстовых поля",
    twoTextOnlyDescription:
        "Эти списки используются для задания вопросов, которые не требуют и изображения, или для перевода между двумя языками",
    type: "Тип",
    unlimited: "неограниченный",
    update: "Обновить",
    updateEmailError: "Произошла ошибка при обновлении адреса электронной почты.",
    uploadImages: "загрузить изображения",
    uploadImagesDropzoneInstructions: "Удалите изображения или нажмите, чтобы просмотреть ваш компьютер.",
    uploadMessage: "Загрузка {0} изображений.",
    uploadProfileImageError: "Произошла ошибка при загрузке изображения профиля.",
    useAsVocabulary: "Добавить в словарный список",
    useCredit: "Использовать кредит",
    useOtherAnswersAsIncorrect: "Используйте другие карты правильных ответов",
    username: "имя пользователя",
    usernameOptional: "Имя пользователя (необязательно)",
    usernameRequiredError: "Имя пользователя требуется.",
    usernameTaken: "Имя пользователя уже используется",
    usernameUnavailable: "имя пользователя не доступно",
    vocabulary: "Запас слов",
    worksheetCreators: "Создатели рабочего листа",
    worksheets: "Worksheets",
    yes: "да",
    getStarted: "",
    requiresFlash: "",
    onlySupportsLatinBasedLanguages: "",
    upgradeToPremium: "",
    startReview: "",
    numberOfGroups: "",
    hideAnswer: "",
    autoPlayAudio: "",
    imageBank: "",
    doubleSidedCards: "",
    ignoreFlippedCardsForReview: "",
    soft: "",
    medium: "",
    strong: "",
    continuePlaying: "",
    languageNotSupported: "",
    createLink: "",
    pinActivityToDashboard: "",
    enterName: "",
    createStudentLink: "",
    expiration: "",
    oneDay: "",
    oneWeek: "",
    never: "",
    link: "",
    copyToClipboard: "",
    pinnedActivities: "",
    studentActivityLinks: "",
    numberOfCards: "",
    matchType: "",
    imageType: "",
    otherOptions: "",
    wordImage: "",
    imageImage: "",
    wordWord: "",
    activity: "",
    seeLinkVisitors: "",
    recentHistory: "",
    startTime: "",
    completedTime: "",
    results: "",
    iDontKnow: "",
    iKnowTheAnswer: "",
    seconds: "",
    minutes: "",
    hours: "",
    pause: "",
    set: "",
    audioHeading: "",
    playAudioOnCorrect: "",
    playAudioOnWrong: "",
    answerTypeHeading: "",
    multipleChoiceAnswerTypeOption: "",
    rightOrWrongAnswerTypeOption: "",
    showNumbersHeading: "",
    showNumbersMultipleChoice: "",
    timerHeading: "",
    showTimerMultipleChoice: "",
    game: "",
    correct: "",
    wrong: "",
    teams: "",
    imagesInGrid: "",
    multipleChoiceOptions: "",
    playAudioOnSelection: "",
    classicModeOptions: "",
    selectAll: "",
    flipCardsToFront: "",
    flipCardsToBack: "",
    shuffle: "",
    showAllCards: "",
    bonusRound: "",
    crazyCupsIntroMessage: "",
    crazyCupsBonusRoundMessage: "",
    goodJob: "",
    sorryYouMissedIt: "",
    teamReadyFormat: "",
    readyToTryAgain: "",
    cupsSpeedAdjustment: "",
    textOnly: "",
    imageText: "",
    imageOnly: "",
    hideShips: "",
    useClassicMode: "",
    classicModeDescription: "",
    stop: "",
    bullsEye: "",
    removeNameOnceSelected: "",
    clearDartAfterThrow: "",
    stopBoardAfterThrow: "",
    playSoundEffect: "",
    spinForPoints: "",
    showImageProfileImage: "",
    cardType: "",
    gridSize: "",
    cardsPerPage: "",
    noListsGetStarted: "",
    noImagesGetStarted: "",
    isTraceable: "",
    twoColumns: "",
    randomizeWorksheet: "",
    createPDF: "",
    fontSize: "",
    goodJobReadyForNext: "",
    whoGoesFirst: "",
    teamGoesFirstFormat: "",
    popBalloonsWith: "",
    dartMeterSpeed: "",
    fontStyle: "",
    cardSensitivity: "",
    points: "",
    hotOrColdInstructions: "",
    inviteUsers: "",
    availableInvitesFormat: "",
    numberOfAccountsToAdd: "",
    removeSubscriptionManagerConfirmation: "",
    managedSubscription: "",
    disconnectFromAccountManager: "",
    confirmToBeManagedConfirmationFormat: "",
    pendingManagerNotFound: "",
    steal: "",
    newWord: "",
    canYouSpell: "",
    wouldLikeToStealFormat: "",
    selectSecondCategory: "",
    clearSecondCategory: "",
    borderThickness: "",
    borderColor: "",
    subscriptionIsPastDue: "",
};
