export interface KeyboardLayoutLanguage {
    default: string[];
    shift?: string[];
}

export default class KeyboardLayout {
    static PUNCTUATION = ["'", '"', "?", ".", ",", "-", "!", "$", "%", "&", "*", "(", ")", ";", ":", " "];
    static ENGLISH: KeyboardLayoutLanguage = { default: ["a b c d e f g h i", "j k l m n o p q r", "s t u v w x y z"] };
    static allKeyboards = [KeyboardLayout.ENGLISH];

    static containsChar(char: string, layout: KeyboardLayoutLanguage) {
        for (var row of layout.default) {
            let rowChars = row.split(" ");
            if (rowChars.includes(char)) {
                return true;
            }
        }
        return false;
    }

    static containsAllChars(text: string, layout: KeyboardLayoutLanguage) {
        var chars = Array.from(text).map((char) => char.toLowerCase());
        for (var char of chars) {
            if (!this.containsChar(char, layout) && !this.PUNCTUATION.includes(char)) {
                return false;
            }
        }

        return true;
    }

    static anyKeyboardContainsAllChars(text: string) {
        for (let keyboardLayout of this.allKeyboards) {
            if (this.containsAllChars(text, keyboardLayout)) {
                return true;
            }
        }
        return false;
    }

    static totalChars(keyboard: string[]) {
        let count = 0;
        keyboard.forEach((row) => {
            row.split(" ").forEach((char) => {
                count += 1;
            });
        });
        return count;
    }

    static longestRow(keyboard: string[]) {
        let longest = 0;
        keyboard.forEach((row) => {
            if (row.length > longest) {
                longest = row.length;
            }
        });
        return (longest + 1) / 2;
    }
}
