import * as React from "react";

// Stores
import { ActivityStore } from "stores";

// Layout
import { Divider, Grid, TextField, Typography } from "@material-ui/core";
import SimpleRadio from "components/SimpleRadio";
import { Observer } from "mobx-react-lite";
import { strings } from "core/localizedStrings";
import {
    BorderSizeOption,
    CardTypeButtons,
    ColorPickerOption,
    FontSizeButtons,
    FontStyleButtons,
    worksheetOptionsBackgroundStyle,
} from "components/activities/CommonActivityOptions";
import { CardType } from "models/customLists/CategoryItem";
import { Fonts } from "core/utils/Fonts";
import * as Icons from "core/utils/Icons";
import { Colors } from "core/utils/Colors";

export enum FlashcardsPerPageOption {
    ONE = 1,
    TWO = 2,
    SIX = 6,
    TWELVE = 12,
    TWENTY_FOUR = 24,
}

export interface PrintableFlashcardsOptions {
    cardsPerPage: FlashcardsPerPageOption;
    fontStyle: string;
    cardType: CardType;
    fontSizeMultiplier: number;
    borderThickness: number;
    borderColor: string;
}

export const defaultPrintableFlashcardsOptions: PrintableFlashcardsOptions = {
    cardsPerPage: FlashcardsPerPageOption.SIX,
    fontStyle: Fonts.BalsamiqSans,
    cardType: CardType.IMAGE_ONLY,
    fontSizeMultiplier: 1,
    borderThickness: 1,
    borderColor: Colors.black,
};

interface PrintableFlashcardsOptionsProps extends React.Props<any> {
    activityStore: ActivityStore;
}

const PrintableFlashcardsOptions = (props: PrintableFlashcardsOptionsProps) => {
    let cardsPerPageLabels = [
        FlashcardsPerPageOption.ONE,
        FlashcardsPerPageOption.TWO,
        FlashcardsPerPageOption.SIX,
        FlashcardsPerPageOption.TWELVE,
        FlashcardsPerPageOption.TWENTY_FOUR,
    ];

    const handleCardTypeChanged = (cardType: CardType) => {
        props.activityStore.activityOptions.printableFlashcardsOptions.cardType = cardType;
        props.activityStore.updateOptions();
    };

    const handleFontStyleChanged = (value: string) => {
        props.activityStore.activityOptions.printableFlashcardsOptions.fontStyle = value;
        props.activityStore.updateOptions();
    };

    const handleCardsPerPageChanged = (value: string) => {
        props.activityStore.activityOptions.printableFlashcardsOptions.cardsPerPage = parseInt(value);
        props.activityStore.updateOptions();
    };

    const handleFontSizeDecremented = () => {
        const MIN_FONT_SIZE = 0.5;
        props.activityStore.activityOptions.printableFlashcardsOptions.fontSizeMultiplier -= 0.1;
        if (props.activityStore.activityOptions.printableFlashcardsOptions.fontSizeMultiplier < MIN_FONT_SIZE) {
            props.activityStore.activityOptions.printableFlashcardsOptions.fontSizeMultiplier = MIN_FONT_SIZE;
        }
    };

    const handleFontSizeIncremented = () => {
        const MAX_FONT_SIZE = 3;
        props.activityStore.activityOptions.printableFlashcardsOptions.fontSizeMultiplier += 0.1;
        if (props.activityStore.activityOptions.printableFlashcardsOptions.fontSizeMultiplier > MAX_FONT_SIZE) {
            props.activityStore.activityOptions.printableFlashcardsOptions.fontSizeMultiplier = MAX_FONT_SIZE;
        }
    };

    const handleBorderColorSelected = (color: string) => {
        console.log(color);
        props.activityStore.activityOptions.printableFlashcardsOptions.borderColor = color;
        props.activityStore.updateOptions();
    };

    const handleBorderThicknessDecremented = () => {
        const MIN_SIZE = 1;
        props.activityStore.activityOptions.printableFlashcardsOptions.borderThickness -= 1;
        if (props.activityStore.activityOptions.printableFlashcardsOptions.borderThickness < MIN_SIZE) {
            props.activityStore.activityOptions.printableFlashcardsOptions.borderThickness = MIN_SIZE;
        }
    };

    const handleBorderThicknessIncremented = () => {
        const MAX_SIZE = 8;
        props.activityStore.activityOptions.printableFlashcardsOptions.borderThickness += 1;
        if (props.activityStore.activityOptions.printableFlashcardsOptions.borderThickness > MAX_SIZE) {
            props.activityStore.activityOptions.printableFlashcardsOptions.borderThickness = MAX_SIZE;
        }
    };

    return (
        <Observer
            render={() => (
                <>
                    <CardTypeButtons
                        activityStore={props.activityStore}
                        onCardTypeChanged={handleCardTypeChanged}
                        selectedType={props.activityStore.activityOptions.printableFlashcardsOptions.cardType}
                    />
                    <FontSizeButtons
                        onFontSizeDecremented={handleFontSizeDecremented}
                        onFontSizeIncremented={handleFontSizeIncremented}
                    />
                    <ColorPickerOption
                        title={strings.borderColor}
                        selectedBorderColor={props.activityStore.activityOptions.printableFlashcardsOptions.borderColor}
                        colors={[
                            Colors.black,
                            Colors.red,
                            Colors.googleBlue,
                            Colors.green,
                            Colors.yellow,
                            Colors.purple,
                            Colors.grey,
                        ]}
                        onColorSelected={handleBorderColorSelected}
                    />
                    <BorderSizeOption
                        onSizeDecremented={handleBorderThicknessDecremented}
                        onSizeIncremented={handleBorderThicknessIncremented}
                    />
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        style={worksheetOptionsBackgroundStyle}
                    >
                        <Grid container justify="center">
                            <Grid item>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography variant={"h6"}>{strings.cardsPerPage}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <SimpleRadio
                                            labels={cardsPerPageLabels.map((label) => label.toString())}
                                            values={cardsPerPageLabels.map((label) => label.toString())}
                                            defaultValue={props.activityStore.activityOptions.printableFlashcardsOptions.cardsPerPage.toString()}
                                            onValueChanged={handleCardsPerPageChanged}
                                        ></SimpleRadio>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <FontStyleButtons onFontStyleChanged={handleFontStyleChanged} />
                </>
            )}
        />
    );
};

export default PrintableFlashcardsOptions;
