import * as React from "react";
import { observer } from "mobx-react";
// Material UI
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

// Models
import { CategoryType } from "../../models";

// Stores
import { RootStore } from "../../stores";

// Utils
import { strings } from "../../core/localizedStrings";
import { Colors } from "core/utils/Colors";
import { PlaceholderImages } from "assets/PlaceholderImages";
import { Tooltip, Typography } from "@material-ui/core";

export interface LessonTypeSelectorMenuProps extends React.Props<any> {
    rootStore: RootStore;
}

const styles = {
    toggleContainer: {
        marginLeft: 6,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },
};

@observer
export default class LessonTypeSelectorMenu extends React.Component<LessonTypeSelectorMenuProps> {
    render() {
        let { lessonSelection } = this.props.rootStore.activityStore;
        return (
            <div style={styles.toggleContainer}>
                <ToggleButtonGroup
                    size="small"
                    value={lessonSelection}
                    exclusive={true}
                    onChange={this.onCategoryTypeSelected}
                    style={{ backgroundColor: Colors.white }}
                >
                    <ToggleButton value={CategoryType.Vocabulary}>
                        <Tooltip title={strings.vocabulary} placement="bottom">
                            <img src={PlaceholderImages.VOCABULARY} style={{ width: 25 }} />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value={CategoryType.Expression}>
                        <Tooltip title={strings.questions} placement="bottom">
                            <img src={PlaceholderImages.EXPRESSION} style={{ width: 25 }} />
                        </Tooltip>
                    </ToggleButton>
                </ToggleButtonGroup>
                <Typography variant="button" style={{ paddingLeft: 5 }}>
                    {this.props.rootStore.activityStore.lessonSelection === CategoryType.Vocabulary
                        ? strings.vocabulary
                        : strings.questions}
                </Typography>
            </div>
        );
    }

    private onCategoryTypeSelected = (event: any) => {
        this.props.rootStore.activityStore.setLessonSelection(event.currentTarget.value);
    };
}
