export default class Utils {
    public static getExtension(text: string): string {
        var splitArray = text.split(".");
        if (splitArray.length > 1) {
            return splitArray.pop();
        } else {
            return undefined;
        }
    }

    public static stripExtension(text: string): string {
        var splitArray = text.split(".");
        if (splitArray.length > 1) {
            splitArray.pop();
            return splitArray.join(".");
        } else {
            return text;
        }
    }

    /* tslint:disable */
    public static hashCode(str: string) {
        return str.split("").reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0);
    }

    public static guid() {
        return "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx".replace(/[x]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }
    /* tslint:enable */

    public static centsToDollarString(cents: number): string {
        cents = cents / 100;
        let dollars = cents.toFixed(2);
        return `$${dollars}`;
    }

    public static createHash(text: string) {
        var sha512 = require("js-sha512");
        return sha512(text);
    }

    public static hexStringToInt(hexString: string) {
        return parseInt(hexString.replace(/^#/, ""), 16);
    }
}
