import * as React from "react";

import * as CustomLists from "../models/customLists";

import { findDOMNode } from "react-dom";
import { DragSource, DropTarget } from "react-dnd";
import flow from "lodash.flow";

export interface DraggableCardProps {
    childObject: any;
    index: number;
    metadata?: any;
    onItemDropped: (props?: any) => void;
    moveCard?: (dragIndex: number, hoverIndex: number, dragMetadata?: any, hoverMetadata?: any) => void;
    connectDragSource?: (source: any) => any;
    connectDropTarget?: (target: any) => any;
    isDragging?: boolean;
    isOver?: boolean;
    didDrop?: boolean;
    category?: CustomLists.Category;
}

class DraggableCard extends React.Component<DraggableCardProps> {
    render() {
        const opacity = this.props.isDragging ? 0 : 1;
        return this.props.connectDragSource(
            this.props.connectDropTarget(
                <div style={{ cursor: "move", opacity: opacity }}>{this.props.childObject}</div>
            )
        );
    }
}

const cardSource = {
    beginDrag(props: DraggableCardProps) {
        return {
            category: props.category,
            index: props.index,
            metadata: props.metadata,
        };
    },
    endDrag(props: DraggableCardProps) {
        props.onItemDropped(props);
    },
};

const cardTarget = {
    hover(props: DraggableCardProps, monitor: any, component: any) {
        const dragIndex = monitor.getItem().index;
        const dragMetadata = monitor.getItem().metadata;
        const hoverIndex = props.index;
        const hoverMetadata = props.metadata;

        // // Don't replace items with themselves
        // if (dragIndex === hoverIndex) {
        //     return;
        // }

        // Determine rectangle on screen
        const rect = findDOMNode(component) as Element;
        const hoverBoundingRect = rect.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }

        // // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }

        // Time to actually perform the action
        props.moveCard(dragIndex, hoverIndex, dragMetadata, hoverMetadata);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

function collect(connect: any, monitor: any) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
}

function collectTarget(connect: any, monitor: any) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
    };
}

var source = DragSource("card", cardSource, collect);
var target = DropTarget("card", cardTarget, collectTarget);

export default flow(source, target)(DraggableCard);
