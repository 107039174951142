import * as React from "react";
import Responsive from "react-responsive";

export const Desktop = (props) => <Responsive {...props} minWidth={900} />;
export const Tablet = (props) => <Responsive {...props} maxWidth={899} />;
export const Mobile = (props) => <Responsive {...props} maxWidth={400} />;
export const Default = (props) => <Responsive {...props} minWidth={768} />;

export const BigScreen = (props) => <Responsive {...props} minWidth={500} />;
export const SmallScreen = (props) => <Responsive {...props} maxWidth={500} />;
