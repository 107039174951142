import * as React from "react";

import { allLanguages } from "../../models";
import { Colors } from "../../core/utils/Colors";
import { strings } from "../../core/localizedStrings";
import { DynamicTypography, DynamicTypographyType } from "core/utils/DynamicTypography";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

export interface AudioClipsInfoRowProps extends React.Props<any> {}

let styles = {
    container: { maxWidth: 1000, padding: "60px 20px 80px 20px", color: Colors.white },
    titleText: {
        color: Colors.white,
        fontWeight: "bold"
    },
    secondaryTitleText: {
        color: Colors.white
    },
    text: {
        fontSize: 10,
        color: Colors.white,
        textAlign: "center",
        marginTop: -8
    },
    image: {
        padding: "5px 10px 0 10px"
    }
};

export default class AudioClipsInfoRow extends React.Component<AudioClipsInfoRowProps> {
    render() {
        return (
            <Row center="xs" style={{ backgroundColor: Colors.lightBlue }}>
                <Col xs={12} style={styles.container}>
                    <Row center="xs">
                        <DynamicTypography variant={DynamicTypographyType.H2} style={styles.titleText}>
                            {strings.multipleLanguageSupport.toUpperCase()}
                        </DynamicTypography>
                    </Row>
                    <Row center="xs" style={styles.secondaryTitleText}>
                        <DynamicTypography variant={DynamicTypographyType.H3} style={styles.secondaryTitleText}>
                            {strings.formatString(strings.textToSpeechSupportFormat)}
                        </DynamicTypography>
                    </Row>
                    <Row center="xs">
                        {allLanguages.map((element, index) => {
                            return (
                                <Col key={index}>
                                    <Row center="xs">
                                        <img
                                            style={styles.image}
                                            src={require("../../assets/images/languages/" + element + ".png")}
                                        />
                                    </Row>
                                    <Row center="xs" style={styles.text}>
                                        {strings[element].toUpperCase()}
                                    </Row>
                                </Col>
                            );
                        })}
                    </Row>
                </Col>
            </Row>
        );
    }
}
