import * as React from "react";

// Stores
import { ActivityStore } from "stores";

// Layout
import { Button, Grid, Typography } from "@material-ui/core";
import { Observer } from "mobx-react-lite";
import {
    CardTypeButtons,
    FontSizeButtons,
    worksheetOptionsBackgroundStyle,
} from "components/activities/CommonActivityOptions";
import { CardType } from "models/customLists/CategoryItem";
import { NumberOfWorksheetColumns } from "../WorksheetHelper";
import { WorksheetOptions } from "../WorksheetScene";
import SimpleCheckbox from "components/SimpleCheckbox";
import { strings } from "core/localizedStrings";
import { Fonts } from "core/utils/Fonts";
import { Colors } from "core/utils/Colors";

export interface SentenceScrambleWorksheetOptions extends WorksheetOptions {
    cardType: CardType;
    numberOfColumns: NumberOfWorksheetColumns;
    fontSize: number;
    codeStyle: string;
}

export const defaultSentenceScrambleWorksheetOptions: SentenceScrambleWorksheetOptions = {
    cardType: CardType.IMAGE_ONLY,
    numberOfColumns: NumberOfWorksheetColumns.ONE,
    fontSize: 30,
    codeStyle: Fonts.GoogleEmojis,
};

interface SentenceScrambleWorksheetOptionsProps extends React.Props<any> {
    activityStore: ActivityStore;
}

const SentenceScrambleWorksheetOptions = (props: SentenceScrambleWorksheetOptionsProps) => {
    const handleCardTypeChanged = (cardType: CardType) => {
        props.activityStore.activityOptions.sentenceScrambleWorksheetOptions.cardType = cardType;
        props.activityStore.updateOptions();
    };

    const handleFontSizeDecremented = () => {
        const MIN_FONT_SIZE = 24;
        props.activityStore.activityOptions.sentenceScrambleWorksheetOptions.fontSize *= 0.9;
        if (props.activityStore.activityOptions.sentenceScrambleWorksheetOptions.fontSize < MIN_FONT_SIZE) {
            props.activityStore.activityOptions.sentenceScrambleWorksheetOptions.fontSize = MIN_FONT_SIZE;
        }
        props.activityStore.updateOptions();
    };

    const handleFontSizeIncremented = () => {
        const MAX_FONT_SIZE = 60;
        props.activityStore.activityOptions.sentenceScrambleWorksheetOptions.fontSize *= 1.1;
        if (props.activityStore.activityOptions.sentenceScrambleWorksheetOptions.fontSize > MAX_FONT_SIZE) {
            props.activityStore.activityOptions.sentenceScrambleWorksheetOptions.fontSize = MAX_FONT_SIZE;
        }
        props.activityStore.updateOptions();
    };

    return (
        <Observer
            render={() => (
                <>
                    <CardTypeButtons
                        activityStore={props.activityStore}
                        onCardTypeChanged={handleCardTypeChanged}
                        selectedType={props.activityStore.activityOptions.sentenceScrambleWorksheetOptions.cardType}
                    />
                    <FontSizeButtons
                        onFontSizeDecremented={handleFontSizeDecremented}
                        onFontSizeIncremented={handleFontSizeIncremented}
                    />
                </>
            )}
        />
    );
};

export default SentenceScrambleWorksheetOptions;

interface CodeStyleButtonProps {
    selectedType: string;
    onChange: (type: string) => void;
}

export const CodeStyleButtons = (props: CodeStyleButtonProps) => {
    return (
        <Grid container direction="column" justify="center" alignItems="center" style={worksheetOptionsBackgroundStyle}>
            <Typography variant={"h6"}>{"Code Style"}</Typography>
            <Grid container justify="center">
                <Grid item>
                    <Button
                        variant={props.selectedType && props.selectedType == Fonts.KawaiiFood ? "outlined" : null}
                        color="primary"
                        onClick={() => {
                            props.onChange(Fonts.KawaiiFood);
                        }}
                    >
                        <img src={require("assets/images/fontIcons/kawaiiFood.png")} height={50} />
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant={props.selectedType && props.selectedType == Fonts.CuteIcons ? "outlined" : null}
                        onClick={() => {
                            props.onChange(Fonts.CuteIcons);
                        }}
                    >
                        <img src={require("assets/images/fontIcons/cuteIcons.png")} height={50} />
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant={props.selectedType && props.selectedType == Fonts.GoogleEmojis ? "outlined" : null}
                        onClick={() => {
                            props.onChange(Fonts.GoogleEmojis);
                        }}
                    >
                        <img src={require("assets/images/fontIcons/googleEmojis.png")} height={50} />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
