export class Timer {
    private interval: number;
    private callback: () => void;
    private timeout: NodeJS.Timeout;
    constructor(interval: number, callback: () => void) {
        this.interval = interval;
        this.callback = callback;

        window.onbeforeunload = () => {
            this.stop();
        };
    }

    public start = () => {
        this.timeout = setInterval(this.callback, this.interval);
    };

    public stop() {
        if (this.timeout) {
            clearInterval(this.timeout);
        }
    }
}

export default class TimerManager {
    private static intervals: Timer[] = [];
    static addTimer(interval: number, callback: () => void) {
        let timer = new Timer(interval, callback);
        this.intervals.push(timer);
        return timer;
    }

    static clearAllTimers = () => {
        TimerManager.intervals.forEach((timer) => timer.stop());
        TimerManager.intervals.splice(0);
    };
}
