import * as React from "react";
import { observer } from "mobx-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RootStore } from "../stores";

interface LoadingScreenProps {
    rootStore: RootStore;
}

@observer
export default class LoadingScreen extends React.Component<LoadingScreenProps> {
    render() {
        if (!this.props.rootStore.shouldShowLoadingScreen) {
            return null;
        }
        return (
            <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <CircularProgress />
            </div>
        );
    }
}
