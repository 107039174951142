import * as React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

import { Card, CardHeader, CardActions, Collapse, IconButton, Button, TextField } from "@material-ui/core";

import TranslationCard from "./TranslationCard";
import * as Icons from "core/utils/Icons";
import { RootStore } from "stores";

import { Category, CategoryItem } from "models";

var Row = require("react-flexbox-grid").Row;

interface TranslationCategoryCardProps extends React.Props<any> {
    rootStore: RootStore;
    category: Category;
    englishCategory: Category;
    stringsDictionary: Map<string, string>;
    onItemTranslatedCallback: (item: CategoryItem, oldItem: CategoryItem, category: Category) => void;
}

@observer
export default class TranslationCategoryCard extends React.Component<TranslationCategoryCardProps> {
    @observable
    private isOpen = false;

    render() {
        return (
            <Card style={{ margin: 10 }}>
                <Row>
                    {this.isOpen ? (
                        <TextField
                            fullWidth={true}
                            label="List Title"
                            defaultValue={this.props.category.title}
                            style={{ margin: 20 }}
                            inputProps={{ style: { fontSize: 24 } }}
                            onBlur={this.onTitleLostFocus}
                        />
                    ) : (
                        <>
                            <CardHeader title={this.props.category.title} />
                            <CardActions disableSpacing={true}>
                                <IconButton onClick={this.onExpandClick}>
                                    <Icons.ExpandMore />
                                </IconButton>
                            </CardActions>
                        </>
                    )}
                </Row>
                <Collapse in={this.isOpen} timeout="auto" unmountOnExit={true}>
                    {this.renderItems()}
                </Collapse>
                {this.isOpen && (
                    <Row end="xs" style={{ padding: 5, margin: 10 }}>
                        <Button onClick={this.onDoneClicked} color="primary">
                            Done
                        </Button>
                    </Row>
                )}
            </Card>
        );
    }

    private renderItems = () => {
        if (!this.props.category || !this.props.englishCategory) {
            return null;
        }
        if (this.props.category.items.length > 0 && this.props.englishCategory.items.length > 0) {
            return this.props.category.items.map((item: CategoryItem, index: number) => {
                return (
                    <TranslationCard
                        categoryItem={item}
                        englishCategoryItem={this.props.englishCategory.items.find((element: CategoryItem) => {
                            return element.key === item.key;
                        })}
                        key={index}
                        stringsDictionary={this.props.stringsDictionary}
                        onItemUpdatedCallback={this.onItemUpdated}
                    />
                );
            });
        }
    };

    @action
    private onExpandClick = () => {
        console.log(this.props.category);
        this.isOpen = !this.isOpen;
    };

    @action
    private onDoneClicked = () => {
        this.isOpen = false;
    };

    @action
    private onTitleLostFocus = (event: any) => {
        let categoryCopy = Object.assign(new Category(), this.props.category);
        categoryCopy.title = event.target.value;
        this.props.rootStore.createListsStore.updateCategory(categoryCopy);
    };

    private onItemUpdated = (item: CategoryItem, oldItem: CategoryItem) => {
        this.props.onItemTranslatedCallback(item, oldItem, this.props.category);
    };
}
