import { log } from "../../";

import * as React from "react";
import { observable, action, runInAction } from "mobx";
import { observer } from "mobx-react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import ProfileImageDropZone from "../../components/ProfileImageDropZone";

import { User, ManagedUser } from "../../models";
import RootStore from "../../stores/RootStore";
import { strings } from "../../core/localizedStrings";
import { Colors } from "../../core/utils/Colors";
import { FirestoreActions } from "../../core/firebase";

import * as Validator from "../../core/utils/Validator";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

export enum ProfileFormType {
    User,
    ManagedTeacher,
    ManagedGroupStudent,
}

export interface UpdateProfileFormProps extends React.Props<any> {
    title: String;
    user: User;
    imageProfileUploadProgress: number;
    onComplete: () => void;
    onImageDroppedCallback: (file: File, user: User) => void;
    rootStore: RootStore;
}

const styles = {
    errorDescription: {
        paddingTop: 3,
        color: Colors.red,
        fontSize: 11,
    },
};

@observer
export class UpdateProfileForm extends React.Component<UpdateProfileFormProps> {
    @observable username: string = "";
    @observable email: string = "";
    @observable password: string = "";

    @observable usernameError: string;
    @observable emailError: string;
    @observable passwordError: string;
    @observable isValidating: boolean;

    constructor(props: any, context: any) {
        super(props, context);

        if (this.props.user !== undefined) {
            this.username = this.props.user.username ? this.props.user.username : "";
            this.email = this.props.user.email ? this.props.user.email : "";
        }
    }

    render() {
        let usernameText = strings.usernameOptional;
        return (
            <div style={{ padding: 20 }}>
                <Typography variant="h6">{this.props.title}</Typography>
                <Row style={{ padding: 30 }}>
                    <Col middle="xs" xs={12}>
                        {this.props.user && this.props.user.uid ? (
                            <Row center="xs">
                                <ProfileImageDropZone
                                    onImageDroppedCallback={this.onImageDropped}
                                    profileImageURL={
                                        this.props.user ? this.props.user.profileImageDownloadURL : undefined
                                    }
                                    imageProfileUploadProgress={this.props.imageProfileUploadProgress}
                                    rootStore={this.props.rootStore}
                                />
                            </Row>
                        ) : null}
                        <Row style={{ paddingTop: 10 }}>
                            <TextField
                                label={usernameText}
                                fullWidth={true}
                                id="username"
                                value={this.username}
                                onChange={this.onTextChanged}
                                onKeyDown={this.onKeyDown}
                            />
                            {this.usernameError ? (
                                <Typography style={styles.errorDescription}>{this.usernameError}</Typography>
                            ) : null}
                        </Row>
                        <Row style={{ paddingTop: 10 }}>
                            <TextField
                                label={strings.email}
                                fullWidth={true}
                                id="email"
                                value={this.email}
                                onChange={this.onTextChanged}
                                onKeyDown={this.onKeyDown}
                            />
                            {this.emailError ? (
                                <Typography style={styles.errorDescription}>{this.emailError}</Typography>
                            ) : null}
                        </Row>
                        {this.renderPasswordButton()}
                    </Col>
                    <Col xs={12}>
                        <Row end="xs">
                            {this.isValidating ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        this.onSubmitClicked();
                                    }}
                                    style={{ margin: "30px 0 10px 0" }}
                                >
                                    {strings.ok}
                                </Button>
                            )}
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }

    private renderPasswordField() {
        return (
            <Row style={{ paddingTop: 10 }}>
                <TextField
                    type="password"
                    label={strings.password}
                    fullWidth={true}
                    id="password"
                    value={this.password}
                    onChange={this.onTextChanged}
                    onKeyDown={this.onKeyDown}
                />
                {this.passwordError ? (
                    <Typography style={styles.errorDescription}>{this.passwordError}</Typography>
                ) : null}
            </Row>
        );
    }

    private renderPasswordButton() {
        return (
            <Button
                variant="contained"
                onClick={this.onUpdatePasswordClicked}
                style={{ marginTop: 20, marginLeft: -8 }}
            >
                {strings.changePassword}
            </Button>
        );
    }

    private onImageDropped = (file: File) => {
        this.props.onImageDroppedCallback(file, this.props.user);
    };

    @action
    private onTextChanged = (event: any) => {
        switch (event.target.id) {
            case "username":
                this.username = event.currentTarget.value;
                break;
            case "password":
                this.password = event.currentTarget.value;
                break;
            case "email":
                this.email = event.currentTarget.value;
                break;
            default:
                break;
        }
    };

    @action
    private onUpdatePasswordClicked = (event: any) => {
        this.props.rootStore.authStore.sendPasswordResetEmail();
    };

    private onKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            this.onSubmitClicked();
        }
    };

    @action
    private onSubmitClicked = async () => {
        this.usernameError = undefined;
        this.emailError = undefined;
        this.passwordError = undefined;

        this.isValidating = true;
        let validated = await this.validateFields();
        runInAction(() => {
            this.isValidating = false;
        });

        if (!validated) {
            return;
        }

        if (
            this.props.rootStore.userStore.user.username === undefined ||
            this.username.toLowerCase() !== this.props.rootStore.userStore.user.username.toLowerCase()
        ) {
            let usernameAvailable = await this.props.rootStore.userStore.isUsernameAvailable(this.username);
            if (!usernameAvailable) {
                runInAction(() => {
                    this.usernameError = strings.usernameTaken;
                });
                return;
            }
        }

        // Username changed
        if (this.username !== this.props.user.username) {
            this.props.user.username = this.username.trim();
            FirestoreActions.updateUser(this.props.user);
        }

        // Email changed
        if (this.email.trim() !== this.props.user.email) {
            this.props.user.email = this.email.trim();
            this.props.rootStore.userStore.updateEmail(this.props.user.email);
        }

        this.props.onComplete();
    };

    @action
    private validateFields = async () => {
        let usernameError: string;
        let emailError: string;
        console.log(this);

        if (
            this.props.rootStore.userStore.user.username === undefined ||
            this.username.toLowerCase() !== this.props.rootStore.userStore.user.username.toLowerCase()
        ) {
            if (this.username) {
                usernameError = await Validator.validateUsername(this.username);
                if (usernameError) {
                    runInAction(() => {
                        this.usernameError = usernameError;
                    });
                }
            }
        }

        if (
            this.props.rootStore.userStore.user.email === undefined ||
            this.email.toLowerCase() !== this.props.rootStore.userStore.user.email.toLowerCase()
        ) {
            emailError = await Validator.validateEmail(this.email);
            if (emailError) {
                this.emailError = emailError;
            }
        }
        if (emailError || usernameError || this.passwordError) {
            return false;
        }

        return true;
    };
}
