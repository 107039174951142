import { defaultTicTacToeOptions, TicTacToeOptions } from "components/activities/games/TicTacToe/TicTacToeOptions";
import { defaultQuizOptions, QuizOptions } from "./QuizOptions";
import { CardMatchOptions, defaultCardMatchOptions } from "components/activities/games/CardMatch/CardMatchOptions";
import { HangmanOptions, defaultHangmanOptions } from "components/activities/games/Hangman/HangmanOptions";
import { AngryWordsOptions, defaultAngryWordsOptions } from "components/activities/games/AngryWords/AngryWordsOptions";
import {
    defaultInteractiveFlashcardsOptions,
    InteractiveFlashcardsOptions,
} from "components/activities/flashcards/InteractiveFlashcardsOptions";
import { CrazyCupsOptions, defaultCrazyCupsOptions } from "./CrazyCupsOptions";
import { defaultTimerOptions, TimerOptions } from "./TimerOptions";
import { BattleshipOptions, defaultBattleshipOptions } from "components/activities/games/Battleship/BattleshipOptions";
import {
    DartboardSelectorOptions,
    defaultDartboardSelectorOptions,
} from "components/activities/tools/dartboardSelector/DartboardSelectorOptions";
import {
    defaultRandomNamePickerOptions,
    RandomNamePickerOptions,
} from "components/activities/tools/randomNamePicker/RandomNamePickerOptions";
import {
    BingoCardsOptions,
    defaultBingoCardsOptions,
} from "components/activities/worksheets/bingoCards/BingoCardsOptions";
import {
    ConnectTheLettersOptions,
    defaultConnectTheLettersOptions,
} from "components/activities/worksheets/connectTheLetters/ConnectTheLettersWorksheetOptions";
import {
    defaultMatchingWorksheetOptions,
    MatchingWorksheetOptions,
} from "components/activities/worksheets/matching/MatchingWorksheetOptions";
import {
    defaultReadingWorksheetOptions,
    ReadingWorksheetOptions,
} from "components/activities/worksheets/reading/ReadingWorksheetOptions";
import {
    defaultTracingWorksheetOptions,
    TracingWorksheetOptions,
} from "components/activities/worksheets/tracing/TracingWorksheetOptions";
import { defaultTheWheelOptions, TheWheelOptions } from "components/activities/games/TheWheel/TheWheelOptions";
import { defaultSharkBaitOptions, SharkBaitOptions } from "components/activities/games/SharkBait/SharkBaitOptions";
import {
    defaultPrintableFlashcardsOptions,
    PrintableFlashcardsOptions,
} from "components/activities/flashcards/PrintableFlashcards/PrintableFlashcardsOptions";
import { defaultHotOrColdOptions, HotOrColdOptions } from "components/activities/games/HotOrCold/HotOrColdOptions";
import {
    CrosswordWorksheetOptions,
    defaultCrosswordWorksheetOptions,
} from "components/activities/worksheets/crossword/CrosswordWorksheetOptions";
import {
    WordsearchWorksheetOptions,
    defaultWordsearchWorksheetOptions,
} from "components/activities/worksheets/wordsearch/WordsearchWorksheetOptions";
import {
    MissingLettersWorksheetOptions,
    defaultMissingLettersWorksheetOptions,
} from "components/activities/worksheets/missingLetters/MissingLettersWorksheetOptions";
import {
    defaultTicTacToeWorksheetOptions,
    TicTacToeWorksheetOptions,
} from "components/activities/worksheets/ticTacToe/TicTacToeWorksheetOptions";
import {
    defaultQAndAWorksheetOptions,
    QAndAWorksheetOptions,
} from "components/activities/worksheets/qAndA/QAndAWorksheetOptions";
import {
    SentenceDecoderWorksheetOptions,
    defaultSentenceDecoderWorksheetOptions,
} from "components/activities/worksheets/sentenceDecoder/SentenceDecoderWorksheetOptions";
import { SpeedOptions, defaultSpeedOptions } from "components/activities/games/Speed/SpeedGameOptions";
import {
    defaultMemoryWheelOptions,
    MemoryWheelGameOptions,
} from "components/activities/games/MemoryWheel/MemoryWheelGameOptions";
import {
    defaultWordScrambleWorksheetOptions,
    WordScrambleWorksheetOptions,
} from "components/activities/worksheets/wordScramble/WordScrambleWorksheetOptions";
import {
    defaultSentenceScrambleWorksheetOptions,
    SentenceScrambleWorksheetOptions,
} from "components/activities/worksheets/sentenceScramble/SentenceScrambleWorksheetOptions";
import { BingoCallerOptions } from "components/activities/games/BingoCaller/BingoCallerOptions";
import {
    defaultRacetrackWorksheetOptions,
    RacetrackWorksheetOptions,
} from "components/activities/worksheets/racetrack/RacetrackWorksheetOptions";
import {
    defaultLaddersSlidesWorksheetOptions,
    LaddersSlidesWorksheetOptions,
} from "components/activities/worksheets/laddersSlides/LaddersSlidesWorksheetOptions";

export const defaultActivityOptions: ActivityOptions = {
    quizOptions: defaultQuizOptions,
    flashcardsOptions: defaultInteractiveFlashcardsOptions,
    printableFlashcardsOptions: defaultPrintableFlashcardsOptions,
    hangmanOptions: defaultHangmanOptions,
    angryWordsOptions: defaultAngryWordsOptions,
    battleshipOptions: defaultBattleshipOptions,
    cardMatchOptions: defaultCardMatchOptions,
    ticTacToeOptions: defaultTicTacToeOptions,
    crazyCupsOptions: defaultCrazyCupsOptions,
    hotOrColdOptions: defaultHotOrColdOptions,
    theWheelOptions: defaultTheWheelOptions,
    sharkBaitOptions: defaultSharkBaitOptions,
    speedOptions: defaultSpeedOptions,
    memoryWheelOptions: defaultMemoryWheelOptions,
    bingoCallerOptions: defaultBingoCardsOptions,
    timerOptions: defaultTimerOptions,
    dartboardSelectorOptions: defaultDartboardSelectorOptions,
    randomNamePickerOptions: defaultRandomNamePickerOptions,
    bingoCardsOptions: defaultBingoCardsOptions,
    connectTheLettersWorksheetOptions: defaultConnectTheLettersOptions,
    matchingWorksheetOptions: defaultMatchingWorksheetOptions,
    readingWorksheetOptions: defaultReadingWorksheetOptions,
    tracingWorksheetOptions: defaultTracingWorksheetOptions,
    crosswordWorksheetOptions: defaultCrosswordWorksheetOptions,
    wordsearchWorksheetOptions: defaultWordsearchWorksheetOptions,
    missingLettersWorksheetOptions: defaultMissingLettersWorksheetOptions,
    ticTacToeWorksheetOptions: defaultTicTacToeWorksheetOptions,
    qAndAWorksheetOptions: defaultQAndAWorksheetOptions,
    sentenceDecoderWorksheetOptions: defaultSentenceDecoderWorksheetOptions,
    wordScrambleWorksheetOptions: defaultWordScrambleWorksheetOptions,
    sentenceScrambleWorksheetOptions: defaultSentenceScrambleWorksheetOptions,
    racetrackWorksheetOptions: defaultRacetrackWorksheetOptions,
    laddersSlidesWorksheetOptions: defaultLaddersSlidesWorksheetOptions,
};

export interface ActivityOptions {
    quizOptions: QuizOptions;
    flashcardsOptions: InteractiveFlashcardsOptions;
    printableFlashcardsOptions: PrintableFlashcardsOptions;
    hangmanOptions: HangmanOptions;
    ticTacToeOptions: TicTacToeOptions;
    angryWordsOptions: AngryWordsOptions;
    battleshipOptions: BattleshipOptions;
    cardMatchOptions: CardMatchOptions;
    crazyCupsOptions: CrazyCupsOptions;
    hotOrColdOptions: HotOrColdOptions;
    theWheelOptions: TheWheelOptions;
    sharkBaitOptions: SharkBaitOptions;
    speedOptions: SpeedOptions;
    memoryWheelOptions: MemoryWheelGameOptions;
    bingoCallerOptions: BingoCallerOptions;
    timerOptions: TimerOptions;
    dartboardSelectorOptions: DartboardSelectorOptions;
    randomNamePickerOptions: RandomNamePickerOptions;
    bingoCardsOptions: BingoCardsOptions;
    connectTheLettersWorksheetOptions: ConnectTheLettersOptions;
    matchingWorksheetOptions: MatchingWorksheetOptions;
    readingWorksheetOptions: ReadingWorksheetOptions;
    tracingWorksheetOptions: TracingWorksheetOptions;
    crosswordWorksheetOptions: CrosswordWorksheetOptions;
    wordsearchWorksheetOptions: WordsearchWorksheetOptions;
    missingLettersWorksheetOptions: MissingLettersWorksheetOptions;
    ticTacToeWorksheetOptions: TicTacToeWorksheetOptions;
    qAndAWorksheetOptions: QAndAWorksheetOptions;
    sentenceDecoderWorksheetOptions: SentenceDecoderWorksheetOptions;
    wordScrambleWorksheetOptions: WordScrambleWorksheetOptions;
    sentenceScrambleWorksheetOptions: SentenceScrambleWorksheetOptions;
    racetrackWorksheetOptions: RacetrackWorksheetOptions;
    laddersSlidesWorksheetOptions: LaddersSlidesWorksheetOptions;
}
