import * as React from "react";

// Stores
import { ActivityStore } from "stores";

// Layout
import { Divider, Grid } from "@material-ui/core";
import { Observer } from "mobx-react-lite";
import {
    CardTypeButtons,
    FontSizeButtons,
    worksheetOptionsBackgroundStyle,
} from "components/activities/CommonActivityOptions";
import { CardType } from "models/customLists/CategoryItem";
import { WorksheetOptions } from "../WorksheetScene";

export interface MatchingWorksheetOptions extends WorksheetOptions {
    cardType: CardType;
    fontSize: number;
}

export const defaultMatchingWorksheetOptions: MatchingWorksheetOptions = {
    cardType: CardType.IMAGE_ONLY,
    fontSize: 36,
};

interface MatchingWorksheetOptionsProps extends React.Props<any> {
    activityStore: ActivityStore;
}

const MatchingWorksheetOptions = (props: MatchingWorksheetOptionsProps) => {
    const handleCardTypeChanged = (cardType: CardType) => {
        props.activityStore.activityOptions.matchingWorksheetOptions.cardType = cardType;
        props.activityStore.updateOptions();
    };

    const handleFontSizeDecremented = () => {
        const MIN_FONT_SIZE = 18;
        props.activityStore.activityOptions.matchingWorksheetOptions.fontSize *= 0.8;
        if (props.activityStore.activityOptions.matchingWorksheetOptions.fontSize < MIN_FONT_SIZE) {
            props.activityStore.activityOptions.matchingWorksheetOptions.fontSize = MIN_FONT_SIZE;
        }
    };

    const handleFontSizeIncremented = () => {
        const MAX_FONT_SIZE = 80;
        props.activityStore.activityOptions.matchingWorksheetOptions.fontSize *= 1.2;
        if (props.activityStore.activityOptions.matchingWorksheetOptions.fontSize > MAX_FONT_SIZE) {
            props.activityStore.activityOptions.matchingWorksheetOptions.fontSize = MAX_FONT_SIZE;
        }
    };

    return (
        <Observer
            render={() => (
                <>
                    <CardTypeButtons
                        activityStore={props.activityStore}
                        onCardTypeChanged={handleCardTypeChanged}
                        selectedType={props.activityStore.activityOptions.matchingWorksheetOptions.cardType}
                    />
                    <FontSizeButtons
                        onFontSizeDecremented={handleFontSizeDecremented}
                        onFontSizeIncremented={handleFontSizeIncremented}
                    />
                </>
            )}
        />
    );
};

export default MatchingWorksheetOptions;
