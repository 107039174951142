import { log } from "../";

import { observable, action, runInAction, computed } from "mobx";

// Models
import { User, ManagedUser, BillingFrequency, SubscriptionDetailsStatus } from "../models";
import { CreateSubscriptionRequest } from "../models/payments/HttpRequests";
import { PendingSubscription, StripeCustomerDetails } from "../models/payments/StripeCustomerDetails";
import { QuelledMessageType, AccountRole, ContentAccess } from "../models";

// Stores
import { RootStore } from "./";

// Utils
import { firebaseApp, FirebaseStorage, Firestore, FirestoreActions } from "../core/firebase";
import FirebaseFunctions from "../core/firebase/FirebaseFunctions";
import { strings } from "../core/localizedStrings";
import { CollectionReference } from "firebase/firebase-firestore";
import { PinnedActivity } from "models/activities/PinnedActivity";
import { ConfirmationMessage } from "components/MessageBoxDialogs";
import { firestore } from "firebase";
import { PendingManagedUser } from "models/User";

export default class UserStore {
    @observable imageProfileUploadProgress: number = 0;
    @observable user: User | ManagedUser = new User();
    @observable customerDetails: StripeCustomerDetails;
    @observable firebaseUserInfo: firebase.UserInfo;
    @observable userToEdit: User;
    @observable fetchingProratedAmount = false;
    @observable isManagedTeacherLoading: boolean = false;
    @observable managedTeachersBeingRemoved: string[] = [];
    @observable pendingSubscription: PendingSubscription;
    @observable subscriptionDetailStatus: SubscriptionDetailsStatus = SubscriptionDetailsStatus.NotLoaded;

    public rootStore: RootStore;

    constructor(rootStore: RootStore, user: User) {
        this.rootStore = rootStore;
        this.setUser(user);
    }
    // @action
    // public setSiteLanguage = (language: LanguageType) => {
    //     if (this.user.uid) {
    //         this.user.siteLanguage = language;
    //         Firestore.userDocForID(this.user.uid).update(this.user.objectForFirebase);
    //     }
    // };

    @action
    public setIsManagedTeacherLoading = (value: boolean) => {
        this.isManagedTeacherLoading = value;
    };

    @action
    public setManagedTeachersBeingRemoved = (value: string[]) => {
        this.managedTeachersBeingRemoved = value;
    };

    @computed
    public get role(): AccountRole {
        console.log("test");
        if (this.user.isAnonymous) {
            return AccountRole.ANONYMOUS;
        }

        if (this.user.isAdmin) {
            return AccountRole.ADMIN;
        }

        if (this.user.isTranslator) {
            return AccountRole.TRANSLATOR;
        }

        if (this.user.isTrial) {
            return AccountRole.TRIAL;
        }

        if (this.customerDetails && !this.customerDetails.accountExpired) {
            if (this.customerDetails.subscription?.status === "past_due") {
                return AccountRole.BASIC;
            }
            return AccountRole.PREMIUM;
        }

        if (this.user.expiration && this.user.expiration.toDate().getTime() > Date.now()) {
            return AccountRole.PREMIUM;
        }

        return AccountRole.BASIC;
    }

    @computed
    public get contentAccess(): ContentAccess {
        // return ContentAccess.Credits;
        switch (this.role) {
            case AccountRole.ADMIN:
            case AccountRole.PREMIUM:
            case AccountRole.TRIAL:
                return ContentAccess.Unlimited;
            case AccountRole.BASIC:
                return ContentAccess.Credits;
            case AccountRole.ANONYMOUS:
                return ContentAccess.None;
            default:
                return ContentAccess.Unknown;
        }
    }

    public registerUserListeners = () => {
        if (this.user.emailLower) {
            let usersCollection = Firestore.usersCollection() as firebase.firestore.CollectionReference;
            let currentUserDocRef = usersCollection.doc(this.user.uid);
            currentUserDocRef.onSnapshot(this.onUserChangeDetected);

            let pendingUsersCollection =
                Firestore.pendingManagedUsersCollection() as firebase.firestore.CollectionReference;
            pendingUsersCollection.doc(this.user.emailLower).onSnapshot(async (snapshot) => {
                if (snapshot.exists) {
                    let pendingManagementRequest: PendingManagedUser = snapshot.data() as PendingManagedUser;
                    if (pendingManagementRequest.managedBy) {
                        let doc = await Firestore.usersCollection().doc(pendingManagementRequest.managedBy).get();
                        if (doc.exists) {
                            let manager = new User(doc.data());
                            let messageText = strings.formatString(
                                strings.confirmToBeManagedConfirmationFormat,
                                manager.email
                            ) as string;
                            let message = new ConfirmationMessage(messageText, undefined, (result) => {
                                snapshot.ref.delete();
                                if (result === "yes") {
                                    currentUserDocRef.update({
                                        managedBy: pendingManagementRequest.managedBy,
                                        managedUserType: pendingManagementRequest.managedUserType,
                                        pendingManager: firestore.FieldValue.delete(),
                                    });
                                } else {
                                    currentUserDocRef.update({
                                        pendingManager: firestore.FieldValue.delete(),
                                    });
                                }
                            });
                            this.rootStore.showConfirmationMessage(message);
                        } else {
                            log.error(strings.pendingManagerNotFound + pendingManagementRequest.managedBy);
                        }
                    }
                }
            });
        }
    };

    public updateEmail(email: string) {
        try {
            let userCopy = new User();
            Object.assign(userCopy, this.user);
            userCopy.email = email;

            FirestoreActions.updateUser(userCopy);
        } catch (error) {
            this.rootStore.showErrorMessage(strings.updateEmailError);
        }
    }

    @action
    public addProfileImage = async (file: File, user: User) => {
        this.imageProfileUploadProgress = 0;

        // profileImages/temp/users/{uid}/{filename.extension}
        let ref = FirebaseStorage.memberProfileImageStorageRef(user.uid, file.name);
        let uploadTask = ref.put(file);
        uploadTask.on(
            "state_changed",
            (snapshot: any) => {
                runInAction(() => {
                    this.imageProfileUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                });
            },
            (error: any) => {
                this.rootStore.showErrorMessage(strings.uploadProfileImageError);
            },
            async () => {
                // On complete
                let downloadURL = await ref.getDownloadURL();
                runInAction(() => {
                    // Set temporary profileImage before being optimized
                    Firestore.usersCollection().doc(user.uid).update({ profileImage: downloadURL });
                });

                let updatedUser = new User(user);
                runInAction(() => {
                    this.userToEdit = updatedUser;
                    this.imageProfileUploadProgress = 0;
                });
            }
        );
    };

    public quellMessage(messageType: QuelledMessageType) {
        this.user.quelledMessages.push(messageType);
        FirestoreActions.updateUser(this.user);
    }

    @action
    public createStripeSubscription(billingFrequency: BillingFrequency, token: string = undefined) {
        this.subscriptionDetailStatus = SubscriptionDetailsStatus.Updating;

        let request = new CreateSubscriptionRequest();
        request.customerID = this.user.customerID;
        request.uid = this.user.uid;
        request.email = this.user.email;
        request.stripeToken = token;
        request.billingFrequency = billingFrequency;
        request.expirationTimestamp = this.user.expiration
            ? Math.floor(this.user.expiration.toDate().getTime() / 1000)
            : 0;
        request.quantity = this.user.managedTeachersCount + 1;

        try {
            console.log(request);
            FirebaseFunctions.createStripeCustomer(request);
        } catch (error) {
            this.rootStore.showErrorMessage(error);
        }
    }

    @action
    public async cancelSubscription() {
        this.subscriptionDetailStatus = SubscriptionDetailsStatus.Updating;
        await FirebaseFunctions.cancelStripeSubscription(this.user.customerID, this.customerDetails.subscription.id);
        this.updateSubscriptionDetails();
    }

    @action
    public subscribeToPendingSubscription = async () => {
        this.subscriptionDetailStatus = SubscriptionDetailsStatus.Updating;

        await FirebaseFunctions.subscribe(
            this.user.uid,
            this.user.customerID,
            this.customerDetails.subscription.id,
            this.pendingSubscription.billingFrequency,
            this.pendingSubscription.quantity,
            this.pendingSubscription.amountDueNow
        );

        runInAction(() => {
            this.pendingSubscription = undefined;
        });
    };

    @action
    public addCard(token: string) {
        this.subscriptionDetailStatus = SubscriptionDetailsStatus.Updating;
        FirebaseFunctions.addCard(this.user, token);
    }

    @action
    public async deleteCard() {
        this.subscriptionDetailStatus = SubscriptionDetailsStatus.Updating;
        FirebaseFunctions.deleteCard(this.user, this.customerDetails);
    }

    @action
    public async updateCard(token: string) {
        this.subscriptionDetailStatus = SubscriptionDetailsStatus.Updating;
        await FirebaseFunctions.updateCard(this.user, this.customerDetails, token);
    }

    @action
    public async restartSubscription() {
        this.subscriptionDetailStatus = SubscriptionDetailsStatus.Updating;
        await FirebaseFunctions.restartStripeSubscription(this.customerDetails.subscription.id, this.user.uid);
        this.updateSubscriptionDetails();
    }

    public pinActivity(activity: PinnedActivity) {
        Firestore.usersPinnedActivitesCollection(this.user.uid).add(activity);
    }

    public isUsernameAvailable = async (username: string) => {
        let snapshot = await (Firestore.usersCollection() as CollectionReference)
            .where("usernameLower", "==", username.toLowerCase())
            .get();
        return snapshot.empty;
    };

    @action
    public calculateSubscriptionAmountDue = async (quantity: number) => {
        this.fetchingProratedAmount = true;
        try {
            var responseObj = await FirebaseFunctions.calculateAmountDue(
                this.user.customerID,
                this.customerDetails.subscription.id,
                this.customerDetails.subscription.billingFrequency,
                quantity
            );

            console.log(responseObj);

            this.fetchingProratedAmount = false;
            this.pendingSubscription = responseObj.pendingSubscription;
            this.subscriptionDetailStatus = SubscriptionDetailsStatus.Loaded;
        } catch (error) {
            this.fetchingProratedAmount = false;
            this.rootStore.showErrorMessage(error);
        }
    };

    @action
    public addPendingManagedUser = async (user: PendingManagedUser) => {
        log.verbose("Adding managed teacher account");
        this.isManagedTeacherLoading = true;
        try {
            await FirebaseFunctions.addManagedUser(user, this.customerDetails.subscription.id);
        } catch (error) {
            this.isManagedTeacherLoading = false;
            this.rootStore.showErrorMessage(error);
        }
    };

    @action
    public deleteManagedUser = (user: ManagedUser | PendingManagedUser) => {
        this.managedTeachersBeingRemoved.push(user.uid);
        log.verbose("Removing managed teacher account");

        try {
            FirebaseFunctions.deleteManagedUser(user);
        } catch (error) {
            this.managedTeachersBeingRemoved.pop();
            this.rootStore.showErrorMessage(error);
        }
    };

    @action
    private setUser = async (user: User) => {
        if (user instanceof ManagedUser) {
            this.user = new ManagedUser(user);
            await this.getSubscriptionForManagedUser(user);
        } else {
            this.user = new User(user);
        }

        if (user.uid) {
            this.rootStore.refFactory.uid = user.uid;
        }

        if (this.rootStore.firebaseUser) {
            this.rootStore.authStore.setIsAnonymous(this.rootStore.firebaseUser.isAnonymous);
            this.rootStore.authStore.setIsSignedIn(!this.rootStore.firebaseUser.isAnonymous);
        }
        if (
            (!user.profileImage || user.profileImage.length === 0) &&
            this.rootStore.firebaseUser &&
            this.rootStore.firebaseUser.photoURL &&
            this.rootStore.firebaseUser.uid === this.user.uid
        ) {
            this.user.profileImage = this.rootStore.firebaseUser.photoURL;

            // Update all categories
            let docsQuery = await (Firestore.categoriesCollection() as CollectionReference)
                .where("uid", "==", user.uid)
                .get();
            if (!docsQuery.empty) {
                for (let doc of docsQuery.docs) {
                    doc.ref.update({ profileImage: this.user.profileImage });
                }
            }
            FirestoreActions.updateUser(this.user);
        }
    };

    private getSubscriptionForManagedUser = async (managedUser: ManagedUser) => {
        let managerObj = await Firestore.usersCollection().doc(managedUser.managedBy).get();
        if (managerObj.exists) {
            let manager = new User(managerObj.data());
            if (manager.customerID) {
                let user = new ManagedUser(Object.assign({}, managedUser));
                user.customerID = manager.customerID;
                this.user = user;
                this.updateSubscriptionDetails();
            }
        }
    };

    private listenForManagedAccountChanges() {
        log.verbose("Listening for managed user changes");

        const onManagedUserChangeDetected = (managedUser: ManagedUser | PendingManagedUser, changeType: string) => {
            switch (changeType) {
                case "added":
                    this.user.addManagedUser(managedUser);
                    this.setIsManagedTeacherLoading(false);
                    this.setUser(new User(this.user)); // Hack to force subscription block to re-render
                    break;

                case "modified":
                    this.user.updateManagedUser(managedUser);
                    if (
                        managedUser instanceof ManagedUser &&
                        this.userToEdit &&
                        this.userToEdit.uid === managedUser.uid
                    ) {
                        this.userToEdit = managedUser;
                    }
                    break;

                case "removed":
                    this.user.removeManagedTeacher(managedUser);
                    this.setManagedTeachersBeingRemoved(
                        this.managedTeachersBeingRemoved.filter((uid) => {
                            return uid !== managedUser.uid;
                        })
                    );

                    break;

                default:
                    break;
            }
        };

        let pendingUsersCollection =
            Firestore.pendingManagedUsersCollection() as firebase.firestore.CollectionReference;
        pendingUsersCollection.where("managedBy", "==", this.user.uid).onSnapshot(async (managedUserSnapshot) => {
            managedUserSnapshot.docChanges().forEach((docChange) => {
                let managedUser: PendingManagedUser = docChange.doc.data() as PendingManagedUser;
                onManagedUserChangeDetected(managedUser, docChange.type);
            });
        });

        let usersCollection = Firestore.usersCollection() as firebase.firestore.CollectionReference;
        usersCollection.where("managedBy", "==", this.user.uid).onSnapshot(async (managedUserSnapshot) => {
            managedUserSnapshot.docChanges().forEach((docChange) => {
                let managedUser = new ManagedUser();
                managedUser.uid = docChange.doc.id;
                Object.assign(managedUser, docChange.doc.data());
                onManagedUserChangeDetected(managedUser, docChange.type);
            });
        });
    }

    // User change listener
    private onUserChangeDetected = async (snapshot: firebase.firestore.DocumentSnapshot) => {
        if (snapshot !== undefined && snapshot.exists) {
            let data = snapshot.data();
            if (data.managedBy) {
                let managedUser = new ManagedUser(data);
                this.setUser(managedUser);
            } else {
                this.setUser(new User(data));
            }
            this.updateSubscriptionDetails();
        } else {
            log.verbose("Error setting current class list. Snapshot is null.");
        }
    };

    private async updateSubscriptionDetails() {
        if (!this.user.customerID) {
            runInAction(() => {
                this.customerDetails = undefined;
                this.subscriptionDetailStatus = SubscriptionDetailsStatus.None;
            });
            log.verbose("User does not have a current subscription.");
        } else {
            runInAction(() => {
                this.pendingSubscription = undefined;
            });

            try {
                let customerDetails = await FirebaseFunctions.getCustomerDetails(this.user.customerID);
                if (customerDetails) {
                    this.customerDetails = customerDetails;
                    this.subscriptionDetailStatus = SubscriptionDetailsStatus.Loaded;
                } else {
                    this.customerDetails = undefined;
                }
            } catch (error) {
                this.rootStore.showErrorMessage(error);
                runInAction(() => {
                    this.subscriptionDetailStatus = SubscriptionDetailsStatus.None;
                });
            }
        }

        // // Check if language preference has been updated
        // this.rootStore.setSiteLanguage(this.user.siteLanguage);

        // Search for managed accounts if this is not already managed by another
        if (!(this.user instanceof ManagedUser)) {
            this.listenForManagedAccountChanges();
        }
    }
}
