import * as React from "react";
import { Observer } from "mobx-react-lite";

// Stores
import { ActivityStore } from "stores";

// Layout
import SimpleCheckbox from "components/SimpleCheckbox";
import { strings } from "core/localizedStrings";

export interface RandomNamePickerOptions {
    removeNamesOnceSelected: boolean;
    playSoundEffect: boolean;
}

export const defaultRandomNamePickerOptions: RandomNamePickerOptions = {
    removeNamesOnceSelected: true,
    playSoundEffect: true,
};

interface RandomNamePickerOptionsProps extends React.Props<any> {
    activityStore: ActivityStore;
}

const RandomNamePickerOptions = (props: RandomNamePickerOptionsProps) => {
    const handleRemoveNamesChanged = (value: any) => {
        props.activityStore.activityOptions.randomNamePickerOptions.removeNamesOnceSelected = value;
    };

    const handlePlaySoundEffect = (value: any) => {
        props.activityStore.activityOptions.randomNamePickerOptions.playSoundEffect = value;
    };

    return (
        <Observer
            render={() => (
                <>
                    <SimpleCheckbox
                        label={strings.playSoundEffect}
                        isChecked={props.activityStore.activityOptions.randomNamePickerOptions.playSoundEffect}
                        onChange={handlePlaySoundEffect}
                    />
                    <SimpleCheckbox
                        label={strings.removeNameOnceSelected}
                        isChecked={props.activityStore.activityOptions.randomNamePickerOptions.removeNamesOnceSelected}
                        onChange={handleRemoveNamesChanged}
                    />
                </>
            )}
        />
    );
};

export default RandomNamePickerOptions;
