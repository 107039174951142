export var korean = {
    absent: "결석한",
    absentStudentsFormat: "{0} 결석한",
    accountRegistration: "계정등록",
    active: "실행되는",
    activityPreview: "실행 미리보기",
    activityPreviewMessage: "로그인해주세요. 또는 무료 계정을 등록하세요.",
    add: "추가",
    addAccount: "추가 계정",
    addCard: "카드 추가",
    addCards: "카드 추가",
    addFolder: "폴더 추가",
    addGroupStudentAccount: "학생 그룹계정추가",
    addKeyword: "키워드 추가",
    addName: "이름 추가",
    addTeacherAccount: "선생님 계정 추가",
    addToFavorites: "즐겨찾기에 추가하기",
    addWithPlus: "추가",
    additionalResources: "",
    admin: "관리",
    affirmative: "동의",
    afterTrial:
        "30일 무료 사용을 한 후에는 기본회원으로 이 사이트를 사용하실수 있고 또는 프리미엄 회원으로 업그레이드 하실수 있습니다.",
    all: "모두",
    allFieldsRequired: "모든 사항은 적으셔야합니다.",
    allowedToChangePassword: "선생님만 비밀번호 변경 가능",
    annual: "연간",
    annualSubscription: "연회원",
    answer: "대답",
    audioClipLanguageLabel: "오디오 클립 언어",
    basic: "기본",
    basicMemberCredits:
        "기본 회원은 8개월동안 사이트를 사용하실수 있으며, 모두 사용시 다음 달 사작하는 날까지 사이트 사용 제한이 있습니다.",
    billedAnnually: "일시불 결제",
    billedAnnuallyFormat: "일시불 결제 ({0})",
    billedMonthly: "월별 결제",
    billedMonthlyFormat: "월별 결제 ({0})",
    billingFrequency: "결제 횟수",
    boy: "남자",
    boys: "남자",
    browseAllCategories: "둘러보기/카테고리 수정",
    browseAllLists: "둘러보기/ 리스트 수정",
    cancel: "취소",
    cancelSubscription: "구독 취소",
    cannotAddManagedAccountsInTrial: "무료 사용중에는 계정관리를 추가할 수 없습니다.",
    cannotBeEmpty: "비울수 없습니다.",
    card: "카드",
    cardLower: "카드",
    cardsLower: "카트",
    category: "카테고리",
    category2: "카테고리 2",
    categoryCharactersNotSupported: "",
    categoryTypeNotSupported: "이 카테고리 유형은 아직 지원되지 않습니다.",
    changeEditStudents: "변경/ 학생 수정",
    changePassword: "비밀번호 변경",
    checkEmailForVerifcation: "계정 활성화를 위해 이메일을 확인해 주세요.",
    chinese: "중국어",
    className: "반 이름",
    clear: "제거",
    clearFilters: "필터 제거",
    clearSelectedImage: "선택한 이미지 제거",
    clearSelectedImages: "선택한 이미지 제거",
    clickHereTo: "계정이 없나요? 여기를 눌러주세요.",
    confirmChangeCategory: "카테고리 변경시에는 실행이 초기화 됩니다. 변경하기을 원하십니까?",
    confirmChangeClassList: "학생 목록 변경시에는 실행이 초기화 됩니다. 변경하기를 원하십니까?",
    confirmChangeTeamNumber: "팀 수를 변경시에는 실행이 초기화됩니다. 변경하기를 원하십니까?",
    confirmRestartSubscription: "회원 가입을 다시 시작하기를 원하십니까? 신용카드 결제가 {0}까지 청구되지 않습니다.",
    contact: "연락처",
    contactAdminMessage: "이 문제가 계속될시에는 사이트 운영자에게 연락해주세요.",
    contactDescription:
        "질문이나 문제 발생시에는 알려주시기 바랍니다. 메시지를 보내시면 가능한 빨리 연락 드리겠습니다.",
    contactFormSubmitted: "감사합니다. 메시지가 전송되었습니다.",
    copy: "복사",
    copyLower: "복사",
    createCardFromSelectedImage: "선택된 이미지에서 카드 만들기",
    createCardsFromSelectedImages: "선택된 이미지 {0}에서 카드 만들기",
    createCopyIn: "복사본 만들기",
    createCustomLists: "사용자 지정 목록 만들기",
    createNewList: "새 목록 만들기",
    createSelectCategory: "카테고리 만들기 또는 선택하기",
    createSelectClassList: "학생 목록 만들기 또는 선택하기",
    createStudentNameLists: "학생 이름 목록 만들기",
    createTeams: "팀 만들기",
    createdBy: "의해 만들어짐",
    createdOn: "을 만들어짐",
    creditCard: "신용카드",
    creditNeeded: "1 포인트씩 사용",
    currentSubscriptionRequiredForManagedAccounts:
        "회원구독 활성이 요구됩니다. 추가 계정을 추가하기 위해서 회원구독을 재시작해주십시오.",
    customizeHeader: "",
    customizeText: "",
    dataBeingMigratedFromBarryFunEnglish: "",
    delete: "삭제",
    deleteCategoryConfirmation: "이 카테고리를 영구 삭제하시겠습니까?",
    deleteFavoriteConfirmation: "즐겨찾기에서 내보내기를 원하십니까?",
    deleteFolderConfirmation: "이 폴더인에 포함된 모든 내용물을 영구적 삭제하기를 원하십니까?",
    deleteStudentListConfirmation: "이 목록을 내보내기를 원하십니까?",
    discount: "할인 ({0}%)",
    discounts: "할인",
    done: "끝",
    dutch: "네덜란드",
    edit: "수정",
    editProfile: "프로필 수정",
    email: "이메일",
    emailAlreadyRegistered: "이메일 계정이 이미 등록되어 있습니다.",
    emailOrUsername: "이메일 또는 사용자 이름",
    emailRequiredError: "사용자 이름은 필수입니다.",
    emailSentTo: "{0}로 이메일이 전송 되었습니다.",
    endGame: "게임 종료",
    english: "영어",
    enterEmailUsedToRegister: "이 사이트 등록시 등록한 이메일 주소를 입력해 주세요.",
    enterIncorrectAnswers: "",
    enterQuestion: "",
    enterText: "",
    enterValidEmail: "",
    enterVocabAndQuestion: "",
    enterVocabulary: "",
    existingAccount: "",
    expirationDateFormat: "만기일",
    expired: "만기된",
    expires: "만기",
    expressionInputTextField1Label: "",
    expressionInputTextField2Label: "",
    favorites: "즐겨찾기",
    flashcards: "플래시 카드",
    folderName: "폴더 이름",
    forClassroomHeader: "",
    forClassroomText: "",
    forgotPassword: "비밀번호를 잊어버리셨나요?",
    free: "무료",
    french: "프랑스",
    games: "게임",
    gender: "성별",
    german: "독일",
    girl: "여자",
    girls: "여자들",
    girlsVsBoys: "여자들 대 남자들",
    greek: "그리스",
    groupNumberFormat: "그룹",
    homePageContactMessage: "우리에게 {{질문이}} 있습니까?",
    imageOverlayGraphic: "",
    imageWithText: "",
    imageWithTextDescription: "",
    imageWithTwoText: "",
    imageWithTwoTextDescription: "",
    imagesSelectorInstructions: "",
    interactiveFlashcards: "",
    invalidEmail: "",
    invalidPassword: "",
    invalidUsername: "",
    invalidUsernameOrPassword: "",
    italian: "이탈리아어",
    japanese: "일본어",
    keywordsLabel: "키워드",
    korean: "한국어",
    language: "언어",
    languageTitle: "언어",
    learn: "",
    learnDescription: "",
    lesson: "",
    lessonDescription: "",
    lessonTypeSelectorTitle: "",
    listAccessibility: "",
    listTitleLabel: "",
    listTypeTitle: "",
    loggingIn: "",
    loginToContinueCreatingLists: "",
    loginToCopy: "",
    loginToCreateFolders: "",
    loginToCreateLists: "",
    loginToEditLists: "",
    loginToFavorite: "",
    logout: "로그 아웃",
    mainPageText: "",
    managedAccounts: "계정 관리",
    managedAccountsDescription: "",
    maxFilesUploadAttempt: "",
    membershipTypes: "",
    message: "",
    monthly: "",
    monthlySubscription: "",
    move: "",
    moveFolderTo: "",
    moveListTo: "",
    multipleChoiceInstructions: "",
    multipleLanguageSupport: "",
    mustHaveOneIncorrectAnswers: "",
    myAccount: "내 계정",
    myClasses: "",
    myImages: "",
    myLists: "",
    name: "",
    negative: "",
    newFolder: "",
    nextBillingDate: "",
    no: "",
    noAcceptableFilesFound: "",
    noCardOnFile: "등록 카드 없음",
    noCreditCardRequired: "",
    noRemainingCredits: "",
    none: "",
    notActivated: "",
    notActive: "",
    notChargedUntilFormat: "",
    notChargedUntilTrialComplete: "",
    numberOfTeams: "",
    off: "",
    ok: "",
    on: "",
    oneRemainingCredit: "",
    or: "",
    pageNotFound: "",
    password: "",
    passwordLengthError: "",
    passwordRequiredError: "",
    passwordResetEmailHasBeenSendMessage: "",
    pay: "결재 하기",
    payBalance: "",
    paymentMethod: "결재 방법",
    pendingSubscription: "",
    play: "",
    playDescription: "",
    pleaseAddCardForRestart: "",
    portuguese: "포르투갈어",
    practice: "",
    practiceDescription: "",
    premium: "프리미엄",
    premiumPriceFormatMonth: "",
    premiumPriceFormatYear: "",
    premiumTrial: "",
    preview: "",
    price: "",
    print: "",
    printDescription: "",
    printableFlashcards: "",
    private: "",
    problemSubmittingContactForm: "",
    problemUpdatingUserErrorMessage: "",
    problemUsingCredit: "",
    profile: "프로필",
    proratedAmountDue: "",
    public: "",
    publicLists: "",
    quellMessage: "",
    question: "",
    questions: "",
    randomize: "",
    recent: "",
    register: "등록",
    registerUsernamePassword: "회원 등록 아이디/비밀번호",
    rejectedFileFormat: "",
    rejectedFilesFormat: "",
    remainingCreditsFormat: "",
    removeFromFavorites: "",
    removeManagedUserAccountConfirmation: "",
    resendEmail: "",
    reset: "",
    restartSubscription: "",
    restartSubscriptionMessage: "",
    review: "",
    reviewDescription: "",
    russian: "",
    sameAsTextField1Label: "",
    sampleClass: "",
    save: "",
    search: "",
    select: "",
    selectACategory: "",
    selectFolderToAddFavoriteTo: "",
    selectStudentList: "",
    showCategoryDetails: "",
    signIn: "로그인",
    signInRegister: "로그인/ 회원등록",
    signInUsernamePassword: "로그인 아이디/비밀번호",
    signUpForFree: "",
    signUpForFreeMessage: "",
    singleTeacher: "",
    singleText: "",
    singleTextDescription: "",
    siteImages: "",
    siteLists: "",
    siteName: "KinteractiveLearning",
    sortByPopularity: "",
    sortByRecentlyCreated: "",
    sortByRelevance: "",
    sortByTitle: "",
    sortByTypeTitle: "",
    spanish: "",
    start: "",
    startSubscription: "",
    startSubscriptionAfterTrial: "",
    studentGroup: "",
    studentGroups: "",
    studentList: "",
    subject: "",
    submit: "제출",
    subscribe: "구독하다",
    subscription: "구독",
    subscriptionDescriptionFormat: "",
    subscriptionType: "",
    subtotal: "",
    support: "",
    teacher: "선생님",
    teacherCountFormat: "",
    teachers: "선생님",
    teachingTools: "",
    teamNumberFormat: "",
    tenOrMore: "10 또는 이상",
    textField1Label: "",
    textField2Label: "",
    textToSpeechSupportFormat: "",
    thousandsOfHeader: "",
    thousandsOfText: "",
    threeOrMore: "",
    titleCannotBeEmptyInfoMessage: "",
    tools: "",
    toolsDescription: "",
    totalDue: "총 금액:",
    totalManagedAccounts: "",
    totalStudentsFormat: "{0} 학생들",
    turkish: "터키어",
    twoTextOnly: "",
    twoTextOnlyDescription: "",
    type: "종류",
    unlimited: "모두 사용",
    update: "업데이트",
    updateEmailError: "이메일 주소 업로드 오류발생",
    uploadImages: "이미지 업로드",
    uploadImagesDropzoneInstructions: "",
    uploadMessage: "{0}이미지 업로드중",
    uploadProfileImageError: "프로필 이미지 업로드 오류 발생",
    useAsVocabulary: "어휘목록 더하기",
    useCredit: "포인트 사용",
    useOtherAnswersAsIncorrect: "다른 카드 사용하기\n정답",
    username: "사용자 이름",
    usernameOptional: "사용자 이름( 선택)",
    usernameRequiredError: "사용자 이름은 필수입니다.",
    usernameTaken: "사용자이름이 사용중입니다.",
    usernameUnavailable: "사용자 이름응 사용할수 없습니다.",
    vocabulary: "어휘",
    worksheetCreators: "학습표 만든이",
    worksheets: "학습표",
    yes: "네",
    page: "",
    misses: "",
    backToWorksheets: "",
    options: "",
    randomizeAndReset: "",
    introduce: "",
    quiz: "",
    changeCategory: "",
    interactive: "",
    printable: "",
    createSelectCategoryDescription: "",
    removeAllLetters: "",
    allowCardsToBeFlipped: "",
    selectCategoryBeforeActivity: "",
    getStarted: "",
    requiresFlash: "",
    onlySupportsLatinBasedLanguages: "",
    upgradeToPremium: "",
    startReview: "",
    numberOfGroups: "",
    hideAnswer: "",
    autoPlayAudio: "",
    imageBank: "",
    doubleSidedCards: "",
    ignoreFlippedCardsForReview: "",
    soft: "",
    medium: "",
    strong: "",
    continuePlaying: "",
    languageNotSupported: "",
    createLink: "",
    pinActivityToDashboard: "",
    enterName: "",
    createStudentLink: "",
    expiration: "",
    oneDay: "",
    oneWeek: "",
    never: "",
    link: "",
    copyToClipboard: "",
    pinnedActivities: "",
    studentActivityLinks: "",
    numberOfCards: "",
    matchType: "",
    imageType: "",
    otherOptions: "",
    wordImage: "",
    imageImage: "",
    wordWord: "",
    activity: "",
    seeLinkVisitors: "",
    recentHistory: "",
    startTime: "",
    completedTime: "",
    results: "",
    iDontKnow: "",
    iKnowTheAnswer: "",
    seconds: "",
    minutes: "",
    hours: "",
    pause: "",
    set: "",
    audioHeading: "",
    playAudioOnCorrect: "",
    playAudioOnWrong: "",
    answerTypeHeading: "",
    multipleChoiceAnswerTypeOption: "",
    rightOrWrongAnswerTypeOption: "",
    showNumbersHeading: "",
    showNumbersMultipleChoice: "",
    timerHeading: "",
    showTimerMultipleChoice: "",
    game: "",
    correct: "",
    wrong: "",
    teams: "",
    imagesInGrid: "",
    multipleChoiceOptions: "",
    playAudioOnSelection: "",
    classicModeOptions: "",
    selectAll: "",
    flipCardsToFront: "",
    flipCardsToBack: "",
    shuffle: "",
    showAllCards: "",
    bonusRound: "",
    crazyCupsIntroMessage: "",
    crazyCupsBonusRoundMessage: "",
    goodJob: "",
    sorryYouMissedIt: "",
    teamReadyFormat: "",
    readyToTryAgain: "",
    cupsSpeedAdjustment: "",
    textOnly: "",
    imageText: "",
    imageOnly: "",
    hideShips: "",
    useClassicMode: "",
    classicModeDescription: "",
    stop: "",
    bullsEye: "",
    removeNameOnceSelected: "",
    clearDartAfterThrow: "",
    stopBoardAfterThrow: "",
    playSoundEffect: "",
    spinForPoints: "",
    showImageProfileImage: "",
    cardType: "",
    gridSize: "",
    cardsPerPage: "",
    noListsGetStarted: "",
    noImagesGetStarted: "",
    isTraceable: "",
    twoColumns: "",
    randomizeWorksheet: "",
    createPDF: "",
    fontSize: "",
    goodJobReadyForNext: "",
    whoGoesFirst: "",
    teamGoesFirstFormat: "",
    popBalloonsWith: "",
    dartMeterSpeed: "",
    fontStyle: "",
    cardSensitivity: "",
    points: "",
    hotOrColdInstructions: "",
    inviteUsers: "",
    availableInvitesFormat: "",
    numberOfAccountsToAdd: "",
    removeSubscriptionManagerConfirmation: "",
    managedSubscription: "",
    disconnectFromAccountManager: "",
    confirmToBeManagedConfirmationFormat: "",
    pendingManagerNotFound: "",
    steal: "",
    newWord: "",
    canYouSpell: "",
    wouldLikeToStealFormat: "",
    selectSecondCategory: "",
    clearSecondCategory: "",
    borderThickness: "",
    borderColor: "",
    subscriptionIsPastDue: "",
};
