import * as React from "react";
import { useWindowSize } from "hooks/useWindowSize";

import Typography from "@material-ui/core/Typography";
import { Colors } from "./Colors";

export enum DynamicTypographyType {
    H1 = 0.04,
    H2 = 0.025,
    H3 = 0.02,
    H4 = 0.017,
    Subheading = 0.013,
    Body = 0.01,
    Body2 = 0.008
}

const SHADOW_PERCENT = 0.002;

interface DynamicTypographyProps extends React.Props<any> {
    variant: DynamicTypographyType;
    hasShadow?: boolean;
    shadowColor?: string;
    style?: any;
}

export const DynamicTypography = (props: DynamicTypographyProps) => {
    let windowSize = useWindowSize();
    let { height } = windowSize;
    let shadowSize = height * SHADOW_PERCENT;
    let shadowColor = props.shadowColor ? props.shadowColor : Colors.black;
    let shadow = props.hasShadow ? `${shadowSize}px ${shadowSize}px ${shadowSize}px ${shadowColor}` : false;

    return (
        <Typography style={Object.assign({ fontSize: height * props.variant, textShadow: shadow }, props.style)}>
            {props.children}
        </Typography>
    );
};
