import { log } from "../../";

import * as firebase from "firebase";
import "firebase/firebase-functions";

import { AudioClipVoice, LanguageType, Category } from "../../models/";
import { User, BillingFrequency } from "../../models";
import { CreateSubscriptionRequest } from "../../models/payments/HttpRequests";

// Models
import { HttpCallStatus, FetchUnknownUserResponse } from "./FunctionResponses";
import { StripeCustomerDetails } from "../../models";
import * as HttpRequests from "../../models/payments/HttpRequests";
import {
    HttpRequest,
    SearchRequest,
    SubscriptionRequestType,
    MediaRequestType,
    UserRequestType,
    SearchRequestType,
    TranslationRequestType,
} from "../../models/payments/HttpRequests";
import { ActivityType } from "models/activities/Activity";
import { AudioClip } from "models/customLists/AudioClip";
import { ManagedUser, PendingManagedUser } from "models/User";

export enum FirebaseFunctionEndpoint {
    Media = "media",
    User = "user",
    Subscription = "subscription",
    Search = "search",
    TranslateCategories = "translateCategories",
}

export default class FirebaseFunctions {
    // Media
    // #region -------------------------------
    public static fetchAudio(text: string, language: AudioClipVoice): Promise<any> {
        var requestData: HttpRequests.FetchAudioRequest = { text: text, language: language };
        return this.sendMediaRequest(MediaRequestType.GetAudioClip, requestData);
    }

    public static updateAudioClipToGoogle(audioClip: AudioClip) {
        var requestData: HttpRequests.UpdateAudioClipToGoogleRequest = { audioClip: audioClip };
        return this.sendMediaRequest(MediaRequestType.UpdateAudioClipToGoogle, requestData);
    }

    public static updateCategoryToGoogleAudio(category: Category) {
        var requestData: HttpRequests.UpdateCategoryToGoogleAudioRequest = { categoryKey: category.key };
        return this.sendMediaRequest(MediaRequestType.UpdateCategoryToGoogleAudio, requestData);
    }

    public static retryCategoryMigration(category: Category) {
        var requestData: HttpRequests.RetryCategoryRequest = { listID: category.mysqlID };
        return this.sendMediaRequest(MediaRequestType.RetryCategoryMigration, requestData);
    }
    // #endregion

    // Translate
    // #region -------------------------------
    public static translateCategories(languages: [LanguageType]): Promise<any> {
        var requestData: HttpRequests.TranslationRequest = { languages: languages };
        return this.sendTranslationRequest(TranslationRequestType.TranslateCategories, requestData);
    }
    // #endregion

    // Search
    // #region -------------------------------
    public static searchCategories(query: any): Promise<any> {
        var requestData: SearchRequest = { type: "categories", query: query };
        return this.sendSearchRequest(SearchRequestType.QueryElasticsearch, requestData);
    }

    public static searchImages(query: any): Promise<any> {
        var requestData: SearchRequest = { type: "images", query: query };
        return this.sendSearchRequest(SearchRequestType.QueryElasticsearch, requestData);
    }
    // #endregion

    // Users
    // #region -------------------------------
    public static async sendContactFormEmail(
        uid: string,
        name: string,
        email: string,
        subject: string,
        message: string
    ) {
        let requestData = {
            uid: uid,
            name: name,
            email: email,
            subject: subject,
            message: message,
        };
        return this.sendUserRequest(UserRequestType.SendContactFormEmail, requestData);
    }

    public static async verifyEmail(uid: string) {
        let requestData = { uid: uid };
        let responseData = await this.sendUserRequest(UserRequestType.VerifyEmail, requestData);
        return responseData;
    }

    public static async updateEmail(uid: string, email: string) {
        let requestData = { uid: uid, email: email };
        let responseData = await this.sendUserRequest(UserRequestType.UpdateEmail, requestData);
        return responseData;
    }

    public static async isUsernameAvailable(username: string) {
        let requestData = { username: username };
        let responseData = await this.sendUserRequest(UserRequestType.IsUsernameAvailable, requestData);
        return responseData.isAvailable;
    }

    public static async isEmailAvailable(email: string) {
        let requestData = { email: email };
        let responseData = await this.sendUserRequest(UserRequestType.IsEmailAvailable, requestData);
        return responseData.isAvailable;
    }

    public static async addManagedUser(user: PendingManagedUser, subscriptionID?: string) {
        let requestData = {
            user: user,
            subscriptionID: subscriptionID,
        };
        return this.sendUserRequest(UserRequestType.AddManagedUser, requestData);
    }

    public static deleteManagedUser(user: ManagedUser | PendingManagedUser) {
        if (user instanceof ManagedUser) {
            let requestData = { user: user.objectForFirebase };
            return this.sendUserRequest(UserRequestType.DeleteManagedUser, requestData);
        } else {
            let requestData = { user: user };
            return this.sendUserRequest(UserRequestType.DeletePendingManagedUser, requestData);
        }
    }

    public static async findUnknownUser(username: string, password: string): Promise<FetchUnknownUserResponse> {
        let requestData = { username: username, password: password };
        return this.sendUserRequest(UserRequestType.FindUnknownUser, requestData);
    }

    public static async findUsersMysqlID(customerID: string, uid: string): Promise<FetchUnknownUserResponse> {
        let requestData = { customerID: customerID, uid: uid };
        return this.sendUserRequest(UserRequestType.FindUsersMysqlID, requestData);
    }

    public static async getCreatedDate(mysqlID: string, firebaseUID: string): Promise<FetchUnknownUserResponse> {
        let requestData = { mysqlID: mysqlID, firebaseUID: firebaseUID };
        return this.sendUserRequest(UserRequestType.GetCreatedDate, requestData);
    }

    public static migrateUserDataFromBarryFunEnglish(mysqlID: number, firebaseUID: string) {
        let requestData = { mysqlID: mysqlID, firebaseUID: firebaseUID };
        return this.sendUserRequest(UserRequestType.MigrateBarryFunUser, requestData);
    }

    public static async useCredit(user: User, activity: ActivityType) {
        let requestData = { user: user.objectForFirebase, activity: activity };
        try {
            this.sendUserRequest(UserRequestType.UseCredit, requestData);
            return true;
        } catch {
            return false;
        }
    }

    public static async createTokenForMasquerade(uid: String) {
        let requestData = { uid: uid };
        return this.sendUserRequest(UserRequestType.Masquerade, requestData);
    }
    // #endregion

    // Subscription
    // #region ------------------------
    public static async createStripeCustomer(request: CreateSubscriptionRequest): Promise<any> {
        return this.sendSubscriptionRequest(SubscriptionRequestType.CreateSubscription, request);
    }

    public static deleteStripeCustomer(customerID: string, uid: string) {
        var data: any = { customerID: customerID, uid: uid };
        return this.sendSubscriptionRequest(SubscriptionRequestType.DeleteCustomer, data);
    }

    public static async getCustomerDetails(customerID: string): Promise<StripeCustomerDetails> {
        let data = { customerID: customerID };
        let details = (await this.sendSubscriptionRequest(
            SubscriptionRequestType.GetCustomerDetails,
            data
        )) as Promise<StripeCustomerDetails>;
        return new StripeCustomerDetails(details);
    }

    public static async calculateAmountDue(
        customerID: string,
        subscriptionID: string,
        billingFrequency: BillingFrequency,
        quantity: number
    ): Promise<any> {
        let data = {
            customerID: customerID,
            subscriptionID: subscriptionID,
            billingFrequency: billingFrequency,
            quantity: quantity,
        };
        return this.sendSubscriptionRequest(
            SubscriptionRequestType.CalculateAmountDue,
            data
        ) as Promise<StripeCustomerDetails>;
    }

    public static subscribe(
        uid: string,
        customerID: string,
        subscriptionID: string,
        billingFrequency: BillingFrequency,
        quantity: number,
        amountDue: number
    ): Promise<any> {
        let subscribeRequest: HttpRequests.SubscribeRequest = {
            uid: uid,
            customerID: customerID,
            subscriptionID: subscriptionID,
            billingFrequency: billingFrequency,
            quantity: quantity,
            amountDue: amountDue,
        };
        return this.sendSubscriptionRequest(SubscriptionRequestType.Subscribe, subscribeRequest);
    }

    public static async restartStripeSubscription(subscriptionID: string, uid: string) {
        let request = { subscriptionID: subscriptionID, uid: uid };
        return this.sendSubscriptionRequest(SubscriptionRequestType.RestartSubscription, request);
    }

    public static async cancelStripeSubscription(customerID: string, subscriptionID: string) {
        let data = { customerID: customerID, subscriptionID: subscriptionID };
        return this.sendSubscriptionRequest(SubscriptionRequestType.CancelSubscription, data);
    }

    public static addCard(user: User, token: string) {
        var requestObj: any = {};
        requestObj.customerID = user.customerID;
        requestObj.token = token;
        requestObj.uid = user.uid;

        return this.sendSubscriptionRequest(SubscriptionRequestType.AddCard, requestObj);
    }

    public static updateCard(user: User, customerDetails: StripeCustomerDetails, token: string) {
        var requestObj: any = {};
        requestObj.customerID = user.customerID;
        requestObj.token = token;
        requestObj.uid = user.uid;
        requestObj.cardID = customerDetails.card.id;

        if (customerDetails.subscription) {
            requestObj.subscriptionId = customerDetails.subscription.id;
            requestObj.subscriptionStatus = customerDetails.subscription.status;
        }

        return this.sendSubscriptionRequest(SubscriptionRequestType.UpdateCard, requestObj);
    }

    public static deleteCard(user: User, customerDetails: StripeCustomerDetails) {
        var requestObj: any = {};
        requestObj.customerID = user.customerID;
        requestObj.cardID = customerDetails.card.id;
        requestObj.uid = user.uid;

        return this.sendSubscriptionRequest(SubscriptionRequestType.DeleteCard, requestObj);
    }
    // #endregion

    // Firebase Function Requests
    // #region ------------------------
    private static async sendMediaRequest(requestType: MediaRequestType, data: any) {
        let request: HttpRequest = { type: requestType, requestData: data };
        return FirebaseFunctions.sendRequest(request, FirebaseFunctionEndpoint.Media);
    }

    private static async sendUserRequest(requestType: UserRequestType, data: any) {
        let request: HttpRequest = { type: requestType, requestData: data };
        return FirebaseFunctions.sendRequest(request, FirebaseFunctionEndpoint.User);
    }

    private static async sendSubscriptionRequest(requestType: SubscriptionRequestType, data: any) {
        let request: HttpRequest = { type: requestType, requestData: data };
        return FirebaseFunctions.sendRequest(request, FirebaseFunctionEndpoint.Subscription);
    }

    private static async sendSearchRequest(requestType: HttpRequests.SearchRequestType, data: any) {
        let request: HttpRequest = { type: requestType, requestData: data };
        return FirebaseFunctions.sendRequest(request, FirebaseFunctionEndpoint.Search);
    }

    private static async sendTranslationRequest(
        requestType: TranslationRequestType,
        data: HttpRequests.TranslationRequest
    ) {
        let request: HttpRequest = { type: requestType, requestData: data };
        return FirebaseFunctions.sendRequest(request, FirebaseFunctionEndpoint.TranslateCategories);
    }

    private static async sendRequest(request: any, endpoint: FirebaseFunctionEndpoint) {
        log.verbose("Sending request to: " + endpoint);
        log.verbose(request);

        try {
            var httpsCall = firebase.app().functions().httpsCallable(endpoint);
            let result = await httpsCall(request);
            if (result.data.status === HttpCallStatus.Success) {
                return result.data.data;
            } else {
                throw result.data.error;
            }
        } catch (error) {
            log.error(error);
        }
    }
    // #endregion
}
