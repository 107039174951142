import React from "react";
import ActivityState, { initialActivityState } from "../ActivityState";

export interface InteractiveFlashcardsState extends ActivityState {
    showSlideshow?: boolean;
    flippedItems: Map<string, boolean>;
    seedForShuffle?: number;
    flipCardsToFront?: () => void;
    flipCardsToBack?: () => void;
}

const initialState: InteractiveFlashcardsState = {
    ...initialActivityState,
    flippedItems: new Map<string, boolean>(),
};

const contextInitialState = {
    state: initialState,
    setState: (state: InteractiveFlashcardsState) => {},
};

export const InteractiveFlashcardsContext = React.createContext(contextInitialState);
export const InteractiveFlashcardsContextProvider = (props) => {
    const [state, setState] = React.useState<InteractiveFlashcardsState>(initialState);
    return (
        <InteractiveFlashcardsContext.Provider value={{ state, setState }}>
            {props.children}
        </InteractiveFlashcardsContext.Provider>
    );
};
