import * as React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { strings } from "../../core/localizedStrings";

var Row = require("react-flexbox-grid").Row;

export interface CardTitleEditorProps extends React.Props<any> {
    description?: string;
    title?: string;
    onEditCompleteCallback: (newTitle: string) => void;
    textField?: any;
}

@observer
export default class CardTitleEditor extends React.Component<CardTitleEditorProps> {
    protected textInput: any;
    @observable private textFieldValue: string = "";

    constructor(props: any, context: any) {
        super(props, context);

        if (this.props.title !== undefined) {
            this.textFieldValue = this.props.title.trim();
        }
    }

    // React component lifecycle events
    // ------------------------------------------
    public componentWillReceiveProps(nextProps: CardTitleEditorProps) {
        this.setTextFieldValue(nextProps.title.trim());
    }

    public componentDidMount() {
        setTimeout(() => {
            this.textInput = document.getElementById("text");
            if (this.textInput !== undefined) {
                let value = this.textInput.value;
                this.textInput.value = "";
                this.textInput.focus();
                this.textInput.value = value; // Hack to get cursor at the end of text
            }
        }, 500);
    }

    // Render UI
    // ------------------------------------------
    render() {
        let styles = {
            description: {
                fontSize: 22,
                paddingBottom: 10
            },
            okButton: {
                paddingTop: 30
            }
        };

        return (
            <div style={{ padding: 20 }}>
                <Row center="xs" style={styles.description}>
                    <Typography variant="h6">{this.props.description}</Typography>
                </Row>
                <Row center="xs">
                    <TextField
                        fullWidth={true}
                        id="text"
                        value={this.textFieldValue}
                        inputProps={{ style: { textAlign: "center", fontSize: 24, paddingTop: 30 } }}
                        onChange={this.onTextFieldChange}
                        onKeyDown={this.onKeyDown}
                    />
                </Row>
                <Row end="xs" style={styles.okButton}>
                    <Button variant="contained" onClick={this.onEditComplete}>
                        {strings.ok}
                    </Button>
                </Row>
            </div>
        );
    }

    // Mobx actions
    // ------------------------------------------
    @action
    setTextFieldValue = (text: string) => {
        this.textFieldValue = text;
    };

    // UI event handlers
    // ------------------------------------------
    private onTextFieldChange = (event: any) => {
        this.setTextFieldValue(event.currentTarget.value);
    };

    private onEditComplete = () => {
        if (this.textFieldValue) {
            let value = this.textFieldValue.trim();
            if (value.length > 0) {
                this.props.onEditCompleteCallback(this.textFieldValue);
            }
        }
    };

    private onKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            this.onEditComplete();
        }
    };
}
