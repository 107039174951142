import * as React from "react";
import { Colors } from "core/utils/Colors";
import IconButton from "@material-ui/core/IconButton";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { Tooltip } from "@material-ui/core";

interface SimpleIconButtonProps {
    icon: JSX.Element;
    color?: string | number;
    size?: "small" | "medium";
    tooltip?: string;
    onClick?: () => void;
}

export const SimpleIconButton = (props: SimpleIconButtonProps) => {
    let color: string | number = Colors.white;
    let size: "small" | "medium" = "small";
    React.useEffect(() => {
        if (props.color) {
            color = props.color;
        }
        if (props.size) {
            size = props.size;
        }
    }, []);

    if (props.tooltip) {
        return (
            <Tooltip title={props.tooltip} placement="bottom">
                <IconButton onClick={props.onClick} size={size} style={{ padding: 4 }}>
                    {props.icon}
                </IconButton>
            </Tooltip>
        );
    } else {
        return <IconButton onClick={props.onClick}>{props.icon}</IconButton>;
    }
};

export { default as Edit } from "@material-ui/icons/Edit";
export { default as Copy } from "@material-ui/icons/FileCopy";
export { default as Move } from "@material-ui/icons/Input";
export { default as Preview } from "@material-ui/icons/Search";
export { default as Image } from "@material-ui/icons/Image";
export { default as Delete } from "@material-ui/icons/Delete";
export { default as MoreVert } from "@material-ui/icons/MoreVert";
export { default as Favorite } from "@material-ui/icons/Grade";
export { default as Add } from "@material-ui/icons/Add";
export { default as Minus } from "@material-ui/icons/Remove";
export { default as Upload } from "@material-ui/icons/CloudUpload";
export { default as Close } from "@material-ui/icons/Close";
export { default as AddFolder } from "@material-ui/icons/CreateNewFolder";
export { default as AddList } from "@material-ui/icons/PlaylistAdd";
export { default as AudioOn } from "@material-ui/icons/VolumeUp";
export { default as AudioOff } from "@material-ui/icons/VolumeOff";
export { default as PublicLists } from "@material-ui/icons/Group";
export { default as SiteLists } from "@material-ui/icons/DesktopWindows";
export { default as MyLists } from "@material-ui/icons/Person";
export { default as Search } from "@material-ui/icons/Search";
export { default as Clear } from "@material-ui/icons/Clear";
export { default as Info } from "@material-ui/icons/Info";
export { default as Settings } from "@material-ui/icons/Settings";
export { default as Replay } from "@material-ui/icons/Replay";
export { default as Check } from "@material-ui/icons/Check";
export { default as Logout } from "@material-ui/icons/ExitToApp";
export { default as Support } from "@material-ui/icons/HelpOutline";
export { default as Email } from "@material-ui/icons/Email";
export { default as ExpandMore } from "@material-ui/icons/ExpandMore";
export { default as GamePad } from "@material-ui/icons/VideogameAsset";
export { default as Book } from "@material-ui/icons/Book";
export { default as Assignment } from "@material-ui/icons/Assignment";
export { default as Print } from "@material-ui/icons/Print";
export { default as CheckCircle } from "@material-ui/icons/CheckCircle";
export { default as Tool } from "@material-ui/icons/Build";
export { default as RightArrow } from "@material-ui/icons/KeyboardArrowRight";
export { default as LeftArrow } from "@material-ui/icons/KeyboardArrowLeft";
export { default as Shuffle } from "@material-ui/icons/Shuffle";
export { default as PlayArrow } from "@material-ui/icons/PlayArrow";
export { default as BlackDot } from "@material-ui/icons/FiberManualRecord";
export { default as BackArrow } from "@material-ui/icons/KeyboardBackspace";
export { default as EmptySquare } from "@material-ui/icons/CropSquare";
export { default as Menu } from "@material-ui/icons/Menu";
export { default as Category } from "@material-ui/icons/Folder";
export { default as StudentLists } from "@material-ui/icons/FolderShared";
export { default as Link } from "@material-ui/icons/InsertLink";
export { default as People } from "@material-ui/icons/People";
export { default as Pin } from "@material-ui/icons/PinDrop";
export { default as DragHandle } from "@material-ui/icons/DragHandle";
export { default as Grid } from "@material-ui/icons/Apps";
export { default as FlipToFront } from "@material-ui/icons/FlipToFront";
export { default as FlipToBack } from "@material-ui/icons/FlipToBack";
export { default as Circle } from "@material-ui/icons/FiberManualRecord";
