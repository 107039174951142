import { firebaseApp, Firestore, FirebaseStorage } from "./";

// Models
import { Student } from "../../models";
import { CategoryImage } from "../../models/customLists";

// Utils
import Utils from "../utils/Utils";

import { FIREBASE_STORAGE_URL, TEMP_CUSTOM_IMAGES_PATH } from "./constants";

export default class StorageActions {
    public static async uploadCategoryImage(imageObj: any, imageFile: File, uid: string, parentKey: string) {
        let doc = await Firestore.categoryImagesRef().add(imageObj);
        let key = doc.id;

        // Path is used to pass in variables that are used for writing data to database
        // customLists/tempImages/{uid}/{parentKey}/{key}/{guid}/{token}/{filename.extension}
        let guid = Utils.guid();
        let token = Utils.guid();
        let storagePath = `${TEMP_CUSTOM_IMAGES_PATH}/${uid}/${parentKey}/${key}/${guid}/${token}`;

        let imageRef: firebase.storage.Reference = this.refFromURL(storagePath);
        await imageRef.child(imageFile.name).put(imageFile);
        await Firestore.categoryImagesRef()
            .doc(key)
            .update({ image: "inProgress" });
    }

    public static deleteCategoryImage = async (image: CategoryImage) => {
        // log.verbose("Deleting item ref from folder for key: " + image.key);
        try {
            await Firestore.categoryImagesRef()
                .doc(image.key)
                .delete();
        } catch (error) {
            // log.error(error);
        }

        try {
            await StorageActions.refFromURL(image.storagePath).delete();
            await StorageActions.refFromURL(image.thumbnailStoragePath).delete();
        } catch (error) {
            // log.error(error);
        }
    };

    public static uploadStudentProfileImage = async (file: File, student: Student, parentKey: string) => {
        if (student.key === undefined) {
            let doc = await Firestore.studentsRef().add({ parentKey: parentKey });
            student.key = doc.id;
        }

        // profileImages/temp/students/{uid}/{student ID}/{filename.extension}
        let ref = await FirebaseStorage.studentsProfileImagesUploadStorageRef(student.key, file.name);
        await ref.put(file);

        let downloadURL = await ref.getDownloadURL();
        await Firestore.studentsRef()
            .doc(student.key)
            .update({ profileImage: downloadURL });
    };

    public static deleteStudentProfileImage = async (student: Student) => {
        if (student.profileImage) {
            let ref = await FirebaseStorage.studentsProfileImagesUploadStorageRef(student.key, student.profileImage);
            await ref.delete();
        }
        return;
    };

    public static refFromURL(path: string): firebase.storage.Reference {
        return firebaseApp.storage().refFromURL(FIREBASE_STORAGE_URL + path);
    }
}
