import { log } from "../";

import { observable, action, runInAction, computed } from "mobx";
import { persist } from "mobx-persist";

// Models
import { Firestore, FirebaseFunctions } from "../core/firebase";
import { CategoryType, StudentList, Student, TeamManager, ToolType } from "../models";
import { Category } from "../models/customLists";

// Utils
import ActivitySupport from "../core/utils/ActivitySupport";
import Activity from "models/activities/Activity";
import { defaultActivityOptions, ActivityOptions } from "models/activities/ActivityOptions";
import { Wait } from "core/utils/Wait";
import { strings } from "core/localizedStrings";
import Utils from "core/utils/Utils";
import RootStore from "./RootStore";
import TimerManager from "core/utils/TimerManager";

enum PersistentState {
    ACTIVITY_OPTIONS = "activityOptions",
    HISTORY = "history",
}

export interface RecentCategory {
    key: string;
    title: string;
}

export interface RecentClassList {
    key: string;
    title: string;
}

interface PersistentStateHistory {
    lastCategory?: string;
    lastSecondCategory?: string;
    lastStudentList?: string;
    lastLessonSelection?: CategoryType;
    recentCategories?: RecentCategory[];
    recentClassLists?: RecentClassList[];
    numberOfTeams?: number;
}

export default class ActivityStore {
    public rootStore: RootStore;
    public currentActivity: Activity; // Only set from ActivityLoader, parsed from path
    public isSettingSecondCategory: boolean = false;
    public activityPath: string;
    public currentRandomizationSeed: string = null;

    @observable isHydrated: boolean = false;

    @observable currentStudent: Student;
    @observable teamNumbersLocked = false;
    @observable optionsExpanded = false;

    @observable shouldShowStudentSnackbar: boolean = false;
    @observable shouldShowActivityLinkDialog: boolean = false;
    @observable shouldShowStudentListSelector: boolean = false;
    @observable shouldShowActivityOptions: boolean = false;
    @observable shouldShowTeamMaker: boolean = false;
    @observable didGameEnd: boolean = false;
    @observable activityLinkVisitorKey: string;
    @observable activityLoaderKey: string = Utils.guid();

    @observable shouldShowCreateListsView: boolean = false;
    @observable shouldShowBrowseListsView: boolean = false;

    @observable lessonSelection: CategoryType = CategoryType.Vocabulary;

    @observable activityOptions: ActivityOptions = defaultActivityOptions;

    @observable currentCategory: Category;
    @observable secondCategory: Category;
    @observable currentStudentList: StudentList;
    @observable teamManager: TeamManager = new TeamManager(2);

    @observable recentCategories: RecentCategory[] = [];
    @observable recentClassLists: RecentClassList[] = [];

    @computed get isOverlayShown() {
        return (
            this.shouldShowBrowseListsView ||
            this.shouldShowCreateListsView ||
            this.shouldShowStudentListSelector ||
            this.shouldShowTeamMaker ||
            this.shouldShowActivityOptions ||
            this.rootStore.confirmationMessage ||
            this.rootStore.infoMessage
        );
    }

    @computed
    public get selectedCategoryType() {
        return this.currentCategory.type === CategoryType.Lesson ? this.lessonSelection : this.currentCategory.type;
    }

    @computed
    public get teams() {
        return this.teamManager ? this.teamManager.teams : undefined;
    }

    @computed
    public get numberOfTeams() {
        if (this.teamManager && this.teamManager.teams) {
            return this.teamManager.teams.length;
        } else {
            return 2;
        }
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.getPersistentState();
    }

    // ----------------------
    // Activity Store Setters
    // #region --------------
    @action
    public setLessonSelection(value: CategoryType) {
        let shouldReset = this.lessonSelection !== value;
        this.lessonSelection = value;
        this.updatePersistentHistory({ lastLessonSelection: value });

        if (shouldReset) {
            TimerManager.clearAllTimers();
            this.activityLoaderKey = Utils.guid(); // Forces a refresh of current activity
        }
    }

    @action
    setIsSettingSecondCategory(value: boolean) {
        this.isSettingSecondCategory = value;
    }

    @action
    public setSecondCategory(value: Category) {
        this.secondCategory = value;
    }

    @action
    public setNextStudent() {
        if (this.teamManager) {
            this.currentStudent = this.teamManager.nextStudent;
        } else {
            this.currentStudent = undefined;
        }
    }

    @action
    public setNumberOfTeams(value: number) {
        let teamManager = new TeamManager(value);
        if (this.currentStudentList) {
            teamManager.addStudentsToTeams(this.currentStudentList.presentStudents);
        }
        TimerManager.clearAllTimers();
        this.activityLoaderKey = Utils.guid(); // Forces a refresh of current activity
        this.teamManager = teamManager;
        this.updatePersistentHistory({ numberOfTeams: value });
    }

    @action
    public clearAllTeamsPoints() {
        if (this.teamManager) {
            for (var team of this.teamManager.teams) {
                team.currentGamePoints = undefined;
            }
        }
    }

    @action
    public setAllTeamsPoints(value: number) {
        if (this.teamManager) {
            for (var team of this.teamManager.teams) {
                team.currentGamePoints = value;
            }
        }
    }

    public incrementTeam() {
        if (this.teamManager != null) {
            this.teamManager.incrementTeam();
        }
    }

    public nextStudent() {
        this.setShouldShowStudentSnackbar(false);
        Wait(500, () => {
            this.setNextStudent();
            if (this.currentStudent) {
                this.setShouldShowStudentSnackbar(true);
            }
        });
    }

    @action
    public setTeamNumbersLocked(value: boolean) {
        this.teamNumbersLocked = value;
    }

    @action
    public setTeamManager(value: TeamManager) {
        this.teamManager = value;
        TimerManager.clearAllTimers();
        this.activityLoaderKey = Utils.guid(); // Forces a refresh of current activity
    }

    @action
    public setShouldShowCreateListsView(value: boolean) {
        this.shouldShowCreateListsView = value;
        this.shouldShowStudentSnackbar = false;
        if (this.shouldShowCreateListsView) {
            this.shouldShowBrowseListsView = false;
        }
    }

    @action
    public setShouldShowBrowseListsView(value: boolean) {
        this.shouldShowBrowseListsView = value;
        this.shouldShowStudentSnackbar = false;
        if (this.shouldShowBrowseListsView) {
            this.shouldShowCreateListsView = false;
        }
    }

    @action
    public setShouldShowStudentListSelector(value: boolean) {
        this.shouldShowStudentListSelector = value;
    }

    @action
    public setShouldShowActivityLinkDialog(value: boolean) {
        this.shouldShowActivityLinkDialog = value;
    }

    @action
    public setShouldShowStudentSnackbar(value: boolean) {
        this.shouldShowStudentSnackbar = value;
    }

    @action
    public setDidGameEnd(value: boolean) {
        this.didGameEnd = value;
    }

    @action
    public setShouldShowTeamMaker(value: boolean) {
        this.shouldShowTeamMaker = value;
        this.setShouldShowStudentSnackbar(false);
    }

    @action
    public toggleOptions() {
        this.optionsExpanded = !this.optionsExpanded;
    }

    @action
    public setCurrentCategory = async (categoryKey: string, activityLinkVisitorKey: string = undefined) => {
        if (!categoryKey) {
            if (this.isSettingSecondCategory) {
                this.secondCategory = undefined;
                this.activityLinkVisitorKey = undefined;
                this.isSettingSecondCategory = false;
                this.activityLoaderKey = Utils.guid(); // Forces a refresh of current activity
            } else {
                this.currentCategory = undefined;
                this.activityLinkVisitorKey = undefined;
            }

            return;
        }

        this.activityLinkVisitorKey = activityLinkVisitorKey;

        if (this.isSettingSecondCategory) {
            if (this.secondCategory) {
                let found = this.recentCategories.filter((recentCategory) => {
                    return recentCategory.key === this.secondCategory.key;
                });
                if (found.length === 0) {
                    this.recentCategories.unshift({ key: this.secondCategory.key, title: this.secondCategory.title });
                }
            }

            await Firestore.categoriesCollection()
                .doc(categoryKey)
                .onSnapshot((snap) => {
                    log.verbose("Second category change detected.");
                    if (snap === undefined || snap === null) {
                        this.isSettingSecondCategory = true;
                        this.setCurrentCategory(undefined);
                    } else {
                        let category = new Category(snap.data());
                        category.key = snap.id;

                        runInAction(() => {
                            this.secondCategory = category;
                        });
                    }
                    this.updatePersistentHistory({
                        recentCategories: this.recentCategories,
                        lastSecondCategory: this.secondCategory.key,
                    });
                    this.isSettingSecondCategory = false;
                    log.verbose(this.secondCategory);
                    this.setShouldShowBrowseListsView(false);
                    TimerManager.clearAllTimers();
                    this.activityLoaderKey = Utils.guid(); // Forces a refresh of current activity
                });
        } else {
            if (this.currentCategory) {
                let found = this.recentCategories.filter((recentCategory) => {
                    return recentCategory.key === this.currentCategory.key;
                });
                if (found.length === 0) {
                    this.recentCategories.unshift({ key: this.currentCategory.key, title: this.currentCategory.title });
                    this.updatePersistentHistory({ recentCategories: this.recentCategories });
                }
            }

            await Firestore.categoriesCollection()
                .doc(categoryKey)
                .onSnapshot((snap) => {
                    this.updatePersistentHistory({ lastCategory: categoryKey });
                    log.verbose("Current category change detected.");
                    if (snap === undefined || snap === null) {
                        this.setCurrentCategory(undefined);
                    } else {
                        let category = new Category(snap.data());
                        category.key = snap.id;

                        let shouldUpdateCategory = true;
                        if (this.currentActivity !== undefined) {
                            if (!ActivitySupport.keyboardsSupportLanguage(this.currentActivity, category)) {
                                this.rootStore.showInfoMessage({ text: strings.languageNotSupported });
                                shouldUpdateCategory = false;
                            }
                        }
                        if (shouldUpdateCategory) {
                            this.currentCategory = category;

                            let itemsWithoutAudio = category.items.filter((item) => {
                                if (category.isVocabularyCategory) {
                                    return !item.audio && !item.answerAudio;
                                } else {
                                    return !item.questionAudio || !item.answerAudio;
                                }
                            });
                            if (!category.hasGoogleAudio || itemsWithoutAudio.length > 0) {
                                FirebaseFunctions.updateCategoryToGoogleAudio(category);
                            }
                        }
                    }

                    this.setShouldShowBrowseListsView(false);
                    TimerManager.clearAllTimers();
                    this.activityLoaderKey = Utils.guid(); // Forces a refresh of current activity
                });
        }

        this.recentCategories = this.recentCategories.filter((item) => {
            return item.key !== categoryKey;
        });
        this.recentCategories.splice(5);
        this.updatePersistentHistory({ recentCategories: this.recentCategories });

        this.setShouldShowBrowseListsView(false);
    };

    public setSampleStudentList = (studentList: StudentList) => {
        this.setStudentListAndRebuildTeams(studentList);
    };

    public setCurrentStudentList = async (classListKey: string) => {
        // If there was an existing list add it to the recent history
        if (this.currentStudentList && this.currentStudentList.key && this.currentStudentList.title) {
            this.recentClassLists.unshift({
                key: this.currentStudentList.key,
                title: this.currentStudentList.title,
            });
            this.updatePersistentHistory({ recentClassLists: this.recentClassLists });
        }

        // Check if list cleared
        if (!classListKey) {
            this.currentStudentList = undefined;
            this.shouldShowStudentSnackbar = false;
            this.currentStudent = undefined;
            this.setNumberOfTeams(this.numberOfTeams > 1 ? this.numberOfTeams : 2);
            this.updatePersistentHistory({ lastStudentList: null });
        } else {
            // Fetch class from Firestore
            this.updatePersistentHistory({ lastStudentList: classListKey });
            let studentListSnapshot = await Firestore.userDocForID(this.rootStore.userStore.user.uid)
                .collection("studentLists")
                .doc(classListKey)
                .get();
            if (studentListSnapshot.exists) {
                let classList = new StudentList(studentListSnapshot.data());
                classList.key = studentListSnapshot.id;

                let querySnapshot = await (Firestore.studentsRef() as firebase.firestore.CollectionReference)
                    .where("parentKey", "==", classListKey)
                    .get();
                if (!querySnapshot.empty) {
                    classList.students = [];
                    querySnapshot.docChanges().forEach((docChange) => {
                        let student = new Student(docChange.doc.data());
                        student.key = docChange.doc.id;
                        classList.students.push(student);
                    });

                    // If selected class is in recent history, remove it
                    this.recentClassLists = this.recentClassLists.filter((item) => {
                        return item.key !== classList.key;
                    });

                    // Limit to 5 in the history
                    this.recentClassLists.splice(5);
                    this.updatePersistentHistory({ recentClassLists: this.recentClassLists });
                    this.setStudentListAndRebuildTeams(classList);
                }
            } else {
                log.error("Student list not found for key: " + classListKey);
                this.updatePersistentHistory({ lastStudentList: null });
            }
        }
    };

    public setCurrentActivity = (activity: Activity) => {
        let shouldSet = true;
        if (this.currentCategory !== undefined && activity !== undefined) {
            if (ActivitySupport.requiresFlash(activity)) {
                this.rootStore.showInfoMessage({
                    text: "Flash has been discontinued. This activity will be updated to be Flash free in the coming weeks.",
                });
                shouldSet = false;
            } else if (!ActivitySupport.keyboardsSupportLanguage(activity, this.currentCategory)) {
                this.rootStore.showInfoMessage({ text: strings.languageNotSupported });
                shouldSet = false;
            }
        }

        if (shouldSet) {
            this.setShouldShowStudentListSelector(false);
            this.setShouldShowTeamMaker(false);
            this.rootStore.authStore.setShouldShowUseCreditDialog(false);
            this.rootStore.setRoute(activity.navigationPath);
        }

        this.activityLinkVisitorKey = undefined;
    };

    private setStudentListAndRebuildTeams(studentList: StudentList) {
        log.verbose("Rebuilding teams");
        this.currentStudentList = Object.assign(new StudentList(studentList.details), studentList);
        if (
            this.currentActivity &&
            ActivitySupport.supportsTeams(this.currentActivity) &&
            this.currentActivity.activityType !== ToolType.Scoreboard
        ) {
            Wait(300, () => {
                // this.shouldShowTeamMaker = true;
                if (this.teamManager) {
                    // Forces a rebuilding of teams
                    this.setNumberOfTeams(this.numberOfTeams > 1 ? this.numberOfTeams : 2);
                }
            });
        } else {
            this.setNumberOfTeams(this.numberOfTeams ? this.numberOfTeams : 2);
        }
    }

    private getPersistentState = async () => {
        if (
            (this.rootStore.authStore.isSignedIn || this.rootStore.authStore.isAnonymous) &&
            this.rootStore.userStore.user.uid
        ) {
            let persistentStateCollection = Firestore.usersPersistentStateCollection(this.rootStore.userStore.user.uid);
            let snapshot = await persistentStateCollection.doc(PersistentState.ACTIVITY_OPTIONS).get();
            if (snapshot.exists && snapshot.data()) {
                let options = snapshot.data();
                for (const [key, defaultOptions] of Object.entries(defaultActivityOptions)) {
                    let savedOptions = options[key];
                    if (savedOptions === undefined) {
                        // Options don't exist use default
                        options[key] = defaultOptions;
                    } else {
                        // Check if new options have been added
                        for (const [optionKey, defaultOptionValue] of Object.entries(defaultOptions)) {
                            if (savedOptions[optionKey] === undefined) {
                                savedOptions[optionKey] = defaultOptionValue;
                            }
                        }
                    }
                }
                this.activityOptions = options;
            }

            snapshot = await persistentStateCollection.doc(PersistentState.HISTORY).get();
            if (snapshot.exists) {
                let history = snapshot.data() as PersistentStateHistory;

                if (history.numberOfTeams) {
                    this.setNumberOfTeams(history.numberOfTeams);
                }

                if (history.lastCategory) {
                    await this.setCurrentCategory(history.lastCategory);
                }

                if (history.lastSecondCategory) {
                    this.isSettingSecondCategory = true;
                    await this.setCurrentCategory(history.lastSecondCategory);
                }

                if (history.lastStudentList) {
                    await this.setCurrentStudentList(history.lastStudentList);
                }

                if (history.lastLessonSelection) {
                    this.lessonSelection = history.lastLessonSelection;
                }
                if (history.recentCategories) {
                    this.recentCategories = history.recentCategories;
                }
                if (history.recentClassLists) {
                    this.recentClassLists = history.recentClassLists;
                }
            }
        }
        this.isHydrated = true;
    };

    public updateOptions = () => {
        if (this.rootStore.authStore.isSignedIn && this.rootStore.userStore.user.uid) {
            let persistentStateCollection = Firestore.usersPersistentStateCollection(this.rootStore.userStore.user.uid);
            persistentStateCollection.doc(PersistentState.ACTIVITY_OPTIONS).set(this.activityOptions);
        }
    };

    private updatePersistentHistory = (history: PersistentStateHistory) => {
        if (this.rootStore.authStore.isSignedIn && this.rootStore.userStore.user.uid) {
            let persistentStateCollection = Firestore.usersPersistentStateCollection(this.rootStore.userStore.user.uid);
            persistentStateCollection.doc(PersistentState.HISTORY).set(history, { merge: true });
        }
    };
}
