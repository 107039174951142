import { log } from "../../";

import * as React from "react";

import Typography from "@material-ui/core/Typography";

import { StripeCustomerDetails } from "../../models";
import { Button, DialogContent, DialogTitle, Grid, Paper, TextField } from "@material-ui/core";
import { Observer } from "mobx-react-lite";
import { strings } from "core/localizedStrings";
import { RootStore } from "stores";
import PendingSubscriptionBlock from "./PendingSubscriptionBlock";

export interface AddManagedUsersFormProps extends React.Props<any> {
    onUpdated: (value: number) => void;
    onClose: () => void;
    customerDetails?: StripeCustomerDetails;
    rootStore: RootStore;
}

const AddManagedUsersForm = (props: AddManagedUsersFormProps) => {
    const [quantityToAdd, setQuantityToAdd] = React.useState(1);

    const handleQuantityChanged = (event: any) => {
        let value = parseInt(event.target.value);
        if (value > 100) {
            value = 100;
        }
        setQuantityToAdd(value);
    };

    const handleAddQuantity = () => {
        props.onUpdated(quantityToAdd + props.customerDetails?.subscription?.quantity);
    };

    return (
        <Observer
            render={() => (
                <Paper elevation={2} variant="outlined" style={{ padding: "2px 5px", minWidth: 300, minHeight: 250 }}>
                    <DialogTitle>{strings.addTeacherAccount}</DialogTitle>
                    <DialogContent>
                        <Grid container alignItems="center">
                            <Grid item style={{ paddingRight: 10 }}>
                                <Typography variant={"subtitle1"}>{strings.numberOfAccountsToAdd + ":"}</Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    inputProps={{ style: { textAlign: "right" } }}
                                    style={{ width: 40, paddingRight: 10, fontSize: 18 }}
                                    value={quantityToAdd}
                                    onChange={handleQuantityChanged}
                                    type="number"
                                ></TextField>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" onClick={handleAddQuantity}>
                                    {strings.ok}
                                </Button>
                            </Grid>
                            <Grid item>
                                <PendingSubscriptionBlock store={props.rootStore.userStore} onClose={props.onClose} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Paper>
            )}
        />
    );
};

export default AddManagedUsersForm;
