import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { action } from "mobx";
// import DevTools from "mobx-react-devtools";

// Models
import { Game, Worksheet, Quiz, Tool, InteractiveWorksheet, FlashcardActivity } from "./models";

// Components
import SiteAppBar from "./components/SiteAppBar";
import { BackgroundImage } from "./core/backgroundImage/backgroundImage";
import MessageBoxDialogs from "./components/MessageBoxDialogs";
import LoadingScreen from "./components/LoadingScreen";
import StudentListSelector from "components/studentListSelector/StudentListSelector";
import ActivityLoader from "./components/activities/ActivityLoader";

// Utils
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { LazyLoader } from "core/utils/LazyLoading";

// Stores
import RootStore from "./stores/RootStore";

// Routes
import Home from "./routes/home/Home";
import { Admin } from "./routes/admin/Admin";
import Translations from "./routes/admin/Translations";
import VocabularyTranslations from "./routes/admin/VocabularyTranslations";
import LessonTranslations from "./routes/admin/LessonTranslations";
import { MyAccount } from "./routes/myAccount/MyAccount";
import ContactPage from "./routes/contact/ContactPage";
import RegistrationPage from "./routes/RegistrationPage";

// Constants
import {
    ADMIN_PATH,
    HOME_PATH,
    MY_ACCOUNT_PATH,
    CONTACT_PATH,
    REGISTRATION_PATH,
    TRANSLATIONS_PATH,
    LESSONS_TRANSLATIONS_PATH,
    VOCABULARY_TRANSLATIONS_PATH,
    ACTIVITY_LINK_PATH,
} from "./constants";
import Activity from "models/activities/Activity";
import ActivityLinkWaitingRoom from "ActivityLinkWaitingRoom";
import SimpleSnackbar from "components/SimpleSnackbar";

// Lazy Imports
const CategoryMenu = React.lazy(() => import("components/categoryMenu/CategoryMenu"));
const TeamMaker = React.lazy(() => import("components/activities/tools/teamMaker/TeamMaker"));

interface AppProps {
    children: any;
    history: any;
    rootStore: RootStore;
}

interface RouterContext {
    router: any;
}

@inject("rootStore")
@observer
class App extends React.Component<AppProps> {
    context: RouterContext;
    @observable key = Math.random();
    update = action(() => {
        this.key = Math.random();
    });

    constructor(props: AppProps, context: any) {
        super(props, context);
        this.props.rootStore.setApp(this);
    }

    componentDidMount() {
        this.setState({ key: Math.random() });
        this.props.history.listen((location) => {
            let rootStore = this.props.rootStore;
            rootStore.setShowLoadingScreen(false);
            rootStore.showConfirmationMessage(undefined);
            rootStore.showErrorMessage(undefined);
            rootStore.showInfoMessage(undefined);
            rootStore.showLoginRequiredMessage(undefined);
            rootStore.authStore.setShouldShowModalRegisterDialog(false);
            rootStore.authStore.setShouldShowSignInDialog(false);
        });
    }

    componentWillReceiveProps(nextProps: AppProps) {
        // if (auth.authenticated && !nextProps.auth.authenticated) {
        //   this.context.router.replace(POST_SIGN_OUT_PATH);
        // }
        // else if (!auth.authenticated && nextProps.auth.authenticated) {
        //   this.context.router.replace(POST_SIGN_IN_PATH);
        // }
    }

    render() {
        let { rootStore } = this.props;
        let { activityStore, userStore } = rootStore;

        if (this.props.rootStore.currentRoute) {
            let path = this.props.rootStore.currentRoute;
            this.props.rootStore.setRoute(undefined);
            return <Redirect to={path} push={true} />;
        }

        return (
            <DndProvider backend={HTML5Backend} key={this.key}>
                {/* <DevTools /> */}
                <BackgroundImage />
                <LoadingScreen rootStore={rootStore} />
                <SiteAppBar rootStore={rootStore} />
                <MessageBoxDialogs rootStore={rootStore} />
                <SimpleSnackbar rootStore={rootStore} />
                {activityStore && (activityStore.shouldShowBrowseListsView || activityStore.shouldShowCreateListsView) && (
                    <LazyLoader>
                        <CategoryMenu />
                    </LazyLoader>
                )}
                {activityStore && activityStore.shouldShowTeamMaker && (
                    <LazyLoader>
                        <TeamMaker />
                    </LazyLoader>
                )}
                {activityStore && activityStore.shouldShowStudentListSelector && (
                    <LazyLoader>
                        <StudentListSelector store={rootStore.studentListStore} />
                    </LazyLoader>
                )}
                {this.props.children}
                <Route component={Admin} path={ADMIN_PATH} />
                {userStore && userStore.user && userStore.user.isTranslator && (
                    <>
                        <Route exact={true} component={Translations} path={TRANSLATIONS_PATH + "/:language"} />
                        <Route
                            exact={true}
                            component={VocabularyTranslations}
                            path={VOCABULARY_TRANSLATIONS_PATH + "/:language"}
                        />
                        <Route
                            exact={true}
                            component={LessonTranslations}
                            path={LESSONS_TRANSLATIONS_PATH + "/:language"}
                        />
                    </>
                )}
                <Route exact={true} component={Home} path={HOME_PATH} />
                <Route
                    exact={true}
                    component={ActivityLinkWaitingRoom}
                    path={ACTIVITY_LINK_PATH + "/:activityLinkID"}
                />
                <Route component={MyAccount} path={MY_ACCOUNT_PATH} />
                <Route component={ContactPage} path={CONTACT_PATH} />
                <Route component={RegistrationPage} path={REGISTRATION_PATH} />
                {this.routesForItems(FlashcardActivity.allItems)}
                {this.routesForItems(InteractiveWorksheet.allItems)}
                {this.routesForItems(Game.allItems)}
                {this.routesForItems(Quiz.allItems)}
                {this.routesForItems(Worksheet.allItems)}
                {this.toolsRoutes()}
            </DndProvider>
        );
    }

    private routesForItems = (items: Activity[]) => {
        return items.map((item: Activity, index) => (
            <Route key={index} component={ActivityLoader} path={item.navigationPath} />
        ));
    };

    private toolsRoutes = () => {
        let toolRoutes = Tool.allItems.map((item: Tool, index) => (
            <Route key={index} component={ActivityLoader} path={item.navigationPath} />
        ));
        return toolRoutes;
    };
}

export default App;
