import * as React from "react";

import Button from "@material-ui/core/Button";

import { strings } from "../core/localizedStrings";

var Row = require("react-flexbox-grid").Row;

export enum DialogButtonsType {
    OK,
    YesNo,
    OKCancel,
    StealNew,
}

interface DialogButtonProps {
    variant: DialogButtonsType;
    onConfirmCallback?: () => void;
    onDenyCallback?: () => void;
}

const styles = {
    buttonStyle: {
        marginTop: 30,
        marginBottom: 10,
        marginLeft: 5,
        marginRight: 5,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "pre",
    } as React.CSSProperties,
};

export const DialogButtons = (props: DialogButtonProps) => {
    let affirmativeText = strings.ok;
    let negativeText = "";

    if (props.variant !== DialogButtonsType.OK) {
        switch (props.variant) {
            case DialogButtonsType.YesNo:
                affirmativeText = strings.yes;
                negativeText = strings.no;
                break;
            case DialogButtonsType.OKCancel:
                affirmativeText = strings.ok;
                negativeText = strings.cancel;
                break;
            case DialogButtonsType.StealNew:
                affirmativeText = strings.steal;
                negativeText = strings.newWord;
                break;
        }
    }
    return (
        <Row end="xs">
            {props.variant !== DialogButtonsType.OK && (
                <Button variant="contained" onClick={props.onDenyCallback} style={styles.buttonStyle}>
                    {negativeText}
                </Button>
            )}
            <Button variant="contained" color="primary" onClick={props.onConfirmCallback} style={styles.buttonStyle}>
                {affirmativeText}
            </Button>
        </Row>
    );
};
