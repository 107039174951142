import { log } from "../../";
import { action } from "mobx";

import SearchResultFolder, { PAGE_SIZE } from "./SearchResultFolder";

// Models
import { Folder, SortByType, LanguageType, CategoryType, Category } from "models";

// Utils
import FirebaseFunctions from "core/firebase/FirebaseFunctions";
import Firestore from "core/firebase/Firestore";
import { ElasticsearchQueryBuilder } from "core/search/ElasticsearchQueryBuilder";

export interface ElasticsearchHit {
    _id: string;
    _source: ElasticsearchSource;
}

interface ElasticsearchSource {
    created: string;
    title: string;
}

export default class CategoriesSearchResultFolder extends SearchResultFolder {
    updateFilters = (sortByType: SortByType, language: LanguageType, listType: CategoryType) => {
        let changeDetected = false;
        if (this.sortByType !== sortByType || this.languageFilter !== language || this.listTypeFilter !== listType) {
            changeDetected = true;
        }

        this.sortByType = sortByType;
        this.languageFilter = language;
        this.listTypeFilter = listType;

        if (this.searchText && changeDetected) {
            this.lastIndexFetched = -1;
            this.search(this.searchText);
        }
    };

    checkShouldFetchMore() {
        if (this.lastIndexFetched < this.totalHits - 1) {
            this.startSearch();
        }
    }

    // Search
    // ---------------------
    @action
    async search(text: string) {
        if (this.isQuerying || this.isLoading) {
            return;
        }

        if (this.currentFolder) {
            this.currentFolder.reset();
        } else {
            this.currentFolder = new Folder();
        }

        if (this.searchText !== text) {
            this.lastIndexFetched = -1;
            this.searchText = text;
        }

        await this.startSearch();
    }

    private async startSearch() {
        let queryBuilder = new ElasticsearchQueryBuilder(this.rootStore.userStore.user.uid);
        let query = queryBuilder.createCategoryQueryForTab(
            this.lastIndexFetched + 1,
            PAGE_SIZE,
            this.searchText,
            this.tabType,
            this.sortByType,
            this.languageFilter,
            this.listTypeFilter
        );

        try {
            let queryString = JSON.stringify(query);
            log.verbose(queryString);

            this.setIsQuerying(true);
            let response = await FirebaseFunctions.searchCategories(query);
            this.setIsQuerying(false);

            if (response && response.hits && response.hits.length > 0) {
                this.setTotalHits(response.total);
                this.fetchCategories(response.hits);
            }
            log.verbose(response);
        } catch (error) {
            log.error(error);
        }
    }

    @action
    private fetchCategories(hits: ElasticsearchHit[]) {
        let totalItemsToFetch = hits.length;
        let fetchCount = 0;
        this.setIsLoading(true);
        hits.forEach((hit) => {
            let categoriesRef = Firestore.categoriesCollection();
            let unsubscribeCallback = categoriesRef.doc(hit._id).onSnapshot((docSnapshot) => {
                let category = new Category(docSnapshot.data());
                category.key = docSnapshot.id;
                if (!docSnapshot.exists) {
                    this.currentFolder.removeItem(category);
                } else {
                    this.currentFolder.addItem(category);
                }

                fetchCount += 1;
                if (fetchCount === totalItemsToFetch) {
                    this.setIsLoading(false);
                }

                this.lastIndexFetched += 1;
            });
            this.currentFolder.itemsUnsubscribeCallbacks.push(unsubscribeCallback);
        });
    }
}
