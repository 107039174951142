import Utils from "core/utils/Utils";
import { ActivityCategoryItem } from "models/customLists/CategoryItem";
import React from "react";

export default interface ActivityState {
    activityItems?: ActivityCategoryItem[];
    currentItemIndex: number;
    isStarted: boolean;
    isComplete: boolean;
    wrongCount: number;
    correctCount: number;
    randomizationSeed: string;
    containerKey: string;
    activityCompleteMessage?: string;
}

export const initialActivityState: ActivityState = {
    isStarted: false,
    isComplete: false,
    currentItemIndex: 0,
    wrongCount: 0,
    correctCount: 0,
    randomizationSeed: Utils.guid(),
    containerKey: Utils.guid(),
};

const contextInitialState = {
    activityState: initialActivityState,
    setActivityState: (state: ActivityState) => {},
};

export const ActivityStateContext = React.createContext(contextInitialState);
export const ActivityStateContextProvider = (props) => {
    const [activityState, setActivityState] = React.useState<ActivityState>(initialActivityState);
    return (
        <ActivityStateContext.Provider value={{ activityState, setActivityState }}>
            {props.children}
        </ActivityStateContext.Provider>
    );
};
