import * as React from "react";
import { Observer } from "mobx-react-lite";
import * as firebase from "firebase";

// Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// Components
import SimpleRadio from "components/SimpleRadio";

import { RootStore } from "stores";
import { strings } from "core/localizedStrings";
import * as Icons from "core/utils/Icons";
import { Row } from "layout";
import { Firestore } from "core/firebase";

export interface ActivityLinkDialogProps extends React.Props<any> {
    rootStore: RootStore;
}

enum ExpirationType {
    ONE_DAY = "oneDay",
    ONE_WEEK = "oneWeek",
    NEVER = "never",
}

const ActivityLinkDialog = (props: ActivityLinkDialogProps) => {
    let { rootStore } = props;
    let { activityStore } = rootStore;
    let expirationValue = ExpirationType.ONE_DAY;
    let [generatedLink, setGeneratedLink] = React.useState<string>(undefined);

    const handleCreateLink = async () => {
        // Calculate expiration
        let now = new Date();
        let expiration = null;
        if (expirationValue !== ExpirationType.NEVER) {
            let days = expirationValue === ExpirationType.ONE_DAY ? 1 : 7;
            expiration = new Date(now.getTime() + 1000 * 60 * 60 * 24 * days);
        }

        let activityLink = {
            activityName: activityStore.currentActivity.activityType,
            categoryName: activityStore.currentCategory.title,
            uid: props.rootStore.authStore.firebaseUser.uid,
            created: firebase.firestore.Timestamp.fromDate(now),
            expiration: expiration,
            path: activityStore.activityPath,
            categoryKey: activityStore.currentCategory.key,
            randomizationSeed: activityStore.currentRandomizationSeed,
        };
        let doc = await Firestore.activityLinksCollection().add(activityLink);
        console.log(window.location);
        let location = window.location;
        setGeneratedLink(`${location.protocol}//${location.host}/a/${doc.id}`);
    };

    const handleCopyToClipboardClicked = () => {};

    const handleExpirationChanged = (value: string) => {
        expirationValue = value as ExpirationType;
    };

    if (!activityStore || !activityStore.currentActivity) {
        return null;
    }

    if (!activityStore.currentCategory) {
        return null;
    }

    return (
        <Observer
            render={() => (
                <Dialog
                    open={activityStore.shouldShowActivityLinkDialog}
                    onBackdropClick={() => {
                        activityStore.setShouldShowActivityLinkDialog(false);
                    }}
                >
                    <DialogTitle>
                        <Row middle="xs">
                            {strings.createStudentLink}
                            <Icons.Link style={{ paddingLeft: 5 }}></Icons.Link>
                        </Row>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {
                                "Create a link that can be shared with students. Students will be able to access this content without an account as long as your account is active and the link has not expired. You can have up to 10 active links at one time. Links can be managed in the 'My Account' section or on your dashboard."
                            }
                        </DialogContentText>
                        <Typography variant="subtitle1">
                            {`Activity: ${activityStore.currentActivity.activityType}`}
                        </Typography>
                        <Typography>{`Category: ${activityStore.currentCategory.title}`}</Typography>
                        <SimpleRadio
                            title={`${strings.expiration}:`}
                            labels={[strings.oneDay, strings.oneWeek, strings.never]}
                            values={[ExpirationType.ONE_DAY, ExpirationType.ONE_WEEK, ExpirationType.NEVER]}
                            onValueChanged={handleExpirationChanged}
                        />
                        {generatedLink && (
                            <div style={{ paddingTop: 20 }}>
                                <Typography variant="subtitle1">{`${strings.link}:`}</Typography>
                                <Row middle="xs" style={{ marginTop: -10 }}>
                                    <Link href={generatedLink} variant="body2">
                                        {generatedLink}
                                    </Link>
                                    <Tooltip title={strings.copyToClipboard} placement="bottom">
                                        <IconButton onClick={handleCopyToClipboardClicked}>
                                            <Icons.Copy />
                                        </IconButton>
                                    </Tooltip>
                                </Row>
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {!generatedLink && (
                            <Button onClick={handleCreateLink} color="primary" autoFocus>
                                {strings.createLink}
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}
        />
    );
};

export default ActivityLinkDialog;
