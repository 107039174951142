import { log } from "../../";

import * as React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import StudentListStore from "../../stores/StudentListStore";

import { strings } from "../../core/localizedStrings";

import ProfileImageDropZone from "../ProfileImageDropZone";
import { Student, StudentGender } from "../../models";

import { Row } from "layout";

export interface EditStudentDialogProps extends React.Props<any> {
    student: Student;
    store: StudentListStore;
}

@observer
export default class EditStudentDialog extends React.Component<EditStudentDialogProps, {}> {
    private name: string;
    private textInput: any;
    @observable private gender: StudentGender;

    constructor(props: any, context: any) {
        super(props, context);

        this.name = this.props.student.name;
        this.gender = this.props.student.gender;
    }

    public componentDidMount() {
        setTimeout(() => {
            this.textInput = document.getElementById("nameTextField");
            if (this.textInput !== undefined) {
                let value = this.textInput.value;
                this.textInput.value = "";
                this.textInput.focus();
                this.textInput.value = value; // Hack to get cursor at the end of text
            }
        }, 500);
    }

    public render() {
        log.verbose("Rendering EditStudentDialog");
        const styles = {
            textFieldStyle: {
                fontSize: 22,
                height: 80
            }
        };

        log.verbose(this.props.student);
        if (this.props.student.profileImageDownloadURL === undefined) {
            log.verbose("Undefined");
        }
        return (
            <div style={{ padding: 20 }}>
                {this.props.student.key || !this.props.store.rootStore.authStore.isSignedIn ? (
                    <Row center="xs" style={{ margin: "0 auto" }}>
                        <ProfileImageDropZone
                            onImageDroppedCallback={this.onImageDropped}
                            profileImageURL={this.props.student.profileImageDownloadURL}
                            imageProfileUploadProgress={this.props.store.imageProfileUploadProgress}
                            rootStore={this.props.store.rootStore}
                        />
                    </Row>
                ) : null}
                <Row>
                    <TextField
                        label={strings.name}
                        defaultValue={this.props.student.name}
                        fullWidth={true}
                        id="nameTextField"
                        style={styles.textFieldStyle}
                        onChange={this.onNameTextChanged}
                        onKeyDown={this.onKeyDown}
                    />
                </Row>
                <Row>
                    <FormControl>
                        <FormLabel>{strings.gender}</FormLabel>
                        <RadioGroup
                            name="genderRadio"
                            value={this.gender}
                            onChange={this.onGenderRadioChanged}
                            style={{ flexDirection: "row" }}
                            onKeyDown={this.onKeyDown}
                        >
                            <FormControlLabel value={StudentGender.Boy} control={<Radio />} label={strings.boy} />
                            <FormControlLabel value={StudentGender.Girl} control={<Radio />} label={strings.girl} />
                        </RadioGroup>
                    </FormControl>
                </Row>
                <DialogActions>
                    <Button onClick={this.onOkClicked}>{strings.ok}</Button>
                </DialogActions>
            </div>
        );
    }

    @action
    private onOkClicked = () => {
        if (!this.props.store.rootStore.authStore.isSignedIn) {
            this.props.store.rootStore.showLoginRequiredMessage(strings.loginToEditLists);
        } else {
            this.props.student.name = this.name;
            this.props.student.parentKey = this.props.store.rootStore.activityStore.currentStudentList.key;

            // Firebase doesn't like undefined
            if (this.gender) {
                this.props.student.gender = this.gender;
            }

            this.props.store.updateStudent(this.props.student);
            this.props.store.studentToEdit = undefined;
        }
    };

    private onNameTextChanged = (event: any) => {
        this.name = event.currentTarget.value;
    };

    @action
    private onGenderRadioChanged = (event: any, value: string) => {
        this.gender = value as StudentGender;
    };

    private onImageDropped = (file: File) => {
        if (!this.props.store.rootStore.authStore.isSignedIn) {
            this.props.store.rootStore.showLoginRequiredMessage(strings.loginToEditLists);
        } else {
            this.props.store.addStudentProfileImage(file, this.props.student);
        }
    };

    private onKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            this.onOkClicked();
        }
    };
}
