import { log } from "../../";

import * as React from "react";
import { observer } from "mobx-react";
import { action } from "mobx";

import { StudentList } from "../../models";

import * as CustomLists from "../../models/customLists";
import StudentListStore from "../../stores/StudentListStore";
import { StudentListCard } from "./StudentListCard";
import DraggableFolderItem from "../categoryMenu/DraggableFolderItem";
import DragDropFolder from "../categoryMenu/DragDropFolder";
import { CategoryMenuFolderCard } from "../categoryMenu/CategoryMenuFolderCard";

var ReactList = require("react-list");

export interface FetchListProps extends React.Props<any> {
    store: StudentListStore;
}

@observer
class FetchList extends React.Component<FetchListProps> {
    render() {
        if (!this.props.store || !this.props.store.visibleItems) {
            return null;
        }

        let totalCount = this.props.store.visibleItems.length + 1;
        return (
            <ReactList
                key={this.props.store.visibleFolder ? this.props.store.visibleFolder.key : ""}
                type="simple"
                itemRenderer={this.renderItem}
                length={totalCount}
                pageSize={30}
            />
        );
    }

    private categoryCardDropped = () => {
        log.error("TODO - categoryCardDropped");
    };

    private itemDroppedOnFolder = (source: any, target: any) => {
        let targetFolder = target.props.folder;
        if (source.type === CategoryMenuFolderCard) {
            let sourceFolder = source.props.folder;
            this.props.store.moveFolderToFolder(sourceFolder, targetFolder, this.props.store.currentTab);
        } else if (source.type === StudentListCard) {
            let sourceClassList = source.props.classList;
            this.props.store.moveItemToFolder(sourceClassList, targetFolder, this.props.store.currentTab);
        }
    };

    @action
    private renderItem = (index: number, key: string) => {
        if (this.props.store.visibleItems === undefined || this.props.store.visibleItems.length === index) {
            return null;
        }

        let totalItems = this.props.store.visibleItems.length;
        let singleItemLoaded = totalItems === 1 && index === 1;
        if (totalItems > index || singleItemLoaded) {
            let item = this.props.store.visibleItems[index];
            if (item instanceof CustomLists.Folder) {
                return (
                    <DragDropFolder
                        key={item.key}
                        onDroppedOnTarget={this.itemDroppedOnFolder}
                        childObject={
                            <CategoryMenuFolderCard
                                onBackgroundClickedCallback={this.props.store.folderSelected.bind(this.props.store)}
                                folder={item as CustomLists.Folder}
                                store={this.props.store}
                            />
                        }
                    />
                );
            } else if (item instanceof StudentList) {
                let classroom = item as StudentList;
                return (
                    <DraggableFolderItem
                        key={item.key}
                        onItemDropped={this.categoryCardDropped}
                        childObject={<StudentListCard classList={classroom} store={this.props.store} />}
                    />
                );
            } else {
                log.verbose("Unknown list item type");
                return null;
            }
        }
        return null;
    };
}

export default FetchList;
