import * as React from "react";
import { Observer } from "mobx-react-lite";

import { Grid, TextField, InputAdornment } from "@material-ui/core";
import SimpleCheckbox from "components/SimpleCheckbox";

import SimpleRadio from "components/SimpleRadio";

import { ActivityStore } from "stores";
import { strings } from "core/localizedStrings";
import { OptionsGroup } from "components/activities/OptionsGroup";

export enum QuizAnswerType {
    MULTIPLE_CHOICE = "multipleChoice",
    RIGHT_OR_WRONG = "rightOrWrong",
}

export const defaultQuizOptions: QuizOptions = {
    autoPlayEnabled: false,
    playAudioOnCorrectAnswer: true,
    playAudioOnIncorrectAnswer: true,
    answerType: QuizAnswerType.MULTIPLE_CHOICE,
    showNumbers: true,
    useTimer: true,
    timerDuration: 30,
};

export interface QuizOptions {
    autoPlayEnabled: boolean;
    playAudioOnCorrectAnswer: boolean;
    playAudioOnIncorrectAnswer: boolean;
    answerType: QuizAnswerType;
    showNumbers: boolean;
    useTimer: boolean;
    timerDuration: number;
}

interface QuizOptionsProps extends React.Props<any> {
    activityStore: ActivityStore;
    disabled?: boolean;
}

const QuizOptions = (props: QuizOptionsProps) => {
    const handleAutoPlayAudio = (value: any) => {
        props.activityStore.activityOptions.quizOptions.autoPlayEnabled = value;
    };

    const handlePlayAudioOnCorrect = (value: any) => {
        props.activityStore.activityOptions.quizOptions.playAudioOnCorrectAnswer = value;
    };

    const handlePlayAudioOnWrong = (value: any) => {
        props.activityStore.activityOptions.quizOptions.playAudioOnIncorrectAnswer = value;
    };

    const handleAnswerTypeChanged = (value: any) => {
        props.activityStore.activityOptions.quizOptions.answerType = value;
    };

    const handleShowMultipleChoiceNumbersChanged = (value: any) => {
        props.activityStore.activityOptions.quizOptions.showNumbers = value;
    };

    const handleShowMultipleChoiceTimerChanged = (value: any) => {
        props.activityStore.activityOptions.quizOptions.useTimer = value;
    };

    const handleTimeValueChanged = (event: any) => {
        props.activityStore.activityOptions.quizOptions.timerDuration = event.target.value;
    };

    const disabled = props.disabled === undefined ? false : props.disabled;

    return (
        <Observer
            render={() => (
                <Grid container>
                    <OptionsGroup heading={strings.audioHeading}>
                        <SimpleCheckbox
                            isDisabled={disabled}
                            label={strings.autoPlayAudio}
                            isChecked={props.activityStore.activityOptions.quizOptions.autoPlayEnabled}
                            onChange={handleAutoPlayAudio}
                        ></SimpleCheckbox>
                        <SimpleCheckbox
                            isDisabled={disabled}
                            label={strings.playAudioOnCorrect}
                            isChecked={props.activityStore.activityOptions.quizOptions.playAudioOnCorrectAnswer}
                            onChange={handlePlayAudioOnCorrect}
                        ></SimpleCheckbox>
                        <SimpleCheckbox
                            isDisabled={disabled}
                            label={strings.playAudioOnWrong}
                            isChecked={props.activityStore.activityOptions.quizOptions.playAudioOnIncorrectAnswer}
                            onChange={handlePlayAudioOnWrong}
                        ></SimpleCheckbox>
                    </OptionsGroup>
                    <OptionsGroup heading={strings.answerTypeHeading}>
                        <SimpleRadio
                            isDisabled={disabled}
                            defaultValue={props.activityStore.activityOptions.quizOptions.answerType}
                            labels={[strings.multipleChoiceAnswerTypeOption, strings.rightOrWrongAnswerTypeOption]}
                            values={[QuizAnswerType.MULTIPLE_CHOICE, QuizAnswerType.RIGHT_OR_WRONG]}
                            onValueChanged={handleAnswerTypeChanged}
                        />
                    </OptionsGroup>
                    <OptionsGroup heading={strings.showNumbersHeading}>
                        <SimpleCheckbox
                            isDisabled={disabled}
                            label={strings.showNumbersMultipleChoice}
                            isChecked={props.activityStore.activityOptions.quizOptions.showNumbers}
                            onChange={handleShowMultipleChoiceNumbersChanged}
                        ></SimpleCheckbox>
                    </OptionsGroup>
                    <OptionsGroup heading={strings.timerHeading}>
                        <Grid container direction="column">
                            <Grid item>
                                <SimpleCheckbox
                                    isDisabled={disabled}
                                    label={strings.showTimerMultipleChoice}
                                    isChecked={props.activityStore.activityOptions.quizOptions.useTimer}
                                    onChange={handleShowMultipleChoiceTimerChanged}
                                ></SimpleCheckbox>
                            </Grid>
                            <Grid item>
                                <TextField
                                    label={"Duration:"}
                                    disabled={disabled || !props.activityStore.activityOptions.quizOptions.useTimer}
                                    autoComplete="off"
                                    id="textField"
                                    onChange={handleTimeValueChanged}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">seconds</InputAdornment>,
                                    }}
                                    style={{ textAlign: "right" }}
                                    inputProps={{ type: "number", min: 6, max: 90 }}
                                    defaultValue={props.activityStore.activityOptions.quizOptions.timerDuration}
                                />
                            </Grid>
                        </Grid>
                    </OptionsGroup>
                </Grid>
            )}
        />
    );
};

export default QuizOptions;
