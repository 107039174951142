import { log } from "../../";

import throttle from "lodash.throttle";

export class WindowResizeChangeDispatcher {
    private static _instance: WindowResizeChangeDispatcher = new WindowResizeChangeDispatcher();

    private listeners: Array<(width: number, height: number) => void> = [];
    private isListening: boolean = false;

    public static getInstance(): WindowResizeChangeDispatcher {
        return WindowResizeChangeDispatcher._instance;
    }

    constructor() {
        if (WindowResizeChangeDispatcher._instance) {
            throw new Error("Error: Instantiation failed: Use SingletonDemo.getInstance() instead of new.");
        }
        WindowResizeChangeDispatcher._instance = this;
    }

    public addListener(callback: (width: number, height: number) => void) {
        if (this.listeners.indexOf(callback) === -1) {
            this.listeners.push(callback);
        }

        if (!this.isListening) {
            // /this.updateWindowDimensions = debounce(1000, this.updateWindowDimensions);
            window.addEventListener("resize", throttle(this.updateWindowDimensions, 500));
            this.isListening = true;
        }

        this.updateWindowDimensions();
    }

    public removeListener(callback: (width: number, height: number) => void) {
        let index = this.listeners.indexOf(callback);
        if (index !== -1) {
            this.listeners.splice(index, 1);
            log.verbose("Removed callback at index " + index);
        } else {
            log.verbose("Callback not found");
        }

        if (this.listeners.length === 0 && this.isListening) {
            window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
            this.isListening = false;
        }
    }

    public updateWindowDimensions = () => {
        if (this.listeners !== undefined && this.listeners.length > 0) {
            this.listeners.forEach(callback => {
                callback(window.innerWidth, window.innerHeight);
            });
        }
    };
}
