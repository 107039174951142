import * as React from "react";
import { action } from "mobx";
import { inject, observer } from "mobx-react";
import { Col, Row } from "layout";
import { Colors } from "core/utils/Colors";
import { RootStore } from "stores";
import PreviewTimer from "components/PreviewTimer";
import { FOOTER_INDEX } from "layers";

interface ActivityFooterProps extends React.Props<any> {
    rootStore?: RootStore;
}

const ActivityFooter = observer((props: ActivityFooterProps) => {
    let { rootStore } = props;
    let usedCredit = false;

    const onUsedCreditSuccess = action(() => {
        usedCredit = true;
    });

    let content;
    if (!usedCredit && props.rootStore.shouldShowPreviewTimer) {
        content = (
            <>
                <Col xs={12} sm={8}>
                    <PreviewTimer rootStore={rootStore} onUsedCreditCallback={onUsedCreditSuccess} />
                </Col>
                <Col xs={12} sm={4}>
                    {props.children}
                </Col>
            </>
        );
    } else {
        content = <Col xs={12}>{props.children}</Col>;
    }

    return (
        <Row
            middle="xs"
            style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                minHeight: 50,
                zIndex: FOOTER_INDEX,
                backgroundColor: Colors.rgba(255, 255, 255, 0.7)
            }}
        >
            {content}
        </Row>
    );
});

export default inject("rootStore")(ActivityFooter);
