import * as React from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import RootStore from "../stores/RootStore";

import { strings } from "../core/localizedStrings";
import { Grid, Typography } from "@material-ui/core";

export interface ForgotPasswordFormProps extends React.Props<any> {
    rootStore: RootStore;
}

export default class ForgotPasswordForm extends React.Component<ForgotPasswordFormProps> {
    private forgotPasswordEmail: string;

    render() {
        return (
            <Grid container direction="column" style={{ padding: 40 }}>
                <Grid item>
                    <Typography variant="h5">{strings.enterEmailUsedToRegister}</Typography>
                </Grid>
                <Grid item>
                    <TextField
                        label={strings.email}
                        fullWidth={true}
                        id="forgotPasswordEmail"
                        onChange={this.onTextChanged}
                        onKeyDown={this.onKeyDown}
                        style={{ marginTop: 20, paddingBottom: 20 }}
                    />
                </Grid>
                <Grid item>
                    <Grid container justify="flex-end">
                        <Button variant="contained" color="primary" onClick={this.onSubmitClicked}>
                            {strings.submit}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    private onTextChanged = (event: any) => {
        this.forgotPasswordEmail = event.currentTarget.value;
    };

    private onKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            this.onSubmitClicked(undefined);
        }
    };

    // Forgot Password Form
    // -----------------------------
    private onSubmitClicked = (event: any) => {
        this.props.rootStore.authStore.submitForgotPassword(this.forgotPasswordEmail);
    };
}
