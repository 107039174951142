import * as React from "react";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";

// Material UI
import Divider from "@material-ui/core/Divider";

// Utils
import { strings } from "../../core/localizedStrings";
import { StripeCheckoutButton } from "../../routes/myAccount/StripeCheckoutButton";
import { Colors } from "../../core/utils/Colors";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import * as Icons from "../../core/utils/Icons";
import { ManagedUser, SubscriptionDetailsStatus } from "../../models";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

export interface PaymentMethodsBlockProps extends React.Props<any> {
    store: UserStore;
}

@observer
export default class PaymentMethodsBlock extends React.Component<PaymentMethodsBlockProps> {
    render() {
        let customerDetails = this.props.store.customerDetails;
        if (!customerDetails) {
            return null;
        }

        if (
            this.props.store.user instanceof ManagedUser ||
            this.props.store.subscriptionDetailStatus !== SubscriptionDetailsStatus.Loaded
        ) {
            return null;
        }

        if (customerDetails !== undefined && customerDetails.card) {
            return (
                <div>
                    <Row middle="xs" style={{ height: 47 }}>
                        <Col>
                            <Typography variant="h5">{strings.paymentMethod}</Typography>
                        </Col>
                        {customerDetails && !customerDetails.noCardForSubscription && (
                            <Col>
                                <StripeCheckoutButton
                                    icon={<Icons.Edit />}
                                    isUpdate={true}
                                    onToken={this.onEditCard}
                                    email={this.props.store.user.email}
                                />
                            </Col>
                        )}
                    </Row>
                    <Divider style={{ marginRight: 20 }} />
                    <Row style={{ paddingLeft: 10 }}>
                        <Typography variant="subtitle1">{this.renderCardDetails()}</Typography>
                    </Row>
                    {this.renderAddCardButton()}
                </div>
            );
        } else {
            return null;
        }
    }

    private onAddCard = (token: any) => {
        this.props.store.addCard(token.id);
    };

    private onDeleteCard = () => {
        this.props.store.deleteCard();
    };

    private onEditCard = (token: any) => {
        this.props.store.updateCard(token.id);
    };
    private renderCardDetails = () => {
        let customerDetails = this.props.store.customerDetails;
        if (customerDetails.noCardForSubscription) {
            return null;
        }

        let card = customerDetails.card;
        return (
            <Row middle="xs" style={{ margin: "10px 0 10px" }}>
                <Typography
                    variant="body1"
                    style={{ paddingRight: 10 }}
                >{`${card.brand}: ****${card.last4}`}</Typography>

                {this.props.store.user.canceledSubscription ? (
                    <Button style={{ marginLeft: 10 }} variant="contained" onClick={this.onDeleteCard}>
                        {strings.delete}
                    </Button>
                ) : null}
            </Row>
        );
    };

    private renderAddCardButton = () => {
        let customerDetails = this.props.store.customerDetails;
        if (!customerDetails.noCardForSubscription || customerDetails.accountExpired) {
            return null;
        }

        return (
            <Row middle="xs" style={{ margin: "10px 0 10px" }}>
                <Col style={{ paddingLeft: 7 }}>
                    <Row style={{ color: Colors.red, paddingBottom: 10 }}>{strings.noCardOnFile}</Row>
                    <Row>
                        <StripeCheckoutButton
                            label={strings.addCard}
                            isUpdate={false}
                            onToken={this.onAddCard}
                            email={this.props.store.user.email}
                        />
                    </Row>
                </Col>
            </Row>
        );
    };
}
