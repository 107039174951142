import { ActivityCategoryItem } from "models/customLists/CategoryItem";
import { Scene } from "phaser";
import { PhaserImages } from "../games/PhaserImages";

const FLIP_SPEED = 100;

export class PhaserCard {
    public activityItem: ActivityCategoryItem;
    public visibleContainer: Phaser.GameObjects.Container;

    protected scene: Scene;
    protected _frontItems: Phaser.GameObjects.GameObject[] = [];
    protected _backItems: Phaser.GameObjects.GameObject[] = [];
    protected _isFlipped: boolean = false;
    protected _onCardClicked?: () => void;
    protected _onCardOver?: () => void;
    protected _onCardOut?: () => void;

    public get isFlipped() {
        return this._isFlipped;
    }

    public get frontItems(): Phaser.GameObjects.GameObject[] {
        return this._frontItems;
    }

    public get backItems(): Phaser.GameObjects.GameObject[] {
        return this._backItems;
    }

    public set onCardClickedCallback(callback: () => void) {
        this._onCardClicked = callback;
    }

    public set onCardOverCallback(callback: () => void) {
        this._onCardOver = callback;
    }

    public set onCardOutCallback(callback: () => void) {
        this._onCardOut = callback;
    }

    constructor(scene: Scene, x: number = 0, y: number = 0) {
        this.scene = scene;
        this.visibleContainer = this.scene.add.container();
        this.visibleContainer.x = x;
        this.visibleContainer.y = y;
    }

    // public showFlipButton = (visible: boolean) => {
    //     this.visibleContainer.each(item => {
    //         if (item.name === PhaserImages.FLIP_ICON) {
    //             (item as any).setVisible(visible);
    //         }
    //     });
    // };

    protected onCardClicked = () => {
        if (this._onCardClicked) {
            this._onCardClicked();
        }
    };

    public flip() {
        let bounds = this.visibleContainer.getBounds();
        this.visibleContainer.removeAll();

        const frontTexture = new Phaser.GameObjects.RenderTexture(this.scene, 0, 0, bounds.width, bounds.height);
        this.saveItemsToTexture(this.frontItems, frontTexture, bounds.width / 2, bounds.height / 2);
        frontTexture.saveTexture("cardFrontTexture");

        const backTexture = new Phaser.GameObjects.RenderTexture(this.scene, 0, 0, bounds.width, bounds.height);
        this.saveItemsToTexture(this.backItems, backTexture, bounds.width / 2, bounds.height / 2);
        backTexture.saveTexture("cardBackTexture");

        const quad = new Phaser.GameObjects.Quad(
            this.scene,
            0,
            0,
            this._isFlipped ? "cardBackTexture" : "cardFrontTexture"
        );
        this.visibleContainer.removeAll();
        this.visibleContainer.add(quad);

        const timeline = this.scene.tweens.timeline({
            onComplete: () => {
                timeline.destroy();
            },
        });
        const { topLeftX, topRightX, bottomLeftX, bottomRightX, topLeftY, topRightY, bottomLeftY, bottomRightY } = quad;
        const width = quad.width;
        const middleX = quad.topLeftX + width * 0.5;
        const extra = 100;
        timeline.add({
            targets: quad,

            duration: FLIP_SPEED,
        });
        timeline.add({
            targets: quad,
            topLeftX: middleX,
            topRightX: middleX,
            topRightY: topRightY - extra,
            bottomLeftX: middleX,
            bottomRightX: middleX,
            bottomRightY: bottomRightY + extra,
            duration: FLIP_SPEED,
            onComplete: () => {
                // Switch textures
                quad.setTexture(this._isFlipped ? "cardFrontTexture" : "cardBackTexture");
                quad.topRightY = topRightY;
                quad.bottomRightY = bottomRightY;
                quad.topLeftY = topRightY - extra;
                quad.bottomLeftY = bottomRightY + extra;
            },
        });
        timeline.add({
            targets: quad,
            topLeftX,
            topLeftY,
            topRightX,
            topRightY,
            bottomLeftX,
            bottomLeftY,
            bottomRightX,
            bottomRightY,
            duration: FLIP_SPEED,
            onStart: () => {
                quad.topRightY = topRightY;
                quad.bottomRightY = bottomRightY;
                quad.topLeftY = topRightY - extra;
                quad.bottomLeftY = bottomRightY + extra;
            },
            onComplete: () => {
                this.visibleContainer.removeAll();
                if (this._isFlipped) {
                    this._isFlipped = false;
                    this.frontItems.forEach((item) => this.visibleContainer.add(item));
                } else {
                    this._isFlipped = true;
                    this.backItems.forEach((item) => this.visibleContainer.add(item));
                }
                // this.showFlipButton(true);
            },
        });
        timeline.play();
    }

    public addContentToFront(object: Phaser.GameObjects.GameObject) {
        this.frontItems.push(object);
        if (!this._isFlipped) {
            this.visibleContainer.add(object);
        }
    }

    public addContentToBack(object: Phaser.GameObjects.GameObject) {
        this.backItems.push(object);
        if (this._isFlipped) {
            this.visibleContainer.add(object);
        }
    }

    private saveItemsToTexture(
        items: Phaser.GameObjects.GameObject[],
        texture: Phaser.GameObjects.RenderTexture,
        offsetX: number,
        offsetY: number
    ) {
        for (let item of items) {
            let anyItem = item as any;
            if (anyItem.visible) {
                texture.draw(item, offsetX + anyItem.x, offsetY + anyItem.y);
            }
        }
    }
}
