import * as React from "react";
import { makeStyles, Theme, createStyles, Grid, IconButton, Button, ButtonBase } from "@material-ui/core";
import Activity from "models/activities/Activity";
import { Colors } from "core/utils/Colors";
import { FlashcardActivity, FlashcardActivityType, Game, GameType, Worksheet, WorksheetType } from "models";
import { InteractiveFlashcardsContext } from "./flashcards/InteractiveFlashcardsState";
import Utils from "core/utils/Utils";
import * as Icons from "core/utils/Icons";
import { ActivityStore } from "stores";
import { strings } from "core/localizedStrings";

export interface ActivityOptionsBarFactoryProps extends React.Props<any> {
    activityStore: ActivityStore;
    activity: Activity;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconStyle: { color: Colors.lightBlue },
    })
);

const iconStyle = {
    color: Colors.lightBlue,
};

const ActivityOptionsBarFactory = (props: ActivityOptionsBarFactoryProps) => {
    if (props.activity instanceof FlashcardActivity) {
        switch (props.activity.activityType) {
            case FlashcardActivityType.Interactive:
                return <FlashcardsOptionBarOptions activityStore={props.activityStore} />;
        }
    } else if (props.activity instanceof Game) {
        switch (props.activity.activityType) {
            case GameType.Bingo:
                return <BingoGameOptionsBarOptions activityStore={props.activityStore} />;
        }
    } else if (props.activity instanceof Worksheet) {
        switch (props.activity.activityType) {
            case WorksheetType.Bingo:
                return <BingoWorksheetOptionsBarOptions activityStore={props.activityStore} />;
        }
    }
    return null;
};

export default ActivityOptionsBarFactory;

interface OptionBarOptionsProps {
    activityStore: ActivityStore;
}

const BingoGameOptionsBarOptions = (props: OptionBarOptionsProps) => {
    return (
        <Grid item>
            <Button
                size="small"
                variant={"contained"}
                color="primary"
                onClick={() => {
                    props.activityStore.rootStore.setRoute("/worksheets/bingo-cards");
                }}
            >
                {"Print Bingo Cards"}
            </Button>
        </Grid>
    );
};

const BingoWorksheetOptionsBarOptions = (props: OptionBarOptionsProps) => {
    return (
        <Grid item>
            <Button
                size="small"
                variant={"contained"}
                color="primary"
                onClick={() => {
                    props.activityStore.rootStore.setRoute("/games/bingo-caller");
                }}
            >
                {"Use Bingo Caller"}
            </Button>
        </Grid>
    );
};

const FlashcardsOptionBarOptions = (props: OptionBarOptionsProps) => {
    let { state, setState } = React.useContext(InteractiveFlashcardsContext);
    if (state.showSlideshow) {
        return (
            <Icons.SimpleIconButton
                tooltip={strings.showAllCards}
                icon={<Icons.Grid style={iconStyle} />}
                onClick={() => {
                    setState({ ...state, showSlideshow: !state.showSlideshow });
                }}
            />
        );
    } else {
        return (
            <Grid item>
                <Grid container alignItems="center" justify="center">
                    <Grid item>
                        <Icons.SimpleIconButton
                            tooltip={strings.review}
                            icon={<Icons.PlayArrow style={iconStyle} />}
                            onClick={() => {
                                setState({ ...state, showSlideshow: !state.showSlideshow });
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Icons.SimpleIconButton
                            tooltip={strings.shuffle}
                            icon={<Icons.Shuffle style={iconStyle} />}
                            onClick={() => {
                                setState({ ...state, randomizationSeed: Utils.guid() });
                            }}
                        />
                    </Grid>
                    {props.activityStore.activityOptions.flashcardsOptions.allowCardsToBeFlipped && (
                        <>
                            <Grid item>
                                <Icons.SimpleIconButton
                                    tooltip={strings.flipCardsToFront}
                                    icon={<Icons.FlipToFront style={iconStyle} />}
                                    onClick={() => {
                                        if (state.flipCardsToFront) {
                                            state.flipCardsToFront();
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Icons.SimpleIconButton
                                    tooltip={strings.flipCardsToBack}
                                    icon={<Icons.FlipToBack style={iconStyle} />}
                                    onClick={() => {
                                        if (state.flipCardsToBack) {
                                            state.flipCardsToBack();
                                        }
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        );
    }
};
