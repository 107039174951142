import { log } from "../";
import { observable, action, runInAction } from "mobx";

// Stores
import { RootStore, UserStore } from "../stores";

// Models
import { User } from "../models";

// Utils
import { strings } from "../core/localizedStrings";
import { firebaseApp, Firestore, FirestoreActions } from "../core/firebase";

// Constants
import { SITE_URL } from "../constants";
import firebase from "firebase";
import { PendingManagedUser } from "models/User";

export default class AuthStore {
    @observable isLoggingIn: boolean = false;
    @observable isSignedIn: boolean = false;
    @observable isAnonymous: boolean = false;
    @observable isRestrictedPage: boolean = true;

    @observable shouldShowSignInDialog: boolean = false;
    @observable shouldShowModalSignInDialog: boolean = false;
    @observable shouldShowForgotPassword: boolean = false;
    @observable shouldShowUseCreditDialog: boolean = false;

    @observable authErrorMessage: string;

    public firebaseUser: firebase.User;
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        firebase
            .auth()
            .getRedirectResult()
            .then(function (result: any) {
                log.verbose(result.credential);
            })
            .catch(function (error: any) {
                // TODO - merge data (if any)
                // Delete anonymous user
                if (error.credential) {
                    firebase.auth().signInWithCredential(error.credential);
                } else {
                    this.rootStore.showErrorMessage(error.message);
                    this.setIsLoggingIn(false);
                }
            });

        firebaseApp
            .auth()
            .onAuthStateChanged(this.onAuthStateChanged, (error) => this.rootStore.showErrorMessage(error.message));
    }

    // #region - Auth Store State Setters -
    // ------------------------------
    @action
    setAuthErrorMessage(value: string) {
        this.setIsLoggingIn(false);
        this.authErrorMessage = value;
    }

    @action
    public setIsLoggingIn(value: boolean) {
        this.rootStore.setShowLoadingScreen(value);
        this.isLoggingIn = value;
    }

    @action
    public setIsSignedIn(value: boolean) {
        this.isSignedIn = value;
        if (this.isSignedIn) {
            this.rootStore.setShowLoadingScreen(false);
            // log.verbose("Sign in success");
            this.shouldShowSignInDialog = false;
            this.authErrorMessage = undefined;
        }
        this.setIsLoggingIn(false);
    }

    @action
    setIsAnonymous(value: boolean) {
        this.isAnonymous = value;
    }

    @action
    public setIsRestrictedPage(value: boolean) {
        this.isRestrictedPage = value;
    }

    @action
    public setShouldShowSignInDialog(value: boolean) {
        this.shouldShowSignInDialog = value;
    }

    @action
    public setShouldShowModalRegisterDialog(value: boolean) {
        this.shouldShowModalSignInDialog = value;
    }

    @action
    public setShouldShowUseCreditDialog(value: boolean) {
        this.shouldShowUseCreditDialog = value;
    }

    @action
    public setShouldShowForgotPasswordDialog(value: boolean) {
        this.shouldShowForgotPassword = value;
    }
    // #endregion

    // #region - Auth Store Actions -
    // ------------------------------
    public signInAnonymously = async () => {
        try {
            this.setIsLoggingIn(true);
            let response = await firebaseApp.auth().signInAnonymously();
            log.verbose(response);
        } catch (error) {
            log.error(error);
        }
    };

    public signInWithGoogle = () => {
        this.signInWithProvider(new firebase.auth.GoogleAuthProvider());
    };

    public signInWithFacebook() {
        return this.signInWithProvider(new firebase.auth.FacebookAuthProvider());
    }

    public signInWithTwitter() {
        return this.signInWithProvider(new firebase.auth.TwitterAuthProvider());
    }

    public signInWithToken = async (token: string) => {
        try {
            this.setIsLoggingIn(true);
            let response = await firebaseApp.auth().signInWithCustomToken(token);
            log.verbose(response);
            this.setIsSignedIn(true);
        } catch (error) {
            this.setAuthErrorMessage(error);
        }
    };

    public async signInWithUsername(username: string, password: string) {
        this.setIsLoggingIn(true);
        return await firebaseApp.auth().signInWithEmailAndPassword(username, password);
    }

    public async registerWithEmail(email: string, username: string, password: string) {
        try {
            let user: firebase.User;
            if (this.rootStore.firebaseUser && this.rootStore.firebaseUser.isAnonymous) {
                var credential = firebase.auth.EmailAuthProvider.credential(email, password);
                var userCredential = await this.rootStore.firebaseUser.linkWithCredential(credential);
                user = userCredential.user;
                log.verbose("Linked credentials to anonymous.");
            } else {
                let userCredential = await firebaseApp.auth().createUserWithEmailAndPassword(email, password);
                user = userCredential.user;
                log.verbose("Register success");
            }

            let newUser = await this.createUserForTrial(user.uid, user.email, username);
            this.rootStore.userStore = new UserStore(this.rootStore, newUser);
            this.rootStore.resetUserDependentStores();
            this.rootStore.userStore.registerUserListeners();
            this.sendEmailVerification();
            return true;
        } catch (error) {
            try {
                if (error.credential) {
                    await firebase.auth().signInWithCredential(credential);
                    return true;
                } else {
                    log.error(error);
                    return false;
                }
            } catch (error) {
                log.error(error);
                return false;
            }
        }
    }

    public async createUserForTrial(uid: string, email: string, username: string = null) {
        let expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 30);

        let user = new User();
        user.expiration = firebase.firestore.Timestamp.fromDate(expirationDate);
        user.isAnonymous = false;
        user.isTrial = true;
        user.uid = uid;
        user.username = username;
        user.email = email;
        user.emailLower = email.toLowerCase();
        user.created = firebase.firestore.Timestamp.now();

        // Check if there is a pending managed account
        let docSnapshot = await Firestore.pendingManagedUsersCollection().doc(user.emailLower).get();
        if (docSnapshot.exists) {
            let pendingUser: PendingManagedUser = docSnapshot.data() as PendingManagedUser;
            user.pendingManager = pendingUser.managedBy;
            log.verbose("Pending managed user found.");
        }

        // Add user to DB
        return FirestoreActions.updateUser(user);
    }

    public async sendEmailVerification() {
        try {
            // Send verification email
            let result = await firebaseApp.auth().currentUser.sendEmailVerification({ url: SITE_URL });
            console.log(result);
            return true;
        } catch (error) {
            log.verbose(error);
            return false;
        }
    }

    public sendPasswordResetEmail() {
        if (this.rootStore.userStore.user.email) {
            this.submitForgotPassword(this.rootStore.userStore.user.email);
        }
    }

    public async submitForgotPassword(email: string) {
        this.setShouldShowForgotPasswordDialog(false);
        try {
            let result = await firebaseApp.auth().sendPasswordResetEmail(email);
            console.log(result);
            this.rootStore.showInfoMessage({ text: strings.passwordResetEmailHasBeenSendMessage });
        } catch (error) {
            this.rootStore.showErrorMessage(error);
        }
    }

    public logout = async () => {
        try {
            await firebaseApp.auth().signOut();
            this.setIsSignedIn(false);
        } catch (error) {
            this.rootStore.showErrorMessage(error);
        }
    };
    // #endregion

    // --------------------------
    // Auth State Change Callback
    // #region ------------------
    private onAuthStateChanged = async (firebaseUser?: firebase.User) => {
        if (firebaseUser) {
            this.firebaseUser = firebaseUser;
            let user = await FirestoreActions.getUser(firebaseUser.uid);
            if (user) {
                if (!this.firebaseUser.isAnonymous && user.isAnonymous) {
                    await this.createUserForTrial(user.uid, this.firebaseUser.email);
                }
                this.rootStore.userStore = new UserStore(this.rootStore, user);
                this.rootStore.resetUserDependentStores();
                this.rootStore.userStore.registerUserListeners();
            } else if (firebaseUser.isAnonymous) {
                log.verbose("Anonymous user");
                let anonymousUser = new User();
                anonymousUser.isAnonymous = true;
                if (this.firebaseUser.email) {
                    anonymousUser.email = this.firebaseUser.email;
                }
                if (this.firebaseUser.uid) {
                    anonymousUser.uid = this.firebaseUser.uid;
                }

                this.rootStore.userStore = new UserStore(this.rootStore, anonymousUser);
                FirestoreActions.updateUser(anonymousUser);
            } else {
                // The only time we should get here is the user used a 3rd party authentication provider
                // and it is the first time they have logged in.
                this.rootStore.fetchUnknownUser(firebaseUser.email, undefined);
            }
        } else {
            this.firebaseUser = undefined;
            this.rootStore.userStore = new UserStore(this.rootStore, new User());
            this.rootStore.resetUserDependentStores();
            this.setIsSignedIn(false);
            this.signInAnonymously();
            log.verbose("Not logged in.");
        }
    };
    // #endregion

    // #region - Private -
    // ------------------------------
    private async signInWithProvider(provider: any) {
        this.setIsLoggingIn(true);
        try {
            if (this.rootStore.firebaseUser && this.rootStore.firebaseUser.isAnonymous) {
                this.rootStore.firebaseUser.linkWithRedirect(provider);
            } else {
                await firebaseApp.auth().signInWithRedirect(provider);
            }

            this.rootStore.authStore.setIsSignedIn(true);
        } catch (error) {
            this.rootStore.authStore.setAuthErrorMessage(error);
        }
    }
    // #endregion
}
