import * as React from "react";
import * as ReactDOM from "react-dom";

import Root from "./Root";
import Logger from "./core/utils/Logger";
import registerServiceWorker, { unregister } from "./registerServiceWorker";
import { Firestore, firebaseApp } from "./core/firebase/";

import RootStore from "./stores/RootStore";

interface WebpackNodeModule extends NodeModule {
    hot: {
        accept: () => void;
    };
}

if ((module as WebpackNodeModule).hot) {
    (module as WebpackNodeModule).hot.accept();
}

export var log = new Logger();

let rootStore: RootStore;

let initStore = async () => {
    Firestore.app = firebaseApp;

    rootStore = new RootStore(firebaseApp);
    log.rootStore = rootStore;
};

unregister();

let render = () => {
    ReactDOM.render(<Root store={rootStore} />, document.getElementById("root") as HTMLElement);
};

registerServiceWorker();
initStore().then(() => {
    render();
});
