import * as React from "react";

// Layout
import { Col, Row } from "layout";
import withDivSize, { WithDivSizeProps } from "core/utils/withDivSize";
import { Grid } from "@material-ui/core";

interface CenteredPageProps extends WithDivSizeProps {
    style?: any;
    maxWidth?: any;
}

class CenteredPage extends React.Component<CenteredPageProps> {
    private style: any = {};

    constructor(props: any) {
        super(props);
        if (this.props.style) {
            this.style = this.props.style;
        }

        if (this.props.maxWidth) {
            this.style = Object.assign(this.style, { maxWidth: this.props.maxWidth });
        }
    }
    render() {
        let height = this.props.windowHeight - this.props.divY;
        if (isNaN(height)) {
            height = 0;
        }
        // let width = this.props.windowWidth - this.props.divX;
        // return (
        //     <Col xs={12} style={{ height: height, position: "absolute", top: 0, left: 0, width: "100%" }}>
        //         <Row center="xs" style={{ height: height }}>
        //             <div style={this.style}>
        //                 <Col xs={12} style={{ height: "100%" }}>
        //                     <Row middle="xs" center="xs" style={{ height: "100%" }}>
        //                         {this.props.children}
        //                     </Row>
        //                 </Col>
        //             </div>
        //         </Row>
        //     </Col>
        // );
        return (
            <Grid container direction="column" style={{ height: "100%" }} justify="center">
                {this.props.children}
            </Grid>
        );
    }
}

export default withDivSize(CenteredPage);
