import * as React from "react";
import { Observer } from "mobx-react-lite";
import { ActivityStore } from "stores";
import { GameOptions } from "../GameScene";
import { CardType } from "models/customLists/CategoryItem";
import { CardTypeButtons } from "components/activities/CommonActivityOptions";
import { TextField, InputAdornment, Grid } from "@material-ui/core";
import { OptionsGroup } from "components/activities/OptionsGroup";
import SimpleCheckbox from "components/SimpleCheckbox";
import { strings } from "core/localizedStrings";

export enum SpeedGameType {
    MULTIPLE_CHOICE = "multipleChoice",
    CLASSIC = "classic",
}

export const defaultSpeedOptions: SpeedOptions = {
    totalCards: 60,
    roundTime: 30,
    bonusSeconds: 10,
    cardType: CardType.IMAGE_ONLY,
};

export interface SpeedOptions extends GameOptions {
    totalCards: number;
    roundTime: number;
    bonusSeconds: number;
    cardType: CardType;
}

interface SpeedOptionsProps {
    activityStore: ActivityStore;
}

const SpeedOptions = (props: SpeedOptionsProps) => {
    const [value, setValue] = React.useState(0);

    const handleTotaCardsChanged = (event: any) => {
        props.activityStore.activityOptions.speedOptions.totalCards = parseInt(event.target.value);
        props.activityStore.updateOptions();
    };

    const handleCardTypeChanged = (value: any) => {
        props.activityStore.activityOptions.speedOptions.cardType = value;
        props.activityStore.updateOptions();
    };

    const handleRoundTimeChanged = (event: any) => {
        props.activityStore.activityOptions.speedOptions.roundTime = parseInt(event.target.value);
        props.activityStore.updateOptions();
    };

    const handleBonusSecondsChanged = (event: any) => {
        props.activityStore.activityOptions.speedOptions.bonusSeconds = parseInt(event.target.value);
        props.activityStore.updateOptions();
    };

    // console.log(props.activityStore.activityOptions.ticTacToeOptions.gameType);

    return (
        <Observer
            render={() => (
                <>
                    <CardTypeButtons
                        activityStore={props.activityStore}
                        onCardTypeChanged={handleCardTypeChanged}
                        selectedType={props.activityStore.activityOptions.speedOptions.cardType}
                    />
                    <OptionsGroup heading={"Total Cards"}>
                        <Grid container direction="column">
                            <Grid item>
                                <TextField
                                    autoComplete="off"
                                    onChange={handleTotaCardsChanged}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">cards</InputAdornment>,
                                    }}
                                    style={{ textAlign: "right", minWidth: 150 }}
                                    inputProps={{ type: "number", min: 8, max: 80 }}
                                    defaultValue={props.activityStore.activityOptions.speedOptions.totalCards}
                                />
                            </Grid>
                        </Grid>
                    </OptionsGroup>
                    <OptionsGroup heading={strings.timerHeading}>
                        <Grid container direction="column">
                            <Grid item>
                                <TextField
                                    label={"Total time"}
                                    autoComplete="off"
                                    id="textField"
                                    onChange={handleRoundTimeChanged}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">seconds</InputAdornment>,
                                    }}
                                    style={{ textAlign: "right", minWidth: 150 }}
                                    inputProps={{ type: "number", min: 6, max: 90 }}
                                    defaultValue={props.activityStore.activityOptions.speedOptions.roundTime}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label={"Bonus seconds"}
                                    autoComplete="off"
                                    id="textField"
                                    onChange={handleBonusSecondsChanged}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">seconds</InputAdornment>,
                                    }}
                                    style={{ textAlign: "right", minWidth: 150 }}
                                    inputProps={{ type: "number", min: 2, max: 60 }}
                                    defaultValue={props.activityStore.activityOptions.speedOptions.bonusSeconds}
                                />
                            </Grid>
                        </Grid>
                    </OptionsGroup>
                    {/* <OptionsGroup heading={strings.game}>
                        <SimpleRadio
                            defaultValue={props.activityStore.activityOptions.ticTacToeOptions.gameType}
                            labels={[strings.multipleChoiceAnswerTypeOption, strings.imagesInGrid]}
                            values={[TicTacToeGameType.MULTIPLE_CHOICE, TicTacToeGameType.CLASSIC]}
                            onValueChanged={handleGameTypeChanged}
                        />
                    </OptionsGroup>
                    {props.activityStore.activityOptions.ticTacToeOptions.gameType === TicTacToeGameType.CLASSIC ? (
                        <OptionsGroup heading={strings.classicModeOptions}>
                            <SimpleCheckbox
                                label={strings.playAudioOnSelection}
                                isChecked={props.activityStore.activityOptions.ticTacToeOptions.playAudioOnSelection}
                                onChange={handlePlayAudioChanged}
                            />
                        </OptionsGroup>
                    ) : (
                        <OptionsGroup heading={strings.multipleChoiceOptions}>
                            <QuizOptions activityStore={props.activityStore} />
                        </OptionsGroup>
                    )} */}
                </>
            )}
        />
    );
};

export default SpeedOptions;
