import MultipleChoiceItems from "models/MultipleChoiceItems";
import React from "react";

export default interface QuizState {
    multipleChoiceItems?: MultipleChoiceItems;
    onAnswered?: (isCorrect, isListComplete: boolean) => void;
}

export const initialQuizState: QuizState = {};

const contextInitialState = {
    quizState: initialQuizState,
    setQuizState: (state: QuizState) => {},
};

export const QuizStateContext = React.createContext(contextInitialState);
export const QuizStateContextProvider = (props) => {
    const [quizState, setQuizState] = React.useState<QuizState>(initialQuizState);
    return <QuizStateContext.Provider value={{ quizState, setQuizState }}>{props.children}</QuizStateContext.Provider>;
};
