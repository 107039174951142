import { log } from "../../";
import { action } from "mobx";

import SearchResultFolder, { PAGE_SIZE } from "./SearchResultFolder";

// Models
import { Folder } from "models";

// Utils
import Firestore from "core/firebase/Firestore";
import FirebaseFunctions from "core/firebase/FirebaseFunctions";
import { ElasticsearchQueryBuilder } from "core/search/ElasticsearchQueryBuilder";
import { CategoryImage } from ".";

export interface ElasticsearchHit {
    _id: string;
    _source: ElasticsearchSource;
}

interface ElasticsearchSource {
    created: string;
    title: string;
}

export default class CategoryImagesSearchResultFolder extends SearchResultFolder {
    checkShouldFetchMore() {
        if (this.lastIndexFetched < this.totalHits - 1) {
            this.startSearch();
        }
    }

    // Search
    // ---------------------
    @action
    async search(text: string) {
        if (this.isQuerying || this.isLoading) {
            return;
        }

        if (this.currentFolder) {
            this.currentFolder.reset();
        } else {
            this.currentFolder = new Folder();
        }

        if (this.searchText !== text) {
            this.lastIndexFetched = -1;
            this.searchText = text;
        }

        await this.startSearch();
    }

    private async startSearch(): Promise<any> {
        let queryBuilder = new ElasticsearchQueryBuilder(this.rootStore.userStore.user.uid);
        let query = queryBuilder.createImageQueryForTab(
            this.lastIndexFetched + 1,
            PAGE_SIZE,
            this.searchText,
            this.tabType
        );

        try {
            let queryString = JSON.stringify(query);
            log.verbose(queryString);

            this.setIsQuerying(true);
            let response = await FirebaseFunctions.searchImages(query);
            this.setIsQuerying(false);

            if (response && response.hits && response.hits.length > 0) {
                this.setTotalHits(response.total);
                this.fetchImages(response.hits);
            }
            log.verbose(response);
        } catch (error) {
            log.error(error);
        }
    }

    @action
    private fetchImages(hits: ElasticsearchHit[]) {
        let totalItemsToFetch = hits.length;
        let fetchCount = 0;
        this.setIsLoading(true);
        hits.forEach(hit => {
            let categoriesRef = Firestore.categoryImagesRef();
            let unsubscribeCallback = categoriesRef.doc(hit._id).onSnapshot(docSnapshot => {
                let category = new CategoryImage(docSnapshot.id, docSnapshot.data());
                if (!docSnapshot.exists) {
                    this.currentFolder.removeItem(category);
                } else {
                    this.currentFolder.addItem(category);
                }

                fetchCount += 1;
                if (fetchCount === totalItemsToFetch) {
                    this.setIsLoading(false);
                }

                this.lastIndexFetched += 1;
            });
            this.currentFolder.itemsUnsubscribeCallbacks.push(unsubscribeCallback);
        });
    }
}
