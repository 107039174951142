import * as React from "react";
// import { runInAction } from "mobx";
import { Component } from "react";

// Material UI
import Typography from "@material-ui/core/Typography";

// Custom UI
import OptionsMenu, { MenuOption } from "../common/OptionsMenu";
import { ConfirmationMessage } from "../MessageBoxDialogs";
import ClickableCard from "../common/ClickableCard";

// Models
import { StudentList } from "../../models";

// Utils
import { strings } from "../../core/localizedStrings";
import { Fonts } from "../../core/utils/Fonts";
import { Colors } from "../../core/utils/Colors";

// Stores
import StudentListStore from "../../stores/StudentListStore";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

interface StudentListCardProps {
    classList: StudentList;
    store: StudentListStore;
}

export class StudentListCard extends Component<StudentListCardProps> {
    render() {
        return this.renderPaperChildren();
    }

    saveClass(event: any) {
        const { classList } = this.props;
        const name = event.currentTarget.value.trim();

        if (name.length && name !== classList.title) {
            this.props.store.updateStudentList(classList);
        }
    }

    protected onBackgroundClicked = async (event: any) => {
        let { activityStore } = this.props.store.rootStore;
        if (event.currentTarget.id === "background") {
            if (this.props.classList.key === "sample") {
                activityStore.setSampleStudentList(this.props.classList);
            } else {
                await activityStore.setCurrentStudentList(this.props.classList.key);
            }
            activityStore.setShouldShowStudentListSelector(false);
            activityStore.setNextStudent();
        }
    };

    private onMenuSelectionChanged = (menuSelection: MenuOption) => {
        switch (menuSelection) {
            case MenuOption.Edit:
                this.props.store.setItemToEdit(this.props.classList);
                break;
            case MenuOption.Delete:
                let text = strings.deleteStudentListConfirmation;
                let message = new ConfirmationMessage(text, undefined, (result, shouldQuell) => {
                    if (result === "yes") {
                        this.props.store.deleteClassroom(this.props.classList);
                    }
                });
                this.props.store.rootStore.showConfirmationMessage(message);

                break;
            case MenuOption.Move:
                this.props.store.setItemToMove(this.props.classList);
                break;

            default:
                break;
        }
    };

    private renderPaperChildren() {
        let styles = {
            container: {
                paddingLeft: 15,
                position: "relative",
                width: "100%",
            } as React.CSSProperties,
            title: {
                fontFamily: Fonts.BalsamiqSans,
                fontSize: 18,
                paddingTop: 5,
            } as React.CSSProperties,
            menu: {
                top: 0,
                right: 0,
                position: "absolute",
            } as React.CSSProperties,
        };

        return (
            <div style={{ position: "relative" }}>
                <ClickableCard onClickCallback={this.onBackgroundClicked}>
                    <Row style={{ width: "100%" }}>
                        <Col style={styles.container}>
                            <Row>
                                <div style={styles.title}>{this.props.classList.title}</div>
                            </Row>
                            <Row>
                                {this.props.classList.details.absentStudentCount > 0 ? (
                                    <Typography variant="subtitle1">
                                        <span>
                                            {`${strings.formatString(
                                                strings.totalStudentsFormat,
                                                this.props.classList.details.totalStudentCount
                                            )}, `}
                                        </span>
                                        <span style={{ color: Colors.red }}>
                                            {`${strings.formatString(
                                                strings.absentStudentsFormat,
                                                this.props.classList.details.absentStudentCount ?? 0
                                            )}`}
                                        </span>
                                    </Typography>
                                ) : (
                                    <Typography variant="subtitle1">
                                        {strings.formatString(
                                            strings.totalStudentsFormat,
                                            this.props.classList.details.totalStudentCount ?? 0
                                        )}
                                    </Typography>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </ClickableCard>
                {this.props.classList.key !== "sample" && <div style={styles.menu}>{this.renderMenu()}</div>}
            </div>
        );
    }

    private renderMenu() {
        let options: MenuOption[] = [MenuOption.Edit, MenuOption.Move, MenuOption.Delete];
        return <OptionsMenu options={options} onMenuSelectionChangedCallback={this.onMenuSelectionChanged} />;
    }
}
