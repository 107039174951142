import * as React from "react";
import { Observer } from "mobx-react-lite";
import { ActivityStore } from "stores";
import { OptionsGroup } from "components/activities/OptionsGroup";
import { GameOptions } from "../GameScene";
import { Button, Grid, Typography } from "@material-ui/core";
import { PhaserImages } from "../PhaserImages";
import * as Icons from "core/utils/Icons";
import QuizOptions from "models/activities/QuizOptions";
import { strings } from "core/localizedStrings";

export enum PopType {
    DART = "dart",
    POINTER = "pointer",
}

export const defaultSharkBaitOptions: SharkBaitOptions = {
    popType: PopType.DART,
    dartSpeedMultiplier: 1,
};

export interface SharkBaitOptions extends GameOptions {
    popType: PopType;
    dartSpeedMultiplier: number;
}

interface SharkBaitOptionsProps {
    activityStore: ActivityStore;
}

const SharkBaitOptions = (props: SharkBaitOptionsProps) => {
    const [value, setValue] = React.useState(0);

    const handlePopTypeChanged = (value: any) => {
        props.activityStore.activityOptions.sharkBaitOptions.popType = value;
    };

    const incrementDartSpeed = () => {
        let speed = props.activityStore.activityOptions.sharkBaitOptions.dartSpeedMultiplier;
        speed += 0.1;
        if (speed <= 5) {
            props.activityStore.activityOptions.sharkBaitOptions.dartSpeedMultiplier = speed;
        }
    };

    const decrementDartSpeed = () => {
        let speed = props.activityStore.activityOptions.sharkBaitOptions.dartSpeedMultiplier;
        speed -= 0.1;
        if (speed >= 0.2) {
            props.activityStore.activityOptions.sharkBaitOptions.dartSpeedMultiplier = speed;
        }
    };

    // const handlePlayAudioChanged = (value: boolean) => {
    //     props.activityStore.activityOptions.ticTacToeOptions.playAudioOnSelection = value;
    // };

    // console.log(props.activityStore.activityOptions.ticTacToeOptions.gameType);

    return (
        <Observer
            render={() => (
                <>
                    <QuizOptions activityStore={props.activityStore} />
                    <OptionsGroup heading={strings.popBalloonsWith}>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <Grid container justify="center" spacing={2}>
                                <Grid item>
                                    <Button
                                        variant={
                                            props.activityStore.activityOptions.sharkBaitOptions.popType ===
                                            PopType.DART
                                                ? "outlined"
                                                : undefined
                                        }
                                        onClick={() => {
                                            handlePopTypeChanged(PopType.DART);
                                        }}
                                    >
                                        <img
                                            src={PhaserImages.pathForImage(PhaserImages.RED_DART)}
                                            height={70}
                                            width={70}
                                        />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant={
                                            props.activityStore.activityOptions.sharkBaitOptions.popType ===
                                            PopType.POINTER
                                                ? "outlined"
                                                : undefined
                                        }
                                        onClick={() => {
                                            handlePopTypeChanged(PopType.POINTER);
                                        }}
                                    >
                                        <img src={require("assets/images/activities/cursorPointer.png")} height={70} />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </OptionsGroup>
                    {props.activityStore.activityOptions.sharkBaitOptions.popType === PopType.DART && (
                        <OptionsGroup heading={strings.dartMeterSpeed}>
                            <Grid container justify="center" alignItems="center">
                                <Grid item>
                                    <Button onClick={decrementDartSpeed}>
                                        <Icons.Minus />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Typography>{`${(
                                        props.activityStore.activityOptions.sharkBaitOptions.dartSpeedMultiplier * 100
                                    ).toFixed(0)}%`}</Typography>
                                </Grid>
                                <Grid item>
                                    <Button onClick={incrementDartSpeed}>
                                        <Icons.Add />
                                    </Button>
                                </Grid>
                            </Grid>
                        </OptionsGroup>
                    )}
                </>
            )}
        />
    );
};

export default SharkBaitOptions;
