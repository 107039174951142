import { log } from "../";

import { observable, action, computed, runInAction } from "mobx";
import { persist } from "mobx-persist";

import { LanguageType } from "../models";
import { Firestore } from "../core/firebase";
import Activity from "models/activities/Activity";

// Stores
import CategoryMenuStore from "./CategoryMenuStore";

// Models
import { TabSelection, Category, CategoryType, Folder, SortByType } from "models";
import FirestoreFolder from "models/customLists/FirestoreFolder";
import CategoriesSearchResultFolder from "models/customLists/CategoriesSearchResultFolder";

// Utils
import { strings } from "../core/localizedStrings";
import { CollectionReference } from "firebase/firebase-firestore";
import Utils from "core/utils/Utils";

export class BrowseListsStore extends CategoryMenuStore {
    @observable categoryToCreate: Category;
    @observable categoryToPreview: Category;
    @observable favoriteToAdd: Category;

    @observable showCategoryDetails: boolean = true;
    @observable shouldShowPreview: boolean = false;
    @observable shouldShowCategoryListSelector: boolean = false;
    @observable optionsExpanded: boolean = false;

    @persist @observable languageFilter = LanguageType.ENGLISH;
    @persist @observable listTypeFilter: CategoryType;

    @computed
    get siteListsSearchCount() {
        return this.searchTabs.get(TabSelection.SITE).totalHits;
    }

    @computed
    get publicListsSearchCount() {
        return this.searchTabs.get(TabSelection.PUBLIC).totalHits;
    }

    @computed
    get myListsSearchCount() {
        return this.searchTabs.get(TabSelection.MY_LISTS).totalHits;
    }

    @computed
    get favoriteListsSearchCount() {
        return this.searchTabs.get(TabSelection.FAVORITES).totalHits;
    }

    @action
    protected onStoreHydrated() {
        if (!this.folderTabs.get(TabSelection.SITE)) {
            this.folderTabs.set(TabSelection.SITE, new FirestoreFolder(TabSelection.SITE, this.rootStore));
            this.folderTabs.set(TabSelection.PUBLIC, new FirestoreFolder(TabSelection.PUBLIC, this.rootStore));
            this.folderTabs.set(TabSelection.MY_LISTS, new FirestoreFolder(TabSelection.MY_LISTS, this.rootStore));
            this.folderTabs.set(TabSelection.FAVORITES, new FirestoreFolder(TabSelection.FAVORITES, this.rootStore));
        }

        if (!this.searchTabs.get(TabSelection.SITE)) {
            this.searchTabs.set(TabSelection.SITE, new CategoriesSearchResultFolder(TabSelection.SITE, this.rootStore));
            this.searchTabs.set(
                TabSelection.PUBLIC,
                new CategoriesSearchResultFolder(TabSelection.PUBLIC, this.rootStore)
            );
            this.searchTabs.set(
                TabSelection.MY_LISTS,
                new CategoriesSearchResultFolder(TabSelection.MY_LISTS, this.rootStore)
            );
            this.searchTabs.set(
                TabSelection.FAVORITES,
                new CategoriesSearchResultFolder(TabSelection.FAVORITES, this.rootStore)
            );
        }

        if (!this.currentTab || !this.folderTabs.get(this.currentTab)) {
            this.currentTab = TabSelection.SITE;
            this.folderTabs.get(TabSelection.SITE).isVisible = true;
        } else {
            this.folderTabs.get(this.currentTab).isVisible = true;
        }

        this.updateFilters();
        this.isHydrated = true;
    }

    public setCurrentActivity(activity: Activity) {
        this.rootStore.activityStore.setCurrentActivity(activity);
    }

    public setCurrentCategory(category: Category) {
        this.rootStore.activityStore.setCurrentCategory(category.key);
    }

    @action
    public setShouldShowCategoryListSelector(value: boolean) {
        this.shouldShowCategoryListSelector = value;
    }

    public toggleOptionsExpanded = () => {
        runInAction(() => {
            this.optionsExpanded = !this.optionsExpanded;
        });
    };

    public refreshCurrentFolder = async () => {
        (this.visibleCategoryMenuFolder as FirestoreFolder).updateCurrentFolder(this.visibleFolder, true);
    };

    // Filters
    @action
    public setSortByType = (sortByType: SortByType) => {
        this.sortByType = sortByType;
        this.updateFilters();
    };

    @action
    public setLanguageFilter = (filter: LanguageType) => {
        if (filter !== this.languageFilter) {
            this.languageFilter = filter;
            this.updateFilters();
        }
    };

    @action
    public setListTypeFilter = (filter: CategoryType) => {
        if (filter !== this.listTypeFilter) {
            this.listTypeFilter = filter;
            this.updateFilters();
        }
    };

    @action
    public setCategoryItemToCreate = (category: Category) => {
        this.categoryToCreate = category;
        this.shouldShowCategoryDetailsEditor = true;
    };

    public async fetchRootFolderForTab(tab: TabSelection) {
        if ((tab === TabSelection.MY_LISTS || tab === TabSelection.FAVORITES) && !this.rootStore.userStore.user.uid) {
            return undefined;
        }

        if (tab === TabSelection.PUBLIC) {
            if (this.visibleCategoryMenuFolder.currentFolder) {
                return this.visibleCategoryMenuFolder.currentFolder;
            } else {
                let folder = new Folder();
                folder.key = Utils.guid();
                (this.visibleCategoryMenuFolder as FirestoreFolder).updateCurrentFolder(folder);
                return folder;
            }
        } else {
            return super.fetchRootFolderForTab(tab);
        }
    }

    // UI Updates
    // ------------------------------------------
    @action
    public setCategoryToPreview = (category: Category) => {
        this.shouldShowPreview = true;
        this.categoryToPreview = category;
    };

    @action
    public copyCategoryToFolder = async (parentFolder: Folder) => {
        let categoriesRef = Firestore.categoriesCollection();

        // Add Copy
        let categoryCopy: Category = new Category();
        Object.assign(categoryCopy, this.itemToCopy);
        categoryCopy.title = categoryCopy.title + " - " + strings.copyLower;
        categoryCopy.parentKey = parentFolder.key;
        categoryCopy.public = false;
        categoryCopy.created = new Date();
        categoryCopy.uid = this.rootStore.userStore.user.uid;
        categoryCopy.copyOf = this.itemToCopy.key;

        delete categoryCopy.key;
        delete categoryCopy.favoritedByFolders;
        delete categoryCopy.modified;

        let doc = await categoriesRef.add(categoryCopy.objectForFirebase);
        let categoryCopyKey = doc.id;

        runInAction(() => {
            this.itemToCopy = undefined;
        });

        // For each item that has an image, need to set a 'usedByCategory'
        // reference to the new category
        let createListsStore = this.rootStore.createListsStore;
        Object.keys(categoryCopy.items).forEach((key: string) => {
            let item = categoryCopy.items[key];
            if (item.imageKey !== undefined) {
                createListsStore.setUsedByCategoryForCategoryImage(item.imageKey, categoryCopyKey);
            }
        });
    };

    @action
    public addFavoriteToFolder = async (parentFolder: Folder) => {
        // Update category's favoritedBy and favoritedByFolders list
        if (!this.favoriteToAdd.isFavoritedBy(this.rootStore.userStore.user.uid)) {
            // Add reference to category
            let favoritedByFolders = Object.assign({}, this.favoriteToAdd.favoritedByFolders);
            favoritedByFolders[parentFolder.key] = this.rootStore.userStore.user.uid;
            let value = await Firestore.categoriesCollection().doc(this.favoriteToAdd.key).update({
                favoritedByFolders: favoritedByFolders,
                favoritedByCount: this.favoriteToAdd.favoritedByCount,
            });
            this.favoriteToAdd = undefined;
            log.verbose(value);
        } else {
            runInAction(() => {
                this.favoriteToAdd = undefined;
            });
        }
    };

    // Delete Data
    // -------------------------------------
    protected deleteItemsForFolder = async (folder: Folder) => {
        if (this.currentTab === TabSelection.MY_LISTS) {
            // Fetch all categories that have folder as parent
            let querySnapshot = await (Firestore.categoriesCollection() as CollectionReference)
                .where("parentKey", "==", folder.key)
                .get();

            for (let doc of querySnapshot.docs) {
                let value = doc.data();
                if (value !== undefined && value !== null) {
                    // Delete categories
                    let category = new Category(doc.data());
                    category.key = doc.id;
                    this.deleteCategory(category);
                }
            }
        } else {
            log.error("Delete folder not implemented for this type!!");
            return;
        }
    };

    public deleteCategory = async (category: Category) => {
        log.verbose("Deleting category with key: " + category.key);

        try {
            if (
                this.currentTab === TabSelection.MY_LISTS ||
                (this.currentTab === TabSelection.SITE && this.rootStore.userStore.user.isAdmin)
            ) {
                // Don't delete if it has been favorited, just remove parent folder key
                // if (category.favoritedByFolders !== undefined) {
                //     await this.refFactory
                //         .categoriesRef()
                //         .doc(category.key)
                //         .update({
                //             parentKey: null
                //         });
                //     log.verbose("Category has been favorited so it was removed
                // from parent folder, but not deleted.");
                // }

                // Mark as deleted
                let categoryRef = Firestore.categoriesCollection();
                categoryRef.doc(category.key).update({ deleted: true, deletedTime: new Date() });
            } else if (this.currentTab === TabSelection.FAVORITES) {
                // Remove favorite
                delete category.favoritedByFolders[this.visibleFolder.key];
                await Firestore.categoriesCollection().doc(category.key).update({
                    favoritedByFolders: category.favoritedByFolders,
                    favoritedByCount: category.favoritedByCount,
                });
            }
        } catch (error) {
            this.rootStore.showErrorMessage(error);
        }
    };

    // TODO !!!!!!
    // private deleteCategoryFromTrash(category: Category) {
    //     // Remove category
    //     let categoryRef = this.refFactory.categoriesTrashRef();
    //     categoryRef.doc(category.key).delete();

    //     // For each item that has an image, need to clear the 'usedByCategory' reference
    //     let createListsStore = this.rootStore.createListsStore;
    //     category.items.forEach((item: CategoryItem) => {
    //         if (item.imageKey !== undefined) {
    //             createListsStore.clearUsedByCategoryForCategoryImage(item.imageKey, category.key);
    //         }
    //     });
    // }

    private updateFilters = async () => {
        Array.from(this.folderTabs.values()).forEach((element) => {
            element.updateFilters(this.languageFilter, this.listTypeFilter);
        });

        Array.from(this.searchTabs.values()).forEach((element) => {
            (element as CategoriesSearchResultFolder).updateFilters(
                this.sortByType,
                this.languageFilter,
                this.listTypeFilter
            );
        });
    };
}
