import { useState, useEffect } from "react";
import { WindowSize } from "models";
import { useWindowSize } from "./useWindowSize";

const DEFAULT_HEIGHT = 1080;

const getPadding = (windowSize: WindowSize, paddingSize: number) => {
    let multiplier = windowSize.height / DEFAULT_HEIGHT;
    return multiplier * paddingSize;
};

export const useDynamicSize = (size: number) => {
    let windowSize = useWindowSize();
    let [padding, setPadding] = useState(getPadding(windowSize, size));

    function handleResize() {
        setPadding(getPadding(windowSize, size));
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return padding;
};
