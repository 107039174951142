import * as React from "react";
import { observer, inject } from "mobx-react";

// Material UI
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// Components
import ContactForm from "../../components/ContactForm";

// Stores
import RootStore from "../../stores/RootStore";

// Utils
import { strings } from "../../core/localizedStrings";

// Styles
import { GlobalStyles } from "../../styles/GlobalStyles";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

export interface ContactPageProps extends React.Props<any> {
    rootStore: RootStore;
}

@inject("rootStore")
@observer
export default class ContactPage extends React.Component<ContactPageProps> {
    render() {
        let rowPadding = 20;
        return (
            <div>
                <Paper style={GlobalStyles.paper}>
                    <Col xs={12}>
                        <Row>
                            <Typography style={GlobalStyles.pageHeading}>{strings.contact}</Typography>
                        </Row>
                        <Row style={{ paddingTop: rowPadding, paddingBottom: rowPadding }}>
                            <Typography style={GlobalStyles.pageDescription}>{strings.contactDescription}</Typography>
                        </Row>
                        <ContactForm store={this.props.rootStore} />
                    </Col>
                </Paper>
            </div>
        );
    }
}
