import Team from "./Team";
import { Student, StudentGender } from "../models";
import { list, serializable, object } from "serializr";
import range from "lodash.range";

import * as LayoutUtils from "../core/utils/LayoutUtils";
import { strings } from "../core/localizedStrings";
import { observable, action, computed } from "mobx";

export default class TeamManager {
    @observable private _currentTeamIndex: number = 0;
    @observable @serializable(list(object(Team))) private teamList: Team[];

    private currentStudentCounts: number[];

    public get currentTeamIndex() {
        return this._currentTeamIndex;
    }

    public set teams(teamList: Team[]) {
        this.teamList = teamList;
    }

    @computed public get teams(): Team[] {
        return this.teamList;
    }

    public get numberOfTeams() {
        if (this.teamList) {
            return this.teamList.length;
        } else {
            return 0;
        }
    }

    public get isOnLastTeam() {
        return this._currentTeamIndex === this.numberOfTeams - 1;
    }

    public get nextStudent() {
        if (!this.teamList) {
            return undefined;
        }

        if (!this.currentStudentCounts) {
            this.currentStudentCounts = range(0, this.teamList.length, 0);
        }

        let currentTeam = this.teamList[this._currentTeamIndex];
        if (!currentTeam.students) {
            return undefined;
        }

        let student = currentTeam.students[this.currentStudentCounts[this._currentTeamIndex]];
        this.currentStudentCounts[this._currentTeamIndex] += 1;
        if (this.currentStudentCounts[this._currentTeamIndex] >= currentTeam.students.length) {
            this.currentStudentCounts[this._currentTeamIndex] = 0;
        }

        return student;
    }

    constructor(numberOfTeams: number) {
        // Create empty teams
        this.teamList = range(1, numberOfTeams + 1).map((teamNumber) => {
            let team = new Team();
            team.name = strings.formatString(strings.teamNumberFormat, teamNumber) as string;
            return team;
        });
    }

    public setTeams(teams: Team[]) {
        this.teamList = teams;
    }

    public reset() {
        for (let team of this.teamList) {
            team.currentGamePoints = 0;
        }
        this.setCurrentTeamIndex(0);
    }

    @action
    public incrementTeam = () => {
        this._currentTeamIndex += 1;
        if (this._currentTeamIndex >= this.teamList.length) {
            this._currentTeamIndex = 0;
        }
    };

    @action
    public incrementPointsForCurrentTeam(value: number) {
        if (this._currentTeamIndex !== undefined) {
            let teamsCopy = this.teams.slice();
            let team = teamsCopy[this._currentTeamIndex];
            if (!team.currentGamePoints) {
                team.currentGamePoints = value;
            } else {
                team.currentGamePoints += value;
            }
            this.teams = teamsCopy;
        }
    }

    public setCurrentTeamPoints(value: number) {
        if (this._currentTeamIndex !== undefined) {
            let teamsCopy = this.teams.slice();
            let team = teamsCopy[this._currentTeamIndex];
            team.currentGamePoints = value;
            this.teams = teamsCopy;
        }
    }

    public getWinner = () => {
        let maxPoints = 0;
        for (var team of this.teamList) {
            if (team.currentGamePoints > maxPoints) {
                maxPoints = team.currentGamePoints;
            }
        }
        let winningTeams = this.teamList.filter((team) => {
            return team.currentGamePoints === maxPoints;
        });
        if (winningTeams.length === 1) {
            return winningTeams[0].name + " wins!";
        } else if (winningTeams.length === this.teamList.length) {
            return "It's a tie!";
        } else if (winningTeams.length === 2) {
            return `It's a tie between ${winningTeams[0].name} and ${winningTeams[1].name}!`;
        } else {
            let tieTeams = "It's a tie between ";
            for (let i = 0; i < winningTeams.length; i++) {
                let name = winningTeams[i].name;
                if (i === winningTeams.length - 1) {
                    tieTeams += ` ${name}.`;
                } else if (i === winningTeams.length - 2) {
                    tieTeams += `${name}, and`;
                } else if (i === winningTeams.length - 2) {
                    tieTeams += `${name}, `;
                }
            }
            return tieTeams;
        }
    };

    public setCurrentTeamIndex = (teamNumber: number) => {
        this._currentTeamIndex = teamNumber;
    };

    public setCurrentTeam = (teamNumber: number) => {
        this._currentTeamIndex = teamNumber;
    };

    public getCurrentTeam = () => {
        return this.teams[this._currentTeamIndex];
    };

    public addStudentsToGirlsVsBoysTeams(students: Student[]) {
        let boys: Student[] = [];
        let girls: Student[] = [];

        students = LayoutUtils.shuffleArray(students);
        for (var student of students) {
            if (student.gender === StudentGender.Boy) {
                boys.push(student);
            } else {
                girls.push(student);
            }
        }

        this.teamList[0].name = strings.boys;
        this.teamList[0].students = boys;

        this.teamList[1].name = strings.girls;
        this.teamList[1].students = girls;
    }

    public addStudentsToTeams = (students: Student[], teamNames: [string] = undefined) => {
        let numberOfTeams = this.teamList.length;
        let studentsPerTeam = Math.floor(students.length / numberOfTeams);
        let teamsWithExtraStudent = students.length % numberOfTeams;

        let start = 0;
        let end = 0;

        students = LayoutUtils.shuffleArray(students);

        let updatedTeamList = this.teamList.slice();
        for (var i = 0; i < numberOfTeams; i++) {
            end = start + studentsPerTeam;
            if (i < teamsWithExtraStudent) {
                end += 1;
            }

            // Students
            updatedTeamList[i].students = students.filter((student, index) => {
                return index >= start && index < end ? true : false;
            });
            start = end;

            // Name
            if (teamNames !== undefined) {
                updatedTeamList[i].name = teamNames[i];
            }
        }

        this.teamList = updatedTeamList;
    };
}
