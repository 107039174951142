/* tslint:disable */
export let spanish = {
    absent: "Ausente",
    absentStudentsFormat: "{0} ausente",
    accountRegistration: "Registro de cuenta",
    active: "Activo",
    activityPreview: "Vista previa de la actividad",
    activityPreviewMessage: "Por favor inicie sesión o regístrese para obtener una cuenta gratuita.",
    add: "Añadir",
    addAccount: "Añadir cuenta",
    addCard: "Agregar tarjeta",
    addCards: "Añadir tarjetas",
    addFolder: "Agregar carpeta",
    addGroupStudentAccount: "Añadir cuenta de estudiante de grupo",
    addKeyword: "Añadir palabra clave",
    addName: "Añadir nombre",
    addTeacherAccount: "Añadir cuenta de profesor",
    addToFavorites: "Agregar a los favoritos",
    addWithPlus: "+ Añadir",
    additionalResources: "Recursos adicionales",
    admin: "Administración",
    affirmative: "Afirmativo",
    afterTrial:
        "Después de la prueba de 30 días, puede continuar usando el sitio como miembro básico, o actualizar de nuevo a Premium.",
    all: "Todos",
    allFieldsRequired: "Todos los campos son obligatorios.",
    allowCardsToBeFlipped: "Permitir que las cartas sean volteadas",
    allowedToChangePassword: "profesor permitido cambiar la contraseña",
    annual: "Anual",
    annualSubscription: "Suscripción anual",
    answer: "Responder",
    audioClipLanguageLabel: "Lenguaje de clip de audio",
    backToWorksheets: "Volver a las hojas de trabajo",
    basic: "BASIC",
    basicMemberCredits:
        "* Los miembros básicos reciben 8 créditos mensuales. Una vez que todos los créditos han sido utilizados,\n        El acceso al sitio estará limitado hasta el primer día del mes siguiente.",
    billedAnnually: "Factura anual",
    billedAnnuallyFormat: "Facturado anualmente ({0})",
    billedMonthly: "Facturado mensualmente",
    billedMonthlyFormat: "Facturado mensualmente ({0})",
    billingFrequency: "Frecuencia de facturación",
    boy: "Niño",
    boys: "Muchachos",
    browseAllCategories: "Examinar / Editar categorías",
    browseAllLists: "Examinar / Editar listas",
    cancel: "Cancelar",
    cancelSubscription: "Cancelar suscripción",
    cannotAddManagedAccountsInTrial: "Las cuentas administradas no se pueden agregar durante el período de prueba.",
    cannotBeEmpty: "No puede estar vacío.",
    card: "Tarjeta",
    cardLower: "tarjeta",
    cardsLower: "tarjetas",
    category: "Categoría",
    category2: "Categoría 2",
    categoryCharactersNotSupported: "Esta categoría contiene caracteres que aún no son compatibles con esta actividad.",
    categoryTypeNotSupported: "Este tipo de categoría aún no es compatible con esta categoría.",
    changeCategory: "Cambiar categoría",
    changeEditStudents: "Cambiar / Editar Estudiantes",
    changePassword: "Cambia la contraseña",
    checkEmailForVerifcation: "Por favor revise su correo electrónico para completar la activación de su cuenta.",
    chinese: "chino",
    className: "Nombre de la clase",
    clear: "Claro",
    clearFilters: "Borrar filtros",
    clearSelectedImage: "Borrar imagen seleccionada",
    clearSelectedImages: "Borrar imágenes seleccionadas",
    clickHereTo: "No cuenta Click aquí para",
    confirmChangeCategory: "Cambiar la categoría restablecerá la actividad. ¿Seguro que quieres cambiarlo?",
    confirmChangeClassList: "Cambiar la lista de estudiantes restablecerá la actividad. ¿Seguro que quieres cambiarlo?",
    confirmChangeTeamNumber: "Cambiar la cantidad de equipos restablecerá la actividad. ¿Seguro que quieres cambiarlo?",
    confirmRestartSubscription:
        "¿Estás seguro de que deseas reiniciar tu suscripción? Su tarjeta de crédito no será cargada hasta {0}.",
    contact: "Contacto",
    contactAdminMessage: " Si el problema persiste, póngase en contacto con el administrador del sitio.",
    contactDescription:
        "Por favor, háganos saber cualquier pregunta o problema que tenga. Simplemente envíe un mensaje a continuación y nos pondremos en contacto con usted lo antes posible.",
    contactFormSubmitted: "Gracias! Tu mensaje ha sido enviado.",
    copy: "Dupdo",
    copyLower: "dupdo",
    createCardFromSelectedImage: "Crear tarjeta a partir de la imagen seleccionada.",
    createCardsFromSelectedImages: "Crear tarjetas de {0} imágenes seleccionadas",
    createCopyIn: "Crear copia en:",
    createCustomLists: "Crear listas personalizadas",
    createNewList: "Crear nueva lista",
    createSelectCategory: "Crea o selecciona una lección",
    createSelectCategoryDescription:
        "Cree una lección personalizada en segundos o busque entre miles de las existentes.",
    createSelectClassList: "Crea o selecciona una lista de estudiantes",
    createStudentNameLists: "Crear listas de nombres de estudiantes",
    createTeams: "Crear equipos",
    createdBy: "Creado por",
    createdOn: "Creado en",
    creditCard: "Tarjeta de crédito",
    creditNeeded: "1 crédito por uso",
    currentSubscriptionRequiredForManagedAccounts:
        "Se requiere una suscripción activa. Por favor reinicie su suscripción para agregar cuentas adicionales.",
    customizeHeader: "{{Personalizar}} para adaptarse a cualquier lección",
    customizeText:
        "Tome una lista de vocabulario simple o una lista de preguntas y conviértala en una Actividad en segundos. Una vez que se ha creado una lista, se puede utilizar con cualquier actividad en todo el sitio.",
    dataBeingMigratedFromBarryFunEnglish:
        "Los datos de su cuenta se están migrando desde BarryFunEnglish.Esto solo debe hacerse una vez. Dependiendo de la cantidad de datos, esto puede tomar desde un\n        De unos segundos a unas pocas horas. Si no ve todos sus datos después de unas horas, comuníquese con\n        El administrador del sitio.",
    delete: "Borrar",
    deleteCategoryConfirmation: "¿Está seguro de que desea eliminar de forma permanente esta categoría?",
    deleteFavoriteConfirmation: "¿Está seguro de que desea eliminar esto de sus favoritos?",
    deleteFolderConfirmation:
        "¿Está seguro de que desea eliminar de forma permanente esta carpeta y todo su contenido?",
    deleteStudentListConfirmation: "¿Está seguro de que desea eliminar esta lista?",
    discount: "Descuento ({0}%):",
    discounts: "Descuentos:",
    done: "Hecho",
    dutch: "holandés",
    edit: "Editar",
    editProfile: "Editar perfil",
    email: "Email",
    emailAlreadyRegistered: "la dirección de correo electrónico ya ha sido registrada",
    emailOrUsername: "Correo electrónico o nombre de usuario",
    emailRequiredError: "Se requiere nombre de usuario.",
    emailSentTo: "Correo electrónico enviado a {0}.",
    endGame: "Juego final",
    english: "Inglés",
    enterEmailUsedToRegister:
        "Por favor ingrese la dirección de correo electrónico que usó para registrarse en el sitio:",
    enterIncorrectAnswers: "Introduce mis propias respuestas incorrectas",
    enterQuestion: "Introducir pregunta",
    enterText: "Ingrese texto:",
    enterValidEmail: "Por favor, introduce una dirección de correo electrónico válida.",
    enterVocabAndQuestion: "Ingrese vocabulario palabra y pregunta",
    enterVocabulary: "Ingresar vocabulario",
    existingAccount: "cuenta existente",
    expirationDateFormat: "Fecha de expiración: {0}",
    expired: "Muerto",
    expires: "Vence",
    expressionInputTextField1Label: "Campo de texto 1 (pregunta)",
    expressionInputTextField2Label: "Campo de texto 2 (Respuesta)",
    favorites: "Favoritos",
    flashcards: "Flashcards",
    folderName: "Nombre de la carpeta:",
    forClassroomHeader: "Diseñado para el {{aula}}",
    forClassroomText:
        "Los juegos y actividades están diseñados para su uso en el aula y trabajar muy bien con clases grandes o pequeñas.\n            Los juegos pueden tener entre dos y cinco equipos y las listas de nombres de los estudiantes se pueden ingresar para una selección aleatoria.",
    forgotPassword: "¿Se te olvidó tu contraseña?",
    free: "Gratis",
    french: "francés",
    games: "Juegos",
    gender: "Género",
    german: "alemán",
    girl: "Niña",
    girls: "Chicas",
    girlsVsBoys: "Chicas vs Niños",
    greek: "griego",
    groupNumberFormat: "Grupo {0}",
    homePageContactMessage: "¿Tiene alguna {{preguntas}} para nosotros?",
    imageOverlayGraphic: "Gráfico de superposición de imagen",
    imageWithText: "Imagen con un solo campo de texto.",
    imageWithTextDescription:
        "Estas listas son excelentes para practicar vocabulario, leer y hablar. Trabajan con casi cualquier actividad en el sitio.",
    imageWithTwoText: "Imagen con dos campos de texto.",
    imageWithTwoTextDescription:
        "Estas listas se utilizan para hacer preguntas sobre una imagen o para traducir entre dos idiomas",
    imagesSelectorInstructions: "Selecciona o sube imágenes para crear tarjetas desde:",
    interactive: "Interactivo",
    interactiveFlashcards: "Tarjetas interactivas",
    introduce: "Introducir",
    invalidEmail: "Dirección de correo electrónico no válida.",
    invalidPassword: "Las contraseñas deben tener al menos 6 caracteres de largo.",
    invalidUsername: "Nombre de usuario no válido.",
    invalidUsernameOrPassword: "Has introducido un nombre de usuario o contraseña no válidos",
    italian: "italiano",
    japanese: "japonés",
    keywordsLabel: "Palabras clave",
    korean: "coreano",
    language: "Idioma",
    languageTitle: "Idioma",
    learn: "Aprender",
    learnDescription: "Presente una nueva lección o reseña con tarjetas flash interactivas o imprimibles.",
    lesson: "Lección",
    lessonDescription: "Mezcla de imagen única e imagen con dos campos de texto.",
    lessonTypeSelectorTitle: "¿Te gustaría practicar el vocabulario o las preguntas de esta lección?",
    listAccessibility: "Lista de accesibilidad",
    listTitleLabel: "Título de la lista",
    listTypeTitle: "Tipo de lista",
    loggingIn: "Iniciar sesión",
    loginToCopy: "Debes iniciar sesión para copiar listas.",
    loginToCreateFolders: "Debes iniciar sesión para crear carpetas.",
    loginToCreateLists: "Debes iniciar sesión para crear listas personalizadas.",
    loginToEditLists: "Debes iniciar sesión para editar listas.",
    loginToFavorite: "Debes iniciar sesión para guardar favoritos.",
    logout: "Cerrar sesión",
    mainPageText: "Actividades personalizadas de aprendizaje de idiomas para cualquier clase.",
    managedAccounts: "Cuentas gestionadas",
    managedAccountsDescription:
        "Agregue cuentas administradas si desea múltiples membresías bajo una suscripción. Los descuentos se ofrecen en función del número de cuentas totales.",
    maxFilesUploadAttempt: "Solo puede cargar un máximo de {0} archivos a la vez.",
    membershipTypes: "Tipos de membresia",
    message: "Mensaje",
    misses: "Misses",
    monthly: "Mensual",
    monthlySubscription: "Suscripción mensual",
    move: "Movimiento",
    moveFolderTo: "Mueve la carpeta a:",
    moveListTo: "Mover la lista a:",
    multipleChoiceInstructions:
        "Con las actividades de opción múltiple, ¿qué quieres usar para las respuestas incorrectas?",
    multipleLanguageSupport: "Múltiples idiomas soportados",
    mustHaveOneIncorrectAnswers: "Debes ingresar al menos una respuesta incorrecta.",
    myAccount: "Mi cuenta",
    myClasses: "Mis clases",
    myImages: "Mis imagenes",
    myLists: "Mis listas",
    name: "Nombre",
    negative: "Negativo",
    newFolder: "Nueva carpeta",
    nextBillingDate: "Fecha de facturación siguiente: {0} ({1})",
    no: "No",
    noAcceptableFilesFound:
        "No se encontraron archivos válidos. Los archivos están limitados a un tamaño máximo de {0} MB.",
    noCardOnFile: "No hay tarjeta en el archivo",
    noCreditCardRequired: "No se requiere tarjeta de crédito.",
    noRemainingCredits:
        "No quedan créditos este mes. Regístrese para obtener una cuenta premium para acceso ilimitado.",
    none: "Ninguna",
    notActivated: "No esta activado",
    notActive: "No activo",
    notChargedUntilFormat: "La tarjeta de crédito no será cargada hasta {0}.",
    notChargedUntilTrialComplete: "Su tarjeta de crédito no se cargará hasta el final del período de prueba.",
    numberOfTeams: "Numero de equipos",
    off: "Apagado",
    ok: "De acuerdo",
    on: "En",
    oneRemainingCredit: "Tienes 1 crédito restante este mes.",
    options: "Opciones",
    or: "o",
    page: "Página",
    pageNotFound: "Página no encontrada",
    password: "Contraseña",
    passwordLengthError: "La contraseña debe tener al menos 6 caracteres.",
    passwordRequiredError: "se requiere contraseña",
    passwordResetEmailHasBeenSendMessage:
        "Por favor revise su correo electrónico en busca de un enlace para restablecer su contraseña.",
    pay: "Paga",
    payBalance: "Pagar saldo",
    paymentMethod: "Método de pago",
    pendingSubscription: "Suscripción pendiente",
    play: "Jugar",
    playDescription: "Juegos emocionantes para clases grandes y pequeñas.",
    pleaseAddCardForRestart: "Debe agregar una tarjeta de crédito antes de reiniciar su suscripción.",
    portuguese: "portugués",
    practice: "Práctica",
    practiceDescription: "Hojas de trabajo interactivas e imprimibles",
    premium: "Prima",
    premiumPriceFormatMonth: "$ {0} / mes",
    premiumPriceFormatYear: "$ {0} / año",
    premiumTrial: "Premium (prueba)",
    preview: "Avance",
    price: "Precio",
    print: "Impresión",
    printDescription: "Imprima tarjetas u hojas de trabajo para practicar sin conexión",
    printable: "Imprimible",
    printableFlashcards: "Flashcards imprimibles",
    private: "Privado",
    problemSubmittingContactForm: "Hubo un problema enviando su mensaje.",
    problemUpdatingUserErrorMessage: "Ocurrió un error al actualizar los detalles del usuario.",
    problemUsingCredit: "Hubo un problema usando tus créditos.",
    profile: "Perfil",
    proratedAmountDue: "Cantidad debida ahora: {0}",
    public: "Público",
    publicLists: "Listas de la comunidad",
    quellMessage: "No mostrar este mensaje de nuevo",
    question: "Pregunta",
    questions: "Preguntas",
    quiz: "Examen",
    randomize: "Aleatorizar",
    randomizeAndReset: "Aleatorizar y reiniciar",
    recent: "Reciente",
    register: "registro",
    registerUsernamePassword: "Registrarse con nombre de usuario / contraseña",
    rejectedFileFormat:
        "El archivo {0} no se cargó. Archivo puede haber sido demasiado grande o de la Tipo de archivo incorrecto. Sólo se aceptan archivos de imagen de 500 KB o más pequeños.",
    rejectedFilesFormat:
        "{0} archivos no fueron subidos. Los archivos pueden haber sido demasiado grandes o d Tipo de archivo incorrecto. Sólo se aceptan archivos de imagen de 500 KB o más pequeños.",
    remainingCreditsFormat: "Tienes {0} créditos restantes este mes.",
    removeAllLetters: "Eliminar todas las letras",
    removeFromFavorites: "Remover de favoritos",
    removeManagedUserAccountConfirmation:
        "Esta cuenta será eliminada y el usuario ya no será permitido para iniciar sesión. ¿Estás seguro de que deseas eliminar de forma permanente esta cuenta?",
    resendEmail: "Reenviar email",
    reset: "Reiniciar",
    restartSubscription: "Suscripción de reinicio",
    restartSubscriptionMessage: "Por favor seleccione una frecuencia de facturación para su suscripción.",
    review: "revisión",
    reviewDescription: "Pruebas de opción múltiple para escuchar, leer, escribir y hablar",
    russian: "ruso",
    sameAsTextField1Label: "Igual que el campo de texto 1",
    sampleClass: "Clase de muestra",
    save: "Salvar",
    search: "Buscar",
    select: "Seleccionar...",
    selectACategory: "Seleccione una categoría",
    selectCategoryBeforeActivity: "Debe seleccionar una categoría antes de seleccionar una actividad.",
    selectFolderToAddFavoriteTo: "Seleccione la carpeta para agregar favorito a:",
    selectStudentList: "Seleccione una lista de estudiantes",
    showCategoryDetails: "mostrar detalles",
    signIn: "Iniciar sesión",
    signInRegister: "Iniciar sesión / Registrarse",
    signInUsernamePassword: "Iniciar sesión con nombre de usuario / contraseña",
    signUpForFree: "Registrate gratis",
    signUpForFreeMessage: "¡Regístrese ahora para obtener una cuenta Premium gratuita durante 30 días!",
    singleTeacher: "Profesor soltero",
    singleText: "Campo de texto único",
    singleTextDescription:
        "Estas listas son excelentes para la lectura y la práctica de la ortografía. Ellos son también Útil para actividades que no requieren una imagen.",
    siteImages: "Imágenes del sitio",
    siteLists: "Listas de sitios",
    sortByPopularity: "Popularidad",
    sortByRecentlyCreated: "Recientemente creado",
    sortByRelevance: "Pertinencia",
    sortByTitle: "Título",
    sortByTypeTitle: "Ordenar por",
    spanish: "Español",
    start: "comienzo",
    startSubscription: "Iniciar Suscripción",
    startSubscriptionAfterTrial:
        "Comience su suscripción para continuar con su membresía 'Premium' después La prueba está completa.",
    studentGroup: "grupo de estudiantes",
    studentGroups: "grupos de estudiantes",
    studentList: "Lista de estudiantes",
    subject: "Tema",
    submit: "Enviar",
    subscribe: "Suscribir",
    subscription: "Suscripción",
    subscriptionDescriptionFormat: "Tipo de suscripción: {0}",
    subscriptionType: "Tipo de suscripción",
    subtotal: "Total parcial:",
    support: "Soporte de contacto",
    teacher: "profesor",
    teacherCountFormat: "Cantidad de profesores: {0}",
    teachers: "maestros",
    teachingTools: "Herramientas de enseñanza",
    teamNumberFormat: "Equipo {0}",
    tenOrMore: "Diez o mas",
    textField1Label: "(Pregunta)",
    textField2Label: "(Responder)",
    textToSpeechSupportFormat: "Clips de audio creados automáticamente con tecnología de texto a voz.",
    thousandsOfHeader: "{{Miles}} de categorías para elegir",
    thousandsOfText:
        "¿No quieres crear tus propias categorías? Navegar a través de Miles creados por la comunidad KinteractiveLearning. Más se añaden todos los días!",
    threeOrMore: "Tres o mas",
    titleCannotBeEmptyInfoMessage: "El título no puede estar vacío. Por favor, introduzca un título.",
    tools: "Herramientas",
    toolsDescription: "Grupo y creadores de equipos, selector aleatorio de estudiantes, cronómetro y marcador.",
    totalDue: "Total a pagar:",
    totalManagedAccounts: "Total de cuentas administradas:",
    totalStudentsFormat: "{0} estudiantes",
    turkish: "turco",
    twoTextOnly: "Dos campos de texto",
    twoTextOnlyDescription:
        "Estas listas se utilizan para hacer preguntas que no requieren una imagen, o para traducir entre dos idiomas",
    type: "Tipo",
    unlimited: "Ilimitado",
    update: "Actualizar",
    updateEmailError: "Ocurrió un error al actualizar la dirección de correo electrónico.",
    uploadImages: "Subir imagenes",
    uploadImagesDropzoneInstructions: "Suelte la (s) imagen (es) o haga clic para explorar su computadora.",
    uploadMessage: "Subiendo {0} imágenes.",
    uploadProfileImageError: "Se ha producido un error al subir la imagen del perfil.",
    useAsVocabulary: "Añadir a la lista de vocabulario",
    useCredit: "Usar credito",
    useOtherAnswersAsIncorrect: "Usa otras tarjetas respuestas correctas",
    username: "Nombre de usuario",
    usernameOptional: "Nombre de usuario (opcional)",
    usernameRequiredError: "Se requiere nombre de usuario.",
    usernameTaken: "Este nombre de usuario ya está tomado",
    usernameUnavailable: "nombre de usuario no esta disponible",
    vocabulary: "Vocabulario",
    worksheetCreators: "Creadores de la hoja de trabajo",
    worksheets: "Hojas de trabajo",
    yes: "Sí",
    getStarted: "",
    requiresFlash: "",
    onlySupportsLatinBasedLanguages: "",
    upgradeToPremium: "",
    startReview: "",
    numberOfGroups: "",
    hideAnswer: "",
    autoPlayAudio: "",
    imageBank: "",
    doubleSidedCards: "",
    ignoreFlippedCardsForReview: "",
    soft: "",
    medium: "",
    strong: "",
    continuePlaying: "",
    languageNotSupported: "",
    createLink: "",
    pinActivityToDashboard: "",
    enterName: "",
    createStudentLink: "",
    expiration: "",
    oneDay: "",
    oneWeek: "",
    never: "",
    link: "",
    copyToClipboard: "",
    pinnedActivities: "",
    studentActivityLinks: "",
    numberOfCards: "",
    matchType: "",
    imageType: "",
    otherOptions: "",
    wordImage: "",
    imageImage: "",
    wordWord: "",
    activity: "",
    seeLinkVisitors: "",
    recentHistory: "",
    startTime: "",
    completedTime: "",
    results: "",
    iDontKnow: "",
    iKnowTheAnswer: "",
    seconds: "",
    minutes: "",
    hours: "",
    pause: "",
    set: "",
    audioHeading: "",
    playAudioOnCorrect: "",
    playAudioOnWrong: "",
    answerTypeHeading: "",
    multipleChoiceAnswerTypeOption: "",
    rightOrWrongAnswerTypeOption: "",
    showNumbersHeading: "",
    showNumbersMultipleChoice: "",
    timerHeading: "",
    showTimerMultipleChoice: "",
    game: "",
    correct: "",
    wrong: "",
    teams: "",
    imagesInGrid: "",
    multipleChoiceOptions: "",
    playAudioOnSelection: "",
    classicModeOptions: "",
    selectAll: "",
    flipCardsToFront: "",
    flipCardsToBack: "",
    shuffle: "",
    showAllCards: "",
    bonusRound: "",
    crazyCupsIntroMessage: "",
    crazyCupsBonusRoundMessage: "",
    goodJob: "",
    sorryYouMissedIt: "",
    teamReadyFormat: "",
    readyToTryAgain: "",
    cupsSpeedAdjustment: "",
    textOnly: "",
    imageText: "",
    imageOnly: "",
    hideShips: "",
    useClassicMode: "",
    classicModeDescription: "",
    stop: "",
    bullsEye: "",
    removeNameOnceSelected: "",
    clearDartAfterThrow: "",
    stopBoardAfterThrow: "",
    playSoundEffect: "",
    spinForPoints: "",
    showImageProfileImage: "",
    cardType: "",
    gridSize: "",
    cardsPerPage: "",
    noListsGetStarted: "",
    noImagesGetStarted: "",
    isTraceable: "",
    twoColumns: "",
    randomizeWorksheet: "",
    createPDF: "",
    fontSize: "",
    goodJobReadyForNext: "",
    whoGoesFirst: "",
    teamGoesFirstFormat: "",
    popBalloonsWith: "",
    dartMeterSpeed: "",
    fontStyle: "",
    cardSensitivity: "",
    points: "",
    hotOrColdInstructions: "",
    inviteUsers: "",
    availableInvitesFormat: "",
    numberOfAccountsToAdd: "",
    removeSubscriptionManagerConfirmation: "",
    managedSubscription: "",
    disconnectFromAccountManager: "",
    confirmToBeManagedConfirmationFormat: "",
    pendingManagerNotFound: "",
    steal: "",
    newWord: "",
    canYouSpell: "",
    wouldLikeToStealFormat: "",
    selectSecondCategory: "",
    clearSecondCategory: "",
    borderThickness: "",
    borderColor: "",
    subscriptionIsPastDue: "",
};
