import * as React from "react";

// Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

// Utiles
import { TEN_OR_GREATER_DISCOUNT, THREE_OR_GREATER_DISCOUNT } from "core/utils/PaymentUtils";
import { strings } from "core/localizedStrings";

export interface ManagedAccountsInfoDialogProps extends React.Props<any> {
    showDialog: boolean;
    onCloseCallback: () => void;
}

export default class ManagedAccountsInfoDialog extends React.Component<ManagedAccountsInfoDialogProps> {
    render() {
        return (
            <Dialog open={this.props.showDialog} onClose={this.props.onCloseCallback}>
                <DialogTitle>{strings.managedAccounts}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {strings.managedAccountsDescription}
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{strings.discounts}</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {strings.threeOrMore}
                                    </TableCell>
                                    <TableCell align="right">{THREE_OR_GREATER_DISCOUNT * 100}%</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {strings.tenOrMore}
                                    </TableCell>
                                    <TableCell align="right">{TEN_OR_GREATER_DISCOUNT * 100}%</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}
