import { log } from "index";

import * as React from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";

import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import TranslationsDB, {
    EnglishRevision,
    TranslationRevision,
    CollectionName,
    MachineTranslation,
} from "./TranslationsDB";
import TranslationBase, { TranslationBaseProps } from "./TranslationBase";

import { LanguageType } from "../../models";

import { Colors } from "../../core/utils/Colors";
import { Typography, TextField } from "@material-ui/core";

@inject("rootStore")
@observer
export default class Translations extends TranslationBase {
    @observable
    private onlyShowChanged = false;

    @observable
    private currentEnglishRevision: EnglishRevision;

    @observable
    private currentTranslationRevision: TranslationRevision;

    @observable
    private currentMachineTranslation: MachineTranslation;

    private notes = new Map<string, string>();
    private lastEnglishRevision: EnglishRevision;
    private lastTranslationRevision: TranslationRevision;
    private changedOrUntranslated: any;

    constructor(props: TranslationBaseProps) {
        super(props);
        this.init();
    }

    async init() {
        await this.getRevisions();
        await this.updateRevisionFromDictionary();
    }

    @action
    setCurrentEnglish(value: EnglishRevision) {
        this.currentEnglishRevision = value;
    }

    @action
    setCurrentTranslation(value: TranslationRevision) {
        this.currentTranslationRevision = value;
    }

    @action
    setCurrentMachineTranslation(value: MachineTranslation) {
        this.currentMachineTranslation = value;
    }

    @action
    setCurrentStrings(value: Map<string, string>) {
        let updated = Object.assign({}, this.currentTranslationRevision);
        updated.strings = value;
        this.currentTranslationRevision = updated;
    }

    @action
    setCurrentNotes(value: Map<string, string>) {
        let updated = Object.assign({}, this.notes);
        updated = value;
        this.notes = updated;
    }

    protected renderToolbar() {
        if (this.props.rootStore.userStore.user.isAdmin) {
            return (
                <>
                    <Button
                        color="secondary"
                        onClick={this.onUpdateCurrentEnglish}
                        style={{ color: Colors.lightBlue, marginTop: -5 }}
                    >
                        Set Current English
                    </Button>
                    <Button
                        color="secondary"
                        onClick={this.onCreateNewRevisionClicked}
                        style={{ color: Colors.lightBlue, marginTop: -5, paddingLeft: 10 }}
                    >
                        Create Revision
                    </Button>
                    <Button
                        color="secondary"
                        onClick={this.printCurrentTranslation}
                        style={{ color: Colors.lightBlue, marginTop: -5, paddingLeft: 10 }}
                    >
                        Print translation object
                    </Button>
                </>
            );
        }
    }

    protected renderChildren() {
        if (!this.currentEnglishRevision || !this.currentTranslationRevision || !this.currentMachineTranslation) {
            return null;
        }
        let englishStrings = this.onlyShowChanged ? this.changedOrUntranslated : this.currentEnglishRevision.strings;
        return (
            <>
                {this.props.rootStore.userStore.user.isAdmin && (
                    <>
                        <Typography variant="h6">
                            Current revision: {this.currentTranslationRevision.revisionNumber}
                        </Typography>
                        <Typography variant="subtitle1">
                            Revision updated: {this.currentTranslationRevision.date.toLocaleString()}
                        </Typography>
                    </>
                )}
                <Typography variant="subtitle1">Total word count: {this.totalEnglishWordCount()}</Typography>
                <FormControlLabel
                    control={<Checkbox checked={this.onlyShowChanged} onChange={this.onOnlyShowChangedClicked} />}
                    label="Only show empty fields or strings that have changed since last version"
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>English</TableCell>
                            <TableCell>Manual Translation</TableCell>
                            <TableCell>Google Translate</TableCell>
                            <TableCell>Notes</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(englishStrings).map((row, index) => {
                            let english = englishStrings[row];
                            let translation = this.currentTranslationRevision.strings[row];
                            let machineTranslation = this.currentMachineTranslation.strings[row];
                            let backgroundColor = index % 2 ? Colors.veryLightGrey : Colors.white;
                            return (
                                <TableRow key={row}>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        style={{ width: "50%", backgroundColor: backgroundColor }}
                                    >
                                        <Typography>{english}</Typography>
                                    </TableCell>

                                    <TableCell component="th" scope="row" style={{ backgroundColor: backgroundColor }}>
                                        <TextField
                                            onBlur={this.onTranslationFieldBlur}
                                            id={row}
                                            InputProps={{ disableUnderline: true }}
                                            multiline={true}
                                            fullWidth={true}
                                            defaultValue={translation}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{ backgroundColor: backgroundColor }}>
                                        {(translation === undefined || translation === "") && (
                                            <TextField
                                                onBlur={this.onNotesFieldBlur}
                                                id={row + "machine"}
                                                InputProps={{ disableUnderline: true }}
                                                multiline={true}
                                                fullWidth={true}
                                                defaultValue={machineTranslation}
                                            />
                                        )}
                                        {(translation === undefined || translation === "") && machineTranslation && (
                                            <Button
                                                variant="outlined"
                                                onClick={this.onUseMachineTranslationClicked.bind(
                                                    this,
                                                    row,
                                                    machineTranslation
                                                )}
                                            >
                                                Use
                                            </Button>
                                        )}
                                    </TableCell>

                                    <TableCell component="th" scope="row" style={{ backgroundColor: backgroundColor }}>
                                        {this.props.rootStore.userStore.user.isAdmin ? (
                                            <TextField
                                                onBlur={this.onNotesFieldBlur}
                                                id={row + "Notes"}
                                                InputProps={{ disableUnderline: true }}
                                                multiline={true}
                                                fullWidth={true}
                                                defaultValue={""}
                                            />
                                        ) : (
                                            <Typography>{""}</Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </>
        );
    }

    protected onUseMachineTranslationClicked = async (key: string, text: string) => {
        log.verbose(key);
        log.verbose(text);
        let element = window.document.getElementById(key);
        if (element) {
            (element as any).value = text;
        }

        this.onTranslationFieldBlur();
    };

    protected onNotesFieldBlur = async () => {
        let newStrings = this.getNewNotes();
        this.setCurrentNotes(newStrings);
    };

    protected onTranslationFieldBlur = async () => {
        let newStrings = this.getNewStrings();
        this.setCurrentStrings(newStrings);

        let wordCount = this.getWordCount();

        await TranslationsDB.updateStringsForKey(
            this.selectedLanguage,
            CollectionName.Strings,
            newStrings,
            wordCount.english,
            this.currentTranslationRevision.key
        );

        if (this.onlyShowChanged) {
            this.setEmptyAndChangedFields();
        }

        await this.getRevisions();

        let dictionary = new Map<string, string>();
        let englishStrings = this.currentEnglishRevision.strings;
        for (let key of Object.keys(newStrings)) {
            dictionary[englishStrings[key]] = newStrings[key];
        }
        this.updateDictionaryWithStrings(dictionary);
    };

    protected onCreateNewRevisionClicked = async () => {
        let newStrings = this.getNewStrings();
        let revision: TranslationRevision = {
            revisionNumber: this.currentTranslationRevision.revisionNumber + 1,
            date: new Date(),
            strings: newStrings,
            changedCount: 0,
            englishRevisionKey: this.currentEnglishRevision.key,
        };
        TranslationsDB.addRevision(this.selectedLanguage, CollectionName.Strings, revision);

        this.getRevisions();
    };

    protected printCurrentTranslation = () => {
        // console.log(this.currentTranslationRevision.strings);
        console.log(JSON.stringify(this.currentTranslationRevision.strings));
    };

    protected onUpdateCurrentEnglish = async () => {
        TranslationsDB.addEnglishRevision(CollectionName.Strings, this.languages[LanguageType.ENGLISH]);
    };

    private getRevisions = async () => {
        await this.getEnglishRevisions();
        await this.getTranslationRevisions();
        await this.getMachineTranslation();
    };

    private async getEnglishRevisions() {
        let englishTranslation = await TranslationsDB.getRevisions(LanguageType.ENGLISH, CollectionName.Strings);
        if (englishTranslation.length > 0) {
            this.setCurrentEnglish(englishTranslation[0]);
        } else {
            this.setCurrentEnglish({ strings: this.languages[LanguageType.ENGLISH] });
        }
    }

    private async getTranslationRevisions() {
        let targetLanguageTranslation = await TranslationsDB.getRevisions(
            this.selectedLanguage,
            CollectionName.Strings,
            2
        );
        if (targetLanguageTranslation.length > 0) {
            this.setCurrentTranslation(targetLanguageTranslation[0]);
            if (targetLanguageTranslation.length > 1) {
                this.lastTranslationRevision = targetLanguageTranslation[0];
            }
            if (this.currentTranslationRevision.englishRevisionKey) {
                this.lastEnglishRevision = await TranslationsDB.getRevisionForKey(
                    LanguageType.ENGLISH,
                    CollectionName.Strings,
                    this.currentTranslationRevision.englishRevisionKey
                );
                this.lastEnglishRevision = await TranslationsDB.getRevisionForKey(
                    LanguageType.ENGLISH,
                    CollectionName.Strings,
                    this.currentTranslationRevision.englishRevisionKey
                );
            }
        } else {
            let newRevision = {
                revisionNumber: 1,
                date: new Date(),
                englishRevisionKey: this.currentEnglishRevision.key,
                strings: {},
            };
            let result = await TranslationsDB.addRevision(
                this.selectedLanguage,
                CollectionName.Strings,
                newRevision as TranslationRevision
            );
            this.setCurrentTranslation(result);
        }
    }

    private async getMachineTranslation() {
        let targetMachineLanguageTranslation = await TranslationsDB.getRevisions(
            this.selectedLanguage,
            CollectionName.MachineTranslated,
            1
        );
        console.log(targetMachineLanguageTranslation[0]);
        if (targetMachineLanguageTranslation.length > 0) {
            this.setCurrentMachineTranslation(targetMachineLanguageTranslation[0]);
        }
    }

    private updateRevisionFromDictionary() {
        if (this.currentTranslationRevision) {
            let translationStrings = this.currentTranslationRevision.strings;
            if (this.allStringsDictionary) {
                let newStrings = this.updateStringsFromDictionary(translationStrings);
                this.setCurrentStrings(newStrings);
            }
        }
    }

    private getWordCount = () => {
        let changedWordCount = 0;
        let englishWordCount = 0;
        for (let key of Object.keys(this.currentTranslationRevision.strings)) {
            let currentString = this.currentTranslationRevision.strings[key];
            let didChange = true;
            if (
                currentString === "" ||
                (this.lastTranslationRevision && currentString === this.lastTranslationRevision.strings[key])
            ) {
                didChange = false;
            }

            if (didChange) {
                let currentSplit = currentString.split(" ");
                changedWordCount += currentSplit.length;

                let englishSplit = this.currentEnglishRevision.strings[key].split(" ");
                englishWordCount += englishSplit.length;
            }
        }

        return { english: englishWordCount, changed: changedWordCount };
    };

    private totalEnglishWordCount = () => {
        let englishWordCount = 0;
        for (let key of Object.keys(this.currentEnglishRevision.strings)) {
            let englishSplit = this.currentEnglishRevision.strings[key].split(" ");
            englishWordCount += englishSplit.length;
        }
        console.log("​Translations -> totalEnglishWordCount -> englishWordCount", englishWordCount);
        return englishWordCount;
    };

    @action
    private onOnlyShowChangedClicked = (event: any, value: any) => {
        if (value) {
            this.setEmptyAndChangedFields();
        }
        this.onlyShowChanged = value;
    };

    private setEmptyAndChangedFields() {
        let newList = {};
        for (let key of Object.keys(this.currentEnglishRevision.strings)) {
            let currentString = this.currentEnglishRevision.strings[key];
            if (
                !this.currentTranslationRevision.strings[key] ||
                !this.lastEnglishRevision ||
                !this.lastEnglishRevision.strings[key] ||
                currentString !== this.lastEnglishRevision.strings[key]
            ) {
                newList[key] = currentString;
            }
        }
        this.changedOrUntranslated = newList;
    }

    private getNewStrings() {
        let newStrings: Map<string, string> = Object.assign({}, this.currentTranslationRevision.strings);
        Object.keys(this.languages[LanguageType.ENGLISH]).map((id) => {
            let element = window.document.getElementById(id);
            if (element) {
                newStrings[id] = (element as any).value.trim();
            }
        });
        return newStrings;
    }

    private getNewNotes() {
        let newStrings: Map<string, string> = Object.assign({}, this.notes);
        Object.keys(this.languages[LanguageType.ENGLISH]).map((id) => {
            let element = window.document.getElementById(id + "Notes");
            if (element) {
                newStrings[id] = (element as any).value.trim();
            }
        });
        return newStrings;
    }
}
