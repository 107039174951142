import * as React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Row, Col } from "layout";

export const LazyLoader = (props: any) => (
    <React.Suspense
        fallback={
            <Col xs={12} style={{ height: "100%" }}>
                <Row style={{ height: "100%" }} center="xs" middle="xs">
                    <CircularProgress />
                </Row>
            </Col>
        }
    >
        {props.children}
    </React.Suspense>
);
