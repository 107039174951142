import { Scene } from "phaser";
import { Colors } from "core/utils/Colors";
import { degreesToRadians, getCenteredPosition, getPositionCenterInBoundries } from "core/utils/LayoutUtils";
import { Team } from "models";
import { Size } from "framer";
import KeyboardLayout, { KeyboardLayoutLanguage } from "components/common/KeyboardLayout";
import { Fonts } from "core/utils/Fonts";
import Phaser from "phaser";
import { strings } from "core/localizedStrings";
import Utils from "core/utils/Utils";
import { jsPDF } from "jspdf";
import { PrintableContainer } from "../worksheets/WorksheetHelper";
import { MD_PAGE } from "core/firebase/constants";

export const WORLD_WIDTH = 1800;
export const WORLD_HEIGHT = 1100;

export class PhaserHelper {
    public static get worldSize() {
        return {
            width: WORLD_WIDTH,
            height: WORLD_HEIGHT,
            centerX: WORLD_WIDTH / 2,
            centerY: WORLD_HEIGHT / 2,
        };
    }

    public static get worksheetSceneSize() {
        return {
            width: WORLD_WIDTH,
            height: WORLD_HEIGHT * 2,
            centerX: WORLD_WIDTH / 2,
            centerY: WORLD_HEIGHT,
        };
    }

    // public static get rexButtonPlugin() {
    //     return {
    //         key: "rexButton",
    //         plugin: ButtonPlugin,
    //         start: true,
    //     };
    // }

    public static scaleForSize(targetSize: Size, size: Size) {
        return Math.min(targetSize.width / size.width, targetSize.height / size.height);
    }

    public static containerStyleForWindowSize(
        windowSize: Size,
        worldWidth: number = PhaserHelper.worldSize.width,
        worldHeight: number = PhaserHelper.worldSize.height
    ) {
        let { width, height } = windowSize;

        let windowSizeWidthHeightRatio = width / height;
        let gameWidthHeightRatio = worldWidth / worldHeight;

        let widthRatio = windowSize.width / worldWidth;
        let heightRatio = windowSize.height / worldHeight;
        let phaserContainterStyle: any;
        if (widthRatio < heightRatio) {
            phaserContainterStyle = { width: width, height: width / gameWidthHeightRatio, margin: "0 auto" };
            if (windowSizeWidthHeightRatio > gameWidthHeightRatio) {
                phaserContainterStyle = { width: height * gameWidthHeightRatio, height: height };
            }
        } else {
            let height = width / gameWidthHeightRatio;
            if (height > windowSize.height) {
                height = windowSize.height;
                width = height * gameWidthHeightRatio;
            }
            phaserContainterStyle = { width: width, height: height, margin: "0 auto" };
            // phaserContainterStyle = { width: height * gameWidthHeightRatio, height: height, margin: "0 auto" };
        }

        return phaserContainterStyle;
    }

    public static worksheetContainerStylesForWindowSize(windowSize: Size) {
        if (windowSize.width > MD_PAGE) {
            let containerStyle = PhaserHelper.containerStyleForWindowSize(
                windowSize,
                PhaserHelper.worldSize.width,
                PhaserHelper.worldSize.height
            );
            let phaserStyle = PhaserHelper.containerStyleForWindowSize(
                containerStyle,
                PhaserHelper.worksheetSceneSize.width,
                PhaserHelper.worksheetSceneSize.height
            );
            containerStyle.width *= 2 / 3;
            delete phaserStyle.margin;
            return { container: containerStyle, phaser: phaserStyle };
        } else {
            let phaserStyle = PhaserHelper.containerStyleForWindowSize(
                windowSize,
                PhaserHelper.worksheetSceneSize.width,
                PhaserHelper.worksheetSceneSize.height
            );
            return { container: phaserStyle, phaser: phaserStyle };
        }
    }

    public static createPrimaryButton(
        scene: Scene,
        x: number,
        y: number,
        width: number,
        height: number,
        text: string,
        onClick: () => void
    ) {
        return PhaserHelper.createButton(scene, x, y, width, height, text, Colors.lightBlueHex, Colors.white, onClick);
    }

    public static createButton(
        scene: Scene,
        x: number,
        y: number,
        width: number,
        height: number,
        text: string,
        color: number,
        fontColor: string,
        onClick: () => void,
        onOver?: () => void,
        onOut?: () => void
    ) {
        var buttonContainer = scene.add.container();
        var shadow = scene.add.rectangle(x + 1, y + 2, width, height, 0x000000, 0.5) as any;
        var rectangle = scene.add.rectangle(x, y, width, height, color) as any;

        buttonContainer.add(shadow);
        buttonContainer.add(rectangle);

        var label = scene.add
            .text(x, y, text.toLocaleUpperCase(), {
                fontSize: "24pt",
                fontFamily: "Roboto",
                color: fontColor,
            })
            .setOrigin(0.5, 0.5);
        buttonContainer.add(label);

        var hitArea = new Phaser.GameObjects.Rectangle(scene, x, y, width, height, Colors.highlightYellowHex, 0)
            .setInteractive({ cursor: "pointer" })
            .on("pointerover", () => {
                shadow.visible = false;
                if (onOver) {
                    onOver();
                }
            })
            .on("pointerout", () => {
                shadow.visible = true;
                if (onOut) {
                    onOut();
                }
            })
            .on("pointerup", () => {
                onClick();
            });
        buttonContainer.add(hitArea);
        buttonContainer.setDepth(0);
        return buttonContainer;
    }

    public static roundedRectangle = (scene: Phaser.Scene, width: number, height: number, color: number) => {
        let graphics = new Phaser.GameObjects.Graphics(scene);
        graphics.fillStyle(color, 1);
        graphics.fillRoundedRect(width / -2, height / -2, width, height, height / 10);
        return graphics;
    };

    public static createTeamsPointsHolder(
        scene: Scene,
        x: number,
        y: number,
        teams: Team[],
        currentTeamIndex: number,
        pointsAreDollars: boolean = false
    ) {
        let CARD_WIDTH = 180;
        let CARD_HEIGHT = 130;
        let PADDING = 30;
        let group = scene.add.group();

        let totalWidth = teams.length * CARD_WIDTH + (teams.length - 1) * PADDING;
        let totalHeight = CARD_HEIGHT;
        teams.forEach((team, index) => {
            let position = getCenteredPosition(CARD_WIDTH, CARD_HEIGHT, index, teams.length, PADDING, 0);

            let pointsHolder = this.createPointsHolder(
                scene,
                position.x + x - totalWidth / 2,
                position.y + y - totalHeight / 2,
                CARD_WIDTH,
                CARD_HEIGHT,
                team.name,
                team.currentGamePoints,
                currentTeamIndex === index,
                pointsAreDollars
            );
            group.add(pointsHolder);
        });
        return group;
    }

    public static createPointsHolder(
        scene: Scene,
        x: number,
        y: number,
        width: number,
        height: number,
        teamName: string,
        points: number,
        isCurrentTeam: boolean,
        pointsAreDollars: boolean
    ) {
        var container = scene.add.container();
        var shadow = scene.add.rectangle(x + 4, y + 4, width, height, Colors.darkGreyHex) as any;
        shadow.setAlpha(0.7);
        container.add(shadow);

        var background = scene.add.rectangle(x, y, width, height, Colors.lightGreyHex) as any;
        container.add(background);

        var teamText = scene.add
            .text(x, y - 30, teamName, {
                color: Colors.black,
                fontSize: "36pt",
                fontFamily: "Roboto",
            })
            .setOrigin(0.5, 0.5);
        container.add(teamText);

        let text = points.toString();
        if (pointsAreDollars) {
            text = `$${text}`;
        }
        var pointsText = scene.add
            .text(x, y + 30, text, {
                color: Colors.black,
                fontSize: "40pt",
                fontFamily: "Roboto",
            })
            .setOrigin(0.5, 0.5);
        container.add(pointsText);

        if (!isCurrentTeam) {
            var highlight = scene.add.rectangle(x, y, width, height, Colors.lightGreyHex) as any;
            highlight.setAlpha(0.5);
            container.add(highlight);
        }

        return container;
    }

    public static createKeyboardContainer = (
        scene: Phaser.Scene,
        layout: KeyboardLayoutLanguage,
        shouldHideOnClick: boolean = false,
        onClickCallback: (letterClicked: string) => void = undefined,
        size: number = 65,
        isUpperCase: boolean = false
    ) => {
        let keyboardContainer = scene.add.container();
        let containerHeight = size * 4;
        let containerWidth = containerHeight * 2.5;
        let index = 0;
        layout.default.map((row, rowIndex) => {
            row.split(" ").map((char, charIndex) => {
                if (isUpperCase) {
                    char = char.toUpperCase();
                }
                let position = getPositionCenterInBoundries(
                    size,
                    size,
                    index,
                    containerWidth,
                    containerHeight,
                    KeyboardLayout.totalChars(layout.default),
                    KeyboardLayout.longestRow(layout.default)
                );
                let text = new Phaser.GameObjects.Text(scene, position.x, position.y, char, {
                    fontFamily: Fonts.BalsamiqSans,
                    fontSize: size + "px",
                }).setOrigin(0.5, 0.5);

                let hitArea = new Phaser.GameObjects.Rectangle(scene, position.x, position.y, size, size, 0xffffff, 0)
                    .setInteractive({ cursor: "pointer" })
                    .on("pointerup", () => {
                        if (shouldHideOnClick) {
                            text.setVisible(false);
                            hitArea.setVisible(false);
                        }
                        if (onClickCallback) {
                            onClickCallback(char);
                        }
                    });
                keyboardContainer.add(hitArea);
                keyboardContainer.add(text);
                index += 1;
            });
        });
        return keyboardContainer;
    };

    public static createInfoMessage = (scene: Phaser.Scene, text: string, onClick: () => void) => {
        let container = scene.add.container();
        let backgroundHeight = PhaserHelper.worldSize.height * 0.25;
        let backgroundWidth = backgroundHeight * 3;
        let background = scene.add.rectangle(0, 0, backgroundWidth, backgroundHeight, 0xffffff, 0.95);
        container.add(background);

        let messageText = new Phaser.GameObjects.Text(scene, 0, backgroundHeight * -0.2, text, {
            color: Colors.black,
            fontSize: backgroundHeight * 0.12 + "px",
            fontFamily: Fonts.Roboto,
            wordWrap: { width: backgroundWidth * 0.8, useAdvancedWrap: true },
        });
        messageText.setOrigin(0.5, 0.5);
        messageText.setAlign("center");
        container.add(messageText);

        let button = PhaserHelper.createButton(
            scene,
            0,
            (backgroundHeight / 2) * 0.5,
            backgroundWidth * 0.3,
            backgroundHeight * 0.2,
            strings.ok,
            Colors.lightBlueHex,
            Colors.white,
            onClick
        );
        container.add(button);

        return container;
    };

    public static createConfirmationMessage = (
        scene: Phaser.Scene,
        text: string,
        options: string[],
        onClick: (result: string) => void
    ) => {
        let container = scene.add.container();
        let backgroundHeight = PhaserHelper.worldSize.height * 0.25;
        let backgroundWidth = backgroundHeight * 3;
        let background = scene.add.rectangle(0, 0, backgroundWidth, backgroundHeight, 0xffffff, 0.95);
        container.add(background);

        let messageText = new Phaser.GameObjects.Text(scene, 0, backgroundHeight * -0.2, text, {
            color: Colors.black,
            fontSize: backgroundHeight * 0.12 + "px",
            fontFamily: Fonts.Roboto,
            wordWrap: { width: backgroundWidth * 0.8, useAdvancedWrap: true },
        });
        messageText.setOrigin(0.5, 0.5);
        messageText.setAlign("center");
        container.add(messageText);

        let button1 = PhaserHelper.createButton(
            scene,
            backgroundWidth * -0.2,
            (backgroundHeight / 2) * 0.5,
            backgroundWidth * 0.3,
            backgroundHeight * 0.2,
            options[0].toUpperCase(),
            Colors.lightBlueHex,
            Colors.white,
            () => {
                onClick(options[0]);
            }
        );
        container.add(button1);

        let button2 = PhaserHelper.createButton(
            scene,
            backgroundWidth * 0.2,
            (backgroundHeight / 2) * 0.5,
            backgroundWidth * 0.3,
            backgroundHeight * 0.2,
            options[1],
            Colors.lightBlueHex,
            Colors.white,
            () => {
                onClick(options[1]);
            }
        );
        container.add(button2);

        return container;
    };

    public static createWheel = (
        scene: Phaser.Scene,
        x: number,
        y: number,
        radius: number,
        sections: Phaser.GameObjects.GameObject[],
        sectionColors?: string[],
        rotateSections: boolean = true,
        onSectionClicked: (index: number) => void = undefined
    ) => {
        var thisArc = 360 / sections.length;
        let startAngle = -90;
        let container = scene.add.container();
        let sectionColorsCount = 0;

        // Create wheel
        for (var i = 0; i < sections.length; i++) {
            var endAngle = startAngle + thisArc;
            var startRadians = degreesToRadians(startAngle);
            var endRadians = degreesToRadians(endAngle);

            var graphics = scene.add.graphics();
            graphics.lineStyle(2, 0xffffff, 1);
            if (sectionColors !== undefined) {
                graphics.fillStyle(Utils.hexStringToInt(sectionColors[sectionColorsCount]), 1);
            }

            //  Without this the arc will appear closed when stroked
            graphics.beginPath();
            graphics.arc(x, y, radius, startRadians, endRadians, false);
            graphics.lineTo(x, y);
            graphics.closePath();
            graphics.fill();
            graphics.strokePath();
            container.add(graphics);

            let sectionAngle = startAngle + thisArc / 2;
            let sectionsAngleRadians = degreesToRadians(sectionAngle);
            let xPos = x + (radius / 1.8) * Math.cos(sectionsAngleRadians);
            let yPos = y + (radius / 1.8) * Math.sin(sectionsAngleRadians);

            var thisSection: any = sections[i];
            thisSection.x = xPos;
            thisSection.y = yPos;
            if (rotateSections) {
                thisSection.setRotation(degreesToRadians(sectionAngle));
            }
            container.add(thisSection);

            // if (onSectionClicked) {
            //     var hitAreaGraphics = scene.add.graphics();
            //     hitAreaGraphics.fillStyle(Utils.hexStringToInt(Colors.white), 1);
            //     hitAreaGraphics.beginPath();
            //     hitAreaGraphics.arc(x, y, radius, startRadians, endRadians, false);
            //     hitAreaGraphics.lineTo(x, y);
            //     hitAreaGraphics.closePath();
            //     hitAreaGraphics.fill();
            //     hitAreaGraphics.strokePath();

            //     container.add(hitAreaGraphics);
            //     hitAreaGraphics.setInteractive({ cursor: "pointer" }).on("pointerup", () => {
            //         onSectionClicked(i);
            //     });
            // }

            startAngle += thisArc;

            if (sectionColors) {
                sectionColorsCount += 1;
                if (sectionColorsCount === sectionColors.length) {
                    sectionColorsCount = 0;
                }
            }
        }
        return container;
    };

    public static containersToPDF = async (
        scene: Phaser.Scene,
        containers: PrintableContainer[],
        fileName: string,
        widthHeightRatio: number
    ) => {
        let snapshots: any[] = [];
        for (let container of containers) {
            let image = await PhaserHelper.containerToSnapshot(scene, container);
            snapshots.push(image);
        }
        PhaserHelper.snapshotsToPDF(snapshots, fileName, widthHeightRatio);
    };

    public static snapshotsToPDF = (snapshots: any[], fileName: string, widthHeightRatio: number) => {
        const pdf = new jsPDF("p", "pt", "a4", true);
        pdf.internal.scaleFactor = 30;
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdfWidth / widthHeightRatio;
        snapshots.forEach((snapshot, index) => {
            pdf.addImage(snapshot, "PNG", 0, 0, pdfWidth, pdfHeight, "", "FAST");
            if (index !== snapshots.length - 1) {
                pdf.addPage();
            }
        });
        pdf.save(`${fileName}.pdf`);
    };

    public static containerToSnapshot = (scene: Phaser.Scene, container: Phaser.GameObjects.Container) => {
        return new Promise<any>((resolve) => {
            let backgroundBounds = undefined;
            container.setRotation(0);
            scene.children.bringToTop(container);
            container.list.forEach((child: any) => {
                if (child.name === "background") {
                    backgroundBounds = child.getBounds();
                    child.setStrokeStyle(0, 0xffffff, 0);
                }
            });
            scene.game.renderer.snapshotArea(
                container.x - backgroundBounds.width / 2,
                container.y - backgroundBounds.height / 2,
                backgroundBounds.width,
                backgroundBounds.height,
                (image) => {
                    let src = (image as any).src;
                    resolve(src);
                }
            );
        });
    };

    public static optionsChanged = (localOptions: any, activityStoreOptions: any) => {
        let changedOptions = [];
        for (const [key, localOption] of Object.entries(localOptions)) {
            if (localOption !== activityStoreOptions[key]) {
                changedOptions.push(key);
            }
        }
        return changedOptions;
    };
}
