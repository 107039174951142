export class PhaserImages {
    public static DESK_AND_CHALKBOARD_BACKGROUND = "deskAndChalkboardBackground";
    public static CHALKBOARD_BACKGROUND = "chalkboardBackground";
    public static CARD_FRONT_BACKGROUND = "cardFrontBackground";
    public static CARD_BACK_BACKGROUND = "cardBackBackground";
    public static NEXT_BUTTON = "nextButton";
    public static FLIP_ICON = "flipIcon";
    public static AUDIO_ICON = "audioIcon";
    public static ANSWER_BACKGROUND = "answerBackground";
    public static TIMER_BACKGROUND = "timerBackground";

    // Common
    public static STAR = "star";
    public static BOMB_SPARK = "bombSpark";
    public static RED_X = "redX";
    public static GREEN_CHECK = "greenCheck";

    // Crazy Cups
    public static CRAZY_CUPS_BALL = "cracyCupsBall";
    public static CRAZY_CUPS_CUP = "cracyCupsCup";
    public static CRAZY_CUPS_ARROWS = "cracyCupsArrows";

    // Battleship
    public static BATTLESHIP_CRUISER = "cruiser";
    public static BATTLESHIP_CRUISER_EXPLOSIONS = "cruiserExplosions";
    public static BATTLESHIP_DESTROYER = "destroyer";
    public static BATTLESHIP_DESTROYER_EXPLOSIONS = "destroyerExplosions";
    public static BATTLESHIP_CARRIER = "carrier";
    public static BATTLESHIP_CARRIER_EXPLOSIONS = "carrierExplosions";
    public static BATTLESHIP_WATER_BACKGROUND = "waterBackground";
    public static BATTLESHIP_WATER_WAVES = "waterWaves";
    public static BATTLESHIP_BOMB = "bomb";

    // The Wheel
    public static WHEEL_EMPTY_BLANK = "wheelEmptyBlank";
    public static WHEEL_BLANK = "wheelBlank";
    public static WHEEL_SPIN_BUTTON = "wheelSpinButton";
    public static WHEEL_SOLVE_BUTTON = "wheelSolveButton";

    // Shark Bait
    public static SHARK_BAIT_BACKGROUND = "sharkBaitBackground";
    public static SHARK_BAIT_SHARK = "sharkBaitShark";
    public static SHARK_BAIT_SHARK_OPEN_MOUTH = "sharkBaitSharkOpenMouth";
    public static SHARK_BAIT_WATER1 = "sharkBaitWater1";
    public static SHARK_BAIT_WATER2 = "sharkBaitWater2";
    public static SHARK_BAIT_WATER3 = "sharkBaitWater3";
    public static SHARK_BAIT_WATER4 = "sharkBaitWater4";
    public static SHARK_BAIT_WATER5 = "sharkBaitWater5";
    public static SHARK_BAIT_WATER6 = "sharkBaitWater6";
    public static SHARK_BAIT_WATER7 = "sharkBaitWater7";
    public static SHARK_BAIT_BLUE_FISH = "blueFish";
    public static SHARK_BAIT_RED_FISH = "redFish";
    public static SHARK_BAIT_YELLOW_FISH = "yellowFish";
    public static SHARK_BAIT_ORANGE_FISH = "orangeFish";
    public static SHARK_BAIT_GREEN_FISH = "greenFish";
    public static SHARK_BAIT_BLUE_BALLOON = "blueBalloon";
    public static SHARK_BAIT_RED_BALLOON = "redBalloon";
    public static SHARK_BAIT_YELLOW_BALLOON = "yellowBalloon";
    public static SHARK_BAIT_PURPLE_BALLOON = "purpleBalloon";
    public static SHARK_BAIT_GREEN_BALLOON = "greenBalloon";
    public static SHARK_BAIT_BLUE_BALLOON_POPPED = "bluePoppedBalloon";
    public static SHARK_BAIT_RED_BALLOON_POPPED = "redPoppedBalloon";
    public static SHARK_BAIT_YELLOW_BALLOON_POPPED = "yellowPoppedBalloon";
    public static SHARK_BAIT_PURPLE_BALLOON_POPPED = "purplePoppedBalloon";
    public static SHARK_BAIT_GREEN_BALLOON_POPPED = "greenPoppedBalloon";
    public static SHARK_BAIT_UP_ARROW = "upArrow";
    public static SHARK_BAIT_LARGE_TARGET = "largeTarget";
    public static SHARK_BAIT_MEDIUM_TARGET = "mediumTarget";
    public static SHARK_BAIT_SMALL_TARGET = "smallTarget";
    public static SHARK_BAIT_EXTRA_SMALL_TARGET = "smallestTarget";

    // Bingo Caller
    public static RED_BINGO_BALL = "redBingoBall";
    public static BLUE_BINGO_BALL = "blueBingoBall";
    public static YELLOW_BINGO_BALL = "yellowBingoBall";
    public static GREEN_BINGO_BALL = "greenBingoBall";
    public static BINGO_BUBBLE = "bingoBubble";

    // Random Name Picker
    public static NAME_PICKER_BACKGROUND = "randomNamePickerBackground";
    public static NAME_PICKER_FOREGROUND = "randomNamePickerForeground";
    public static SPIN_BUTTON = "spinButton";

    // Dartboard Selector
    public static BLUE_DART = "blueDart";
    public static YELLOW_DART = "yellowDart";
    public static RED_DART = "redDart";
    public static BLUE_THROWN_DART = "blueDartThrown";
    public static YELLOW_THROWN_DART = "yellowDartThrown";
    public static RED_THROWN_DART = "redDartThrown";
    public static CORK_BOARD_BACKGROUND = "corkBoardBackground";

    // Worksheets
    public static WRITING_LINES = "writingLines";
    public static RACETRACK_BACKGROUND = "racetrackBackground";
    public static LADDERS_AND_SLIDES_BACKGROUND = "laddersSlidesBackground";

    public static load(scene: Phaser.Scene, images: string[]) {
        for (let image of images) {
            let path = this.pathForImage(image);
            scene.load.image(image, path);
        }
    }

    public static pathForImage(image: string) {
        switch (image) {
            case PhaserImages.STAR:
                return require("../../../assets/images/games/angryWords/star.png");
            case PhaserImages.BOMB_SPARK:
                return require("../../../assets/images/games/wordScramble/spark.svg");
            case PhaserImages.RED_X:
                return require("../../../assets/images/games/speed/redX.svg");
            case PhaserImages.GREEN_CHECK:
                return require("../../../assets/images/games/speed/greenCheck.svg");
            case PhaserImages.TIMER_BACKGROUND:
                return require("../../../assets/images/activities/timerBackground.svg");

            case PhaserImages.DESK_AND_CHALKBOARD_BACKGROUND:
                return require("../../../assets/images/games/deskAndChalkboardTransparent.png");
            case PhaserImages.CHALKBOARD_BACKGROUND:
                return require("../../../assets/images/games/chalkboard.png");
            case PhaserImages.CARD_FRONT_BACKGROUND:
                return require("../../../assets/images/activities/card.svg");
            case PhaserImages.CARD_BACK_BACKGROUND:
                return require("../../../assets/images/activities/cardBack.svg");
            case PhaserImages.ANSWER_BACKGROUND:
                return require("../../../assets/images/activities/answerBackground.svg");
            case PhaserImages.NEXT_BUTTON:
                return require("../../../assets/images/activities/nextButton.svg");
            case PhaserImages.AUDIO_ICON:
                return require("../../../assets/images/activities/audioIcon.svg");
            case PhaserImages.FLIP_ICON:
                return require("../../../assets/images/activities/flip.svg");

            case PhaserImages.CRAZY_CUPS_BALL:
                return require("../../../assets/images/games/crazyCups/ball.svg");
            case PhaserImages.CRAZY_CUPS_CUP:
                return require("../../../assets/images/games/crazyCups/cup.svg");
            case PhaserImages.CRAZY_CUPS_ARROWS:
                return require("../../../assets/images/games/crazyCups/arrows.svg");

            case PhaserImages.BATTLESHIP_CARRIER:
                return require("../../../assets/images/games/battleship/carrier.svg");
            case PhaserImages.BATTLESHIP_CARRIER_EXPLOSIONS:
                return require("../../../assets/images/games/battleship/carrierExplosions.svg");
            case PhaserImages.BATTLESHIP_CRUISER:
                return require("../../../assets/images/games/battleship/cruiser.svg");
            case PhaserImages.BATTLESHIP_CRUISER_EXPLOSIONS:
                return require("../../../assets/images/games/battleship/cruiserExplosions.svg");
            case PhaserImages.BATTLESHIP_DESTROYER:
                return require("../../../assets/images/games/battleship/destroyer.svg");
            case PhaserImages.BATTLESHIP_DESTROYER_EXPLOSIONS:
                return require("../../../assets/images/games/battleship/destroyerExplosions.svg");
            case PhaserImages.BATTLESHIP_WATER_BACKGROUND:
                return require("../../../assets/images/games/battleship/waterBackground.svg");
            case PhaserImages.BATTLESHIP_WATER_WAVES:
                return require("../../../assets/images/games/battleship/waterWaves.svg");
            case PhaserImages.BATTLESHIP_BOMB:
                return require("../../../assets/images/games/battleship/bomb.svg");

            case PhaserImages.WHEEL_BLANK:
                return require("../../../assets/images/games/theWheel/blank.svg");
            case PhaserImages.WHEEL_EMPTY_BLANK:
                return require("../../../assets/images/games/theWheel/emptyBlank.svg");
            case PhaserImages.WHEEL_SPIN_BUTTON:
                return require("../../../assets/images/games/theWheel/spinButton.png");
            case PhaserImages.WHEEL_SOLVE_BUTTON:
                return require("../../../assets/images/games/theWheel/solveButton.png");

            case PhaserImages.RED_BINGO_BALL:
                return require("../../../assets/images/games/bingoCaller/redBingoBall.png");
            case PhaserImages.BLUE_BINGO_BALL:
                return require("../../../assets/images/games/bingoCaller/blueBingoBall.png");
            case PhaserImages.GREEN_BINGO_BALL:
                return require("../../../assets/images/games/bingoCaller/greenBingoBall.png");
            case PhaserImages.YELLOW_BINGO_BALL:
                return require("../../../assets/images/games/bingoCaller/yellowBingoBall.png");
            case PhaserImages.BINGO_BUBBLE:
                return require("../../../assets/images/games/bingoCaller/bingoBubble.png");

            case PhaserImages.SHARK_BAIT_BACKGROUND:
                return require("../../../assets/images/games/sharkBait/background.svg");
            case PhaserImages.SHARK_BAIT_SHARK:
                return require("../../../assets/images/games/sharkBait/shark.svg");
            case PhaserImages.SHARK_BAIT_WATER1:
                return require("../../../assets/images/games/sharkBait/water1.svg");
            case PhaserImages.SHARK_BAIT_WATER2:
                return require("../../../assets/images/games/sharkBait/water2.svg");
            case PhaserImages.SHARK_BAIT_WATER3:
                return require("../../../assets/images/games/sharkBait/water3.svg");
            case PhaserImages.SHARK_BAIT_WATER4:
                return require("../../../assets/images/games/sharkBait/water4.svg");
            case PhaserImages.SHARK_BAIT_WATER5:
                return require("../../../assets/images/games/sharkBait/water5.svg");
            case PhaserImages.SHARK_BAIT_WATER6:
                return require("../../../assets/images/games/sharkBait/water6.svg");
            case PhaserImages.SHARK_BAIT_WATER7:
                return require("../../../assets/images/games/sharkBait/water7.svg");
            case PhaserImages.SHARK_BAIT_SHARK_OPEN_MOUTH:
                return require("../../../assets/images/games/sharkBait/sharkOpenMouth.svg");
            case PhaserImages.SHARK_BAIT_BLUE_FISH:
                return require("../../../assets/images/games/sharkBait/blueFish.svg");
            case PhaserImages.SHARK_BAIT_RED_FISH:
                return require("../../../assets/images/games/sharkBait/redFish.svg");
            case PhaserImages.SHARK_BAIT_YELLOW_FISH:
                return require("../../../assets/images/games/sharkBait/yellowFish.svg");
            case PhaserImages.SHARK_BAIT_ORANGE_FISH:
                return require("../../../assets/images/games/sharkBait/orangeFish.svg");
            case PhaserImages.SHARK_BAIT_GREEN_FISH:
                return require("../../../assets/images/games/sharkBait/greenFish.svg");
            case PhaserImages.SHARK_BAIT_BLUE_BALLOON:
                return require("../../../assets/images/games/sharkBait/blueBalloon.svg");
            case PhaserImages.SHARK_BAIT_BLUE_BALLOON_POPPED:
                return require("../../../assets/images/games/sharkBait/bluePoppedBalloon.svg");
            case PhaserImages.SHARK_BAIT_RED_BALLOON:
                return require("../../../assets/images/games/sharkBait/redBalloon.svg");
            case PhaserImages.SHARK_BAIT_RED_BALLOON_POPPED:
                return require("../../../assets/images/games/sharkBait/redPoppedBalloon.svg");
            case PhaserImages.SHARK_BAIT_YELLOW_BALLOON:
                return require("../../../assets/images/games/sharkBait/yellowBalloon.svg");
            case PhaserImages.SHARK_BAIT_YELLOW_BALLOON_POPPED:
                return require("../../../assets/images/games/sharkBait/yellowPoppedBalloon.svg");
            case PhaserImages.SHARK_BAIT_PURPLE_BALLOON:
                return require("../../../assets/images/games/sharkBait/purpleBalloon.svg");
            case PhaserImages.SHARK_BAIT_PURPLE_BALLOON_POPPED:
                return require("../../../assets/images/games/sharkBait/purplePoppedBalloon.svg");
            case PhaserImages.SHARK_BAIT_GREEN_BALLOON:
                return require("../../../assets/images/games/sharkBait/greenBalloon.svg");
            case PhaserImages.SHARK_BAIT_GREEN_BALLOON_POPPED:
                return require("../../../assets/images/games/sharkBait/greenPoppedBalloon.svg");
            case PhaserImages.SHARK_BAIT_UP_ARROW:
                return require("../../../assets/images/games/sharkBait/upArrow.svg");
            case PhaserImages.SHARK_BAIT_LARGE_TARGET:
                return require("../../../assets/images/games/sharkBait/largeTarget.svg");
            case PhaserImages.SHARK_BAIT_MEDIUM_TARGET:
                return require("../../../assets/images/games/sharkBait/mediumTarget.svg");
            case PhaserImages.SHARK_BAIT_SMALL_TARGET:
                return require("../../../assets/images/games/sharkBait/smallTarget.svg");
            case PhaserImages.SHARK_BAIT_EXTRA_SMALL_TARGET:
                return require("../../../assets/images/games/sharkBait/smallestTarget.svg");

            case PhaserImages.NAME_PICKER_FOREGROUND:
                return require("../../../assets/images/tools/randomNamePicker/randomNamePickerForeground.svg");
            case PhaserImages.NAME_PICKER_BACKGROUND:
                return require("../../../assets/images/tools/randomNamePicker/randomNamePickerBackground.svg");
            case PhaserImages.SPIN_BUTTON:
                return require("../../../assets/images/tools/randomNamePicker/spinButton.svg");

            case PhaserImages.BLUE_DART:
                return require("../../../assets/images/tools/dartboardSelector/blueDart.svg");
            case PhaserImages.BLUE_THROWN_DART:
                return require("../../../assets/images/tools/dartboardSelector/blueDartThrown.svg");
            case PhaserImages.YELLOW_DART:
                return require("../../../assets/images/tools/dartboardSelector/yellowDart.svg");
            case PhaserImages.YELLOW_THROWN_DART:
                return require("../../../assets/images/tools/dartboardSelector/yellowDartThrown.svg");
            case PhaserImages.RED_DART:
                return require("../../../assets/images/tools/dartboardSelector/redDart.svg");
            case PhaserImages.RED_THROWN_DART:
                return require("../../../assets/images/tools/dartboardSelector/redDartThrown.svg");
            case PhaserImages.CORK_BOARD_BACKGROUND:
                return require("../../../assets/images/tools/dartboardSelector/corkBoardBackground.jpg");

            case PhaserImages.WRITING_LINES:
                return require("../../../assets/images/worksheets/writingLines.svg");
            case PhaserImages.RACETRACK_BACKGROUND:
                return require("../../../assets/images/worksheets/racetrack/racetrack.png");
            case PhaserImages.LADDERS_AND_SLIDES_BACKGROUND:
                return require("../../../assets/images/worksheets/laddersSlides/laddersSlides.png");
        }
    }
}
