import { log } from "../../";

import * as React from "react";
import { DragSource } from "react-dnd";

interface DraggableFolderItemProps extends React.Props<any> {
    childObject: any;
    onItemDropped: (source: any, target: any) => void;
    connectDragSource?: (source: any) => any;
    isDragging?: boolean;
    didDrop?: boolean;
}

class DraggableFolderItem extends React.Component<DraggableFolderItemProps> {
    render() {
        const opacity = this.props.isDragging ? 0.3 : 1;
        return this.props.connectDragSource(<div style={{ opacity: opacity }}>{this.props.childObject}</div>);
    }
}

const cardSource = {
    beginDrag(props: DraggableFolderItemProps) {
        log.verbose("Begin drag");
        return {
            childObject: props.childObject
        };
    }
};

function collect(connect: any, monitor: any) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    };
}

var folderItem = DragSource("draggableFolderItem", cardSource, collect)(DraggableFolderItem);
export default folderItem;
