import * as React from "react";

// Material UI
import Button from "@material-ui/core/Button";

// Stores
import { RootStore } from "stores";

// Models
import { ContentAccess } from "models";

// Layout
import { Col, Row } from "layout";

// Utils
import { strings } from "core/localizedStrings";
import Typography from "@material-ui/core/Typography";
import { FirebaseFunctions } from "core/firebase";
import { MY_ACCOUNT_PATH } from "../constants";

export interface UseCreditFormProps extends React.Props<any> {
    rootStore: RootStore;
}

export const UseCreditForm = (props: UseCreditFormProps) => {
    let { rootStore } = props;
    let { userStore } = rootStore;

    let message: string | React.ReactText[];
    let button: any;
    let isUsingCreditLoading: boolean = false;

    const onUseCreditClicked = async () => {
        if (!isUsingCreditLoading) {
            isUsingCreditLoading = true;
            let success = await FirebaseFunctions.useCredit(
                rootStore.userStore.user,
                rootStore.activityStore.currentActivity.activityType
            );
            isUsingCreditLoading = false;

            if (success) {
                rootStore.authStore.setShouldShowUseCreditDialog(false);
            } else {
                rootStore.showErrorMessage(strings.problemUsingCredit);
            }
        }
    };

    const onUpgradeClicked = () => {
        rootStore.setRoute(MY_ACCOUNT_PATH);
        rootStore.authStore.setShouldShowUseCreditDialog(false);
    };

    if (userStore.contentAccess === ContentAccess.Credits) {
        if (userStore.user.remainingCredits === 0) {
            message = strings.noRemainingCredits;
            button = undefined;
        } else {
            message =
                userStore.user.remainingCredits === 1
                    ? (message = strings.oneRemainingCredit)
                    : strings.formatString(strings.remainingCreditsFormat, userStore.user.remainingCredits);
            button = (
                <Button variant="contained" color="primary" onClick={onUseCreditClicked} style={{ marginRight: 5 }}>
                    {strings.useCredit}
                </Button>
            );
        }
    }
    return (
        <Col xs={true}>
            <div style={{ padding: 20 }}>
                <Row center="xs">
                    <Typography variant="body1">&nbsp;{message}</Typography>
                </Row>
                <Row center="xs">
                    {button}
                    <Button variant="contained" color="primary" onClick={onUpgradeClicked}>
                        {strings.upgradeToPremium}
                    </Button>
                </Row>
            </div>
        </Col>
    );
};
