import { observable } from "mobx";

export default class WindowSize {
    @observable width: number;
    @observable height: number;
    constructor(width: number, height: number) {
        this.width = width;
        this.height = height;
    }
}
