import { TabSelection, SortByType, CategoryType, LanguageType } from "models";

export const ITEM_PAGE_SIZE = 40;

export default class FirestoreQueryBuilder {
    static createQuery(
        languageFilter: LanguageType,
        listTypeFilter: CategoryType,
        sortByType: SortByType,
        currentFolderKey: String,
        uid: String,
        currentTab: TabSelection,
        queryCursor: any,
        firestoreRef: firebase.firestore.CollectionReference
    ): firebase.firestore.Query {
        let query: firebase.firestore.Query;

        switch (currentTab) {
            case TabSelection.PUBLIC:
                query = firestoreRef.where("public", "==", true).where("deleted", "==", false);
                break;

            case TabSelection.FAVORITES:
                query = firestoreRef.where("favoritedByFolders." + currentFolderKey, ">", "");
                break;

            case TabSelection.CLASS_EDITOR:
                query = firestoreRef.where("parentKey", "==", currentFolderKey);
                break;

            case TabSelection.SITE:
                query = firestoreRef
                    .where("uid", "==", "site")
                    .where("parentKey", "==", currentFolderKey)
                    .where("deleted", "==", false);
                break;

            case TabSelection.MY_LISTS:
            case TabSelection.MY_IMAGES:
                query = firestoreRef
                    .where("uid", "==", uid)
                    .where("parentKey", "==", currentFolderKey)
                    .where("deleted", "==", false);
                break;

            default:
                query = firestoreRef.where("parentKey", "==", currentFolderKey).where("deleted", "==", false);
                break;
        }

        if (query && currentTab !== TabSelection.FAVORITES) {
            // Language Filter
            if (languageFilter !== undefined && query) {
                query = query.where("language", "==", languageFilter).where("deleted", "==", false);
            }

            // List type filter
            if (listTypeFilter !== undefined && query) {
                query = query.where("type", "==", listTypeFilter).where("deleted", "==", false);
            }

            if (
                currentTab === TabSelection.SITE ||
                currentTab === TabSelection.MY_IMAGES ||
                currentTab === TabSelection.SITE_IMAGES ||
                currentTab === TabSelection.CLASS_EDITOR
            ) {
                query = query.orderBy(SortByType.Title);
            } else {
                switch (sortByType) {
                    case SortByType.RecentlyCreated:
                        query = query.orderBy(sortByType, "desc");
                        break;
                    case SortByType.Popularity:
                        query = query.orderBy(sortByType, "desc").orderBy(SortByType.Title, "asc");
                        break;
                    case SortByType.Title:
                        query = query.orderBy(sortByType, "asc");
                        break;
                    default:
                        break;
                }
            }

            // Page size
            query = query.limit(ITEM_PAGE_SIZE);

            // Query cursor
            if (queryCursor) {
                query = query.startAfter(queryCursor);
            }
        }
        return query;
    }
}
