import LocalizedStrings from "react-localization";
import { english } from "./english";
import { korean } from "./korean";
import { french } from "./french";
import { chinese } from "./chinese";
import { spanish } from "./spanish";
import { german } from "./german";
import { russian } from "./russian";
import { greek } from "./greek";
import { japanese } from "./japanese";
import { portuguese } from "./portuguese";
import { dutch } from "./dutch";

export const strings = new LocalizedStrings({
    english: english,
    korean: korean,
    french: french,
    chinese: chinese,
    spanish: spanish,
    german: german,
    greek: greek,
    russian: russian,
    japanese: japanese,
    dutch: dutch,
    portuguese: portuguese
});
