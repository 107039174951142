import * as CustomLists from "../models/customLists";
import { Firestore } from "../core/firebase";
import { FirestoreCollection } from "core/firebase/Firestore";

export class CustomListsRefFactory {
    private siteFoldersRef: FirestoreCollection;
    private siteImagesFolderRef: FirestoreCollection;

    private memberFoldersRef: FirestoreCollection;
    private favoritesFoldersRef: FirestoreCollection;
    private memberImagesFolderRef: FirestoreCollection;

    private classListsFolderCollection: FirestoreCollection;
    private classListsCollection: FirestoreCollection;

    constructor() {
        // Site Data
        let siteDoc = Firestore.usersCollection().doc("site");
        this.siteFoldersRef = siteDoc.collection("categoryFolders");
        this.siteImagesFolderRef = siteDoc.collection("categoryImageFolders");
    }

    set uid(value: string) {
        // Member Data
        let userDoc = Firestore.usersCollection().doc(value);
        this.memberImagesFolderRef = userDoc.collection("categoryImageFolders");
        this.memberFoldersRef = userDoc.collection("categoryFolders");
        this.favoritesFoldersRef = userDoc.collection("favoritesFolders");
        this.classListsFolderCollection = userDoc.collection("studentListFolders");
        this.classListsCollection = userDoc.collection("studentLists");
    }

    // Firestore DB
    // ----------------------------------
    public foldersRefForTab(tab: CustomLists.TabSelection): firebase.firestore.CollectionReference {
        switch (tab) {
            case CustomLists.TabSelection.SITE:
                return this.siteFoldersRef as firebase.firestore.CollectionReference;

            case CustomLists.TabSelection.MY_LISTS:
                return this.memberFoldersRef as firebase.firestore.CollectionReference;

            case CustomLists.TabSelection.FAVORITES:
                return this.favoritesFoldersRef as firebase.firestore.CollectionReference;

            case CustomLists.TabSelection.SITE_IMAGES:
                return this.siteImagesFolderRef as firebase.firestore.CollectionReference;

            case CustomLists.TabSelection.MY_IMAGES:
                return this.memberImagesFolderRef as firebase.firestore.CollectionReference;

            case CustomLists.TabSelection.CLASS_EDITOR:
                return this.classListsFolderCollection as firebase.firestore.CollectionReference;

            default:
                return undefined;
        }
    }

    public itemRefForTab(tab: CustomLists.TabSelection): firebase.firestore.CollectionReference {
        switch (tab) {
            case CustomLists.TabSelection.SITE:
            case CustomLists.TabSelection.PUBLIC:
            case CustomLists.TabSelection.MY_LISTS:
            case CustomLists.TabSelection.FAVORITES:
                return Firestore.categoriesCollection() as firebase.firestore.CollectionReference;

            case CustomLists.TabSelection.MY_IMAGES:
            case CustomLists.TabSelection.SITE_IMAGES:
                return Firestore.categoryImagesRef() as firebase.firestore.CollectionReference;

            case CustomLists.TabSelection.CLASS_EDITOR:
                return this.classListsCollection as firebase.firestore.CollectionReference;

            default:
                return undefined;
        }
    }
}
