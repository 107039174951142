import { DOWNLOAD_URL_PREFIX, IMAGES_STORAGE_PATH } from "../../core/firebase/constants";

export interface UsedByCategoriesDictionary {
    [key: string]: boolean;
}

export default class CategoryImage {
    key: string;
    uid: string;
    image: string;
    thumbnail: string;
    title: string;
    parentKey: string;
    parentTitle: string;
    indexed: boolean;
    usedByCategoriesDictionary: UsedByCategoriesDictionary;
    deleted: boolean;

    public constructor(key: any = undefined, obj: any = undefined) {
        this.key = key;

        if (obj === undefined || obj === null) {
            return;
        }
        Object.assign(this, obj);

        // "inProgress" means it has been uploaded, but is being optimized
        if (obj.image !== undefined && obj.image !== "inProgress") {
            this.thumbnail = "thumb_" + this.image;
        }
    }

    get objectForFirebase(): any {
        return Object.assign({}, removeUndefinedFields(this));
    }

    get objectForElasticsearch(): any {
        return { uid: this.uid, title: this.title };
    }

    get imageDownloadURL(): string {
        if (this.image !== undefined && this.image === "inProgress") {
            // This is the temp location and we are already storing download URL
            return this.image;
        }

        return DOWNLOAD_URL_PREFIX + encodeURIComponent(IMAGES_STORAGE_PATH) + this.image;
    }

    get thumbnailDownloadURL(): string {
        if (this.thumbnail === undefined) {
            return this.imageDownloadURL;
        } else {
            return DOWNLOAD_URL_PREFIX + encodeURIComponent(IMAGES_STORAGE_PATH) + this.thumbnail;
        }
    }

    get storagePath(): string {
        let filename = this.image;
        if (filename.indexOf("?") !== -1) {
            filename = filename.split("?")[0];
        }
        return IMAGES_STORAGE_PATH + filename;
    }

    get thumbnailStoragePath(): string {
        if (this.thumbnail === undefined) {
            return undefined;
        }

        let filename = this.thumbnail;
        if (filename.indexOf("?") !== -1) {
            filename = filename.split("?")[0];
        }
        return IMAGES_STORAGE_PATH + filename;
    }

    public shouldUpdateIndex(oldCategoryImage: CategoryImage) {
        if (
            this.title !== oldCategoryImage.title ||
            this.parentKey !== oldCategoryImage.parentKey ||
            this.parentTitle !== oldCategoryImage.parentTitle
        ) {
            return true;
        } else {
            return false;
        }
    }
}

const removeUndefinedFields = (object: Object) => {
    Object.keys(object).forEach(key => {
        if (object[key] === undefined) {
            delete object[key];
        }
    });
    return object;
};
