import { observable, action, computed } from "mobx";

import { CanceledSubscription } from "./payments/StripeCustomerDetails";
import { DefaultProfileImages } from "../assets";
// import { LanguageType } from "models/Languages";
import { DOWNLOAD_URL_PREFIX, PROFILE_IMAGES_PATH } from "../core/firebase/constants";
import { removeUndefinedFields } from "../core/utils/ObjectUtils";

enum PaymentProcessor {
    stripe = "stripe",
}

export enum QuelledMessageType {
    ChangeCategory = "changeCategory",
    ChangeClassList = "changeClassList",
    ChangeTeamNumber = "changeTeamNumber",
}

export enum ManagedUserType {
    Teacher = "teacher",
    StudentGroup = "studentGroup",
}

export default class User {
    public uid: string;
    public email: string;
    public emailLower: string;
    public customerID: string;
    public isAdmin: boolean;
    public isTranslator: boolean;
    public isAnonymous: boolean;
    public isTrial: boolean;
    public username: string;
    public mysqlID: number;
    public pendingManager: string;

    public paymentProcessor: PaymentProcessor;
    public profileImage: string;
    public quelledMessages: QuelledMessageType[] = [];
    public expiration: firebase.firestore.Timestamp;
    public created: firebase.firestore.Timestamp;
    public canceledSubscription: CanceledSubscription;

    @observable
    public remainingCredits: number = 0;
    @observable
    public managedUsers: (ManagedUser | PendingManagedUser)[] = [];

    public get objectForFirebase() {
        let copy: any = Object.assign({}, this);
        if (this.username) {
            copy.usernameLower = this.username.toLowerCase();
        }
        if (this.email) {
            copy.emailLower = this.email.toLowerCase();
        }

        delete copy.subscription;
        delete copy.managedUsers;

        return removeUndefinedFields(copy);
    }

    @computed
    public get managedTeachersCount() {
        if (this.managedUsers) {
            let users = this.managedUsers.filter((user) => {
                return user.managedUserType === ManagedUserType.Teacher;
            });
            return users.length;
        }
        return 0;
    }

    @computed
    public get managedStudentGroupsCount() {
        return this.managedUsers.filter((user) => {
            return user.managedUserType === ManagedUserType.StudentGroup;
        }).length;
    }

    public get profileImageDownloadURL(): string {
        if (!this.profileImage) {
            return DefaultProfileImages.neutralProfileImage();
        }

        if (this.profileImage.indexOf("http") === 0) {
            return this.profileImage;
        }
        return DOWNLOAD_URL_PREFIX + encodeURIComponent(PROFILE_IMAGES_PATH) + this.profileImage;
    }

    @computed
    get thumbnailDownloadURL(): string {
        if (!this.profileImage) {
            return DefaultProfileImages.neutralProfileImage();
        }

        if (this.profileImage.indexOf("http") === 0) {
            return this.profileImage;
        }
        return DOWNLOAD_URL_PREFIX + encodeURIComponent(PROFILE_IMAGES_PATH) + "thumb_" + this.profileImage;
    }

    constructor(obj: any = undefined) {
        if (obj !== undefined) {
            Object.assign(this, obj);
        }
        return this;
    }

    @action
    public addManagedUser(user: ManagedUser | PendingManagedUser) {
        let foundUser = this.managedUsers.find((element) => element.uid === user.uid);
        if (!foundUser) {
            this.managedUsers.push(user);
        }
    }

    @action
    public updateManagedUser(user: ManagedUser | PendingManagedUser) {
        let updatedUsers = this.managedUsers.map((managedUser) => {
            return user.uid === managedUser.uid ? user : managedUser;
        });
        console.log(updatedUsers);
        this.managedUsers = updatedUsers;
    }

    @action
    public removeManagedTeacher(userToRemove: ManagedUser | PendingManagedUser) {
        this.managedUsers = this.managedUsers.filter((user) => {
            return user.uid !== userToRemove.uid;
        });
    }

    public isMessageQuelled(message: QuelledMessageType): boolean {
        return (
            this.quelledMessages.find((element) => {
                return element === message;
            }) !== undefined
        );
    }
}

export class ManagedUser extends User {
    // public enabled: boolean = false;
    public managedBy: string;
    public managedUserType: ManagedUserType;

    constructor(obj: any = undefined) {
        super();
        if (obj !== undefined) {
            Object.assign(this, obj);
        }
        delete this.managedUsers;
        return this;
    }

    public get isManagedUser(): boolean {
        return this.isStudentGroup || this.isManagedTeacher;
    }

    public get isStudentGroup(): boolean {
        return this.managedBy && this.managedUserType === ManagedUserType.StudentGroup;
    }

    public get isManagedTeacher(): boolean {
        return this.managedBy && this.managedUserType === ManagedUserType.Teacher;
    }
}

export interface PendingManagedUser {
    uid?: string;
    email: string;
    emailLower: string;
    managedBy: string;
    managedUserType: ManagedUserType;
}
