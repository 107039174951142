import * as React from "react";
import { Component } from "react";

// Material UI
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// Utils
import { Colors } from "../../core/utils/Colors";

var Row = require("react-flexbox-grid").Row;

interface TeamHeaderCardProps {
    title: string;
    width?: number;
}

const styles = {
    title: {
        fontSize: 26,
        padding: 5
    }
};

export default class TeamHeaderCard extends Component<TeamHeaderCardProps> {
    render() {
        return (
            <Paper style={{ width: "100%", backgroundColor: Colors.lightGrey }} children={this.renderPaperChildren()} />
        );
    }

    private renderPaperChildren() {
        let width = this.props.width ? this.props.width : 250;
        return (
            <div style={{ position: "relative" }}>
                <Row center="xs" style={{ margin: "auto", minWidth: width, width: "100%" }}>
                    <Typography style={styles.title}>{this.props.title}</Typography>
                </Row>
            </div>
        );
    }
}
