import { log } from "../";

import * as firebase from "firebase";
import { observable, action, computed, runInAction } from "mobx";

import CategoryMenuStore from "./CategoryMenuStore";
import FirestoreFolder from "models/customLists/FirestoreFolder";
import CategoryFolder from "models/customLists/CategoryFolder";
import CategoryImagesSearchResultFolder from "../models/customLists/CategoryImagesSearchResultFolder";
import { Firestore, StorageActions } from "../core/firebase";
import { CollectionReference } from "firebase/firebase-firestore";

import {
    Folder,
    TabSelection,
    Category,
    CategoryType,
    CategoryItem,
    VocabularyCategoryItem,
    ExpressionCategoryItem,
    LessonCategoryItem,
    AudioClipVoice,
    CategoryImage,
} from "../models";
import Utils from "../core/utils/Utils";
import { ListItem } from "models/Folder";

export class CreateListsStore extends CategoryMenuStore {
    @observable currentCategory: Category;

    @observable siteListsSearchFolder: Folder;
    @observable myListsSearchFolder: Folder;

    @observable categoryItemsToCreate: Array<CategoryItem> = new Array<CategoryItem>();
    @observable categoryItemToEdit?: CategoryItem;

    @observable shouldShowImagesSelector: boolean = false;
    @observable shouldShowTextOnlyInput: boolean = false;
    @observable shouldShowImagesUploader: boolean = false;

    @observable totalUploads: number = 0;
    @observable uploadErrorCount: number = 0;
    @observable uploadSuccessCount: number = 0;

    siteListsFolder: Folder;
    myListsFolder: Folder;

    private currentCategoryUnsubscribeCallback: () => void;

    @computed
    get isSearching() {
        if (this.currentTab === TabSelection.SITE_IMAGES || this.searchText !== undefined) {
            return true;
        } else {
            return false;
        }
    }

    get visibleCategoryMenuFolder(): CategoryFolder {
        if (this.isSearching) {
            return this.searchTabs.get(this.currentTab);
        } else {
            return this.folderTabs.get(this.currentTab);
        }
    }

    @computed
    get siteListsSearchCount() {
        return this.siteListsSearchFolder === undefined ? 0 : this.folderTabs[TabSelection.SITE_IMAGES].totalItems;
    }

    @computed
    get myListsSearchCount() {
        return this.myListsSearchFolder === undefined ? 0 : this.folderTabs[TabSelection.MY_IMAGES].totalItems;
    }

    @action
    public setCategoryItemToEdit = (item: CategoryItem) => {
        this.categoryItemToEdit = item;
    };

    @action
    public setShouldShowImagesSelector(value: boolean) {
        this.shouldShowImagesSelector = value;
    }

    @action
    public setShouldShowTextOnlyInput(value: boolean) {
        this.shouldShowTextOnlyInput = value;
    }

    @action
    public setShouldShowImagesUploader(value: boolean) {
        this.shouldShowImagesUploader = value;
    }

    protected onStoreHydrated() {
        if (!this.folderTabs.get(TabSelection.MY_IMAGES)) {
            this.folderTabs.set(TabSelection.MY_IMAGES, new FirestoreFolder(TabSelection.MY_IMAGES, this.rootStore));
        }

        if (!this.searchTabs.get(TabSelection.SITE_IMAGES)) {
            this.searchTabs.set(
                TabSelection.SITE_IMAGES,
                new CategoryImagesSearchResultFolder(TabSelection.SITE_IMAGES, this.rootStore)
            );
            this.searchTabs.set(
                TabSelection.MY_IMAGES,
                new CategoryImagesSearchResultFolder(TabSelection.MY_IMAGES, this.rootStore)
            );
        }

        runInAction(() => {
            if (!this.currentTab || !this.folderTabs.get(this.currentTab)) {
                this.currentTab = TabSelection.SITE_IMAGES;
            }
            this.searchTabs.get(TabSelection.SITE_IMAGES).isVisible = true;
        });
        this.isHydrated = true;
    }

    // UI Updates
    // ------------------------------------------
    @action
    public setCurrentCategory = (category: Category) => {
        if (category === undefined) {
            // if (this.currentCategoryUnsubscribeCallback) {
            this.currentCategoryUnsubscribeCallback();
            // }
            this.currentCategory = undefined;
            return;
        }

        if (category.key !== undefined) {
            console.log("Subscribing to category: " + category.key);
            let ref = Firestore.categoriesCollection().doc(category.key) as firebase.firestore.DocumentReference;
            this.currentCategoryUnsubscribeCallback = ref.onSnapshot(
                action((snapshot: firebase.firestore.DocumentSnapshot) => {
                    if (snapshot !== undefined && snapshot.exists) {
                        let key = snapshot.id;
                        let value = snapshot.data();

                        let newCategory: Category = new Category(value);
                        newCategory.key = key;

                        this.currentCategory = newCategory;
                        log.verbose("Updated current category. Key: " + key);
                    } else {
                        log.error("Error setting current category. Snapshot is null.");
                    }
                })
            );
        } else {
            this.currentCategory = category; // New category
        }
    };

    // Uploaded Images
    // ------------------------------------------
    public updateImageFolder = async (folder: Folder) => {
        await this.createAndSetRootFolderIfNecessary();
        let foldersRef = this.rootStore.refFactory.foldersRefForTab(this.currentTab);
        if (folder.key === undefined) {
            // Create a new folder
            foldersRef.add({
                title: folder.title,
                searchTitle: folder.title.toLowerCase(),
                parentKey: this.visibleFolder.key,
            });
        } else {
            // Update existing title
            foldersRef.doc(folder.key).update({ title: folder.title, searchTitle: folder.title.toLowerCase() });
        }
    };

    public updateCategoryImageTitle = (uploadedImageKey: string, title: string) => {
        let updates = { title: title, searchTitle: title.toLowerCase() };
        let imageRefDB = this.rootStore.refFactory.itemRefForTab(this.currentTab);
        imageRefDB.doc(uploadedImageKey).update(updates);
    };

    public uploadImage = async (imageFile: File) => {
        // First create object, so we have a key to point to
        let title = Utils.stripExtension(imageFile.name);
        let imageObj = {
            uid: this.rootStore.userStore.user.uid,
            parentTitle: null,
            parentKey: this.visibleFolder.key,
            title: title,
            searchTitle: title.toLowerCase(),
            deleted: false,
        };

        if (this.visibleFolder.title !== undefined) {
            imageObj.parentTitle = this.visibleFolder.title.toLowerCase();
        }

        try {
            let success = StorageActions.uploadCategoryImage(
                imageObj,
                imageFile,
                this.rootStore.userStore.user.uid,
                this.visibleFolder.key
            );
            if (success) {
                this.uploadSuccessCount++;

                // Hide upload drop zone and force folder refresh
                let uploadCompleteCount = this.uploadErrorCount + this.uploadSuccessCount;
                log.verbose(`Upload Count: ${uploadCompleteCount}, Total: ${this.totalUploads}`);
                if (uploadCompleteCount === this.totalUploads) {
                    this.setShouldShowImagesUploader(false);
                }
            }
        } catch (error) {
            this.rootStore.showErrorMessage(error);
            this.uploadErrorCount++;

            let uploadCompleteCount = this.uploadErrorCount + this.uploadSuccessCount;
            log.verbose(`Upload Count: ${uploadCompleteCount}, Total: ${this.totalUploads}`);
            if (uploadCompleteCount === this.totalUploads) {
                this.setShouldShowImagesUploader(false);
            }
        }
    };

    @action
    public deleteCategoryImage = (uploadedImage: CategoryImage) => {
        let ref = Firestore.categoryImagesRef();
        ref.doc(uploadedImage.key).update({ deleted: true, deletedTime: new Date() });

        // Check if it is currently in the "selected" list and remove it
        this.categoryItemsToCreate = this.categoryItemsToCreate.filter((item) => {
            return item.imageKey !== uploadedImage.key;
        });
    };

    protected deleteItemsForFolder = async (folder: Folder) => {
        if (this.currentTab === TabSelection.MY_IMAGES) {
            // Fetch all categories that have folder as parent
            let querySnapshot = await (Firestore.categoryImagesRef() as CollectionReference)
                .where("parentKey", "==", folder.key)
                .get();

            for (let doc of querySnapshot.docs) {
                let value = doc.data();
                if (value !== undefined && value !== null) {
                    // Delete images
                    let category = new CategoryImage(doc.id, doc.data());
                    this.deleteCategoryImage(category);
                }
            }
        } else {
            log.error("Delete folder not implemented for this type!!");
            return;
        }
    };

    // TODO
    // @action
    // public deleteCategoryImageFromTrash = (uploadedImage: CategoryImage) => {
    //     // Don't delete if it has been favorited, just remove parent folder key
    //     if (
    //         uploadedImage.usedByCategoriesDictionary !== undefined &&
    //         Object.keys(uploadedImage.usedByCategoriesDictionary).length > 0
    //     ) {
    //         let uploadedImageCopy: any = Object.assign({}, uploadedImage);
    //         delete uploadedImageCopy.parentKey;
    //         delete uploadedImageCopy.parentTitle;
    //         delete uploadedImageCopy.uid;

    //         try {
    //             this.rootStore.refFactory
    //                 .categoryImagesRef()
    //                 .doc(uploadedImage.key)
    //                 .delete();
    //         } catch (error) {
    //             log.error(`Error deleting uploaded image. Error: ${error}`);
    //         }

    //         log.verbose("Uploaded image is being used in list so it was moved from parent folder, but not deleted.");
    //         return;
    //     }

    // // This may only be needed until all images have been migrated from barryfunenglish
    // if (uploadedImage.image !== undefined) {
    //     let imageStorageGuid: string;
    //     let split = uploadedImage.image.split(".");
    //     if (split.length > 0) {
    //         imageStorageGuid = split[0];
    //         this.rootStore.refFactory
    //             .imagesStorageDBRef()
    //             .doc(imageStorageGuid)
    //             .delete();

    //         log.verbose("Deleting image storage DB ref for " + imageStorageGuid);
    //     } else {
    //         log.error("Could not get imageStorageGuid for " + uploadedImage.image);
    //     }
    // }

    //     // Delete from db and storage
    //     let imageRefDB = Firestore.categoryImagesRef();
    //     log.verbose("Deleting item ref from folder for key: " + uploadedImage.key);
    //     imageRefDB
    //         .doc(uploadedImage.key)
    //         .delete()
    //         .then(() => {
    //             log.verbose("Deleting image from storage for key: " + uploadedImage.key);
    //             let imageFileRef = this.rootStore.refFactory.storageRefForPath(uploadedImage.storagePath);
    //             return imageFileRef.delete();
    //         })
    //         .then(() => {
    //             log.verbose("Deleting image thumbnail from storage for key: " + uploadedImage.key);
    //             let imageFileRef = this.rootStore.refFactory.storageRefForPath(uploadedImage.thumbnailStoragePath);
    //             return imageFileRef.delete();
    //         })
    //         .catch(error => {
    //             log.verbose("Error deleting uploaded image. Error: ");
    //             log.verbose(error);
    //         });
    // };

    // Selected Images
    @action
    public addSelectedImage = (image: ListItem) => {
        let found = false;
        this.categoryItemsToCreate.forEach((item) => {
            if (item.imageKey === image.key) {
                log.verbose("Item already selected, not adding.");
                found = true;
            }
        });
        if (found) {
            return;
        }

        let newArray = this.categoryItemsToCreate.slice();
        newArray.push(this.newCategoryItemForImage(image as CategoryImage, this.currentCategory.type));

        this.categoryItemsToCreate = newArray;
        console.log(this.categoryItemsToCreate);
    };

    @action
    public removeSelectedImage = (imageKey: string) => {
        this.categoryItemsToCreate = this.categoryItemsToCreate.filter((categoryItem: CategoryItem) => {
            return categoryItem.imageKey !== imageKey;
        });
    };

    @action
    public clearSelectedImages = () => {
        this.categoryItemsToCreate = [];
    };

    @action
    public removeFirstSelectedItem = () => {
        // Remove fist element and continue adding if more selections
        this.categoryItemsToCreate.splice(0, 1);
        if (this.categoryItemsToCreate.length > 0) {
            this.editNextSelectedImageForAdd();
        } else {
            this.clearSelectedImages();
            this.setCategoryItemToEdit(undefined);
        }
    };

    public editNextSelectedImageForAdd = () => {
        if (this.categoryItemsToCreate.length === 0) {
            log.error("No selected images to edit.");
        } else {
            let item = this.categoryItemsToCreate[0];
            this.setCategoryItemToEdit(item);
        }
    };

    // Category List
    // -------------------------------------
    @action
    public updateCategory = async (category: Category) => {
        if (category.title) {
            category.searchTitle = category.title.toLowerCase();
        }

        if (category.key) {
            // Updating an existing category
            category.modified = new Date();

            let ref = Firestore.categoriesCollection().doc(category.key);
            let categoryForFirebase = category.objectForFirebase;
            log.verbose(categoryForFirebase);
            ref.update(categoryForFirebase).catch((error) => {
                log.verbose("Error editing category details. Error: " + error);
                return false;
            });
        } else {
            // Creating a new category
            category.created = new Date();
            if (category.uid === "site") {
                category.profileImage = require(`../assets/images/icon.png`);
            } else if (this.rootStore.userStore.user && this.rootStore.userStore.user.profileImage) {
                category.profileImage = this.rootStore.userStore.user.profileImage;
            }

            let ref = Firestore.categoriesCollection();
            let doc = await ref.add(category.objectForFirebase);
            if (doc.id !== undefined) {
                category.key = doc.id;
                this.rootStore.browseListsStore.setItemToEdit(category);
                this.rootStore.activityStore.setShouldShowCreateListsView(true);
            } else {
                log.error("Error editing category details.");
            }

            return doc.id;
        }
    };

    public updateCategoryItemForCategory = async (newItem: CategoryItem, oldItem: CategoryItem, category: Category) => {
        if (category === undefined) {
            log.verbose("Error updating category item. Current category is null");
        } else {
            let categoryCopy = new Category();
            Object.assign(categoryCopy, category);

            let foundItem = categoryCopy.items.find((element) => {
                return newItem.key === element.key;
            });
            if (!foundItem) {
                categoryCopy.items.push(newItem);

                if (newItem.image !== undefined) {
                    // Set usedInCategory field for uploaded image
                    this.setUsedByCategoryForCategoryImage(newItem.imageKey, this.currentCategory.key);
                }
            } else {
                categoryCopy.updateItem(newItem);
            }

            let ref = Firestore.categoriesCollection().doc(categoryCopy.key);
            try {
                await ref.set({ items: categoryCopy.itemsForFirebase }, { merge: true });
            } catch (error) {
                log.error("Error editing category details. Error: " + error);
                return false;
            }

            await this.updateCategoryItemAudio(newItem, oldItem, categoryCopy, ref);
        }
    };

    public async getAudioIfChange(newText: string, oldText: string, language: AudioClipVoice) {
        if (newText.toLowerCase() !== oldText.toLowerCase()) {
            return this.getAudio(newText, language);
        } else {
            return undefined;
        }
    }

    public async getAudio(text: string, language: AudioClipVoice) {
        let audioClip = await this.rootStore.audioStore.fetchAudioForText(text, language);
        return audioClip.file;
    }

    public updateCategoryItemForCurrentCategory = async (item: CategoryItem) => {
        this.updateCategoryItemForCategory(item, undefined, this.currentCategory);
    };

    public setUsedByCategoryForCategoryImage = async (uploadedImageKey: string, categoryKey: string) => {
        // First fetch the uploadedImage item and only set the value if it is not a site image
        let docRef = Firestore.categoryImagesRef().doc(uploadedImageKey);
        let docSnapshot = await docRef.get();
        if (docSnapshot.exists) {
            let data = docSnapshot.data();
            if (data !== undefined && data !== null) {
                if (data.uid !== "site") {
                    if (data.usedByCategories === undefined || data.usedByCategories[categoryKey] === undefined) {
                        let uploadedImageCopy: any = {};
                        Object.assign(uploadedImageCopy, data);

                        // Add current to
                        if (uploadedImageCopy.usedByCategories === undefined) {
                            uploadedImageCopy.usedByCategories = {};
                        }
                        uploadedImageCopy.usedByCategories[categoryKey] = true;

                        docSnapshot.ref.update(uploadedImageCopy);
                        log.verbose(`Added category: ${categoryKey} to usedByCategories list for ${uploadedImageKey}`);
                    } else {
                        log.verbose("Uploaded image already used in category.");
                    }
                }
            } else {
                log.error("Uploaded image data is undefined.");
            }
        }
    };

    public clearUsedByCategoryForCategoryImage = async (uploadedImageKey: string, categoryKey: string) => {
        let docRef = Firestore.categoryImagesRef().doc(uploadedImageKey);
        let docSnapshot = await docRef.get();
        if (docSnapshot.exists) {
            let data = docSnapshot.data();
            if (data !== undefined && data !== null) {
                // We also want to check if this is the last category using this image and if
                // the image has been deleted from it's parent folder. In this case, just
                // delete the image
                if (data.usedByCategories !== undefined && data.usedByCategories !== null) {
                    let usedByCount = Object.keys(data.usedByCategories).length;
                    log.verbose(`${usedByCount} categories using this image.`);
                    if (data.usedByCategories[categoryKey] !== undefined) {
                        delete data.usedByCategories[categoryKey];
                        await docSnapshot.ref.update(data);
                        log.verbose(`Removed usedBy record for category: ${categoryKey}`);
                        if (usedByCount === 1 && data.parentKey === undefined) {
                            log.verbose(`The last usedBy category was removed and the image
                                        does not have a parent key. Deleting image.`);
                            let uploadedImageDocSnapshot = await Firestore.categoryImagesRef()
                                .doc(uploadedImageKey)
                                .get();
                            if (uploadedImageDocSnapshot.exists) {
                                let uploadedImage = new CategoryImage(
                                    uploadedImageDocSnapshot.id,
                                    uploadedImageDocSnapshot.data()
                                );
                                this.deleteCategoryImage(uploadedImage);
                            } else {
                                log.error("Uploaded image could not be found.");
                            }
                        }
                    } else {
                        log.verbose(`Uploaded image did not have a usedBy record for category: ${categoryKey}`);
                    }
                }
            }
        }
    };

    public deleteCategoryItemForCurrentCategory = (key: string) => {
        let categoryCopy = new Category();
        Object.assign(categoryCopy, this.currentCategory);

        if (categoryCopy === undefined) {
            log.verbose("Error updating category item. Current category is null");
        } else {
            let item = categoryCopy.items.find((item1) => {
                return item1.key === key;
            });
            let uploadedImageKey = item.imageKey;
            categoryCopy.items = categoryCopy.items.filter((item2) => {
                return item2.key !== key;
            });

            // Check if any other items in the list are using the same image. If not,
            // Remove uploadedImage/usedByCategories reference to current category
            let found = false;
            for (let item3 of categoryCopy.items) {
                if (item3.imageKey === uploadedImageKey) {
                    found = true;
                }
            }

            if (!found) {
                this.clearUsedByCategoryForCategoryImage(uploadedImageKey, categoryCopy.key);
            } else {
                log.verbose("Another item in the list is using the same image. Not clearing usedByCategory reference.");
            }

            this.updateCategory(categoryCopy);
        }
    };

    private newCategoryItemForImage = (image: CategoryImage, type: CategoryType): CategoryItem => {
        let categoryItem;
        switch (type) {
            case CategoryType.Vocabulary:
            case CategoryType.VocabularyTextOnly:
                categoryItem = new VocabularyCategoryItem();
                categoryItem.text = image.title;
                break;
            case CategoryType.Expression:
            case CategoryType.ExpressionTextOnly:
                categoryItem = new ExpressionCategoryItem();
                break;
            case CategoryType.Lesson:
                categoryItem = new LessonCategoryItem();
                categoryItem.text = image.title;
                break;
            default:
                break;
        }

        categoryItem.type = type;
        categoryItem.image = image.image;
        categoryItem.imageKey = image.key;

        return categoryItem;
    };

    private async updateCategoryItemAudio(
        newItem: CategoryItem,
        oldItem: CategoryItem,
        categoryCopy: Category,
        ref: firebase.firestore.DocumentReference
    ) {
        let audioUpdated = false;
        try {
            if (newItem instanceof VocabularyCategoryItem || (newItem instanceof LessonCategoryItem && newItem.text)) {
                // Now we need to make sure that the audio is up to date
                if (oldItem) {
                    let audio = await this.getAudioIfChange(newItem.text, oldItem.text, categoryCopy.voice);
                    if (audio) {
                        newItem.audio = audio;
                        audioUpdated = true;
                    }
                } else {
                    let audio = await this.getAudio(newItem.text, categoryCopy.voice);
                    if (audio) {
                        newItem.audio = audio;
                        audioUpdated = true;
                    }
                }
            }
            if (newItem instanceof ExpressionCategoryItem) {
                if (newItem.question) {
                    if (oldItem) {
                        let audio = await this.getAudioIfChange(newItem.question, oldItem.question, categoryCopy.voice);
                        if (audio) {
                            newItem.questionAudio = audio;
                            audioUpdated = true;
                        }
                    } else {
                        let audio = await this.getAudio(newItem.question, categoryCopy.voice);
                        if (audio) {
                            newItem.questionAudio = audio;
                            audioUpdated = true;
                        }
                    }
                }

                let voice = categoryCopy.answersVoice !== undefined ? categoryCopy.answersVoice : categoryCopy.voice;
                if (newItem.answer) {
                    if (oldItem) {
                        let audio = await this.getAudioIfChange(
                            newItem.answer,
                            oldItem.answer,
                            categoryCopy.answersVoice
                        );
                        if (audio) {
                            newItem.answerAudio = audio;
                            audioUpdated = true;
                        }
                    } else {
                        let audio = await this.getAudio(newItem.answer, voice);
                        if (audio) {
                            newItem.answerAudio = audio;
                            audioUpdated = true;
                        }
                    }
                }
                if (newItem.wrong1) {
                    if (oldItem) {
                        let audio = await this.getAudioIfChange(newItem.wrong1, oldItem.wrong1, voice);
                        if (audio) {
                            newItem.wrongAudio1 = audio;
                            audioUpdated = true;
                        }
                    } else {
                        let audio = await this.getAudio(newItem.wrong1, voice);
                        if (audio) {
                            newItem.wrongAudio1 = audio;
                            audioUpdated = true;
                        }
                    }
                }
                if (newItem.wrong2) {
                    if (oldItem) {
                        let audio = await this.getAudioIfChange(newItem.wrong2, oldItem.wrong2, voice);
                        if (audio) {
                            newItem.wrongAudio2 = audio;
                            audioUpdated = true;
                        }
                    } else {
                        let audio = await this.getAudio(newItem.wrong2, voice);
                        if (audio) {
                            newItem.wrongAudio2 = audio;
                            audioUpdated = true;
                        }
                    }
                }
                if (newItem.wrong3) {
                    if (oldItem) {
                        let audio = await this.getAudioIfChange(newItem.wrong3, oldItem.wrong3, voice);
                        if (audio) {
                            newItem.wrongAudio3 = audio;
                            audioUpdated = true;
                        }
                    } else {
                        let audio = await this.getAudio(newItem.wrong3, voice);
                        if (audio) {
                            newItem.wrongAudio3 = audio;
                            audioUpdated = true;
                        }
                    }
                }
            }
            if (audioUpdated) {
                categoryCopy.updateItem(newItem);
                console.log("Updating item");
                ref.set({ items: categoryCopy.itemsForFirebase }, { merge: true }).catch((error) => {
                    log.verbose("Error editing category details. Error: " + error);
                    return false;
                });
            }
        } catch (error) {
            log.verbose("Error fetching audio. Error: " + error);
        }
    }
}
