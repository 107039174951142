import { ActivityStore } from "stores";
import ActivitySupport from "./ActivitySupport";
import Activity from "models/activities/Activity";
import { FlashcardActivity, Worksheet, Game, InteractiveWorksheet, Quiz, Tool } from "models";
import { strings } from "core/localizedStrings";

export const updateCurrentActivity = (activityStore: ActivityStore, activity: Activity) => {
    if (activityStore) {
        if (!activityStore.currentActivity || activityStore.currentActivity.activityType !== activity.activityType) {
            activityStore.currentActivity = activity;
        }

        if (
            !(activityStore.currentActivity instanceof Tool) &&
            activityStore.currentCategory &&
            activityStore.currentActivity &&
            !ActivitySupport.categoryTypeSupported(activityStore.currentActivity, activityStore.currentCategory)
        ) {
            activityStore.rootStore.showInfoMessage({ text: strings.categoryTypeNotSupported });
            activityStore.setCurrentCategory(undefined);
        }
    }
};

export const activityFromPath = (path: string): Activity => {
    let allActivityItems = [
        FlashcardActivity.allItems,
        Worksheet.allItems,
        Game.allItems,
        InteractiveWorksheet.allItems,
        Quiz.allItems,
        Tool.allItems,
    ];

    for (var activityItems of allActivityItems) {
        for (var item of activityItems) {
            if (item.navigationPath === path) {
                return item;
            }
        }
    }
    return undefined;
};
