import { log } from "../";

import * as React from "react";
import { observable, runInAction, autorun } from "mobx";
import { observer } from "mobx-react";

// Material UI
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// Stores
import RootStore from "../stores/RootStore";

// Utils
import { Colors } from "../core/utils/Colors";
import { strings } from "../core/localizedStrings";
import FirebaseFunctions from "../core/firebase/FirebaseFunctions";
import { StringUtils } from "../core/utils/StringUtils";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

export interface ContactFormProps extends React.Props<any> {
    store: RootStore;
}

@observer
export default class ContactForm extends React.Component<ContactFormProps> {
    @observable submitted: boolean = false;

    @observable private email: string;
    @observable private name: string = "";
    @observable private subject: string = "";
    @observable private message: string = "";

    constructor(props: ContactFormProps) {
        super(props);

        autorun(() => {
            if (this.props.store.userStore && this.props.store.userStore.user) {
                runInAction(() => {
                    this.email = this.props.store.userStore.user.email;
                });
            }
        });
    }

    render() {
        let rowPadding = 20;
        return (
            <Row center="xs" style={{ width: "100%" }}>
                <Col
                    xs={12}
                    style={{ maxWidth: 500, backgroundColor: Colors.veryLightGrey, padding: "0 25px 20px 25px" }}
                >
                    <div style={{ margin: 20 }}>
                        <Row style={{ paddingTop: rowPadding }}>
                            <TextField
                                label={strings.name}
                                value={this.name}
                                disabled={this.submitted}
                                fullWidth={true}
                                id="name"
                                onChange={this.onTextChanged}
                                // onKeyDown={this.onKeyDown}
                            />
                        </Row>
                        <Row style={{ paddingTop: rowPadding }}>
                            <TextField
                                label={strings.email}
                                value={this.email ? this.email : ""}
                                disabled={this.props.store.authStore.isSignedIn || this.submitted}
                                onChange={this.onTextChanged}
                                fullWidth={true}
                                id="email"
                            />
                        </Row>
                        <Row style={{ paddingTop: rowPadding }}>
                            <TextField
                                multiline={true}
                                label={strings.subject}
                                value={this.subject}
                                disabled={this.submitted}
                                fullWidth={true}
                                id="subject"
                                onChange={this.onTextChanged}
                                // onKeyDown={this.onKeyDown}
                            />
                        </Row>
                        <Row style={{ paddingTop: rowPadding }}>
                            <TextField
                                multiline={true}
                                rows={5}
                                value={this.message}
                                disabled={this.submitted}
                                label={strings.message}
                                fullWidth={true}
                                id="message"
                                onChange={this.onTextChanged}
                                // onKeyDown={this.onKeyDown}
                            />
                        </Row>
                        <Row center="xs" style={{ paddingTop: rowPadding }}>
                            {this.submitted ? (
                                <CircularProgress />
                            ) : (
                                <Button fullWidth={true} color="primary" variant="contained" onClick={this.onSubmit}>
                                    {strings.submit}
                                </Button>
                            )}
                        </Row>
                    </div>
                </Col>
            </Row>
        );
    }

    private onTextChanged = (event: any) => {
        runInAction(() => {
            switch (event.currentTarget.id) {
                case "name":
                    this.name = event.currentTarget.value;
                    break;
                case "email":
                    this.email = event.currentTarget.value;
                    break;
                case "subject":
                    this.subject = event.currentTarget.value;
                    break;
                case "message":
                    this.message = event.currentTarget.value;
                    break;
                default:
                    log.verbose(`Unknown target ID: ${event.currentTarget.id}`);
                    break;
            }
        });
    };

    // private onKeyDown = (event: any) => {
    //     if (event.keyCode === 13) {
    //         if (this.props.submitOnEnter) {
    //             this.onSubmit();
    //         }
    //     }
    // };

    private onSubmit = async () => {
        if (
            !this.email ||
            this.email.length === 0 ||
            this.name.length === 0 ||
            this.message.length === 0 ||
            this.subject.length === 0
        ) {
            this.props.store.showInfoMessage({ text: strings.allFieldsRequired });
            return;
        }

        if (!StringUtils.isValidEmail(this.email)) {
            this.props.store.showInfoMessage({ text: strings.enterValidEmail });
            return;
        }

        runInAction(() => {
            this.submitted = true;
        });

        try {
            let userID = undefined;
            if (
                !this.props.store.authStore.isAnonymous &&
                this.props.store.userStore.user &&
                this.props.store.userStore.user.uid
            ) {
                userID = this.props.store.userStore.user.uid;
            }
            await FirebaseFunctions.sendContactFormEmail(userID, this.name, this.email, this.subject, this.message);
            runInAction(() => {
                this.submitted = false;
                this.subject = "";
                this.message = "";
            });
            this.props.store.showInfoMessage({ text: strings.contactFormSubmitted });
        } catch (error) {
            this.props.store.showErrorMessage(strings.problemSubmittingContactForm + `Error: ${error}`);
        }
    };
}
