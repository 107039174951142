import { log } from "../../";

import Student from "../../models/Student";
import { User, ManagedUser } from "../../models";
import { Firestore, StorageActions } from "./";

export default class FirestoreActions {
    public static app: firebase.app.App;

    // Actions
    // --------------------------------------
    public static async getUser(uid: string) {
        let userSnapshot = await Firestore.usersCollection().doc(uid).get();
        if (userSnapshot.exists) {
            let data = userSnapshot.data();
            if (data.managedBy) {
                let managedUser = new ManagedUser(data);
                return managedUser;
            } else {
                return new User(data);
            }
        }
        return undefined;
    }

    public static async getUserFromEmail(email: string) {
        let userSnapshotQuery = await (Firestore.usersCollection() as firebase.firestore.CollectionReference)
            .where("emailLower", "==", email.toLowerCase())
            .get();
        if (!userSnapshotQuery.empty) {
            let data = userSnapshotQuery.docs[0].data();
            if (data.managedBy) {
                return new ManagedUser(data);
            } else {
                return new User(data);
            }
        }
        return undefined;
    }

    public static async updateUser(user: User) {
        let obj: any = user.objectForFirebase;
        try {
            let document = Firestore.usersCollection().doc(user.uid) as firebase.firestore.DocumentReference;
            await document.set(obj, { merge: true });
            return FirestoreActions.getUser(user.uid);
        } catch (error) {
            log.error(error);
            return null;
        }
    }

    public static async deleteStudent(student: Student) {
        await Firestore.studentsRef().doc(student.key).delete();
        await StorageActions.deleteStudentProfileImage(student);
    }
}
