import * as React from "react";

// Material UI
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

// Utils
import { strings } from "core/localizedStrings";
import { Row } from "layout";
import { Grid } from "@material-ui/core";

interface CheckboxProps {
    isChecked: boolean;
    label?: string;
    isDisabled?: boolean;
    onChange: (event: any, value: any) => void;
}

export const MyCheckbox = (props: CheckboxProps) => (
    <Grid container>
        <FormControlLabel
            control={
                <Checkbox
                    checked={props.isChecked}
                    disabled={props.isDisabled != undefined && props.isDisabled}
                    onChange={props.onChange}
                />
            }
            label={props.label}
        />
    </Grid>
);

export const AutoPlayAudioCheckbox = (props: CheckboxProps) => (
    <MyCheckbox isChecked={props.isChecked} label={strings.autoPlayAudio} onChange={props.onChange} />
);
