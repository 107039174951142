import Student from "./Student";
import { serializable, object, list } from "serializr";
import { observable } from "mobx";

export default class Team {
    @serializable key?: string;
    @serializable name?: string;

    @serializable(list(object(Student)))
    students?: Student[] = [];

    @observable
    currentGamePoints?: number = 0;
}
