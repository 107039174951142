import * as React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";

import * as Icons from "../../core/utils/Icons";
import SimpleMenu from "./SimpleMenu";

// Localization
import { strings } from "../../core/localizedStrings";
import { RootStore } from "stores";

export enum MenuOption {
    Preview,
    Edit,
    Delete,
    Copy,
    Move,
    MyAccount,
    Logout,
    Support
}

const styles = {
    avatar: { width: 25, height: 25, marginRight: 5 }
};

interface OptionsMenuProps extends React.Props<any> {
    rootStore?: RootStore;
    options: MenuOption[];
    onMenuSelectionChangedCallback: (option: MenuOption) => void;
}

export default class OptionsMenu extends React.Component<OptionsMenuProps> {
    public render() {
        return <SimpleMenu icon={<Icons.MoreVert />}>{this.renderOptions()}</SimpleMenu>;
    }

    private onMenuSelectionChanged = (event: any) => {
        this.props.onMenuSelectionChangedCallback(event.currentTarget.value);
    };

    private renderOptions() {
        return this.props.options.map((option: MenuOption, index) => (
            <MenuItem key={option} value={option} onClick={this.onMenuSelectionChanged}>
                {this.getIconForOption(option)}
                {this.getStringForOption(option)}
            </MenuItem>
        ));
    }

    private getIconForOption(option: MenuOption): JSX.Element {
        let style = { paddingRight: 5 };
        switch (option) {
            case MenuOption.Preview:
                return <Icons.Preview style={style} />;
            case MenuOption.Edit:
                return <Icons.Edit style={style} />;
            case MenuOption.Delete:
                return <Icons.Delete style={style} />;
            case MenuOption.Copy:
                return <Icons.Copy style={style} />;
            case MenuOption.Move:
                return <Icons.Move style={style} />;
            case MenuOption.Logout:
                return <Icons.Logout style={style} />;
            case MenuOption.Support:
                return <Icons.Support style={style} />;
            case MenuOption.MyAccount:
                return (
                    <Avatar style={styles.avatar} src={this.props.rootStore.userStore.user.profileImageDownloadURL} />
                );
            default:
                return null;
        }
    }

    private getStringForOption(option: MenuOption) {
        switch (option) {
            case MenuOption.Preview:
                return strings.preview;
            case MenuOption.Edit:
                return strings.edit;
            case MenuOption.Delete:
                return strings.delete;
            case MenuOption.Copy:
                return strings.copy;
            case MenuOption.Move:
                return strings.move;
            case MenuOption.Logout:
                return strings.logout;
            case MenuOption.Support:
                return strings.support;
            case MenuOption.MyAccount:
                return strings.myAccount;
            default:
                return undefined;
        }
    }
}
