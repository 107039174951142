import * as React from "react";
import { observer } from "mobx-react";

import * as CustomLists from "../../models/customLists";
import { BrowseListsStore } from "../../stores/BrowseListsStore";
import { CreateListsStore } from "../../stores/CreateListsStore";
import StudentListStore from "../../stores/StudentListStore";
import { MenuOption } from "../common/OptionsMenu";

import { FolderCard } from "../folderBrowser/FolderCard";

interface CategoryMenuFolderCardProps extends React.Props<any> {
    store: BrowseListsStore | CreateListsStore | StudentListStore;
    folder: CustomLists.Folder;
    onBackgroundClickedCallback?: (folder: CustomLists.Folder) => void;
}

@observer
export class CategoryMenuFolderCard extends React.Component<CategoryMenuFolderCardProps> {
    protected onMenuSelectionChanged = (selectedOption: MenuOption) => {
        switch (selectedOption) {
            case MenuOption.Move:
                this.props.store.setFolderToMove(this.props.folder);
                break;
            case MenuOption.Edit:
                this.props.store.setFolderTitleToEdit(this.props.folder);
                break;
            case MenuOption.Delete:
                this.props.store.deleteFolder(this.props.folder);
                break;
            default:
                break;
        }
    };

    render() {
        return (
            <FolderCard
                showOptions={true}
                store={this.props.store}
                folder={this.props.folder}
                onBackgroundClickedCallback={this.props.onBackgroundClickedCallback}
            />
        );
    }

    // protected renderButtons() {
    //     let isAdmin = this.props.store.rootStore.userStore.user && this.props.store.rootStore.userStore.user.isAdmin;

    //     if (
    //         (this.props.store.currentTab === CustomLists.TabSelection.SITE && !isAdmin) ||
    //         this.props.store.currentTab === CustomLists.TabSelection.SITE_IMAGES ||
    //         this.props.store.currentTab === CustomLists.TabSelection.PUBLIC
    //     ) {
    //         return null;
    //     } else {
    //         let options: MenuOption[] = [MenuOption.Edit, MenuOption.Move, MenuOption.Delete];
    //         return (
    //             <div style={{ position: "absolute", top: "0px", right: "0px" }}>
    //                 <OptionsMenu options={options} onMenuSelectionChangedCallback={this.onMenuSelectionChanged} />
    //             </div>
    //         );
    //     }
    // }
}
