import { useState, useEffect } from "react";

const getSize = () => {
    return {
        height: window.innerHeight,
        width: window.innerWidth
    };
};

export const useWindowSize = () => {
    let [windowSize, setWindowSize] = useState(getSize());

    function handleResize() {
        setWindowSize(getSize());
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return windowSize;
};
