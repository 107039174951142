import { Firestore } from "core/firebase";
import { LanguageType } from "models";

export const TRANSLATIONS_COLLECTION = "translations";

export enum CollectionName {
    Strings = "strings",
    MachineTranslated = "machineTranslated",
    Categories = "categories"
}

export interface MachineTranslation {
    key?: string;
    date?: Date;
    strings: Map<string, string>;
}

export interface EnglishRevision {
    key?: string;
    date?: Date;
    strings: Map<string, string>;
}

export interface TranslationRevision {
    key?: string;
    date?: Date;
    changedCount?: number;
    englishRevisionKey: string;
    strings: Map<string, string>;
    revisionNumber: number;
}
export default class TranslationsDB {
    public static collection() {
        return Firestore.app.firestore().collection(TRANSLATIONS_COLLECTION);
    }

    public static getAllTranslations = async (
        language: LanguageType,
        callback: (dictionary: Map<string, string>) => void
    ) => {
        let ref = TranslationsDB.collection();
        ref.doc(language).onSnapshot(snap => {
            if (snap.exists) {
                let dictionary = snap.data().allTranslations;
                if (dictionary) {
                    callback(dictionary);
                }
            }
        });
    };

    public static updateAllTranslations = async (language: LanguageType, dictionary: Map<string, string>) => {
        return TranslationsDB.collection()
            .doc(language)
            .set({ allTranslations: dictionary }, { merge: true });
    };

    public static getRevisionForKey = async (language: LanguageType, collectionName: CollectionName, key: string) => {
        let doc = await TranslationsDB.collection()
            .doc(language)
            .collection(collectionName)
            .doc(key)
            .get();
        let revision = doc.data() as TranslationRevision;
        let date: firebase.firestore.Timestamp = doc.data().date;
        revision.date = date.toDate();
        return Object.assign(revision, { key: doc.id });
    };

    public static getRevisions = async (language: LanguageType, collectionName: CollectionName, count: number = 1) => {
        let querySnapshot = await TranslationsDB.collection()
            .doc(language)
            .collection(collectionName)
            .orderBy("date", "desc")
            .limit(count)
            .get();

        let stringsList = [];
        if (!querySnapshot.empty) {
            for (var doc of querySnapshot.docs) {
                let revision = doc.data() as TranslationRevision;
                let date: firebase.firestore.Timestamp = doc.data().date;
                revision.date = date.toDate();
                stringsList.push(Object.assign(revision, { key: doc.id }));
            }
        }
        return stringsList;
    };

    public static addEnglishRevision = async (collectionName: CollectionName, strings: Map<string, string>) => {
        let englishRevision: EnglishRevision = { date: new Date(), strings: strings };
        let doc = await TranslationsDB.collection()
            .doc(LanguageType.ENGLISH)
            .collection(collectionName)
            .add(englishRevision);
        return Object.assign(englishRevision, { key: doc.id });
    };

    public static addRevision = async (
        language: LanguageType,
        collectionName: CollectionName,
        revision: TranslationRevision
    ) => {
        let doc = await TranslationsDB.collection()
            .doc(language)
            .collection(collectionName)
            .add(revision);
        return Object.assign(revision, { key: doc.id });
    };

    public static updateStringsForKey = async (
        language: LanguageType,
        collectionName: CollectionName,
        strings: Map<string, string>,
        changedWordCount: number,
        key: string
    ) => {
        return await TranslationsDB.collection()
            .doc(language)
            .collection(collectionName)
            .doc(key)
            .update({
                date: new Date(),
                changedCount: changedWordCount,
                strings: strings
            });
    };
}
