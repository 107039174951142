import * as React from "react";

// Material UI
import Button from "@material-ui/core/Button";

// Utils
import { Colors } from "core/utils/Colors";
import { strings } from "core/localizedStrings";
import * as Icons from "core/utils/Icons";
import { DynamicTypography, DynamicTypographyType } from "core/utils/DynamicTypography";
import { IconButton, Tooltip } from "@material-ui/core";

export interface ButtonProps {
    onClick?: () => void;
}

interface TextButtonProps extends ButtonProps {
    fontStyle?: React.CSSProperties;
    style?: React.CSSProperties;
}

export const StartButton: React.SFC<TextButtonProps> = (props: TextButtonProps) => (
    <Button
        variant="contained"
        onClick={props.onClick}
        style={Object.assign({ backgroundColor: Colors.lightBlue, width: "100%" }, props.style)}
    >
        <DynamicTypography
            variant={DynamicTypographyType.H2}
            style={Object.assign({ color: Colors.white }, props.fontStyle)}
        >
            {strings.start}
        </DynamicTypography>
    </Button>
);

export const RandomizeButton: React.SFC<ButtonProps> = (props: ButtonProps) => (
    <Button onClick={props.onClick} variant="contained" fullWidth={true}>
        <Icons.Shuffle />
        {strings.randomizeAndReset}
    </Button>
);

export const ResetButton: React.SFC<ButtonProps> = (props: ButtonProps) => (
    <Tooltip title={strings.reset} placement="bottom">
        <IconButton onClick={props.onClick} size="small">
            <Icons.Replay />
        </IconButton>
    </Tooltip>
);

export const EndGameButton: React.SFC<ButtonProps> = (props: ButtonProps) => (
    <Tooltip title={strings.endGame} placement="bottom">
        <IconButton onClick={props.onClick} size="small">
            <Icons.Clear />
        </IconButton>
    </Tooltip>
);
