import * as React from "react";
import { action } from "mobx";
import { observer } from "mobx-react";

// Material UI
import Dialog from "@material-ui/core/Dialog";

// Custom UI
import CardTitleEditor from "../categoryMenu/CardTitleEditor";
import EditStudentDialog from "./EditStudentDialog";

// Stores
import StudentListStore from "../../stores/StudentListStore";

import { strings } from "../../core/localizedStrings";

export interface StudentListEditorDialogsProps extends React.Props<any> {
    store: StudentListStore;
}

@observer
export default class StudentListEditorDialogs extends React.Component<StudentListEditorDialogsProps> {
    public render() {
        let editingFolder = this.props.store.folderToEdit !== undefined;
        let editingListTitle = this.props.store.classListToEditTitle !== undefined;
        let editingStudent = this.props.store.studentToEdit !== undefined;

        if (editingFolder) {
            return (
                <Dialog
                    fullWidth={true}
                    open={this.props.store.folderToEdit !== undefined}
                    onClose={this.onFolderEditClose}
                >
                    <CardTitleEditor
                        description={strings.folderName}
                        title={this.props.store.folderToEdit.title}
                        onEditCompleteCallback={this.onEditFolderTitle}
                    />
                </Dialog>
            );
        } else if (editingListTitle) {
            return (
                <Dialog fullWidth={true} open={editingListTitle} onClose={this.onClassNameEditClose}>
                    <CardTitleEditor
                        description={strings.className}
                        title={this.props.store.classListToEditTitle.title}
                        onEditCompleteCallback={this.onEditClassNameTitle}
                    />
                </Dialog>
            );
        } else if (editingStudent) {
            return (
                <Dialog fullWidth={true} open={editingStudent} onClose={this.onStudentEditClose}>
                    <EditStudentDialog student={this.props.store.studentToEdit} store={this.props.store} />
                </Dialog>
            );
        } else {
            return null;
        }
    }

    @action
    private onEditFolderTitle = (newTitle: string) => {
        if (!this.props.store.rootStore.authStore.isSignedIn) {
            this.props.store.rootStore.showLoginRequiredMessage(strings.loginToEditLists);
        } else {
            this.props.store.folderToEdit!.title = newTitle;
            this.props.store.updateFolder(this.props.store.folderToEdit!);

            this.props.store.folderToEdit = undefined;
        }
    };

    @action
    private onFolderEditClose = () => {
        this.props.store.folderToEdit = undefined;
    };

    @action
    private onEditClassNameTitle = (newTitle: string) => {
        this.props.store.classListToEditTitle!.title = newTitle;
        this.props.store.updateStudentList(this.props.store.classListToEditTitle);

        this.props.store.itemToEdit = this.props.store.classListToEditTitle;
        this.props.store.classListToEditTitle = undefined;
    };

    @action
    private onClassNameEditClose = () => {
        this.props.store.classListToEditTitle = undefined;
    };

    @action
    private onStudentEditClose = () => {
        this.props.store.studentToEdit = undefined;
    };
}
