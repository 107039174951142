import * as React from "react";
import { observer } from "mobx-react-lite";
import { Col } from "layout";
import NavigationDrawer from "components/navigation/NavigationDrawer";
import LandingPage from "./LandingPage";
import { RootStore } from "stores";
import TeacherDashboard from "./dashboard/TeacherDashboard";
import { inject } from "mobx-react";

export interface HomeProps extends React.Props<any> {
    rootStore: RootStore;
}

const Home = inject("rootStore")(
    observer((props: HomeProps) => {
        const isSignedIn =
            !props.rootStore.authStore.isSignedIn ||
            (props.rootStore.firebaseUser && props.rootStore.firebaseUser.isAnonymous);

        return (
            <Col xs={12}>
                <NavigationDrawer rootStore={props.rootStore} />
                {/* {isSignedIn ? ( */}
                <LandingPage rootStore={props.rootStore} />
                {/* ) : (
                    <TeacherDashboard rootStore={props.rootStore} />
                )} */}
            </Col>
        );
    })
);

export default Home;
