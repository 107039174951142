import * as React from "react";
import { observer } from "mobx-react";

import OptionsMenu, { MenuOption } from "../../components/common/OptionsMenu";

// Material UI
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";

// Models
import { ManagedUser } from "../../models";

// Localization
import { strings } from "../../core/localizedStrings";

// Stores
import UserStore from "../../stores/UserStore";

import { Colors } from "../../core/utils/Colors";
import { Fonts } from "../../core/utils/Fonts";
import { ConfirmationMessage } from "../../components/MessageBoxDialogs";
import CircularProgress from "@material-ui/core/CircularProgress";
import { runInAction } from "mobx";

import { Row, Col } from "layout";
import { PendingManagedUser } from "models/User";

export interface ManagedUserProps extends React.Props<any> {
    store: UserStore;
    user: ManagedUser | PendingManagedUser;
}

@observer
export default class ManagedUserCard extends React.Component<ManagedUserProps> {
    protected styles: any;

    constructor(props: any, context: any) {
        super(props, context);

        this.styles = {
            header: {
                fontFamily: Fonts.Roboto,
                fontSize: 16,
                paddingLeft: 10,
                float: "right",
            } as React.CSSProperties,
        };
    }

    render() {
        return (
            <Paper variant="outlined" style={{ margin: 10, cursor: "pointer" }} children={this.renderPaperChildren()} />
        );
    }

    protected renderPaperChildren() {
        var imageURL = this.props.user instanceof ManagedUser ? this.props.user.profileImageDownloadURL : "";

        return (
            <div style={{ position: "relative", padding: 10 }}>
                {this.props.user instanceof ManagedUser && (
                    <Row style={{ width: "100%" }}>
                        <Col style={this.styles.header}>
                            <Row middle="xs">
                                <Avatar src={imageURL} />
                                <div style={{ paddingLeft: 10 }}>{this.props.user.username}</div>
                            </Row>
                        </Col>
                    </Row>
                )}
                <Row style={{ width: "100%" }}>
                    <Col style={this.styles.header}>
                        <div style={{ paddingTop: 10 }}>{this.props.user.email}</div>
                    </Col>
                </Row>
                {!(this.props.user instanceof ManagedUser) && (
                    <Row style={{ width: "100%" }}>
                        <Col style={this.styles.header}>
                            <div style={{ paddingTop: 10, color: Colors.red }}>
                                {
                                    "User hasn't accepted management. Have the user login or create an account with the corresponding email address."
                                }
                            </div>
                        </Col>
                    </Row>
                )}
                {this.renderOptions()}
            </div>
        );
    }

    protected renderOptions() {
        let options: MenuOption[] = [];
        options = [MenuOption.Delete];

        let teachersBeingRemoved = this.props.store.managedTeachersBeingRemoved;
        let teacherBeingRemoved = teachersBeingRemoved.filter((uid) => {
            return this.props.user.uid === uid;
        });
        return (
            <div style={{ right: 1, top: 3, position: "absolute" }}>
                {teacherBeingRemoved.length > 0 ? (
                    <CircularProgress />
                ) : (
                    <OptionsMenu options={options} onMenuSelectionChangedCallback={this.onMenuSelectionChanged} />
                )}
            </div>
        );
    }

    private onMenuSelectionChanged = (menuSelection: MenuOption) => {
        switch (menuSelection) {
            case MenuOption.Delete:
                let message = new ConfirmationMessage(
                    strings.removeManagedUserAccountConfirmation,
                    undefined,
                    (result, shouldQuell) => {
                        if (result === "yes") {
                            this.props.store.deleteManagedUser(this.props.user);
                        }
                    }
                );
                this.props.store.rootStore.showConfirmationMessage(message);

                break;
            default:
                break;
        }
    };
}
