import { log } from "../../";

import * as React from "react";
import { observer, inject } from "mobx-react";

import RootStore from "../../stores/RootStore";
import { CustomListsRefFactory } from "../../stores/CustomListsRefFactory";
import * as CustomLists from "../../models/customLists";
import { LanguageType, User } from "../../models";
import { CategoryImage } from "../../models/customLists";

import { Firestore, StorageActions, FirebaseFunctions } from "../../core/firebase/";

// Components
import { PageNotFound } from "../../components/PageNotFound";

// Material UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { CollectionReference } from "firebase/firebase-firestore";

// Tasks
import { updateImagesWithKeywords } from "./AdminTasks";
import TranslationsDB, { CollectionName } from "./TranslationsDB";
import { observable } from "mobx";
import { Grid, List, ListItem, Table, TableCell, TableRow } from "@material-ui/core";
import { Colors } from "core/utils/Colors";

export interface AdminProps extends React.Props<any> {
    rootStore: RootStore;
}

// class SiteListsCategory {
//     public title: string;
//     public type: CustomLists.CategoryType;
//     public items: any;
// }

// class SiteListsFolder {
//     public title: string;
//     public categories: SiteListsCategory;
// }

interface LoggedError {
    time: firebase.firestore.Timestamp;
    error: string;
    user: string;
    stack?: string[];
}

@inject("rootStore")
@observer
export class Admin extends React.Component<AdminProps> {
    private refFactory: CustomListsRefFactory;

    private username: string;
    private uid: string;
    @observable errors: LoggedError[];

    constructor(props: AdminProps) {
        super(props);
        this.refFactory = this.props.rootStore.refFactory;

        this.getErrors();
    }

    componentDidMount() {
        this.getErrors();
    }

    getErrors = async () => {
        console.log("GET ERRORS");
        let query = await Firestore.errorsCollection().limit(10).orderBy("time", "desc").get();
        let errors = [];
        query.docs.forEach((doc) => {
            errors.push(doc.data());
        });
        this.errors = errors;
    };

    render() {
        if (!this.props.rootStore.userStore || !this.props.rootStore.userStore.user) {
            return null;
        } else if (this.props.rootStore.userStore.user.isAdmin) {
            return (
                <Grid container direction="column" style={{ padding: 40 }}>
                    {/* {this.createButton("Create Site Images", this.onCreateImagesClicked)} */}
                    {/* {this.createButton("Delete Member Lists", this.onDeleteMemberListsClicked)} */}
                    {/* {this.createButton("Delete Site Images", this.onDeleteSiteImagesClicked)} */}
                    {/* {this.createButton("Delete Member Images", this.onDeleteMemberImagesClicked)} */}
                    {/* 
                    {this.createButton("Update categories", this.onUpdateCategoriesClicked)}
                    {this.createButton("Update images", this.onUpdateImagesClicked)} */}
                    {/* {this.createButton("Update audio", this.onUpdateAudioClicked)} */}
                    {this.createButton("Update Emails", this.onUpdateEmailsClicked)}
                    {this.createButton("Translate", this.onTranslate)}
                    {this.createButton("Translate Categories", this.onTranslateCategories)}
                    {/* {this.createButton("Fix lists", this.fixLists)} */}
                    {/* {this.createButton("Get created date", this.getCreatedDates)} */}
                    <Grid container>
                        <Grid item>
                            <TextField
                                placeholder="Username"
                                id="text"
                                style={{ fontSize: 24, paddingTop: 20 }}
                                value={this.username}
                                onChange={this.onUsernameTextFieldChanged}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                placeholder="UID"
                                id="text"
                                style={{ fontSize: 24, paddingTop: 20 }}
                                value={this.uid}
                                onChange={this.onUIDTextFieldChanged}
                            />
                        </Grid>

                        <Grid item>
                            {this.createButton("Delete All Member Data", this.onDeleteAllMemberDataClicked)}
                            {this.createButton("Masquerade as user", this.masqueradeAsUser)}
                            {this.createButton("Migrate member data", this.onMigrateMemberDataClicked)}
                            {this.createButton("Verify email", this.onVerifyEmail)}
                            {/* {this.createButton("Change email", this.onChangeEmail)} */}
                        </Grid>
                    </Grid>
                    {this.errors && (
                        <Table style={{ backgroundColor: Colors.white }}>
                            {this.errors.map((error) => {
                                return (
                                    <TableRow>
                                        <TableCell>{error.time.toDate().toDateString()}</TableCell>
                                        <TableCell>{JSON.stringify(error.error)}</TableCell>
                                        <TableCell>{error.user}</TableCell>
                                        <TableCell>{error.stack ? error.stack.join("</br>") : ""}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </Table>
                    )}
                </Grid>
            );
        } else {
            return <PageNotFound />;
        }
    }

    private createButton(title: string, callback: () => {}) {
        return (
            <Grid item style={{ paddingTop: 5 }}>
                <Button variant="contained" onClick={callback}>
                    {title}
                </Button>
            </Grid>
        );
    }

    // private getCreatedDates = async () => {
    //     let query = await Firestore.usersCollection().where("mysqlID", "!=", null).get();
    //     query.docs.forEach(async (doc) => {
    //         console.log(doc.data().mysqlID);
    //         let data = doc.data();
    //         if (data.mysqlID && !data.created) {
    //             await FirebaseFunctions.getCreatedDate(data.mysqlID, doc.id);
    //         }
    //     });
    // };

    // private onFindMissingMysqlUsers = async () => {
    //     let update = async () => {
    //         let query = await Firestore.usersCollection()
    //             .where("mysqlID", "==", null)
    //             .where("customerID", "!=", null)
    //             .get();
    //         console.log(query.docs);
    //         console.log(query.docs.length);
    //         if (query.docs.length > 0) {
    //             query.docs.forEach((element) => {
    //                 let data = element.data();
    //                 console.log(data);
    //                 if (!data.managedBy) {
    //                     let user = new User(element.data());
    //                     FirebaseFunctions.findUsersMysqlID(user.customerID, user.uid);
    //                 }
    //             });
    //         }
    //     };
    //     let count = 0;
    //     update();
    //     let id = setInterval(async () => {
    //         count += 1;
    //         if (count === 25) {
    //             clearInterval(id);
    //         }
    //         update();
    //     }, 1000 * 60 * 2);
    // };

    private onTranslate = async () => {
        // let test = new Map<string, string>();
        // test.set("test", "test");
        // let englishData = await TranslationsDB.getRevisionForKey(
        //     LanguageType.ENGLISH,
        //     CollectionName.Strings,
        //     "frmBQqo22f6FRumFljeL"
        // );
        let koreanData = await TranslationsDB.getRevisionForKey(
            LanguageType.CHINESE,
            CollectionName.MachineTranslated,
            "OeJEt4jUdBg4qEqg2vQp"
        );
        let koreanStrings = koreanData.strings;

        // for (let key of Object.keys(englishData.strings)) {
        //     let englishValue = englishData.strings[key];

        //     // let needsTranslation = false;
        //     if (koreanStrings) {
        //         if (!koreanStrings[key] || koreanStrings[key] === englishValue || koreanStrings[key] === "") {
        //             // needsTranslation = true;
        //             console.log(englishValue);
        //         }
        //     }

        //     // if (needsTranslation) {
        //     //     let strings = {};
        //     //     let result = await translateValueFromEnglish(translate, englishValue, language);
        //     //     if (result) {
        //     //         strings[key] = result;

        //     //         try {
        //     //             await docRef.set({ strings: strings, translated: true }, { merge: true });
        //     //         } catch (error) {
        //     //             console.log(error);
        //     //         }
        //     //     }
        //     // }
        // }
        // let total = Object.keys(translatedData.strings).length;
        // let matchCount = 0;
        // for (let key of Object.keys(translatedData.strings)) {
        //     let translated = translatedData.strings[key].trim();
        //     let machine = machineData.strings[key].trim();
        //     if (translated !== machine) {
        //         console.log(key + " - Translated - " + translated);
        //         console.log(key + " - Machine - " + machine);
        //     } else {
        //         matchCount += 1;
        //     }
        // }
        // let percent = (matchCount / total) * 100;
        // console.log("Percentage: " + percent + "%");
    };

    // private fixLists = async () => {
    //     let rootFolderID = "131G63cINpaeJplmoRSh";
    //     let snapshot = await Firestore.categoriesCollection().where("uid", "==", "BHKvqSWCZTVl8NOMrC7aSXEzEE03").get();
    //     if (snapshot && snapshot.docs) {
    //         for (let doc of snapshot.docs) {
    //             console.log(doc.data());
    //             doc.ref.update({ deleted: false, parentKey: rootFolderID });
    //         }
    //     }
    // };

    private onTranslateCategories = async () => {
        // Translate Categories
        FirebaseFunctions.translateCategories([LanguageType.TURKISH]);
    };

    private onUsernameTextFieldChanged = (event: any) => {
        this.username = event.currentTarget.value;
    };

    private onUIDTextFieldChanged = (event: any) => {
        this.uid = event.currentTarget.value;
    };

    private onMigrateMemberDataClicked = async () => {
        console.log("Migrating member data");
        let user: User;
        if (this.username.includes("@")) {
            user = await this.userFromEmail(this.username);
        } else {
            user = await this.userFromUsername(this.username);
        }
        if (!user) {
            console.error("User not found");
            return;
        }

        if (!user.mysqlID) {
            console.error("No mysqlID for user");
            return;
        }

        FirebaseFunctions.migrateUserDataFromBarryFunEnglish(user.mysqlID, user.uid);
    };

    private onVerifyEmail = async () => {
        let user: User;
        if (this.username.includes("@")) {
            user = await this.userFromEmail(this.username);
        } else {
            user = await this.userFromUsername(this.username);
        }
        FirebaseFunctions.verifyEmail(user.uid);
    };

    private onUpdateEmail = async () => {
        let user: User;
        if (this.username.includes("@")) {
            user = await this.userFromEmail(this.username);
        } else {
            user = await this.userFromUsername(this.username);
        }
        FirebaseFunctions.updateEmail(this.uid, this.username);
    };

    private onCreateImagesClicked = async () => {
        log.verbose("Click");
        this.fetchSiteFolders();
    };

    private onDeleteAllMemberDataClicked = async () => {
        try {
            log.verbose("Deleting member lists.");
            await this.onDeleteMemberListsClicked();

            // log.verbose("Deleting member images.");
            // await this.onDeleteMemberImagesClicked();

            // log.verbose("Deleting member student lists.");
            // await this.onDeleteMemberStudentLists();

            // log.verbose("Deleting member favorites.");
            // await this.onDeleteMemberFavorites();
        } catch (error) {
            log.verbose(error);
        }
    };

    private onDeleteSiteImagesClicked = async () => {
        let collectionRef = this.refFactory.itemRefForTab(CustomLists.TabSelection.SITE_IMAGES);
        let imageFoldersRef = this.refFactory.foldersRefForTab(CustomLists.TabSelection.SITE_IMAGES);
        this.deleteImages(collectionRef, imageFoldersRef, "site");
    };

    private onDeleteMemberImagesClicked = async () => {
        let collectionRef = this.refFactory.itemRefForTab(CustomLists.TabSelection.MY_IMAGES);
        let imageFoldersRef = this.refFactory.foldersRefForTab(CustomLists.TabSelection.MY_IMAGES);
        let user = await this.userFromUsername(this.username);
        this.deleteImages(collectionRef, imageFoldersRef, user.uid);
    };

    private onDeleteMemberStudentLists = async () => {
        // let user = await this.userFromUsername(this.username);
        // if (!user) {
        //     console.error("User not found");
        //     return;
        // }
        // let folderQuery = await this.refFactory.foldersRefForTab(CustomLists.TabSelection.CLASS_EDITOR).get();
        // if (folderQuery.docs) {
        //     for (var folder of folderQuery.docs) {
        //         let studentsQuery = await (Firestore.studentsRef() as CollectionReference)
        //             .where("parentKey", "==", folder.id)
        //             .get();
        //         if (studentsQuery.docs) {
        //             for (var studentDoc of studentsQuery.docs) {
        //                 let student = new Student(studentDoc.data());
        //                 student.key = studentDoc.id;
        //                 log.verbose("Deleting student: " + student.key);
        //                 FirestoreActions.deleteStudent(student);
        //             }
        //         }
        //         log.verbose("Deleting student folder: " + folder.id);
        //         folder.ref.delete();
        //     }
        // }
    };

    private masqueradeAsUser = async () => {
        let user: User;
        if (this.username.includes("@")) {
            user = await this.userFromEmail(this.username);
        } else {
            user = await this.userFromUsername(this.username);
        }

        if (!user) {
            console.error("User not found");
            return;
        }

        try {
            let data = await FirebaseFunctions.createTokenForMasquerade(user.uid);
            if (!data.token) {
                console.log("Token could not be created");
                return;
            }
            this.props.rootStore.authStore.signInWithToken(data.token);
        } catch {
            console.log("Token could not be created");
            return;
        }
    };

    private onDeleteMemberFavorites = async () => {
        let user = await this.userFromUsername(this.username);
        if (!user) {
            console.error("User not found");
            return;
        }

        let folderQuery = await Firestore.usersCollection().doc(user.uid).collection("favoritesFolders").get();
        if (folderQuery.docs) {
            for (var folder of folderQuery.docs) {
                let categoriesQuery = await (Firestore.categoriesCollection() as CollectionReference)
                    .where("favoritedByFolders." + folder.id, ">", "")
                    .get();
                if (categoriesQuery.docs) {
                    for (var categoryObj of categoriesQuery.docs) {
                        let category = new CustomLists.Category(categoryObj.data());
                        category.key = categoryObj.id;
                        log.verbose("Before:");
                        log.verbose(category);
                        if (category.favoritedByFolders[folder.id]) {
                            delete category.favoritedByFolders[folder.id];
                        }
                        log.verbose("After:");
                        log.verbose(category);
                        categoryObj.ref.update(category.objectForFirebase);
                    }
                }
                log.verbose("Deleting: ");
                log.verbose(folder);
                folder.ref.delete();
            }
        }
    };

    // private onDeleteMemberImagesClicked = async () => {
    //     let collectionRef = this.refFactory.itemRefForTab(CustomLists.TabSelection.MY_IMAGES);
    //     let imageFoldersRef = this.refFactory.foldersRefForTab(CustomLists.TabSelection.MY_IMAGES);
    //     let uid = await this.uidFromUsername(this.username);
    //     this.deleteImages(collectionRef, imageFoldersRef, uid);
    // };

    // private onUpdateCategoriesClicked = async () => {
    //     console.log("TODO");
    // };

    // private onUpdateImagesClicked = async () => {
    //     updateImagesWithKeywords(this.props.rootStore);
    // };

    // private onUpdateAudioClicked = async () => {
    //     let collectionRef = Firestore.categoryAudioCollection();
    //     // let newCollectionRef = Firestore.categoryAudioCollection();
    //     // log.verbose("Start");

    //     const update = async () => {
    //         let querySnapshot = await collectionRef.limit(200).where("language", "==", 16).get();
    //         if (!querySnapshot.empty) {
    //             for (let doc of querySnapshot.docs) {
    //                 let key = doc.id;
    //                 let data: any = doc.data();
    //                 let clip = new CustomLists.AudioClip(data);
    //                 clip.key = key;
    //                 console.log(clip.text);
    //                 FirebaseFunctions.updateAudioClipToGoogle(clip);
    //             }
    //             console.log(`Count: ${count}, Total docs: ` + querySnapshot.docs.length);
    //         } else {
    //             console.log("No files left to convert.");
    //         }
    //     };

    //     let count = 0;
    //     update();
    //     let id = setInterval(async () => {
    //         count += 1;
    //         if (count === 10) {
    //             clearInterval(id);
    //         }
    //         update();
    //     }, 1000 * 60 * 2);
    // };

    private deleteImages = async (
        itemsRef: firebase.firestore.CollectionReference,
        folderRef: firebase.firestore.CollectionReference,
        uid: string
    ) => {
        let querySnapshot = await itemsRef.where("uid", "==", uid).get();
        if (!querySnapshot.empty) {
            for (let doc of querySnapshot.docs) {
                let image = new CategoryImage(doc.id, doc.data());
                StorageActions.deleteCategoryImage(image);
            }
        }

        let snapshot = await folderRef.get();
        if (!snapshot.empty) {
            for (let doc of snapshot.docs) {
                log.verbose("Deleting: ");
                log.verbose(doc.data());
                doc.ref.delete();
            }
        }
    };

    private onUpdateEmailsClicked = async () => {
        let querySnapshot = await Firestore.usersCollection().where("email", ">", "").get();
        // console.log(querySnapshot);
        if (!querySnapshot.empty) {
            let userCount = 0;
            let customerCount = 0;
            for (let doc of querySnapshot.docs) {
                let data = doc.data();
                if (data.email && !data.emailLower) {
                    console.log("No email lower for: " + data.email);
                    doc.ref.update({ emailLower: data.email.toLowerCase() });
                }
            }
            console.log(`Users: ${userCount}, Customers: ${customerCount}`);
        }
    };

    private onDeleteMemberListsClicked = async () => {
        let collectionRef = Firestore.categoriesCollection() as CollectionReference;

        let user = await this.userFromUsername(this.username);
        if (!user.uid) {
            return;
        }

        let querySnapshot = await collectionRef.where("uid", "==", user.uid).get();
        if (!querySnapshot.empty) {
            for (let doc of querySnapshot.docs) {
                log.verbose("Deleting: ");
                log.verbose(doc.data());
                doc.ref.delete();
            }
        }

        let imageFoldersRef = this.refFactory.foldersRefForTab(CustomLists.TabSelection.MY_LISTS);
        let snapshot = await imageFoldersRef.get();
        if (!snapshot.empty) {
            for (let doc of snapshot.docs) {
                log.verbose("Deleting: ");
                log.verbose(doc.data());
                doc.ref.delete();
            }
        }
    };

    private fetchSiteFolders = async () => {
        let siteImagesFolderCollectionRef = this.refFactory.foldersRefForTab(CustomLists.TabSelection.SITE_IMAGES);

        // let rootFolder = await this.props.rootStore.browseListsStore.fetchRootFolderForTab(
        //     CustomLists.TabSelection.SITE
        // );
        let querySnapshot = await (Firestore.categoriesCollection() as CollectionReference)
            .where("uid", "==", "site")
            .get();
        if (!querySnapshot.empty) {
            for (var categoryDoc of querySnapshot.docs) {
                let category = new CustomLists.Category(categoryDoc.data());
                category.key = categoryDoc.id;
                log.verbose(category);
                if (
                    category.language === LanguageType.ENGLISH &&
                    category.type === CustomLists.CategoryType.Vocabulary
                ) {
                    // Add new folder with category name and add reference to parent
                    let newFolderDoc = await siteImagesFolderCollectionRef.add({
                        title: category.title,
                        searchTitle: category.title.toLowerCase(),
                        parentKey: "oadhhbUaKK0clD7U5xZH",
                    });
                    let folderKey = newFolderDoc.id;

                    // tslint:disable-next-line:forin
                    for (var key in category.items) {
                        let item = category.items[key];
                        if (item.image !== undefined) {
                            let categoryImageKey = item.imageKey;
                            if (categoryImageKey !== undefined) {
                                log.verbose(`Updating image with key: ${categoryImageKey} to parent ${folderKey}`);
                                let imagesRef = Firestore.categoryImagesRef();
                                let doc = imagesRef.doc(categoryImageKey) as firebase.firestore.DocumentReference;
                                let result = await doc.update({
                                    parentKey: folderKey,
                                    parentTitle: category.title.toLowerCase(),
                                });
                                console.log(result);
                            }
                        }
                    }
                }
            }
        }
    };

    // private fetchSubfolder = async (key: string, parentKey: string) => {
    //     let docSnapshot = await this.refFactory
    //         .foldersRefForTab(CustomLists.TabSelection.SITE)
    //         .doc(key)
    //         .get();
    //     if (docSnapshot.data()) {
    //         let value = docSnapshot.data();
    //         let folder = new SiteListsFolder();
    //         folder.title = value.title;

    //         // Push new folder
    //         let siteImagesFolderCollectionRef =
    // this.refFactory.foldersRefForTab(CustomLists.TabSelection.SITE_IMAGES);
    //         let folderDocumentRef = await siteImagesFolderCollectionRef.add({
    //             title: folder.title,
    //             searchTitle: folder.title.toLowerCase(),
    //             parentKey: parentKey
    //         });
    //         let newImagesFolderKey = folderDocumentRef.id;

    //         let categoriesQuerySnapshot = await Firestore.categoriesRef()
    //             .where("parentKey", "==", key)
    //             .get();
    //     }
    // };

    private userFromUsername = async (username: string) => {
        let usersQuery = await (Firestore.usersCollection() as CollectionReference)
            .where("usernameLower", "==", username.toLowerCase())
            .get();
        if (usersQuery.docs.length > 0) {
            console.log(usersQuery.docs[0].data());
            return new User(usersQuery.docs[0].data());
        }
        return undefined;
    };

    private userFromEmail = async (username: string) => {
        let usersQuery = await (Firestore.usersCollection() as CollectionReference)
            .where("email", "==", username)
            .get();
        if (usersQuery.docs.length > 0) {
            console.log(usersQuery.docs[0].data());
            return new User(usersQuery.docs[0].data());
        }
        return undefined;
    };
    // private fetchCategory = (key: string, parentKey: string): Promise<any> => {
    //     return new Promise<any>(resolve => {
    //         // let refFactory = new CustomListsRefFactory(this.props.rootStore.userStore.authenticatedUser.uid);
    //         // let categoryRef = refFactory.itemRefForTab(
    //         //     CustomLists.TabSelection.SITE
    //         // ) as firebase.firestore.CollectionReference;
    //         // categoryRef
    //         //     .child(key)
    //         //     .once("value")
    //         //     .then((snap: firebase.database.DataSnapshot) => {
    //         //         let value = snap.val();
    //         //         if (value !== undefined) {
    //         //             resolve(value);
    //         //         } else {
    //         //             resolve(undefined);
    //         //         }
    //         //     });
    //     });
    // };

    // private fetchImageKey = async (id: string): Promise<string> => {
    //     var strlength = id.length;
    //     var strFrontCode = id.slice(0, strlength - 1);
    //     var strEndCode = id.slice(strlength - 1, id.length);
    //     var endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);
    //     let querySnapshot = await Firestore.categoryImagesRef()
    //         .where("image", ">=", id)
    //         .where("image", "<", endcode)
    //         .get();
    //     if (!querySnapshot.empty) {
    //         return querySnapshot.docs[0].id;
    //     } else {
    //         return undefined;
    //     }
    // };
}
