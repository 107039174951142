import { observer, inject } from "mobx-react";

import CategoryTranslationsBase from "./CategoryTranslationsBase";
import Firestore from "core/firebase/Firestore";
import { CategoryType } from "models";

@inject("rootStore")
@observer
export default class LessonTranslations extends CategoryTranslationsBase {
    constructor(props: any) {
        super(props);
        let ref = Firestore.categoriesCollection() as firebase.firestore.CollectionReference;
        this.englishQuery = ref
            .where("uid", "==", "site")
            .where("type", "==", CategoryType.Lesson)
            .where("language", "==", "english")
            .where("deleted", "==", false)
            .limit(100)
            .orderBy("searchTitle");

        this.translationTargetQuery = ref
            .where("uid", "==", "site")
            .where("type", "==", CategoryType.Lesson)
            .where("language", "==", this.selectedLanguage)
            .where("deleted", "==", false)
            .limit(100)
            .orderBy("searchTitle");

        this.getCategories();
    }
}
