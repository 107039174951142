import Activity from "./Activity";

export enum GameType {
    AngryWords = "Angry Words",
    Battleship = "Battleship",
    Bingo = "Bingo Caller",
    CardMatch = "Card Match",
    CrazyCups = "Crazy Cups",
    Englishopoly = "Englishopoly",
    Hangman = "Hangman",
    HiddenPicture = "Hidden Picture",
    HotOrCold = "Hot or Cold",
    MemoryWheel = "Memory Wheel",
    SharkBait = "Shark Bait",
    Speed = "Speed",
    TeamSpelling = "Team Spelling",
    TheWheel = "The Wheel",
    TicTacToe = "Tic-Tac-Toe",
    Wordscramble = "Word Scramble",
}

export default class Game extends Activity {
    public static allItems: Game[] = [
        new Game(GameType.AngryWords),
        new Game(GameType.Battleship),
        new Game(GameType.Bingo),
        new Game(GameType.CardMatch),
        new Game(GameType.CrazyCups),
        new Game(GameType.Hangman),
        new Game(GameType.HiddenPicture),
        new Game(GameType.HotOrCold),
        new Game(GameType.MemoryWheel),
        new Game(GameType.SharkBait),
        new Game(GameType.Speed),
        new Game(GameType.TeamSpelling),
        new Game(GameType.TheWheel),
        new Game(GameType.TicTacToe),
        new Game(GameType.Wordscramble),
    ];

    public type: GameType;

    protected get prefix() {
        return "games";
    }

    get supportsSecondActivity() {
        if (this.type === GameType.Battleship || this.type === GameType.HotOrCold) {
            return true;
        }
        return false;
    }
}
