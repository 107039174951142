import * as React from "react";

// Utils
import { Colors } from "../../core/utils/Colors";
import { StringUtils } from "../../core/utils/StringUtils";
import * as DeviceSize from "../../core/utils/DeviceSize";
import { DynamicTypographyType, DynamicTypography } from "core/utils/DynamicTypography";
import { RootStore } from "stores";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

export interface InfoRowProps extends React.Props<any> {
    rootStore: RootStore;
    imagePath: string;
    textHeader: string;
    text: string;
    variant: "rightImage" | "leftImage";
    height?: number;
    imageHeight?: number;
}

export default class InfoRow extends React.Component<InfoRowProps> {
    private styles = {
        container: { maxWidth: 1000, minHeight: 350, padding: "0px 60px 0px 60px" },
        titleText: {
            fontSize: 20,
            fontWeight: "bold",
            textAlign: "left",
            padding: "60px 5px 10px 5px",
        },
        text: {
            color: Colors.darkGrey,
            fontSize: 14,
            textAlign: "left",
            padding: 5,
        },
        image: {
            height: 350,
            padding: 5,
        },
    };

    render() {
        // if (this.props.imageHeight !== undefined) {
        //     this.styles.image.height = this.props.imageHeight;
        // }

        let image = require(`../../assets/images/homePage/${this.props.imagePath}`);
        let backgroundColor;
        let content;
        if (this.props.variant === "leftImage") {
            backgroundColor = Colors.lightGrey;
            content = (
                <>
                    <DeviceSize.Default>
                        {this.renderImage(image)}
                        {this.renderText()}
                    </DeviceSize.Default>
                    <DeviceSize.Mobile>
                        {this.renderText()}
                        {this.renderImage(image)}
                    </DeviceSize.Mobile>
                </>
            );
        } else {
            backgroundColor = Colors.veryLightGrey;
            content = (
                <>
                    {this.renderText()}
                    {this.renderImage(image)}
                </>
            );
        }

        return (
            <Row center="xs" style={{ backgroundColor: backgroundColor }}>
                <Col xs={12} style={this.styles.container}>
                    <Row onClick={this.onClick} style={{ cursor: "pointer" }}>
                        {content}
                    </Row>
                </Col>
            </Row>
        );
    }

    private renderText() {
        return (
            <Col xs={12} sm={6}>
                <Row>
                    <DynamicTypography variant={DynamicTypographyType.Subheading} style={this.styles.titleText}>
                        {StringUtils.createHighlightedText(this.props.textHeader, Colors.lightBlue)}
                    </DynamicTypography>
                </Row>
                <Row>
                    <DynamicTypography variant={DynamicTypographyType.H4} style={this.styles.text}>
                        {this.props.text}
                    </DynamicTypography>
                </Row>
            </Col>
        );
    }

    private renderImage(image: any) {
        return (
            <Col xs={12} sm={6}>
                <Row center="xs" middle="xs">
                    <img src={image} style={this.styles.image} alt="" />
                </Row>
            </Col>
        );
    }

    private onClick = () => {
        this.props.rootStore.setRoute("flashcards/interactive-flashcards");
    };
}
