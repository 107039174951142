import { FlashcardActivityType, WorksheetType, GameType, ToolType, QuizType } from "../../models";
import { InteractiveWorksheetType } from "./InteractiveWorksheet";

export type ActivityType =
    | QuizType
    | GameType
    | WorksheetType
    | ToolType
    | FlashcardActivityType
    | InteractiveWorksheetType;

export default class Activity {
    public activityType: ActivityType;

    constructor(type: ActivityType) {
        this.activityType = type;
    }

    get navigationPath(): string {
        return this.prefix ? `/${this.prefix}/` + this.cleanedType : "/" + this.cleanedType;
    }

    get imagePath() {
        return this.prefix ? `${this.prefix}/` + this.cleanedType + ".png" : this.cleanedType + ".png";
    }

    get swfFile() {
        return this.cleanedType + ".swf";
    }

    get cleanedType() {
        return this.activityType
            .split(" ")
            .join("-")
            .toLowerCase();
    }

    get supportsSecondActivity() {
        return false;
    }

    protected get prefix() {
        // implemented in subclass
        return undefined;
    }
}
