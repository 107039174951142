import { log } from "../../";

import * as React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

// Material UI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

// Stores
import UserStore from "../../stores/UserStore";

// Models
import { AccountRole, ManagedUser } from "../../models";
import { BillingFrequency, SubscriptionDetailsStatus } from "../../models";

// Components
import { StripeCheckoutButton } from "./StripeCheckoutButton";
import BillingFrequencyRadios from "./BillingFrequencyRadios";
import { ConfirmationMessage } from "../../components/MessageBoxDialogs";

// Utils
import Utils from "../../core/utils/Utils";
import { Colors } from "../../core/utils/Colors";
import { strings } from "../../core/localizedStrings";
import * as PaymentUtils from "../../core/utils/PaymentUtils";
import { Fonts } from "core/utils/Fonts";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

export interface SubscriptionBlockProps extends React.Props<any> {
    store: UserStore;
}

@observer
export default class SubscriptionBlock extends React.Component<SubscriptionBlockProps> {
    @observable private selectedBillingFrequency = BillingFrequency.Monthly;

    render() {
        log.verbose("Rendering SubscriptionBlock");

        let content;
        if (this.props.store.subscriptionDetailStatus === SubscriptionDetailsStatus.NotLoaded) {
            log.verbose("Not loaded");
            content = <CircularProgress />;
        } else if (this.props.store.user instanceof ManagedUser) {
            content = this.renderManagedUserSubscriptionDetails();
        } else {
            content = this.renderSubscriptionDetails(this.selectedBillingFrequency);
        }
        return (
            <div>
                <Row middle="xs" style={{ height: 47 }}>
                    <Col>
                        <Typography variant="h5">{strings.subscription}</Typography>
                    </Col>
                </Row>
                <Divider style={{ marginRight: 20 }} />
                {content}
            </div>
        );
    }

    private onCreateCustomer = (token: any) => {
        this.props.store.createStripeSubscription(this.selectedBillingFrequency, token.id);
    };

    private onSubscribeImmediatelyClicked = () => {
        this.props.store.createStripeSubscription(this.selectedBillingFrequency);
    };

    private onRestartSubscriptionClicked = () => {
        let customerDetails = this.props.store.customerDetails;
        if (customerDetails.noCardForSubscription) {
            this.props.store.rootStore.showInfoMessage({ text: strings.pleaseAddCardForRestart });
        } else {
            let message = new ConfirmationMessage(
                strings.formatString(
                    strings.confirmRestartSubscription,
                    customerDetails.subscription.currentPeriodEnd.toLocaleDateString()
                ) as string,
                undefined,
                (result, shouldQuell) => {
                    if (result === "yes") {
                        this.props.store.restartSubscription();
                    }
                }
            );
            this.props.store.rootStore.showConfirmationMessage(message);
        }
    };

    @action
    private onBillingFrequencyChanged = (value: BillingFrequency) => {
        this.selectedBillingFrequency = value;
        // if (this.props.store.customerDetails) {
        //     // this.props.store.setPendingSubscription(value, subscription.stripeDetails.quantity);
        // } else {
        //     this.selectedBillingFrequency = value;
        // }
    };

    private onCancelSubscriptionClicked = () => {
        this.props.store.cancelSubscription();
    };

    private renderSubscriptionDetails = (selectedBillingFrequency: BillingFrequency) => {
        switch (this.props.store.subscriptionDetailStatus) {
            case SubscriptionDetailsStatus.Updating:
                return this.renderProgress();

            case SubscriptionDetailsStatus.None:
                return this.renderNoSubscriptionBlock(selectedBillingFrequency);

            case SubscriptionDetailsStatus.Loaded:
                if (
                    this.props.store.user.canceledSubscription ||
                    !this.props.store.customerDetails.subscription ||
                    this.props.store.customerDetails.subscription.cancelAtPeriodEnd
                ) {
                    return this.renderCanceledSubscriptionBlock(selectedBillingFrequency);
                } else {
                    return this.renderActiveSubscriptionBlock();
                }

            default:
                log.error("Unknown subscription status.");
                break;
        }
        return null;
    };

    private renderManagedUserSubscriptionDetails = () => {
        switch (this.props.store.subscriptionDetailStatus) {
            case SubscriptionDetailsStatus.Updating:
                return this.renderProgress();

            case SubscriptionDetailsStatus.None:
                return (
                    <Typography variant="subtitle1" style={{ paddingTop: 5 }}>
                        {strings.notActive}
                    </Typography>
                );

            case SubscriptionDetailsStatus.Loaded:
                if (this.props.store.customerDetails.accountExpired) {
                    return (
                        <Typography variant="subtitle1" style={{ color: Colors.red, paddingTop: 5 }}>
                            {strings.expired}
                        </Typography>
                    );
                } else {
                    return (
                        <>
                            <Typography variant="subtitle1" style={{ paddingTop: 5 }}>
                                {`${strings.active} ${strings.managedSubscription}`}
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    let messageText = strings.removeSubscriptionManagerConfirmation;
                                    let message = new ConfirmationMessage(messageText, undefined, (result) => {
                                        if (result === "yes") {
                                            this.props.store.deleteManagedUser(this.props.store.user as ManagedUser);
                                        }
                                    });
                                    this.props.store.rootStore.showConfirmationMessage(message);
                                }}
                            >
                                {strings.disconnectFromAccountManager}
                            </Button>
                        </>
                    );
                }

            default:
                log.error("Unknown subscription status");
                break;
        }
        return null;
    };

    private renderProgress() {
        return (
            <Row style={{ margin: 20 }} center="xs">
                <CircularProgress />
            </Row>
        );
    }

    private renderSubscriptionType() {
        console.log("renderSubscriptionType");
        return (
            <>
                <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
                    <Typography>
                        {`${strings.subscriptionType}: ${this.subscriptionTypeStringForRole(this.props.store.role)}`}
                    </Typography>
                </Row>
                {this.props.store.user.expiration && (
                    <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
                        <Typography>
                            {strings.formatString(
                                strings.expirationDateFormat,
                                this.props.store.user.expiration.toDate().toLocaleDateString()
                            )}
                        </Typography>
                    </Row>
                )}
            </>
        );
    }

    private renderNoSubscriptionBlock(selectedBillingFrequency: BillingFrequency) {
        let userDetails = this.props.store.user;

        let totalDue = PaymentUtils.calculateTotal(
            selectedBillingFrequency,
            this.props.store.user.managedTeachersCount,
            userDetails.managedStudentGroupsCount
        );
        let subtotal = PaymentUtils.subtotal(
            selectedBillingFrequency,
            this.props.store.user.managedTeachersCount,
            userDetails.managedStudentGroupsCount
        );
        let discount = PaymentUtils.discount(
            selectedBillingFrequency,
            this.props.store.user.managedTeachersCount,
            userDetails.managedStudentGroupsCount
        );
        let discountPercentage = PaymentUtils.getDiscountMultiplier(this.props.store.user.managedTeachersCount) * 100;

        let { customerDetails } = this.props.store;
        return (
            <div style={{ fontFamily: Fonts.Roboto }}>
                {this.renderSubscriptionType()}
                {
                    // Only show if trial
                    this.props.store.role === AccountRole.TRIAL ? (
                        <Row style={{ paddingTop: 20 }}>
                            <Typography
                                variant="subtitle1"
                                style={{ color: Colors.red, paddingTop: 20, paddingLeft: 5 }}
                            >
                                {strings.startSubscriptionAfterTrial}
                            </Typography>
                            <Typography variant="subtitle1" style={{ color: Colors.red, paddingLeft: 5 }}>
                                {strings.notChargedUntilTrialComplete}
                            </Typography>
                        </Row>
                    ) : null
                }
                <BillingFrequencyRadios
                    userStore={this.props.store}
                    onBillingFrequencyChangedCallback={this.onBillingFrequencyChanged}
                />
                <Row end="xs">
                    <Col style={{ paddingRight: 40 }}>
                        <Row>
                            <Table>
                                <TableBody>
                                    {
                                        // Only show if have discount
                                        userDetails.managedTeachersCount >= 2 ? (
                                            <TableRow style={{ height: 25 }}>
                                                <TableCell>{strings.subtotal}</TableCell>
                                                <TableCell align={"right"}>
                                                    {Utils.centsToDollarString(subtotal)}
                                                </TableCell>
                                            </TableRow>
                                        ) : null
                                    }
                                    {
                                        // Only show if have discount
                                        userDetails.managedTeachersCount >= 2 ? (
                                            <TableRow style={{ height: 25 }}>
                                                <TableCell>
                                                    {strings.formatString(strings.discount, discountPercentage)}
                                                </TableCell>

                                                <TableCell align={"right"}>
                                                    {Utils.centsToDollarString(discount)}
                                                </TableCell>
                                            </TableRow>
                                        ) : null
                                    }
                                    <TableRow style={{ height: 25 }}>
                                        <TableCell>{strings.totalDue}</TableCell>
                                        <TableCell align={"right"} style={{ color: Colors.red }}>
                                            {Utils.centsToDollarString(totalDue)}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Row>
                        <Row end="xs" style={{ paddingTop: 10 }}>
                            {customerDetails && customerDetails.card && customerDetails.card.id ? (
                                <Button variant="contained" onClick={this.onSubscribeImmediatelyClicked}>
                                    {strings.subscribe}
                                </Button>
                            ) : (
                                <StripeCheckoutButton
                                    label={strings.subscribe}
                                    isUpdate={false}
                                    onToken={this.onCreateCustomer}
                                    totalAmount={totalDue}
                                    email={this.props.store.user.email}
                                />
                            )}
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }

    @action
    private renderActiveSubscriptionBlock() {
        let userStore = this.props.store;
        let customerDetails = userStore.customerDetails;
        if (customerDetails.subscription === undefined || userStore.customerDetails === undefined) {
            return null;
        }

        let pendingSubscription = this.props.store.pendingSubscription;
        this.selectedBillingFrequency = pendingSubscription
            ? pendingSubscription.billingFrequency
            : customerDetails.subscription.billingFrequency;

        if (this.props.store.customerDetails.subscription.status === "past_due") {
            return (
                <div>
                    <div style={{ paddingLeft: 10 }}>
                        <Row middle="xs">
                            <Typography>{this.description(customerDetails.subscription.billingFrequency)}</Typography>
                        </Row>
                        <Row middle="xs">
                            <Typography variant="body1">
                                {this.teacherCount(customerDetails.subscription.quantity)}
                            </Typography>
                        </Row>
                        <Row middle="xs">
                            <Typography variant="body1" style={{ color: "red" }}>
                                {strings.subscriptionIsPastDue}
                            </Typography>
                        </Row>
                    </div>
                    {this.renderCancelSubscriptionButton()}
                </div>
            );
        } else {
            return (
                <div>
                    <div style={{ paddingLeft: 10 }}>
                        <Row middle="xs">
                            <Typography>{this.description(customerDetails.subscription.billingFrequency)}</Typography>
                        </Row>
                        <Row middle="xs">
                            <Typography variant="body1">
                                {this.teacherCount(customerDetails.subscription.quantity)}
                            </Typography>
                        </Row>
                        {this.renderNextBillingDate()}
                    </div>
                    {this.renderCancelSubscriptionButton()}
                </div>
            );
        }
    }

    @action
    private renderCanceledSubscriptionBlock(selectedBillingFrequency: BillingFrequency) {
        let margin = "5px 0 5px 0";
        if (this.props.store.customerDetails.subscription) {
            return (
                <div style={{ paddingLeft: 10 }}>
                    <Row middle="xs">
                        <Typography>
                            {this.description(this.props.store.customerDetails.subscription.billingFrequency)}
                        </Typography>
                    </Row>
                    <Row middle="xs" style={{ margin: margin, color: Colors.red }}>
                        <Typography>
                            {strings.formatString(
                                strings.expirationDateFormat,
                                this.props.store.customerDetails.subscription.currentPeriodEnd.toLocaleDateString()
                            )}
                        </Typography>
                    </Row>
                    {this.renderRestartSubscriptionButton()}
                </div>
            );
        } else {
            return this.renderNoSubscriptionBlock(selectedBillingFrequency);
        }
    }

    private renderNextBillingDate() {
        let customerDetails = this.props.store.customerDetails;
        var nextBillAmount = !this.props.store.user.canceledSubscription
            ? Utils.centsToDollarString(customerDetails.subscription.amountDue)
            : undefined;

        var billingDateText;
        var billingDateTextColor = Colors.black;
        var endDate = customerDetails.subscription.currentPeriodEnd;
        if (customerDetails.accountExpired) {
            billingDateTextColor = Colors.red;
            billingDateText = `${strings.expired}: ${endDate.toLocaleDateString()}`;
        } else if (this.props.store.user.canceledSubscription) {
            billingDateText = `${
                strings.expires
            }: ${customerDetails.subscription.currentPeriodEnd.toLocaleDateString()}`;
        } else {
            billingDateText = strings.formatString(
                strings.nextBillingDate,
                endDate.toLocaleDateString(),
                nextBillAmount
            );
        }

        return (
            <Row style={{ margin: "5px 0 5px 0", color: billingDateTextColor }}>
                <Typography variant="body1">{billingDateText}</Typography>
            </Row>
        );
    }

    private renderRestartSubscriptionButton = () => {
        let style = { margin: "5px 0 5px 0" };
        return (
            <Row style={style} middle="xs">
                <Col style={{ paddingLeft: 7 }}>
                    <Row>
                        <Button variant="contained" onClick={this.onRestartSubscriptionClicked}>
                            {strings.restartSubscription}
                        </Button>
                    </Row>
                </Col>
            </Row>
        );
    };

    private renderCancelSubscriptionButton = () => {
        let style = { margin: "5px 0 5px 0" };
        return (
            <Row style={style} middle="xs">
                <Col style={{ paddingLeft: 7 }}>
                    <Row>
                        <Button variant="contained" onClick={this.onCancelSubscriptionClicked}>
                            {strings.cancelSubscription}
                        </Button>
                    </Row>
                </Col>
            </Row>
        );
    };

    // Helpers
    private description = (billingFrequency: BillingFrequency) => {
        let billingFrequencyDescription =
            billingFrequency === BillingFrequency.Monthly ? strings.monthly : strings.annual;
        return strings.formatString(strings.subscriptionDescriptionFormat, billingFrequencyDescription);
    };

    private teacherCount = (quantity: number) => {
        if (quantity === 1) {
            return strings.formatString(strings.teacherCountFormat, strings.singleTeacher);
        } else {
            return strings.formatString(strings.teacherCountFormat, quantity);
        }
    };

    private subscriptionTypeStringForRole(role: AccountRole) {
        switch (role) {
            case AccountRole.ADMIN:
                return strings.admin;
            case AccountRole.TRIAL:
                return strings.premiumTrial;
            case AccountRole.BASIC:
                return strings.basic;
            case AccountRole.PREMIUM:
                return strings.premium;
            default:
                return strings.basic;
        }
    }
}
