export let chinese = {
    absent: "没有此项资料",
    absentStudentsFormat: "{0} 没有此项资料",
    accountRegistration: "户口注册",
    active: "活跃的",
    activityPreview: "预览活动",
    activityPreviewMessage: "请登录或注册免费帐户",
    add: "加入",
    addAccount: "加入账户",
    addCard: "加入卡",
    addCards: "加入多过一张卡",
    addFolder: "加入文件夹",
    addGroupStudentAccount: "添加学生组帐户",
    addKeyword: "加入关键词",
    addName: "加入名字",
    addTeacherAccount: "加入老师账户",
    addToFavorites: "加入最爱",
    addWithPlus: "+ 加入",
    additionalResources: "额外资源",
    admin: "管理员",
    affirmative: "肯定的",
    afterTrial: "在30天试用期后，您可以继续使用该站点作为基本成员，或升级回高级账户。",
    all: "全部",
    allFieldsRequired: "每一栏都需要填上",
    allowedToChangePassword: "老师可以改变密码",
    annual: "年度的",
    annualSubscription: "年度的订阅",
    answer: "答案",
    audioClipLanguageLabel: "音频档案语言",
    basic: "基本的",
    basicMemberCredits: "**基本会员每月可获得8个积分。 使用所有积分后，站点访问权限将限制在下个月的第一天。",
    billedAnnually: "每年付款",
    billedAnnuallyFormat: "每年付款 ({0})",
    billedMonthly: "每月付款",
    billedMonthlyFormat: "每月付款 ({0})",
    billingFrequency: "付款频率",
    boy: "男孩子",
    boys: "男孩子",
    browseAllCategories: "浏览/编辑类别",
    browseAllLists: "浏览/编辑清单",
    cancel: "取消",
    cancelSubscription: "取消订阅",
    cannotAddManagedAccountsInTrial: "在试用期内无法添加托管帐户",
    cannotBeEmpty: "这一栏不能为空白",
    card: "卡",
    cardLower: "卡",
    cardsLower: "卡",
    category: "类别",
    category2: "类别2",
    categoryCharactersNotSupported: "此类别包含此活动尚不支持的字符。",
    categoryTypeNotSupported: "此类别尚不支持此类型。",
    changeEditStudents: "更改/编辑学生类别",
    changePassword: "更改密码",
    checkEmailForVerifcation: "请检查您的电子邮件以完成帐户的激活。",
    chinese: "中文",
    className: "班别名称",
    clear: "清除",
    clearFilters: "清除过滤器",
    clearSelectedImage: "清除所选图像",
    clearSelectedImages: "清除所选图像",
    clickHereTo: "没有账户？按这里",
    confirmChangeCategory: "更改类别将重置活动。 你确定要改变吗？",
    confirmChangeClassList: "更改学生列表将重置活动。 你确定要改变吗？",
    confirmChangeTeamNumber: "更改团队数量将重置活动。 你确定要改变吗？",
    confirmRestartSubscription: "您确定要重新开始订阅吗？\n        \n我们不会向您的信用卡收费，直至 {0}.",
    contact: "联系",
    contactAdminMessage: "如果此问题仍然存在，请与网站管理员联系。",
    contactDescription: "如果您有任何问题或疑问，请告知我们。只需在下方发送消息，我们会尽快给您回复。",
    contactFormSubmitted: "谢谢，您的邮件已发送。",
    copy: "复制",
    copyLower: "复制",
    createCardFromSelectedImage: "从所选图像创建卡",
    createCardsFromSelectedImages: "从所选图像 {0} 创建卡",
    createCopyIn: "复制到:",
    createCustomLists: "创建自定义列表",
    createNewList: "创建新列表",
    createSelectCategory: "创建或选择一个类别",
    createSelectClassList: "创建或选择学生列表",
    createStudentNameLists: "创建学生姓名列表",
    createTeams: "创建团队",
    createdBy: "由制作",
    createdOn: "制作日期",
    creditCard: "信用卡",
    creditNeeded: "每次使用1个积分",
    currentSubscriptionRequiredForManagedAccounts: "需要有效订阅。 请重新启动订阅以添加其他帐户。",
    customizeHeader: "{{个人化}} 适合所有课堂",
    customizeText:
        "获取一个简单的词汇表或问题列表，并在几秒钟内将其转化为引人入胜的活动。 创建列表后，它可以与整个站点中的任何活动一起使用。",
    dataBeingMigratedFromBarryFunEnglish:
        "您的帐户数据正在从BarryFunEnglish迁移。这项动作只需要进行一次。 根据数据量，这可能需要几秒到几个小时。 如果几小时后仍未看到所有数据，请与网站管理员联系。",
    delete: "删除",
    deleteCategoryConfirmation: "您确定要永久删除此类别吗？",
    deleteFavoriteConfirmation: "您确定要从收藏夹中删除此内容吗？",
    deleteFolderConfirmation: "您确定要永久删除此文件夹及其所有内容吗？",
    deleteStudentListConfirmation: "您确定要删除此列表吗？",
    discount: "折扣 ({0}%):",
    discounts: "折扣:",
    done: "已完成",
    dutch: "荷兰语",
    edit: "编辑",
    editProfile: "编辑档案",
    email: "电邮地址",
    emailAlreadyRegistered: "此电邮地址已被登记",
    emailOrUsername: "电邮地址或用户名",
    emailRequiredError: "你必须输入用户名",
    emailSentTo: "电邮已发给 {0}.",
    endGame: "结束游戏",
    english: "英语",
    enterEmailUsedToRegister: "请输入您用于注册该站点的电子邮件地址:",
    enterIncorrectAnswers: "输入我的错误答案",
    enterQuestion: "输入问题",
    enterText: "输入文字:",
    enterValidEmail: "请输入有效的电子邮件地址.",
    enterVocabAndQuestion: "输入词汇单词和问题",
    enterVocabulary: "输入词汇",
    existingAccount: "现有的账户",
    expirationDateFormat: "过期时间: {0}",
    expired: "已过期",
    expires: "正在过期",
    expressionInputTextField1Label: "文字字段 1（问题）",
    expressionInputTextField2Label: "文字字段 2 （答案）",
    favorites: "我的最爱",
    flashcards: "抽认卡",
    folderName: "文件夹名字:",
    forClassroomHeader: "为{{班房}}设计",
    forClassroomText:
        "游戏和活动专为在课堂上使用而设计，适合大班或小班。 游戏可以有两到五个团队，可以输入学生名单以供随机选择。",
    forgotPassword: "忘记密码?",
    free: "免费",
    french: "法语",
    games: "游戏",
    gender: "性别",
    german: "德语",
    girl: "女孩子",
    girls: "女孩子",
    girlsVsBoys: "女孩子对男孩子",
    greek: "希腊语",
    groupNumberFormat: "小组",
    homePageContactMessage: "有什么 {{问题}} 要问我们吗?",
    imageOverlayGraphic: "图像叠加图形",
    imageWithText: "单个文本字段的图像",
    imageWithTextDescription: "这些列表非常适合练习词汇，阅读和口语。 他们几乎可以在网站上进行任何活动。",
    imageWithTwoText: "图像有两个文本字段",
    imageWithTwoTextDescription: "这些列表用于询问有关图像的问题或用于在两种语言之间进行翻译",
    imagesSelectorInstructions: "选择或上传图片以创建卡片：",
    interactiveFlashcards: "交互式抽认卡",
    invalidEmail: "无效的邮件地址",
    invalidPassword: "密码长度必须至少为6个字符",
    invalidUsername: "无效的用户名",
    invalidUsernameOrPassword: "您输入的用户名或密码无效",
    italian: "意大利语",
    japanese: "日语",
    keywordsLabel: "关键词",
    korean: "韩语",
    language: "语言",
    languageTitle: "语言",
    learn: "学习",
    learnDescription: "介绍和回顾词汇和问题",
    lesson: "课堂",
    lessonDescription: "单个图像和图像与两个文本字段的混合",
    lessonTypeSelectorTitle: "您想练习本课程中的词汇或问题吗？",
    listAccessibility: "列出可访问性",
    listTitleLabel: "列出题目",
    listTypeTitle: "列出类型",
    loggingIn: "正在登入",
    loginToCopy: "您必须登录才能复制列表",
    loginToCreateFolders: "您必须登录才能创建文件夹",
    loginToCreateLists: "您必须登录才能创建自定义列表",
    loginToEditLists: "您必须登录才能编辑列表",
    loginToFavorite: "您必须登录才能保存收藏夹",
    logout: "登出",
    mainPageText: "任何课程的自定义语言学习活动",
    managedAccounts: "已管理的账户",
    managedAccountsDescription: "如果您希望在一个订阅下拥有多个成员资格，请添加管理帐户。 折扣根据总帐户数量提供。",
    maxFilesUploadAttempt: "您一次只能上传最多{0}个文件。",
    membershipTypes: "会员类型",
    message: "信息",
    monthly: "每月",
    monthlySubscription: "每月订阅",
    move: "移动",
    moveFolderTo: "移动文件夹至:",
    moveListTo: "移动清单至:",
    multipleChoiceInstructions: "通过多项选择活动，您希望将哪些用于错误答案？",
    multipleLanguageSupport: "支持多种语言",
    mustHaveOneIncorrectAnswers: "您必须输入至少一个不正确的答案。",
    myAccount: "我的账户",
    myClasses: "我的课堂",
    myImages: "我的图像",
    myLists: "我的清单",
    name: "名字",
    negative: "负面的",
    newFolder: "新的文件夹",
    nextBillingDate: "下一个结算日期: {0} ({1})",
    no: "不是",
    noAcceptableFilesFound: "找不到有效文件。 文件的最大大小限制为{0} MB。",
    noCardOnFile: "没有卡存档",
    noCreditCardRequired: "无需信用卡。",
    noRemainingCredits: "本月没有剩余的积分。注册高级帐户以进行无限制访问。",
    none: "没有",
    notActivated: "未激活",
    notActive: "不活跃",
    notChargedUntilFormat: "信用卡费用将不会被收取，直到 {0}.",
    notChargedUntilTrialComplete: "在试用期结束前，我们不会向您的信用卡收费。",
    numberOfTeams: "团队数目",
    off: "已关",
    ok: "Ok",
    on: "已开",
    oneRemainingCredit: "你这个月还剩1个积分。",
    or: "或者",
    pageNotFound: "找不到此页面",
    password: "密码",
    passwordLengthError: "密码必须至少包含6个字符。",
    passwordRequiredError: "密码是必需的",
    passwordResetEmailHasBeenSendMessage: "请检查您的电子邮件，以获取重置密码的链接。",
    pay: "付款",
    payBalance: "付款余额",
    paymentMethod: "付款方式",
    pendingSubscription: "待定订阅",
    play: "开始游戏",
    playDescription: "刺激的大小班游戏",
    pleaseAddCardForRestart: "您必须在重新开始订阅之前添加信用卡。",
    portuguese: "葡萄牙文",
    practice: "练习",
    practiceDescription: "交互式工作表",
    premium: "高级用户",
    premiumPriceFormatMonth: "${0}/每月",
    premiumPriceFormatYear: "${0}/每年",
    premiumTrial: "高级用户(试用)",
    preview: "预览",
    price: "价格",
    print: "打印",
    printDescription: "打印抽认卡或工作表以进行离线练习",
    printableFlashcards: "可打印的抽认卡",
    private: "私人的",
    problemSubmittingContactForm: "发送邮件时出现问题。",
    problemUpdatingUserErrorMessage: "更新用户详细信息时出错。",
    problemUsingCredit: "使用您的积分时出现问题。",
    profile: "个人档案",
    proratedAmountDue: "现在所欠的金额：0}",
    public: "公开的",
    publicLists: "社区名单",
    quellMessage: "不要再显示这个信息",
    question: "问题",
    questions: "问题",
    randomize: "随意化",
    recent: "最近的",
    register: "登记",
    registerUsernamePassword: "利用用户名或密码登记",
    rejectedFileFormat: "{0}文件并未上传。文件可能太大或文件类型错误。 仅接受500 KB或更小的图像文件。",
    rejectedFilesFormat: "{0}文件并未上传。文件可能太大或文件类型错误。 仅接受500 KB或更小的图像文件。",
    remainingCreditsFormat: "你这个月剩下{0}个积分。",
    removeFromFavorites: "从收藏夹中删除",
    removeManagedUserAccountConfirmation: "此帐户将被删除，用户将不再被允许登录。 您确定要永久删除此帐户吗？",
    resendEmail: "重发电子邮件",
    reset: "重设",
    restartSubscription: "重新订阅",
    restartSubscriptionMessage: "请为您的订阅选择结算频率",
    review: "评论",
    reviewDescription: "有关听力，阅读，写作和口语的多项选择测验",
    russian: "俄罗斯语",
    sameAsTextField1Label: "与文本字段1相同",
    sampleClass: "试学课堂",
    save: "保存",
    search: "寻找",
    select: "选择...",
    selectACategory: "选择一个类别",
    selectFolderToAddFavoriteTo: "选择要添加收藏夹的文件夹:",
    selectStudentList: "选择学生列表",
    showCategoryDetails: "显示详细资料",
    signIn: "登入",
    signInRegister: "登入/登记",
    signInUsernamePassword: "用用户名/密码登录",
    signUpForFree: "免费注册",
    signUpForFreeMessage: "现在注册，可以免费享用高级帐户30天！",
    singleTeacher: "一位老师",
    singleText: "单个文本字段",
    singleTextDescription: "这些列表非常适合阅读和拼写练习。 它们对于不需要图像的活动也很有用。",
    siteImages: "网站图片",
    siteLists: "网站列表",
    sortByPopularity: "受欢迎程度",
    sortByRecentlyCreated: "最近创建",
    sortByRelevance: "相关度",
    sortByTitle: "题目",
    sortByTypeTitle: "以分类",
    spanish: "西班牙语",
    start: "开始",
    startSubscription: "开始订阅",
    startSubscriptionAfterTrial: "试用完毕后，开始订阅以继续使用“高级”会员资格。",
    studentGroup: "学生团体",
    studentGroups: "学生团体",
    studentList: "学生名单",
    subject: "科目",
    submit: "提交",
    subscribe: "订阅",
    subscription: "订阅",
    subscriptionDescriptionFormat: "订阅类型: {0}",
    subscriptionType: "订阅类型",
    subtotal: "小计:",
    support: "联系技术支持部",
    teacher: "老师",
    teacherCountFormat: "老师数目: {0}",
    teachers: "老师",
    teachingTools: "教学工具",
    teamNumberFormat: "团队{0}",
    tenOrMore: "十个或更多",
    textField1Label: "(问题)",
    textField2Label: "(答案)",
    textToSpeechSupportFormat: "使用文本到语音技术自动创建音频剪辑",
    thousandsOfHeader: "{{千个}} 类别可供选择",
    thousandsOfText: "不想创建自己的类别？ 浏览由KinteractiveLearning社区创建的数千个类别。 每天都会加入更多！",
    threeOrMore: "三个或以上",
    titleCannotBeEmptyInfoMessage: "标题不能为空。请输入标题。",
    tools: "工具",
    toolsDescription: "团队和团队制作者，随机学生选择器，计时表和记分牌",
    totalDue: "总欠额:",
    totalManagedAccounts: "以管理的账户总数目:",
    totalStudentsFormat: "{0} 学生",
    turkish: "土耳其文",
    twoTextOnly: "两个文本字段",
    twoTextOnlyDescription: "这些列表用于提出不需要和图像的问题，或用于在两种语言之间进行翻译的问题。",
    type: "类型",
    unlimited: "无限的",
    update: "更新",
    updateEmailError: "更新电子邮件地址时出错。",
    uploadImages: "上传图片",
    uploadImagesDropzoneInstructions: "删除图像，或单击以浏览您的计算机。",
    uploadMessage: "正在上传{0}张图片。",
    uploadProfileImageError: "上传个人资料图片时出错。",
    useAsVocabulary: "添加到词汇表",
    useCredit: "使用积分",
    useOtherAnswersAsIncorrect: "使用其他卡以改正答案",
    username: "用户名",
    usernameOptional: "用户名 (可选项)",
    usernameRequiredError: "用户名必需填上。",
    usernameTaken: "此用户名已被占用。",
    usernameUnavailable: "此用户名不可用",
    vocabulary: "生词",
    worksheetCreators: "工作表创建工具",
    worksheets: "工作表",
    yes: "是的",
    page: "",
    misses: "",
    backToWorksheets: "",
    options: "",
    randomizeAndReset: "",
    introduce: "",
    quiz: "",
    changeCategory: "",
    interactive: "",
    printable: "",
    createSelectCategoryDescription: "",
    removeAllLetters: "",
    allowCardsToBeFlipped: "",
    selectCategoryBeforeActivity: "",
    getStarted: "",
    requiresFlash: "",
    onlySupportsLatinBasedLanguages: "",
    upgradeToPremium: "",
    startReview: "",
    numberOfGroups: "",
    hideAnswer: "",
    autoPlayAudio: "",
    imageBank: "",
    doubleSidedCards: "",
    ignoreFlippedCardsForReview: "",
    soft: "",
    medium: "",
    strong: "",
    continuePlaying: "",
    languageNotSupported: "",
    createLink: "",
    pinActivityToDashboard: "",
    enterName: "",
    createStudentLink: "",
    expiration: "",
    oneDay: "",
    oneWeek: "",
    never: "",
    link: "",
    copyToClipboard: "",
    pinnedActivities: "",
    studentActivityLinks: "",
    numberOfCards: "",
    matchType: "",
    imageType: "",
    otherOptions: "",
    wordImage: "",
    imageImage: "",
    wordWord: "",
    activity: "",
    seeLinkVisitors: "",
    recentHistory: "",
    startTime: "",
    completedTime: "",
    results: "",
    iDontKnow: "",
    iKnowTheAnswer: "",
    seconds: "",
    minutes: "",
    hours: "",
    pause: "",
    set: "",
    audioHeading: "",
    playAudioOnCorrect: "",
    playAudioOnWrong: "",
    answerTypeHeading: "",
    multipleChoiceAnswerTypeOption: "",
    rightOrWrongAnswerTypeOption: "",
    showNumbersHeading: "",
    showNumbersMultipleChoice: "",
    timerHeading: "",
    showTimerMultipleChoice: "",
    game: "",
    correct: "",
    wrong: "",
    teams: "",
    imagesInGrid: "",
    multipleChoiceOptions: "",
    playAudioOnSelection: "",
    classicModeOptions: "",
    selectAll: "",
    flipCardsToFront: "",
    flipCardsToBack: "",
    shuffle: "",
    showAllCards: "",
    bonusRound: "",
    crazyCupsIntroMessage: "",
    crazyCupsBonusRoundMessage: "",
    goodJob: "",
    sorryYouMissedIt: "",
    teamReadyFormat: "",
    readyToTryAgain: "",
    cupsSpeedAdjustment: "",
    textOnly: "",
    imageText: "",
    imageOnly: "",
    hideShips: "",
    useClassicMode: "",
    classicModeDescription: "",
    stop: "",
    bullsEye: "",
    removeNameOnceSelected: "",
    clearDartAfterThrow: "",
    stopBoardAfterThrow: "",
    playSoundEffect: "",
    spinForPoints: "",
    showImageProfileImage: "",
    cardType: "",
    gridSize: "",
    cardsPerPage: "",
    noListsGetStarted: "",
    noImagesGetStarted: "",
    isTraceable: "",
    twoColumns: "",
    randomizeWorksheet: "",
    createPDF: "",
    fontSize: "",
    goodJobReadyForNext: "",
    whoGoesFirst: "",
    teamGoesFirstFormat: "",
    popBalloonsWith: "",
    dartMeterSpeed: "",
    fontStyle: "",
    cardSensitivity: "",
    points: "",
    hotOrColdInstructions: "",
    inviteUsers: "",
    availableInvitesFormat: "",
    numberOfAccountsToAdd: "",
    removeSubscriptionManagerConfirmation: "",
    managedSubscription: "",
    disconnectFromAccountManager: "",
    confirmToBeManagedConfirmationFormat: "",
    pendingManagerNotFound: "",
    steal: "",
    newWord: "",
    canYouSpell: "",
    wouldLikeToStealFormat: "",
    selectSecondCategory: "",
    clearSecondCategory: "",
    borderThickness: "",
    borderColor: "",
    subscriptionIsPastDue: "",
};
