import Activity from "./Activity";

export enum FlashcardActivityType {
    Interactive = "Interactive Flashcards",
    Printable = "Printable Flashcards"
}

export default class FlashcardActivity extends Activity {
    public static allItems: FlashcardActivity[] = [
        new FlashcardActivity(FlashcardActivityType.Interactive),
        new FlashcardActivity(FlashcardActivityType.Printable)
    ];

    public type: FlashcardActivityType;

    protected get prefix() {
        return "flashcards";
    }
}
