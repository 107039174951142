import * as React from "react";
import Paper from "@material-ui/core/Paper";
import { strings } from "../core/localizedStrings";
import { GlobalStyles } from "../styles/GlobalStyles";
var Col = require("react-flexbox-grid").Col;
var Row = require("react-flexbox-grid").Row;

export const PageNotFound = props => {
    return (
        <Paper style={GlobalStyles.paper}>
            <Col xs={12} style={{ height: 300, fontSize: 24 }}>
                <Row center="xs" middle="xs" style={{ height: "100%", fontSize: 24 }}>
                    {strings.pageNotFound}
                </Row>
            </Col>
        </Paper>
    );
};
