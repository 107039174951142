import * as React from "react";
import { observable, action } from "mobx";

import Typography from "@material-ui/core/Typography";

import TranslationBase from "./TranslationBase";
import TranslationCategoryCard from "./TranslationCategoryCard";

import { Category, CategoryItem } from "models";
import { Languages } from "models/Languages";

export default class CategoryTranslations extends TranslationBase {
    protected englishQuery: firebase.firestore.Query;
    protected translationTargetQuery: firebase.firestore.Query;

    private englishCategories: Category[] = [];

    @observable
    private translationTargetCategories: Category[] = [];

    protected getCategories = async () => {
        if (!this.englishQuery || !this.translationTargetQuery) {
            return;
        }
        let categories = await this.categoriesForQuery(this.englishQuery);
        this.englishCategories = categories.filter((category: Category) => {
            let prefixes = ["alphabet", "long", "short", "digraphs", "consonant"];
            let skip = false;
            for (let prefix of prefixes) {
                if (category.searchTitle.startsWith(prefix)) {
                    skip = true;
                    break;
                }
            }

            return !skip;
        });

        let translationTargetCategories = await this.categoriesForQuery(this.translationTargetQuery);
        this.setTranslationCategories(translationTargetCategories);

        this.createMissingTranslationTargetCategories();

        this.totalEnglishWordCount();
    };

    @action
    protected setTranslationCategories(categories: Category[]) {
        this.translationTargetCategories = categories;
    }

    protected createMissingTranslationTargetCategories = () => {
        for (var englishCategory of this.englishCategories) {
            let found = false;
            for (var translationTargetCategory of this.translationTargetCategories) {
                if (translationTargetCategory.englishCategoryKey === englishCategory.key) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                // Create a copy, with a reference to original
                let newCategory = Object.assign(new Category(), englishCategory);
                newCategory.key = null;
                newCategory.uid = "site";
                newCategory.englishCategoryKey = englishCategory.key;
                newCategory.language = this.selectedLanguage;
                newCategory.answersLanguage = this.selectedLanguage;
                newCategory.voice = Languages.languageTypeToVoice(this.selectedLanguage);
                newCategory.answersVoice = newCategory.voice;

                this.props.rootStore.createListsStore.updateCategory(newCategory);
                console.log(newCategory);
            }
        }
    };

    protected categoriesForQuery = async (queryRef: firebase.firestore.Query) => {
        let categories: Category[] = [];

        let querySnapshot = await queryRef.get();
        if (!querySnapshot.empty) {
            for (let doc of querySnapshot.docs) {
                let vocabularyCategory = new Category(doc.data());
                vocabularyCategory.key = doc.id;
                categories.push(vocabularyCategory);
                // if (vocabularyCategory.language === "krkorean" && vocabularyCategory.type === CategoryType.Lesson) {
                //     console.log(vocabularyCategory);
                //     doc.ref.delete();
                // }
            }
        }

        return categories;
    };

    protected renderChildren() {
        return (
            <>
                <Typography variant="subtitle1">Total word count: {this.totalEnglishWordCount()}</Typography>
                {this.renderCards()}
            </>
        );
    }

    protected renderCards() {
        if (this.translationTargetCategories.length > 0 && this.englishCategories.length > 0) {
            return this.translationTargetCategories.map((category: Category, index: number) => {
                return (
                    <TranslationCategoryCard
                        rootStore={this.props.rootStore}
                        key={index}
                        category={category}
                        englishCategory={this.englishCategories.find((element: Category) => {
                            return element.key === category.englishCategoryKey;
                        })}
                        stringsDictionary={this.allStringsDictionary}
                        onItemTranslatedCallback={this.onItemTranslated}
                    />
                );
            });
        }
        return null;
    }

    private onItemTranslated = (item: CategoryItem, oldItem: CategoryItem, category: Category) => {
        let newTranslations = new Map<string, string>();

        category.items = category.items.map(element => {
            if (element.key === item.key) {
                return item;
            }
            return element;
        });

        this.props.rootStore.createListsStore.updateCategoryItemForCategory(item, oldItem, category);
        if (oldItem.text && oldItem.text !== item.text) {
            newTranslations[oldItem.text] = item.text;
        }
        if (oldItem.question && oldItem.question !== item.question) {
            newTranslations[oldItem.question] = item.question;
        }
        if (oldItem.answer && oldItem.answer !== item.answer) {
            newTranslations[oldItem.answer] = item.answer;
        }
        if (oldItem.wrong1 && oldItem.wrong1 !== item.wrong1) {
            newTranslations[oldItem.wrong1] = item.wrong1;
        }
        if (oldItem.wrong2 && oldItem.wrong2 !== item.wrong2) {
            newTranslations[oldItem.wrong2] = item.wrong2;
        }
        if (oldItem.wrong3 && oldItem.wrong3 !== item.wrong3) {
            newTranslations[oldItem.wrong3] = item.wrong3;
        }
        this.updateDictionaryWithStrings(newTranslations);
        // this.forceUpdate();
    };

    // Helpers
    private totalEnglishWordCount = () => {
        let englishWordCount = 0;
        for (let category of this.englishCategories) {
            for (let item of category.items) {
                englishWordCount += item.text ? item.text.split(" ").length : 0;
                englishWordCount += item.question ? item.question.split(" ").length : 0;
                englishWordCount += item.answer ? item.answer.split(" ").length : 0;
                englishWordCount += item.wrong1 ? item.wrong1.split(" ").length : 0;
                englishWordCount += item.wrong2 ? item.wrong2.split(" ").length : 0;
                englishWordCount += item.wrong3 ? item.wrong3.split(" ").length : 0;
            }
        }
        console.log("CategoryTranslationsBase -> totalEnglishWordCount -> englishWordCount", englishWordCount);
        return englishWordCount;
    };
}
