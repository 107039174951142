import { log } from "../";

import { observable, action, runInAction } from "mobx";

// Models
import { StudentList, Student, TabSelection } from "../models";
import * as CustomLists from "../models/customLists";
import FirestoreFolder from "../models/customLists/FirestoreFolder";
import CategoryMenuStore from "./CategoryMenuStore";
import { Firestore, StorageActions } from "../core/firebase";

// Stores
import RootStore from "./RootStore";

// Firebase
import { FirebaseStorage } from "../core/firebase";

// Utils
import { strings } from "../core/localizedStrings";
import SampleStudentListCreator from "core/utils/SampleStudentListCreator";

export default class StudentListStore extends CategoryMenuStore {
    @observable selectedClassroomID: string;
    @observable imageProfileUploadProgress: number = 0;

    @observable classListToEditTitle: StudentList;
    @observable studentToEdit: Student;

    constructor(rootStore: RootStore) {
        super(rootStore);

        // No tabs for class editor
        this.currentTab = CustomLists.TabSelection.CLASS_EDITOR;
        this.folderTabs[this.currentTab] = new FirestoreFolder(CustomLists.TabSelection.CLASS_EDITOR, this.rootStore);

        runInAction(() => {
            this.folderTabs.set(
                TabSelection.CLASS_EDITOR,
                new FirestoreFolder(TabSelection.CLASS_EDITOR, this.rootStore)
            );
        });
    }

    @action
    public setClassListToEdit = (classList: StudentList) => {
        if (classList.key !== undefined && classList.students.length === 0) {
            let studentsRef = Firestore.studentsRef() as firebase.firestore.CollectionReference;
            studentsRef.where("parentKey", "==", classList.key).onSnapshot((snapshot) => {
                runInAction(() => {
                    snapshot.docChanges().forEach((docChange) => {
                        let student = new Student();
                        Object.assign(student, docChange.doc.data());
                        student.key = docChange.doc.id;
                        switch (docChange.type) {
                            case "added":
                                classList.students.push(student);
                                break;

                            case "modified":
                                classList.students = classList.students.map((item) => {
                                    return item.key === student.key ? student : item;
                                });
                                if (this.studentToEdit && this.studentToEdit.key === student.key) {
                                    this.studentToEdit = student;
                                }
                                break;

                            case "removed":
                                classList.students = classList.students.filter((item) => {
                                    return item.key !== student.key;
                                });
                                if (this.studentToEdit && this.studentToEdit.key === student.key) {
                                    this.studentToEdit = undefined;
                                }
                                break;

                            default:
                                break;
                        }
                    });
                    this.rootStore.activityStore.currentStudentList = classList;

                    // Update student counts
                    this.rootStore.refFactory.itemRefForTab(this.currentTab).doc(classList.key).update({
                        totalStudentCount: classList.students.length,
                        absentStudentCount: classList.absentStudents.length,
                    });
                });
            });
        } else {
            this.rootStore.activityStore.currentStudentList = classList; // New list
        }
    };

    protected onStoreHydrated() {
        if (!this.folderTabs.get(TabSelection.CLASS_EDITOR)) {
            this.folderTabs.set(
                TabSelection.CLASS_EDITOR,
                new FirestoreFolder(TabSelection.CLASS_EDITOR, this.rootStore)
            );
        }

        runInAction(() => {
            if (!this.currentTab || !this.folderTabs.get(this.currentTab)) {
                this.currentTab = TabSelection.CLASS_EDITOR;
            }
            this.folderTabs.get(TabSelection.CLASS_EDITOR).isVisible = true;
        });
        this.isHydrated = true;
    }

    @action
    public addStudentProfileImage = async (file: File, student: Student) => {
        this.imageProfileUploadProgress = 0;

        try {
            await StorageActions.uploadStudentProfileImage(
                file,
                student,
                this.rootStore.activityStore.currentStudentList.key
            );
        } catch (error) {
            this.rootStore.showErrorMessage(strings.uploadProfileImageError);
        }
    };

    // Classroom Data
    // --------------------------------

    public updateStudentList = async (classList: StudentList) => {
        let classListRef = this.rootStore.refFactory.itemRefForTab(this.currentTab);
        let shouldAddSampleFolder = false;

        if (this.visibleFolder.key === "sample") {
            shouldAddSampleFolder = true;
            this.visibleFolder.key = undefined;
        }
        await this.createAndSetRootFolderIfNecessary();

        if (shouldAddSampleFolder) {
            let sampleList = SampleStudentListCreator.create();
            sampleList.details.parentKey = this.visibleFolder.key;
            let doc = await classListRef.add(sampleList.details);
            for (let student of sampleList.students) {
                student.parentKey = doc.id;
                this.updateStudent(student);
            }
        }

        classList.details.parentKey = this.visibleFolder.key;
        try {
            if (classList.key === undefined) {
                // New class list
                classList.details.deleted = false;
                let doc = await classListRef.add(classList.details);
                classList.key = doc.id;
                this.setClassListToEdit(classList);
            } else {
                await classListRef.doc(classList.key).update(classList.details);
            }
        } catch (error) {
            this.rootStore.showErrorMessage(error);
        }
    };

    public updateStudent = async (student: Student) => {
        if (!this.rootStore.authStore.isSignedIn) {
            this.rootStore.showLoginRequiredMessage(strings.loginToCreateLists);
        } else {
            try {
                let studentsRef = Firestore.studentsRef();
                if (student.key === undefined) {
                    await studentsRef.add(student.objectForFirebase);
                } else {
                    await studentsRef.doc(student.key).update(student.objectForFirebase);
                }
            } catch (error) {
                this.rootStore.showErrorMessage(error);
            }
        }
    };

    public deleteStudent = async (student: Student) => {
        if (!this.rootStore.authStore.isSignedIn) {
            this.rootStore.showLoginRequiredMessage(strings.loginToCreateLists);
        } else {
            try {
                let studentsRef = Firestore.studentsRef();
                await studentsRef.doc(student.key).delete();

                // Delete profile images
                let fileName;
                let profileImageStorageRef;
                if (student.profileImage) {
                    if (student.profileImage.indexOf("https") === 0) {
                        let start = student.profileImage.lastIndexOf("%2F") + 3;
                        let end = student.profileImage.indexOf("?");
                        fileName = student.profileImage.slice(start, end);
                        profileImageStorageRef = FirebaseStorage.studentsProfileImagesUploadStorageRef(
                            student.key,
                            fileName
                        );

                        try {
                            profileImageStorageRef.delete();
                        } catch (error) {
                            log.verbose("Could not delete profile image. " + error);
                        }
                    } else {
                        fileName = student.profileImage.slice(0, student.profileImage.indexOf("?"));
                        profileImageStorageRef = FirebaseStorage.profileImagesStorageRef();

                        try {
                            profileImageStorageRef.child(fileName).delete();
                        } catch (error) {
                            log.verbose("Could not delete profile image. " + error);
                        }

                        try {
                            profileImageStorageRef.child("thumb_" + fileName).delete();
                        } catch (error) {
                            log.verbose("Could not delete profile image. " + error);
                        }
                    }
                }
            } catch (error) {
                this.rootStore.showErrorMessage(error);
            }
        }
    };

    public deleteClassroom(classroom: StudentList) {
        if (!this.rootStore.authStore.isSignedIn) {
            this.rootStore.showLoginRequiredMessage(strings.loginToCreateLists);
        } else {
            let classListRef = this.rootStore.refFactory.itemRefForTab(this.currentTab);
            classListRef.doc(classroom.key).delete();
        }
    }
}
