import * as React from "react";

// Stores
import { ActivityStore } from "stores";

// Layout
import { Grid, Typography } from "@material-ui/core";
import { Observer } from "mobx-react-lite";
import {
    CardTypeButtons,
    FontSizeButtons,
    worksheetOptionsBackgroundStyle,
} from "components/activities/CommonActivityOptions";
import { CardType } from "models/customLists/CategoryItem";
import { NumberOfWorksheetColumns } from "../WorksheetHelper";
import { WorksheetOptions } from "../WorksheetScene";
import SimpleCheckbox from "components/SimpleCheckbox";
import { strings } from "core/localizedStrings";
import SimpleRadio from "components/SimpleRadio";

export enum NumberOfTicTacToeBoards {
    FOUR = "four",
    NINE = "nine",
}

export interface TicTacToeWorksheetOptions extends WorksheetOptions {
    cardType: CardType;
    numberOfBoards: NumberOfTicTacToeBoards;
}

export const defaultTicTacToeWorksheetOptions: TicTacToeWorksheetOptions = {
    cardType: CardType.IMAGE_ONLY,
    numberOfBoards: NumberOfTicTacToeBoards.FOUR,
};

interface TicTacToeWorksheetOptionsProps extends React.Props<any> {
    activityStore: ActivityStore;
}

const TicTacToeWorksheetOptions = (props: TicTacToeWorksheetOptionsProps) => {
    const handleCardTypeChanged = (cardType: CardType) => {
        props.activityStore.activityOptions.ticTacToeWorksheetOptions.cardType = cardType;
        props.activityStore.updateOptions();
    };

    const handleNumberOfBoardsChanged = (value: string) => {
        console.log(value);
        props.activityStore.activityOptions.ticTacToeWorksheetOptions.numberOfBoards = value as NumberOfTicTacToeBoards;
        props.activityStore.updateOptions();
    };

    return (
        <Observer
            render={() => (
                <>
                    <CardTypeButtons
                        activityStore={props.activityStore}
                        onCardTypeChanged={handleCardTypeChanged}
                        selectedType={props.activityStore.activityOptions.ticTacToeWorksheetOptions.cardType}
                    />
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        style={worksheetOptionsBackgroundStyle}
                    >
                        <Grid container justify="center">
                            <Grid item>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography variant={"h6"}>{strings.gridSize}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <SimpleRadio
                                            labels={["Four", "Nine"]}
                                            values={[NumberOfTicTacToeBoards.FOUR, NumberOfTicTacToeBoards.NINE]}
                                            defaultValue={
                                                props.activityStore.activityOptions.ticTacToeWorksheetOptions
                                                    .numberOfBoards
                                            }
                                            direction="column"
                                            onValueChanged={handleNumberOfBoardsChanged}
                                        ></SimpleRadio>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        />
    );
};

export default TicTacToeWorksheetOptions;
