export const BACKGROUND_IMAGE_INDEX = -2;
export const OPTIONS_PANEL_INDEX = 3;
export const FOOTER_INDEX = 4;
export const MATCHING_WORKSHEET_MATCH_LINE_INDEX = 5;
export const MATCHING_WORKSHEET_LINE_INDEX = 5;
export const DEFAULT_SHADOWBOX_INDEX = 6;
export const ACTIVITY_COMPLETE_OPTIONS_INDEX = 7;
export const FLIP_CARD_INDEX = 10;
export const ACTIVITY_OPTIONS_BAR_INDEX = 10;
export const CATEGORY_MENU_INDEX = 20;
export const TEAM_MAKER_INDEX = 20;
export const STUDENT_LIST_SELECTOR_INDEX = 21;
export const MODAL_SIGN_IN_DIALOG_INDEX = 50;
export const PREVIEW_TIMER_INDEX = 51;
// Navigation Drawer - 1200
// Dialogs - 1300
export const APP_BAR_INDEX = 1301;
export const SIMPLE_MENU_INDEX = 2000;
