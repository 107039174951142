import * as React from "react";
import { observer } from "mobx-react";

import * as CustomLists from "../models/customLists";
import CategoryMenuStore from "../stores/CategoryMenuStore";
import Typography from "@material-ui/core/Typography";
import { Colors } from "core/utils/Colors";
import { Grid } from "@material-ui/core";

class BreadcrumbItem extends React.Component<BreadcrumbItemProps> {
    render() {
        let style = { fontSize: this.props.fontSize, color: this.props.fontColor };

        if (this.props.isLastItem) {
            return <Typography style={style}>{this.props.folder.title}</Typography>;
        } else {
            return (
                <a
                    style={Object.assign({ cursor: "pointer" }, style)}
                    onClick={this.onClicked.bind(this, this.props.folder)}
                >
                    <Typography style={style}>{this.props.folder.title}&nbsp;/&nbsp;</Typography>
                </a>
            );
        }
    }

    private onClicked = (folder: CustomLists.Folder) => {
        if (this.props.isFolderBrowser !== undefined && this.props.isFolderBrowser) {
            this.props.store.folderBrowserFolderSelected(folder);
        } else {
            this.props.store.folderSelected(folder);
        }
    };
}

export interface BreadcrumbsProps extends React.Props<any> {
    store: CategoryMenuStore;
    fontSize?: number;
    fontColor?: string;
    isFolderBrowser?: boolean;
}

@observer
export default class Breadcrumbs extends React.Component<BreadcrumbsProps> {
    render() {
        return this.renderBreadcrumbs();
    }

    private renderBreadcrumbs() {
        let breadcrumbs = this.props.store.breadcrumbs;
        if (!breadcrumbs) {
            return null;
        }

        if (this.props.isFolderBrowser !== undefined && this.props.isFolderBrowser) {
            breadcrumbs = this.props.store.folderBrowserCategoryMenuFolder.breadcrumbs;
        }

        let lastFolder = breadcrumbs[breadcrumbs.length - 1];
        return (
            <Grid container>
                {breadcrumbs.map((folder: CustomLists.Folder, index) => (
                    <Grid item key={folder.key}>
                        <BreadcrumbItem
                            store={this.props.store}
                            folder={folder}
                            isLastItem={folder.key === lastFolder.key}
                            fontSize={this.props.fontSize ? this.props.fontSize : 14}
                            fontColor={this.props.fontColor ? this.props.fontColor : Colors.white}
                            isFolderBrowser={this.props.isFolderBrowser}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    }
}

export interface BreadcrumbItemProps extends React.Props<any> {
    store: CategoryMenuStore;
    folder: CustomLists.Folder;
    isLastItem: boolean;
    fontSize?: number;
    fontColor?: string;
    isFolderBrowser?: boolean;
}
