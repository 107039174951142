import { Colors } from "core/utils/Colors";
import { Fonts } from "core/utils/Fonts";
import SoundEffectPlayer from "core/utils/SoundEffectPlayer";
import { Category } from "models";
import { TextAudioPair } from "models/customLists/CategoryItem";
import { PhaserHelper } from "../games/PhaserHelper";
import { PhaserImages } from "../games/PhaserImages";
import { PhaserCard } from "./PhaserCard";

export enum PhaserCardContent {
    QUESTION = "question",
    ANSWER = "answer",
    WRONG_ANSWER = "answer",
    IMAGE = "image",
    CATEGORY_TITLE = "categoryTitle",
    FLIP_BUTTON = "flipButton",
    CARD_BACKGROUND_FRONT = "cardBackgroundFront",
    CARD_BACKGROUND_BACK = "cardBackgroundBack",
}

export default class PhaserCardHelper {
    public static createBackgroundImage(
        scene: Phaser.Scene,
        assetKey: string,
        cardWidth: number,
        cardHeight: number,
        keepScale: boolean = true,
        onClickHandler?: () => void,
        onOverHandler?: () => void,
        onOutHandler?: () => void
    ) {
        const background = new Phaser.GameObjects.Image(scene, 0, 0, assetKey);
        background.name = "background";
        if (onClickHandler) {
            background
                .setInteractive({ cursor: "pointer" })
                .on(
                    "pointerup",
                    (pointer) => {
                        if (onClickHandler) {
                            onClickHandler();
                        }
                    },
                    scene
                )
                .on(
                    "pointerover",
                    (pointer) => {
                        if (onOverHandler) {
                            onOverHandler();
                        }
                    },
                    scene
                )
                .on(
                    "pointerout",
                    (pointer) => {
                        if (onOutHandler) {
                            onOutHandler();
                        }
                    },
                    scene
                );
        }

        if (keepScale) {
            let scale = PhaserHelper.scaleForSize({ width: cardWidth, height: cardHeight }, background.getBounds());
            background.setScale(scale);
        } else {
            let bounds = background.getBounds();
            background.scaleX = cardWidth / bounds.width;
            background.scaleY = cardHeight / bounds.height;
        }

        return background;
    }

    public static createCategoryTitleText(scene: Phaser.Scene, category: Category, cardWidth, cardHeight: number) {
        return new Phaser.GameObjects.Text(scene, 0, 0, category.title, {
            fontFamily: Fonts.BalsamiqSans,
            fontSize: "72px",
            wordWrap: { width: cardWidth * 0.8, useAdvancedWrap: true },
        })
            .setOrigin(0.5, 0.5)
            .setName(PhaserCardContent.CATEGORY_TITLE)
            .setAlign("center");
    }

    public static createText(
        scene: Phaser.Scene,
        contentName: PhaserCardContent,
        text: string,
        cardWidth: number,
        cardHeight: number,
        xPos: number = 0,
        onClickHandler?: () => void,
        fontStyle?: string,
        fontMultiplier?: number
    ) {
        let fontSize = cardHeight * 0.15;
        if (text.length > 20) {
            fontSize *= 0.6;
        } else if (text.length > 12) {
            fontSize *= 0.8;
        }

        if (fontMultiplier) {
            fontSize *= fontMultiplier;
        }

        let style = {
            fontFamily: Fonts.BalsamiqSans,
            color: Colors.black,
            fontSize: fontSize + "px",
            wordWrap: { width: cardWidth * 0.8, useAdvancedWrap: true },
        };
        if (fontStyle) {
            style = Object.assign(style, fontStyle);
        }

        return new Phaser.GameObjects.Text(scene, 0, (cardHeight / 2) * xPos, text, style)
            .setOrigin(0.5, 0.5)
            .setName(contentName)
            .setAlign("center")
            .setInteractive({ cursor: onClickHandler ? "pointer" : "arrow" })
            .on(
                "pointerup",
                (pointer) => {
                    if (onClickHandler) {
                        onClickHandler();
                    }
                },
                scene
            );
    }

    public static createVocabularyImage(
        scene: Phaser.Scene,
        contentName: PhaserCardContent,
        imageKey: string,
        cardWidth: number,
        cardHeight: number,
        scale: number = 1,
        xPos: number = 0,
        onClickHandler?: () => void
    ) {
        let image = new Phaser.GameObjects.Image(scene, 0, (cardHeight / 2) * xPos, imageKey);
        image.setName(contentName);
        if (onClickHandler) {
            image.setInteractive({ cursor: "pointer" }).on(
                "pointerup",
                (pointer) => {
                    onClickHandler();
                },
                scene
            );
        }
        let scaleForSize = PhaserHelper.scaleForSize(
            { width: cardWidth * scale * 0.8, height: cardHeight * scale },
            image.getBounds()
        );
        image.setScale(scaleForSize);
        return image;
    }

    public static createFlipButton = (
        scene: Phaser.Scene,
        contentName: PhaserCardContent,
        card: PhaserCard,
        cardWidth: number,
        cardHeight: number
    ) => {
        return new Phaser.GameObjects.Image(scene, cardWidth * 0.4, cardHeight * -0.4, PhaserImages.FLIP_ICON)
            .setInteractive({ cursor: "pointer" })
            .setName(contentName)
            .setScale(3)
            .setVisible(true)
            .on("pointerup", (pointer) => {
                card.flip();
            });
    };
}
