import { AUDIO_STORAGE_PATH, DOWNLOAD_URL_PREFIX } from "../../core/firebase/constants";
import { AudioClipVoice } from "../../models/Languages";

export class AudioClip {
    key: string;
    file: string;
    text: string;
    language: AudioClipVoice;
    speed: number;
    source: string;

    get downloadURL(): string {
        return DOWNLOAD_URL_PREFIX + encodeURIComponent(AUDIO_STORAGE_PATH) + this.file;
    }

    get token(): string {
        let components = this.file.split("token=");
        if (components.length > 1) {
            return components[1];
        }
        return undefined;
    }

    public constructor(obj: any = undefined) {
        if (obj) {
            Object.assign(this, obj);
        }
        console.log(this);
    }

    public initWithFirebaseObject(key: string, object: any) {
        this.key = key;

        if (object === undefined) {
            return;
        }
        Object.assign(this, object);

        if (object.language !== undefined) {
            this.language = object.language;
        }
    }
}
