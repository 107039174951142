import * as React from "react";
import { observer } from "mobx-react";
import { observable, runInAction } from "mobx";

export interface WithWindowSizeProps extends React.Props<any> {
    windowHeight?: number;
    windowWidth?: number;
}

function withWindowSize<OriginalProps extends WithWindowSizeProps>(
    WrappedComponent: React.ComponentType<OriginalProps & WithWindowSizeProps>
) {
    class WithWindowSize extends React.Component<OriginalProps & WithWindowSizeProps> {
        @observable private windowHeight: number;
        @observable private windowWidth: number;

        render() {
            return <WrappedComponent {...this.props} windowHeight={this.windowHeight} windowWidth={this.windowWidth} />;
        }

        componentDidMount() {
            this.updateWindowSize();
            window.addEventListener("resize", this.updateWindowSize);
        }

        componentWillUnmount() {
            window.removeEventListener("resize", this.updateWindowSize);
        }

        updateWindowSize = () => {
            const w = window,
                documentElement = document.documentElement,
                body = document.getElementsByTagName("body")[0];

            runInAction(() => {
                this.windowWidth = w.innerWidth || documentElement.clientWidth || body.clientWidth;
                this.windowHeight = w.innerHeight || documentElement.clientHeight || body.clientHeight;
            });
        };
    }
    return observer(WithWindowSize);
}

export default withWindowSize;
