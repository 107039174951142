import { log } from "../";

import * as React from "react";
import { action, observable, runInAction } from "mobx";
import { observer } from "mobx-react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import RootStore from "../stores/RootStore";

import { strings } from "../core/localizedStrings";
import { CircularProgress } from "@material-ui/core";

import * as Validator from "../core/utils/Validator";
import { HOME_PATH } from "../constants";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

const styles = {
    checkingDBProgress: {
        width: 30,
        height: 30
    }
};

export interface RegisterFormProps extends React.Props<any> {
    rootStore: RootStore;
}

@observer
export default class RegisterForm extends React.Component<RegisterFormProps> {
    private username: string;
    private email: string;
    private password: string;

    @observable
    private isRegistering = false;

    @observable
    private usernameError: string;
    @observable
    private emailError: string;
    @observable
    private passwordError: string;

    render() {
        let textPadding = 8;
        return (
            <Col xs={12} style={{ maxWidth: 350 }}>
                <Row center="xs" style={{ paddingBottom: 3 }}>
                    <Typography variant="body1">{strings.registerUsernamePassword.toUpperCase()}</Typography>
                </Row>
                <Row style={{ paddingBottom: textPadding }}>
                    <TextField
                        error={this.usernameError !== undefined}
                        label={strings.username}
                        helperText={this.usernameError}
                        fullWidth={true}
                        id="usernameTextField"
                        onChange={this.onTextChanged}
                    />
                </Row>
                <Row style={{ paddingBottom: textPadding }}>
                    <TextField
                        error={this.emailError !== undefined}
                        label="Email"
                        helperText={this.emailError}
                        fullWidth={true}
                        id="emailTextField"
                        onChange={this.onTextChanged}
                    />
                </Row>
                <Row>
                    <TextField
                        error={this.passwordError !== undefined}
                        type="password"
                        helperText={this.passwordError}
                        label={strings.password}
                        fullWidth={true}
                        id="passwordTextField"
                        onChange={this.onTextChanged}
                    />
                </Row>
                <Row end="xs" middle="xs" style={{ paddingTop: 10 }}>
                    {this.isRegistering && <CircularProgress style={styles.checkingDBProgress} />}
                    <Button
                        disabled={this.isRegistering}
                        variant="contained"
                        color="primary"
                        onClick={this.onRegisterClicked}
                    >
                        {strings.register}
                    </Button>
                </Row>
            </Col>
        );
    }

    private onTextChanged = (event: any) => {
        switch (event.currentTarget.id) {
            case "usernameTextField":
                this.username = event.currentTarget.value;
                break;
            case "emailTextField":
                this.email = event.currentTarget.value;
                break;
            case "passwordTextField":
                this.password = event.currentTarget.value;
                if (this.password.length > 5) {
                    runInAction(() => {
                        this.passwordError = Validator.validatePassword(this.password);
                    });
                }
                break;
            default:
                log.verbose(`Unknown target ID: ${event.currentTarget.id}`);
                break;
        }
    };

    @action
    private onRegisterClicked = async () => {
        this.isRegistering = true;

        this.passwordError = Validator.validatePassword(this.password);

        let emailError = await Validator.validateEmail(this.email);
        runInAction(async () => {
            this.emailError = emailError;
        });

        runInAction(async () => {
            this.passwordError = Validator.validatePassword(this.password);
        });

        let usernameError = await Validator.validateUsername(this.username);
        runInAction(async () => {
            this.usernameError = usernameError;
        });

        if (!this.passwordError && !this.emailError && !this.usernameError) {
            let success = await this.props.rootStore.authStore.registerWithEmail(
                this.email.trim(),
                this.username.trim(),
                this.password
            );
            this.props.rootStore.showLoginRequiredMessage(undefined);
            this.props.rootStore.authStore.setShouldShowSignInDialog(false);
            this.props.rootStore.authStore.setShouldShowModalRegisterDialog(false);
            if (success) {
                this.props.rootStore.setRoute(HOME_PATH);
            } else {
                log.error("Error registering.");
            }
        }

        runInAction(() => {
            this.isRegistering = false;
        });
    };
}
