import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { worksheetOptionsBackgroundStyle } from "./CommonActivityOptions";

interface OptionsGroupProps extends React.Props<any> {
    heading?: string;
}

export const OptionsGroup = (props: OptionsGroupProps) => {
    return (
        <Grid container direction="column" style={worksheetOptionsBackgroundStyle}>
            {props.heading && (
                <Grid item>
                    <Typography variant="subtitle1" style={{ fontWeight: "bold", paddingBottom: 5 }}>
                        {props.heading}:
                    </Typography>
                </Grid>
            )}
            <Grid item style={{ paddingLeft: 20 }}>
                {props.children}
            </Grid>
        </Grid>
    );
};
