import { StringUtils } from "./StringUtils";
import FirebaseFunctions from "../firebase/FirebaseFunctions";
import { strings } from "../localizedStrings";

export const validateUsername = async (username: string) => {
    let usernameError: string;
    if (username) {
        if (StringUtils.isValidUsername(username.trim())) {
            let isAvailable = await FirebaseFunctions.isUsernameAvailable(username.trim());
            if (!isAvailable) {
                usernameError = strings.usernameUnavailable;
            }
        } else {
            usernameError = strings.invalidUsername;
        }
    } else {
        usernameError = strings.cannotBeEmpty;
    }
    return usernameError;
};

export const validateEmail = async (email: string) => {
    let emailError: string;
    if (email) {
        if (StringUtils.isValidEmail(email.trim())) {
            let isAvailable = await FirebaseFunctions.isEmailAvailable(email.trim());
            if (!isAvailable) {
                emailError = strings.emailAlreadyRegistered;
            }
        } else {
            emailError = strings.invalidEmail;
        }
    } else {
        emailError = strings.cannotBeEmpty;
    }
    return emailError;
};

export const validatePassword = (password: string) => {
    let passwordError: string;
    if (password) {
        if (!StringUtils.isValidPassword(password)) {
            passwordError = strings.invalidPassword;
        }
    } else {
        passwordError = strings.cannotBeEmpty;
    }
    return passwordError;
};
