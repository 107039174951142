import * as React from "react";
import { Observer } from "mobx-react-lite";
import { ActivityStore } from "stores";
import { OptionsGroup } from "components/activities/OptionsGroup";
import { GameOptions } from "../GameScene";
import { Button, Grid, Typography } from "@material-ui/core";
import { PhaserImages } from "../PhaserImages";
import * as Icons from "core/utils/Icons";
import QuizOptions from "models/activities/QuizOptions";
import { strings } from "core/localizedStrings";
import { CardTypeButtons, SecondCategoryButtons } from "components/activities/CommonActivityOptions";
import { CardType } from "models/customLists/CategoryItem";
import SimpleCheckbox from "components/SimpleCheckbox";
import { Colors } from "core/utils/Colors";

export enum PopType {
    DART = "dart",
    POINTER = "pointer",
}

export const defaultHotOrColdOptions: HotOrColdOptions = {
    isUsingClassicMode: false,
    cardType: CardType.IMAGE_ONLY,
};

export interface HotOrColdOptions extends GameOptions {
    isUsingClassicMode: boolean;
    cardType: CardType;
}

interface HotOrColdOptionsProps {
    activityStore: ActivityStore;
}

const HotOrColdOptions = (props: HotOrColdOptionsProps) => {
    const handleCardTypeChanged = (value: any) => {
        console.log(value);
        props.activityStore.activityOptions.hotOrColdOptions.cardType = value;
    };

    const handleIsClassicModeChanged = (value: any) => {
        props.activityStore.activityOptions.hotOrColdOptions.isUsingClassicMode = value;
    };

    return (
        <Observer
            render={() => (
                <>
                    <OptionsGroup>
                        <SimpleCheckbox
                            label={strings.useClassicMode}
                            isChecked={props.activityStore.activityOptions.hotOrColdOptions.isUsingClassicMode}
                            onChange={handleIsClassicModeChanged}
                        />
                        <Typography variant="body1">{strings.classicModeDescription}</Typography>
                    </OptionsGroup>
                    {props.activityStore.activityOptions.hotOrColdOptions.isUsingClassicMode ? (
                        <>
                            <CardTypeButtons
                                activityStore={props.activityStore}
                                onCardTypeChanged={handleCardTypeChanged}
                                selectedType={props.activityStore.activityOptions.hotOrColdOptions.cardType}
                            />
                            <SecondCategoryButtons activityStore={props.activityStore} />
                        </>
                    ) : (
                        <QuizOptions activityStore={props.activityStore} />
                    )}
                </>
            )}
        />
    );
};

export default HotOrColdOptions;
