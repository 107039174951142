import { CategoryType } from "models";
import { ActivityCategoryItem } from "models/customLists/CategoryItem";
import { ActivityStore } from "stores";
import { PhaserCard } from "../phaserComponents/PhaserCard";
import PhaserCardHelper, { PhaserCardContent } from "../phaserComponents/PhaserCardHelper";
import { PhaserImages } from "./PhaserImages";

export class AnswerPhaserCard extends PhaserCard {
    public keepScale = true;
    public categoryTypeOverride: CategoryType;
    private activityStore: ActivityStore;
    private isBackgroundHidden = false;
    private cardWidth: number;
    private cardHeight: number;

    private fontSizeMultiplier: number = 1;

    constructor(
        scene: Phaser.Scene,
        cardWidth: number,
        cardHeight: number,
        activityItem: ActivityCategoryItem,
        activityStore: ActivityStore,
        keepScale: boolean,
        hasBack: boolean = false,
        categoryTypeOverride: CategoryType,
        onCardClicked?: () => void
    ) {
        super(scene, 0, 0);
        this.activityItem = activityItem;
        this.activityStore = activityStore;
        this._onCardClicked = onCardClicked;
        this.keepScale = keepScale;
        this.categoryTypeOverride = categoryTypeOverride;
        this.cardWidth = cardWidth;
        this.cardHeight = cardHeight;

        const cardFrontContent = this.createCardFront(cardWidth, cardHeight);
        cardFrontContent.forEach((item) => {
            this.addContentToFront(item);
        });

        if (hasBack) {
            const cardBackContent = this.createCardBack(cardWidth, cardHeight);
            cardBackContent.forEach((item) => {
                this.addContentToBack(item);
            });
        }
    }

    public updateItem = (item: ActivityCategoryItem) => {
        this.activityItem = item;
        this.updateCardFront();
    };

    public updateFontSize = (fontSizeMultiplier: number) => {
        this.fontSizeMultiplier = fontSizeMultiplier;
        this.updateCardFront();
    };

    public hideBackground = () => {
        this.frontItems.forEach((gameObject) => {
            if (gameObject.name === "background") {
                (gameObject as any).visible = false;
            }
        });
        this.isBackgroundHidden = true;
    };

    private updateCardFront = () => {
        this.frontItems.forEach((item) => item.destroy());
        this._frontItems = [];

        const cardFrontContent = this.createCardFront(this.cardWidth, this.cardHeight);
        cardFrontContent.forEach((item) => {
            this.addContentToFront(item);
        });

        if (this.isBackgroundHidden) {
            this.hideBackground();
        }
    };

    private createCardFront(cardWidth: number, cardHeight: number) {
        let background = PhaserCardHelper.createBackgroundImage(
            this.scene,
            PhaserImages.CARD_FRONT_BACKGROUND,
            cardWidth,
            cardHeight,
            this.keepScale,
            () => {
                this.onCardClicked();
            }
        );
        let type = this.categoryTypeOverride
            ? this.categoryTypeOverride
            : this.activityItem.typeForLessonSelection(this.activityStore.lessonSelection);
        switch (type) {
            case CategoryType.Vocabulary:
                let answerXPos = 0.65;
                let imageXPos = -0.2;
                let imageScale = 0.7;
                let answerText = PhaserCardHelper.createText(
                    this.scene,
                    PhaserCardContent.ANSWER,
                    this.activityItem.answer.text,
                    cardWidth,
                    cardHeight,
                    answerXPos,
                    () => {
                        this.onCardClicked();
                    },
                    undefined,
                    this.fontSizeMultiplier
                );
                let vocabularyImage = PhaserCardHelper.createVocabularyImage(
                    this.scene,
                    PhaserCardContent.IMAGE,
                    this.activityItem.key,
                    cardWidth,
                    cardHeight,
                    imageScale,
                    imageXPos
                );
                return [background, answerText, vocabularyImage];
            case CategoryType.VocabularyImageOnly:
                imageXPos = 0;
                imageScale = 0.9;
                vocabularyImage = PhaserCardHelper.createVocabularyImage(
                    this.scene,
                    PhaserCardContent.IMAGE,
                    this.activityItem.key,
                    cardWidth,
                    cardHeight,
                    imageScale,
                    imageXPos
                );
                return [background, vocabularyImage];
            case CategoryType.VocabularyTextOnly:
                answerText = PhaserCardHelper.createText(
                    this.scene,
                    PhaserCardContent.ANSWER,
                    this.activityItem.answer.text,
                    cardWidth,
                    cardHeight,
                    0,
                    () => {
                        this.onCardClicked();
                    },
                    undefined,
                    this.fontSizeMultiplier
                );
                return [background, answerText];
            case CategoryType.Expression:
                imageScale = 0.6;
                answerText = PhaserCardHelper.createText(
                    this.scene,
                    PhaserCardContent.ANSWER,
                    this.activityItem.answer.text,
                    cardWidth,
                    cardHeight,
                    0.7,
                    () => {
                        this.onCardClicked();
                    },
                    undefined,
                    this.fontSizeMultiplier
                );
                vocabularyImage = PhaserCardHelper.createVocabularyImage(
                    this.scene,
                    PhaserCardContent.IMAGE,
                    this.activityItem.key,
                    cardWidth,
                    cardHeight,
                    imageScale,
                    0
                );
                return [background, vocabularyImage, answerText];
            case CategoryType.ExpressionTextOnly:
                answerText = PhaserCardHelper.createText(
                    this.scene,
                    PhaserCardContent.ANSWER,
                    this.activityItem.answer.text,
                    cardWidth,
                    cardHeight,
                    0,
                    () => {
                        this.onCardClicked();
                    },
                    undefined,
                    this.fontSizeMultiplier
                );
                return [background, answerText];
        }
        return [];
    }

    private createCardBack(cardWidth: number, cardHeight: number) {
        let background = PhaserCardHelper.createBackgroundImage(
            this.scene,
            PhaserImages.CARD_BACK_BACKGROUND,
            cardWidth,
            cardHeight,
            this.keepScale,
            () => {
                this.onCardClicked();
            }
        );
        return [background];
    }
}
