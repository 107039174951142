import { firebaseApp } from "./";

import {
    IMAGES_STORAGE_PATH,
    PROFILE_IMAGES_PATH,
    STUDENT_PROFILE_TEMP_PATH,
    MEMBER_PROFILE_TEMP_PATH,
    AUDIO_STORAGE_PATH,
} from "./constants";

export default class FirebaseStorage {
    // Storage
    // ----------------------------------

    public static categoryImagesStorageRef() {
        return firebaseApp.storage().ref(IMAGES_STORAGE_PATH);
    }

    public static profileImagesStorageRef() {
        return firebaseApp.storage().ref(PROFILE_IMAGES_PATH);
    }

    public static profileImage(studentKey: string, fileName: string) {
        return STUDENT_PROFILE_TEMP_PATH + `${studentKey}/${fileName}`;
    }

    public static studentsProfileImagesUploadStorageRef(studentKey: string, file: string): firebase.storage.Reference {
        return firebaseApp.storage().ref(this.profileImage(studentKey, file));
    }

    public static memberProfileImageStorageRef(uid: string, file: string): firebase.storage.Reference {
        return firebaseApp.storage().ref(MEMBER_PROFILE_TEMP_PATH + `/${uid}/${file}`);
    }

    public static audioClipsStorageRefForFile(file: string): firebase.storage.Reference {
        return firebaseApp.storage().ref(AUDIO_STORAGE_PATH + file);
    }
}
