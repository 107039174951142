import * as React from "react";

import ActivityFooter from "components/activities/ActivityFooter";
import { Row } from "layout";
import Button from "@material-ui/core/Button";

interface SingleButtonFooterProps {
    text: string;
    onClick: () => void;
}

export const SingleButtonFooter = (props: SingleButtonFooterProps) => (
    <ActivityFooter>
        <Row end="xs" style={{ paddingRight: 10 }}>
            <Button variant="contained" color="primary" onClick={props.onClick}>
                {props.text}
            </Button>
        </Row>
    </ActivityFooter>
);
