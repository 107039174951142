import * as React from "react";
import { observer, inject } from "mobx-react";
import { RootStore } from "stores";

interface HomeIconProps {
    rootStore?: RootStore;
    variant: "icon" | "logo";
    height?: number;
}

const DEFAULT_HEIGHT = 35;

@inject("rootStore")
@observer
export default class HomeIcon extends React.Component<HomeIconProps> {
    render() {
        let styles = {
            logo: {
                height: this.props.height ? this.props.height : DEFAULT_HEIGHT,
                cursor: "pointer",
            },
        };

        let icon: string;
        if (this.props.variant === "logo") {
            icon = require(`../assets/images/logo.png`);
        } else {
            icon = require(`../assets/images/icon.png`);
        }
        return <img style={styles.logo} src={icon} onClick={this.onLogoClicked} />;
    }

    private onLogoClicked = () => {
        this.props.rootStore.setRoute("/");
    };
}
