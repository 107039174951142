export class Colors {
    public static black: string = "#000000";
    public static white: string = "#ffffff";
    public static grey: string = "#808080";
    public static red: string = "#ff0000";
    public static yellow: string = "#ffff00";
    public static green: string = "#008000";
    public static purple: string = "#800080";
    public static highlightYellow = "#fffee0";
    public static dialogBoxGrey: string = "#f5f5f5";
    public static veryLightGrey: string = "#efefef";
    public static lightGrey: string = "#dddddd";
    public static darkGrey: string = "#444444";
    public static lightBlue: string = "#148aff";
    public static googleBlue: string = "#4285F4";
    public static facebookBlue: string = "#4267b2";
    public static twitterBlue: string = "#1DA1F2";
    public static orange: string = "#FFA500";
    public static clear: string = "clear";

    public static blackHex: number = 0x000000;
    public static whiteHex: number = 0xffffff;
    public static lightBlueHex: number = 0x148aff;
    public static lightGreyHex: number = 0xdddddd;
    public static darkGreyHex: number = 0x444444;
    public static highlightYellowHex: number = 0xfffee0;

    public static get transparent() {
        return `rgba(255, 255, 255, 0)`;
    }

    public static get translucentWhite() {
        return `rgba(255, 255, 255, .7)`;
    }

    public static get translucentBlack() {
        return `rgba(0, 0, 0, .5)`;
    }

    public static rgba(red: number, green: number, blue: number, opacity: number): string {
        return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
    }
}
