import { log } from "../";

import * as CustomLists from "../models/customLists";
import { AudioClipVoice, Languages } from "../models";

import FirebaseFunctions from "../core/firebase/FirebaseFunctions";
import { Firestore } from "../core/firebase";
import { Howl } from "howler";
import { CollectionReference } from "firebase/firebase-firestore";
import { AudioSourceType } from "models/customLists/CategoryItem";

export enum AudioLoadState {
    NO_AUDIO,
    LOADING,
    LOADED,
}

export class AudioStore {
    private audioClipCache = new Map<string, any>();

    public loadSound = (id: string) => {
        this.audioClipCache[id] = new Howl({
            src: [id],
            format: ["mp3"],
            html5: true,
        });
    };

    public playSound = (id: string, callback?: () => void) => {
        if (!id && callback) {
            callback();
            return;
        }

        const selfCleaningCallback = () => {
            if (callback) {
                callback();
            }
            this.audioClipCache[id]._onend = [];
        };

        if (this.audioClipCache[id]) {
            if (callback) {
                this.audioClipCache[id].on("end", selfCleaningCallback);
            }
            this.audioClipCache[id].play();
        } else {
            let sound = new Howl({
                src: [id],
                format: ["mp3"],
                autoplay: true,
                html5: true,
                onend: selfCleaningCallback,
                onplayerror: (playID, error) => {
                    sound.once("unlock", () => {
                        sound.play();
                    });
                    log.error(error);
                },
            });
            this.audioClipCache[id] = sound;
        }
    };

    public playLoop = (id: string) => {
        if (!id) {
            return;
        }

        let cacheId = id + "_loop";
        if (this.audioClipCache[cacheId]) {
            this.audioClipCache[cacheId].play();
        } else {
            let sound = new Howl({
                src: [id],
                format: ["mp3"],
                autoplay: true,
                html5: true,
                loop: true,
                onplayerror: (playID, error) => {
                    sound.once("unlock", () => {
                        sound.play();
                    });
                    log.error(error);
                },
            });
            this.audioClipCache[cacheId] = sound;
        }
    };

    public stopSound = (id: string) => {
        if (this.audioClipCache[id]) {
            this.audioClipCache[id]._onend = [];
            this.audioClipCache[id].stop();
        }
    };

    public stopAll = () => {
        for (let key of Object.keys(this.audioClipCache)) {
            let audioClip = this.audioClipCache[key];
            if (audioClip.playing()) {
                this.stopSound(key);
            }
        }
    };

    public fetchAudioForText = async (text: string, language: AudioClipVoice) => {
        try {
            let ref = Firestore.categoryAudioCollection() as CollectionReference;
            let languageNum = Languages.languageTypeToNum(language);

            // First check if we have google version
            let querySnapshot = await ref
                .where("text", "==", text.toLowerCase())
                .where("language", "==", language)
                .where("source", "==", "google")
                .get();

            if (querySnapshot.docs.length === 0) {
                querySnapshot = await ref
                    .where("text", "==", text.toLowerCase())
                    .where("language", "==", languageNum)
                    .where("source", "==", "google")
                    .get();
            }

            // Old way of storing was to use laguage ID
            if (querySnapshot.docs.length === 0) {
                querySnapshot = await ref
                    .where("text", "==", text.toLowerCase())
                    .where("language", "==", languageNum)
                    .get();
            }

            // New way is to use laguage code (e.g. "usenglish")
            if (querySnapshot.docs.length === 0) {
                querySnapshot = await ref
                    .where("text", "==", text.toLowerCase())
                    .where("language", "==", language)
                    .get();
            }

            if (querySnapshot.docs.length > 0) {
                let key = querySnapshot.docs[0].id;
                let data = querySnapshot.docs[0].data();
                if (data !== undefined && data !== null) {
                    // Add category details to search category cache
                    console.log(data);
                    let audioClip = new CustomLists.AudioClip();
                    audioClip.initWithFirebaseObject(key, data);

                    if (audioClip.source === AudioSourceType.Google) {
                        log.verbose("Found existing audio clip for text: " + text);
                        return audioClip;
                    } else {
                        log.verbose(
                            "Found existing audio clip for text: " + text + ", but need to update to Google audio."
                        );
                        try {
                            FirebaseFunctions.updateAudioClipToGoogle(audioClip);
                        } catch (error) {
                            log.error("Error updating audio to Google audio. " + error);
                        }
                        return audioClip;
                    }
                }
            } else {
                log.verbose("Could not find existing audio clip for text: " + text + ". Attempting to download.");
                let audioClip = await this.downloadAudioForText(text, language);
                log.verbose(audioClip);
                return audioClip;
            }
        } catch (error) {
            throw error;
        }
        return undefined;
    };

    public downloadAudioForText = async (text: string, language: AudioClipVoice): Promise<CustomLists.AudioClip> => {
        try {
            let fetchAudioResponse = await FirebaseFunctions.fetchAudio(text, language);
            let audioClip = new CustomLists.AudioClip();
            audioClip.text = text;
            audioClip.language = language;
            audioClip.file = fetchAudioResponse.file;

            return audioClip;
        } catch (error) {
            log.error(error);
            return null;
        }
    };

    // public uploadMP3 = (blob: any): Promise<string> => {
    //     return new Promise<string>((resolve, reject) => {
    //         let filename = Utils.guid() + ".mp3";
    //         let ref = FirebaseStorage.audioClipsStorageRefForFile(filename);
    //         let uploadTask = ref.put(blob);
    //         uploadTask.on(
    //             "state_changed", // On progress
    //             function(snapshot: firebase.storage.UploadTaskSnapshot) {
    //                 log.verbose((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    //             }.bind(this), // On error
    //             function(error: firebase.FirebaseError) {
    //                 log.verbose(error);
    //                 // Handle unsuccessful uploads
    //                 reject(error);
    //             }.bind(this), // On complete
    //             function() {
    //                 log.verbose("Complete!");
    //                 resolve(filename);
    //             }.bind(this)
    //         );
    //     });
    // };
}
