declare let FontFace: any;

export enum PhaserFont {
    Tracing = "Tracing",
    TracingLined = "TracingLined",
    CuteIcons = "CuteIcons",
    GoogleEmojis = "GoogleEmojis",
    KawaiiFood = "KawaiiFood",
}

export default class PhaserFontLoader {
    public static load(fonts: PhaserFont[]) {
        fonts.forEach((font) => PhaserFontLoader.loadFont(font));
    }

    public static loadFont(name) {
        let url = PhaserFontLoader.fontUrlForName(name);
        var newFont = new FontFace(name, `url(${url})`);
        newFont
            .load()
            .then(function (loaded) {
                (document as any).fonts.add(loaded);
            })
            .catch(function (error) {
                return error;
            });
    }

    private static fontUrlForName = (name: PhaserFont) => {
        switch (name) {
            case PhaserFont.Tracing:
                return require("assets/fonts/KGPrimaryDots.ttf");
            case PhaserFont.TracingLined:
                return require("assets/fonts/KGPrimaryDotsLined.ttf");
            case PhaserFont.CuteIcons:
                return require("assets/fonts/CuteIcons.ttf");
            case PhaserFont.GoogleEmojis:
                return require("assets/fonts/GoogleEmojis.ttf");
            case PhaserFont.KawaiiFood:
                return require("assets/fonts/KawaiiFood.ttf");
        }
    };
}
