import * as React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

// Material UI
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Models
import { BillingFrequency } from "../../models";

// Stores
import { UserStore } from "../../stores";

// Utils
import { strings } from "../../core/localizedStrings";
import Utils from "../../core/utils/Utils";
import * as PaymentUtils from "../../core/utils/PaymentUtils";
import { Typography } from "@material-ui/core";

// Layout
var Row = require("react-flexbox-grid").Row;

export interface BillingFrequencyRadiosProps extends React.Props<any> {
    userStore: UserStore;
    onBillingFrequencyChangedCallback: (billingFrequency: BillingFrequency) => void;
}

@observer
export default class BillingFrequencyRadios extends React.Component<BillingFrequencyRadiosProps> {
    @observable private selectedBillingFrequency: BillingFrequency = BillingFrequency.Monthly;
    @observable private monthlyLabel = strings.billedMonthly;
    @observable private annualLabel = strings.billedAnnually;

    @action
    setMonthlyLabel(value: string) {
        this.monthlyLabel = value;
    }

    @action
    setAnnualLabel(value: string) {
        this.annualLabel = value;
    }

    async componentDidMount() {
        let amount = Utils.centsToDollarString(this.getAmountForBillingFrequency(BillingFrequency.Monthly));
        this.setMonthlyLabel(strings.formatString(strings.billedMonthlyFormat, amount) as string);

        amount = Utils.centsToDollarString(this.getAmountForBillingFrequency(BillingFrequency.Annually));
        this.setAnnualLabel(strings.formatString(strings.billedAnnuallyFormat, amount) as string);
    }

    getAmountForBillingFrequency = (billingFrequency: BillingFrequency) => {
        return PaymentUtils.calculateTotal(
            billingFrequency,
            this.props.userStore.user.managedTeachersCount,
            this.props.userStore.user.managedStudentGroupsCount
        );
    };

    render() {
        return (
            <>
                <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
                    <Typography variant="body1">{strings.billingFrequency}</Typography>
                </Row>
                <Row style={{ paddingLeft: 30 }}>
                    <FormControl>
                        <RadioGroup
                            name="billingFrequency"
                            value={this.selectedBillingFrequency}
                            onChange={this.onBillingFrequencyChanged}
                        >
                            <FormControlLabel
                                style={{ marginTop: -5 }}
                                value={BillingFrequency.Monthly}
                                control={<Radio />}
                                label={this.monthlyLabel}
                            />
                            <FormControlLabel
                                style={{ marginTop: -20 }}
                                value={BillingFrequency.Annually}
                                control={<Radio />}
                                label={this.annualLabel}
                            />
                        </RadioGroup>
                    </FormControl>
                </Row>
            </>
        );
    }

    @action
    private onBillingFrequencyChanged = (event: any, value: string) => {
        this.selectedBillingFrequency = value as BillingFrequency;
        this.props.onBillingFrequencyChangedCallback(this.selectedBillingFrequency);
    };
}
