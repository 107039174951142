import * as React from "react";
import { Observer } from "mobx-react-lite";
import { ActivityStore } from "stores";
import { GameOptions } from "../GameScene";
import { CardType } from "models/customLists/CategoryItem";
import { CardTypeButtons } from "components/activities/CommonActivityOptions";
import { TextField, InputAdornment, Grid } from "@material-ui/core";
import { OptionsGroup } from "components/activities/OptionsGroup";
import { strings } from "core/localizedStrings";
import SimpleRadio from "components/SimpleRadio";

export enum MemoryWheelDifficulty {
    EASY = "easy",
    MEDIUM = "medium",
    HARD = "hard",
}

export const defaultMemoryWheelOptions: MemoryWheelGameOptions = {
    difficulty: MemoryWheelDifficulty.EASY,
    cardType: CardType.IMAGE_ONLY,
};

export interface MemoryWheelGameOptions extends GameOptions {
    difficulty: MemoryWheelDifficulty;
    cardType: CardType;
}

interface MemoryWheelGameOptionsProps {
    activityStore: ActivityStore;
}

const MemoryWheelGameOptions = (props: MemoryWheelGameOptionsProps) => {
    const handleDifficultyChanged = (value: string) => {
        props.activityStore.activityOptions.memoryWheelOptions.difficulty = value as MemoryWheelDifficulty;
        props.activityStore.updateOptions();
    };

    const handleCardTypeChanged = (value: any) => {
        props.activityStore.activityOptions.memoryWheelOptions.cardType = value;
        props.activityStore.updateOptions();
    };

    return (
        <Observer
            render={() => (
                <>
                    <CardTypeButtons
                        activityStore={props.activityStore}
                        onCardTypeChanged={handleCardTypeChanged}
                        selectedType={props.activityStore.activityOptions.memoryWheelOptions.cardType}
                    />
                    <OptionsGroup heading={strings.game}>
                        <SimpleRadio
                            defaultValue={props.activityStore.activityOptions.memoryWheelOptions.difficulty}
                            labels={["Easy", "Medium", "Hard"]}
                            values={[
                                MemoryWheelDifficulty.EASY,
                                MemoryWheelDifficulty.MEDIUM,
                                MemoryWheelDifficulty.HARD,
                            ]}
                            onValueChanged={handleDifficultyChanged}
                        />
                    </OptionsGroup>
                </>
            )}
        />
    );
};

export default MemoryWheelGameOptions;
