import * as React from "react";
import { Observer } from "mobx-react-lite";

import { Grid, TextField, InputAdornment } from "@material-ui/core";
import { strings } from "core/localizedStrings";
import { ActivityStore } from "stores";
import { CardTypeButtons, FontSizeButtons } from "components/activities/CommonActivityOptions";
import { CardType } from "models/customLists/CategoryItem";

export const defaultCrazyCupsOptions: CrazyCupsOptions = {
    cardType: CardType.IMAGE_WORD,
    userSpeedAdjustment: 0,
    fontSizeMultiplier: 1,
};

export interface CrazyCupsOptions {
    cardType: CardType;
    userSpeedAdjustment: number;
    fontSizeMultiplier: number;
}

interface CrazyCupsOptionsProps extends React.Props<any> {
    activityStore: ActivityStore;
    disabled?: boolean;
}

const MIN_SPEED_ADJUSTMENT = -50;
const MAX_SPEED_ADJUSTMENT = 50;

const CrazyCupsOptions = (props: CrazyCupsOptionsProps) => {
    const handleCardTypeChanged = (cardType: CardType) => {
        props.activityStore.activityOptions.crazyCupsOptions.cardType = cardType;
        props.activityStore.updateOptions();
    };

    const handleSpeedAdjustmentChanged = (event: any) => {
        let value = event.target.value;
        if (value < MIN_SPEED_ADJUSTMENT) {
            value = MIN_SPEED_ADJUSTMENT;
        } else if (value > MAX_SPEED_ADJUSTMENT) {
            value = MAX_SPEED_ADJUSTMENT;
        }
        props.activityStore.activityOptions.crazyCupsOptions.userSpeedAdjustment = value;
    };

    const handleFontSizeDecremented = () => {
        const MIN_FONT_SIZE = 0.5;
        props.activityStore.activityOptions.crazyCupsOptions.fontSizeMultiplier -= 0.1;
        if (props.activityStore.activityOptions.crazyCupsOptions.fontSizeMultiplier < MIN_FONT_SIZE) {
            props.activityStore.activityOptions.crazyCupsOptions.fontSizeMultiplier = MIN_FONT_SIZE;
        }
    };

    const handleFontSizeIncremented = () => {
        const MAX_FONT_SIZE = 3;
        props.activityStore.activityOptions.crazyCupsOptions.fontSizeMultiplier += 0.1;
        if (props.activityStore.activityOptions.crazyCupsOptions.fontSizeMultiplier > MAX_FONT_SIZE) {
            props.activityStore.activityOptions.crazyCupsOptions.fontSizeMultiplier = MAX_FONT_SIZE;
        }
    };

    const disabled = props.disabled === undefined ? false : props.disabled;

    return (
        <Observer
            render={() => (
                <>
                    <CardTypeButtons
                        activityStore={props.activityStore}
                        onCardTypeChanged={handleCardTypeChanged}
                        selectedType={props.activityStore.activityOptions.crazyCupsOptions.cardType}
                    />
                    <FontSizeButtons
                        onFontSizeDecremented={handleFontSizeDecremented}
                        onFontSizeIncremented={handleFontSizeIncremented}
                    />
                    <TextField
                        label={strings.cupsSpeedAdjustment}
                        disabled={disabled}
                        autoComplete="off"
                        id="textField"
                        onChange={handleSpeedAdjustmentChanged}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        style={{ minWidth: 200 }}
                        inputProps={{
                            type: "number",
                            min: MIN_SPEED_ADJUSTMENT,
                            max: MAX_SPEED_ADJUSTMENT,
                            step: 5,
                            textAlign: "right",
                        }}
                        defaultValue={props.activityStore.activityOptions.crazyCupsOptions.userSpeedAdjustment}
                    />
                </>
            )}
        />
    );
};

export default CrazyCupsOptions;
