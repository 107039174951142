import * as React from "react";
import { observable, runInAction } from "mobx";

import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";

import TranslationsDB from "./TranslationsDB";

import { strings } from "core/localizedStrings";
import { RootStore } from "stores";

export interface TranslationBaseProps extends React.Props<any> {
    rootStore: RootStore;
}

export default class TranslationBase extends React.Component<TranslationBaseProps> {
    @observable
    protected selectedLanguage;

    /* tslint:disable:no-string-literal */
    @observable
    protected languages = strings["_props"];
    /* tslint:enable:no-string-literal */

    @observable
    protected allStringsDictionary: Map<string, string>;

    constructor(props: TranslationBaseProps) {
        super(props);
        this.selectedLanguage = Object(props).match.params.language;
        this.getAllTranslations();
    }

    render() {
        return (
            <Paper style={{ padding: 40, margin: 20 }}>
                <Toolbar>
                    <>{this.renderToolbar()}</>
                </Toolbar>
                {this.renderChildren()}
            </Paper>
        );
    }

    protected renderToolbar() {
        // overrideen in subclass
    }

    protected renderChildren() {
        // overridden in subclass
    }

    protected onUpdateCurrentEnglish = () => {
        // overridden in subclass
    };

    protected onCreateNewRevisionClicked = () => {
        // overridden in subclass};
    };

    protected onSaveClicked = () => {
        // overridden in subclass
    };

    protected getAllTranslations = () => {
        TranslationsDB.getAllTranslations(this.selectedLanguage, (dictionary: Map<string, string>) => {
            runInAction(() => {
                this.allStringsDictionary = dictionary;
            });
        });
    };

    protected updateDictionaryWithStrings(fromStrings: Map<string, string>) {
        for (let key of Object.keys(fromStrings)) {
            if (fromStrings[key] !== undefined && fromStrings[key] !== "") {
                this.allStringsDictionary[key] = fromStrings[key];
            } else {
                delete this.allStringsDictionary[key];
            }
        }
        TranslationsDB.updateAllTranslations(this.selectedLanguage, this.allStringsDictionary);
    }

    protected updateStringsFromDictionary(stringsToUpdate: Map<string, string>) {
        for (let key of Object.keys(this.allStringsDictionary)) {
            if (stringsToUpdate[key] && stringsToUpdate[key] === "") {
                let value = this.allStringsDictionary[key];
                stringsToUpdate[key] = value;
            }
        }
        return stringsToUpdate;
    }
}
