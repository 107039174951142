import * as React from "react";

// Components
import ContactForm from "../../components/ContactForm";

// Stores
import RootStore from "../../stores/RootStore";

// Utils
import { Colors } from "../../core/utils/Colors";
import { StringUtils } from "../../core/utils/StringUtils";
import { strings } from "../../core/localizedStrings";
import { DynamicTypographyType, DynamicTypography } from "core/utils/DynamicTypography";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

export interface ContactRowProps extends React.Props<any> {
    store: RootStore;
}

let styles = {
    container: { maxWidth: 1000, padding: "40px 20px" },
    text: { padding: 20, fontSize: 28, fontWeight: "bold", lineHeight: "28px" },
    contactForm: { width: "100%", paddingTop: 20 }
};

export default class ContactRow extends React.Component<ContactRowProps> {
    render() {
        return (
            <Row center="xs" style={{ backgroundColor: Colors.white }}>
                <Col xs={12} style={styles.container}>
                    <Row center="xs">
                        <DynamicTypography variant={DynamicTypographyType.H2} style={styles.text}>
                            {StringUtils.createHighlightedText(strings.homePageContactMessage, Colors.lightBlue)}
                        </DynamicTypography>
                    </Row>
                    <Row center="xs" style={styles.contactForm}>
                        <ContactForm store={this.props.store} />
                    </Row>
                </Col>
            </Row>
        );
    }
}
