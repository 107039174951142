import Activity from "./Activity";

export enum WorksheetType {
    Bingo = "Bingo Cards",
    ConnectTheLetters = "Connect The Letters",
    Crossword = "Crossword",
    LaddersAndSlides = "Ladders And Slides",
    Matching = "Matching",
    MissingLetters = "Missing Letters",
    QAndA = "Q And A",
    Racetrack = "Racetrack",
    Reading = "Reading",
    SentenceDecoder = "Sentence Decoder",
    SentenceScramble = "Sentence Scramble",
    TicTacToe = "Tic-Tac-Toe",
    Tracing = "Tracing",
    WordScramble = "Word Scramble",
    Wordsearch = "Wordsearch",
    YellowBrickRoad = "Yellow Brick Road",
}

export default class Worksheet extends Activity {
    public static allItems: Worksheet[] = [
        new Worksheet(WorksheetType.Bingo),
        new Worksheet(WorksheetType.ConnectTheLetters),
        new Worksheet(WorksheetType.Crossword),
        new Worksheet(WorksheetType.LaddersAndSlides),
        new Worksheet(WorksheetType.Matching),
        new Worksheet(WorksheetType.MissingLetters),
        new Worksheet(WorksheetType.QAndA),
        new Worksheet(WorksheetType.Racetrack),
        new Worksheet(WorksheetType.Reading),
        new Worksheet(WorksheetType.SentenceDecoder),
        new Worksheet(WorksheetType.SentenceScramble),
        new Worksheet(WorksheetType.TicTacToe),
        new Worksheet(WorksheetType.Tracing),
        new Worksheet(WorksheetType.WordScramble),
        new Worksheet(WorksheetType.Wordsearch),
        // new Worksheet(WorksheetType.YellowBrickRoad)
    ];

    public type: WorksheetType;

    protected get prefix() {
        return "worksheets";
    }
}
