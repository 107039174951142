import { log } from "../../";

import * as React from "react";
import { withRouter } from "react-router-dom";
import { observable, action, runInAction } from "mobx";
import { observer, inject } from "mobx-react";

// Material UI
import Snackbar from "@material-ui/core/Snackbar";

// Components
import ActivityStudentCard from "components/activities/ActivityStudentCard";
import ActivityOptionsBar from "./ActivityOptionsBar";

// Stores
import { RootStore } from "../../stores";

// Models
import {
    FlashcardActivity,
    Game,
    InteractiveWorksheet,
    Tool,
    InteractiveWorksheetType,
    Quiz,
    QuizType,
    FlashcardActivityType,
    ToolType,
    GameType,
    Worksheet,
    WorksheetType,
} from "../../models";

// Utils
import { updateCurrentActivity, activityFromPath } from "../../core/utils/ActivityUtils";
import { checkTeams } from "core/utils/TeamUtils";
import { LazyLoader } from "core/utils/LazyLoading";

// Layout
import Utils from "core/utils/Utils";
import NavigationDrawer from "components/navigation/NavigationDrawer";
import GroupMaker from "components/activities/tools/GroupMaker";
import ActivitySupport from "core/utils/ActivitySupport";
import Activity from "models/activities/Activity";
import ActivityLinkDialog from "./ActivityLinkDialog";
import { Grid } from "@material-ui/core";
import { InteractiveFlashcardsContextProvider } from "./flashcards/InteractiveFlashcardsState";
import { ActivityStateContextProvider } from "./ActivityState";
import { QuizStateContextProvider } from "./quizzes/QuizState";
import SoundEffectPlayer from "core/utils/SoundEffectPlayer";

// Lazy Imports
const InteractiveFlashcardsActivityLazy = React.lazy(
    () => import("components/activities/flashcards/InteractiveFlashcardsContainer")
);
const PrintableFlashcards = React.lazy(
    () => import("components/activities/flashcards/PrintableFlashcards/PrintableFlashcards")
);
const MatchingWorksheetActivityLazy = React.lazy(
    () => import("components/activities/interactiveWorksheets/Matching/MatchingWorksheetActivity")
);
const MissingLettersActivityLazy = React.lazy(
    () => import("components/activities/interactiveWorksheets/MissingLetters/MissingLettersActivity")
);
const MultipleChoiceQuizLazy = React.lazy(() => import("components/activities/quizzes/MultipleChoiceQuizContainer"));

const AngryWordsGame = React.lazy(() => import("components/activities/games/AngryWords/AngryWordsGameContainer"));
const TicTacToeGame = React.lazy(() => import("components/activities/games/TicTacToe/TicTacToeGameContainer"));
const HiddenPictureGame = React.lazy(
    () => import("components/activities/games/HiddenPicture/HiddenPictureGameContainer")
);
const HangmanGame = React.lazy(() => import("components/activities/games/Hangman/HangmanGameContainer"));
const CardMatchGame = React.lazy(() => import("components/activities/games/CardMatch/CardMatchGameContainer"));
const WordScrambleGame = React.lazy(() => import("components/activities/games/WordScramble/WordScrambleGameContainer"));
const CrazyCupsGame = React.lazy(() => import("components/activities/games/CrazyCups/CrazyCupsGameContainer"));
const BattleshipGame = React.lazy(() => import("components/activities/games/Battleship/BattleshipGameContainer"));
const HotOrColdGame = React.lazy(() => import("components/activities/games/HotOrCold/HotOrColdGameContainer"));
const TheWheelGame = React.lazy(() => import("components/activities/games/TheWheel/TheWheelGameContainer"));
const SharkBaitGame = React.lazy(() => import("components/activities/games/SharkBait/SharkBaitGameContainer"));
const TeamSpellingGame = React.lazy(() => import("components/activities/games/TeamSpelling/TeamSpellingGameContainer"));
const SpeedGame = React.lazy(() => import("components/activities/games/Speed/SpeedGameContainer"));
const MemoryWheelGame = React.lazy(() => import("components/activities/games/MemoryWheel/MemoryWheelGameContainer"));
const BingoCaller = React.lazy(() => import("components/activities/games/BingoCaller/BingoCallerContainer"));

const BingoCards = React.lazy(() => import("components/activities/worksheets/bingoCards/BingoCards"));
const ConnectTheLetters = React.lazy(
    () => import("components/activities/worksheets/connectTheLetters/ConnectTheLettersWorksheet")
);
const MatchingWorksheet = React.lazy(() => import("components/activities/worksheets/matching/MatchingWorksheet"));
const ReadingWorksheet = React.lazy(() => import("components/activities/worksheets/reading/ReadingWorksheet"));
const TracingWorksheet = React.lazy(() => import("components/activities/worksheets/tracing/TracingWorksheet"));
const CrosswordWorksheet = React.lazy(() => import("components/activities/worksheets/crossword/CrosswordWorksheet"));
const WordsearchWorksheet = React.lazy(() => import("components/activities/worksheets/wordsearch/WordsearchWorksheet"));
const MissingLettersWorksheet = React.lazy(
    () => import("components/activities/worksheets/missingLetters/MissingLettersWorksheet")
);
const TicTacToeWorksheet = React.lazy(() => import("components/activities/worksheets/ticTacToe/TicTacToeWorksheet"));
const SentenceDecoderWorksheet = React.lazy(
    () => import("components/activities/worksheets/sentenceDecoder/SentenceDecoderWorksheet")
);
const QAndAWorksheet = React.lazy(() => import("components/activities/worksheets/qAndA/QAndAWorksheet"));
const SentenceScrambleWorksheet = React.lazy(
    () => import("components/activities/worksheets/sentenceScramble/SentenceScrambleWorksheet")
);
const WordScrambleWorksheet = React.lazy(
    () => import("components/activities/worksheets/wordScramble/WordScrambleWorksheet")
);
const RacetrackWorksheet = React.lazy(() => import("components/activities/worksheets/racetrack/RacetrackWorksheet"));
const LaddersSlidesWorksheet = React.lazy(
    () => import("components/activities/worksheets/laddersSlides/LaddersSlidesWorksheet")
);

const TimerTool = React.lazy(() => import("components/activities/tools/timer/TimerTool"));
const RandomNamePicker = React.lazy(() => import("components/activities/tools/randomNamePicker/RandomNamePicker"));
const DartboardSelector = React.lazy(() => import("components/activities/tools/dartboardSelector/DartboardSelector"));
const Scoreboard = React.lazy(() => import("components/activities/tools/scoreboard/Scoreboard"));

interface ActivityLoaderProps extends React.Props<any> {
    rootStore: RootStore;
    location?: any;
}

@inject("rootStore")
@observer
export class ActivityLoader extends React.Component<ActivityLoaderProps> {
    @observable supportsSecondCategory: boolean = false;
    @observable shouldShowSWF: boolean = true;

    private activity: Activity;

    private activityRef: any;

    public componentWillMount() {
        this.setActivity(this.props);
    }

    public componentWillUpdate(props: ActivityLoaderProps) {
        this.setActivity(props);
    }

    private setActivity = async (props: ActivityLoaderProps) => {
        let { activityStore } = this.props.rootStore;

        if (
            this.props.rootStore.authStore &&
            this.props.rootStore.authStore.firebaseUser &&
            !this.props.rootStore.authStore.firebaseUser.isAnonymous &&
            !this.props.rootStore.authStore.firebaseUser.emailVerified
        ) {
            this.props.rootStore.setRoute("/");
        }

        if (activityStore && this.props.rootStore.activityStore.isHydrated) {
            activityStore.activityPath = this.props.location.pathname;
            this.activity = activityFromPath(activityStore.activityPath);
            updateCurrentActivity(activityStore, this.activity);

            if (!activityStore.currentCategory && !activityStore.shouldShowCreateListsView) {
                activityStore.setShouldShowBrowseListsView(!(this.activity instanceof Tool));
            }
        }
    };

    render() {
        let { activityStore } = this.props.rootStore;
        let pathName = this.props.location.pathname;

        this.activity = activityFromPath(pathName);
        updateCurrentActivity(activityStore, this.activity);

        let shouldRender = this.activity !== undefined && activityStore !== undefined && activityStore.isHydrated;

        if (!shouldRender) {
            return null;
        }

        if (!activityStore.currentCategory && !(this.activity instanceof Tool)) {
            if (!activityStore.shouldShowCreateListsView) {
                activityStore.setShouldShowBrowseListsView(true);
            }
            return null;
        }

        if (
            !this.props.rootStore.activityStore.currentStudentList &&
            this.activity instanceof Tool &&
            (this.activity.activityType === ToolType.RandomNamePicker ||
                this.activity.activityType === ToolType.DartboardSelector)
        ) {
            this.props.rootStore.activityStore.setShouldShowStudentListSelector(true);
            return null;
        }

        activityStore.activityPath = pathName;

        // Sets the number of teams based off of current activity
        checkTeams(this.props.rootStore);

        return (
            <Grid container direction="column" style={{ height: "100%" }}>
                <NavigationDrawer rootStore={this.props.rootStore} />
                {this.renderOptions()}
                {this.renderContent()}
                {this.props.rootStore.activityStore.shouldShowStudentSnackbar && (
                    <Snackbar open={true}>
                        <ActivityStudentCard student={activityStore.currentStudent} />
                    </Snackbar>
                )}
                <ActivityLinkDialog rootStore={this.props.rootStore} />
            </Grid>
        );
    }

    renderOptions() {
        let currentActivity = this.props.rootStore.activityStore.currentActivity;
        if (
            currentActivity instanceof FlashcardActivity ||
            currentActivity instanceof InteractiveWorksheet ||
            currentActivity instanceof Quiz ||
            currentActivity instanceof Tool ||
            (currentActivity instanceof Worksheet && !ActivitySupport.requiresFlash(currentActivity)) ||
            (currentActivity instanceof Game && !ActivitySupport.requiresFlash(currentActivity))
        ) {
            return null;
        }

        return (
            <ActivityOptionsBar
                rootStore={this.props.rootStore}
                activity={this.activity}
                supportsSecondCategory={this.supportsSecondCategory}
                gameEnded={this.props.rootStore.activityStore.didGameEnd}
                resetSWFContentCallback={this.resetSWFContent}
                endGameCallback={this.onEndGame}
                randomizeCallback={this.onRandomize}
                resetCallback={this.onReset}
            />
        );
    }

    renderContent() {
        let currentActivity = this.activity;
        let { activityStore } = this.props.rootStore;

        let activityContent: any = null;
        if (currentActivity == undefined) {
            return;
        }

        if (this.props.rootStore.activityStore === undefined) {
            return;
        }

        // if (activityStore.currentCategory && ActivitySupport.supportsDifferentLessonTypes(this.activity.activityType)) {
        //     if (activityStore.currentCategory.type === CategoryType.Lesson && !activityStore.lessonSelection) {
        //         return <LessonTypeSelector rootStore={this.props.rootStore} />;
        //     }
        // }

        if (currentActivity instanceof FlashcardActivity) {
            if (currentActivity.activityType === FlashcardActivityType.Interactive) {
                activityContent = (
                    <InteractiveFlashcardsContextProvider>
                        <InteractiveFlashcardsActivityLazy
                            key={activityStore.activityLoaderKey}
                            rootStore={this.props.rootStore}
                        />
                    </InteractiveFlashcardsContextProvider>
                );
            } else {
                activityContent = <PrintableFlashcards rootStore={this.props.rootStore} />;
            }
        } else if (currentActivity instanceof InteractiveWorksheet) {
            if (this.props.rootStore.activityStore.currentCategory) {
                switch (this.activity.activityType) {
                    case InteractiveWorksheetType.Matching:
                        activityContent = <MatchingWorksheetActivityLazy rootStore={this.props.rootStore} />;
                        break;
                    case InteractiveWorksheetType.MissingLetters:
                        activityContent = <MissingLettersActivityLazy rootStore={this.props.rootStore} />;
                        break;
                    default:
                        break;
                }
            }
        } else if (currentActivity instanceof Game && !ActivitySupport.requiresFlash(currentActivity)) {
            switch (currentActivity.activityType) {
                case GameType.AngryWords:
                    activityContent = <AngryWordsGame rootStore={this.props.rootStore} />;
                    break;
                case GameType.TicTacToe:
                    activityContent = <TicTacToeGame rootStore={this.props.rootStore} />;
                    break;
                case GameType.HiddenPicture:
                    activityContent = <HiddenPictureGame rootStore={this.props.rootStore} />;
                    break;
                case GameType.Hangman:
                    activityContent = <HangmanGame rootStore={this.props.rootStore} />;
                    break;
                case GameType.CardMatch:
                    activityContent = <CardMatchGame rootStore={this.props.rootStore} />;
                    break;
                case GameType.CrazyCups:
                    activityContent = <CrazyCupsGame rootStore={this.props.rootStore} />;
                    break;
                case GameType.Battleship:
                    activityContent = <BattleshipGame rootStore={this.props.rootStore} />;
                    break;
                case GameType.HotOrCold:
                    activityContent = <HotOrColdGame rootStore={this.props.rootStore} />;
                    break;
                case GameType.TheWheel:
                    activityContent = <TheWheelGame rootStore={this.props.rootStore} />;
                    break;
                case GameType.SharkBait:
                    activityContent = <SharkBaitGame rootStore={this.props.rootStore} />;
                    break;
                case GameType.TeamSpelling:
                    activityContent = <TeamSpellingGame rootStore={this.props.rootStore} />;
                    break;
                case GameType.Wordscramble:
                    activityContent = <WordScrambleGame rootStore={this.props.rootStore} />;
                    break;
                case GameType.Speed:
                    activityContent = <SpeedGame rootStore={this.props.rootStore} />;
                    break;
                case GameType.MemoryWheel:
                    activityContent = <MemoryWheelGame rootStore={this.props.rootStore} />;
                    break;
                case GameType.Bingo:
                    activityContent = <BingoCaller rootStore={this.props.rootStore} />;
                    break;
            }
        } else if (currentActivity instanceof Worksheet && !ActivitySupport.requiresFlash(currentActivity)) {
            switch (currentActivity.activityType) {
                case WorksheetType.Bingo:
                    activityContent = <BingoCards rootStore={this.props.rootStore} />;
                    break;
                case WorksheetType.ConnectTheLetters:
                    activityContent = <ConnectTheLetters rootStore={this.props.rootStore} />;
                    break;
                case WorksheetType.Reading:
                    activityContent = <ReadingWorksheet rootStore={this.props.rootStore} />;
                    break;
                case WorksheetType.Matching:
                    activityContent = <MatchingWorksheet rootStore={this.props.rootStore} />;
                    break;
                case WorksheetType.Tracing:
                    activityContent = <TracingWorksheet rootStore={this.props.rootStore} />;
                    break;
                case WorksheetType.Crossword:
                    activityContent = <CrosswordWorksheet rootStore={this.props.rootStore} />;
                    break;
                case WorksheetType.Wordsearch:
                    activityContent = <WordsearchWorksheet rootStore={this.props.rootStore} />;
                    break;
                case WorksheetType.MissingLetters:
                    activityContent = <MissingLettersWorksheet rootStore={this.props.rootStore} />;
                    break;
                case WorksheetType.TicTacToe:
                    activityContent = <TicTacToeWorksheet rootStore={this.props.rootStore} />;
                    break;
                case WorksheetType.QAndA:
                    activityContent = <QAndAWorksheet rootStore={this.props.rootStore} />;
                    break;
                case WorksheetType.SentenceDecoder:
                    activityContent = <SentenceDecoderWorksheet rootStore={this.props.rootStore} />;
                    break;
                case WorksheetType.SentenceScramble:
                    activityContent = <SentenceScrambleWorksheet rootStore={this.props.rootStore} />;
                    break;
                case WorksheetType.WordScramble:
                    activityContent = <WordScrambleWorksheet rootStore={this.props.rootStore} />;
                    break;
                case WorksheetType.Racetrack:
                    activityContent = <RacetrackWorksheet rootStore={this.props.rootStore} />;
                    break;
                case WorksheetType.LaddersAndSlides:
                    activityContent = <LaddersSlidesWorksheet rootStore={this.props.rootStore} />;
                    break;
            }
        } else if (currentActivity instanceof Quiz) {
            switch (this.activity.activityType) {
                case QuizType.MultipleChoice:
                    if (this.props.rootStore.activityStore.currentCategory) {
                        activityContent = <MultipleChoiceQuizLazy rootStore={this.props.rootStore} />;
                    }

                    break;
                default:
                    break;
            }
        } else if (this.activity.activityType === ToolType.GroupMaker) {
            activityContent = <GroupMaker rootStore={this.props.rootStore} />;
        } else if (this.activity.activityType === ToolType.Stopwatch) {
            activityContent = <TimerTool activityStore={this.props.rootStore.activityStore} />;
        } else if (this.activity.activityType === ToolType.RandomNamePicker) {
            activityContent = <RandomNamePicker activityStore={this.props.rootStore.activityStore} />;
        } else if (this.activity.activityType === ToolType.DartboardSelector) {
            activityContent = <DartboardSelector activityStore={this.props.rootStore.activityStore} />;
        } else if (this.activity.activityType === ToolType.Scoreboard) {
            activityContent = <Scoreboard activityStore={this.props.rootStore.activityStore} />;
        }
        return (
            <LazyLoader key={activityStore.activityLoaderKey}>
                <ActivityStateContextProvider>
                    <QuizStateContextProvider>{activityContent}</QuizStateContextProvider>
                </ActivityStateContextProvider>
            </LazyLoader>
        );
    }

    @action
    private resetSWFContent = () => {
        this.props.rootStore.activityStore.setDidGameEnd(false);

        runInAction(() => {
            this.props.rootStore.activityStore.setShouldShowStudentSnackbar(false);
            this.shouldShowSWF = false;
        });

        let id = setInterval(() => {
            runInAction(() => {
                this.shouldShowSWF = true;
            });
            clearInterval(id);
        }, 50);
    };

    private getNextStudent = () => {
        this.props.rootStore.activityStore.nextStudent();
    };

    private onEndGame = () => {
        this.props.rootStore.activityStore.setDidGameEnd(true);
        SoundEffectPlayer.stopAll();
    };

    private onReset = () => {
        this.props.rootStore.activityStore.shouldShowActivityOptions = false;
        SoundEffectPlayer.stopAll();
        this.resetSWFContent();
    };

    private onRandomize = () => {
        if (this.activityRef) {
            if (this.activityRef.onRandomize) {
                this.activityRef.onRandomize();
            } else if (this.activityRef.wrappedInstance && this.activityRef.wrappedInstance.onRandomize) {
                this.activityRef.wrappedInstance.onRandomize();
            }
        }
    };

    @action
    private clearStudentCard = () => {
        this.props.rootStore.activityStore.setShouldShowStudentSnackbar(false);
    };

    @action
    private enableSecondCategory = (value: boolean) => {
        log.verbose(`Enabling second category: ${value}`);
        this.supportsSecondCategory = value;
    };

    private getSecondCategory = () => {
        this.clearStudentCard();
        this.supportsSecondCategory = true;
        this.props.rootStore.activityStore.setIsSettingSecondCategory(true);
        this.props.rootStore.activityStore.setShouldShowBrowseListsView(true);
    };
}

// function Transition(props: any) {
//     return <Slide direction="up" {...props} />;
// }

export default withRouter((props) => <ActivityLoader {...props} />);
