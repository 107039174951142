import { log } from "../../";

import { findDOMNode } from "react-dom";
import * as React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

// Material UI
import Tooltip from "@material-ui/core/Tooltip";

// Custom UI
import * as Icons from "../../core/utils/Icons";
import Breadcrumbs from "../Breadcrumbs";
import StudentListEditorFetchList from "./StudentListEditorFetchList";
import StudentListEditorDialogs from "./StudentListEditorDialogs";
import StudentListEditor from "./StudentListEditor";

// Stores
import StudentListStore from "../../stores/StudentListStore";

// Models
import * as CustomLists from "../../models/customLists";
import { StudentList, Tool } from "../../models";

// Utils
import { WindowResizeChangeDispatcher } from "../../core/utils/WindowResizeChangeDispatcher";
import { strings } from "../../core/localizedStrings";
import Shadowbox from "components/Shadowbox";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Fab } from "@material-ui/core";
import { STUDENT_LIST_SELECTOR_INDEX } from "layers";
import { SingleButtonFooter } from "components/common/SingleButtonFooter";

// Layout
require("../../styles/commonStyles.css");

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

interface StudentListSelectorProps {
    store: StudentListStore;
}

const BOTTOM_PADDING: number = 10;

@observer
export default class StudentListSelector extends React.Component<StudentListSelectorProps> {
    @observable
    private listContainerHeight: number = 600;
    @observable
    private listHeight: number = 500;

    constructor(props: StudentListSelectorProps) {
        super(props);
        this.props.store.handleTabChange(this.props.store.currentTab);
    }

    componentDidMount() {
        WindowResizeChangeDispatcher.getInstance().addListener(this.onWindowResize);
        this.props.store.rootStore.setAppBarTitle(strings.selectStudentList);
    }

    componentWillUnmount() {
        WindowResizeChangeDispatcher.getInstance().removeListener(this.onWindowResize);
        this.props.store.rootStore.setAppBarTitle(undefined);
    }

    render() {
        log.verbose("Rendering StudentListSelector");
        return (
            <Shadowbox
                backgroundOpacity={0.7}
                zIndex={STUDENT_LIST_SELECTOR_INDEX}
                onBackgroundClicked={this.onShadowboxBackgroundClicked}
            >
                <>
                    <SingleButtonFooter text={strings.done} onClick={this.onShadowboxBackgroundClicked} />
                    <Row>
                        {this.props.store.itemToEdit === undefined ? (
                            <Col xs={12} id="listViewContainer" style={{ maxHeight: this.listContainerHeight }}>
                                {this.renderListView()}
                            </Col>
                        ) : (
                            <Col xs={12} id="listViewContainer" style={{ maxHeight: this.listContainerHeight - 60 }}>
                                <StudentListEditor store={this.props.store} listHeight={this.listHeight} />
                            </Col>
                        )}
                    </Row>
                    <StudentListEditorDialogs store={this.props.store} />
                </>
            </Shadowbox>
        );
    }

    private onShadowboxBackgroundClicked = () => {
        let { rootStore } = this.props.store;
        let { activityStore, studentListStore } = rootStore;
        if (studentListStore.itemToEdit) {
            // StudentListSelector is visible
            studentListStore.setItemToEdit(undefined);
        } else {
            if (activityStore.currentActivity instanceof Tool) {
                if (activityStore.currentStudentList && activityStore.currentStudentList.students.length > 0) {
                    activityStore.setShouldShowTeamMaker(true);
                    activityStore.setShouldShowStudentListSelector(false);
                }
            } else {
                if (activityStore.currentStudentList && activityStore.currentStudentList.students.length > 0) {
                    // activityStore.rebuidTeams();
                    activityStore.setCurrentStudentList(activityStore.currentStudentList.key); // Forces a rebuilding of teams
                }
                activityStore.setShouldShowStudentListSelector(false);
            }
        }
    };

    private renderListView() {
        let iconStyle = { width: 45, height: 45 };

        return (
            <div style={{ paddingTop: 53 }}>
                <Row center="xs">
                    <Col xs={12} style={{ width: "98%", maxWidth: 1000 }}>
                        <Row end="xs" style={{ paddingRight: 20 }}>
                            <Tooltip title={strings.createNewList} placement="bottom">
                                <Fab color="primary" onClick={this.onCreateListsButtonClicked} style={iconStyle}>
                                    <Icons.AddList color="secondary" />
                                </Fab>
                            </Tooltip>
                            <Tooltip title={strings.addFolder} placement="bottom">
                                <Fab color="primary" onClick={this.onAddFolderButtonClicked} style={iconStyle}>
                                    <Icons.AddFolder color="secondary" />
                                </Fab>
                            </Tooltip>
                        </Row>
                        <Row style={{ padding: "10px 10px 10px 20px" }}>
                            <Breadcrumbs store={this.props.store} />
                        </Row>
                        <Row>
                            <div
                                id="list"
                                style={{
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    width: "100%",
                                    maxHeight: this.listHeight - 70,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                }}
                            >
                                {this.props.store.isLoading && this.props.store.visibleItems.length === 0 && (
                                    <LinearProgress color="primary" />
                                )}
                                <StudentListEditorFetchList store={this.props.store} />
                            </div>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }

    @action
    private onCreateListsButtonClicked = () => {
        if (!this.props.store.rootStore.authStore.isSignedIn) {
            this.props.store.rootStore.showLoginRequiredMessage(strings.loginToCreateLists);
        } else {
            this.props.store.classListToEditTitle = new StudentList({});
        }
    };

    private onAddFolderButtonClicked = () => {
        if (!this.props.store.rootStore.authStore.isSignedIn) {
            this.props.store.rootStore.showLoginRequiredMessage(strings.loginToCreateFolders);
        } else {
            this.props.store.setFolderTitleToEdit(new CustomLists.Folder());
        }
    };

    @action
    private onWindowResize = (windowWidth: number, windowHeight: number) => {
        var listNode = findDOMNode(document.getElementById("list")) as Element;
        var listBoundingRect = listNode.getBoundingClientRect();

        var listContainerNode = findDOMNode(document.getElementById("listViewContainer")) as Element;
        var listContainerBoundingRect = listContainerNode.getBoundingClientRect();

        this.listHeight = windowHeight - listBoundingRect.top - BOTTOM_PADDING;
        this.listContainerHeight = windowHeight - listContainerBoundingRect.top - BOTTOM_PADDING;
    };
}
