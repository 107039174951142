import { AudioStore } from "stores";

export enum SoundEffect {
    APPLAUSE,
    AWW,
    BUZZ,
    BEEP,
    BEEP2,
    BOMB,
    BOMB_WHISTLE,
    BONUS,
    CASH,
    CHALK_WRITING,
    CHOMP,
    COLD,
    DRUM_ROLL,
    DRUM_ROLL_HIT,
    GAME_WINNER,
    HOT,
    NEGATIVE_BADUNK,
    MATCH,
    MAGIC_CHIME,
    POSITIVE_DING,
    POP,
    RESET,
    DOUBLE_SHOT,
    PEEK_A_BOO,
    SPLASH,
    TICK,
    WARM,
    WA_WA_WA,
}

export default class SoundEffectPlayer {
    private static _instance: SoundEffectPlayer;
    public audioStore: AudioStore;

    static play(sound: string, callback?: () => void) {
        if (SoundEffectPlayer.instance.audioStore) {
            SoundEffectPlayer.instance.audioStore.playSound(sound, callback);
        }
    }

    static loadSoundEffect(sound: SoundEffect) {
        if (SoundEffectPlayer.instance.audioStore) {
            SoundEffectPlayer.instance.audioStore.loadSound(SoundEffectPlayer.getSound(sound));
        }
    }
    static playSoundEffectLoop(soundEffect: SoundEffect) {
        if (SoundEffectPlayer.instance.audioStore) {
            SoundEffectPlayer.instance.audioStore.playLoop(SoundEffectPlayer.getSound(soundEffect));
        }
    }

    static playSoundEffect(soundEffect: SoundEffect, callback?: () => void) {
        if (SoundEffectPlayer.instance.audioStore) {
            SoundEffectPlayer.instance.audioStore.playSound(SoundEffectPlayer.getSound(soundEffect), callback);
        }
    }

    static stop(sound: string) {
        SoundEffectPlayer.instance.audioStore.stopSound(sound);
    }

    static stopAll() {
        SoundEffectPlayer.instance.audioStore.stopAll();
    }

    static getSound(effect: SoundEffect) {
        switch (effect) {
            case SoundEffect.APPLAUSE:
                return require("assets/soundEffects/applause.mp3");
            case SoundEffect.AWW:
                return require("assets/soundEffects/aww.mp3");
            case SoundEffect.BUZZ:
                return require("assets/soundEffects/buzz.mp3");
            case SoundEffect.BEEP:
                return require("assets/soundEffects/beep.mp3");
            case SoundEffect.BEEP2:
                return require("assets/soundEffects/beep2.mp3");
            case SoundEffect.BONUS:
                return require("assets/soundEffects/bonus.mp3");
            case SoundEffect.BOMB:
                return require("assets/soundEffects/bomb.mp3");
            case SoundEffect.BOMB_WHISTLE:
                return require("assets/soundEffects/bombWhistle.mp3");
            case SoundEffect.CASH:
                return require("assets/soundEffects/cash.mp3");
            case SoundEffect.CHALK_WRITING:
                return require("assets/soundEffects/writeChalk.mp3");
            case SoundEffect.CHOMP:
                return require("assets/soundEffects/chomp.mp3");
            case SoundEffect.COLD:
                return require("assets/soundEffects/cold.mp3");
            case SoundEffect.DRUM_ROLL:
                return require("assets/soundEffects/drumRoll.mp3");
            case SoundEffect.DRUM_ROLL_HIT:
                return require("assets/soundEffects/drumRollHit.mp3");
            case SoundEffect.GAME_WINNER:
                return require("assets/soundEffects/gameWinner.mp3");
            case SoundEffect.HOT:
                return require("assets/soundEffects/hot.mp3");
            case SoundEffect.MATCH:
                return require("assets/soundEffects/match.mp3");
            case SoundEffect.MAGIC_CHIME:
                return require("assets/soundEffects/magicChime.mp3");
            case SoundEffect.NEGATIVE_BADUNK:
                return require("assets/soundEffects/negative.mp3");
            case SoundEffect.POP:
                return require("assets/soundEffects/pop.mp3");
            case SoundEffect.POSITIVE_DING:
                return require("assets/soundEffects/positiveDing.mp3");
            case SoundEffect.DOUBLE_SHOT:
                return require("assets/soundEffects/doubleShot.mp3");
            case SoundEffect.PEEK_A_BOO:
                return require("assets/soundEffects/peekABoo.mp3");
            case SoundEffect.RESET:
                return require("assets/soundEffects/reset.mp3");
            case SoundEffect.SPLASH:
                return require("assets/soundEffects/splash.mp3");
            case SoundEffect.TICK:
                return require("assets/soundEffects/tick.wav");
            case SoundEffect.WARM:
                return require("assets/soundEffects/warm.mp3");
            case SoundEffect.WA_WA_WA:
                return require("assets/soundEffects/wawawawa.mp3");
        }
    }

    public static get instance() {
        return this._instance || (this._instance = new this());
    }
}
