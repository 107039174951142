import { log } from "../";

import { observable, action, runInAction } from "mobx";

// Stores
import { RootStore } from "./";

import { create } from "mobx-persist";
import * as localForage from "localforage";

export const hydrate = create({ storage: localForage, jsonify: true });

export default class PersistentStore {
    @observable public isHydrated: Boolean = false;
    public rootStore: RootStore;
    private label: string;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.label = this.constructor.name;

        let init = async () => {
            this.rootStore.setShowLoadingScreen(true);
            await this.hydrateStore(rootStore.userStore.user.uid);
            this.rootStore.setShowLoadingScreen(false);
        };
        init();
    }

    @action
    protected hydrateStore = async (uid: string) => {
        try {
            let hydratedStore = await hydrate(this.label + "_" + uid, this);
            if (hydratedStore) {
                runInAction(() => {
                    Object.assign(this, hydratedStore);
                });
                this.onStoreHydrated();
            }
        } catch {
            log.error("Count not hydrate store.");
            this.onStoreHydrated();
        }
    };

    protected onStoreHydrated() {
        // can be overridden in subclass
    }
}
