import * as React from "react";
import { observer } from "mobx-react";

// Material UI
import Typography from "@material-ui/core/Typography";
import Activity from "../../models/activities/Activity";

// Components
import { observable, runInAction } from "mobx";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Colors } from "core/utils/Colors";
import { Row, Col } from "layout";
import { DynamicTypography, DynamicTypographyType } from "core/utils/DynamicTypography";
import ActivitySupport from "core/utils/ActivitySupport";

// Utils
import { strings } from "core/localizedStrings";

export interface NavigationMenuProps extends React.Props<any> {
    type: NavigationMenuType;
    text: string;
    description: string;
    items: Activity[];
    itemsDescription?: string;
    items2?: Activity[];
    items2Description?: string;
    initialStateExpanded?: boolean;
    onExpansionChanged: (type: NavigationMenuType, value: boolean) => void;
    onActivitySelected?: (activity: Activity) => void;
    onDisplayComponentClickedCallback?: () => void;
}

export enum NavigationMenuType {
    Introduce,
    Practice,
    Play,
    Quiz,
    Tools,
}

@observer
export default class NavigationMenu extends React.Component<NavigationMenuProps> {
    @observable isExpanded = true;

    constructor(props: NavigationMenuProps) {
        super(props);
        if (props.initialStateExpanded !== undefined) {
            this.isExpanded = props.initialStateExpanded;
        }
        console.log(props.items);
    }

    render() {
        return (
            <ExpansionPanel
                onChange={this.handleExpandClicked}
                expanded={this.isExpanded}
                style={{
                    boxShadow: "0px 0px 0px",
                    backgroundColor: "transparent",
                }}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#f7f7f7" }}>
                    <Col xs={true}>
                        <Row>
                            <Typography style={{ fontWeight: "bold" }}>{this.props.text}</Typography>
                        </Row>
                        <Row>
                            <Typography style={{ paddingRight: 15 }}>{this.props.description}</Typography>
                        </Row>
                    </Col>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Col xs={true}>
                        {this.renderCards(this.props.items, this.props.itemsDescription)}
                        {this.props.items2 && (
                            <div style={{ paddingTop: 60 }}>
                                {this.renderCards(this.props.items2, this.props.items2Description)}
                            </div>
                        )}
                    </Col>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

    private handleExpandClicked = (event: object, expanded: boolean) => {
        runInAction(() => {
            this.isExpanded = expanded;
            this.props.onExpansionChanged(this.props.type, this.isExpanded);
        });
    };

    private renderCards(items: Activity[], description: string) {
        let style = {
            boxShadow: `0px 2px 2px`,
            maxHeight: 70,
            maxWidth: 70,
            margin: 2,
        };

        let border = {
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: Colors.white,
            borderRadius: 1,
        };
        style = Object.assign(style, border);

        let nonFlashItems = items.filter((item) => !ActivitySupport.requiresFlash(item));
        let flashItems = items.filter((item) => ActivitySupport.requiresFlash(item));
        let combinedItems = nonFlashItems.concat(flashItems);

        try {
            return (
                <>
                    {description && (
                        <Row>
                            &nbsp;&nbsp;
                            <Typography style={{ fontStyle: "italic", fontWeight: "bold" }}>{description}</Typography>
                        </Row>
                    )}
                    <Col xs={12}>
                        {combinedItems.map((item: Activity, index) => {
                            let path = require(`../../assets/images/screenshots/${item.imagePath}`);
                            return (
                                <Row
                                    key={index}
                                    middle="xs"
                                    onClick={this.onClick.bind(this, item)}
                                    style={{ padding: 5, cursor: "pointer" }}
                                >
                                    <img style={style} src={path} alt="" />
                                    &nbsp;&nbsp;
                                    <DynamicTypography variant={DynamicTypographyType.Subheading}>
                                        {item.activityType}
                                    </DynamicTypography>
                                </Row>
                            );
                        })}
                    </Col>
                </>
            );
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    // UI event handlers
    // ------------------------------------------
    private onClick = (activity: Activity) => {
        this.props.onActivitySelected(activity);
    };
}
