import * as React from "react";

import { RootStore } from "../../stores/";
import { Firestore } from "../firebase";

export interface LoggerProps extends React.Props<any> {
    rootStore: RootStore;
}

export default class Logger {
    public rootStore: RootStore;

    public verbose = (value: any, ...optionalParams: any[]) => {
        if (process.env.NODE_ENV !== "production" && value !== undefined) {
            if (optionalParams && optionalParams.length > 0) {
                console.log(value, optionalParams);
            } else {
                console.log(value);
            }
        }
    };

    public error = (value: string) => {
        if (process.env.NODE_ENV !== "production" && value !== undefined) {
            console.error(value);
        } else if (value !== undefined) {
            var err = this.getErrorObject();
            var cleanedStack = err.stack
                .split("\n")
                .filter((line: string) => !line.includes("getErrorObject"))
                .filter((line: string) => !line.includes("tryCatch"))
                .filter((line: string) => !line.includes("Promise"))
                .filter((line: string) => !line.includes("Generator"))
                .filter((line: string) => !line.includes("_next"))
                .filter((line: string) => !line.includes("_callee"));
            console.log(value, cleanedStack);
            this.writeErrorToFirestore(value, cleanedStack);
        }
    };

    private getErrorObject() {
        try {
            throw Error("");
        } catch (err) {
            return err;
        }
    }

    private writeErrorToFirestore(value: string, stack: string[]) {
        let error: any = { time: new Date() };
        error.error = value;
        error.stack = stack;
        if (this.rootStore && this.rootStore.userStore && this.rootStore.userStore.user) {
            error.user = this.rootStore.userStore.user.uid;
            try {
                Firestore.errorsCollection().add(error);
            } catch (error) {
                console.error("Cannot write error: " + error);
                console.error(value);
            }
        }
    }
}
