import Folder from "../Folder";
import Category from "./Category";
import { CategoryType } from "./Category";
import CategoryImage from "./CategoryImage";
import { AudioClip } from "./AudioClip";
import { Favorite } from "./Favorite";

// Export
export { Folder };
export { Category };
export { CategoryType };
export { CategoryImage };
export { AudioClip };
export { Favorite };

export class SiteListsFolder extends Folder {}
export class PublicListsFolder extends Folder {}
export class MyCustomListsFolder extends Folder {}
export class FavoritesFolder extends Folder {}
export class TheWheelListsFolder extends Folder {}

export interface CallbackUnsubscribeDictionary {
    [key: string]: () => void;
}

export enum SortByType {
    RecentlyCreated = "created",
    Popularity = "favoritedByCount",
    Title = "searchTitle",
    Relevence = "relevance"
}

export enum TabSelection {
    SITE = "site",
    PUBLIC = "public",
    MY_LISTS = "myLists",
    FAVORITES = "favorites",
    SITE_IMAGES = "siteImages",
    MY_IMAGES = "myImages",
    CLASS_EDITOR = "classEditor"
}
