import { StudentGender } from "../models/Student";

export class DefaultProfileImages {
    static neutralProfileImage() {
        return require("./images/neutral_placeholder.png");
    }

    static profileImageForStudentGender(gender: StudentGender) {
        switch (gender) {
            case StudentGender.Boy:
                return require("./images/male_placeholder.jpg");
            case StudentGender.Girl:
                return require("./images/female_placeholder.jpg");
            default:
                return require("./images/neutral_placeholder.png");
        }
    }
}
