/* tslint:disable */
export const portuguese = {
    absent: "Ausente",
    absentStudentsFormat: "{0} ausente",
    accountRegistration: "Registro de conta",
    active: "Ativo",
    activityPreview: "Visualização de atividade",
    activityPreviewMessage: "Por favor, faça o login ou registre-se para uma conta gratuita.",
    add: "Adicionar",
    addAccount: "Adicionar Conta",
    addCard: "Adicionar cartão",
    addCards: "Adicionar cartões",
    addFolder: "Adicionar pasta",
    addGroupStudentAccount: "Adicionar conta de estudante do grupo",
    addKeyword: "Adicionar palavra-chave",
    addName: "Adicionar nome",
    addTeacherAccount: "Adicionar conta do professor",
    addToFavorites: "Adicionar aos favoritos",
    addWithPlus: "acrescentar",
    additionalResources: "Recursos adicionais",
    admin: "Admin",
    affirmative: "Afirmativa",
    afterTrial:
        "Após o teste de 30 dias, você pode continuar usando o site como membro Básico, ou atualize de volta para o Premium.",
    all: "Todos",
    allFieldsRequired: "Todos os campos são necessários.",
    allowCardsToBeFlipped: "Permitir que os cartões sejam virados",
    allowedToChangePassword: "professor autorizado a alterar a senha",
    annual: "Anual",
    annualSubscription: "Inscrição anual",
    answer: "Responda",
    audioClipLanguageLabel: "Idioma do clipe de áudio",
    backToWorksheets: "Voltar para planilhas",
    basic: "Basic",
    basicMemberCredits:
        "* Membros básicos recebem 8 créditos mensais. Depois de todos os créditos terem sido usados, o acesso ao site será limitado até o primeiro dia do próximo mês.",
    billedAnnually: "Anual faturado",
    billedAnnuallyFormat: "Faturado anualmente ({0})",
    billedMonthly: "Faturado mensalmente",
    billedMonthlyFormat: "Faturado mensalmente ({0})",
    billingFrequency: "Frequência de cobrança",
    boy: "Garoto",
    boys: "Rapazes",
    browseAllCategories: "Navegar / Editar categorias",
    browseAllLists: "Navegar / Editar listas",
    cancel: "Cancelar",
    cancelSubscription: "Cancelar assinatura",
    cannotAddManagedAccountsInTrial: "As contas gerenciadas não podem ser adicionadas durante o período de avaliação.",
    cannotBeEmpty: "Não pode estar vazio.",
    card: "Cartão",
    cardLower: "cartão",
    cardsLower: "cartões",
    category: "Categoria",
    category2: "Categoria2",
    categoryCharactersNotSupported: "Esta categoria contém caracteres que ainda não são suportados por esta atividade.",
    categoryTypeNotSupported: "Este tipo de categoria ainda não é compatível com esta categoria.",
    changeCategory: "Alterar categoria",
    changeEditStudents: "Alterar / editar alunos",
    changePassword: "Mudar senha",
    checkEmailForVerifcation: "Por favor, verifique seu e-mail para completar a ativação de sua conta.",
    chinese: "chinês",
    className: "Nome da classe",
    clear: "Claro",
    clearFilters: "Limpar filtros",
    clearSelectedImage: "Limpar a imagem selecionada",
    clearSelectedImages: "Limpar imagens selecionadas",
    clickHereTo: "Sem conta? Clique aqui para",
    confirmChangeCategory: "Alterar a categoria irá redefinir a atividade. Tem certeza de que quer mudar?",
    confirmChangeClassList: "Alterar a lista de alunos redefinirá a atividade. Tem certeza de que quer mudar?",
    confirmChangeTeamNumber: "Alterar o número de equipes redefinirá a atividade. Tem certeza de que quer mudar?",
    confirmRestartSubscription:
        "Tem certeza de que gostaria de reiniciar sua assinatura? Seu cartão de crédito não será cobrado até {0}.",
    contact: "Contato",
    contactAdminMessage: " Se esse problema persistir, entre em contato com o administrador do site.",
    contactDescription:
        "Por favor, deixe-nos saber quaisquer dúvidas ou problemas que você tem. Basta enviar uma mensagem abaixo e entraremos em contato o mais breve possível.",
    contactFormSubmitted: "Obrigado! Sua mensagem foi enviada.",
    copy: "cópia de",
    copyLower: "cópia de",
    createCardFromSelectedImage: "Criar cartão da imagem selecionada",
    createCardsFromSelectedImages: "Crie cartões de {0} imagens selecionadas",
    createCopyIn: "Crie uma cópia em:",
    createCustomLists: "Crie listas personalizadas",
    createNewList: "Criar nova lista",
    createSelectCategory: "Crie ou selecione uma lição",
    createSelectCategoryDescription:
        "Crie uma aula personalizada em segundos ou pesquise em milhares de aulas já existentes.",
    createSelectClassList: "Crie ou selecione uma lista de alunos",
    createStudentNameLists: "Crie listas de nomes de alunos",
    createTeams: "Crie equipes",
    createdBy: "Criado por",
    createdOn: "Criado em",
    creditCard: "Cartão de crédito",
    creditNeeded: "1 crédito por uso",
    currentSubscriptionRequiredForManagedAccounts:
        "Uma assinatura ativa é necessária. Por favor, reinicie seu subscrito para adicionar outras contas.",
    customizeHeader: "{{Personalizar}} para se adequar a qualquer lição",
    customizeText:
        "Faça uma lista de vocabulário simples ou uma lista de perguntas e transforme-a em um atividade em segundos. Depois que uma lista é criada, ela pode ser usada com qualquer atividade em todo o site.",
    dataBeingMigratedFromBarryFunEnglish:
        "Os dados da sua conta estão sendo migrados do BarryFunEnglish. Isso só precisa ser feito uma vez. Dependendo da quantidade de dados, isso pode levar de um\n        alguns segundos a algumas horas. Se você não vir todos os seus dados após algumas horas, entre em contato\n        o administrador do site.",
    delete: "Excluir",
    deleteCategoryConfirmation: "Tem certeza de que deseja excluir permanentemente esta categoria?",
    deleteFavoriteConfirmation: "Tem certeza de que gostaria de remover isso dos seus favoritos?",
    deleteFolderConfirmation:
        "Tem certeza de que gostaria de excluir permanentemente esta pasta e todo o seu conteúdo?",
    deleteStudentListConfirmation: "Tem certeza de que deseja remover essa lista?",
    discount: "Desconto ({0}%):",
    discounts: "Descontos:",
    done: "Feito",
    dutch: "holandês",
    edit: "Editar",
    editProfile: "Editar Perfil",
    email: "O email",
    emailAlreadyRegistered: "Endereço de email já cadastrado",
    emailOrUsername: "Email ou nome de usuário",
    emailRequiredError: "Nome de usuário é requerido.",
    emailSentTo: "E-mail enviado para {0}.",
    endGame: "Jogo final",
    english: "Inglês",
    enterEmailUsedToRegister: "Por favor, insira o endereço de e-mail que você usou para se registrar no site:",
    enterIncorrectAnswers: "Digite minhas próprias respostas incorretas",
    enterQuestion: "Digite a pergunta",
    enterText: "Digite o texto:",
    enterValidEmail: "Por favor insira um endereço de e-mail válido.",
    enterVocabAndQuestion: "Digite a palavra e a pergunta do vocabulário",
    enterVocabulary: "Digite o vocabulário",
    existingAccount: " conta existente",
    expirationDateFormat: "Data de expiração: {0}",
    expired: "Expirado",
    expires: "Expira",
    expressionInputTextField1Label: "Campo de texto 1 (pergunta)",
    expressionInputTextField2Label: "Campo de texto 2 (resposta)",
    favorites: "Favoritos",
    flashcards: "Flashcards",
    folderName: "Nome da pasta:",
    forClassroomHeader: "Projetado para o {{classroom}}",
    forClassroomText:
        "Jogos e atividades são projetados para uso em sala de aula e funciona muito bem com grandes ou pequenas classes.\n            Os jogos podem ter entre duas a cinco equipes e listas de nomes de alunos podem ser inseridas para seleção aleatória.",
    forgotPassword: "Esqueceu a senha?",
    free: "Livre",
    french: "francês",
    games: "Jogos",
    gender: "Gênero",
    german: "alemão",
    girl: "Menina",
    girls: "Meninas",
    girlsVsBoys: "Meninas vs meninos",
    greek: "grego",
    groupNumberFormat: "Grupo {0}",
    homePageContactMessage: "Tem alguma {{questions}} para nós?",
    imageOverlayGraphic: "Gráfico de sobreposição de imagens",
    imageWithText: "Imagem com campo de texto único",
    imageWithTextDescription:
        "Essas listas são ótimas para praticar vocabulário, leitura e fala. Eles trabalham com quase qualquer atividade no site.",
    imageWithTwoText: "Imagem com dois campos de texto",
    imageWithTwoTextDescription:
        "Estas listas são usadas para fazer perguntas sobre uma imagem ou para traduzir entre dois idiomas",
    imagesSelectorInstructions: "Selecione ou faça upload de imagens para criar cartões de:",
    interactive: "Interativo",
    interactiveFlashcards: "Flashcards interativos",
    introduce: "Introduzir",
    invalidEmail: "Endereço de email invalido.",
    invalidPassword: "As senhas devem ter pelo menos 6 caracteres.",
    invalidUsername: "Nome de usuário Inválido.",
    invalidUsernameOrPassword: "Você digitou um nome de usuário ou senha inválidos",
    italian: "italiano",
    japanese: "japonês",
    keywordsLabel: "Palavras-chave",
    korean: "coreano",
    language: "Língua",
    languageTitle: "Língua",
    learn: "Aprender",
    learnDescription: "Apresente uma nova lição ou revisão com cartões flash interativos ou imprimíveis.",
    lesson: "Lição",
    lessonDescription: "Mistura de imagem única e imagem com dois campos de texto",
    lessonTypeSelectorTitle: "Você gostaria de praticar vocabulário ou perguntas desta lição?",
    listAccessibility: "Acessibilidade da lista",
    listTitleLabel: "Título da lista",
    listTypeTitle: "Tipo de lista",
    loggingIn: "Login",
    loginToCopy: "Você deve estar logado para copiar listas.",
    loginToCreateFolders: "Você deve estar logado para criar pastas.",
    loginToCreateLists: "Você deve estar logado para criar listas personalizadas.",
    loginToEditLists: "Você deve estar logado para editar listas.",
    loginToFavorite: "Você deve estar logado para salvar favoritos.",
    logout: "Sair",
    mainPageText: "Atividades de aprendizado de idiomas personalizadas para qualquer lição",
    managedAccounts: "Contas gerenciadas",
    managedAccountsDescription:
        "Adicione contas gerenciadas se você quiser várias associações com uma assinatura. Descontos são oferecidos com base no número total de contas.",
    maxFilesUploadAttempt: "Você só pode carregar no máximo {0} arquivos por vez.",
    membershipTypes: "Tipos de associação",
    message: "mensagem",
    misses: "Sente falta",
    monthly: "Por mês",
    monthlySubscription: "Assinatura mensal",
    move: "Mover",
    moveFolderTo: "Mova a pasta para:",
    moveListTo: "Mover lista para:",
    multipleChoiceInstructions: "Com atividades de múltipla escolha, o que você deseja usar para respostas incorretas?",
    multipleLanguageSupport: "Vários idiomas suportados",
    mustHaveOneIncorrectAnswers: "Você deve digitar pelo menos uma resposta incorreta.",
    myAccount: "Minha conta",
    myClasses: "Minhas aulas",
    myImages: "Minhas Imagens",
    myLists: "Minhas listas",
    name: "Nome",
    negative: "Negativo",
    newFolder: "Nova pasta",
    nextBillingDate: "Data de faturamento seguinte: {0} ({1})",
    no: "Não",
    noAcceptableFilesFound:
        "Nenhum arquivo válido encontrado. Os arquivos estão limitados a um tamanho máximo de {0} MB.",
    noCardOnFile: "Nenhum cartão no arquivo",
    noCreditCardRequired: "Nenhum cartão de crédito é necessário.",
    noRemainingCredits: "Nenhum crédito restante este mês. Registre-se para uma conta premium para acesso ilimitado.",
    none: "Nenhum",
    notActivated: "Não ativado",
    notActive: "Não ativo",
    notChargedUntilFormat: "O cartão de crédito não será redirecionado até {0}.",
    notChargedUntilTrialComplete: "Seu cartão de crédito não será cobrado até o final do período de avaliação.",
    numberOfTeams: "Número de equipes",
    off: "Fora",
    ok: "Está bem",
    on: "Em",
    oneRemainingCredit: "Você tem 1 crédito restante este mês.",
    options: "Opções",
    or: "ou",
    page: "Página",
    pageNotFound: "página não encontrada",
    password: "Senha",
    passwordLengthError: "A senha deve ter pelo menos 6 caracteres.",
    passwordRequiredError: "Senha requerida",
    passwordResetEmailHasBeenSendMessage: "Por favor, verifique seu e-mail para um link para redefinir sua senha.",
    pay: "Pagar",
    payBalance: "Pagar saldo",
    paymentMethod: "Método de pagamento",
    pendingSubscription: "Subscrição pendente",
    play: "Toque",
    playDescription: "Jogos emocionantes para grandes e pequenas classes",
    pleaseAddCardForRestart: "Você deve adicionar um cartão de crédito antes de reiniciar sua assinatura.",
    portuguese: "Português",
    practice: "Prática",
    practiceDescription: "Planilhas interativas e imprimíveis",
    premium: "Prêmio",
    premiumPriceFormatMonth: "$ {0} / mês",
    premiumPriceFormatYear: "US $ {0} / ano",
    premiumTrial: "Premium (julgamento)",
    preview: "Visualizar",
    price: "Preço",
    print: "Impressão",
    printDescription: "Imprimir flashcards ou planilhas para prática off-line",
    printable: "Imprimível",
    printableFlashcards: "Flashcards imprimíveis",
    private: "Privado",
    problemSubmittingContactForm: "Houve um problema ao enviar sua mensagem.",
    problemUpdatingUserErrorMessage: "Ocorreu um erro ao atualizar os detalhes do usuário.",
    problemUsingCredit: "Houve um problema ao usar seus créditos.",
    profile: "Perfil",
    proratedAmountDue: "Valor devido agora: {0}",
    public: "Público",
    publicLists: "Listas de comunidades",
    quellMessage: "Não mostrar esta mensagem novamente",
    question: "Questão",
    questions: "Questões",
    quiz: "Questionário",
    randomize: "Aleatória",
    randomizeAndReset: "Aleatório e redefinir",
    recent: "Recente",
    register: "registo",
    registerUsernamePassword: "Registre-se com nome de usuário / senha",
    rejectedFileFormat:
        "{0} arquivo não foi carregado. O arquivo pode ter sido muito grande ou tipo de arquivo errado. Apenas arquivos de imagem de 500 KB ou menores são aceitos.",
    rejectedFilesFormat:
        "{0} arquivos não foram enviados. Os arquivos podem ter sido muito grandes ou tipo de arquivo errado. Apenas arquivos de imagem de 500 KB ou menores são aceitos.",
    remainingCreditsFormat: "Você tem {0} créditos restantes neste mês.",
    removeAllLetters: "Remova todas as letras",
    removeFromFavorites: "Remover dos favoritos",
    removeManagedUserAccountConfirmation:
        "Esta conta será removida e o usuário não será mais permissão para login. Tem certeza de que deseja remover permanentemente esta conta?",
    resendEmail: "Reenviar email",
    reset: "Restabelecer",
    restartSubscription: "Reinicie a Assinatura",
    restartSubscriptionMessage: "Por favor, selecione uma frequência de cobrança para sua assinatura.",
    review: "Reveja",
    reviewDescription: "Questionários de múltipla escolha para ouvir, ler, escrever e falar",
    russian: "russo",
    sameAsTextField1Label: "O mesmo que o campo de texto 1",
    sampleClass: "Amostra de Classe",
    save: "Salve ",
    search: "Procurar",
    select: "Selecione ...",
    selectACategory: "Selecione uma categoria",
    selectCategoryBeforeActivity: "Você deve selecionar uma categoria antes de selecionar uma atividade.",
    selectFolderToAddFavoriteTo: "Selecione a pasta para adicionar favorito a:",
    selectStudentList: "Selecione uma lista de alunos",
    showCategoryDetails: "mostrar detalhes",
    signIn: "Entrar",
    signInRegister: "Login / Registre-se",
    signInUsernamePassword: "Login com nome de usuário / senha",
    signUpForFree: "Cadastre-se gratuitamente",
    signUpForFreeMessage: "Registre-se agora para uma conta Premium gratuita por 30 dias!",
    singleTeacher: "Único professor",
    singleText: "Campo de texto único",
    singleTextDescription:
        "Essas listas são ótimas para ler e praticar ortografia. Eles também são útil para atividades que não exigem uma imagem.",
    siteImages: "Imagens do site",
    siteLists: "Listas de sites",
    sortByPopularity: "Popularidade",
    sortByRecentlyCreated: "Criado recentemente",
    sortByRelevance: "Relevância",
    sortByTitle: "Título",
    sortByTypeTitle: "Ordenar por",
    spanish: "espanhol",
    start: "Começar",
    startSubscription: "Iniciar assinatura",
    startSubscriptionAfterTrial:
        'Inicie sua assinatura para continuar sua associação "Premium" depois o julgamento está completo.',
    studentGroup: "grupo de estudantes",
    studentGroups: "grupos de estudantes",
    studentList: "Lista de estudantes",
    subject: "Sujeito",
    submit: "Enviar",
    subscribe: "Se inscrever",
    subscription: "Inscrição",
    subscriptionDescriptionFormat: "Tipo de Assinatura: {0}",
    subscriptionType: "Tipo de assinatura",
    subtotal: "Subtotal:",
    support: "Entre em contato com o suporte",
    teacher: "professor",
    teacherCountFormat: "Contagem de professores: {0}",
    teachers: "professores",
    teachingTools: "Ferramentas De Ensino",
    teamNumberFormat: "Equipe {0}",
    tenOrMore: "Dez ou mais",
    textField1Label: "(Questão)",
    textField2Label: "(Responda)",
    textToSpeechSupportFormat: "Clipes de áudio criados automaticamente usando a tecnologia text-to-speech",
    thousandsOfHeader: "{{Milhares}} de categorias para escolher",
    thousandsOfText:
        "Não quer criar suas próprias categorias? Navegue através milhares criados pela comunidade KinteractiveLearning. Mais são adicionados todos os dias!",
    threeOrMore: "Três ou mais",
    titleCannotBeEmptyInfoMessage: "O título não pode estar vazio. Por favor insira um título.",
    tools: "Ferramentas",
    toolsDescription: "Criadores de grupos e equipes, seletor aleatório de alunos, cronômetro e placar",
    totalDue: "Total devido:",
    totalManagedAccounts: "Total de contas gerenciadas:",
    totalStudentsFormat: "{0} estudantes",
    turkish: "turco",
    twoTextOnly: "Dois campos de texto",
    twoTextOnlyDescription:
        "Essas listas são usadas para fazer perguntas que não exigem e ou para traduzir entre dois idiomas",
    type: "Tipo",
    unlimited: "Ilimitado",
    update: "Atualizar",
    updateEmailError: "Ocorreu um erro ao atualizar o endereço de e-mail.",
    uploadImages: "Enviar imagens",
    uploadImagesDropzoneInstructions: "Solte as imagens ou clique para navegar no seu computador.",
    uploadMessage: "Upload de imagens {0}.",
    uploadProfileImageError: "Ocorreu um erro durante o upload da imagem do perfil.",
    useAsVocabulary: "Adicionar à lista de vocabulário",
    useCredit: "Use crédito",
    useOtherAnswersAsIncorrect: "Use outras respostas corretas de cartões",
    username: "Nome de usuário",
    usernameOptional: "Nome de usuário (opcional)",
    usernameRequiredError: "Nome de usuário é requerido.",
    usernameTaken: "O nome de usuário já está sendo usado",
    usernameUnavailable: "Nome de usuário não está disponível",
    vocabulary: "Vocabulário",
    worksheetCreators: "Criadores de planilha",
    worksheets: "Fichas de trabalho",
    yes: "sim",
    getStarted: "",
    requiresFlash: "",
    onlySupportsLatinBasedLanguages: "",
    upgradeToPremium: "",
    startReview: "",
    numberOfGroups: "",
    hideAnswer: "",
    autoPlayAudio: "",
    imageBank: "",
    doubleSidedCards: "",
    ignoreFlippedCardsForReview: "",
    soft: "",
    medium: "",
    strong: "",
    continuePlaying: "",
    languageNotSupported: "",
    createLink: "",
    pinActivityToDashboard: "",
    enterName: "",
    createStudentLink: "",
    expiration: "",
    oneDay: "",
    oneWeek: "",
    never: "",
    link: "",
    copyToClipboard: "",
    pinnedActivities: "",
    studentActivityLinks: "",
    numberOfCards: "",
    matchType: "",
    imageType: "",
    otherOptions: "",
    wordImage: "",
    imageImage: "",
    wordWord: "",
    activity: "",
    seeLinkVisitors: "",
    recentHistory: "",
    startTime: "",
    completedTime: "",
    results: "",
    iDontKnow: "",
    iKnowTheAnswer: "",
    seconds: "",
    minutes: "",
    hours: "",
    pause: "",
    set: "",
    audioHeading: "",
    playAudioOnCorrect: "",
    playAudioOnWrong: "",
    answerTypeHeading: "",
    multipleChoiceAnswerTypeOption: "",
    rightOrWrongAnswerTypeOption: "",
    showNumbersHeading: "",
    showNumbersMultipleChoice: "",
    timerHeading: "",
    showTimerMultipleChoice: "",
    game: "",
    correct: "",
    wrong: "",
    teams: "",
    imagesInGrid: "",
    multipleChoiceOptions: "",
    playAudioOnSelection: "",
    classicModeOptions: "",
    selectAll: "",
    flipCardsToFront: "",
    flipCardsToBack: "",
    shuffle: "",
    showAllCards: "",
    bonusRound: "",
    crazyCupsIntroMessage: "",
    crazyCupsBonusRoundMessage: "",
    goodJob: "",
    sorryYouMissedIt: "",
    teamReadyFormat: "",
    readyToTryAgain: "",
    cupsSpeedAdjustment: "",
    textOnly: "",
    imageText: "",
    imageOnly: "",
    hideShips: "",
    useClassicMode: "",
    classicModeDescription: "",
    stop: "",
    bullsEye: "",
    removeNameOnceSelected: "",
    clearDartAfterThrow: "",
    stopBoardAfterThrow: "",
    playSoundEffect: "",
    spinForPoints: "",
    showImageProfileImage: "",
    cardType: "",
    gridSize: "",
    cardsPerPage: "",
    noListsGetStarted: "",
    noImagesGetStarted: "",
    isTraceable: "",
    twoColumns: "",
    randomizeWorksheet: "",
    createPDF: "",
    fontSize: "",
    goodJobReadyForNext: "",
    whoGoesFirst: "",
    teamGoesFirstFormat: "",
    popBalloonsWith: "",
    dartMeterSpeed: "",
    fontStyle: "",
    cardSensitivity: "",
    points: "",
    hotOrColdInstructions: "",
    inviteUsers: "",
    availableInvitesFormat: "",
    numberOfAccountsToAdd: "",
    removeSubscriptionManagerConfirmation: "",
    managedSubscription: "",
    disconnectFromAccountManager: "",
    confirmToBeManagedConfirmationFormat: "",
    pendingManagerNotFound: "",
    steal: "",
    newWord: "",
    canYouSpell: "",
    wouldLikeToStealFormat: "",
    selectSecondCategory: "",
    clearSecondCategory: "",
    borderThickness: "",
    borderColor: "",
    subscriptionIsPastDue: "",
};
