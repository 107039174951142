export enum HttpCallStatus {
    Success,
    Error
}

export enum FetchUnknownUserStatus {
    MysqlUserMigrated,
    ManagedUserFound,
    UsernameFound,
    NotFound,
    Error
}

export interface FetchUnknownUserResponse {
    status: FetchUnknownUserStatus;
    error?: any;
    data?: MysqlUserMigratedResponse | ManagedUserResponse | UserFoundResponse;
}

export interface MasqueradeResponse {
    status: HttpCallStatus;
    error?: any;
    data?: MasqueradeResponseData;
}

export interface MasqueradeResponseData {
    token: string;
}

export interface MysqlUserMigratedResponse {
    mysqlID: number;
    uid: string;
    email: string;
}

export interface ManagedUserResponse {
    uid: string;
    token: string;
}

export interface UserFoundResponse {
    email: string;
}
