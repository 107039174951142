import * as React from "react";

// Material UI
import Button from "@material-ui/core/Button";
import ListItemText from "@material-ui/core/ListItemText";

// Components
import SimpleMenu from "../common/SimpleMenu";

// Utils
import { strings } from "../../core/localizedStrings";
import ButtonMenuDisplayComponent from "./ButtonMenuDisplayComponent";
import { Row, Col } from "layout";

export interface TeamNumberMenuProps extends React.Props<any> {
    numberOfTeams: number;
    numberOptions: number[];
    isGroupMaker?: boolean;
    onChangedCallback: (numberOfTeams: number) => void;
}

export default class TeamNumberMenu extends React.Component<TeamNumberMenuProps> {
    render() {
        return <SimpleMenu displayComponent={this.renderDisplayComponent()}>{this.renderOptions()}</SimpleMenu>;
    }

    private renderDisplayComponent() {
        return (
            <ButtonMenuDisplayComponent
                label={this.props.isGroupMaker ? strings.numberOfGroups : strings.numberOfTeams}
                value={this.props.numberOfTeams.toString()}
            />
        );
    }

    private renderOptions() {
        return (
            <Col xs={true}>
                {this.props.numberOptions.map((teamNumber: number, index) => (
                    <Row end="xs" style={{ minWidth: 60 }} key={index}>
                        <Button key={teamNumber} onClick={this.onOptionChanged.bind(this, teamNumber)}>
                            <ListItemText primary={teamNumber} />
                        </Button>
                    </Row>
                ))}
            </Col>
        );
    }

    private onOptionChanged = (numberOfTeams: number) => {
        this.props.onChangedCallback(numberOfTeams);
    };
}
