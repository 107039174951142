// Site URL
export const SITE_URL = "https://www.kinteractivelearning.com";

// Route paths
export const ADMIN_PATH = "/admin";
export const TRANSLATIONS_PATH = "/translations/strings";
export const VOCABULARY_TRANSLATIONS_PATH = "/translations/vocabulary";
export const LESSONS_TRANSLATIONS_PATH = "/translations/lessons";
export const HOME_PATH = "/";
export const ACTIVITY_LINK_PATH = "/a";
export const APP_ROOT_PATH = "/app";
export const MY_ACCOUNT_PATH = "/account";
export const GROUP_MAKER_PATH = "/tools/group-maker";
export const CONTACT_PATH = "/contact";
export const REGISTRATION_PATH = "/register";
export const POST_LOGOUT_PATH = HOME_PATH;

// Payments
export const BILLED_MONTHLY_AMOUNT = 500;
export const BILLED_ANNUALLY_AMOUNT = 3900;
export const STRIPE_PUBLISHABLE_KEY = "pk_live_Pmeg0LCjRlDM2CGUbweqa4Lk";
export const TRIALING_SUBSCRIPTION = "trialing";
export const ACTIVE_SUBSCRIPTION = "active";
export const DELINQUENT_PAYMENT = "delinquent";

// API URLS
export const STRIPE_JS = "https://js.stripe.com/v2/";
export const STRIPE_CHECKOUT_JS = "https://checkout.stripe.com/checkout.js";

export const TOTAL_MONTHLY_CREDITS = 8;
