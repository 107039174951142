export let english = {
    loggingIn: "Logging In",
    mainPageText: "Custom language learning activities for any lesson",
    signIn: "Login",
    signInRegister: "Login/Register",
    registerUsernamePassword: "Register with username/password",
    signInUsernamePassword: "Login with username/password",
    logout: "Logout",
    support: "Contact support",
    useCredit: "Use Credit",
    myAccount: "My Account",
    unlimited: "Unlimited",
    creditNeeded: "1 credit per use",
    profile: "Profile",
    editProfile: "Edit Profile",
    addAccount: "Add Account",
    managedAccounts: "Managed Accounts",
    managedAccountsDescription: `Add managed accounts if you would like multiple memberships under one subscription.
        Discounts are offered based on the number of total accounts.`,
    discounts: "Discounts:",
    threeOrMore: "Three or more",
    tenOrMore: "Ten or more",
    subscription: "Subscription",
    membershipTypes: "Membership types",
    accountRegistration: "Account Registration",
    pendingSubscription: "Pending Subscription",
    paymentMethod: "Payment Method",
    pay: "Pay",
    creditCard: "Credit Card",
    card: "Card",
    addCard: "Add Card",
    noCardOnFile: "No card on file",
    billingFrequency: "Billing Frequency",
    startSubscription: "Start Subscription",
    restartSubscription: "Restart Subscription",
    monthlySubscription: "Monthly Subscription",
    monthly: "Monthly",
    annual: "Annual",
    payBalance: "Pay balance",
    annualSubscription: "Annual Subscription",
    subscriptionType: "Subscription type",
    billedAnnually: "Billed Annualy",
    billedMonthly: "Billed Monthly",
    billedAnnuallyFormat: "Billed Annually ({0})",
    billedMonthlyFormat: "Billed Monthly ({0})",
    notActive: "Not Active",
    active: "Active",
    expired: "Expired",
    restartSubscriptionMessage: "Please select a billing frequency for your subscription.",
    notChargedUntilFormat: "Credit card will not be chared until {0}.",
    startSubscriptionAfterTrial: `Start your subscription to continue your 'Premium' membership after 
        the trial is complete.`,
    notChargedUntilTrialComplete: `Your credit card will not be charged until the end of the trial period.`,
    pleaseAddCardForRestart: "You must add a credit card before restarting your subscription.",
    confirmRestartSubscription: `Are you sure you would like to restart your subscription? 
        \nYour credit card will not be charged until {0}.`,
    currentSubscriptionRequiredForManagedAccounts:
        "An active subscription is required. Please restart your subscripton to add additional accounts.",
    cannotAddManagedAccountsInTrial: "Managed accounts cannot be added while in the trial period.",
    subtotal: "Subtotal: ",
    totalDue: "Total Due: ",
    discount: "Discount ({0}%): ",
    subscribe: "Subscribe",
    nextBillingDate: "Next Billing Date: {0} ({1}) ",
    proratedAmountDue: "Amount due now: {0}",
    teacher: "teacher",
    teachers: "teachers",
    singleTeacher: "Single teacher",
    studentGroup: "student group",
    studentGroups: "student groups",
    addTeacherAccount: "Add accounts",
    addGroupStudentAccount: "Add group student account",
    username: "Username",
    usernameOptional: "Username (optional)",
    emailOrUsername: "Email or username",
    passwordResetEmailHasBeenSendMessage: "Please check your email for a link to reset your password.",
    enterEmailUsedToRegister: "Please enter the email address you used to register for the site:",
    usernameTaken: "Username is already taken",
    usernameRequiredError: "Username is required.",
    emailRequiredError: "Username is required.",
    passwordRequiredError: "Password is required",
    passwordLengthError: "Password must be at least 6 characters.",
    allowedToChangePassword: "teacher allowed to change password",
    updateEmailError: "An error occured updating email address.",
    problemUpdatingUserErrorMessage: "An error occured updating user details.",
    uploadProfileImageError: "An error occured uploading profile image.",
    contactAdminMessage: " If this problem persists, please contact the site administrator.",
    email: "Email",
    password: "Password",
    changePassword: "Change password",
    forgotPassword: "Forgot password?",
    existingAccount: " existing account",
    clickHereTo: "No account? Click here to",
    register: "register",
    expires: "Expires",
    notActivated: "Not Activated",
    ok: "Ok",
    cancel: "Cancel",
    yes: "Yes",
    no: "No",
    update: "Update",
    add: "Add",
    delete: "Delete",
    on: "On",
    off: "Off",
    move: "Move",
    copy: "Copy",
    copyLower: "copy",
    edit: "Edit",
    save: "Save",
    reset: "Reset",
    clear: "Clear",
    clearFilters: "Clear Filters",
    changeEditStudents: "Change/Edit Students",
    endGame: "End Game",
    submit: "Submit",
    subject: "Subject",
    contact: "Contact",
    message: "Message",
    contactDescription: `Please let us know any questions or problems that you have. 
            Just send a message below and we'll get back to you as soon as possible.`,
    all: "All",
    none: "None",
    preview: "Preview",
    type: "Type",
    moveListTo: "Move list to:",
    moveFolderTo: "Move folder to:",
    selectFolderToAddFavoriteTo: "Select folder to add favorite to:",
    createCopyIn: "Create copy in:",
    createNewList: "Create New List",
    category: "Category",
    category2: "Category2",
    studentList: "Student list",
    selectACategory: "Select a category",
    createSelectCategory: "Create or select a lesson",
    createSelectClassList: "Create or select a student list",
    selectStudentList: "Select a student list",
    createTeams: "Create teams",
    groupNumberFormat: "Group {0}",
    teamNumberFormat: "Team {0}",
    recent: "Recent",
    browseAllCategories: "Browse/Edit categories",
    browseAllLists: "Browse/Edit lists",
    select: "Select...",
    search: "Search",
    randomize: "Randomize",
    girlsVsBoys: "Girls vs Boys",
    done: "Done",
    addFolder: "Add Folder",
    folderName: "Folder name:",
    uploadImages: "Upload Images",
    siteLists: "Site Lists",
    siteImages: "Site Images",
    publicLists: "Community Lists",
    myLists: "My Lists",
    myClasses: "My Classes",
    favorites: "Favorites",
    myImages: "My Images",
    addCards: "Add cards",
    createdBy: "Created By",
    createdOn: "Created On",
    language: "Language",
    createCardFromSelectedImage: "Create card from selected image",
    createCardsFromSelectedImages: "Create cards from {0} selected images",
    clearSelectedImage: "Clear selected image",
    clearSelectedImages: "Clear selected images",
    addKeyword: "Add keyword",
    enterText: "Enter text:",
    addWithPlus: "+ Add",
    public: "Public",
    private: "Private",
    listAccessibility: "List accessibility",
    titleCannotBeEmptyInfoMessage: "Title cannot be empty. Please enter a title.",
    expressionInputTextField1Label: "Text Field 1 (Question)",
    expressionInputTextField2Label: "Text Field 2 (Answer)",
    useOtherAnswersAsIncorrect: "Use other cards correct answers",
    enterIncorrectAnswers: "Enter my own incorrect answers",
    imagesSelectorInstructions: "Select or upload images to create cards from",
    listTitleLabel: "List title",
    audioClipLanguageLabel: "Audio clip language",
    textField1Label: "(Question)",
    textField2Label: "(Answer)",
    question: "Question",
    answer: "Answer",
    vocabulary: "Vocabulary",
    keywordsLabel: "Keywords",
    sameAsTextField1Label: "Same as Text Field 1",
    multipleChoiceInstructions: "With multiple choice activities, what do you want to use for incorrect answers?",
    uploadImagesDropzoneInstructions: "Drop image(s), or click to browse your computer.",
    uploadMessage: "Uploading {0} images.",
    deleteStudentListConfirmation: "Are you sure you would like to remove this list?",
    deleteCategoryConfirmation: "Are you sure you would like to permanently delete this category?",
    deleteFavoriteConfirmation: "Are you sure you would like to remove this from your favorites?",
    deleteFolderConfirmation: "Are you sure you would like to permanently delete this folder and all of its contents?",
    removeManagedUserAccountConfirmation: `Are you sure you would like to remove this account. The account will still remain but it won't be included in this subscription.`,
    mustHaveOneIncorrectAnswers: "You must enter at least one incorrect answer.",
    showCategoryDetails: "show details",
    removeFromFavorites: "Remove From Favorites",
    addToFavorites: "Add To Favorites",
    sortByRelevance: "Relevance",
    sortByRecentlyCreated: "Recently Created",
    sortByPopularity: "Popularity",
    sortByTitle: "Title",
    sortByTypeTitle: "Sort By",
    listTypeTitle: "List Type",
    languageTitle: "Language",
    className: "Class Name",
    name: "Name",
    boy: "Boy",
    girl: "Girl",
    boys: "Boys",
    girls: "Girls",
    addName: "Add Name",
    gender: "Gender",
    absent: "Absent",
    totalStudentsFormat: "{0} students",
    absentStudentsFormat: " {0} absent",
    numberOfTeams: "Number of teams",
    newFolder: "New Folder",
    cardsLower: "cards",
    cardLower: "card",
    maxFilesUploadAttempt: "You can only upload a maximum of {0} files at a time.",
    noAcceptableFilesFound: "No valid files found. Files are limited to a maximum size of {0} MB.",
    rejectedFileFormat: `{0} file was not uploaded. File may have been too large or of the  
            wrong file type. Only image files 500 KB or smaller are accepted.`,
    rejectedFilesFormat: `{0} files were not uploaded. Files may have been too large or of the 
            wrong file type. Only image files 500 KB or smaller are accepted.`,
    categoryTypeNotSupported: "This category type is not yet supported with this activity.",
    categoryCharactersNotSupported: "This category contains characters that are not yet supported by this activity.",
    confirmChangeCategory: "Changing the category will reset the activity. Are you sure you want to change it?",
    confirmChangeClassList: "Changing the student list will reset the activity. Are you sure you want to change it?",
    confirmChangeTeamNumber:
        "Changing the number of teams will reset the activity. Are you sure you want to change it?",
    activityPreviewMessage: "Please login or register for a free account.",
    noRemainingCredits: "No remaining credits this month. Register for a premium account for unlimited access.",
    oneRemainingCredit: "You have 1 remaining credit this month.",
    remainingCreditsFormat: "You have {0} remaining credits this month.",
    problemUsingCredit: "There was a problem using your credits. ",
    activityPreview: "Activity Preview",
    games: "Games",
    worksheets: "Worksheets",
    tools: "Tools",
    flashcards: "Flashcards",
    interactiveFlashcards: "Interactive Flashcards",
    printableFlashcards: "Printable Flashcards",
    worksheetCreators: "Worksheet Creators",
    teachingTools: "Teaching Tools",
    createCustomLists: "Create custom Lists",
    createStudentNameLists: "Create Student Name Lists",
    basic: "Basic",
    premium: "Premium",
    premiumTrial: "Premium (trial)",
    admin: "Admin",
    expirationDateFormat: "Expiration Date: {0}",
    price: "Price",
    free: "Free",
    premiumPriceFormatMonth: "${0}/month",
    premiumPriceFormatYear: "${0}/year",
    signUpForFreeMessage: "Register now for a free Premium account for 30 days!",
    noCreditCardRequired: "No credit card required.",
    signUpForFree: "Sign up for free",
    afterTrial: `After the 30 day trial, you can either continue to use the site as as Basic member, 
        or upgrade back to Premium.`,
    basicMemberCredits: `*Basic members receive 8 monthly credits. Once all credits have been used, 
        site access will be limited until the first day of the next month.`,
    allFieldsRequired: "All fields are required.",
    enterValidEmail: "Please enter a valid email address.",
    contactFormSubmitted: "Thank you, your message has been sent.",
    problemSubmittingContactForm: "There was a problem sending your message.",
    quellMessage: "Do not show this message again",
    customizeHeader: "{{Customize}} to fit any lesson",
    customizeText: `Take a simple vocabulary list or list of questions and turn it into an engaging 
        activity in seconds. Once a list has been created, it can be used with any activity throughout the site.`,
    forClassroomHeader: "Designed for the {{classroom}}",
    forClassroomText: `Games and activities are designed for use in the classroom and  
        work great with big or small classes. 
            Games can have between two to five teams and student name lists can be entered for random selection.`,
    thousandsOfHeader: "{{Thousands}} of categories to choose from",
    thousandsOfText: `Don't want to create your own categories? Browse through  
        thousands created by the KinteractiveLearning community. More are added everyday!`,
    homePageContactMessage: "Have any {{questions}} for us?",
    multipleLanguageSupport: "Multiple languages supported",
    textToSpeechSupportFormat: "Audio clips automatically created using text-to-speech technology",
    english: "English",
    chinese: "Chinese",
    dutch: "Dutch",
    french: "French",
    german: "German",
    greek: "Greek",
    italian: "Italian",
    korean: "Korean",
    japanese: "Japanese",
    russian: "Russian",
    spanish: "Spanish",
    portuguese: "Portuguese",
    turkish: "Turkish",
    dataBeingMigratedFromBarryFunEnglish: `Your account data is being migrated from BarryFunEnglish. 
        This only needs to be done once. Depending on the amount of data, this can take anywhere from a 
        few seconds to a few hours. If you do not see all of your data after a few hours, please contact 
        the site administrator.`,
    invalidUsernameOrPassword: "You have entered an invalid username or password",
    invalidEmail: "Invalid email address.",
    invalidUsername: "Invalid username.",
    invalidPassword: "Passwords must be at least 6 characters long.",
    usernameUnavailable: "Username is not available",
    emailAlreadyRegistered: "Email address has already been registered",
    cannotBeEmpty: "Cannot be empty.",
    checkEmailForVerifcation: "Please check your email to complete the activation of your account.",
    resendEmail: "Resend email",
    emailSentTo: "Email sent to {0}.",
    totalManagedAccounts: "Total managed accounts:",
    cancelSubscription: "Cancel subscription",
    subscriptionDescriptionFormat: "Subscription Type: {0}",
    teacherCountFormat: "Teacher count: {0}",
    loginToEditLists: "You must be logged in to edit lists.",
    loginToCreateLists: "You must be logged in to create custom lists.",
    loginToCreateFolders: "You must be logged in to create folders.",
    loginToFavorite: "You must be logged in to save favorites.",
    loginToCopy: "You must be logged in to copy lists.",
    pageNotFound: "Page not found",
    lessonTypeSelectorTitle: "Would you like to practice vocabulary or questions from this lesson?",
    questions: "Questions",
    enterVocabulary: "Enter vocabulary",
    enterVocabAndQuestion: "Enter vocabulary word and question",
    enterQuestion: "Enter question",
    useAsVocabulary: "Add to vocabulary list",
    affirmative: "Affirmative",
    negative: "Negative",
    imageOverlayGraphic: "Image Overlay Graphic",
    sampleClass: "Sample Class",
    learn: "Learn",
    practice: "Practice",
    play: "Play",
    print: "Print",
    review: "Review",
    learnDescription: "Introduce a new lesson or review with interactive or printable flash cards.",
    practiceDescription: "Interactive and printable worksheets",
    playDescription: "Exciting games for big and small classes",
    printDescription: "Print flashcards or worksheets for offline practice",
    reviewDescription: "Multiple choice quizzes for listening, reading, writing, and speaking",
    toolsDescription: "Group and team makers, random student selector, stopwatch, and scoreboard",
    additionalResources: "Additional Resources",
    start: "Start",
    imageWithText: "Image with single text field",
    singleText: "Single text field",
    imageWithTwoText: "Image with two text fields",
    twoTextOnly: "Two text fields",
    lesson: "Lesson",
    imageWithTextDescription: `These lists are great for practicing vocabulary, reading, and speaking. 
    They work with almost any activity on the site.`,
    singleTextDescription: `These lists are great for reading and spelling practice. They are also 
    useful for activities that do no require an image.`,
    imageWithTwoTextDescription: `These lists a used for asking questions about an image or for translating
    between two languages`,
    twoTextOnlyDescription: `These lists are used for asking questions that do not require and image, 
    or for translating between two languages`,
    lessonDescription: "Mix of single image and image with two text fields",
    or: "or",
    page: "Page",
    misses: "Misses",
    backToWorksheets: "Back to worksheets",
    options: "Options",
    randomizeAndReset: "Randomize and reset",
    introduce: "Introduce",
    quiz: "Quiz",
    changeCategory: "Change category",
    interactive: "Interactive",
    printable: "Printable",
    createSelectCategoryDescription: "Create a custom lesson in seconds or search from thousands of existing ones.",
    removeAllLetters: "Remove all letters",
    allowCardsToBeFlipped: "Allow cards to be flipped",
    selectCategoryBeforeActivity: "You must select a category before selecting an activity.",
    getStarted: "Get started - for free!",
    requiresFlash: "Requires Flash Player",
    onlySupportsLatinBasedLanguages: "Only supports latin based languages",
    upgradeToPremium: "Upgrade",
    startReview: "Start Review",
    numberOfGroups: "Number of groups",
    hideAnswer: "Hide answers",
    autoPlayAudio: "Auto play audio",
    imageBank: "Image Bank",
    doubleSidedCards: "Use double sided cards",
    ignoreFlippedCardsForReview: "Ignore flipped cards for review",
    soft: "Soft",
    medium: "Medium",
    strong: "Strong",
    continuePlaying: "Continue playing?",
    languageNotSupported: "Sorry, this list contains characters that are not yet supported by this activity.",
    createLink: "Create student link",
    pinActivityToDashboard: "Pin activity to dashboard",
    enterName: "Enter name:",
    createStudentLink: "Create student link",
    expiration: "Expiration",
    oneDay: "1 day",
    oneWeek: "1 week",
    never: "never",
    link: "Link",
    copyToClipboard: "Copy to clipboard",
    pinnedActivities: "Pinned Activites",
    studentActivityLinks: "Student Activity Links",
    numberOfCards: "Number of cards",
    matchType: "Match type",
    imageType: "Image Type",
    otherOptions: "Other Options",
    wordImage: "Word/Image",
    imageImage: "Image/Image",
    wordWord: "Word/Word",
    activity: "Activity",
    seeLinkVisitors: "See link visitors",
    recentHistory: "Recent history",
    startTime: "Start time",
    completedTime: "Completed",
    results: "Results",
    iDontKnow: "I don't know",
    iKnowTheAnswer: "I know the answer!",
    seconds: "seconds",
    minutes: "minutes",
    hours: "hours",
    pause: "pause",
    set: "set",
    audioHeading: "Audio",
    playAudioOnCorrect: "play audio on correct answer",
    playAudioOnWrong: "play correct answer on wrong selection",
    answerTypeHeading: "Answer Type",
    multipleChoiceAnswerTypeOption: "multiple choice (4 choices with 1 correct answer)",
    rightOrWrongAnswerTypeOption: "correct or wrong (teacher decides if correct or wrong)",
    showNumbersHeading: "Show Numbers",
    showNumbersMultipleChoice: "show numbers with multiple choice options",
    timerHeading: "Timer",
    showTimerMultipleChoice: "show timer with each question",
    game: "Game",
    correct: "Correct",
    wrong: "Wrong",
    teams: "Teams",
    imagesInGrid: "Images in grid (classic mode)",
    multipleChoiceOptions: "Multiple choice options",
    playAudioOnSelection: "Play audio on selection",
    classicModeOptions: "Classic mode options",
    selectAll: "Select all",
    flipCardsToFront: "Flip cards to front",
    flipCardsToBack: "Flip cards to back",
    shuffle: "Shuffle",
    showAllCards: "Show all cards",
    bonusRound: "Start Bonus Round",
    crazyCupsIntroMessage: "Carefully watch the cups and select the answer above the correct cup.",
    crazyCupsBonusRoundMessage: "Each team get's three tries for 10 points each! Are you ready?",
    goodJob: "Good Job.",
    sorryYouMissedIt: "Sorry, you missed it.",
    teamReadyFormat: "Team {0}, are you ready?",
    readyToTryAgain: "Ready to try again?",
    cupsSpeedAdjustment: "Cups speed adjustment",
    textOnly: "Text only",
    imageText: "Image/Text",
    imageOnly: "Image only",
    hideShips: "Hide ships",
    useClassicMode: "Use classic mode",
    classicModeDescription:
        "In classic mode, images or text is used for the selector cards instead of letters and numbers.",
    stop: "Stop",
    bullsEye: "Bulls Eye!",
    removeNameOnceSelected: "Remove name once selected",
    clearDartAfterThrow: "Clear dart after throw",
    stopBoardAfterThrow: "Stop board after each throw",
    playSoundEffect: "Play sound effect",
    spinForPoints: "Spin for points",
    showImageProfileImage: "Show profile image",
    cardType: "Card type",
    gridSize: "Grid size",
    cardsPerPage: "Cards/Page",
    noListsGetStarted: 'You don\'t have any custom lists yet. Click the "add list" button to get started.',
    noImagesGetStarted: 'You haven\'t uploaded any images yet. Click the "upload" button to get started.',
    isTraceable: "Traceable",
    twoColumns: "Two columns",
    randomizeWorksheet: "Randomize Worksheet",
    createPDF: "Create PDF",
    fontSize: "Font size",
    goodJobReadyForNext: "Good job! Ready for the next one?",
    whoGoesFirst: "Let's see who goes first.",
    teamGoesFirstFormat: "Team {0} goes first.",
    popBalloonsWith: "Pop ballons with",
    dartMeterSpeed: "Dart meter speed",
    fontStyle: "Font Style",
    cardSensitivity: "Card sensitivity",
    points: "Points",
    hotOrColdInstructions:
        "Use the clues to find the star card. When you're 'HOT', you've almost found it. Are you ready?",
    inviteUsers: "Invite users",
    availableInvitesFormat: "{0} invite(s) available",
    numberOfAccountsToAdd: "Number of accounts to add",
    removeSubscriptionManagerConfirmation: "Are you sure you would like to remove the managed subscription?",
    managedSubscription: "(Managed Subscription)",
    disconnectFromAccountManager: "Disconnect from account manager",
    confirmToBeManagedConfirmationFormat:
        "There is a request from a user with email address, {0}, to add this account to their subscription. This user will not have any control or access to your account, they will just have the ability to include or remove you from their subscription. Would you like to accept?",
    pendingManagerNotFound: "A pendingManagementRequest exists, but manager cannot be found. ID: ",
    steal: "Steal",
    newWord: "New Word",
    canYouSpell: "Can you spell?",
    wouldLikeToStealFormat: "Team {0}, would you like to steal or choose a new word?",
    selectSecondCategory: "Select second category",
    clearSecondCategory: "Clear second category",
    borderThickness: "Border thickness",
    borderColor: "Border color",
    subscriptionIsPastDue: "Payment is past due. Please update your payment method.",
};
