import Activity from "./Activity";

export enum ToolType {
    DartboardSelector = "Dart Board Selector",
    GroupMaker = "Group Maker",
    RandomNamePicker = "Random Name Picker",
    Scoreboard = "Scoreboard",
    Stopwatch = "Stopwatch",
}

export default class Tool extends Activity {
    public static allItems = [
        new Tool(ToolType.DartboardSelector),
        new Tool(ToolType.GroupMaker),
        new Tool(ToolType.RandomNamePicker),
        new Tool(ToolType.Scoreboard),
        new Tool(ToolType.Stopwatch),
    ];

    public static allSWFItems = [
        new Tool(ToolType.DartboardSelector),
        new Tool(ToolType.RandomNamePicker),
        new Tool(ToolType.Scoreboard),
    ];

    public type: ToolType;

    protected get prefix() {
        return "tools";
    }
}
