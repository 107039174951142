import { log } from "index";
import { observable, action, runInAction, computed } from "mobx";

// Stores
import { RootStore } from "stores";

// Models
import { TabSelection, Folder, LanguageType, Category, CategoryType, StudentList, CategoryImage } from "models";
import CategoryFolder, { CachedFolderItem, FOLDER_PAGE_SIZE } from "./CategoryFolder";
import { SortByType } from ".";

// Utils
import FirestoreQueryBuilder from "core/search/FirestoreQueryBuilder";
import SampleStudentListCreator from "core/utils/SampleStudentListCreator";
import { ListItem } from "models/Folder";

export default class FirestoreFolder implements CategoryFolder {
    isFolderBrowser: boolean = false;
    tabType: TabSelection;
    isVisible: boolean = false;
    sortByType = SortByType.RecentlyCreated;
    languageFilter: LanguageType;
    listTypeFilter: CategoryType;

    @observable breadcrumbs: Folder[] = [];
    @observable isLoading: boolean = false;
    @observable isSearchLoading: boolean = false;
    @observable currentFolderKey: string;

    rootStore: RootStore;

    @observable private cachedFolders = new Map<String, CachedFolderItem>();

    constructor(tab: TabSelection, rootStore: RootStore) {
        this.tabType = tab;
        this.rootStore = rootStore;
    }

    @computed
    get currentFolder(): Folder {
        if (this.cachedFolders[this.currentFolderKey]) {
            return this.cachedFolders[this.currentFolderKey].folder;
        }
        return undefined;
    }

    @computed
    get visibleItems(): ListItem[] {
        let allItems: ListItem[] = [];

        if (this.currentFolder !== undefined) {
            if (this.currentFolder.parentKey) {
                let folder: Folder = new Folder();
                folder.isParentFolderLink = true;
                folder.key = this.currentFolder.parentKey;
                folder.title = "..";
                allItems.push(folder);
            }

            allItems.push(...this.currentFolder.subfolders);
            allItems.push(...this.currentFolder.items);
        }

        return allItems;
    }

    public checkShouldFetchMore() {
        if (this.currentFolder !== undefined) {
            if (!this.currentFolder.allFoldersLoaded && this.tabType !== TabSelection.PUBLIC) {
                this.fetchFoldersForNextPage();
            } else if (!this.currentFolder.allItemsLoaded) {
                this.fetchItemsForNextPage();
            }
        }
    }

    public updateIfNecessary() {
        if (this.currentFolder.needsUpdate) {
            return this.updateCurrentFolder(this.currentFolder, true);
        }
        return undefined;
    }

    @action
    public async updateCurrentFolder(folder: Folder, force: Boolean = false): Promise<any> {
        if (!folder) {
            if (this.tabType === TabSelection.CLASS_EDITOR) {
                let studentList = SampleStudentListCreator.create();
                let sampleFolder = new Folder();
                sampleFolder.key = "sample";
                sampleFolder.addItem(studentList);
                runInAction(() => {
                    this.cachedFolders[sampleFolder.key] = { folder: sampleFolder, updatedDate: new Date() };
                    this.currentFolderKey = sampleFolder.key;
                });
            }

            return;
        }

        if (!force && this.cachedFolders[folder.key]) {
            // If not public or public and less than 10 minutes use cache
            if (this.tabType === TabSelection.PUBLIC) {
                let cachedFolderItem = this.cachedFolders[folder.key];
                let now = new Date();
                let timeSinceUpdateMS = now.getTime() - cachedFolderItem.updatedDate.getTime();
                if (timeSinceUpdateMS < 60 * 10 * 1000) {
                    this.currentFolderKey = folder.key;
                    this.updateBreadcrumbs();
                    return;
                }
            } else {
                this.currentFolderKey = folder.key;
                this.updateBreadcrumbs();
                return;
            }
        }

        return new Promise<any>((resolve) => {
            if (!folder || (this.tabType !== TabSelection.PUBLIC && !folder.key)) {
                log.verbose("Folder or folder key is undefined");
                // this.reset();
                return;
            }

            log.verbose("Updating current folder: " + folder.key);
            folder.reset();
            this.currentFolderKey = undefined;

            runInAction(() => {
                this.isLoading = true;
            });

            if (this.tabType === TabSelection.PUBLIC) {
                this.cachedFolders[folder.key] = { folder: folder, updatedDate: new Date() };
                this.currentFolderKey = folder.key;
                this.fetchItemsForNextPage();
            } else {
                let folderItemsRef = this.rootStore.refFactory.foldersRefForTab(this.tabType);
                folder.folderUnsubscribeCallback = folderItemsRef.doc(folder.key).onSnapshot((snap) => {
                    log.verbose("Current folder change detected.");
                    if (snap === undefined || snap === null) {
                        log.error("updateCurrentFolder: Snapshot is null.");
                        return;
                    } else if (snap.exists) {
                        let updatedFolder = new Folder(snap.data());
                        updatedFolder.key = snap.id;

                        runInAction(() => {
                            this.cachedFolders[folder.key] = {
                                folder: updatedFolder,
                                updatedDate: new Date(),
                            };
                            this.currentFolderKey = updatedFolder.key;
                        });

                        this.onCurrentFolderChangeDetected(updatedFolder);
                    } else {
                        log.verbose("Current folder has been deleted");
                        delete this.cachedFolders[snap.id];
                    }
                    runInAction(() => {
                        this.isLoading = false;
                    });
                    resolve();
                });
            }
        });
    }
    public updateFilters = (language: LanguageType, listType: CategoryType) => {
        let changeDetected = false;
        if (this.languageFilter !== language || this.listTypeFilter !== listType) {
            changeDetected = true;
        }

        this.languageFilter = language;
        this.listTypeFilter = listType;

        if (!this.currentFolder) {
            return;
        }

        // Delete cached folders
        for (let key of Object.keys(this.cachedFolders)) {
            if (key !== this.currentFolder.key) {
                delete this.cachedFolders[key];
            }
        }

        // Refresh
        if (changeDetected && this.isVisible) {
            this.updateCurrentFolder(this.currentFolder, true);
        } else {
            this.currentFolder.needsUpdate = true;
        }
    };

    @action
    protected onCurrentFolderChangeDetected(updatedFolder: Folder) {
        log.verbose("onCurrentFolderChangeDetected: " + updatedFolder.key);
        this.currentFolderKey = updatedFolder.key;
        this.updateBreadcrumbs();
        this.fetchFoldersForNextPage();
    }

    @action
    protected fetchFoldersForNextPage = () => {
        log.verbose("fetchFoldersForNextPage - " + this.tabType);

        let callback = (snap) => {
            if (snap === undefined || snap === null) {
                log.error("fetchFoldersForNextPage: Snapshot is null.");
                return;
            }

            if (snap.size < FOLDER_PAGE_SIZE) {
                this.currentFolder.allFoldersLoaded = true;
                this.checkShouldFetchMore();
            }

            let currentFolderKey = this.currentFolderKey; // Create a copy in case current folder changes
            snap.docChanges().forEach((docChange) => {
                let folder = new Folder(docChange.doc.data());
                folder.key = docChange.doc.id;

                let shouldSkip =
                    folder.title === "Phonics" &&
                    this.tabType === TabSelection.SITE &&
                    this.languageFilter !== LanguageType.ENGLISH;
                if (!shouldSkip) {
                    switch (docChange.type) {
                        case "added":
                            this.cachedFolders[folder.parentKey].folder.addSubfolder(folder, folder.parentKey);
                            break;
                        case "modified":
                            this.cachedFolders[folder.parentKey].folder.updateSubFolder(folder, folder.parentKey);
                            break;
                        case "removed":
                            this.cachedFolders[folder.parentKey].folder.removeSubfolder(folder, folder.parentKey);
                            break;
                        default:
                            break;
                    }
                }
            });

            if (snap.docs.length > 1) {
                var lastVisible = snap.docs[snap.docs.length - 1];
                if (this.cachedFolders[currentFolderKey]) {
                    this.cachedFolders[currentFolderKey].folder.lastFetchedFolder = lastVisible;
                }
            } else {
                if (this.cachedFolders[currentFolderKey]) {
                    this.cachedFolders[currentFolderKey].folder.lastFetchedFolder = undefined;
                }
            }
        };

        callback = callback.bind(this);

        let folderItemsRef = this.rootStore.refFactory.foldersRefForTab(this.tabType);
        if (this.currentFolder.lastFetchedFolder === undefined) {
            let unsubscribeCallback = folderItemsRef
                .where("parentKey", "==", this.currentFolderKey)
                .limit(FOLDER_PAGE_SIZE)
                .orderBy("searchTitle")
                .onSnapshot(callback);
            this.currentFolder.subfoldersUnsubscribeCallbacks.push(unsubscribeCallback);
        } else {
            let unsubscribeCallback = folderItemsRef
                .where("parentKey", "==", this.currentFolderKey)
                .limit(FOLDER_PAGE_SIZE)
                .orderBy("searchTitle")
                .startAfter(this.currentFolder.lastFetchedFolder)
                .onSnapshot(callback);
            this.currentFolder.subfoldersUnsubscribeCallbacks.push(unsubscribeCallback);
        }
    };

    @action
    protected fetchItemsForNextPage() {
        if (this.isFolderBrowser) {
            log.verbose("Skipping item fetch. Folder is folder browser.");
            return;
        }

        log.verbose("fetchItemsForNextPage - " + this.tabType);

        let uid = this.rootStore.userStore.user.uid;
        let ref = this.rootStore.refFactory.itemRefForTab(this.tabType);
        let query = FirestoreQueryBuilder.createQuery(
            this.languageFilter,
            this.listTypeFilter,
            this.sortByType,
            this.currentFolderKey,
            uid,
            this.tabType,
            this.currentFolder.lastFetchedItem,
            ref
        );
        if (query) {
            this.isLoading = true;

            // Create a copy in case current folder changes before onSnapshot callback fires
            let currentFolderKey = this.currentFolderKey;
            let unsubscribeCallback = query.onSnapshot((snap) => {
                if (snap === undefined || snap === null) {
                    log.error("queryItemsForFolder: Snapshot is null.");
                    return;
                }

                if (snap.empty && this.currentFolder) {
                    this.currentFolder.allItemsLoaded = true;
                }

                let docChanges = snap.docChanges();
                let cachedFolder = this.cachedFolders[currentFolderKey];
                if (cachedFolder) {
                    docChanges.forEach((docChange) => {
                        let folderItem = this.folderItemFromFirebaseObject(docChange.doc.id, docChange.doc.data());
                        switch (docChange.type) {
                            case "added":
                            case "modified":
                                cachedFolder.folder.addItem(folderItem);
                                break;
                            case "removed":
                                cachedFolder.folder.removeItem(folderItem);
                                break;
                            default:
                                break;
                        }
                    });

                    if (docChanges.length > 1) {
                        cachedFolder.folder.lastFetchedItem = snap.docs[snap.docs.length - 1];
                    } else {
                        cachedFolder.folder.lastFetchedItem = undefined;
                    }
                }

                this.isSearchLoading = false;
                this.isLoading = false;

                // Need to manually sort favorites
                if (this.tabType === TabSelection.FAVORITES) {
                    this.currentFolder.sortItemsByTitle();
                }
            });

            this.currentFolder.itemsUnsubscribeCallbacks.push(unsubscribeCallback);
        } else {
            runInAction(() => {
                this.isLoading = false;
                this.isSearchLoading = false;
            });
        }
    }

    private updateBreadcrumbs = () => {
        if (!this.currentFolder) {
            return;
        }

        // Check if the last breadcrumb is the current folder
        if (this.breadcrumbs.length > 0) {
            if (this.breadcrumbs[this.breadcrumbs.length - 1].key === this.currentFolder.key) {
                return;
            }
        }

        // Check if a parent folder was clicked
        if (this.breadcrumbs.length > 1) {
            let foundFolders: Folder[] = this.breadcrumbs.filter((breadcrumbFolder: Folder) => {
                return breadcrumbFolder.key === this.currentFolder.key;
            });

            if (foundFolders !== undefined && foundFolders.length > 0) {
                while (this.breadcrumbs.length > 0) {
                    if (this.breadcrumbs[this.breadcrumbs.length - 1].key !== this.currentFolder.key) {
                        runInAction(() => {
                            this.breadcrumbs.pop();
                        });
                    } else {
                        break;
                    }
                }
                return;
            }
        }

        // Push breadcrumb
        let folder = this.currentFolder;
        var breadcrumb: Folder = new Folder();
        breadcrumb.key = this.currentFolderKey;
        breadcrumb.title = folder.title !== undefined ? folder.title : "";

        runInAction(() => {
            this.breadcrumbs.push(breadcrumb);
        });
    };

    // Helpers
    private folderItemFromFirebaseObject(key: string, data: any) {
        switch (this.tabType) {
            case TabSelection.SITE_IMAGES:
            case TabSelection.MY_IMAGES:
                let uploadedImage = new CategoryImage(key, data);
                return uploadedImage;

            case TabSelection.CLASS_EDITOR:
                let classList: StudentList = new StudentList(data);
                classList.key = key;
                return classList;

            default:
                let category = new Category(data);
                category.key = key;
                return category;
        }
    }
}
