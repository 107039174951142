import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";

interface SimpleSnackbarProps {
    rootStore: RootStore;
}

export const SimpleSnackbar = observer((props: SimpleSnackbarProps) => {
    const handleClose = () => {
        props.rootStore.showSnackbarMessage(undefined);
        console.log("close");
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={props.rootStore.snackbarMessage !== undefined}
                autoHideDuration={3000}
                onClose={handleClose}
                message={props.rootStore.snackbarMessage}
            />
        </div>
    );
});

export default SimpleSnackbar;
