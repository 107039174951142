import * as React from "react";

import * as CustomLists from "../../models/customLists";
import OptionsMenu, { MenuOption } from "../common/OptionsMenu";
import { ConfirmationMessage } from "../MessageBoxDialogs";

// Material UI
import Paper from "@material-ui/core/Paper";
import FolderIcon from "@material-ui/icons/Folder";

// Stores
import CategoryMenuStore from "../../stores/CategoryMenuStore";

// Localization
import { strings } from "../../core/localizedStrings";

import { Colors } from "../../core/utils/Colors";
import { Fonts } from "../../core/utils/Fonts";

// Layout
var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

export interface FolderCardProps extends React.Props<any> {
    store: CategoryMenuStore;
    folder: CustomLists.Folder;
    showOptions: boolean;
    childElements?: JSX.IntrinsicElements;
    fontSize?: number;
    onBackgroundClickedCallback?: (folder: CustomLists.Folder) => void;
}

export class FolderCard extends React.Component<FolderCardProps> {
    protected styles: any;

    constructor(props: any, context: any) {
        super(props, context);

        this.styles = {
            header: {
                fontFamily: Fonts.Roboto,
                fontSize: 24,
                paddingLeft: 10,
                float: "right",
            } as React.CSSProperties,
        };

        if (this.props.fontSize !== undefined) {
            this.styles.header.fontSize = this.props.fontSize;
        }
    }

    render() {
        var title: string = "";
        if (this.props.folder !== undefined) {
            title = this.props.folder.title;

            return (
                <Paper
                    style={{ margin: "4px 0 4px 0", cursor: "pointer" }}
                    square={true}
                    children={this.renderPaperChildren(title)}
                />
            );
        } else {
            return null;
        }
    }

    protected renderPaperChildren(title: string) {
        let iconHeightWidth = this.styles.header.fontSize * 1.5;

        return (
            <div style={{ position: "relative" }}>
                <div id="background" onClick={this.onBackgroundClicked}>
                    <Row style={{ padding: 10, width: "100%" }}>
                        <Col style={this.styles.header}>
                            <Row middle="xs">
                                <FolderIcon
                                    style={{
                                        color: Colors.lightBlue,
                                        width: iconHeightWidth,
                                        height: iconHeightWidth,
                                    }}
                                />
                                <div style={{ paddingLeft: 10 }}>{title}</div>
                            </Row>
                            {this.props.childElements}
                        </Col>
                    </Row>
                </div>
                {this.props.showOptions && this.renderOptions()}
            </div>
        );
    }

    protected onBackgroundClicked = (event: any) => {
        if (event.currentTarget.id === "background") {
            this.props.onBackgroundClickedCallback(this.props.folder);
        }
    };

    protected renderOptions() {
        if (
            ((this.props.store.currentTab === CustomLists.TabSelection.SITE_IMAGES ||
                this.props.store.currentTab === CustomLists.TabSelection.SITE) &&
                !this.props.store.rootStore.userStore.user.isAdmin) ||
            this.props.store.currentTab === CustomLists.TabSelection.PUBLIC ||
            this.props.folder.isParentFolderLink
        ) {
            return null;
        }

        let options: MenuOption[] = [];
        if (this.props.store.currentTab === CustomLists.TabSelection.FAVORITES) {
            options = [MenuOption.Edit, MenuOption.Move, MenuOption.Delete];
        } else {
            options = [MenuOption.Edit, MenuOption.Move, MenuOption.Delete];
        }

        return (
            <div style={{ right: 8, top: 3, position: "absolute" }}>
                <OptionsMenu options={options} onMenuSelectionChangedCallback={this.onMenuSelectionChanged} />
            </div>
        );
    }

    private onMenuSelectionChanged = (menuSelection: MenuOption) => {
        switch (menuSelection) {
            case MenuOption.Edit:
                this.props.store.setFolderTitleToEdit(this.props.folder);
                break;
            case MenuOption.Delete:
                let message = new ConfirmationMessage(
                    strings.deleteFolderConfirmation,
                    undefined,
                    (result, shouldQuell) => {
                        if (result === "yes") {
                            this.props.store.deleteFolder(this.props.folder);
                        }
                    }
                );
                this.props.store.rootStore.showConfirmationMessage(message);

                break;
            case MenuOption.Move:
                this.props.store.setFolderToMove(this.props.folder);
                break;
            default:
                break;
        }
    };
}
