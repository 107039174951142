import * as React from "react";
import { makeStyles, Theme, createStyles, Grid, Button, IconButton, Tooltip, Typography } from "@material-ui/core";
import { strings } from "core/localizedStrings";
import { Colors } from "core/utils/Colors";
import * as DeviceSize from "core/utils/DeviceSize";
import { RootStore } from "stores";
import SimpleMenu from "components/common/SimpleMenu";
import * as Icons from "core/utils/Icons";
import { ToolType } from "models";

export interface TeamsButtonProps extends React.Props<any> {
    rootStore: RootStore;
}

const TeamsButton = (props: TeamsButtonProps) => {
    let { activityStore } = props.rootStore;
    let numbers =
        props.rootStore.activityStore.currentActivity.activityType === ToolType.Scoreboard
            ? [2, 3, 4, 5, 6, 7, 8]
            : [2, 3, 4, 5];
    if (
        activityStore.currentActivity.activityType !== ToolType.Scoreboard &&
        activityStore.currentStudentList &&
        activityStore.currentStudentList.students.length > 0
    ) {
        return (
            <Grid
                item
                onClick={() => {
                    props.rootStore.activityStore.setShouldShowTeamMaker(true);
                }}
            >
                <DeviceSize.Desktop>
                    <BigScreenTeamsButton />
                </DeviceSize.Desktop>
                <DeviceSize.Tablet>
                    <SmallScreenTeamsButton />
                </DeviceSize.Tablet>
            </Grid>
        );
    } else {
        return (
            <SimpleMenu
                displayComponent={
                    <>
                        <DeviceSize.Desktop>
                            <BigScreenTeamsButton />
                        </DeviceSize.Desktop>
                        <DeviceSize.Tablet>
                            <SmallScreenTeamsButton />
                        </DeviceSize.Tablet>
                    </>
                }
            >
                {
                    <Grid container direction="column" alignItems="flex-end">
                        {numbers.map((teamNumber: number, index) => (
                            <Grid item style={{ minWidth: 60 }} key={index}>
                                <Button
                                    key={teamNumber}
                                    onClick={() => {
                                        activityStore.setNumberOfTeams(teamNumber);
                                    }}
                                >
                                    <Typography variant="subtitle1">{teamNumber}</Typography>
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                }
            </SimpleMenu>
        );
    }
};

export default TeamsButton;

const SmallScreenTeamsButton = () => (
    <Tooltip title={strings.teams} placement="bottom">
        <IconButton style={{ padding: 3 }}>
            <Icons.People
                style={{
                    color: Colors.lightBlue,
                    width: 30,
                    height: 30,
                }}
            />
        </IconButton>
    </Tooltip>
);

const BigScreenTeamsButton = (props: any) => (
    <Button style={{ padding: "5px 10px 5px 10px" }}>
        <Icons.People
            style={{
                color: Colors.lightBlue,
                width: 30,
                height: 30,
            }}
        />
        {strings.teams}
    </Button>
);
