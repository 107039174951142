/* tslint:disable */
export let german = {
    absent: "Abwesend",
    absentStudentsFormat: "{0} abwesend",
    accountRegistration: "Konto Registrierung",
    active: "Aktiv",
    activityPreview: "Aktivitätsvorschau",
    activityPreviewMessage: "Bitte loggen Sie sich ein oder registrieren Sie sich für ein kostenloses Konto.",
    add: "Hinzufügen",
    addAccount: "Konto hinzufügen",
    addCard: "Karte hinzufügen",
    addCards: "Karten hinzufügen",
    addFolder: "Ordner hinzufügen",
    addGroupStudentAccount: "Fügen Sie ein Gruppenkursteilnehmerkonto hinzu",
    addKeyword: "Stichwort hinzufügen",
    addName: "Name hinzufügen",
    addTeacherAccount: "Lehrerkonto hinzufügen",
    addToFavorites: "Zu den Favoriten hinzufügen",
    addWithPlus: "+ Hinzufügen",
    additionalResources: "Zusätzliche Ressourcen",
    admin: "Administrator",
    affirmative: "Positiv",
    afterTrial:
        "Nach der 30-tägigen Testversion können Sie die Site entweder weiterhin als Basismitglied verwenden. oder ein Upgrade auf Premium durchführen.",
    all: "Alles",
    allFieldsRequired: "Alle Felder sind erforderlich.",
    allowCardsToBeFlipped: "Karten dürfen umgedreht werden",
    allowedToChangePassword: "Lehrer darf das Passwort ändern",
    annual: "Jährlich",
    annualSubscription: "Jahresabonnement",
    answer: "Antworten",
    audioClipLanguageLabel: "Audioclip-Sprache",
    backToWorksheets: "Zurück zu den Arbeitsblättern",
    basic: "Basic",
    basicMemberCredits:
        "* Basic Mitglieder erhalten 8 monatliche Credits. Sobald alle Credits verwendet wurden, Der Zugriff auf die Website ist bis zum ersten Tag des nächsten Monats beschränkt.",
    billedAnnually: "Jahresrechnung in Rechnung gestellt",
    billedAnnuallyFormat: "Jährlich abgerechnet ({0})",
    billedMonthly: "Monatlich abgerechnet",
    billedMonthlyFormat: "Monatlich abgerechnet ({0})",
    billingFrequency: "Abrechnungshäufigkeit",
    boy: "Junge",
    boys: "Jungs",
    browseAllCategories: "Kategorien durchsuchen / bearbeiten",
    browseAllLists: "Listen durchsuchen / bearbeiten",
    cancel: "Stornieren",
    cancelSubscription: "Abonnement kündigen",
    cannotAddManagedAccountsInTrial: "Verwaltete Konten können nicht während des Testzeitraums hinzugefügt werden.",
    cannotBeEmpty: "Kann nicht leer sein.",
    card: "Karte",
    cardLower: "Karte",
    cardsLower: "Karten",
    category: "Kategorie",
    category2: "Kategorie2",
    categoryCharactersNotSupported:
        "Diese Kategorie enthält Zeichen, die von dieser Aktivität noch nicht unterstützt werden.",
    categoryTypeNotSupported: "Dieser Kategorietyp wird von dieser Kategorie noch nicht unterstützt.",
    changeCategory: "Kategorie wechseln",
    changeEditStudents: "Schüler ändern / bearbeiten",
    changePassword: "Ändere das Passwort",
    checkEmailForVerifcation: "Bitte überprüfen Sie Ihre E-Mail, um die Aktivierung Ihres Kontos abzuschließen.",
    chinese: "Chinesisch",
    className: "Klassenname",
    clear: "klar",
    clearFilters: "Filter löschen",
    clearSelectedImage: "Ausgewähltes Bild löschen",
    clearSelectedImages: "Ausgewählte Bilder löschen",
    clickHereTo: "Kein Account? Hier klicken, um",
    confirmChangeCategory: "Das Ändern der Kategorie setzt die Aktivität zurück. Möchten Sie es wirklich ändern?",
    confirmChangeClassList:
        "Wenn Sie die Schülerliste ändern, wird die Aktivität zurückgesetzt. Möchten Sie es wirklich ändern?",
    confirmChangeTeamNumber:
        "Wenn Sie die Anzahl der Teams ändern, wird die Aktivität zurückgesetzt. Möchten Sie es wirklich ändern?",
    confirmRestartSubscription:
        "Sind Sie sicher, dass Sie Ihr Abonnement erneut starten möchten? Ihre Kreditkarte wird erst ab {0} belastet.",
    contact: "Kontakt",
    contactAdminMessage: " Wenn das Problem weiterhin besteht, wenden Sie sich an den Site-Administrator.",
    contactDescription:
        "Bitte teilen Sie uns Ihre Fragen oder Probleme mit. Senden Sie einfach eine Nachricht und wir melden uns so schnell wie möglich bei Ihnen.",
    contactFormSubmitted: "Vielen Dank! Ihre Nachricht wurde gesendet.",
    copy: "Kopieren",
    copyLower: "Kopieren",
    createCardFromSelectedImage: "Karte aus ausgewähltem Bild erstellen",
    createCardsFromSelectedImages: "Erstellen Sie Karten aus {0} ausgewählten Bildern",
    createCopyIn: "Kopie erstellen in:",
    createCustomLists: "Erstellen Sie benutzerdefinierte Listen",
    createNewList: "Neue Liste erstellen",
    createSelectCategory: "Lektion erstellen oder auswählen",
    createSelectCategoryDescription:
        "Erstellen Sie in Sekunden eine benutzerdefinierte Lektion oder suchen Sie aus Tausenden von vorhandenen.",
    createSelectClassList: "Erstellen oder wählen Sie eine Schülerliste",
    createStudentNameLists: "Erstellen Sie Listen mit Schülernamen",
    createTeams: "Teams erstellen",
    createdBy: "Erstellt von",
    createdOn: "Erstellt am",
    creditCard: "Kreditkarte",
    creditNeeded: "1 Gutschrift pro Nutzung",
    currentSubscriptionRequiredForManagedAccounts:
        "Ein aktives Abonnement ist erforderlich. Bitte starten Sie Ihr Abonnement erneut, um weitere Konten hinzuzufügen.",
    customizeHeader: "{{Anpassen}}, um zu jeder Lektion zu passen",
    customizeText:
        "Nehmen Sie eine einfache Vokabelliste oder eine Liste mit Fragen und machen Sie daraus ein spannendes Aktivität in Sekunden. Nachdem eine Liste erstellt wurde, kann sie mit jeder Aktivität auf der Site verwendet werden.",
    dataBeingMigratedFromBarryFunEnglish:
        "Ihre Kontodaten werden von BarryFunEnglish migriert. Dies muss nur einmal durchgeführt werden. Je nach Datenmenge kann dies von a\n        wenige Sekunden bis einige Stunden. Wenn Sie nach einigen Stunden nicht alle Ihre Daten sehen, wenden Sie sich bitte an\n        der Site-Administrator",
    delete: "Löschen",
    deleteCategoryConfirmation: "Möchten Sie diese Kategorie endgültig löschen?",
    deleteFavoriteConfirmation: "Möchten Sie dies wirklich aus Ihren Favoriten entfernen?",
    deleteFolderConfirmation: "Möchten Sie diesen Ordner und seinen gesamten Inhalt endgültig löschen?",
    deleteStudentListConfirmation: "Möchten Sie diese Liste wirklich entfernen?",
    discount: "Rabatt ({0}%):",
    discounts: "Rabatte:",
    done: "Erledigt",
    dutch: "Niederländisch",
    edit: "Bearbeiten",
    editProfile: "Profil bearbeiten",
    email: "Email",
    emailAlreadyRegistered: "Die E-Mail Adresse wurde schon einmal registriert",
    emailOrUsername: "E-Mail Adresse oder Benutzername",
    emailRequiredError: "Ein Benutzername ist erforderlich.",
    emailSentTo: "E-Mail an {0} gesendet",
    endGame: "Spiel beenden",
    english: "Englisch",
    enterEmailUsedToRegister:
        "Bitte geben Sie die E-Mail-Adresse ein, unter der Sie sich für die Website registriert haben:",
    enterIncorrectAnswers: "Gib meine eigenen falschen Antworten ein",
    enterQuestion: "Frage eingeben",
    enterText: "Text eingeben:",
    enterValidEmail: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
    enterVocabAndQuestion: "Geben Sie das Wortwort und die Frage ein",
    enterVocabulary: "Geben Sie den Wortschatz ein",
    existingAccount: " bestehendes Konto",
    expirationDateFormat: "Ablaufdatum: {0}",
    expired: "Abgelaufen",
    expires: "Läuft ab",
    expressionInputTextField1Label: "Textfeld 1 (Frage)",
    expressionInputTextField2Label: "Textfeld 2 (Antwort)",
    favorites: "Favoriten",
    flashcards: "Flashcards",
    folderName: "Ordnernamen:",
    forClassroomHeader: "Entwickelt für das {{classroom}}",
    forClassroomText:
        "Spiele und Aktivitäten sind für den Einsatz im Klassenzimmer und vorgesehen Arbeitet gut mit großen oder kleinen Klassen.\n            Spiele können aus zwei bis fünf Teams bestehen, und die Namen der Studenten können zur zufälligen Auswahl eingegeben werden.",
    forgotPassword: "Passwort vergessen?",
    free: "Kostenlos",
    french: "Französisch",
    games: "Spiele",
    gender: "Geschlecht",
    german: "Deutsche",
    girl: "Mädchen",
    girls: "Mädchen",
    girlsVsBoys: "Mädchen gegen Jungen",
    greek: "griechisch",
    groupNumberFormat: "Gruppe {0}",
    homePageContactMessage: "Haben Sie {{Fragen}} für uns?",
    imageOverlayGraphic: "Grafik für Bildüberlagerung",
    imageWithText: "Bild mit einzelnem Textfeld",
    imageWithTextDescription:
        "Diese Listen eignen sich hervorragend zum Üben von Vokabeln, Lesen und Sprechen. Sie arbeiten mit fast allen Aktivitäten auf der Website zusammen.",
    imageWithTwoText: "Bild mit zwei Textfeldern",
    imageWithTwoTextDescription:
        "Diese Listen dienen zum Stellen von Fragen zu einem Bild oder zum Übersetzen zwischen zwei Sprachen",
    imagesSelectorInstructions: "Bilder auswählen oder hochladen, um Karten zu erstellen:",
    interactive: "Interaktiv",
    interactiveFlashcards: "Interaktive Karteikarten",
    introduce: "Vorstellen",
    invalidEmail: "Ungültige E-Mail-Adresse.",
    invalidPassword: "Passwörter müssen mindestens 6 Zeichen lang sein.",
    invalidUsername: "Ungültiger Benutzername.",
    invalidUsernameOrPassword: "Sie haben einen ungültigen Benutzernamen oder ein ungültiges Passwort eingegeben",
    italian: "Italienisch",
    japanese: "japanisch",
    keywordsLabel: "Schlüsselwörter",
    korean: "Koreanisch",
    language: "Sprache",
    languageTitle: "Sprache",
    learn: "Lernen",
    learnDescription:
        "Führen Sie eine neue Lektion oder Überprüfung mit interaktiven oder druckbaren Flash-Karten ein.",
    lesson: "Lektion",
    lessonDescription: "Mischung aus Einzelbild und Bild mit zwei Textfeldern",
    lessonTypeSelectorTitle: "Möchten Sie Vokabeln oder Fragen aus dieser Lektion üben?",
    listAccessibility: "Zugänglichkeit auflisten",
    listTitleLabel: "Listentitel",
    listTypeTitle: "Listentyp",
    loggingIn: "Einloggen",
    loginToCopy: "Sie müssen angemeldet sein, um Listen kopieren zu können.",
    loginToCreateFolders: "Sie müssen angemeldet sein, um Ordner erstellen zu können.",
    loginToCreateLists: "Sie müssen angemeldet sein, um benutzerdefinierte Listen erstellen zu können.",
    loginToEditLists: "Sie müssen angemeldet sein, um Listen bearbeiten zu können.",
    loginToFavorite: "Sie müssen angemeldet sein, um Favoriten speichern zu können.",
    logout: "Ausloggen",
    mainPageText: "Individuelle Sprachlernaktivitäten für jede Lektion",
    managedAccounts: "Verwaltete Konten",
    managedAccountsDescription:
        "Fügen Sie verwaltete Konten hinzu, wenn Sie mehrere Mitgliedschaften unter einem Abonnement haben möchten. Rabatte werden basierend auf der Anzahl der Gesamtkonten angeboten.",
    maxFilesUploadAttempt: "Sie können maximal maximal {0} Dateien gleichzeitig hochladen.",
    membershipTypes: "Arten der Mitgliedschaft",
    message: "Botschaft",
    misses: "Misses",
    monthly: "Monatlich",
    monthlySubscription: "Monatliches Abo",
    move: "Bewegung",
    moveFolderTo: "Ordner verschieben nach:",
    moveListTo: "Liste verschieben nach:",
    multipleChoiceInstructions: "Was möchten Sie bei Multiple-Choice-Aktivitäten für falsche Antworten verwenden?",
    multipleLanguageSupport: "Mehrere Sprachen werden unterstützt",
    mustHaveOneIncorrectAnswers: "Sie müssen mindestens eine falsche Antwort eingeben.",
    myAccount: "Mein Konto",
    myClasses: "Meine Klassen",
    myImages: "Meine Bilder",
    myLists: "Meine Listen",
    name: "Name",
    negative: "Negativ",
    newFolder: "Neuer Ordner",
    nextBillingDate: "Nächstes Rechnungsdatum: {0} ({1})",
    no: "Nein",
    noAcceptableFilesFound:
        "Keine gültigen Dateien gefunden Dateien sind auf eine maximale Größe von {0} MB beschränkt.",
    noCardOnFile: "Keine Karte im Archiv",
    noCreditCardRequired: "Keine Kreditkarte benötigt.",
    noRemainingCredits:
        "Keine verbleibenden Credits diesen Monat. Registrieren Sie sich für ein Premium-Konto für unbegrenzten Zugriff.",
    none: "Keiner",
    notActivated: "Nicht aktiviert",
    notActive: "Nicht aktiv",
    notChargedUntilFormat: "Kreditkarten werden erst nach {0} belastet.",
    notChargedUntilTrialComplete: "Ihre Kreditkarte wird erst nach Ablauf des Testzeitraums belastet.",
    numberOfTeams: "Anzahl der Mannschaften",
    off: "aus",
    ok: "OK",
    on: "Auf",
    oneRemainingCredit: "Diesen Monat haben Sie noch 1 Guthaben.",
    options: "Optionen",
    or: "oder",
    page: "Seite",
    pageNotFound: "Seite nicht gefunden",
    password: "Passwort",
    passwordLengthError: "Passwort muss mindestens 6 Zeichen lang sein.",
    passwordRequiredError: "Passwort wird benötigt",
    passwordResetEmailHasBeenSendMessage:
        "Bitte überprüfen Sie Ihre E-Mail auf einen Link, um Ihr Passwort zurückzusetzen.",
    pay: "Zahlen",
    payBalance: "Zahlen balance",
    paymentMethod: "Zahlungsmethode",
    pendingSubscription: "Ausstehendes Abonnement",
    play: "abspielen",
    playDescription: "Spannende Spiele für große und kleine Klassen",
    pleaseAddCardForRestart: "Sie müssen eine Kreditkarte hinzufügen, bevor Sie Ihr Abonnement erneut starten.",
    portuguese: "Portugiesisch",
    practice: "Trainieren",
    practiceDescription: "Interaktive und druckbare Arbeitsblätter",
    premium: "Prämie",
    premiumPriceFormatMonth: "$ {0} / Monat",
    premiumPriceFormatYear: "$ {0} / Jahr",
    premiumTrial: "Premium (Testversion)",
    preview: "Vorschau",
    price: "Preis",
    print: "Drucken",
    printDescription: "Drucken Sie Karteikarten oder Arbeitsblätter für das Offline-Üben",
    printable: "Druckbar",
    printableFlashcards: "Druckbare Flashcards",
    private: "Privatgelände",
    problemSubmittingContactForm: "Beim Senden Ihrer Nachricht ist ein Problem aufgetreten.",
    problemUpdatingUserErrorMessage: "Beim Aktualisieren der Benutzerdetails ist ein Fehler aufgetreten.",
    problemUsingCredit: "Bei der Verwendung Ihrer Credits ist ein Problem aufgetreten.",
    profile: "Profil",
    proratedAmountDue: "Jetzt fällig: {0}",
    public: "Öffentlichkeit",
    publicLists: "Community-Listen",
    quellMessage: "Zeige diese Nachricht nicht noch einmal",
    question: "Frage",
    questions: "Fragen",
    quiz: "Quiz",
    randomize: "Randomize",
    randomizeAndReset: "Randomisieren und zurücksetzen",
    recent: "Kürzlich",
    register: "registrieren",
    registerUsernamePassword: "Registrieren Sie sich mit Benutzername / Passwort",
    rejectedFileFormat:
        "{0} -Datei wurde nicht hochgeladen. Datei war möglicherweise zu groß oder von der falscher Dateityp Es werden nur Bilddateien mit einer Größe von mindestens 500 KB akzeptiert.",
    rejectedFilesFormat:
        "{0} -Dateien wurden nicht hochgeladen. Dateien waren möglicherweise zu groß oder von der falscher Dateityp Es werden nur Bilddateien mit einer Größe von mindestens 500 KB akzeptiert.",
    remainingCreditsFormat: "Sie haben noch {0} verbleibende Gutschriften in diesem Monat.",
    removeAllLetters: "Entferne alle Buchstaben",
    removeFromFavorites: "Von Favoriten entfernen",
    removeManagedUserAccountConfirmation:
        "Dieses Konto wird entfernt und der Benutzer wird nicht mehr sein darf sich einloggen. Möchten Sie dieses Konto endgültig löschen?",
    resendEmail: "E-Mail zurücksenden",
    reset: "Zurücksetzen",
    restartSubscription: "Abonnement neu starten",
    restartSubscriptionMessage: "Bitte wählen Sie eine Abrechnungshäufigkeit für Ihr Abonnement.",
    review: "Rezension",
    reviewDescription: "Multiple-Choice-Quiz zum Hören, Lesen, Schreiben und Sprechen",
    russian: "Russisch",
    sameAsTextField1Label: "Gleich wie Textfeld 1",
    sampleClass: "Musterklasse",
    save: "sparen",
    search: "Suche",
    select: "Wählen...",
    selectACategory: "Wählen Sie eine Kategorie",
    selectCategoryBeforeActivity: "Sie müssen eine Kategorie auswählen, bevor Sie eine Aktivität auswählen.",
    selectFolderToAddFavoriteTo: "Wählen Sie den Ordner aus, zu dem Sie einen Favoriten hinzufügen möchten:",
    selectStudentList: "Wählen Sie eine Schülerliste aus",
    showCategoryDetails: "zeige Details",
    signIn: "Anmeldung",
    signInRegister: "Anmeldung Registrieren",
    signInUsernamePassword: "Loggen Sie sich mit Benutzername / Passwort ein",
    signUpForFree: "Melde dich kostenlos an",
    signUpForFreeMessage: "Registrieren Sie sich jetzt 30 Tage lang für ein kostenloses Premium-Konto!",
    singleTeacher: "Einziger Lehrer",
    singleText: "Einzelne Textfeld",
    singleTextDescription:
        "Diese Listen eignen sich hervorragend zum Lesen und zur Rechtschreibprüfung. Sie sind auch nützlich für Aktivitäten, für die kein Bild erforderlich ist.",
    siteImages: "Site-Bilder",
    siteLists: "Site-Listen",
    sortByPopularity: "Popularität",
    sortByRecentlyCreated: "Kürzlich erstellt",
    sortByRelevance: "Relevanz",
    sortByTitle: "Titel",
    sortByTypeTitle: "Sortiere nach",
    spanish: "Spanisch",
    start: "Start",
    startSubscription: "Abonnement starten",
    startSubscriptionAfterTrial:
        "Starten Sie Ihr Abonnement, um Ihre Premium-Mitgliedschaft danach fortzusetzen Die Studie ist abgeschlossen.",
    studentGroup: "Studentengruppe",
    studentGroups: "Studentengruppen",
    studentList: "Schülerliste",
    subject: "Gegenstand",
    submit: "einreichen",
    subscribe: "Abonnieren",
    subscription: "Abonnement",
    subscriptionDescriptionFormat: "Abonnementtyp: {0}",
    subscriptionType: "Abonnementtyp",
    subtotal: "Zwischensumme:",
    support: "Kontaktieren Sie Support",
    teacher: "Lehrer",
    teacherCountFormat: "Anzahl der Lehrer: {0}",
    teachers: "Lehrer",
    teachingTools: "Lehrmittel",
    teamNumberFormat: "Team {0}",
    tenOrMore: "Zehn oder mehr",
    textField1Label: "(Frage)",
    textField2Label: "(Antworten)",
    textToSpeechSupportFormat: "Audioclips werden automatisch mit der Text-to-Speech-Technologie erstellt",
    thousandsOfHeader: "{{Tausende}} von Kategorien zur Auswahl",
    thousandsOfText:
        "Sie möchten keine eigenen Kategorien erstellen? Etwas durchstöbern Tausende von der KinteractiveLearning-Community erstellt. Mehr werden jeden Tag hinzugefügt!",
    threeOrMore: "Drei oder mehr",
    titleCannotBeEmptyInfoMessage: "Titel darf nicht leer sein Bitte geben Sie einen Titel ein.",
    tools: "Werkzeuge",
    toolsDescription: "Gruppen- und Teammacher, Studenten, Stoppuhr und Anzeigetafel",
    totalDue: "Insgesamt fällig:",
    totalManagedAccounts: "Gesamt verwaltete Konten:",
    totalStudentsFormat: "{0} Studenten",
    turkish: "Türkisch",
    twoTextOnly: "Zwei Textfelder",
    twoTextOnlyDescription:
        "Diese Listen werden verwendet, um Fragen zu stellen, die nicht erforderlich sind. oder zum Übersetzen zwischen zwei Sprachen",
    type: "Art",
    unlimited: "Unbegrenzt",
    update: "Aktualisieren",
    updateEmailError: "Beim Aktualisieren der E-Mail-Adresse ist ein Fehler aufgetreten.",
    uploadImages: "Bilder hochladen",
    uploadImagesDropzoneInstructions: "Legen Sie Bilder ab oder klicken Sie auf, um Ihren Computer zu durchsuchen.",
    uploadMessage: "{0} Bilder hochladen.",
    uploadProfileImageError: "Beim Hochladen des Profilbildes ist ein Fehler aufgetreten.",
    useAsVocabulary: "Zur Vokabelliste hinzufügen",
    useCredit: "Gutschrift verwenden",
    useOtherAnswersAsIncorrect: "Verwenden Sie andere Karten mit korrekten Antworten",
    username: "Nutzername",
    usernameOptional: "Benutzername (optional)",
    usernameRequiredError: "Ein Benutzername ist erforderlich.",
    usernameTaken: "Benutzername ist bereits vergeben",
    usernameUnavailable: "Benutzername ist nicht verfügbar",
    vocabulary: "Wortschatz",
    worksheetCreators: "Arbeitsblatt-Ersteller",
    worksheets: "Arbeitsblätter",
    yes: "Ja",
    getStarted: "",
    requiresFlash: "",
    onlySupportsLatinBasedLanguages: "",
    upgradeToPremium: "",
    startReview: "",
    numberOfGroups: "",
    hideAnswer: "",
    autoPlayAudio: "",
    imageBank: "",
    doubleSidedCards: "",
    ignoreFlippedCardsForReview: "",
    soft: "",
    medium: "",
    strong: "",
    continuePlaying: "",
    languageNotSupported: "",
    createLink: "",
    pinActivityToDashboard: "",
    enterName: "",
    createStudentLink: "",
    expiration: "",
    oneDay: "",
    oneWeek: "",
    never: "",
    link: "",
    copyToClipboard: "",
    pinnedActivities: "",
    studentActivityLinks: "",
    numberOfCards: "",
    matchType: "",
    imageType: "",
    otherOptions: "",
    wordImage: "",
    imageImage: "",
    wordWord: "",
    activity: "",
    seeLinkVisitors: "",
    recentHistory: "",
    startTime: "",
    completedTime: "",
    results: "",
    iDontKnow: "",
    iKnowTheAnswer: "",
    seconds: "",
    minutes: "",
    hours: "",
    pause: "",
    set: "",
    audioHeading: "",
    playAudioOnCorrect: "",
    playAudioOnWrong: "",
    answerTypeHeading: "",
    multipleChoiceAnswerTypeOption: "",
    rightOrWrongAnswerTypeOption: "",
    showNumbersHeading: "",
    showNumbersMultipleChoice: "",
    timerHeading: "",
    showTimerMultipleChoice: "",
    game: "",
    correct: "",
    wrong: "",
    teams: "",
    imagesInGrid: "",
    multipleChoiceOptions: "",
    playAudioOnSelection: "",
    classicModeOptions: "",
    selectAll: "",
    flipCardsToFront: "",
    flipCardsToBack: "",
    shuffle: "",
    showAllCards: "",
    bonusRound: "",
    crazyCupsIntroMessage: "",
    crazyCupsBonusRoundMessage: "",
    goodJob: "",
    sorryYouMissedIt: "",
    teamReadyFormat: "",
    readyToTryAgain: "",
    cupsSpeedAdjustment: "",
    textOnly: "",
    imageText: "",
    imageOnly: "",
    hideShips: "",
    useClassicMode: "",
    classicModeDescription: "",
    stop: "",
    bullsEye: "",
    removeNameOnceSelected: "",
    clearDartAfterThrow: "",
    stopBoardAfterThrow: "",
    playSoundEffect: "",
    spinForPoints: "",
    showImageProfileImage: "",
    cardType: "",
    gridSize: "",
    cardsPerPage: "",
    noListsGetStarted: "",
    noImagesGetStarted: "",
    isTraceable: "",
    twoColumns: "",
    randomizeWorksheet: "",
    createPDF: "",
    fontSize: "",
    goodJobReadyForNext: "",
    whoGoesFirst: "",
    teamGoesFirstFormat: "",
    popBalloonsWith: "",
    dartMeterSpeed: "",
    fontStyle: "",
    cardSensitivity: "",
    points: "",
    hotOrColdInstructions: "",
    inviteUsers: "",
    availableInvitesFormat: "",
    numberOfAccountsToAdd: "",
    removeSubscriptionManagerConfirmation: "",
    managedSubscription: "",
    disconnectFromAccountManager: "",
    confirmToBeManagedConfirmationFormat: "",
    pendingManagerNotFound: "",
    steal: "",
    newWord: "",
    canYouSpell: "",
    wouldLikeToStealFormat: "",
    selectSecondCategory: "",
    clearSecondCategory: "",
    borderThickness: "",
    borderColor: "",
    subscriptionIsPastDue: "",
};
