// import { log } from "../../";

import * as React from "react";
import { observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import * as firebase from "firebase";

// Material UI
import IconButton from "@material-ui/core/IconButton";

// Components
import LessonTypeSelectorMenu from "../menus/LessonTypeSelectorMenu";
import ActivityOptionsBarFactory from "./ActivityOptionsBarFactory";
import RecentStudentListsMenu from "components/menus/RecentStudentListsMenu";
import { ResetButton, EndGameButton } from "components/common/CommonButtons";

// Stores
import RootStore from "stores/RootStore";

// Models
import { Game, CategoryType, FlashcardActivity, Tool, ToolType } from "models";

// Utils
import { strings } from "core/localizedStrings";
import ActivitySupport from "core/utils/ActivitySupport";
import { ACTIVITY_OPTIONS_BAR_INDEX } from "layers";
import RecentCategoriesMenu from "components/menus/RecentCategoriesMenu";
import Activity from "models/activities/Activity";
import * as Icons from "core/utils/Icons";
import Tooltip from "@material-ui/core/Tooltip";
import { PinnedActivity } from "models/activities/PinnedActivity";
import ActivityOptionsDialog from "./ActivityOptionsDialog";
import { Divider, Grid } from "@material-ui/core";
import { Colors } from "core/utils/Colors";

import TeamsButton from "components/menus/TeamsButton";
import { ActivityOptionsFactory } from "./ActivityOptionsFactory";

type CSSPosition = "relative" | "absolute";

export interface ActivityOptionsBarProps extends React.Props<any> {
    rootStore: RootStore;
    activity: Activity;
    gameEnded?: boolean;
    supportsSecondCategory?: boolean;
    customOptions?: JSX.Element;
    randomizeCallback?: () => void;
    resetSWFContentCallback?: () => void;
    endGameCallback?: () => void;
    resetCallback?: () => void;
    clearStudentCardCallback?: () => void;
}

@observer
export default class ActivityOptionsBar extends React.Component<ActivityOptionsBarProps> {
    @observable usedCredit = false;
    @observable cssPosition: CSSPosition;

    componentDidMount() {
        runInAction(() => {
            this.props.rootStore.activityStore.setTeamNumbersLocked(false);
        });
    }

    render() {
        let { currentActivity } = this.props.rootStore.activityStore;
        if (currentActivity instanceof Tool) {
            if (currentActivity.activityType === ToolType.Stopwatch) {
                return null;
            }
        }
        return (
            <Grid
                container
                alignItems="center"
                style={{
                    backgroundColor: Colors.translucentWhite,
                    zIndex: ACTIVITY_OPTIONS_BAR_INDEX,
                    position: "relative",
                }}
            >
                <Grid item xs={6} sm={9}>
                    {this.renderLeftOptions()}
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Grid container justify="flex-end" alignItems="center">
                        <Grid item>{this.renderRightOptions()}</Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    private renderLeftOptions = () => {
        let { rootStore } = this.props;
        let { activityStore } = rootStore;

        let shouldUseCategory =
            ActivitySupport.usesCategory(this.props.rootStore.activityStore.currentActivity) &&
            !activityStore.activityLinkVisitorKey;
        let shouldUseLessonType =
            ActivitySupport.supportsDifferentLessonTypes(activityStore.currentActivity) &&
            activityStore.currentCategory &&
            activityStore.currentCategory.type === CategoryType.Lesson;
        let shouldUseStudentLists =
            ActivitySupport.usesStudentListSnackbar(activityStore.currentActivity) &&
            this.props.rootStore.activityStore &&
            !this.props.rootStore.activityStore.activityLinkVisitorKey;
        let isGroupMaker = activityStore.currentActivity.activityType === ToolType.GroupMaker;

        return (
            <Grid container alignItems="center">
                {shouldUseCategory && (
                    <RecentCategoriesMenu rootStore={rootStore} recentCategories={activityStore.recentCategories} />
                )}
                {(shouldUseStudentLists || isGroupMaker) && (
                    <RecentStudentListsMenu
                        rootStore={rootStore}
                        recentClassLists={activityStore.recentClassLists}
                        currentClassList={activityStore.currentStudentList}
                    />
                    // <StudentListsMenu
                    //     currentClassList={activityStore.currentStudentList}
                    //     onEditCallback={this.handleStudentListChanged}
                    //     onSelectionClearedCallback={this.handleStudentListCleared}
                    // />
                )}
                {ActivitySupport.supportsTeams(this.props.activity) && <TeamsButton rootStore={rootStore} />}
                {shouldUseLessonType && (
                    <>
                        <Divider flexItem orientation="vertical" style={{ margin: 5 }} />
                        <LessonTypeSelectorMenu key={5} rootStore={this.props.rootStore} />
                    </>
                )}

                <>
                    <Divider flexItem orientation="vertical" style={{ margin: 5 }} />
                    <ActivityOptionsBarFactory
                        activity={this.props.rootStore.activityStore.currentActivity}
                        activityStore={this.props.rootStore.activityStore}
                    />
                </>
            </Grid>
        );
    };

    private renderRightOptions = () => {
        let { activity } = this.props;
        return (
            <Grid container>
                <Grid item>
                    <CreateLinkButton rootStore={this.props.rootStore} onClick={this.handleCreateLinkClicked} />
                </Grid>
                {(activity instanceof FlashcardActivity || activity instanceof Game || activity instanceof Tool) &&
                    this.props.resetCallback && (
                        <Grid item style={{ padding: 2, zIndex: 5000 }}>
                            <ResetButton onClick={this.props.resetCallback} />
                        </Grid>
                    )}
                {activity instanceof Game && this.props.endGameCallback && (
                    <Grid item style={{ padding: 2 }}>
                        <EndGameButton onClick={this.props.endGameCallback} />
                    </Grid>
                )}
                {!ActivitySupport.requiresFlash(activity) && this.renderOptionsButton()}
                {/* <PinActivityButton rootStore={this.props.rootStore} onClick={this.handlePinActivityClicked} /> */}
            </Grid>
        );
    };

    private renderOptionsButton = () => {
        let { rootStore, activity } = this.props;
        let { activityStore } = rootStore;
        let { activityType } = activity;

        let activityOptions = ActivityOptionsFactory.get(activityStore);
        if (!activityOptions) {
            return null;
        }

        return (
            <div style={{ paddingRight: 10 }}>
                <Tooltip title={strings.options} placement="bottom">
                    <IconButton
                        size="small"
                        onClick={() => {
                            this.props.rootStore.activityStore.shouldShowActivityOptions = true;
                        }}
                    >
                        <Icons.Settings />
                    </IconButton>
                </Tooltip>
                <ActivityOptionsDialog
                    activityStore={activityStore}
                    activityType={activityType}
                    customOptions={this.props.customOptions}
                    open={this.props.rootStore.activityStore.shouldShowActivityOptions}
                    onClose={() => {
                        this.props.rootStore.activityStore.updateOptions();
                        this.props.rootStore.activityStore.shouldShowActivityOptions = false;
                    }}
                ></ActivityOptionsDialog>
            </div>
        );
    };

    // UI Interaction callbacks
    // --------------------------------------------------------
    private handleCreateLinkClicked = () => {
        this.props.rootStore.activityStore.setShouldShowActivityLinkDialog(true);
    };

    private handlePinActivityClicked = () => {
        let activityToPin: PinnedActivity = {
            activityPath: window.location.pathname,
            categoryName: this.props.rootStore.activityStore.currentCategory.title,
            categoryKey: this.props.rootStore.activityStore.currentCategory.key,
            date: firebase.firestore.Timestamp.now(),
        };
        this.props.rootStore.userStore.pinActivity(activityToPin);
        this.props.rootStore.showSnackbarMessage("Activity pinned to dashboard.");
    };

    private onClassListChangeConfirmed() {
        if (
            this.props.rootStore.activityStore.currentStudentList &&
            this.props.rootStore.activityStore.currentStudentList.students.length > 0 &&
            this.props.rootStore.activityStore.teams &&
            ActivitySupport.supportsTeams(this.props.rootStore.activityStore.currentActivity)
        ) {
            this.props.rootStore.activityStore.setShouldShowTeamMaker(true);
        } else {
            this.props.rootStore.activityStore.setShouldShowStudentListSelector(true);
        }
        this.props.rootStore.activityStore.setShouldShowStudentSnackbar(false);
    }
}

interface CreateLinkButtonProps {
    rootStore: RootStore;
    onClick: () => void;
}

const CreateLinkButton = (props: CreateLinkButtonProps) => {
    if (props.rootStore.activityStore.activityLinkVisitorKey || props.rootStore.authStore.isAnonymous) {
        return null;
    }
    return (
        <Tooltip title={strings.createLink} placement="bottom">
            <IconButton onClick={props.onClick} size="small">
                <Icons.Link />
            </IconButton>
        </Tooltip>
    );
};

interface PinActivityButtonProps {
    rootStore: RootStore;
    onClick: () => void;
}

const PinActivityButton = (props: PinActivityButtonProps) => {
    if (props.rootStore.activityStore.activityLinkVisitorKey || props.rootStore.authStore.isAnonymous) {
        return null;
    }
    return (
        <Tooltip title={strings.pinActivityToDashboard} placement="bottom">
            <IconButton onClick={props.onClick} style={{ marginRight: -15 }}>
                <div style={{ color: "transparent", textShadow: "0 0 0 #000000", fontSize: 12 }}>📌</div>
            </IconButton>
        </Tooltip>
    );
};
