import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyBWt9Mkwg6bklLTbMZhrSuepblSODHIe3Q",
    authDomain: "www.kinteractivelearning.com",
    databaseURL: "https://kinteractivelearning.firebaseio.com",
    storageBucket: "gs://kinteractivelearning.appspot.com",
    projectId: "kinteractivelearning",
    messagingSenderId: "939693817755",
    appId: "1:939693817755:web:212a29ca7271f00e78ab3e",
    measurementId: "G-XB4YNRWC0K",
};

export const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG);
export const firebaseAnalytics = firebase.analytics();
