import { BillingFrequency } from "../../../src/models";
import { BILLED_ANNUALLY_AMOUNT, BILLED_MONTHLY_AMOUNT } from "../../../src/constants";

export const STUDENT_GROUP_BILLED_MONTHLY_AMOUNT = 300;
export const STUDENT_GROUP_BILLED_ANNUALLY_AMOUNT = 2400;
export const TEN_OR_GREATER_DISCOUNT = 0.3;
export const THREE_OR_GREATER_DISCOUNT = 0.2;

export function calculateTotal(
    billingFrequency: BillingFrequency,
    teacherCount: number,
    studentGroupCount: number
): number {
    return (
        subtotal(billingFrequency, teacherCount, studentGroupCount) -
        discount(billingFrequency, teacherCount, studentGroupCount)
    );
}

export function subtotal(billingFrequency: BillingFrequency, teacherCount: number, studentGroupCount: number): number {
    let teachersTotal = billingFrequency === BillingFrequency.Monthly ? BILLED_MONTHLY_AMOUNT : BILLED_ANNUALLY_AMOUNT;
    teachersTotal = teachersTotal * (teacherCount + 1);

    let studentGroupTotal =
        billingFrequency === BillingFrequency.Monthly
            ? STUDENT_GROUP_BILLED_MONTHLY_AMOUNT
            : STUDENT_GROUP_BILLED_ANNUALLY_AMOUNT;
    studentGroupTotal = studentGroupTotal * studentGroupCount;

    return teachersTotal + studentGroupTotal;
}

export function discount(billingFrequency: BillingFrequency, teacherCount: number, studentGroupCount: number): number {
    let teachersTotal = billingFrequency === BillingFrequency.Monthly ? BILLED_MONTHLY_AMOUNT : BILLED_ANNUALLY_AMOUNT;
    teachersTotal = teachersTotal * (teacherCount + 1);
    return getDiscountMultiplier(teacherCount) * teachersTotal;
}

export function getDiscountMultiplier(teacherCount: number): number {
    let discountMultiplier = 0;
    if (teacherCount >= 9) {
        discountMultiplier = TEN_OR_GREATER_DISCOUNT;
    } else if (teacherCount >= 2) {
        discountMultiplier = THREE_OR_GREATER_DISCOUNT;
    }
    return discountMultiplier;
}
