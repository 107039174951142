import { CollectionReference, DocumentReference, Query } from "firebase/firebase-firestore";
import * as firebase from "firebase";

import {
    BASIC_CREDITS_COLLECTION,
    CATEGORIES_COLLECTION,
    STUDENTS_COLLECTION,
    CATEGORY_IMAGES_COLLECTION,
    CATEGORY_AUDIO_COLLECTION,
    MANAGED_USERS_AUTH_COLLECTION,
    USERS_COLLECTION,
    ERRORS_COLLECTION,
    ACTIVITY_LINKS,
    ACTIVITY_LINK_VISITORS,
    PINNED_ACTIVITES,
    ANGRY_WORDS_LEVELS,
    USER_PERSISTENT_STATE,
    PENDING_MANAGED_USERS_COLLECTION,
} from "./constants";

export type FirestoreCollection = CollectionReference;
type FirestoreDocument = DocumentReference;
type FirestoreQuery = Query;

export default class Firestore {
    public static app: any;

    // public static init() {
    //     const firestore = firebase.firestore();
    //     const settings = {};
    //     firestore.settings(settings);
    // }

    public static timestamp() {
        return firebase.firestore.Timestamp.now();
    }

    // Collection Refs
    // --------------------------------------
    public static basicCreditsCollection(): FirestoreCollection {
        return Firestore.app.firestore().collection(BASIC_CREDITS_COLLECTION);
    }

    public static categoriesCollection(): FirestoreCollection {
        return Firestore.app.firestore().collection(CATEGORIES_COLLECTION);
    }

    public static studentsRef(): FirestoreCollection {
        return Firestore.app.firestore().collection(STUDENTS_COLLECTION);
    }

    public static categoryImagesRef(): FirestoreCollection {
        return Firestore.app.firestore().collection(CATEGORY_IMAGES_COLLECTION);
    }

    public static managedUsersAuthCollection(): FirestoreCollection {
        return Firestore.app.firestore().collection(MANAGED_USERS_AUTH_COLLECTION);
    }

    public static categoryAudioCollection(): FirestoreCollection {
        return Firestore.app.firestore().collection(CATEGORY_AUDIO_COLLECTION);
    }

    public static usersCollection(): FirestoreCollection {
        return Firestore.app.firestore().collection(USERS_COLLECTION);
    }

    public static activityLinksCollection(): FirestoreCollection {
        return Firestore.app.firestore().collection(ACTIVITY_LINKS);
    }

    public static activityLinkVisitorsCollection(): FirestoreCollection {
        return Firestore.app.firestore().collection(ACTIVITY_LINK_VISITORS);
    }

    public static pendingManagedUsersCollection(): FirestoreCollection {
        return Firestore.app.firestore().collection(PENDING_MANAGED_USERS_COLLECTION);
    }

    public static usersPinnedActivitesCollection(uid: string): FirestoreCollection {
        return Firestore.usersCollection().doc(uid).collection(PINNED_ACTIVITES);
    }

    public static usersPersistentStateCollection(uid: string): FirestoreCollection {
        return Firestore.usersCollection().doc(uid).collection(USER_PERSISTENT_STATE);
    }

    public static usersAngryWordsLevelsCollection(uid: string): FirestoreCollection {
        return Firestore.usersCollection().doc(uid).collection(ANGRY_WORDS_LEVELS);
    }

    public static errorsCollection(): FirestoreCollection {
        return Firestore.app.firestore().collection(ERRORS_COLLECTION);
    }

    // Document Refs
    // --------------------------------------
    public static userDocForID(uid: string): FirestoreDocument {
        return Firestore.usersCollection().doc(uid);
    }

    public static async userDocForEmail(emailLower: string): FirestoreDocument {
        let snapshot = await Firestore.usersCollection().where("emailLower", "==", emailLower).get();
        if (snapshot.exists && snapshot.docs.length > 0) {
            return snapshot.docs[0];
        }
        return undefined;
    }

    public static audioClipDBRefForFile(file: string): FirestoreDocument {
        if (file.indexOf(".") === file.length - 4) {
            file.slice(0, -4);
        }
        return Firestore.categoryAudioCollection().doc(file);
    }

    // Query Snapshots
    // --------------------------------------
    public static managedUsersQueryForID(uid: string): FirestoreQuery {
        return Firestore.usersCollection().where("managedBy", "==", uid);
    }
}
