// import { log } from "index";
import * as React from "react";

// Models
import { CategoryType } from "models";

// Stores
import { ActivityStore } from "stores";

// Utils
import { strings } from "core/localizedStrings";
import { InteractiveFlashcardsContext } from "./InteractiveFlashcardsState";
import { AutoPlayAudioCheckbox, MyCheckbox } from "components/common/CommonOptions";
import { Observer } from "mobx-react-lite";
import { OptionsGroup } from "../OptionsGroup";

export interface InteractiveFlashcardsOptions {
    ignoreFlippedCardsForReview: boolean;
    allowCardsToBeFlipped: boolean;
    useDoubleSidedCards: boolean;
    hideAnswers: boolean;
}

export const defaultInteractiveFlashcardsOptions: InteractiveFlashcardsOptions = {
    ignoreFlippedCardsForReview: true,
    allowCardsToBeFlipped: false,
    useDoubleSidedCards: false,
    hideAnswers: false,
};

export interface InteractiveFlashcardsOptionsProps extends React.Props<any> {
    activityStore: ActivityStore;
}

const InteractiveFlashcardsOptions = (props: InteractiveFlashcardsOptionsProps) => {
    let { state } = React.useContext(InteractiveFlashcardsContext);
    let { selectedCategoryType } = props.activityStore;

    const handleShouldAutoPlayAudio = (event: any, value: any) => {
        props.activityStore.activityOptions.quizOptions.autoPlayEnabled = value;
    };

    const handleShouldHideSlideShowAnswers = (event: any, value: any) => {
        props.activityStore.activityOptions.flashcardsOptions.hideAnswers = value;
    };

    const handleDoubleSidedCardsSelected = (event: any, value: any) => {
        props.activityStore.activityOptions.flashcardsOptions.useDoubleSidedCards = value;
    };

    const handleIgnoreFlippedCardsForReview = (event: any, value: any) => {
        props.activityStore.activityOptions.flashcardsOptions.ignoreFlippedCardsForReview = value;
    };

    const handleAllowFlashcardsToBeFlipped = (event: any, value: any) => {
        props.activityStore.activityOptions.flashcardsOptions.allowCardsToBeFlipped = value;
        if (!value) {
            props.activityStore.activityOptions.flashcardsOptions.useDoubleSidedCards = false;
        }
    };

    return (
        <Observer
            render={() => (
                <OptionsGroup heading={strings.interactiveFlashcards}>
                    <AutoPlayAudioCheckbox
                        isChecked={props.activityStore.activityOptions.quizOptions.autoPlayEnabled}
                        onChange={handleShouldAutoPlayAudio}
                    />
                    <MyCheckbox
                        isChecked={props.activityStore.activityOptions.flashcardsOptions.hideAnswers}
                        label={strings.hideAnswer}
                        onChange={handleShouldHideSlideShowAnswers}
                    />
                    {selectedCategoryType !== CategoryType.VocabularyTextOnly && (
                        <>
                            <MyCheckbox
                                isChecked={props.activityStore.activityOptions.flashcardsOptions.allowCardsToBeFlipped}
                                label={strings.allowCardsToBeFlipped}
                                onChange={handleAllowFlashcardsToBeFlipped}
                            />
                            <MyCheckbox
                                isDisabled={
                                    !props.activityStore.activityOptions.flashcardsOptions.allowCardsToBeFlipped
                                }
                                isChecked={props.activityStore.activityOptions.flashcardsOptions.useDoubleSidedCards}
                                label={strings.doubleSidedCards}
                                onChange={handleDoubleSidedCardsSelected}
                            />
                        </>
                    )}
                    <MyCheckbox
                        isDisabled={
                            !props.activityStore.activityOptions.flashcardsOptions.allowCardsToBeFlipped ||
                            props.activityStore.activityOptions.flashcardsOptions.useDoubleSidedCards
                        }
                        isChecked={props.activityStore.activityOptions.flashcardsOptions.ignoreFlippedCardsForReview}
                        label={strings.ignoreFlippedCardsForReview}
                        onChange={handleIgnoreFlippedCardsForReview}
                    />
                </OptionsGroup>
            )}
        />
    );
};

export default InteractiveFlashcardsOptions;
