import * as React from "react";
import { Observer } from "mobx-react-lite";

// Material UI
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

// Components
import { ActivityLink, ActivityLinkVisitor } from "models/activities/ActivityLink";
import { Firestore } from "core/firebase";

// Utils/Layout
import { Row } from "layout";
import { Colors } from "core/utils/Colors";
import DialogTitle from "@material-ui/core/DialogTitle";
import { strings } from "core/localizedStrings";

export interface ActivityLinkVisitorsProps extends React.Props<any> {
    activityLink: ActivityLink;
}

const ActivityLinkVisitors = (props: ActivityLinkVisitorsProps) => {
    let [activityLinkVisitors, setActivityLinkVisitors] = React.useState<ActivityLinkVisitor[]>(undefined);
    React.useEffect(() => {
        Firestore.activityLinkVisitorsCollection()
            .where("activityLinkKey", "==", props.activityLink.key)
            .orderBy("startTime", "desc")
            .onSnapshot((snapshot) => {
                if (snapshot !== undefined && snapshot.docs) {
                    let visitors: ActivityLinkVisitor[] = [];
                    snapshot.docs.forEach((visitor) => {
                        let visitorData = visitor.data();
                        visitorData.key = visitor.id;
                        visitors.push(visitorData);
                    });
                    setActivityLinkVisitors(visitors);
                }
            });
    }, []);

    return (
        <Observer
            render={() => (
                <Row style={{ width: "100%", padding: 10 }}>
                    <DialogTitle>
                        <Row>{strings.recentHistory}</Row>
                    </DialogTitle>
                    <DialogContent>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{strings.name}</TableCell>
                                    <TableCell>{strings.email}</TableCell>
                                    <TableCell>{strings.startTime}</TableCell>
                                    {/* <TableCell>{strings.completedTime}</TableCell>
                                    <TableCell>{strings.results}</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {activityLinkVisitors &&
                                    activityLinkVisitors.map((visitor, index) => (
                                        <ActivityLinkVisitorRow key={index} visitor={visitor} />
                                    ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <Row end="xs" style={{ width: "100%" }}>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    Firestore.activityLinkVisitorsCollection()
                                        .where("activityLinkKey", "==", props.activityLink.key)
                                        .orderBy("startTime", "desc")
                                        .onSnapshot((snapshot) => {
                                            if (snapshot !== undefined && snapshot.docs) {
                                                let visitors: ActivityLinkVisitor[] = [];
                                                snapshot.docs.forEach((visitor) => {
                                                    visitor.ref.delete();
                                                });
                                                setActivityLinkVisitors(visitors);
                                            }
                                        });
                                }}
                                style={{ color: Colors.red }}
                            >
                                {"Clear history"}
                            </Button>
                        </DialogActions>
                    </Row>
                </Row>
            )}
        />
    );
};

export default ActivityLinkVisitors;

interface ActivityLinkVisitorRowProps {
    visitor: ActivityLinkVisitor;
}

const ActivityLinkVisitorRow = (props: ActivityLinkVisitorRowProps) => {
    let { visitor } = props;
    let startDate = visitor.startTime.toDate();
    return (
        <TableRow>
            <TableCell>{visitor.name}</TableCell>
            <TableCell>{visitor.email}</TableCell>
            <TableCell>{`${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString()}`}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
        </TableRow>
    );
};
