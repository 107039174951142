import * as React from "react";
import { Observer } from "mobx-react-lite";

// Stores
import { ActivityStore } from "stores";

// Layout
import { Grid } from "@material-ui/core";
import { OptionsGroup } from "components/activities/OptionsGroup";
import SimpleCheckbox from "components/SimpleCheckbox";
import { strings } from "core/localizedStrings";

export interface DartboardSelectorOptions {
    removeNamesOnceSelected: boolean;
    clearDartAfterThrow: boolean;
    stopBoardAfterThrow: boolean;
}

export const defaultDartboardSelectorOptions: DartboardSelectorOptions = {
    removeNamesOnceSelected: false,
    clearDartAfterThrow: false,
    stopBoardAfterThrow: false,
};

interface DartboardSelectorOptionsProps extends React.Props<any> {
    activityStore: ActivityStore;
}

const DartboardSelectorOptions = (props: DartboardSelectorOptionsProps) => {
    const handleRemoveNamesChanged = (value: any) => {
        props.activityStore.activityOptions.dartboardSelectorOptions.removeNamesOnceSelected = value;
    };

    const handleClearDartsChanged = (value: any) => {
        props.activityStore.activityOptions.dartboardSelectorOptions.clearDartAfterThrow = value;
    };

    const handleStopBoardChanged = (value: any) => {
        props.activityStore.activityOptions.dartboardSelectorOptions.stopBoardAfterThrow = value;
    };

    return (
        <Observer
            render={() => (
                <>
                    <SimpleCheckbox
                        label={strings.removeNameOnceSelected}
                        isChecked={props.activityStore.activityOptions.dartboardSelectorOptions.removeNamesOnceSelected}
                        onChange={handleRemoveNamesChanged}
                    />
                    <SimpleCheckbox
                        label={strings.clearDartAfterThrow}
                        isChecked={props.activityStore.activityOptions.dartboardSelectorOptions.clearDartAfterThrow}
                        onChange={handleClearDartsChanged}
                    />
                    <SimpleCheckbox
                        label={strings.stopBoardAfterThrow}
                        isChecked={props.activityStore.activityOptions.dartboardSelectorOptions.stopBoardAfterThrow}
                        onChange={handleStopBoardChanged}
                    />
                </>
            )}
        />
    );
};

export default DartboardSelectorOptions;
