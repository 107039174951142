import * as React from "react";
import { Observer } from "mobx-react-lite";

// Material UI
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";

// Stores
import { ActivityStore } from "stores";

// Layout
import { strings } from "core/localizedStrings";
import { OptionsGroup } from "components/activities/OptionsGroup";
import SimpleCheckbox from "components/SimpleCheckbox";
import QuizOptions from "models/activities/QuizOptions";
import { CardType } from "models/customLists/CategoryItem";
import { CardTypeButtons, SecondCategoryButtons } from "components/activities/CommonActivityOptions";
import { Button } from "@material-ui/core";
import { Colors } from "core/utils/Colors";

export enum NumberOfCardsType {
    EIGHT = 8,
    SIXTEEN = 16,
    TWENTY_FOUR = 24,
}

export interface BattleshipOptions {
    cardType: CardType;
    isUsingClassicMode: boolean;
}

export const defaultBattleshipOptions: BattleshipOptions = {
    cardType: CardType.IMAGE_WORD,
    isUsingClassicMode: false,
};

interface BattleshipOptionsProps extends React.Props<any> {
    activityStore: ActivityStore;
}

const BattleshipOptions = (props: BattleshipOptionsProps) => {
    const handleCardTypeChanged = (value: any) => {
        props.activityStore.activityOptions.battleshipOptions.cardType = value;
    };

    const handleIsClassicModeChanged = (value: any) => {
        props.activityStore.activityOptions.battleshipOptions.isUsingClassicMode = value;
    };

    return (
        <Observer
            render={() => (
                <>
                    <OptionsGroup>
                        <SimpleCheckbox
                            label={strings.useClassicMode}
                            isChecked={props.activityStore.activityOptions.battleshipOptions.isUsingClassicMode}
                            onChange={handleIsClassicModeChanged}
                        />
                        <Typography variant="body1">{strings.classicModeDescription}</Typography>
                    </OptionsGroup>
                    {props.activityStore.activityOptions.battleshipOptions.isUsingClassicMode ? (
                        <>
                            <CardTypeButtons
                                activityStore={props.activityStore}
                                onCardTypeChanged={handleCardTypeChanged}
                                selectedType={props.activityStore.activityOptions.battleshipOptions.cardType}
                            />
                            <SecondCategoryButtons activityStore={props.activityStore} />
                        </>
                    ) : (
                        <QuizOptions activityStore={props.activityStore} />
                    )}
                </>
            )}
        />
    );
};

export default BattleshipOptions;
