import * as React from "react";

// Stores
import { ActivityStore } from "stores";

// Layout
import { Grid } from "@material-ui/core";
import { Observer } from "mobx-react-lite";
import {
    CardTypeButtons,
    FontSizeButtons,
    worksheetOptionsBackgroundStyle,
} from "components/activities/CommonActivityOptions";
import { CardType } from "models/customLists/CategoryItem";
import { NumberOfWorksheetColumns } from "../WorksheetHelper";
import { WorksheetOptions } from "../WorksheetScene";
import SimpleCheckbox from "components/SimpleCheckbox";
import { strings } from "core/localizedStrings";

export interface ConnectTheLettersOptions extends WorksheetOptions {
    cardType: CardType;
    numberOfColumns: NumberOfWorksheetColumns;
    fontSize: number;
}

export const defaultConnectTheLettersOptions: ConnectTheLettersOptions = {
    cardType: CardType.IMAGE_ONLY,
    numberOfColumns: NumberOfWorksheetColumns.ONE,
    fontSize: 55,
};

interface ConnectTheLettersOptionsProps extends React.Props<any> {
    activityStore: ActivityStore;
}

const ConnectTheLettersOptions = (props: ConnectTheLettersOptionsProps) => {
    const handleCardTypeChanged = (cardType: CardType) => {
        props.activityStore.activityOptions.connectTheLettersWorksheetOptions.cardType = cardType;
        props.activityStore.updateOptions();
    };

    const handleTwoColumnsCheckboxChanged = (value: boolean) => {
        props.activityStore.activityOptions.connectTheLettersWorksheetOptions.numberOfColumns = value
            ? NumberOfWorksheetColumns.TWO
            : NumberOfWorksheetColumns.ONE;
        props.activityStore.updateOptions();
    };

    const handleFontSizeDecremented = () => {
        const MIN_FONT_SIZE = 24;
        props.activityStore.activityOptions.connectTheLettersWorksheetOptions.fontSize *= 0.8;
        if (props.activityStore.activityOptions.connectTheLettersWorksheetOptions.fontSize < MIN_FONT_SIZE) {
            props.activityStore.activityOptions.connectTheLettersWorksheetOptions.fontSize = MIN_FONT_SIZE;
        }
    };

    const handleFontSizeIncremented = () => {
        const MAX_FONT_SIZE = 100;
        props.activityStore.activityOptions.connectTheLettersWorksheetOptions.fontSize *= 1.2;
        if (props.activityStore.activityOptions.connectTheLettersWorksheetOptions.fontSize > MAX_FONT_SIZE) {
            props.activityStore.activityOptions.connectTheLettersWorksheetOptions.fontSize = MAX_FONT_SIZE;
        }
    };

    return (
        <Observer
            render={() => (
                <>
                    <CardTypeButtons
                        activityStore={props.activityStore}
                        onCardTypeChanged={handleCardTypeChanged}
                        selectedType={props.activityStore.activityOptions.connectTheLettersWorksheetOptions.cardType}
                    />
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        style={worksheetOptionsBackgroundStyle}
                    >
                        <Grid container justify="center">
                            <Grid item>
                                <SimpleCheckbox
                                    label={strings.twoColumns}
                                    isChecked={
                                        props.activityStore.activityOptions.connectTheLettersWorksheetOptions
                                            .numberOfColumns === NumberOfWorksheetColumns.TWO
                                    }
                                    onChange={handleTwoColumnsCheckboxChanged}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <FontSizeButtons
                        onFontSizeDecremented={handleFontSizeDecremented}
                        onFontSizeIncremented={handleFontSizeIncremented}
                    />
                </>
            )}
        />
    );
};

export default ConnectTheLettersOptions;
