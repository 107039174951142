import * as React from "react";
import { Observer } from "mobx-react-lite";
import { ActivityStore } from "stores";
import { OptionsGroup } from "components/activities/OptionsGroup";
import { GameOptions } from "../GameScene";
import { Button, Grid, Typography } from "@material-ui/core";
import { PhaserImages } from "../PhaserImages";
import * as Icons from "core/utils/Icons";
import QuizOptions from "models/activities/QuizOptions";
import { strings } from "core/localizedStrings";
import { CardTypeButtons } from "components/activities/CommonActivityOptions";
import { CardType } from "models/customLists/CategoryItem";
import SimpleCheckbox from "components/SimpleCheckbox";

export const defaultBingoCallerOptions: BingoCallerOptions = {
    cardType: CardType.IMAGE_ONLY,
};

export interface BingoCallerOptions extends GameOptions {
    cardType: CardType;
}

interface BingoCallerOptionsProps {
    activityStore: ActivityStore;
}

const BingoCallerOptions = (props: BingoCallerOptionsProps) => {
    const handleCardTypeChanged = (value: any) => {
        props.activityStore.activityOptions.bingoCallerOptions.cardType = value;
    };

    const handleAutoPlayAudio = (value: any) => {
        props.activityStore.activityOptions.quizOptions.autoPlayEnabled = value;
    };

    return (
        <Observer
            render={() => (
                <>
                    <CardTypeButtons
                        activityStore={props.activityStore}
                        onCardTypeChanged={handleCardTypeChanged}
                        selectedType={props.activityStore.activityOptions.bingoCallerOptions.cardType}
                    />
                    <OptionsGroup heading={strings.audioHeading}>
                        <SimpleCheckbox
                            label={strings.autoPlayAudio}
                            isChecked={props.activityStore.activityOptions.quizOptions.autoPlayEnabled}
                            onChange={handleAutoPlayAudio}
                        ></SimpleCheckbox>
                    </OptionsGroup>
                </>
            )}
        />
    );
};

export default BingoCallerOptions;
