import { log } from "../../";

import * as React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { ManagedUser, ManagedUserType, User } from "../../models";
import RootStore from "../../stores/RootStore";
import { strings } from "../../core/localizedStrings";
import { Colors } from "../../core/utils/Colors";

import * as Validator from "../../core/utils/Validator";
import { Button } from "@material-ui/core";
import { StringUtils } from "core/utils/StringUtils";
import { PendingManagedUser } from "models/User";

export enum ProfileFormType {
    User,
    ManagedTeacher,
    ManagedGroupStudent,
}

export interface InviteManagedUserFormProps extends React.Props<any> {
    title: String;
    onComplete: () => void;
    rootStore: RootStore;
}

const styles = {
    errorDescription: {
        paddingTop: 3,
        color: Colors.red,
        fontSize: 11,
    },
};

@observer
export class InviteManagedUserForm extends React.Component<InviteManagedUserFormProps> {
    @observable email: string = "";

    render() {
        return (
            <div style={{ padding: 20 }}>
                <Typography variant="h6">{this.props.title}</Typography>
                <TextField
                    label={strings.email}
                    fullWidth={true}
                    id="email"
                    value={this.email}
                    onChange={this.onTextChanged}
                />
                <Button
                    variant="contained"
                    onClick={() => {
                        this.onSubmitClicked();
                    }}
                    style={{ margin: "30px 0 10px 0" }}
                >
                    {strings.ok}
                </Button>
            </div>
        );
    }

    @action
    private onTextChanged = (event: any) => {
        this.email = event.currentTarget.value;
    };

    // private onKeyDown = (event: any) => {
    //     if (event.keyCode === 13) {
    //         this.onSubmitClicked();
    //     }
    // };

    @action
    private onSubmitClicked = () => {
        let email = this.email.trim();
        if (StringUtils.isValidEmail(email)) {
            let user: PendingManagedUser = {
                email: email,
                emailLower: email.toLowerCase(),
                managedBy: this.props.rootStore.userStore.user.uid,
                managedUserType: ManagedUserType.Teacher,
            };

            this.props.rootStore.userStore.addPendingManagedUser(user);

            this.props.onComplete();
        }
    };
}
