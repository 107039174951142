import * as React from "react";
import { Button } from "@material-ui/core";
import { CategoryItem, VocabularyCategoryItem, ExpressionCategoryItem, LessonCategoryItem } from "../../models";

import Paper from "@material-ui/core/Paper";
import { TextField } from "@material-ui/core";
import Utils from "core/utils/Utils";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

export interface TranslationCardProps extends React.Props<any> {
    categoryItem: CategoryItem;
    englishCategoryItem: CategoryItem;
    stringsDictionary: Map<string, string>;
    onItemUpdatedCallback?: (updatedItem: CategoryItem, oldItem: CategoryItem) => void;
}

export default class TranslationCard extends React.Component<TranslationCardProps> {
    render() {
        return (
            <Paper style={{ minHeight: 100, padding: 5, margin: 10 }}>
                <Row middle="xs">
                    <Col center="xs" xs={12} sm={3}>
                        {this.renderImage()}
                    </Col>
                    <Col center="xs" xs={12} sm={9}>
                        {this.renderCard()}
                    </Col>
                </Row>
            </Paper>
        );
    }

    private renderImage() {
        if (this.props.categoryItem.image) {
            return (
                <Row center="xs" style={{ padding: 10 }}>
                    <img src={this.props.categoryItem.thumbnailDownloadURL} style={{ height: 80 }} />
                </Row>
            );
        }
    }

    private renderCard() {
        let content: any[] = [];
        let item = this.props.categoryItem;
        let englishItem = this.props.englishCategoryItem;
        if (item instanceof VocabularyCategoryItem || item instanceof LessonCategoryItem) {
            content.push(this.renderLine(item.text, englishItem.text, "text", "Vocabulary Word"));
        }
        if (item instanceof ExpressionCategoryItem || item instanceof LessonCategoryItem) {
            content.push(this.renderLine(item.question, englishItem.question, "question", "Question"));
            content.push(this.renderLine(item.answer, englishItem.answer, "answer", "Answer"));

            if (item.wrong1) {
                content.push(this.renderLine(item.wrong1, englishItem.wrong1, "wrong1", "Incorrect 1", true));
            }
            if (item.wrong2) {
                content.push(this.renderLine(item.wrong2, englishItem.wrong2, "wrong2", "Incorrect 2", true));
            }
            if (item.wrong3) {
                content.push(this.renderLine(item.wrong3, englishItem.wrong3, "wrong3", "Incorrect 3", true));
            }
            content.push(
                <Row end="xs" style={{ margin: 5 }}>
                    <Button variant="contained" onClick={this.onSaveClicked}>
                        Save
                    </Button>
                </Row>
            );
        }
        return content;
    }

    private renderLine = (text: string, englishText: string, id: string, label: string, isError: boolean = false) => {
        if (text === undefined) {
            return null;
        }

        let callbackOnBlur =
            this.props.categoryItem instanceof VocabularyCategoryItem ||
            this.props.categoryItem instanceof ExpressionCategoryItem ||
            this.props.categoryItem instanceof LessonCategoryItem;

        let newText = this.translationOrDefaultValue(text, englishText);
        return (
            <Row center="xs" style={{ padding: 5 }} key={Utils.guid()}>
                <TextField
                    onBlur={callbackOnBlur ? this.onTextFieldLostFocus : undefined}
                    style={{ maxWidth: 500 }}
                    error={isError}
                    fullWidth={true}
                    id={`${id}_${this.props.categoryItem.key}`}
                    defaultValue={newText}
                    label={label}
                />
            </Row>
        );
    };

    private translationOrDefaultValue = (text: string, englishText) => {
        if (text !== englishText) {
            return text;
        }
        // if (this.props.stringsDictionary[text] !== undefined) {
        //     return this.props.stringsDictionary[text];
        // }
        return text;
    };

    private onSaveClicked = () => {
        let newObj =
            this.props.categoryItem instanceof VocabularyCategoryItem
                ? new VocabularyCategoryItem()
                : new LessonCategoryItem();
        let categoryItem = Object.assign(newObj, this.props.categoryItem);

        let node: any = document.getElementById(`text_${this.props.categoryItem.key}`);
        categoryItem.text = node.value.trim();

        node = document.getElementById(`question_${this.props.categoryItem.key}`);
        categoryItem.question = node.value.trim();

        node = document.getElementById(`answer_${this.props.categoryItem.key}`);
        categoryItem.answer = node.value.trim();

        node = document.getElementById(`wrong1_${this.props.categoryItem.key}`);
        if (node) {
            categoryItem.wrong1 = node.value.trim();
        }

        node = document.getElementById(`wrong2_${this.props.categoryItem.key}`);
        if (node) {
            categoryItem.wrong2 = node.value.trim();
        }

        node = document.getElementById(`wrong3_${this.props.categoryItem.key}`);
        if (node) {
            categoryItem.wrong3 = node.value.trim();
        }

        if (this.props.onItemUpdatedCallback !== undefined) {
            this.props.onItemUpdatedCallback(categoryItem, this.props.categoryItem);
        }
    };

    private onTextFieldLostFocus = (event: any) => {
        console.log(event.target.id.split("_")[0]);
        if (this.props.categoryItem instanceof VocabularyCategoryItem) {
            let newObj = new VocabularyCategoryItem();

            let categoryItem = Object.assign(newObj, this.props.categoryItem);
            switch (event.target.id.split("_")[0]) {
                case "text":
                    categoryItem.text = event.target.value.trim();
                    break;
                case "question":
                    categoryItem.question = event.target.value.trim();
                    break;
                case "answer":
                    categoryItem.answer = event.target.value.trim();
                    break;
                case "wrong1":
                    categoryItem.wrong1 = event.target.value.trim();
                    break;
                case "wrong2":
                    categoryItem.wrong2 = event.target.value.trim();
                    break;
                case "wrong3":
                    categoryItem.wrong3 = event.target.value.trim();
                    break;
                default:
                    break;
            }

            if (this.props.onItemUpdatedCallback !== undefined) {
                this.props.onItemUpdatedCallback(categoryItem, this.props.categoryItem);
            }
        }
    };
}
