import * as React from "react";

// Utils
import { Colors } from "../../core/utils/Colors";
import Typography from "@material-ui/core/Typography";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

export interface FooterProps extends React.Props<any> {}

let styles = {
    container: { maxWidth: 1000, padding: 20 },
    text: { color: Colors.white, fontSize: 14 }
};

export default class Footer extends React.Component<FooterProps> {
    render() {
        let copyrightText = `Copyright © ${new Date().getFullYear()} - KinteractiveLearning - All Rights Reserved`;
        return (
            <Row center="xs" style={{ backgroundColor: Colors.darkGrey }}>
                <Col xs={12} style={styles.container}>
                    <Row center="xs">
                        <Typography style={styles.text}>{copyrightText}</Typography>
                    </Row>
                </Col>
            </Row>
        );
    }
}
