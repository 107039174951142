import { strings } from "core/localizedStrings";
import { Colors } from "core/utils/Colors";
import { Fonts } from "core/utils/Fonts";
import Utils from "core/utils/Utils";
import { CategoryType } from "models";
import Activity from "models/activities/Activity";
import { ActivityCategoryItem } from "models/customLists/CategoryItem";
import { shuffle } from "shuffle-seed";
import { ActivityStore } from "stores";
import { AnswerPhaserCard } from "../games/AnswerPhaserCard";
import { PhaserHelper } from "../games/PhaserHelper";
import PhaserCardHelper, { PhaserCardContent } from "../phaserComponents/PhaserCardHelper";

export const A4_RATIO = 8.25 / 11.75;
export const LETTER_RATIO = 8 / 11;

export enum NumberOfWorksheetColumns {
    ONE = 1,
    TWO = 2,
}

export class PrintableContainer extends Phaser.GameObjects.Container {
    constructor(
        scene: Phaser.Scene,
        backgroundContainer: Phaser.GameObjects.GameObject,
        x?: number,
        y?: number,
        onClick?: () => void
    ) {
        super(scene, x, y);
        backgroundContainer.name = "background";
        this.add(backgroundContainer);

        if (onClick) {
            backgroundContainer.setInteractive({ cursor: "pointer" }).on("pointerup", () => {
                onClick();
            });
        }
    }
}

interface QuestionComponents {
    questionLine: WorksheetTextLine;
    answerLine: WorksheetTextLine;
    imageContainer?: Phaser.GameObjects.Container;
}

export class WorksheetTextLine extends Phaser.GameObjects.Container {
    private lineSize: number;
    public text: Phaser.GameObjects.Text;
    public button: Phaser.GameObjects.Container;

    constructor(scene: Phaser.Scene, text: string, lineSize: number, fontSize: number) {
        super(scene);
        this.scene = scene;
        this.lineSize = lineSize;

        if (!text?.includes("__")) {
            let line = scene.add.line(0, 0, 0, 0, lineSize, 0, 0x000000, 1).setLineWidth(1).setOrigin(0, 0);
            this.add(line);
        }

        this.text = scene.add.text(0, 0, text, {
            fontFamily: Fonts.BalsamiqSans,
            fontSize: fontSize,
            color: Colors.black,
        });
        this.text.setOrigin(0, 1);
        this.add(this.text);

        this.createButton("Hide");
    }

    public createButton = (text: string) => {
        if (this.button) {
            this.button.destroy(true);
            this.button = undefined;
        }
        this.button = PhaserHelper.createButton(
            this.scene,
            this.lineSize / 2,
            -20,
            this.lineSize,
            40,
            text,
            Colors.whiteHex,
            Colors.facebookBlue,
            () => {
                this.toggleText();
            },
            () => {
                if (this.text.text.length > 0) {
                    this.button.setAlpha(1);
                }
            },
            () => {
                this.button.setAlpha(0.01);
            }
        );
        this.button.alpha = 0.01;
        this.add(this.button);
    };

    public hideText() {
        this.createButton("Show");
        this.text.visible = false;
    }

    public showText() {
        this.createButton("Hide");
        this.text.visible = true;
    }

    private toggleText() {
        if (this.text.visible) {
            this.hideText();
        } else {
            this.showText();
        }
    }
}

export default class WorksheetHelper {
    public static createWorksheetContainer = (
        scene: Phaser.Scene,
        paperWidth: number,
        paperHeight: number,
        onClick?: () => void
    ) => {
        let background = scene.add.rectangle(0, 0, paperWidth, paperHeight, 0xffffff, 1);
        background.setStrokeStyle(1, 0x222222, 0.6);
        let backgroundShadow = scene.add.rectangle(5, 5, paperWidth, paperHeight, 0x000000, 0.3);

        let container = new PrintableContainer(scene, background, 0, 0, onClick);
        container.add(backgroundShadow);
        container.sendToBack(backgroundShadow);
        return container;
    };

    public static createWorksheetHeader(scene: Phaser.Scene, worksheetWidth: number, title: string) {
        let titleSize = 60;

        // Create name blank
        let nameText = scene.add.text(worksheetWidth / -2 + worksheetWidth / 11, 20, strings.name + ":", {
            fontFamily: Fonts.Roboto,
            color: Colors.black,
            fontSize: "22px",
        });
        let nameTextBounds = nameText.getBounds();
        let nameLineXPos = nameText.x + 225;
        let nameLineYPos = nameText.y + nameTextBounds.height;
        let nameLine = scene.add.line(0, 0, nameLineXPos, nameLineYPos, nameLineXPos + 300, nameLineYPos, 0x000000, 1);

        // Create title
        let titleYPos = 100;
        let shadowDistance = 3;
        let titleShadow = scene.add
            .text(shadowDistance, titleYPos + shadowDistance, title, {
                fontFamily: Fonts.CenturyGothic,
                fontSize: titleSize + "px",
                color: Colors.darkGrey,
            })
            .setOrigin(0.5, 0.5);

        let titleText = scene.add
            .text(0, titleYPos, title, {
                fontFamily: Fonts.CenturyGothic,
                fontSize: titleSize + "px",
                color: Colors.red,
            })
            .setOrigin(0.5, 0.5);

        let container = new Phaser.GameObjects.Container(scene);
        container.add(nameText);
        container.add(nameLine);
        container.add(titleShadow);
        container.add(titleText);
        return container;
    }

    public static createWorksheetFooter(scene: Phaser.Scene) {
        // Site name text
        let siteText = scene.add.text(0, 0, "www.kinteractivelearning.com", {
            fontFamily: Fonts.Roboto,
            color: Colors.black,
            fontSize: "96px",
        });
        siteText.setScale(0.25);
        siteText.setOrigin(0.5, 0.5);

        let container = new Phaser.GameObjects.Container(scene);
        container.add(siteText);
        return container;
    }

    public static createNumberText(scene: Phaser.Scene, number: number) {
        return scene.add
            .text(0, 0, number.toString() + ".", {
                fontFamily: Fonts.BalsamiqSans,
                fontSize: 28,
                color: Colors.black,
            })
            .setOrigin(0.5, 0.5);
    }

    public static randomActivityItemsForSize(items: ActivityCategoryItem[], size: number) {
        let randomItems: ActivityCategoryItem[] = [];
        if (items.length >= size) {
            randomItems = shuffle(items.slice(), Utils.guid());
        } else {
            while (randomItems.length < size) {
                randomItems = randomItems.concat(shuffle(items.slice(), Utils.guid()));
            }
        }

        if (randomItems.length > size) {
            randomItems = randomItems.slice(0, size);
        }
        return randomItems;
    }

    public static createImageCard = (
        scene: Phaser.Scene,
        item: ActivityCategoryItem,
        lessonSelection: CategoryType,
        cardWidth: number,
        cardHeight: number
    ) => {
        var background = scene.add.rectangle(0, 0, cardWidth, cardHeight, 0xffffff);
        // background.setStrokeStyle(3, 0x222222, 1);

        let type = item.typeForLessonSelection(lessonSelection);
        if (type === CategoryType.Expression && item.imageURL === undefined) {
            type = CategoryType.ExpressionTextOnly;
        }

        switch (type) {
            case CategoryType.Vocabulary:
                let answerXPos = 0.7;
                let imageXPos = -0.1;
                let imageScale = 0.6;
                let answerText = PhaserCardHelper.createText(
                    scene,
                    PhaserCardContent.ANSWER,
                    item.answer.text,
                    cardWidth,
                    cardHeight,
                    answerXPos
                );
                let vocabularyImage = PhaserCardHelper.createVocabularyImage(
                    scene,
                    PhaserCardContent.IMAGE,
                    item.key,
                    cardWidth,
                    cardHeight,
                    imageScale,
                    imageXPos
                );
                return [background, answerText, vocabularyImage];
            case CategoryType.VocabularyTextOnly:
                answerText = PhaserCardHelper.createText(
                    scene,
                    PhaserCardContent.ANSWER,
                    item.answer.text,
                    cardWidth,
                    cardHeight,
                    0
                );
                return [background, answerText];
            case CategoryType.VocabularyImageOnly:
                vocabularyImage = PhaserCardHelper.createVocabularyImage(
                    scene,
                    PhaserCardContent.IMAGE,
                    item.key,
                    cardWidth,
                    cardHeight,
                    imageScale,
                    imageXPos
                );
                return [background, vocabularyImage];
            case CategoryType.VocabularyTextOnly:
            case CategoryType.Expression:
                imageScale = 0.6;
                let questionText = PhaserCardHelper.createText(
                    scene,
                    PhaserCardContent.QUESTION,
                    item.question.text,
                    cardWidth,
                    cardHeight,
                    -0.7
                );
                answerText = PhaserCardHelper.createText(
                    scene,
                    PhaserCardContent.ANSWER,
                    item.answer.text,
                    cardWidth,
                    cardHeight,
                    0.7
                );
                vocabularyImage = PhaserCardHelper.createVocabularyImage(
                    scene,
                    PhaserCardContent.IMAGE,
                    item.key,
                    cardWidth,
                    cardHeight,
                    imageScale,
                    0
                );
                return [background, vocabularyImage, questionText, answerText];
            case CategoryType.ExpressionTextOnly:
                questionText = PhaserCardHelper.createText(
                    scene,
                    PhaserCardContent.QUESTION,
                    item.question.text,
                    cardWidth,
                    cardHeight,
                    -0.4
                );
                answerText = PhaserCardHelper.createText(
                    scene,
                    PhaserCardContent.ANSWER,
                    item.answer.text,
                    cardWidth,
                    cardHeight,
                    0.4
                );
                return [background, answerText, questionText];
        }
        return [];
    };

    public static createQuestion(
        scene: Phaser.Scene,
        questionText: string,
        answerText: string,
        fontSize: number,
        lineLength: number,
        cardHeight: number,
        container: Phaser.GameObjects.Container
    ) {
        const questionLine = new WorksheetTextLine(scene, questionText, lineLength, fontSize);
        questionLine.y = cardHeight * -0.25;
        container.add(questionLine);

        const answerLine = new WorksheetTextLine(scene, answerText, lineLength, fontSize);
        answerLine.y = cardHeight * 0.25;
        container.add(answerLine);

        return { questionLine: questionLine, answerLine: answerLine } as QuestionComponents;
    }

    public static createQuestionWithImage(
        scene: Phaser.Scene,
        questionText: string,
        answerText: string,
        item: ActivityCategoryItem,
        fontSize: number,
        lineLength: number,
        activityStore: ActivityStore,
        cardHeight: number,
        cardWidth: number,
        container: Phaser.GameObjects.Container
    ) {
        let questionComponents = this.createQuestion(
            scene,
            questionText,
            answerText,
            fontSize,
            lineLength,
            cardHeight,
            container
        );

        let image = new AnswerPhaserCard(scene, cardWidth, cardHeight, item, activityStore, false, false, undefined);
        container.add(image.visibleContainer);

        questionComponents.imageContainer = image.visibleContainer;
        return questionComponents;
    }

    public static isFirstColumn(index: number, maxRows: number) {
        let mod = (index + 1) % (maxRows * 2);
        if (mod === 0 || mod > maxRows) {
            return false;
        }
        return true;
    }
}
