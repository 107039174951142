import * as React from "react";
import { ActivityStore } from "stores";
import QuizOptions from "models/activities/QuizOptions";
import { Observer } from "mobx-react-lite";
import { Button, Grid, Typography } from "@material-ui/core";
import { OptionsGroup } from "components/activities/OptionsGroup";
import { strings } from "core/localizedStrings";
import * as Icons from "core/utils/Icons";

export enum BallLaunchType {
    SLINGSHOT = "slingshot",
    SLIDERS = "sliders",
}

export const defaultAngryWordsOptions: AngryWordsOptions = {
    randomizeLevels: false,
    ballLaunchType: BallLaunchType.SLINGSHOT,
    pointCardSensitivityMultiplier: 1,
};

export interface AngryWordsOptions {
    randomizeLevels: boolean;
    ballLaunchType: BallLaunchType;
    pointCardSensitivityMultiplier: number;
}

interface AngryWordsOptionsProps {
    activityStore: ActivityStore;
}

const AngryWordsOptions = (props: AngryWordsOptionsProps) => {
    const [value, setValue] = React.useState(0);

    const incrementSensitivity = () => {
        let sensitivity = props.activityStore.activityOptions.angryWordsOptions.pointCardSensitivityMultiplier;
        sensitivity += 0.1;
        if (sensitivity <= 3.1) {
            props.activityStore.activityOptions.angryWordsOptions.pointCardSensitivityMultiplier = sensitivity;
        }
    };

    const decrementSensitivity = () => {
        let sensitivity = props.activityStore.activityOptions.angryWordsOptions.pointCardSensitivityMultiplier;
        sensitivity -= 0.1;
        if (sensitivity >= 1) {
            props.activityStore.activityOptions.angryWordsOptions.pointCardSensitivityMultiplier = sensitivity;
        }
    };

    return (
        <Observer
            render={() => (
                <>
                    <QuizOptions activityStore={props.activityStore} />
                    <OptionsGroup heading={strings.cardSensitivity}>
                        <Grid container justify="center" alignItems="center">
                            <Grid item>
                                <Button onClick={decrementSensitivity}>
                                    <Icons.Minus />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Typography>{`${(
                                    props.activityStore.activityOptions.angryWordsOptions
                                        .pointCardSensitivityMultiplier * 100
                                ).toFixed(0)}%`}</Typography>
                            </Grid>
                            <Grid item>
                                <Button onClick={incrementSensitivity}>
                                    <Icons.Add />
                                </Button>
                            </Grid>
                        </Grid>
                    </OptionsGroup>
                </>
            )}
        />
    );
};

export default AngryWordsOptions;
