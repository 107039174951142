import { Button, Grid, Typography } from "@material-ui/core";
import { strings } from "core/localizedStrings";
import { Colors } from "core/utils/Colors";
import { Fonts } from "core/utils/Fonts";
import { CategoryType, GameType, WorksheetType } from "models";
import { CardType } from "models/customLists/CategoryItem";
import * as React from "react";
import { ActivityStore } from "stores";
import { PhaserFont } from "./PhaserFontLoader";
import * as Icons from "core/utils/Icons";

export const worksheetOptionsBackgroundStyle = {
    background: Colors.lightGrey,
    boxShadow: "2px 2px 5px",
    borderRadius: 10,
    padding: 20,
    margin: 5,
};

interface CardTypeButtonProps {
    activityStore: ActivityStore;
    onCardTypeChanged: (cardType: CardType) => void;
    selectedType?: CardType;
}

export const CardTypeButtons = (props: CardTypeButtonProps) => {
    if (props.activityStore.currentCategory.type === CategoryType.VocabularyTextOnly) {
        return null;
    }

    let lessonOrVocabulary =
        props.activityStore.currentCategory.type === CategoryType.Lesson &&
        props.activityStore.lessonSelection === CategoryType.Vocabulary;

    let isImageCategory = props.activityStore.currentCategory.isImageCategory;

    if (
        (props.activityStore.currentActivity.activityType === WorksheetType.QAndA && isImageCategory) ||
        (props.activityStore.currentActivity.activityType === WorksheetType.SentenceScramble && isImageCategory) ||
        (props.activityStore.currentActivity.activityType === WorksheetType.SentenceDecoder && isImageCategory) ||
        (props.activityStore.currentActivity.activityType === WorksheetType.WordScramble && isImageCategory) ||
        (props.activityStore.currentActivity.activityType === WorksheetType.ConnectTheLetters && isImageCategory) ||
        (props.activityStore.currentActivity.activityType === WorksheetType.Matching && isImageCategory) ||
        (props.activityStore.currentActivity.activityType === WorksheetType.Racetrack && isImageCategory) ||
        (props.activityStore.currentActivity.activityType === WorksheetType.LaddersAndSlides && isImageCategory) ||
        (props.activityStore.currentActivity.activityType === GameType.Bingo && isImageCategory) ||
        props.activityStore.currentCategory.type === CategoryType.Vocabulary ||
        lessonOrVocabulary
    ) {
        return (
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={worksheetOptionsBackgroundStyle}
            >
                <Typography variant={"h6"}>{strings.cardType}</Typography>
                <Grid container justify="center">
                    <Grid item>
                        <Button
                            variant={
                                props.selectedType && props.selectedType == CardType.IMAGE_WORD ? "outlined" : null
                            }
                            onClick={() => {
                                props.onCardTypeChanged(CardType.IMAGE_WORD);
                            }}
                        >
                            <img src={require("assets/images/activities/imageWordCard.svg")} height={60} />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant={
                                props.selectedType && props.selectedType == CardType.IMAGE_ONLY ? "outlined" : null
                            }
                            onClick={() => {
                                props.onCardTypeChanged(CardType.IMAGE_ONLY);
                            }}
                        >
                            <img src={require("assets/images/activities/imageOnlyCard.svg")} height={60} />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant={props.selectedType && props.selectedType == CardType.WORD_ONLY ? "outlined" : null}
                            onClick={() => {
                                props.onCardTypeChanged(CardType.WORD_ONLY);
                            }}
                        >
                            <img src={require("assets/images/activities/textOnlyCard.svg")} height={60} />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
    return null;
};

interface FontSizeButtonsProps {
    onFontSizeIncremented: () => void;
    onFontSizeDecremented: () => void;
}

export const FontSizeButtons = (props: FontSizeButtonsProps) => {
    return (
        <Grid container direction="column" justify="center" alignItems="center" style={worksheetOptionsBackgroundStyle}>
            <Typography variant={"h6"}>{strings.fontSize}</Typography>
            <Grid container justify="center" spacing={2}>
                <Grid item>
                    <Button onClick={props.onFontSizeDecremented} variant="contained">
                        <Typography variant="h4" style={{ fontFamily: Fonts.BalsamiqSans }}>
                            T-
                        </Typography>
                    </Button>
                </Grid>
                <Grid item>
                    <Button onClick={props.onFontSizeIncremented} variant="contained">
                        <Typography variant="h4" style={{ fontFamily: Fonts.BalsamiqSans }}>
                            T+
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

interface BorderSizeOptionProps {
    onSizeIncremented: () => void;
    onSizeDecremented: () => void;
}

export const BorderSizeOption = (props: BorderSizeOptionProps) => {
    return (
        <Grid container direction="column" justify="center" alignItems="center" style={worksheetOptionsBackgroundStyle}>
            <Typography variant={"h6"}>{strings.borderThickness}</Typography>
            <Grid container justify="center" spacing={2}>
                <Grid item>
                    <Button onClick={props.onSizeDecremented} variant="contained">
                        <Typography variant="h4" style={{ fontFamily: Fonts.BalsamiqSans }}>
                            -
                        </Typography>
                    </Button>
                </Grid>
                <Grid item>
                    <Button onClick={props.onSizeIncremented} variant="contained">
                        <Typography variant="h4" style={{ fontFamily: Fonts.BalsamiqSans }}>
                            +
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

interface ColorPickerOptionProps {
    title: string;
    colors: string[];
    selectedBorderColor: string;
    onColorSelected: (color: string) => void;
}

export const ColorPickerOption = (props: ColorPickerOptionProps) => {
    return (
        <Grid container direction="column" justify="center" alignItems="center" style={worksheetOptionsBackgroundStyle}>
            <Typography variant={"h6"}>{props.title}</Typography>
            <Grid container justify="center" spacing={2}>
                {props.colors.map((color) => {
                    return (
                        <Grid item>
                            <Button
                                onClick={() => props.onColorSelected(color)}
                                variant={props.selectedBorderColor === color ? "contained" : null}
                            >
                                <Icons.Circle style={{ color: color }} />
                            </Button>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};

interface PuzzleSizeButtonsProps {
    onSizeIncremented: () => void;
    onSizeDecremented: () => void;
    currentValue?: number;
}

export const PuzzleSizeButtons = (props: PuzzleSizeButtonsProps) => {
    let currentValue = props.currentValue ? ` (${props.currentValue}x${props.currentValue})` : "";
    return (
        <Grid container direction="column" justify="center" alignItems="center" style={worksheetOptionsBackgroundStyle}>
            <Typography variant={"h6"}>{strings.gridSize + currentValue}</Typography>
            <Grid container justify="center" spacing={2}>
                <Grid item>
                    <Button onClick={props.onSizeDecremented} variant="contained">
                        <Typography variant="h4" style={{ fontFamily: Fonts.BalsamiqSans }}>
                            -
                        </Typography>
                    </Button>
                </Grid>
                <Grid item>
                    <Button onClick={props.onSizeIncremented} variant="contained">
                        <Typography variant="h4" style={{ fontFamily: Fonts.BalsamiqSans }}>
                            +
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export enum CasingType {
    UPPER_CASE,
    LOWER_CASE,
}

interface CasingTypeButtonsProps {
    onCasingTypeChanged: (casing: CasingType) => void;
}

export const CasingTypeButtons = (props: CasingTypeButtonsProps) => {
    return (
        <Grid container direction="column" justify="center" alignItems="center" style={worksheetOptionsBackgroundStyle}>
            <Typography variant={"h6"}>{"Letter Casing"}</Typography>
            <Grid container justify="center" spacing={2}>
                <Grid item>
                    <Button
                        style={{ textTransform: "none" }}
                        onClick={() => {
                            props.onCasingTypeChanged(CasingType.LOWER_CASE);
                        }}
                        variant="contained"
                    >
                        <Typography variant="h4" style={{ fontFamily: Fonts.BalsamiqSans }}>
                            a
                        </Typography>
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => {
                            props.onCasingTypeChanged(CasingType.UPPER_CASE);
                        }}
                        variant="contained"
                    >
                        <Typography variant="h4" style={{ fontFamily: Fonts.BalsamiqSans }}>
                            A
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

interface FontStyleButtonsProps {
    onFontStyleChanged: (fontStyle: string) => void;
}

export const FontStyleButtons = (props: FontStyleButtonsProps) => {
    return (
        <Grid container direction="column" justify="center" alignItems="center" style={worksheetOptionsBackgroundStyle}>
            <Typography variant={"h6"}>{strings.fontStyle}</Typography>
            <Grid container justify="center" spacing={2}>
                <Grid item>
                    <Button
                        onClick={() => {
                            props.onFontStyleChanged(Fonts.BalsamiqSans);
                        }}
                        variant="contained"
                    >
                        <img src={require("assets/images/activities/defaultFont.png")} height={70} />
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => {
                            props.onFontStyleChanged(PhaserFont.TracingLined);
                        }}
                        variant="contained"
                    >
                        <img src={require("assets/images/activities/tracingFont.png")} height={70} />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export interface SecondCategoryButtonsProps {
    activityStore: ActivityStore;
}

export const SecondCategoryButtons = (props: SecondCategoryButtonsProps) => {
    return (
        <>
            <Button
                style={{ marginTop: 20 }}
                variant="contained"
                onClick={() => {
                    props.activityStore.setIsSettingSecondCategory(true);
                    props.activityStore.setShouldShowBrowseListsView(true);
                    props.activityStore.shouldShowActivityOptions = false;
                    props.activityStore.updateOptions();
                }}
            >
                {strings.selectSecondCategory}
            </Button>
            {props.activityStore.secondCategory && (
                <Button
                    style={{ marginTop: 20, marginLeft: 10 }}
                    variant="contained"
                    onClick={() => {
                        props.activityStore.setIsSettingSecondCategory(true);
                        props.activityStore.setCurrentCategory(undefined);
                    }}
                >
                    <Typography style={{ color: Colors.red }} variant="button">
                        {strings.clearSecondCategory}
                    </Typography>
                </Button>
            )}
        </>
    );
};
