import * as React from "react";

// Stores
import { ActivityStore } from "stores";

// Layout
import { Button, Grid, Typography } from "@material-ui/core";
import { Observer } from "mobx-react-lite";
import {
    CardTypeButtons,
    FontSizeButtons,
    worksheetOptionsBackgroundStyle,
} from "components/activities/CommonActivityOptions";
import { CardType } from "models/customLists/CategoryItem";
import { NumberOfWorksheetColumns } from "../WorksheetHelper";
import { WorksheetOptions } from "../WorksheetScene";
import SimpleCheckbox from "components/SimpleCheckbox";
import { strings } from "core/localizedStrings";
import { Fonts } from "core/utils/Fonts";
import { Colors } from "core/utils/Colors";

export interface SentenceDecoderWorksheetOptions extends WorksheetOptions {
    cardType: CardType;
    numberOfColumns: NumberOfWorksheetColumns;
    fontSize: number;
    codeStyle: string;
}

export const defaultSentenceDecoderWorksheetOptions: SentenceDecoderWorksheetOptions = {
    cardType: CardType.IMAGE_ONLY,
    numberOfColumns: NumberOfWorksheetColumns.ONE,
    fontSize: 30,
    codeStyle: Fonts.GoogleEmojis,
};

interface SentenceDecoderWorksheetOptionsProps extends React.Props<any> {
    activityStore: ActivityStore;
}

const SentenceDecoderWorksheetOptions = (props: SentenceDecoderWorksheetOptionsProps) => {
    const handleCardTypeChanged = (cardType: CardType) => {
        props.activityStore.activityOptions.sentenceDecoderWorksheetOptions.cardType = cardType;
        props.activityStore.updateOptions();
    };

    const handleTwoColumnsCheckboxChanged = (value: boolean) => {
        props.activityStore.activityOptions.sentenceDecoderWorksheetOptions.numberOfColumns = value
            ? NumberOfWorksheetColumns.TWO
            : NumberOfWorksheetColumns.ONE;
        props.activityStore.updateOptions();
    };

    const handleFontSizeDecremented = () => {
        const MIN_FONT_SIZE = 24;
        props.activityStore.activityOptions.sentenceDecoderWorksheetOptions.fontSize *= 0.9;
        if (props.activityStore.activityOptions.sentenceDecoderWorksheetOptions.fontSize < MIN_FONT_SIZE) {
            props.activityStore.activityOptions.sentenceDecoderWorksheetOptions.fontSize = MIN_FONT_SIZE;
        }
        props.activityStore.updateOptions();
    };

    const handleFontSizeIncremented = () => {
        const MAX_FONT_SIZE = 60;
        props.activityStore.activityOptions.sentenceDecoderWorksheetOptions.fontSize *= 1.1;
        if (props.activityStore.activityOptions.sentenceDecoderWorksheetOptions.fontSize > MAX_FONT_SIZE) {
            props.activityStore.activityOptions.sentenceDecoderWorksheetOptions.fontSize = MAX_FONT_SIZE;
        }
        props.activityStore.updateOptions();
    };

    const handleCodeStyleChanged = (type: string) => {
        props.activityStore.activityOptions.sentenceDecoderWorksheetOptions.codeStyle = type;
        props.activityStore.updateOptions();
    };

    return (
        <Observer
            render={() => (
                <>
                    <CardTypeButtons
                        activityStore={props.activityStore}
                        onCardTypeChanged={handleCardTypeChanged}
                        selectedType={props.activityStore.activityOptions.sentenceDecoderWorksheetOptions.cardType}
                    />
                    <CodeStyleButtons
                        onChange={handleCodeStyleChanged}
                        selectedType={props.activityStore.activityOptions.sentenceDecoderWorksheetOptions.codeStyle}
                    />
                    <FontSizeButtons
                        onFontSizeDecremented={handleFontSizeDecremented}
                        onFontSizeIncremented={handleFontSizeIncremented}
                    />
                </>
            )}
        />
    );
};

export default SentenceDecoderWorksheetOptions;

interface CodeStyleButtonProps {
    selectedType: string;
    onChange: (type: string) => void;
}

export const CodeStyleButtons = (props: CodeStyleButtonProps) => {
    return (
        <Grid container direction="column" justify="center" alignItems="center" style={worksheetOptionsBackgroundStyle}>
            <Typography variant={"h6"}>{"Code Style"}</Typography>
            <Grid container justify="center">
                <Grid item>
                    <Button
                        variant={props.selectedType && props.selectedType == Fonts.KawaiiFood ? "outlined" : null}
                        color="primary"
                        onClick={() => {
                            props.onChange(Fonts.KawaiiFood);
                        }}
                    >
                        <img src={require("assets/images/fontIcons/kawaiiFood.png")} height={50} />
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant={props.selectedType && props.selectedType == Fonts.CuteIcons ? "outlined" : null}
                        onClick={() => {
                            props.onChange(Fonts.CuteIcons);
                        }}
                    >
                        <img src={require("assets/images/fontIcons/cuteIcons.png")} height={50} />
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant={props.selectedType && props.selectedType == Fonts.GoogleEmojis ? "outlined" : null}
                        onClick={() => {
                            props.onChange(Fonts.GoogleEmojis);
                        }}
                    >
                        <img src={require("assets/images/fontIcons/googleEmojis.png")} height={50} />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
