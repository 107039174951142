import * as React from "react";

// Stores
import { ActivityStore } from "stores";

// Layout
import { Divider, Grid } from "@material-ui/core";
import { Observer } from "mobx-react-lite";
import {
    CardTypeButtons,
    FontSizeButtons,
    worksheetOptionsBackgroundStyle,
} from "components/activities/CommonActivityOptions";
import { CardType } from "models/customLists/CategoryItem";
import SimpleCheckbox from "components/SimpleCheckbox";
import { strings } from "core/localizedStrings";
import { NumberOfWorksheetColumns } from "../WorksheetHelper";
import { WorksheetOptions } from "../WorksheetScene";

export interface CrosswordWorksheetOptions extends WorksheetOptions {
    cardType: CardType;
    isTraceable: boolean;
    numberOfColumns: NumberOfWorksheetColumns;
    fontSize: number;
}

export const defaultCrosswordWorksheetOptions: CrosswordWorksheetOptions = {
    cardType: CardType.IMAGE_ONLY,
    isTraceable: true,
    numberOfColumns: NumberOfWorksheetColumns.ONE,
    fontSize: 140,
};

interface CrosswordWorksheetOptionsProps extends React.Props<any> {
    activityStore: ActivityStore;
}

const CrosswordWorksheetOptions = (props: CrosswordWorksheetOptionsProps) => {
    const handleCardTypeChanged = (cardType: CardType) => {
        props.activityStore.activityOptions.crosswordWorksheetOptions.cardType = cardType;
        props.activityStore.updateOptions();
    };

    return (
        <Observer
            render={() => (
                <>
                    <CardTypeButtons activityStore={props.activityStore} onCardTypeChanged={handleCardTypeChanged} />
                </>
            )}
        />
    );
};

export default CrosswordWorksheetOptions;
