import * as React from "react";

export default class CenterHorizontally extends React.Component<any> {
    render() {
        let styles = {
            outer: {
                width: "100%"
            } as React.CSSProperties,
            middle: {
                position: "absolute",
                left: "50%",
                top: 0,
                paddingBottom: 0
            } as React.CSSProperties,
            inner: {
                position: "relative",
                left: "-50%"
            } as React.CSSProperties
        };

        if (this.props.top !== undefined) {
            styles.middle.top = this.props.top;
        }

        if (this.props.paddingBottom !== undefined) {
            styles.middle.paddingBottom = this.props.paddingBottom;
        }

        return (
            <div style={styles.outer}>
                <div style={styles.middle}>
                    <div style={styles.inner}>{this.props.children}</div>
                </div>
            </div>
        );
    }
}
