import * as React from "react";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { autorun, observable } from "mobx";

// Material UI
import Dialog from "@material-ui/core/Dialog";
import CardTitleEditor from "components/categoryMenu/CardTitleEditor";

// Componenets
import { ActivityLink, ActivityLinkVisitor } from "models/activities/ActivityLink";

// Stores
import { RootStore } from "stores";

// Models
import { User } from "models";

// Utils
import { Firestore, FirebaseFunctions } from "core/firebase";
import { strings } from "core/localizedStrings";

interface ActivityLinkWaitingRoomProps {
    location?: any;
    match?: any;
    rootStore: RootStore;
}

@inject("rootStore")
@observer
class ActivityLinkWaitingRoom extends React.Component<ActivityLinkWaitingRoomProps> {
    @observable private showNameDialog = false;
    private isCheckingLink = false;
    private activityLink?: ActivityLink = undefined;

    constructor(props: ActivityLinkWaitingRoomProps) {
        super(props);

        autorun(() => {
            if (this.props.rootStore.activityStore !== undefined && !this.isCheckingLink) {
                this.isCheckingLink = true;
                this.checkActivityLink(this.props);
            }
        });
    }

    private async checkActivityLink(props: ActivityLinkWaitingRoomProps) {
        let pathName = this.props.location.pathname;
        let activityLinkID = this.props.match.params.activityLinkID;
        if (pathName && activityLinkID) {
            // Grab link info
            let activityLinkDoc = await Firestore.activityLinksCollection().doc(activityLinkID).get();
            if (!activityLinkDoc.exists) {
                this.props.rootStore.showErrorMessage("TODO");
                return;
            }
            this.activityLink = activityLinkDoc.data() as ActivityLink;
            this.activityLink.key = activityLinkDoc.id;

            // Check if account is still active
            let userDoc = await Firestore.userDocForID(this.activityLink.uid).get();
            if (!userDoc.exists) {
                this.props.rootStore.showErrorMessage("TODO");
                return;
            }

            let user = new User(userDoc.data());

            // If the have a hard coded expiration, check that first
            if (user.expiration) {
                if (user.expiration.toDate().getTime() < Date.now()) {
                    this.props.rootStore.showErrorMessage("Account has expired");
                    return;
                } else {
                    // Account has not yet expired, allow it
                    this.showNameDialog = true;
                    return;
                }
            }

            if (user.customerID) {
                let customerDetails = await FirebaseFunctions.getCustomerDetails(user.customerID);
                if (!customerDetails) {
                    this.props.rootStore.showErrorMessage("No details");
                    return;
                }

                console.log(customerDetails);
                if (!customerDetails.subscription) {
                    this.props.rootStore.showErrorMessage("No Subscription");
                    return;
                }

                if (!customerDetails.accountExpired) {
                    this.showNameDialog = true;
                } else {
                    this.props.rootStore.showErrorMessage("TODO");
                }
            } else {
                this.props.rootStore.showErrorMessage("no customerID");
                return;
            }
        }
    }

    private handleNameEntered = async (name: string) => {
        if (name.length > 0) {
            let { rootStore } = this.props;
            let { activityStore } = rootStore;

            let activityLinkVisitor: ActivityLinkVisitor = {
                name: name,
                activityLinkKey: this.activityLink.key,
                creatorUID: this.activityLink.uid,
                startTime: Firestore.timestamp(),
            };

            this.props.rootStore.setShowLoadingScreen(true);
            this.showNameDialog = false;
            let doc = await Firestore.activityLinkVisitorsCollection().add(activityLinkVisitor);
            if (doc.id) {
                this.props.rootStore.setShowLoadingScreen(false);
                activityStore.setCurrentCategory(this.activityLink.categoryKey, doc.id);
                rootStore.setRoute(this.activityLink.path);
            } else {
                this.props.rootStore.showErrorMessage(doc.error);
            }
        }
    };

    render() {
        return (
            <Dialog open={this.showNameDialog}>
                <CardTitleEditor
                    description={strings.enterName}
                    title={""}
                    onEditCompleteCallback={this.handleNameEntered}
                />
            </Dialog>
        );
    }
}

export default withRouter((props) => <ActivityLinkWaitingRoom {...props} />);
