import { log } from "../";

import { observable, action, runInAction, computed } from "mobx";
import { persist } from "mobx-persist";

import {
    AuthStore,
    AudioStore,
    ActivityStore,
    BrowseListsStore,
    StudentListStore,
    CreateListsStore,
    UserStore,
} from "./";

import { CustomListsRefFactory } from "./CustomListsRefFactory";
import { ConfirmationMessage, InfoMessage } from "components/MessageBoxDialogs";
import { WindowSize, LanguageType, ContentAccess, Tool } from "models";
import FirebaseFunctions from "core/firebase/FirebaseFunctions";

// Utils
import { FirestoreActions } from "core/firebase";
import { WindowResizeChangeDispatcher } from "core/utils/WindowResizeChangeDispatcher";
import { strings } from "core/localizedStrings";
import { hydrate } from "stores/PersistentStore";

// Constants
import { HOME_PATH } from "../constants";

import {
    FetchUnknownUserStatus,
    ManagedUserResponse,
    MysqlUserMigratedResponse,
    UserFoundResponse,
} from "../core/firebase/FunctionResponses";
import SoundEffectPlayer from "core/utils/SoundEffectPlayer";
import { firebaseAnalytics } from "core/firebase/firebaseApp";
import { activityFromPath } from "core/utils/ActivityUtils";
import TimerManager from "core/utils/TimerManager";

export interface FileDownloadDictionary {
    [key: string]: string;
}

export default class RootStore {
    @observable windowSize: WindowSize;

    @observable infoMessage: InfoMessage;
    @observable errorMessage: string;
    @observable loginRequiredMessage: string;
    @observable snackbarMessage: string;
    @observable confirmationMessage: ConfirmationMessage;

    @observable public currentRoute: string;
    @observable public appBarTitle: string;
    @observable public shouldShowLoadingScreen: boolean = false;
    @observable public shouldShowNavigationDrawer: boolean = false;

    @persist @observable public introduceExpanded: boolean = true;
    @persist @observable public practiceExpanded: boolean = false;
    @persist @observable public playExpanded: boolean = false;
    @persist @observable public quizExpanded: boolean = false;
    @persist @observable public toolsExpanded: boolean = false;

    @observable public studentListStore?: StudentListStore;
    @observable public browseListsStore: BrowseListsStore;
    @observable public createListsStore: CreateListsStore;
    @observable public audioStore: AudioStore;
    @observable public authStore: AuthStore;
    @observable public activityStore: ActivityStore;
    @observable public userStore: UserStore;

    @persist @observable public siteLanguage: LanguageType = LanguageType.ENGLISH;
    public refFactory = new CustomListsRefFactory();

    private appRoot: any;

    @computed get shouldShowPreviewTimer() {
        if (!this.userStore || !this.activityStore) {
            return true;
        }

        let isRestricted = !(this.activityStore.currentActivity instanceof Tool);
        if (!isRestricted) {
            return false;
        }

        if (this.activityStore.activityLinkVisitorKey) {
            return false;
        }

        if (this.userStore.user.isAnonymous) {
            return true;
        }

        let shouldShow =
            (this.userStore.contentAccess === ContentAccess.None ||
                this.userStore.contentAccess === ContentAccess.Credits) &&
            !this.activityStore.shouldShowBrowseListsView &&
            !this.activityStore.shouldShowCreateListsView &&
            !this.activityStore.shouldShowStudentListSelector &&
            !this.activityStore.shouldShowTeamMaker &&
            !this.shouldShowNavigationDrawer;

        return shouldShow;
    }

    get firebaseUser() {
        return this.authStore.firebaseUser;
    }

    constructor(firebaseApp: firebase.app.App) {
        this.audioStore = new AudioStore();
        this.authStore = new AuthStore(this);
        this.authStore.setIsLoggingIn(true);

        SoundEffectPlayer.instance.audioStore = this.audioStore;

        WindowResizeChangeDispatcher.getInstance().addListener(this.onWindowResize);
    }

    setApp = (app: any) => {
        this.appRoot = app;
        this.hydrateStore();
    };

    hydrateStore = async () => {
        let hydratedStore = await hydrate("root", this);
        if (hydratedStore) {
            runInAction(() => {
                Object.assign(this, hydratedStore);
            });
            if (this.siteLanguage !== LanguageType.ENGLISH) {
                this.setSiteLanguage(this.siteLanguage);
            }
        }
    };

    @action
    public resetUserDependentStores() {
        this.activityStore = new ActivityStore(this);
        this.browseListsStore = new BrowseListsStore(this);
        this.studentListStore = new StudentListStore(this);
        this.createListsStore = new CreateListsStore(this);
    }

    @action
    public setAppBarTitle(title: string) {
        this.appBarTitle = title;
    }

    @action
    public setRoute(path: string) {
        TimerManager.clearAllTimers();
        this.setShouldShowNavigationDrawer(false);
        this.activityStore.setShouldShowBrowseListsView(false);
        this.activityStore.setShouldShowCreateListsView(false);
        this.activityStore.setShouldShowStudentListSelector(false);
        this.activityStore.setShouldShowTeamMaker(false);
        this.activityStore.setShouldShowStudentSnackbar(false);

        let activity = activityFromPath(path);
        if (path !== undefined) {
            firebaseAnalytics.setCurrentScreen(path);
            firebaseAnalytics.logEvent("page_view", {
                page_path: path,
                page_location: window.location.hostname + path,
                page_title: activity ? activity.activityType : path,
            });
        }
        this.currentRoute = path;
    }

    @action
    public setSiteLanguage = (language: LanguageType) => {
        this.siteLanguage = language;
        // this.userStore.setSiteLanguage(language);
        strings.setLanguage(language);

        if (this.appRoot) {
            this.appRoot.update();
        }
    };

    @action
    public setShowLoadingScreen(value: boolean) {
        this.shouldShowLoadingScreen = value;
    }

    @action
    public setIntroduceExpanded = (value: boolean) => {
        this.introduceExpanded = value;
    };

    @action
    public setPracticeExpanded = (value: boolean) => {
        this.practiceExpanded = value;
    };

    @action
    public setPlayExpanded = (value: boolean) => {
        this.playExpanded = value;
    };

    @action
    public setQuizExpanded = (value: boolean) => {
        this.quizExpanded = value;
    };

    @action
    public setToolsExpanded = (value: boolean) => {
        this.toolsExpanded = value;
    };

    @action
    public showLoginRequiredMessage = (message: string) => {
        this.loginRequiredMessage = message;
    };

    @action
    public showInfoMessage = (message: InfoMessage) => {
        this.infoMessage = message;
    };

    @action
    public showErrorMessage = (error: any) => {
        if (error instanceof Error) {
            error = error.message;
        } else if (error instanceof Object) {
            error = JSON.stringify(error);
        }
        this.errorMessage = error;
        log.error(error);
    };

    @action
    public showSnackbarMessage = (message: any) => {
        this.snackbarMessage = message;
    };

    @action
    public showConfirmationMessage = (message: ConfirmationMessage) => {
        this.confirmationMessage = message;
    };

    @action
    public setShouldShowNavigationDrawer = (value: boolean) => {
        this.shouldShowNavigationDrawer = value;
    };

    public logout() {
        this.setRoute(HOME_PATH);
        this.authStore.logout();
    }

    public fetchUnknownUser = async (username: string, password: string) => {
        try {
            log.verbose("Fetching unknown user.");
            this.authStore.setIsLoggingIn(true);
            let result = await FirebaseFunctions.findUnknownUser(username, password);
            if (result) {
                switch (result.status) {
                    case FetchUnknownUserStatus.MysqlUserMigrated:
                        let migratedResponse = result.data as MysqlUserMigratedResponse;
                        try {
                            if (password) {
                                await this.authStore.signInWithUsername(migratedResponse.email, password);
                            } else {
                                let user = await FirestoreActions.getUser(migratedResponse.uid);
                                runInAction(() => {
                                    this.userStore = new UserStore(this, user);
                                });
                            }

                            this.authStore.setIsSignedIn(true);
                            this.showInfoMessage({ text: strings.dataBeingMigratedFromBarryFunEnglish });
                            FirebaseFunctions.migrateUserDataFromBarryFunEnglish(
                                migratedResponse.mysqlID,
                                migratedResponse.uid
                            );
                        } catch (error) {
                            this.showErrorMessage(error);
                            this.authStore.setIsLoggingIn(false);
                        }

                        break;

                    // case FetchUnknownUserStatus.ManagedUserFound:
                    //     let managedUserResponse = result.data as ManagedUserResponse;
                    //     await this.authStore.signInWithToken(managedUserResponse.token);
                    //     break;

                    case FetchUnknownUserStatus.UsernameFound:
                        let userFoundResponse = result.data as UserFoundResponse;
                        await this.authStore.signInWithUsername(userFoundResponse.email, password);
                        break;

                    case FetchUnknownUserStatus.NotFound:
                        log.verbose("Unknown user not found.");
                        if (!password && this.firebaseUser) {
                            // 3rd party provider login for first time
                            log.verbose("Checking for user.");
                            let user = await FirestoreActions.getUserFromEmail(this.firebaseUser.email);
                            if (!user) {
                                log.verbose("User not found. Creating user.");
                                log.verbose(this.firebaseUser);
                                let createdUser = await this.authStore.createUserForTrial(
                                    this.firebaseUser.uid,
                                    this.firebaseUser.email
                                );
                                if (createdUser) {
                                    runInAction(() => {
                                        this.userStore = new UserStore(this, createdUser);
                                    });
                                    this.authStore.setIsSignedIn(true);
                                }
                            }
                        } else {
                            this.showErrorMessage(strings.invalidUsernameOrPassword);
                            this.authStore.setIsLoggingIn(false);
                        }
                        break;

                    default:
                        this.authStore.setIsLoggingIn(false);
                        break;
                }
            }
        } catch (error) {
            this.showErrorMessage(error);
            this.authStore.setIsLoggingIn(false);
        }
    };

    // -----------
    // Private
    // #region ---
    @action
    private onWindowResize = (windowWidth: number, windowHeight: number) => {
        this.windowSize = new WindowSize(windowWidth, windowHeight);
    };
}
