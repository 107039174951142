import * as React from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { Component } from "react";

// Material UI
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";

// Custom UI
import OptionsMenu, { MenuOption } from "./OptionsMenu";

// Models
import { Student } from "../../models";

// Utils
import * as Icons from "../../core/utils/Icons";
import { Colors } from "../../core/utils/Colors";
import { Fonts } from "../../core/utils/Fonts";
import { GlobalStyles } from "../../styles/GlobalStyles";

// Stores
import StudentListStore from "../../stores/StudentListStore";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

const styles = {
    title: {
        color: Colors.black,
        fontFamily: Fonts.BalsamiqSans,
        paddingLeft: 15,
    } as React.CSSProperties,
    menu: {
        top: 3,
        right: 10,
        position: "absolute",
    } as React.CSSProperties,
    absentToggle: { paddingLeft: 10, fontSize: "48px" },
};

export enum StudentCardType {
    ClassListEditor,
    TeamMaker,
}

interface StudentCardProps {
    student: Student;
    variant: StudentCardType;
    store?: StudentListStore;
    width?: number;
}

@observer
export default class StudentCard extends Component<StudentCardProps> {
    render() {
        return <Paper style={{ width: "100%" }} children={this.renderPaperChildren()} />;
    }

    @action
    private onMenuSelectionChanged = (menuSelection: MenuOption) => {
        switch (menuSelection) {
            case MenuOption.Edit:
                this.props.store.studentToEdit = this.props.student;
                break;
            case MenuOption.Delete:
                this.props.store.deleteStudent(this.props.student);
                break;

            default:
                break;
        }
    };

    private renderPaperChildren() {
        switch (this.props.variant) {
            case StudentCardType.ClassListEditor:
                return this.renderClassListEditorCard();
            case StudentCardType.TeamMaker:
                return this.renderTeamMakerCard();

            default:
                return null;
        }
    }

    private renderTeamMakerCard() {
        let titleStyles = Object.assign({}, styles.title);
        titleStyles.fontSize = 26;
        titleStyles.paddingBottom = 0;
        titleStyles.paddingTop = 10;
        titleStyles = Object.assign(titleStyles, GlobalStyles.truncatedText);

        let width = this.props.width ? this.props.width : 250;

        return (
            <div style={{ position: "relative" }}>
                <Row middle="xs" style={{ margin: "auto", padding: 5, minWidth: width, width: "100%" }}>
                    <Avatar
                        style={{ height: 45, width: 45, marginLeft: 10, marginRight: 5 }}
                        src={this.props.student.profileImageDownloadURL}
                    />
                    <div style={titleStyles}>{this.props.student.name}</div>
                </Row>
            </div>
        );
    }

    private renderClassListEditorCard() {
        let titleStyles: any = Object.assign({}, styles.title);
        titleStyles = Object.assign(titleStyles, GlobalStyles.truncatedText);

        return (
            <div style={{ position: "relative" }}>
                <Row style={{ margin: 2, padding: 2, width: "100%" }}>
                    <Col xs={12} sm={12}>
                        <Row middle="xs">
                            <Checkbox
                                checkedIcon={<Icons.Clear color="error" />}
                                checked={this.props.student.isAbsent}
                                onChange={this.onAbsentToggled}
                                style={styles.absentToggle}
                            />
                            <Avatar
                                style={{ height: 40, width: 40, marginLeft: 10 }}
                                src={this.props.student.profileImageDownloadURL}
                            />
                            <div style={titleStyles}>{this.props.student.name}</div>
                        </Row>
                    </Col>
                </Row>
                <div style={styles.menu}>{this.renderMenu()}</div>
            </div>
        );
    }

    private renderMenu() {
        let options: MenuOption[] = [MenuOption.Edit, MenuOption.Delete];
        return <OptionsMenu options={options} onMenuSelectionChangedCallback={this.onMenuSelectionChanged} />;
    }

    private onAbsentToggled = (event: any, isInputCheckd: boolean) => {
        this.props.student.isAbsent = isInputCheckd;
        this.props.store.updateStudent(this.props.student);
    };
}
