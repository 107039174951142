// import { log } from "../../";

import * as React from "react";

// Material UI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";

// Components
import SimpleMenu from "../common/SimpleMenu";

// Models
import { Category } from "models/customLists";

// Utils
import { strings } from "core/localizedStrings";
import { Colors } from "core/utils/Colors";
import { QuelledMessageType } from "models";
import { RootStore } from "stores";
import ActivitySupport from "core/utils/ActivitySupport";
import { ConfirmationMessage } from "components/MessageBoxDialogs";
import * as Icons from "core/utils/Icons";
import { Button, Grid } from "@material-ui/core";
import { IconButton, Tooltip } from "@material-ui/core";
import * as DeviceSize from "core/utils/DeviceSize";
import { StringUtils } from "core/utils/StringUtils";
import { RecentCategory } from "stores/ActivityStore";

export interface RecentCategoriesMenuProps extends React.Props<any> {
    rootStore: RootStore;
    recentCategories: RecentCategory[];
    isSecond?: boolean;
}

const RecentCategoriesMenu = (props: RecentCategoriesMenuProps) => {
    let { currentCategory } = props.rootStore.activityStore;

    const onCategoryMenuOptionChanged = (category: Category) => {
        if (props.isSecond) {
            handleSecondCategoryChanged(category);
        } else {
            handleCategoryChanged(category);
        }
    };

    const handleCategoryChanged = (category: Category) => {
        let { activityStore, authStore, userStore } = props.rootStore;
        let messageType = QuelledMessageType.ChangeCategory;
        if (
            activityStore.didGameEnd ||
            userStore.user.isMessageQuelled(messageType) ||
            !authStore.isSignedIn ||
            !activityStore.currentCategory ||
            !activityStore.currentActivity ||
            !ActivitySupport.requiresFlash(activityStore.currentActivity)
        ) {
            onCategoryChangeConfirmed(category);
        } else {
            let message = new ConfirmationMessage(
                strings.confirmChangeCategory,
                strings.quellMessage,
                (result, didQuell) => {
                    if (didQuell) {
                        userStore.quellMessage(messageType);
                    }
                    if (result === "yes") {
                        onCategoryChangeConfirmed(category);
                    }
                }
            );
            props.rootStore.showConfirmationMessage(message);
        }
    };

    const handleSecondCategoryChanged = (category: Category) => {
        let { rootStore } = props;
        rootStore.activityStore.setIsSettingSecondCategory(true);
        if (category) {
            rootStore.activityStore.setCurrentCategory(category.key);
        } else {
            rootStore.activityStore.setShouldShowStudentSnackbar(false);
            rootStore.activityStore.setShouldShowBrowseListsView(true);
        }
        rootStore.setShouldShowNavigationDrawer(false);
    };

    const onCategoryChangeConfirmed = (category: Category) => {
        let { rootStore } = props;
        if (category) {
            rootStore.activityStore.setCurrentCategory(category.key);
        } else {
            rootStore.activityStore.setShouldShowBrowseListsView(true);
        }
        rootStore.activityStore.setShouldShowStudentSnackbar(false);
        rootStore.setShouldShowNavigationDrawer(false);
    };

    let displayComponent = (
        <Grid container alignItems="center">
            <Grid item>
                <DeviceSize.Desktop>
                    <BigScreenButton title={StringUtils.limitStringToChars(currentCategory.title, 30)} />
                </DeviceSize.Desktop>
                <DeviceSize.Tablet>
                    <SmallScreenButton />
                </DeviceSize.Tablet>
            </Grid>
        </Grid>
    );

    if (!currentCategory || props.recentCategories.length === 0) {
        return (
            <div
                onClick={() => {
                    onCategoryMenuOptionChanged(undefined);
                }}
            >
                {displayComponent}
            </div>
        );
    } else {
        return (
            <SimpleMenu displayComponent={displayComponent}>
                <Options
                    recentCategories={props.recentCategories}
                    onCategoryMenuOptionChanged={onCategoryMenuOptionChanged}
                />
            </SimpleMenu>
        );
    }
};
export default RecentCategoriesMenu;

interface OptionsProps {
    recentCategories: RecentCategory[];
    onCategoryMenuOptionChanged: (Category) => void;
}

const Options = (props: OptionsProps) => {
    return (
        <>
            <List
                component="nav"
                subheader={
                    props.recentCategories.length > 0 ? (
                        <ListSubheader style={{ marginTop: -10, marginBottom: -20 }}>{strings.recent}</ListSubheader>
                    ) : null
                }
            />
            {props.recentCategories.map((category: RecentCategory, index) => (
                <ListItem
                    key={category.key}
                    button={true}
                    onClick={() => {
                        props.onCategoryMenuOptionChanged(category);
                    }}
                >
                    <ListItemText primary={category.title} />
                </ListItem>
            ))}
            {props.recentCategories.length > 0 && <Divider />}
            <ListItem
                button={true}
                onClick={() => {
                    props.onCategoryMenuOptionChanged(undefined);
                }}
            >
                <ListItemText
                    primary={<span style={{ color: Colors.lightBlue }}>{strings.browseAllCategories}</span>}
                />
            </ListItem>
        </>
    );
};

const SmallScreenButton = () => (
    <Tooltip title={strings.changeCategory} placement="bottom">
        <IconButton style={{ padding: 2 }}>
            <Icons.Category
                style={{
                    color: Colors.lightBlue,
                    width: 30,
                    height: 30,
                }}
            />
        </IconButton>
    </Tooltip>
);

const BigScreenButton = (props: any) => (
    <Button
        style={{ padding: "5px 10px 5px 10px" }}
        startIcon={
            <Icons.Category
                style={{
                    color: Colors.lightBlue,
                    width: 30,
                    height: 30,
                }}
            />
        }
    >
        {props.title}
    </Button>
);
