import * as React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import { SIMPLE_MENU_INDEX } from "layers";

// import { Col } from "layout";

interface SimpleMenuProps extends React.Props<any> {
    icon?: JSX.Element;
    displayComponent?: JSX.Element;
    text?: string;
    secondaryText?: string;
    onButtonClickedCallback?: () => void;
    children: any;
}

@observer
export default class SimpleMenu extends React.Component<SimpleMenuProps> {
    @observable showMenu: boolean = false;
    private anchorElement: HTMLElement;

    render() {
        return (
            <>
                {this.props.displayComponent ? (
                    <div onClick={this.onClick} style={{ cursor: "pointer" }}>
                        {this.props.displayComponent}
                    </div>
                ) : null}
                {this.props.icon ? <IconButton onClick={this.onClick}>{this.props.icon}</IconButton> : null}
                <Menu
                    id="simple-menu"
                    MenuListProps={{ disablePadding: true }}
                    style={{ padding: 0, zIndex: SIMPLE_MENU_INDEX }}
                    anchorEl={this.anchorElement}
                    open={this.showMenu}
                    onClick={this.onClose}
                    onClose={this.onClose}
                >
                    {this.props.children}
                </Menu>
            </>
        );
    }

    @action
    private onClick = (event: any) => {
        if (this.props.children) {
            this.anchorElement = event.currentTarget;
            this.showMenu = true;
        }

        if (this.props.onButtonClickedCallback) {
            this.props.onButtonClickedCallback();
        }
    };

    @action
    private onClose = (event: any) => {
        this.showMenu = false;
    };
}
