import { log } from "../../";

import * as React from "react";
import ResizeObserver from "react-resize-observer";
import { findDOMNode } from "react-dom";
import { observer } from "mobx-react";
import { observable, runInAction } from "mobx";

const throttle = require("throttle-debounce").throttle;

export interface WithDivSizeProps extends React.Props<any> {
    divX?: number;
    divY?: number;
    divHeight?: number;
    divWidth?: number;
    windowWidth?: number;
    windowHeight?: number;
}

function withDivSize<OriginalProps extends WithDivSizeProps>(
    WrappedComponent: React.ComponentType<OriginalProps & WithDivSizeProps>
) {
    class WithDivSize extends React.Component<OriginalProps & WithDivSizeProps> {
        @observable private divHeight: number;
        @observable private divWidth: number;
        @observable private divX: number;
        @observable private divY: number;
        @observable private windowHeight: number;
        @observable private windowWidth: number;

        private refGuid: any;

        constructor(props: any) {
            super(props);
            this.updateWindowSize = throttle(50, this.updateWindowSize);
        }

        render() {
            return (
                <div>
                    <ResizeObserver
                        onPosition={rect => {
                            this.updateWindowSize();
                        }}
                        onResize={rect => {
                            this.updateWindowSize();
                        }}
                    />

                    <WrappedComponent
                        {...this.props}
                        divHeight={this.divHeight}
                        divWidth={this.divWidth}
                        divX={this.divX}
                        divY={this.divY}
                        windowHeight={this.windowHeight}
                        windowWidth={this.windowWidth}
                        ref={ref => (this.refGuid = ref)}
                    />
                </div>
            );
        }

        componentDidMount = () => {
            window.addEventListener("resize", this.updateWindowSize);
            this.updateWindowSize();
        };

        componentDidUpdate = () => {
            this.updateWindowSize();
        };

        componentWillUnmount = () => {
            window.removeEventListener("resize", this.updateWindowSize);
        };

        updateWindowSize = () => {
            let domNode = findDOMNode(this.refGuid) as Element;
            if (domNode) {
                var listBoundingRect = domNode.getBoundingClientRect();
                const w = window,
                    documentElement = document.documentElement,
                    body = document.getElementsByTagName("body")[0];

                runInAction(() => {
                    this.divY = listBoundingRect.top;
                    this.divX = listBoundingRect.left;
                    this.divWidth = domNode.clientWidth;
                    this.divHeight = domNode.clientHeight;

                    this.windowWidth = w.innerWidth || documentElement.clientWidth || body.clientWidth;
                    this.windowHeight = w.innerHeight || documentElement.clientHeight || body.clientHeight;
                });
            } else {
                log.verbose("No DOM node!!");
            }
        };
    }
    return observer(WithDivSize);
}

export default withDivSize;
