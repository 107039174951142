import * as React from "react";
import { observer } from "mobx-react";

// Material UI
import Button from "@material-ui/core/Button";

// Components
import { observable } from "mobx";
import { Row, Col } from "layout";
import { Fonts } from "core/utils/Fonts";
import ActivitySupport from "core/utils/ActivitySupport";

// Models
import Activity from "../../models/activities/Activity";

// Utils
import { strings } from "core/localizedStrings";
import SimpleMenu from "components/common/SimpleMenu";
import Typography from "@material-ui/core/Typography";

export interface NavigationDropdownProps extends React.Props<any> {
    description: string;
    items: Activity[];
    itemsDescription?: string;
    items2?: Activity[];
    items2Description?: string;
    onActivitySelected?: (activity: Activity) => void;
}

export enum NavigationDropdownType {
    Introduce,
    Practice,
    Play,
    Quiz,
    Tools,
}

@observer
export default class NavigationDropdown extends React.Component<NavigationDropdownProps> {
    @observable isExpanded = true;

    render() {
        return <SimpleMenu displayComponent={this.renderDisplayComponent()}>{this.renderOptions()}</SimpleMenu>;
    }

    private renderDisplayComponent() {
        return <Button style={{}}>{this.props.description}</Button>;
    }

    private renderOptions() {
        return (
            <Col style={{ padding: 10, cursor: "pointer" }}>
                {this.props.itemsDescription && (
                    <Row>
                        <Typography variant="subtitle1">{this.props.itemsDescription}</Typography>
                    </Row>
                )}
                {this.itemsToListItems(this.props.items)}
                {this.props.items2Description && (
                    <Row style={{ paddingTop: 10 }}>
                        <Typography variant="subtitle1">{this.props.items2Description}</Typography>
                    </Row>
                )}
                {this.props.items2 && this.itemsToListItems(this.props.items2)}
            </Col>
        );
    }

    private itemsToListItems(items: Activity[]) {
        let nonFlashItems = items.filter((item) => !ActivitySupport.requiresFlash(item));
        let flashItems = items.filter((item) => ActivitySupport.requiresFlash(item));
        let combinedItems = nonFlashItems.concat(flashItems);
        return combinedItems.map((item: Activity, index) => (
            <div key={index}>
                <ListItemButton
                    item={item}
                    onClick={() => {
                        this.onClick(item);
                    }}
                />
            </div>
        ));
    }

    // UI event handlers
    // ------------------------------------------
    private onClick(activity: Activity) {
        this.props.onActivitySelected(activity);
    }
}

interface ListItemButtonProps {
    item: Activity;
    onClick: () => void;
}

const ListItemButton = (props: ListItemButtonProps) => (
    <Row middle="xs" center="xs" onClick={props.onClick} style={{ padding: 5, display: "inline" }}>
        <Button style={{ paddingTop: 15, fontFamily: Fonts.BalsamiqSans }}>
            {props.item.activityType}
            {ActivitySupport.requiresFlash(props.item) && (
                <img
                    style={{ height: 12, paddingLeft: 5 }}
                    src={require("assets/images/flashIcon.png")}
                    title={strings.requiresFlash}
                />
            )}
        </Button>
    </Row>
);
