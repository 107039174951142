import { observable } from "mobx";
import { TabSelection, LanguageType } from "../../models";
import { SortByType } from "models/customLists";

// Stores
import { RootStore } from "stores";
import { CategoryType, Folder } from "models";

export const FOLDER_PAGE_SIZE = 40;

export default interface CategoryFolder {
    tabType: TabSelection;
    isVisible: boolean;
    sortByType: SortByType;
    languageFilter: LanguageType;
    listTypeFilter: CategoryType;

    breadcrumbs: Folder[];
    isLoading: boolean;
    currentFolder: Folder;
    currentFolderKey: string;
    rootStore: RootStore;

    updateIfNecessary();
    checkShouldFetchMore();
}

export class CachedFolderItem {
    updatedDate: Date;
    @observable folder: Folder;
}
