import Activity from "./Activity";

export enum QuizType {
    MultipleChoice = "Multiple Choice"
}

export default class Quiz extends Activity {
    public static allItems = [new Quiz(QuizType.MultipleChoice)];

    public type: QuizType;

    get imagePath() {
        return this.prefix ? `${this.prefix}/` + this.cleanedType + ".jpg" : this.cleanedType + ".jpg";
    }

    protected get prefix() {
        return "quizzes";
    }
}
