/* tslint:disable */
export let french = {
    absent: "Absent",
    absentStudentsFormat: "{0} absent",
    accountRegistration: "Enregistrement du compte",
    active: "Actif",
    activityPreview: "Aperçu de l'activité",
    activityPreviewMessage: "Veuillez vous connecter ou vous inscrire pour un compte gratuit.",
    add: "Ajouter",
    addAccount: "Ajouter un compte",
    addCard: "Ajouter une carte",
    addCards: "Ajouter des cartes",
    addFolder: "Ajouter le dossier",
    addGroupStudentAccount: "Ajouter un compte étudiant au groupe",
    addKeyword: "Ajouter un mot clé",
    addName: "Ajouter un nom",
    addTeacherAccount: "Ajouter un compte enseignant",
    addToFavorites: "Ajouter aux Favoris",
    addWithPlus: "+ Ajouter",
    additionalResources: "Ressources supplémentaires",
    admin: "Admin",
    affirmative: "Affirmative",
    afterTrial:
        "Après la période d'essai de 30 jours, vous pouvez continuer à utiliser le site en tant que membre de base ou effectuer une mise à niveau vers Premium.",
    all: "tous",
    allFieldsRequired: "Tous les champs sont requis.",
    allowedToChangePassword: "enseignant autorisé à changer de mot de passe",
    annual: "Annuel",
    annualSubscription: "Souscription annuelle",
    answer: "Réponse",
    audioClipLanguageLabel: "Langue du clip audio",
    basic: "De base",
    basicMemberCredits:
        "* Les membres de base reçoivent 8 crédits mensuels. Une fois que tous les crédits ont été utilisés, l'accès au site sera limité jusqu'au premier jour du mois prochain.",
    billedAnnually: "Facturé annuellement",
    billedAnnuallyFormat: "Facturé annuellement ({0})",
    billedMonthly: "Facturé mensuellement",
    billedMonthlyFormat: "Facturé mensuellement ({0})",
    billingFrequency: "Fréquence de facturation",
    boy: "Garçon",
    boys: "Garçons",
    browseAllCategories: "Parcourir / Modifier les catégories",
    browseAllLists: "Parcourir / modifier des listes",
    cancel: "Annuler",
    cancelSubscription: "Annuler l'abonnement",
    cannotAddManagedAccountsInTrial: "Les comptes gérés ne peuvent pas être ajoutés pendant la période d'essai.",
    cannotBeEmpty: "Ne peux pas être vide.",
    card: "Carte",
    cardLower: "Carte",
    cardsLower: "Cartes",
    category: "Catégorie",
    category2: "Catégories",
    categoryCharactersNotSupported:
        "Cette catégorie contient des caractères qui ne sont pas encore pris en charge par cette activité.",
    categoryTypeNotSupported: "Ce type de catégorie n'est pas encore pris en charge avec cette catégorie.",
    changeEditStudents: "Changer / Editer les Etudiants",
    changePassword: "Changer le mot de passe",
    checkEmailForVerifcation: "Veuillez vérifier votre email pour terminer l'activation de votre compte.",
    chinese: "Chinois",
    className: "Nom du cours",
    clear: "Clair",
    clearFilters: "Effacer les filtres",
    clearSelectedImage: "Effacer l'image sélectionnée",
    clearSelectedImages: "Effacer les images sélectionnées",
    clickHereTo: "Pas de compte? Cliquez ici pour",
    confirmChangeCategory: "Changer la catégorie réinitialisera l'activité. Êtes-vous sûr de vouloir le changer?",
    confirmChangeClassList:
        "Changer la liste des étudiants réinitialisera l'activité. Êtes-vous sûr de vouloir le changer?",
    confirmChangeTeamNumber:
        "Changer le nombre d'équipes réinitialisera l'activité. Êtes-vous sûr de vouloir le changer?",
    confirmRestartSubscription:
        "Changer le nombre d'équipes réinitialisera l'activité. Êtes-vous sûr de vouloir le changer?",
    contact: "Contact",
    contactAdminMessage: "Si le problème persiste, contactez l'administrateur du site.",
    contactDescription:
        "S'il vous plaît laissez-nous savoir toutes les questions ou problèmes que vous avez. Envoyez simplement un message ci-dessous et nous vous contacterons dans les meilleurs délais.",
    contactFormSubmitted: "Merci! Votre message a été envoyé.",
    copy: "copie",
    copyLower: "copie",
    createCardFromSelectedImage: "Créer une carte à partir de l'image sélectionnée",
    createCardsFromSelectedImages: "Créer des cartes à partir de {0} images sélectionnées",
    createCopyIn: "Créer une copie dans:",
    createCustomLists: "Créer des listes personnalisées",
    createNewList: "Créer une nouvelle liste",
    createSelectCategory: "Créer ou sélectionner une catégorie",
    createSelectClassList: "Créer ou sélectionner une liste d'étudiants",
    createStudentNameLists: "Créer des listes de noms d'étudiants",
    createTeams: "Créer des équipes",
    createdBy: "Créé par",
    createdOn: "Créé sur",
    creditCard: "Carte de crédit",
    creditNeeded: "1 crédit par utilisation",
    currentSubscriptionRequiredForManagedAccounts:
        "Un abonnement actif est requis. Veuillez redémarrer votre abonnement pour ajouter des comptes supplémentaires.",
    customizeHeader: "{{Personnaliser}} pour s'adapter à toutes les leçons",
    customizeText:
        "Prenez une liste de vocabulaire simple ou une liste de questions et transformez-la en un activité en secondes. Une fois la liste créée, elle peut être utilisée avec n’importe quelle activité du site.",
    dataBeingMigratedFromBarryFunEnglish:
        "Les données de votre compte sont en cours de migration depuis BarryFunEnglish. Cela a seulement besoin d'être fait une fois. Selon la quantité de données, cela peut prendre de l’un à l’autre. Quelques secondes à quelques heures. Si vous ne voyez pas toutes vos données après quelques heures, veuillez contacter l'administrateur du site.",
    delete: "Effacer",
    deleteCategoryConfirmation: "Êtes-vous sûr de vouloir supprimer définitivement cette catégorie?",
    deleteFavoriteConfirmation: "Êtes-vous sûr de vouloir supprimer ceci de vos favoris?",
    deleteFolderConfirmation: "Êtes-vous sûr de vouloir supprimer définitivement ce dossier et tout son contenu?",
    deleteStudentListConfirmation: "Êtes-vous sûr de vouloir supprimer cette liste?",
    discount: "Remise ({0}%):",
    discounts: "Réductions:",
    done: "Terminé",
    dutch: "Néerlandais",
    edit: "Modifier",
    editProfile: "Editer le profil",
    email: "Email",
    emailAlreadyRegistered: "L'adresse e-mail a déjà été enregistrée",
    emailOrUsername: "E-mail ou nom d'utilisateur",
    emailRequiredError: "Nom d'utilisateur est nécessaire.",
    emailSentTo: "E-mail envoyé à {0}.",
    endGame: "Fin du jeu",
    english: "Anglais",
    enterEmailUsedToRegister: "Veuillez entrer l'adresse email que vous avez utilisée pour vous inscrire sur le site:",
    enterIncorrectAnswers: "Entrez mes propres réponses incorrectes",
    enterQuestion: "Entrez la question",
    enterText: "EntEntrez la questioner text:",
    enterValidEmail: "S'il vous plaît, mettez une adresse email valide.",
    enterVocabAndQuestion: "Entrez le mot de vocabulaire et la question",
    enterVocabulary: "Entrez le vocabulaire",
    existingAccount: "compte existant",
    expirationDateFormat: "Date d'expiration: {0}",
    expired: "Expiré",
    expires: "Expire",
    expressionInputTextField1Label: "Champ de texte 1 (Question)",
    expressionInputTextField2Label: "Champ de texte 2 (réponse)",
    favorites: "Favoris",
    flashcards: "Flashcards",
    folderName: "Nom de dossier:",
    forClassroomHeader: "Conçu pour la {{classe}}",
    forClassroomText:
        "Les jeux et activités sont conçus pour être utilisés en classe et travaillez très bien avec les classes grandes ou petites. Les jeux peuvent avoir entre deux et cinq équipes et des listes de noms d'étudiants peuvent être entrées pour une sélection aléatoire.",
    forgotPassword: "mot de passe oublié?",
    free: "Libre",
    french: "français",
    games: "Jeux",
    gender: "Le sexe",
    german: "allemand",
    girl: "Fille",
    girls: "Filles",
    girlsVsBoys: "Filles vs garçons",
    greek: "grec",
    groupNumberFormat: "Groupe {0}",
    homePageContactMessage: "Avez-vous des {{questions}} pour nous?",
    imageOverlayGraphic: "Image superposée",
    imageWithText: "Image avec un seul champ de texte",
    imageWithTextDescription:
        "Ces listes sont parfaites pour la pratique du vocabulaire, la lecture et la parole. Ils travaillent avec presque toutes les activités du site.",
    imageWithTwoText: "Image avec deux champs de texte",
    imageWithTwoTextDescription:
        "Ces listes sont utilisées pour poser des questions sur une image ou pour traduire entre deux langues",
    imagesSelectorInstructions: "Sélectionnez ou téléchargez des images pour créer des cartes à partir de:",
    interactiveFlashcards: "Flashcards interactives",
    invalidEmail: "Adresse e-mail invalide.",
    invalidPassword: "Les mots de passe doivent comporter au moins 6 caractères.",
    invalidUsername: "Nom d'utilisateur invalide.",
    invalidUsernameOrPassword: "Vous avez entré un nom d'utilisateur ou un mot de passe invalide",
    italian: "italien",
    japanese: "Japonais",
    keywordsLabel: "Mots clés",
    korean: "coréen",
    language: "La langue",
    languageTitle: "La langue",
    learn: "Apprendre",
    learnDescription: "Introduction et révision du vocabulaire et des questions",
    lesson: "Leçon",
    lessonDescription: "Mélange d'une image unique et d'une image avec deux champs de texte",
    lessonTypeSelectorTitle: "Voulez-vous pratiquer le vocabulaire ou les questions de cette leçon?",
    listAccessibility: "Liste d'accessibilité",
    listTitleLabel: "Titre de la liste",
    listTypeTitle: "Type de liste",
    loggingIn: "Se connecter",
    loginToCopy: "Vous devez être connecté pour copier des listes.",
    loginToCreateFolders: "Vous devez être connecté pour créer des dossiers.",
    loginToCreateLists: "Vous devez être connecté pour créer des listes personnalisées.",
    loginToEditLists: "Vous devez être connecté pour éditer des listes.",
    loginToFavorite: "Vous devez être connecté pour sauvegarder vos favoris.",
    logout: "Connectez - Out",
    mainPageText: "Activités d'apprentissage linguistique personnalisées pour n'importe quelle leçon",
    managedAccounts: "Comptes gérés",
    managedAccountsDescription:
        "Ajoutez des comptes gérés si vous souhaitez plusieurs abonnements sous un même abonnement. Des réductions sont offertes en fonction du nombre total de comptes.",
    maxFilesUploadAttempt: "Vous ne pouvez télécharger qu'un maximum de {0} fichiers à la fois.",
    membershipTypes: "Types d'adhésion",
    message: "Message",
    monthly: "Mensuel",
    monthlySubscription: "Souscription mensuelle",
    move: "Bouge toi",
    moveFolderTo: "Déplacer le dossier vers:",
    moveListTo: "Déplacer la liste vers:",
    multipleChoiceInstructions:
        "Avec les activités à choix multiples, que voulez-vous utiliser pour les réponses incorrectes?",
    multipleLanguageSupport: "Plusieurs langues prises en charge",
    mustHaveOneIncorrectAnswers: "Vous devez entrer au moins une réponse incorrecte.",
    myAccount: "Mon compte",
    myClasses: "Mes classes",
    myImages: "Mes images",
    myLists: "Mes listes",
    name: "prénom",
    negative: "Négatif",
    newFolder: "Nouveau dossier",
    nextBillingDate: "Date de facturation suivante: {0} ({1})",
    no: "Non",
    noAcceptableFilesFound: "Aucun fichier valide trouvé. Les fichiers sont limités à une taille maximale de {0} MB.",
    noCardOnFile: "Aucune carte au dossier",
    noCreditCardRequired: "Pas de carte de crédit nécessaire.",
    noRemainingCredits:
        "Aucun crédit restant ce mois-ci. Inscrivez-vous pour un compte premium pour un accès illimité.",
    none: "Aucun",
    notActivated: "Non activé",
    notActive: "Pas actif",
    notChargedUntilFormat: "Les cartes de crédit ne seront pas chargées avant le {0}.",
    notChargedUntilTrialComplete: "Votre carte de crédit ne sera pas débitée avant la fin de la période d’essai.",
    numberOfTeams: "Nombre d'équipes",
    off: "De",
    ok: "D'accord",
    on: "Sur",
    oneRemainingCredit: "Vous avez 1 crédit restant ce mois-ci.",
    or: "ou",
    pageNotFound: "Page non trouvée",
    password: "Mot de passe",
    passwordLengthError: "Le mot de passe doit être au moins de 6 caractères.",
    passwordRequiredError: "Mot de passe requis",
    passwordResetEmailHasBeenSendMessage:
        "S'il vous plaît vérifier votre email pour un lien pour réinitialiser votre mot de passe.",
    pay: "Payer",
    payBalance: "Solde",
    paymentMethod: "Mode de paiement",
    pendingSubscription: "Souscription en attente",
    play: "Jouer",
    playDescription: "Des jeux passionnants pour petites et grandes classes",
    pleaseAddCardForRestart: "Vous devez ajouter une carte de crédit avant de redémarrer votre abonnement.",
    portuguese: "Portugais",
    practice: "Entraine toi",
    practiceDescription: "Feuilles de travail interactives",
    premium: "Prime",
    premiumPriceFormatMonth: "{0} $ / mois",
    premiumPriceFormatYear: "{0} $ / an",
    premiumTrial: "Premium (essai)",
    preview: "Aperçu",
    price: "Prix",
    print: "Impression",
    printDescription: "Imprimez des cartes mémoire ou des feuilles de calcul pour vous entraîner hors ligne",
    printableFlashcards: "Printable Flashcards",
    private: "Privé",
    problemSubmittingContactForm: "Un problème est survenu lors de l'envoi de votre message.",
    problemUpdatingUserErrorMessage: "Une erreur s'est produite lors de la mise à jour des détails de l'utilisateur.",
    problemUsingCredit: "Un problème est survenu lors de l'utilisation de vos crédits.",
    profile: "Profil",
    proratedAmountDue: "Montant dû maintenant: {0}",
    public: "Publique",
    publicLists: "Listes communautaires",
    quellMessage: "Ne plus afficher ce message",
    question: "Question",
    questions: "Questions",
    randomize: "Randomiser",
    recent: "Récent",
    register: "registre",
    registerUsernamePassword: "S'inscrire avec nom d'utilisateur / mot de passe",
    rejectedFileFormat:
        "Le fichier {0} n'a pas été téléchargé. Le fichier était peut-être trop volumineux ou du mauvais type de fichier. Seuls les fichiers image de 500 Ko ou moins sont acceptés.",
    rejectedFilesFormat:
        "{0} fichiers n'ont pas été téléchargés. Les fichiers étaient peut-être trop volumineux ou du mauvais type de fichier. Seuls les fichiers image de 500 Ko ou moins sont acceptés.",
    remainingCreditsFormat: "Vous avez {0} crédits restants ce mois-ci.",
    removeFromFavorites: "Retirer des favoris",
    removeManagedUserAccountConfirmation:
        "Ce compte sera supprimé et l'utilisateur ne sera plus autorisé à se connecter. Êtes-vous sûr de vouloir supprimer définitivement ce compte?",
    resendEmail: "Ré-envoyer l'email",
    reset: "Réinitialiser",
    restartSubscription: "Redémarrer l'abonnement",
    restartSubscriptionMessage: "Veuillez sélectionner une fréquence de facturation pour votre abonnement.",
    review: "La revue",
    reviewDescription: "Jeux à choix multiples pour écouter, lire, écrire et parler",
    russian: "russe",
    sameAsTextField1Label: "Identique au champ de texte 1",
    sampleClass: "Identique au champ de texte 1",
    save: "sauvegarder",
    search: "Chercher",
    select: "Sélectionnez ...",
    selectACategory: "choisissez une catégorie",
    selectFolderToAddFavoriteTo: "Sélectionnez le dossier dans lequel ajouter le favori à:",
    selectStudentList: "Sélectionnez une liste d'étudiants",
    showCategoryDetails: "afficher les détails",
    signIn: "S'identifier",
    signInRegister: "Connexion / S'inscrire",
    signInUsernamePassword: "Connexion avec nom d'utilisateur / mot de passe",
    signUpForFree: "Inscription gratuite",
    signUpForFreeMessage: "Inscrivez-vous maintenant pour un compte Premium gratuit pendant 30 jours!",
    singleTeacher: "Professeur unique",
    singleText: "Seul champ de texte",
    singleTextDescription:
        "Ces listes sont idéales pour la lecture et l’orthographe. Ils sont aussi utile pour les activités qui ne nécessitent pas d'image.",
    siteImages: "Images du site",
    siteLists: "Listes de sites",
    sortByPopularity: "Popularité",
    sortByRecentlyCreated: "Récemment créé",
    sortByRelevance: "Pertinence",
    sortByTitle: "Titre",
    sortByTypeTitle: "Trier par",
    spanish: "Espanol",
    start: "Début",
    startSubscription: "Commencer l'abonnement",
    startSubscriptionAfterTrial:
        "Commencez votre abonnement pour continuer votre adhésion 'Premium' après le procès est terminé.",
    studentGroup: "groupe d'étudiants",
    studentGroups: "groupes d'étudiants",
    studentList: "Liste des étudiants",
    subject: "Assujettir",
    submit: "Submit",
    subscribe: "Souscrire",
    subscription: "Abonnement",
    subscriptionDescriptionFormat: "Type d'abonnement: {0}",
    subscriptionType: "Type d'abonnement",
    subtotal: "Total:",
    support: "Contact support",
    teacher: "prof",
    teacherCountFormat: "Nombre d'enseignants: {0}",
    teachers: "profs",
    teachingTools: "Outils pédagogiques",
    teamNumberFormat: "Equipe {0}",
    tenOrMore: "Dix ou plus",
    textField1Label: "(Question)",
    textField2Label: "(Réponse)",
    textToSpeechSupportFormat: "Audio clips automatically created using text-to-speech technology",
    thousandsOfHeader: "{{Milliers}} de catégories à choisir",
    thousandsOfText:
        "Vous ne voulez pas créer vos propres catégories? Parcourir à travers des milliers créés par la communauté KinteractiveLearning. Plus sont ajoutés tous les jours!",
    threeOrMore: "Trois ou plus",
    titleCannotBeEmptyInfoMessage: "Le titre ne peut être vide. S'il vous plaît entrer un titre.",
    tools: "Outils",
    toolsDescription: "Group and team makers, random student selector, stopwatch, and scoreboard",
    totalDue: "Total Due:",
    totalManagedAccounts: "Total des comptes gérés:",
    totalStudentsFormat: "{0} étudiants",
    turkish: "turc",
    twoTextOnly: "Deux champs de texte",
    twoTextOnlyDescription:
        "Ces listes sont utilisées pour poser des questions qui ne nécessitent pas et image, ou pour traduire entre deux langues",
    type: "Type",
    unlimited: "Illimité",
    update: "Mettre à jour",
    updateEmailError: "Une erreur s'est produite lors de la mise à jour de l'adresse e-mail.",
    uploadImages: "Importer des images",
    uploadImagesDropzoneInstructions: "Drop image(s), or click to browse your computer.",
    uploadMessage: "Chargement d'images {0}.",
    uploadProfileImageError: "Une erreur s'est produite lors du téléchargement de l'image de profil.",
    useAsVocabulary: "Ajouter à la liste de vocabulaire",
    useCredit: "Utiliser le crédit",
    useOtherAnswersAsIncorrect: "Utilisez d'autres cartes réponses correctes",
    username: "Nom d'utilisateur",
    usernameOptional: "Nom d'utilisateur (facultatif)",
    usernameRequiredError: "Nom d'utilisateur est nécessaire.",
    usernameTaken: "Nom d'utilisateur déjà pris",
    usernameUnavailable: "Le nom d'utilisateur n'est pas disponible",
    vocabulary: "Vocabulaire",
    worksheetCreators: "Créateurs de feuilles de calcul",
    worksheets: "Feuilles de travail",
    yes: "Oui",
    page: "",
    misses: "",
    backToWorksheets: "",
    options: "",
    randomizeAndReset: "",
    introduce: "",
    quiz: "",
    changeCategory: "",
    interactive: "",
    printable: "",
    createSelectCategoryDescription: "",
    removeAllLetters: "",
    allowCardsToBeFlipped: "",
    selectCategoryBeforeActivity: "",
    getStarted: "",
    requiresFlash: "",
    onlySupportsLatinBasedLanguages: "",
    upgradeToPremium: "",
    startReview: "",
    numberOfGroups: "",
    hideAnswer: "",
    autoPlayAudio: "",
    imageBank: "",
    doubleSidedCards: "",
    ignoreFlippedCardsForReview: "",
    soft: "",
    medium: "",
    strong: "",
    continuePlaying: "",
    languageNotSupported: "",
    createLink: "",
    pinActivityToDashboard: "",
    enterName: "",
    createStudentLink: "",
    expiration: "",
    oneDay: "",
    oneWeek: "",
    never: "",
    link: "",
    copyToClipboard: "",
    pinnedActivities: "",
    studentActivityLinks: "",
    numberOfCards: "",
    matchType: "",
    imageType: "",
    otherOptions: "",
    wordImage: "",
    imageImage: "",
    wordWord: "",
    activity: "",
    seeLinkVisitors: "",
    recentHistory: "",
    startTime: "",
    completedTime: "",
    results: "",
    iDontKnow: "",
    iKnowTheAnswer: "",
    seconds: "",
    minutes: "",
    hours: "",
    pause: "",
    set: "",
    audioHeading: "",
    playAudioOnCorrect: "",
    playAudioOnWrong: "",
    answerTypeHeading: "",
    multipleChoiceAnswerTypeOption: "",
    rightOrWrongAnswerTypeOption: "",
    showNumbersHeading: "",
    showNumbersMultipleChoice: "",
    timerHeading: "",
    showTimerMultipleChoice: "",
    game: "",
    correct: "",
    wrong: "",
    teams: "",
    imagesInGrid: "",
    multipleChoiceOptions: "",
    playAudioOnSelection: "",
    classicModeOptions: "",
    selectAll: "",
    flipCardsToFront: "",
    flipCardsToBack: "",
    shuffle: "",
    showAllCards: "",
    bonusRound: "",
    crazyCupsIntroMessage: "",
    crazyCupsBonusRoundMessage: "",
    goodJob: "",
    sorryYouMissedIt: "",
    teamReadyFormat: "",
    readyToTryAgain: "",
    cupsSpeedAdjustment: "",
    textOnly: "",
    imageText: "",
    imageOnly: "",
    hideShips: "",
    useClassicMode: "",
    classicModeDescription: "",
    stop: "",
    bullsEye: "",
    removeNameOnceSelected: "",
    clearDartAfterThrow: "",
    stopBoardAfterThrow: "",
    playSoundEffect: "",
    spinForPoints: "",
    showImageProfileImage: "",
    cardType: "",
    gridSize: "",
    cardsPerPage: "",
    noListsGetStarted: "",
    noImagesGetStarted: "",
    isTraceable: "",
    twoColumns: "",
    randomizeWorksheet: "",
    createPDF: "",
    fontSize: "",
    goodJobReadyForNext: "",
    whoGoesFirst: "",
    teamGoesFirstFormat: "",
    popBalloonsWith: "",
    dartMeterSpeed: "",
    fontStyle: "",
    cardSensitivity: "",
    points: "",
    hotOrColdInstructions: "",
    inviteUsers: "",
    availableInvitesFormat: "",
    numberOfAccountsToAdd: "",
    removeSubscriptionManagerConfirmation: "",
    managedSubscription: "",
    disconnectFromAccountManager: "",
    confirmToBeManagedConfirmationFormat: "",
    pendingManagerNotFound: "",
    steal: "",
    newWord: "",
    canYouSpell: "",
    wouldLikeToStealFormat: "",
    selectSecondCategory: "",
    clearSecondCategory: "",
    borderThickness: "",
    borderColor: "",
    subscriptionIsPastDue: "",
};
