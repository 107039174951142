export class Fonts {
    public static Roboto: string = "Roboto";
    public static BalsamiqSans: string = "Balsamiq Sans";
    public static RobotoMono: string = "Roboto Mono";
    public static Archivo: string = "Archivo";
    public static LuckiestGuy: string = "Luckiest Guy";
    public static CenturyGothic: string = "Century Gothic, Futura, sans-serif";
    public static KawaiiFood: string = "KawaiiFood";
    public static GoogleEmojis: string = "GoogleEmojis";
    public static CuteIcons: string = "CuteIcons";
}
