import * as React from "react";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import RootStore from "../stores/RootStore";

import { Colors } from "../core/utils/Colors";
import { strings } from "../core/localizedStrings";

const googleIcon = require("../assets/images/googleIcon.jpg");
const facebookIcon = require("../assets/images/facebookIcon.png");
const twitterIcon = require("../assets/images/twitterIcon.png");

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

export interface AuthProviderButtonsProps extends React.Props<any> {
    providerButtonPrefix: string;
    rootStore: RootStore;
}

let style = {
    color: Colors.white,
    width: "100%",
    minWidth: 100,
    marginBottom: 5,
    justifyContent: "left",
};
const styles = {
    googleButton: Object.assign({ backgroundColor: Colors.googleBlue }, style),
    facebookButton: Object.assign({ backgroundColor: Colors.facebookBlue }, style),
    twitterButton: Object.assign({ backgroundColor: Colors.twitterBlue }, style),
    icon: { width: 25, height: 25, marginRight: 15 },
};

export default class AuthProviderButtons extends React.Component<AuthProviderButtonsProps, {}> {
    render() {
        return (
            <Col xs={12}>
                <Row center="xs">
                    <Col>
                        <Row center="xs">
                            <Typography variant="body1">
                                {this.props.providerButtonPrefix.toUpperCase()}
                                {strings.existingAccount.toUpperCase()}
                            </Typography>
                        </Row>
                        <Row style={{ paddingTop: 25 }}>
                            <Button style={styles.googleButton} onClick={this.onSignInWithGoogle}>
                                <Avatar style={styles.icon} src={googleIcon} />
                                {`${this.props.providerButtonPrefix} Google`}
                            </Button>
                        </Row>
                        {/* <Row>
                            <Button style={styles.facebookButton} onClick={this.onSignInWithFacebook}>
                                <Avatar style={styles.icon} src={facebookIcon} />
                                {`${this.props.providerButtonPrefix} Facebook`}
                            </Button>
                        </Row>
                        <Row>
                            <Button style={styles.twitterButton} onClick={this.onSignInWithTwitter}>
                                <Avatar style={styles.icon} src={twitterIcon} />
                                {`${this.props.providerButtonPrefix} Twitter`}
                            </Button>
                        </Row> */}
                    </Col>
                </Row>
            </Col>
        );
    }

    private onSignInWithGoogle = () => {
        this.props.rootStore.authStore.signInWithGoogle();
    };

    private onSignInWithFacebook = () => {
        this.props.rootStore.authStore.signInWithFacebook();
    };

    private onSignInWithTwitter = () => {
        this.props.rootStore.authStore.signInWithTwitter();
    };
}
