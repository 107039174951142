import * as React from "react";
import { Observer } from "mobx-react-lite";

// Material UI
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";

// Stores
import { ActivityStore } from "stores";

// Layout
import { Row, Col } from "layout";
import { AutoPlayAudioCheckbox } from "components/common/CommonOptions";
import { strings } from "core/localizedStrings";
import { FontSizeButtons } from "components/activities/CommonActivityOptions";
import { OptionsGroup } from "components/activities/OptionsGroup";

export enum CardMatchType {
    IMAGE_IMAGE = "imageImage",
    WORD_WORD = "wordWord",
    IMAGE_WORD = "imageWord",
}

export enum NumberOfCardsType {
    EIGHT = "8",
    SIXTEEN = "16",
    TWENTY_FOUR = "24",
}

export const defaultCardMatchOptions: CardMatchOptions = {
    matchType: CardMatchType.IMAGE_WORD,
    numberOfCards: NumberOfCardsType.SIXTEEN,
    fontSizeMultiplier: 1,
};

export interface CardMatchOptions {
    matchType: CardMatchType;
    numberOfCards: NumberOfCardsType;
    fontSizeMultiplier: number;
}

interface CardMatchOptionsProps extends React.Props<any> {
    activityStore: ActivityStore;
}

const CardMatchOptions = (props: CardMatchOptionsProps) => {
    const handleMatchTypeChanged = (event: any, value: any) => {
        props.activityStore.activityOptions.cardMatchOptions.matchType = value;
    };

    const handleNumberOfCardsChanged = (event: any, value: any) => {
        props.activityStore.activityOptions.cardMatchOptions.numberOfCards = value;
    };

    const handleAutoPlayAudioChanged = (event: any, value: any) => {
        props.activityStore.activityOptions.quizOptions.autoPlayEnabled = value;
    };

    const handleFontSizeDecremented = () => {
        const MIN_FONT_SIZE = 0.5;
        props.activityStore.activityOptions.cardMatchOptions.fontSizeMultiplier -= 0.1;
        if (props.activityStore.activityOptions.cardMatchOptions.fontSizeMultiplier < MIN_FONT_SIZE) {
            props.activityStore.activityOptions.cardMatchOptions.fontSizeMultiplier = MIN_FONT_SIZE;
        }
    };

    const handleFontSizeIncremented = () => {
        const MAX_FONT_SIZE = 3;
        props.activityStore.activityOptions.cardMatchOptions.fontSizeMultiplier += 0.1;
        if (props.activityStore.activityOptions.cardMatchOptions.fontSizeMultiplier > MAX_FONT_SIZE) {
            props.activityStore.activityOptions.cardMatchOptions.fontSizeMultiplier = MAX_FONT_SIZE;
        }
    };

    return (
        <Observer
            render={() => (
                <Col xs={true}>
                    <OptionsGroup>
                        <Typography variant="subtitle2">{strings.matchType}</Typography>
                        <Row>
                            <FormControl>
                                <RadioGroup
                                    name="cardMatchType"
                                    value={props.activityStore.activityOptions.cardMatchOptions.matchType}
                                    onChange={handleMatchTypeChanged}
                                >
                                    <FormControlLabel
                                        style={{ marginTop: -5 }}
                                        value={CardMatchType.IMAGE_WORD}
                                        control={<Radio />}
                                        label={strings.wordImage}
                                    />
                                    <FormControlLabel
                                        style={{ marginTop: -20 }}
                                        value={CardMatchType.IMAGE_IMAGE}
                                        control={<Radio />}
                                        label={strings.imageImage}
                                    />
                                    <FormControlLabel
                                        style={{ marginTop: -20 }}
                                        value={CardMatchType.WORD_WORD}
                                        control={<Radio />}
                                        label={strings.wordWord}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Row>
                        <Typography variant="subtitle2">{strings.numberOfCards}</Typography>
                        <Row>
                            <FormControl>
                                <RadioGroup
                                    name="numberOfCards"
                                    value={props.activityStore.activityOptions.cardMatchOptions.numberOfCards}
                                    onChange={handleNumberOfCardsChanged}
                                >
                                    <FormControlLabel
                                        style={{ marginTop: -5 }}
                                        value={NumberOfCardsType.EIGHT}
                                        control={<Radio />}
                                        label={NumberOfCardsType.EIGHT}
                                    />
                                    <FormControlLabel
                                        style={{ marginTop: -20 }}
                                        value={NumberOfCardsType.SIXTEEN}
                                        control={<Radio />}
                                        label={NumberOfCardsType.SIXTEEN}
                                    />
                                    <FormControlLabel
                                        style={{ marginTop: -20 }}
                                        value={NumberOfCardsType.TWENTY_FOUR}
                                        control={<Radio />}
                                        label={NumberOfCardsType.TWENTY_FOUR}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Row>
                        <Row style={{ paddingBottom: 10, paddingTop: 5 }}>
                            <AutoPlayAudioCheckbox
                                isChecked={props.activityStore.activityOptions.quizOptions.autoPlayEnabled}
                                onChange={handleAutoPlayAudioChanged}
                            />
                        </Row>
                    </OptionsGroup>
                    <Row>
                        <FontSizeButtons
                            onFontSizeDecremented={handleFontSizeDecremented}
                            onFontSizeIncremented={handleFontSizeIncremented}
                        />
                    </Row>
                </Col>
            )}
        />
    );
};

export default CardMatchOptions;
