import { StudentList, Student, StudentGender } from "models";
import { strings } from "core/localizedStrings";

export default class SampleStudentListCreator {
    static create() {
        let studentData: any[] = [
            ["Sally", "girl1", StudentGender.Girl, false],
            ["Jim", "boy1", StudentGender.Boy, false],
            ["Mike", "boy2", StudentGender.Boy, false],
            ["Jennifer", "girl2", StudentGender.Girl, false],
            ["Tony", "boy3", StudentGender.Boy, false],
            ["Elizabeth", "girl3", StudentGender.Girl, true],
            ["Jack", "boy4", StudentGender.Boy, false],
            ["Mary", "girl4", StudentGender.Girl, false],
            ["Linda", "girl5", StudentGender.Girl, false],
            ["Eric", "boy5", StudentGender.Boy, false]
        ];

        let students: Student[] = studentData.map(data => {
            let image = `https://storage.googleapis.com/kinteractivelearning.appspot.com/profileImages/sampleStudents/${
                data[1]
            }.png`;
            let student = new Student();
            student.name = data[0];
            student.key = data[1];
            student.profileImage = image;
            student.gender = data[2];
            student.isAbsent = data[3];

            return student;
        });

        let studentList: StudentList = new StudentList({
            deleted: false,
            title: strings.sampleClass,
            searchTitle: strings.sampleClass.toLocaleLowerCase(),
            absentStudentCount: 1,
            totalStudentCount: 10
        });
        studentList.key = "sample";
        studentList.students = students;
        return studentList;
    }
}
