import { log } from "../../";

import * as React from "react";
import { action, observable } from "mobx";
import { observer, inject } from "mobx-react";

// MaterialUI
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

// Components
import SubscriptionBlock from "./SubscriptionBlock";
import PaymentMethodsBlock from "./PaymentMethodsBlock";
import { UpdateProfileForm } from "./UpdateProfileForm";
import ManagedUserCard from "./ManagedUserCard";
import { PageNotFound } from "../../components/PageNotFound";
import ManagedAccountsInfoDialog from "./ManagedAccountsInfoDialog";

// Stores
import RootStore from "../../stores/RootStore";

// Modles
import { ManagedUser, SubscriptionDetailsStatus } from "../../models";

// Utils
import { strings } from "../../core/localizedStrings";
import * as Icons from "../../core/utils/Icons";

// Styles
import { GlobalStyles } from "../../styles/GlobalStyles";
import NavigationDrawer from "components/navigation/NavigationDrawer";
import { InviteManagedUserForm } from "./InviteManagedUserForm";
import AddManagedUsersForm from "./AddManagedUsersForm";
import { Grid } from "@material-ui/core";
import { PendingManagedUser } from "models/User";
import ActivityLinkBlock from "routes/home/dashboard/ActivityLinkBlock";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

export interface MyAccountProps extends React.Props<any> {
    rootStore: RootStore;
}

const styles = {
    dataRow: {
        margin: "5px 0 5px 0",
    },
};

@inject("rootStore")
@observer
export class MyAccount extends React.Component<MyAccountProps> {
    @observable private showManagedAccountsInfo = false;
    @observable private showAddManagedUserDialog = false;
    @observable private showInviteManagedUserDialog = false;

    render() {
        if (!this.props.rootStore.userStore || !this.props.rootStore.userStore.user) {
            return null;
        } else if (!this.props.rootStore.userStore.user.isAnonymous) {
            return (
                <>
                    <NavigationDrawer rootStore={this.props.rootStore} />
                    <Paper style={GlobalStyles.paper}>
                        <Typography style={GlobalStyles.pageHeading}>{strings.myAccount}</Typography>
                        <Row>
                            <Col xs={12} style={{ paddingBottom: 30, minHeight: 100 }}>
                                {this.renderProfileBlock()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} style={{ paddingBottom: 40, minHeight: 100 }}>
                                <SubscriptionBlock store={this.props.rootStore.userStore} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} style={{ paddingBottom: 40, minHeight: 100 }}>
                                <PaymentMethodsBlock store={this.props.rootStore.userStore} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>{this.renderManagedAccountsBlock()}</Col>
                        </Row>
                        <Row>
                            <ActivityLinkBlock rootStore={this.props.rootStore} />
                        </Row>
                    </Paper>
                    {this.renderDialogs()}
                </>
            );
        } else {
            return <PageNotFound />;
        }
    }

    @action
    private onUpdateProfile = () => {
        this.props.rootStore.userStore.userToEdit = this.props.rootStore.userStore.user;
    };

    @action
    private onProfileFormClose = () => {
        this.props.rootStore.userStore.userToEdit = undefined;
    };

    @action
    private onAddStudentAccount = () => {
        let customerDetails = this.props.rootStore.userStore.customerDetails;
        if (
            customerDetails.subscription &&
            (customerDetails.accountExpired || customerDetails.subscription.cancelAtPeriodEnd)
        ) {
            this.props.rootStore.showInfoMessage({ text: strings.currentSubscriptionRequiredForManagedAccounts });
        }
        // else if (this.props.rootStore.userStore.user && this.props.rootStore.userStore.user.isTrial) {
        //     this.props.rootStore.showInfoMessage({ text: strings.cannotAddManagedAccountsInTrial });
        // }
        else {
            this.showAddManagedUserDialog = true;
        }
    };

    @action
    private onManagedAccountsInfoClicked = () => {
        this.showManagedAccountsInfo = true;
    };

    @action
    private onManagedAccountsInfoClosed = () => {
        this.showManagedAccountsInfo = false;
    };

    private renderProfileBlock() {
        return (
            <div>
                <Row middle="xs">
                    <Col>
                        <Typography variant="h5">{strings.profile}</Typography>
                    </Col>
                    <Col>
                        <IconButton onClick={this.onUpdateProfile}>
                            <Icons.Edit />
                        </IconButton>
                    </Col>
                </Row>
                <Divider style={{ marginRight: 40 }} />
                <Typography variant="subtitle1">{this.renderProfileDetails()}</Typography>
            </div>
        );
    }

    private renderProfileDetails() {
        return (
            <Row middle="xs" style={{ paddingTop: 10 }}>
                <Col xs={1}>
                    <Row middle="xs" center="xs">
                        <Avatar src={this.props.rootStore.userStore.user.profileImageDownloadURL} />
                    </Row>
                </Col>
                <Col xs={11} style={{ paddingLeft: 10 }}>
                    <Row style={styles.dataRow} top="xs">
                        {this.props.rootStore.userStore.user.username}
                    </Row>
                    <Row style={styles.dataRow} top="xs">
                        {this.props.rootStore.userStore.user.email}
                    </Row>
                </Col>
            </Row>
        );
    }

    private renderManagedAccountsBlock() {
        if (
            !this.props.rootStore.userStore.customerDetails ||
            !this.props.rootStore.userStore.customerDetails.subscription ||
            this.props.rootStore.userStore.subscriptionDetailStatus === SubscriptionDetailsStatus.Updating ||
            this.props.rootStore.userStore.user instanceof ManagedUser ||
            this.props.rootStore.userStore.subscriptionDetailStatus === SubscriptionDetailsStatus.NotLoaded
        ) {
            return null;
        }

        let { managedUsers } = this.props.rootStore.userStore.user;
        let managedUsersQuantity = this.props.rootStore.userStore.customerDetails?.subscription?.quantity - 1;

        return (
            <Grid container direction="column" alignItems="flex-start">
                <Grid item>
                    <Grid container alignItems="center">
                        <Typography variant="h5">{strings.managedAccounts}</Typography>
                        <IconButton onClick={this.onManagedAccountsInfoClicked}>
                            <Icons.Info />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item style={{ height: 5 }}>
                    <Divider style={{ marginRight: 40 }} />
                </Grid>
                <Grid item style={{ paddingLeft: 10 }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            this.onAddStudentAccount();
                        }}
                    >
                        {`+ ${strings.addTeacherAccount}`}
                    </Button>
                </Grid>
                {this.renderManagedUsersCards()}
                <Grid item style={{ paddingBottom: 20 }}>
                    {this.props.rootStore.userStore.isManagedTeacherLoading && <CircularProgress />}
                </Grid>
                <Grid item style={{ paddingLeft: 10 }}>
                    {!this.props.rootStore.userStore.isManagedTeacherLoading &&
                        managedUsers.length < managedUsersQuantity && (
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            this.showInviteManagedUserDialog = true;
                                        }}
                                    >
                                        {strings.inviteUsers}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        {strings.formatString(
                                            strings.availableInvitesFormat,
                                            managedUsersQuantity - managedUsers.length
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                </Grid>
            </Grid>
        );
    }

    private renderManagedUsersCards() {
        return this.props.rootStore.userStore.user.managedUsers.map(
            (managedTeacher: ManagedUser | PendingManagedUser, index) => {
                return (
                    <Grid item style={{ width: "100%" }} key={index}>
                        <ManagedUserCard store={this.props.rootStore.userStore} user={managedTeacher} />
                    </Grid>
                );
            }
        );
    }

    private renderDialogs() {
        return (
            <>
                <Dialog
                    open={this.props.rootStore.userStore.userToEdit !== undefined}
                    fullWidth={true}
                    onClose={this.onProfileFormClose}
                >
                    <UpdateProfileForm
                        title={strings.editProfile}
                        imageProfileUploadProgress={this.props.rootStore.userStore.imageProfileUploadProgress}
                        user={this.props.rootStore.userStore.userToEdit}
                        onImageDroppedCallback={this.props.rootStore.userStore.addProfileImage}
                        onComplete={this.onProfileFormClose}
                        rootStore={this.props.rootStore}
                    />
                </Dialog>
                <Dialog
                    open={this.showInviteManagedUserDialog}
                    fullWidth={true}
                    onClose={() => {
                        this.showInviteManagedUserDialog = false;
                    }}
                >
                    <InviteManagedUserForm
                        title={strings.addAccount}
                        onComplete={() => {
                            this.showInviteManagedUserDialog = false;
                        }}
                        rootStore={this.props.rootStore}
                    />
                </Dialog>
                <Dialog
                    open={this.showAddManagedUserDialog}
                    onClose={() => {
                        this.showAddManagedUserDialog = false;
                    }}
                >
                    <AddManagedUsersForm
                        customerDetails={this.props.rootStore.userStore.customerDetails}
                        onClose={() => {
                            this.showAddManagedUserDialog = false;
                        }}
                        onUpdated={(quantity: number) => {
                            this.props.rootStore.userStore.calculateSubscriptionAmountDue(quantity);
                        }}
                        rootStore={this.props.rootStore}
                    />
                </Dialog>
                <ManagedAccountsInfoDialog
                    showDialog={this.showManagedAccountsInfo}
                    onCloseCallback={this.onManagedAccountsInfoClosed}
                />
            </>
        );
    }
}
