import { BillingFrequency } from "../payments/StripeCustomerDetails";
import { AudioClipVoice } from "../../models";
import { AudioClip } from "../../models/customLists/AudioClip";

export enum SubscriptionRequestType {
    KeepAlive = "keepAlive",
    CreateSubscription = "createSubscription",
    DeleteCustomer = "deleteCustomer",
    GetCustomerDetails = "getCustomerDetails",
    CalculateAmountDue = "calculateAmountDue",
    Subscribe = "subscribe",
    CancelSubscription = "cancelSubscription",
    RestartSubscription = "restartSubscription",
    AddCard = "addCard",
    DeleteCard = "deleteCard",
    UpdateCard = "updateCard",
}

export enum UserRequestType {
    KeepAlive = "keepAlive",
    SendContactFormEmail = "sendContactFormEmail",
    IsUsernameAvailable = "isUsernameAvailable",
    IsEmailAvailable = "isEmailAvailable",
    AddManagedUser = "addManagedUser",
    DeleteManagedUser = "deleteManagedUser",
    DeletePendingManagedUser = "deletePendingManagedUser",
    FindUnknownUser = "findUnknownUser",
    FindUsersMysqlID = "findUsersMysqlID",
    GetCreatedDate = "getCreatedDate",
    MigrateBarryFunUser = "migrateBarryFunUser",
    UseCredit = "useCredit",
    Masquerade = "masqueradeAsUser",
    VerifyEmail = "verifyEmail",
    UpdateEmail = "updateEmail",
}

export enum MediaRequestType {
    KeepAlive = "keepAlive",
    GetAudioClip = "getAudioClip",
    UpdateAudioClipToGoogle = "updateAudioClipToGoogle",
    UpdateCategoryToGoogleAudio = "updateCategoryToGoogleAudio",
    RetryCategoryMigration = "retryCategoryMigration",
}

export enum SearchRequestType {
    KeepAlive = "keepAlive",
    QueryElasticsearch = "queryElasticsearch",
}

export enum TranslationRequestType {
    TranslateCategories = "translateCategories",
}

export interface HttpRequest {
    type:
        | SubscriptionRequestType
        | UserRequestType
        | MediaRequestType
        | SearchRequestType
        | TranslationRequestType
        | RetryCategoryRequest;
    requestData: any;
}

export class CreateSubscriptionRequest {
    public customerID?: string;
    public uid: string;
    public email: string;
    public stripeToken: string;
    public billingFrequency: BillingFrequency;
    public expirationTimestamp: number;
    public quantity: number;
}

export class DeleteCustomerRequest {
    public uid: string;
    public customerID: string;
}

export interface SubscribeRequest {
    uid: string;
    customerID: string;
    subscriptionID: string;
    billingFrequency: BillingFrequency;
    quantity: number;
    amountDue: number;
}

export interface SearchRequest {
    type: "categories" | "images";
    query: any;
}

export interface TranslationRequest {
    languages: [string];
}

export interface FetchAudioRequest {
    text: string;
    language: AudioClipVoice;
}

export interface UpdateAudioClipToGoogleRequest {
    audioClip: AudioClip;
}

export interface UpdateCategoryToGoogleAudioRequest {
    categoryKey: string;
}

export interface RetryCategoryRequest {
    listID: number;
}
