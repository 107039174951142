import * as React from "react";
import { Colors } from "../core/utils/Colors";
import { DEFAULT_SHADOWBOX_INDEX } from "layers";

export interface ShadowboxProps extends React.Props<any> {
    backgroundOpacity?: number;
    backgroundColor?: string;
    style?: any;
    zIndex?: number;
    onBackgroundClicked?: () => void;
}

const Shadowbox = (props: ShadowboxProps) => {
    const DEFAULT_OPACITY = 0.9;
    let style = {
        width: "100%",
        height: "100%",
        backgroundColor: props.backgroundColor
            ? props.backgroundColor
            : Colors.rgba(50, 50, 50, props.backgroundOpacity ? props.backgroundOpacity : DEFAULT_OPACITY),
        textAlign: "center",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: props.zIndex ? props.zIndex : DEFAULT_SHADOWBOX_INDEX,
    } as React.CSSProperties;

    const handleClick = (event) => {
        if (event.target !== event.currentTarget) {
            return;
        }
        if (event.currentTarget.id === "background") {
            if (props.onBackgroundClicked) {
                props.onBackgroundClicked();
            }
        }
    };

    return (
        <div id="background" style={Object.assign(style, props.style)} onClick={handleClick}>
            {props.children}
        </div>
    );
};

export default Shadowbox;
