import { createMuiTheme } from "@material-ui/core/styles";
import { Colors } from "./core/utils/Colors";

// Material-UI Theme
export const muiTheme = createMuiTheme({
    typography: {
        fontSize: 13,
    },
    palette: {
        primary: {
            main: Colors.lightBlue,
            contrastText: Colors.white,
        },
        secondary: {
            main: Colors.white,
        },
    },
    overrides: {
        MuiCheckbox: {
            colorSecondary: {
                "&$checked": {
                    color: Colors.lightBlue,
                },
            },
        },
        MuiRadio: {
            colorSecondary: {
                "&$checked": {
                    color: Colors.lightBlue,
                },
            },
        },
        MuiTab: {
            textColorSecondary: {
                color: Colors.lightGrey,
                "&$selected": {
                    color: Colors.white,
                },
            },
        },
        MuiTabs: { root: { backgroundColor: Colors.lightBlue } },
        MuiSvgIcon: {
            colorSecondary: {
                color: Colors.white,
            },
        },
        MuiDialog: { paperFullScreen: { backgroundColor: Colors.rgba(0, 0, 0, 0.6) } },
    },
});
