import * as React from "react";
import { Provider } from "mobx-react";
import { Route, BrowserRouter as Router } from "react-router-dom";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { muiTheme } from "./theme";

import RootStore from "./stores/RootStore";

// components
import App from "./App";

interface RootProps {
    // onEnter: () => void;
    store: RootStore;
}

export default function Root(props: RootProps) {
    return (
        <MuiThemeProvider theme={muiTheme}>
            <Provider rootStore={props.store}>
                <Router>
                    <Route component={App} path="/" />
                </Router>
            </Provider>
        </MuiThemeProvider>
    );
}
