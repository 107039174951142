import * as React from "react";
import {
    makeStyles,
    Theme,
    createStyles,
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    DialogTitle,
} from "@material-ui/core";
import { strings } from "core/localizedStrings";
import { ActivityType } from "models/activities/Activity";
import { ActivityStore } from "stores";
import withWidth from "@material-ui/core/withWidth";
import { ActivityOptionsFactory } from "./ActivityOptionsFactory";

export interface ActivityOptionsDialogProps extends React.Props<any> {
    activityStore: ActivityStore;
    activityType: ActivityType;
    customOptions: JSX.Element;
    open: boolean;
    onClose: () => void;
    width?: string;
}

const ActivityOptionsDialog = (props: ActivityOptionsDialogProps) => {
    const { width } = props;

    let gameOptions = ActivityOptionsFactory.get(props.activityStore);
    return (
        <Dialog open={props.open} disableBackdropClick={true} fullScreen={width === "xs"}>
            <DialogTitle>{strings.options}</DialogTitle>
            <DialogContent>
                <Grid container direction="column">
                    {gameOptions && (
                        <Grid item style={{ minWidth: width === "xs" ? 0 : 550, minHeight: 300 }}>
                            {gameOptions}
                        </Grid>
                    )}
                    <Grid item style={{ paddingTop: 10 }}>
                        {props.customOptions}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{strings.ok}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default withWidth()(ActivityOptionsDialog);
