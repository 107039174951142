import * as React from "react";

// Material UI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// Components
import SimpleMenu from "../common/SimpleMenu";

// Stores
import { RootStore } from "stores";

// Models
import { Languages, LanguageType } from "../../models";

// Utils
import { localizedLanguages } from "models/Languages";
import { IconButton } from "@material-ui/core";

export interface SiteLangaugeMenuProps extends React.Props<any> {
    style?: any;
    rootStore: RootStore;
    onChangedCallback: (language: LanguageType) => void;
}

export default class SiteLangaugeMenu extends React.Component<SiteLangaugeMenuProps> {
    render() {
        return (
            <div style={this.props.style}>
                <SimpleMenu displayComponent={this.renderDisplayComponent()}>{this.renderOptions()}</SimpleMenu>
            </div>
        );
    }

    private renderDisplayComponent() {
        return <IconButton>{this.renderItem(this.props.rootStore.siteLanguage)}</IconButton>;
    }

    private renderOptions() {
        var content = [];
        content.push(<List key={0} component="nav" />);
        content.push(
            localizedLanguages.map((language: LanguageType, index) => {
                return (
                    <ListItem
                        key={index + 1}
                        button={true}
                        onClick={this.onClassListOptionChanged.bind(this, language)}
                    >
                        {this.renderItem(language)}
                    </ListItem>
                );
            })
        );
        return content;
    }

    private renderItem(language: LanguageType) {
        return <img style={{ height: 20 }} src={Languages.imageForLanguage(language)} />;
    }

    private onClassListOptionChanged = (language: LanguageType) => {
        this.props.onChangedCallback(language);
    };
}
