import { observable, action, computed } from "mobx";

// Stores
import { RootStore } from "stores";

// Models
import { TabSelection, SortByType, LanguageType, CategoryType, Folder } from "models";
import CategoryFolder from "./CategoryFolder";
import { ListItem } from "models/Folder";

export const PAGE_SIZE = 40;

export default class SearchResultFolder implements CategoryFolder {
    tabType: TabSelection;
    isVisible: boolean = false;
    sortByType = SortByType.RecentlyCreated;
    languageFilter: LanguageType;
    listTypeFilter: CategoryType;
    searchText: string;
    lastIndexFetched = -1;

    @observable totalHits: number;
    @observable currentFolder: Folder;
    @observable breadcrumbs: Folder[];
    @observable isLoading: boolean = false;
    @observable isQuerying: boolean = false;
    @observable currentFolderKey: string;

    rootStore: RootStore;

    constructor(tab: TabSelection, rootStore: RootStore) {
        this.tabType = tab;
        this.rootStore = rootStore;
    }

    @computed
    get visibleItems(): ListItem[] {
        let allItems: ListItem[] = [];
        if (this.currentFolder !== undefined) {
            allItems.push(...this.currentFolder.items);
        }
        return allItems;
    }

    @action
    setIsQuerying(value: boolean) {
        this.isQuerying = value;
    }

    @action
    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @action
    setTotalHits(value: number) {
        this.totalHits = value;
    }

    reset() {
        this.lastIndexFetched = -1;
        this.isLoading = false;
        this.isQuerying = false;
        this.totalHits = 0;
    }

    // Search
    // ----------------

    updateIfNecessary() {
        console.log("TODO");
    }

    checkShouldFetchMore() {
        // Implemented in subclass
    }

    async search(text: string) {
        // Implemented in subclass
    }
}
