import * as React from "react";
import { inject, observer } from "mobx-react";

import RootStore from "stores/RootStore";
import TeamMaker from "components/activities/tools/teamMaker/TeamMaker";
import ActivityOptionsBar from "../ActivityOptionsBar";

interface GroupMakerProps extends React.Props<any> {
    rootStore: RootStore;
    location?: any;
}

@inject("rootStore")
@observer
export default class GroupMaker extends React.Component<GroupMakerProps> {
    componentDidMount() {
        let { activityStore } = this.props.rootStore;
        if (activityStore && !activityStore.currentStudentList) {
            activityStore.setShouldShowStudentListSelector(true);
        }
    }

    componentWillUpdate(newProps: GroupMakerProps) {
        let { activityStore } = newProps.rootStore;
        if (activityStore && !activityStore.currentStudentList) {
            activityStore.setShouldShowStudentListSelector(true);
        }
    }

    render() {
        if (!this.props.rootStore || !this.props.rootStore.activityStore) {
            return null;
        }

        return (
            <>
                <ActivityOptionsBar
                    rootStore={this.props.rootStore}
                    activity={this.props.rootStore.activityStore.currentActivity}
                />
                <TeamMaker isGroupMaker={true} />
            </>
        );
    }
}
