import * as React from "react";

// Material UI
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export interface SimpleCheckboxProps extends React.Props<any> {
    isChecked: boolean;
    label?: string;
    isDisabled?: boolean;
    onChange: (value: any) => void;
}

const SimpleCheckbox = (props: SimpleCheckboxProps) => {
    const handleChange = (event: any, value: boolean) => {
        props.onChange(value);
    };

    return (
        <div style={{ marginTop: -10 }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={props.isChecked}
                        disabled={props.isDisabled != undefined && props.isDisabled}
                        onChange={handleChange}
                    />
                }
                label={props.label}
            />
        </div>
    );
};

export default SimpleCheckbox;
