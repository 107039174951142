import * as React from "react";
import { Observer } from "mobx-react-lite";
import { ActivityStore } from "stores";
import SimpleRadio from "components/SimpleRadio";
import { strings } from "core/localizedStrings";
import QuizOptions, { QuizAnswerType } from "models/activities/QuizOptions";
import SimpleCheckbox from "components/SimpleCheckbox";
import { OptionsGroup } from "components/activities/OptionsGroup";

export enum TicTacToeGameType {
    MULTIPLE_CHOICE = "multipleChoice",
    CLASSIC = "classic",
}

export const defaultTicTacToeOptions: TicTacToeOptions = {
    gameType: TicTacToeGameType.MULTIPLE_CHOICE,
    playAudioOnSelection: true,
};

export interface TicTacToeOptions {
    gameType: TicTacToeGameType;
    playAudioOnSelection: boolean;
}

interface TicTacToeOptionsProps {
    activityStore: ActivityStore;
}

const TicTacToeOptions = (props: TicTacToeOptionsProps) => {
    const [value, setValue] = React.useState(0);

    const handleGameTypeChanged = (value: any) => {
        props.activityStore.activityOptions.ticTacToeOptions.gameType = value;
    };

    const handlePlayAudioChanged = (value: boolean) => {
        props.activityStore.activityOptions.ticTacToeOptions.playAudioOnSelection = value;
    };

    console.log(props.activityStore.activityOptions.ticTacToeOptions.gameType);

    return (
        <Observer
            render={() => (
                <>
                    <OptionsGroup heading={strings.game}>
                        <SimpleRadio
                            defaultValue={props.activityStore.activityOptions.ticTacToeOptions.gameType}
                            labels={[strings.multipleChoiceAnswerTypeOption, strings.imagesInGrid]}
                            values={[TicTacToeGameType.MULTIPLE_CHOICE, TicTacToeGameType.CLASSIC]}
                            onValueChanged={handleGameTypeChanged}
                        />
                    </OptionsGroup>
                    {props.activityStore.activityOptions.ticTacToeOptions.gameType === TicTacToeGameType.CLASSIC ? (
                        <OptionsGroup heading={strings.classicModeOptions}>
                            <SimpleCheckbox
                                label={strings.playAudioOnSelection}
                                isChecked={props.activityStore.activityOptions.ticTacToeOptions.playAudioOnSelection}
                                onChange={handlePlayAudioChanged}
                            />
                        </OptionsGroup>
                    ) : (
                        <OptionsGroup heading={strings.multipleChoiceOptions}>
                            <QuizOptions activityStore={props.activityStore} />
                        </OptionsGroup>
                    )}
                </>
            )}
        />
    );
};

export default TicTacToeOptions;
