import * as React from "react";
import { observer } from "mobx-react";
import UserStore from "../../stores/UserStore";

// Utils
import { strings } from "../../core/localizedStrings";
import { Colors } from "../../core/utils/Colors";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Utils from "../../core/utils/Utils";
import { CircularProgress } from "@material-ui/core";
import { SubscriptionDetailsStatus, ManagedUser } from "../../models";

var Row = require("react-flexbox-grid").Row;

export interface PendingSubscriptionBlockProps extends React.Props<any> {
    store: UserStore;
    onClose: () => void;
}

@observer
export default class PendingSubscriptionBlock extends React.Component<PendingSubscriptionBlockProps> {
    render() {
        let pendingSubscription = this.props.store.pendingSubscription;

        if (this.props.store.fetchingProratedAmount) {
            return <CircularProgress />;
        }

        if (
            this.props.store.user instanceof ManagedUser ||
            !pendingSubscription ||
            this.props.store.subscriptionDetailStatus === SubscriptionDetailsStatus.Updating
        ) {
            return null;
        }

        return (
            <>
                <Typography variant="subtitle1" style={{ paddingTop: 5 }}>
                    {strings.pendingSubscription}
                </Typography>
                <Typography variant="subtitle1">{this.renderProratedAmountDue()}</Typography>
            </>
        );
    }

    private onPayUpgrade = () => {
        this.props.store.subscribeToPendingSubscription();
    };

    private renderProratedAmountDue = () => {
        let pendingSubscription = this.props.store.pendingSubscription;
        let nextBillDate = new Date(pendingSubscription.currentPeriodEnd * 1000).toLocaleDateString();
        let nextBillAmount = Utils.centsToDollarString(pendingSubscription.subscriptionFee);
        let amountDue = Utils.centsToDollarString(pendingSubscription.amountDueNow);
        let style = { color: Colors.red };
        return (
            <div>
                <Row style={style}>
                    {strings.formatString(strings.teacherCountFormat, pendingSubscription.quantity)}
                </Row>
                <Row style={style}>{strings.formatString(strings.proratedAmountDue, amountDue)}</Row>
                <Row style={style}>{strings.formatString(strings.nextBillingDate, nextBillDate, nextBillAmount)}</Row>
                <Row style={{ paddingTop: 10 }}>
                    {"Once subscription has been updated you can invite additional users with their email addresses."}
                </Row>
                <Row style={{ paddingTop: 10 }} end="xs">
                    <Button
                        style={{ marginRight: 5 }}
                        variant="contained"
                        onClick={() => {
                            this.props.onClose();
                        }}
                    >
                        {strings.cancel}
                    </Button>
                    <Button variant="contained" color="primary" onClick={this.onPayUpgrade}>
                        {strings.update}
                    </Button>
                </Row>
            </div>
        );
    };
}
