/* tslint:disable */
export const japanese = {
    absent: "不在",
    absentStudentsFormat: "{0}が存在しません",
    accountRegistration: "アカウント登録",
    active: "アクティブ",
    activityPreview: "活動のプレビュー",
    activityPreviewMessage: "無料アカウントにログインまたは登録してください。",
    add: "追加する",
    addAccount: "アカウントを追加する",
    addCard: "カードを追加",
    addCards: "カードを追加",
    addFolder: "フォルダーを追加",
    addGroupStudentAccount: "グループの学生アカウントを追加",
    addKeyword: "キーワードを追加",
    addName: "名前を追加",
    addTeacherAccount: "教師アカウントを追加",
    addToFavorites: "お気に入りに追加",
    addWithPlus: "+追加",
    additionalResources: "追加のリソース",
    admin: "管理者",
    affirmative: "肯定的",
    afterTrial:
        "30日間のトライアルの後、あなたはサイトを基本会員として使い続けることができます。プレミアムにアップグレードする。",
    all: "すべて",
    allFieldsRequired: "全て必須項目です。",
    allowCardsToBeFlipped: "カードを裏返すことを許可する",
    allowedToChangePassword: "先生はパスワードを変更することを許可されました",
    annual: "年次",
    annualSubscription: "年間会費",
    answer: "回答",
    audioClipLanguageLabel: "オーディオクリップの言語",
    backToWorksheets: "ワークシートに戻る",
    basic: "基本",
    basicMemberCredits:
        "*基本会員は月8クレジットを受け取ります。すべてのクレジットが使用されたら、サイトへのアクセスは翌月の初日までに制限されます。",
    billedAnnually: "請求済み年間",
    billedAnnuallyFormat: "毎年請求されます（{0}）",
    billedMonthly: "毎月の請求",
    billedMonthlyFormat: "毎月請求（{0}）",
    billingFrequency: "請求頻度",
    boy: "男の子",
    boys: "男の子",
    browseAllCategories: "カテゴリを閲覧/編集する",
    browseAllLists: "リストを閲覧/編集する",
    cancel: "キャンセル",
    cancelSubscription: "購読をキャンセル",
    cannotAddManagedAccountsInTrial: "試用期間中は管理アカウントを追加できません。",
    cannotBeEmpty: "空にすることはできません。",
    card: "カード",
    cardLower: "カード",
    cardsLower: "カード",
    category: "カテゴリー",
    category2: "カテゴリー2",
    categoryCharactersNotSupported:
        "このカテゴリには、このアクティビティでまだサポートされていない文字が含まれています。",
    categoryTypeNotSupported: "このカテゴリタイプはこのカテゴリではまだサポートされていません。",
    changeCategory: "カテゴリを変更",
    changeEditStudents: "学生の変更/編集",
    changePassword: "パスワードを変更する",
    checkEmailForVerifcation: "あなたのアカウントの有効化を完了するためにあなたの電子メールをチェックしてください。",
    chinese: "中国語",
    className: "クラス名",
    clear: "クリア",
    clearFilters: "フィルターをクリア",
    clearSelectedImage: "選択した画像を消去",
    clearSelectedImages: "選択した画像を消去",
    clickHereTo: "アカウントがありませんか？ここをクリックして",
    confirmChangeCategory: "カテゴリを変更するとアクティビティがリセットされます。変更してもよろしいですか？",
    confirmChangeClassList: "生徒リストを変更すると、アクティビティがリセットされます。変更してもよろしいですか？",
    confirmChangeTeamNumber: "チーム数を変更すると、アクティビティがリセットされます。変更してもよろしいですか？",
    confirmRestartSubscription: "購読を再開してもよろしいですか？あなたのクレジットカードは{0}まで請求されません。",
    contact: "接触",
    contactAdminMessage: " この問題が解決しない場合は、サイト管理者に連絡してください。",
    contactDescription:
        "ご質問や問題がありましたら教えてください。以下にメッセージを送ってください。できるだけ早く返信いたします",
    contactFormSubmitted: "ありがとうございました！あなたのメッセージは送信されました。",
    copy: "コピーする",
    copyLower: "コピーする",
    createCardFromSelectedImage: "選択した画像からカードを作成する",
    createCardsFromSelectedImages: "選択した画像{0}からカードを作成します",
    createCopyIn: "コピーを作成します。",
    createCustomLists: "カスタムリストを作成する",
    createNewList: "新しいリストを作成",
    createSelectCategory: "レッスンを作成または選択する",
    createSelectCategoryDescription: "数秒でカスタムレッスンを作成するか、既存の何千ものレッスンから検索します。",
    createSelectClassList: "学生リストを作成または選択する",
    createStudentNameLists: "学生の名前リストを作成する",
    createTeams: "チームを作る",
    createdBy: "によって作成された",
    createdOn: "作成日",
    creditCard: "クレジットカード",
    creditNeeded: "1回の使用につき1クレジット",
    currentSubscriptionRequiredForManagedAccounts:
        "有効な購読が必要です。アカウントを追加するには、サブスクリプトを再起動してください。",
    customizeHeader: "どんなレッスンにも合うように{{Customize}}",
    customizeText:
        "簡単な語彙リストや質問のリストを取り、魅力的なものに変えましょう。秒単位のアクティビティリストが作成されると、サイト全体のあらゆる活動に使用できます。",
    dataBeingMigratedFromBarryFunEnglish:
        "アカウントデータはBarryFunEnglishから移行されています。これは一度だけ行う必要があります。データ量に応じて、これはどこからでも取ることができます\n        数秒から数時間。数時間経ってもすべてのデータが表示されない場合は、にお問い合わせください。\n        サイト管理者",
    delete: "削除する",
    deleteCategoryConfirmation: "このカテゴリを完全に削除してもよろしいですか？",
    deleteFavoriteConfirmation: "あなたのお気に入りからこれを削除しますか？",
    deleteFolderConfirmation: "このフォルダとその中身をすべて完全に削除してもよろしいですか？",
    deleteStudentListConfirmation: "このリストを削除してもよろしいですか？",
    discount: "割引（{0}％）：",
    discounts: "割引：",
    done: "完了",
    dutch: "オランダの",
    edit: "編集する",
    editProfile: "プロファイル編集",
    email: "Eメール",
    emailAlreadyRegistered: "メールアドレスが既に登録されています",
    emailOrUsername: "メールアドレスまたはユーザ名",
    emailRequiredError: "ユーザー名は必須です。",
    emailSentTo: "メールを{0}に送信しました。",
    endGame: "ゲーム終了",
    english: "英語",
    enterEmailUsedToRegister: "サイトの登録に使用したEメールアドレスを入力してください。",
    enterIncorrectAnswers: "自分の間違った答えを入力してください",
    enterQuestion: "質問を入力してください",
    enterText: "テキストを入力してください：",
    enterValidEmail: "有効なメールアドレスを入力してください。",
    enterVocabAndQuestion: "語彙と質問を入力してください",
    enterVocabulary: "語彙を入力してください",
    existingAccount: " 既存のアカウント",
    expirationDateFormat: "有効期限：{0}",
    expired: "期限切れ",
    expires: "有効期限",
    expressionInputTextField1Label: "テキストフィールド1（質問）",
    expressionInputTextField2Label: "テキストフィールド2（回答）",
    favorites: "お気に入り",
    flashcards: "フラッシュカード",
    folderName: "フォルダ名：",
    forClassroomHeader: "{{classroom}}向けに設計されています",
    forClassroomText:
        "ゲームやアクティビティは教室での使用を想定して設計されています。大小のクラスではうまく機能します。\n            ゲームには2〜5チームがあり、生徒の名前リストをランダムに選択することができます。",
    forgotPassword: "パスワードをお忘れですか？",
    free: "無料",
    french: "フランス語",
    games: "ゲーム",
    gender: "性別",
    german: "ドイツ人",
    girl: "女の子",
    girls: "ガールズ",
    girlsVsBoys: "ガールズvsボーイズ",
    greek: "ギリシャ語",
    groupNumberFormat: "グループ{0}",
    homePageContactMessage: "{{questions}}がありますか？",
    imageOverlayGraphic: "画像オーバーレイグラフィック",
    imageWithText: "単一テキストフィールドを持つ画像",
    imageWithTextDescription:
        "これらのリストは、語彙の練習、読み、そして話すのに最適です。彼らはサイト上のほとんどすべての活動に取り組んでいます。",
    imageWithTwoText: "2つのテキストフィールドを持つ画像",
    imageWithTwoTextDescription: "これらは、画像に関する質問や翻訳のために使われます 2つの言語間",
    imagesSelectorInstructions: "カードを作成する画像を選択またはアップロードします。",
    interactive: "インタラクティブ",
    interactiveFlashcards: "インタラクティブフラッシュカード",
    introduce: "紹介する",
    invalidEmail: "無効な電子メールアドレス。",
    invalidPassword: "パスワードは6文字以上にする必要があります。",
    invalidUsername: "無効なユーザー名。",
    invalidUsernameOrPassword: "無効なユーザー名またはパスワードを入力しました",
    italian: "イタリアの",
    japanese: "日本人",
    keywordsLabel: "キーワード",
    korean: "韓国語",
    language: "言語",
    languageTitle: "言語",
    learn: "学ぶ",
    learnDescription: "対話型または印刷可能なフラッシュカードを使用して新しいレッスンまたはレビューを紹介します。",
    lesson: "レッスン",
    lessonDescription: "1つの画像と2つのテキストフィールドを持つ画像の混在",
    lessonTypeSelectorTitle: "あなたはこのレッスンからの語彙や質問を練習しますか？",
    listAccessibility: "リストのアクセシビリティ",
    listTitleLabel: "リストのタイトル",
    listTypeTitle: "リストの種類",
    loggingIn: "ログイン中",
    loginToCopy: "リストをコピーするにはログインしなければなりません。",
    loginToCreateFolders: "フォルダを作成するにはログインしている必要があります。",
    loginToCreateLists: "カスタムリストを作成するにはログインしなければなりません。",
    loginToEditLists: "リストを編集するにはログインしなければなりません。",
    loginToFavorite: "お気に入りを保存するにはログインする必要があります。",
    logout: "ログアウト",
    mainPageText: "どんな授業のためのカスタム言語学習活動",
    managedAccounts: "管理アカウント",
    managedAccountsDescription:
        "1回の購読で複数のメンバーシップを希望する場合は、管理アカウントを追加してください。割引は、合計アカウント数に基づいて提供されます。",
    maxFilesUploadAttempt: "一度にアップロードできるファイルは最大{0}個です。",
    membershipTypes: "会員タイプ",
    message: "メッセージ",
    misses: "見逃し",
    monthly: "毎月",
    monthlySubscription: "毎月のサブスクリプション",
    move: "移動する",
    moveFolderTo: "フォルダを次の場所に移動します。",
    moveListTo: "リストを次の場所に移動します。",
    multipleChoiceInstructions: "複数選択の活動で、あなたは間違った答えに何を使いたいですか？",
    multipleLanguageSupport: "多言語サポート",
    mustHaveOneIncorrectAnswers: "少なくとも1つの間違った答えを入力しなければなりません。",
    myAccount: "マイアカウント",
    myClasses: "私のクラス",
    myImages: "私の画像",
    myLists: "私のリスト",
    name: "名",
    negative: "負",
    newFolder: "新しいフォルダ",
    nextBillingDate: "次の請求日：{0}（{1}）",
    no: "いいえ",
    noAcceptableFilesFound: "有効なファイルが見つかりません。ファイルは最大サイズ{0} MBに制限されています。",
    noCardOnFile: "カードにファイルがありません",
    noCreditCardRequired: "クレジットカードは必要ありません。",
    noRemainingCredits:
        "今月の残りクレジットはありません。無制限アクセスのためのプレミアムアカウントに登録してください。",
    none: "無し",
    notActivated: "起動していない",
    notActive: "非活動中",
    notChargedUntilFormat: "クレジットカードは{0}まで変更されません。",
    notChargedUntilTrialComplete: "あなたのクレジットカードは試用期間の終わりまで請求されません。",
    numberOfTeams: "チーム数",
    off: "オフ",
    ok: "OK",
    on: "に",
    oneRemainingCredit: "今月のクレジットは1つ残っています。",
    options: "オプション",
    or: "または",
    page: "ページ",
    pageNotFound: "ページが見つかりません",
    password: "パスワード",
    passwordLengthError: "パスワードは少なくとも6文字でなければなりません。",
    passwordRequiredError: "パスワードが必要です",
    passwordResetEmailHasBeenSendMessage: "パスワードをリセットするためのリンクをあなたのEメールで確認してください。",
    pay: "支払う",
    payBalance: "支払い残高",
    paymentMethod: "支払方法",
    pendingSubscription: "保留中の購読",
    play: "遊びます",
    playDescription: "大小クラスのエキサイティングなゲーム",
    pleaseAddCardForRestart: "購読を再開する前にクレジットカードを追加する必要があります。",
    portuguese: "ポルトガル語",
    practice: "練習",
    practiceDescription: "インタラクティブで印刷可能なワークシート",
    premium: "プレミアム",
    premiumPriceFormatMonth: "$ {0} /月",
    premiumPriceFormatYear: "$ {0} /年",
    premiumTrial: "プレミアム（お試し）",
    preview: "プレビュー",
    price: "価格",
    print: "印刷する",
    printDescription: "オフライン練習用のフラッシュカードまたはワークシートを印刷する",
    printable: "印刷可能",
    printableFlashcards: "印刷用フラッシュカード",
    private: "非公開",
    problemSubmittingContactForm: "メッセージの送信中に問題が発生しました。",
    problemUpdatingUserErrorMessage: "ユーザー詳細の更新中にエラーが発生しました。",
    problemUsingCredit: "クレジットの使用中に問題が発生しました。",
    profile: "プロフィール",
    proratedAmountDue: "支払期限：{0}",
    public: "パブリック",
    publicLists: "コミュニティリスト",
    quellMessage: "このメッセージを次から表示しない",
    question: "質問",
    questions: "質問",
    quiz: "クイズ",
    randomize: "ランダム化",
    randomizeAndReset: "ランダム化とリセット",
    recent: "最近",
    register: "登録",
    registerUsernamePassword: "ユーザー名/パスワードで登録",
    rejectedFileFormat:
        "{0}ファイルはアップロードされませんでした。ファイルが大きすぎるか、ファイルタイプが違います。 500 KB以下の画像ファイルのみが受け入れられます。",
    rejectedFilesFormat:
        "{0}ファイルがアップロードされませんでした。ファイルが大きすぎるか、ファイルタイプが違います。 500 KB以下の画像ファイルのみが受け入れられます。",
    remainingCreditsFormat: "今月のクレジットは{0}になっています。",
    removeAllLetters: "すべての文字を削除",
    removeFromFavorites: "お気に入りから削除",
    removeManagedUserAccountConfirmation:
        "このアカウントは削除され、ユーザーは削除されますログインを許可されています。このアカウントを完全に削除してもよろしいですか？",
    resendEmail: "メールを再送",
    reset: "リセット",
    restartSubscription: "購読を再開する",
    restartSubscriptionMessage: "サブスクリプションの請求頻度を選択してください。",
    review: "見直し",
    reviewDescription: "リスニング、リーディング、ライティング、スピーキングのための多肢選択式クイズ",
    russian: "ロシア",
    sameAsTextField1Label: "テキストフィールド1と同じ",
    sampleClass: "サンプルクラス",
    save: "保存する",
    search: "サーチ",
    select: "選択してください",
    selectACategory: "カテゴリーを選ぶ",
    selectCategoryBeforeActivity: "アクティビティを選択する前にカテゴリを選択する必要があります。",
    selectFolderToAddFavoriteTo: "お気に入りを追加するフォルダを選択します。",
    selectStudentList: "学生リストを選択してください",
    showCategoryDetails: "詳細を表示",
    signIn: "ログイン",
    signInRegister: "ログイン/登録",
    signInUsernamePassword: "ユーザー名/パスワードでログイン",
    signUpForFree: "無料でお申し込み頂けます",
    signUpForFreeMessage: "30日間無料プレミアムアカウントに今すぐ登録！",
    singleTeacher: "シングルティーチャー",
    singleText: "単一テキストフィールド",
    singleTextDescription:
        "これらのリストは読書や綴りの練習に最適です。彼らはまた画像を必要としないアクティビティに便利です。",
    siteImages: "サイト画像",
    siteLists: "サイトリスト",
    sortByPopularity: "人気",
    sortByRecentlyCreated: "最近作成された",
    sortByRelevance: "関連性",
    sortByTitle: "タイトル",
    sortByTypeTitle: "並び替え",
    spanish: "スペイン語",
    start: "開始",
    startSubscription: "購読を開始",
    startSubscriptionAfterTrial: "購読を開始してから「プレミアム」メンバーシップを継続裁判は完了です。",
    studentGroup: "学生グループ",
    studentGroups: "学生グループ",
    studentList: "学生リスト",
    subject: "件名",
    submit: "提出する",
    subscribe: "申し込む",
    subscription: "購読",
    subscriptionDescriptionFormat: "購読タイプ：{0}",
    subscriptionType: "購読タイプ",
    subtotal: "小計：",
    support: "サポート問い合わせ先",
    teacher: "先生",
    teacherCountFormat: "先生の数：{0}",
    teachers: "先生",
    teachingTools: "教育ツール",
    teamNumberFormat: "チーム{0}",
    tenOrMore: "10以上",
    textField1Label: "（質問）",
    textField2Label: "（回答）",
    textToSpeechSupportFormat: "テキスト読み上げテクノロジを使用して自動的に作成されたオーディオクリップ",
    thousandsOfHeader: "から選択するカテゴリの{{数千}}",
    thousandsOfText:
        "あなた自身のカテゴリを作成したくないですか？ブラウズする KinteractiveLearningコミュニティによって作成された数千人。毎日追加されています！",
    threeOrMore: "三つ以上",
    titleCannotBeEmptyInfoMessage: "タイトルは空にできません。タイトルを入力してください。",
    tools: "道具",
    toolsDescription: "グループおよびチームメーカー、ランダムな学生セレクター、ストップウォッチ、およびスコアボード",
    totalDue: "支払期限：",
    totalManagedAccounts: "管理アカウント総数：",
    totalStudentsFormat: "{0}人の生徒",
    turkish: "トルコ語",
    twoTextOnly: "2つのテキストフィールド",
    twoTextOnlyDescription: "これらのリストは必要としない質問をするために使われます、そしてまたは2つの言語間の翻訳用",
    type: "タイプ",
    unlimited: "無制限",
    update: "更新",
    updateEmailError: "メールアドレスの更新中にエラーが発生しました。",
    uploadImages: "画像をアップロードする",
    uploadImagesDropzoneInstructions: "画像をドロップするか、クリックしてコンピュータを参照します。",
    uploadMessage: "{0}枚の画像をアップロードしています。",
    uploadProfileImageError: "プロフィール画像のアップロード中にエラーが発生しました。",
    useAsVocabulary: "語彙リストに追加する",
    useCredit: "クレジットを使用",
    useOtherAnswersAsIncorrect: "他のカードを使って正解する",
    username: "ユーザー名",
    usernameOptional: "ユーザー名（オプション）",
    usernameRequiredError: "ユーザー名は必須です。",
    usernameTaken: "ユーザー名は既に使われています",
    usernameUnavailable: "そのユーザー名は使用できません",
    vocabulary: "単語",
    worksheetCreators: "ワークシート作成者",
    worksheets: "ワークシート",
    yes: "はい",
    getStarted: "",
    requiresFlash: "",
    onlySupportsLatinBasedLanguages: "",
    upgradeToPremium: "",
    startReview: "",
    numberOfGroups: "",
    hideAnswer: "",
    autoPlayAudio: "",
    imageBank: "",
    doubleSidedCards: "",
    ignoreFlippedCardsForReview: "",
    soft: "",
    medium: "",
    strong: "",
    continuePlaying: "",
    languageNotSupported: "",
    createLink: "",
    pinActivityToDashboard: "",
    enterName: "",
    createStudentLink: "",
    expiration: "",
    oneDay: "",
    oneWeek: "",
    never: "",
    link: "",
    copyToClipboard: "",
    pinnedActivities: "",
    studentActivityLinks: "",
    numberOfCards: "",
    matchType: "",
    imageType: "",
    otherOptions: "",
    wordImage: "",
    imageImage: "",
    wordWord: "",
    activity: "",
    seeLinkVisitors: "",
    recentHistory: "",
    startTime: "",
    completedTime: "",
    results: "",
    iDontKnow: "",
    iKnowTheAnswer: "",
    seconds: "",
    minutes: "",
    hours: "",
    pause: "",
    set: "",
    audioHeading: "",
    playAudioOnCorrect: "",
    playAudioOnWrong: "",
    answerTypeHeading: "",
    multipleChoiceAnswerTypeOption: "",
    rightOrWrongAnswerTypeOption: "",
    showNumbersHeading: "",
    showNumbersMultipleChoice: "",
    timerHeading: "",
    showTimerMultipleChoice: "",
    game: "",
    correct: "",
    wrong: "",
    teams: "",
    imagesInGrid: "",
    multipleChoiceOptions: "",
    playAudioOnSelection: "",
    classicModeOptions: "",
    selectAll: "",
    flipCardsToFront: "",
    flipCardsToBack: "",
    shuffle: "",
    showAllCards: "",
    bonusRound: "",
    crazyCupsIntroMessage: "",
    crazyCupsBonusRoundMessage: "",
    goodJob: "",
    sorryYouMissedIt: "",
    teamReadyFormat: "",
    readyToTryAgain: "",
    cupsSpeedAdjustment: "",
    textOnly: "",
    imageText: "",
    imageOnly: "",
    hideShips: "",
    useClassicMode: "",
    classicModeDescription: "",
    stop: "",
    bullsEye: "",
    removeNameOnceSelected: "",
    clearDartAfterThrow: "",
    stopBoardAfterThrow: "",
    playSoundEffect: "",
    spinForPoints: "",
    showImageProfileImage: "",
    cardType: "",
    gridSize: "",
    cardsPerPage: "",
    noListsGetStarted: "",
    noImagesGetStarted: "",
    isTraceable: "",
    twoColumns: "",
    randomizeWorksheet: "",
    createPDF: "",
    fontSize: "",
    goodJobReadyForNext: "",
    whoGoesFirst: "",
    teamGoesFirstFormat: "",
    popBalloonsWith: "",
    dartMeterSpeed: "",
    fontStyle: "",
    cardSensitivity: "",
    points: "",
    hotOrColdInstructions: "",
    inviteUsers: "",
    availableInvitesFormat: "",
    numberOfAccountsToAdd: "",
    removeSubscriptionManagerConfirmation: "",
    managedSubscription: "",
    disconnectFromAccountManager: "",
    confirmToBeManagedConfirmationFormat: "",
    pendingManagerNotFound: "",
    steal: "",
    newWord: "",
    canYouSpell: "",
    wouldLikeToStealFormat: "",
    selectSecondCategory: "",
    clearSecondCategory: "",
    borderThickness: "",
    borderColor: "",
    subscriptionIsPastDue: "",
};
