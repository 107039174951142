import * as React from "react";
import { Observer } from "mobx-react-lite";

// Material UI
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";

// Stores
import { ActivityStore } from "stores";

// Layout
import { Row, Col } from "layout";

// Utils
import { strings } from "core/localizedStrings";

export enum HangmanImageType {
    Hangman = "hangman",
    Snowman = "snowman",
}

export interface HangmanOptions {
    hangmanImageType: HangmanImageType;
}

export const defaultHangmanOptions: HangmanOptions = {
    hangmanImageType: HangmanImageType.Hangman,
};

interface HangmanOptionsProps {
    activityStore: ActivityStore;
}

const HangmanOptions = (props: HangmanOptionsProps) => {
    const handleHangmanImageTypeChanged = (event: any, value: any) => {
        props.activityStore.activityOptions.hangmanOptions.hangmanImageType = value;
    };

    return (
        <Observer
            render={() => (
                <Col xs={true}>
                    <Typography variant="subtitle2">{strings.imageType}</Typography>
                    <Row style={{ paddingLeft: 20, minWidth: 300 }}>
                        <FormControl>
                            <RadioGroup
                                name="hanamanImageType"
                                value={props.activityStore.activityOptions.hangmanOptions.hangmanImageType}
                                onChange={handleHangmanImageTypeChanged}
                            >
                                <FormControlLabel
                                    value={HangmanImageType.Hangman}
                                    control={<Radio />}
                                    label={
                                        <img
                                            style={{ height: 60 }}
                                            src={require("../../../../assets/images/games/hangman/hangmanPreview.png")}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    value={HangmanImageType.Snowman}
                                    control={<Radio />}
                                    label={
                                        <img
                                            style={{ height: 60 }}
                                            src={require("../../../../assets/images/games/hangman/snowmanPreview.png")}
                                        />
                                    }
                                />
                            </RadioGroup>
                        </FormControl>
                    </Row>
                </Col>
            )}
        />
    );
};

export default HangmanOptions;
