import * as React from "react";
import { action, autorun, observable } from "mobx";
import { observer } from "mobx-react";

// Material UI
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

// Custom Components
import { SimpleInputForm } from "../SimpleInputForm";
import StudentCard, { StudentCardType } from "../common/StudentCard";

// Models
import { StudentList, Student } from "../../models";

// Stores
import StudentListStore from "../../stores/StudentListStore";

// Utils
import { strings } from "../../core/localizedStrings";
import { Colors } from "../../core/utils/Colors";
import { Fonts } from "../../core/utils/Fonts";
import * as Icons from "../../core/utils/Icons";
import { SingleButtonFooter } from "components/common/SingleButtonFooter";
import { log } from "index";

// Layout
var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

export interface StudentListEditorProps extends React.Props<any> {
    listHeight: number;
    store: StudentListStore;
}

@observer
export default class StudentListEditor extends React.Component<StudentListEditorProps> {
    @observable
    private shouldFocusNameInput = true;

    constructor(props: any, context: any) {
        super(props, context);
        this.props.store.setClassListToEdit(this.props.store.itemToEdit as StudentList);

        autorun(() => {
            if (this.props.store.studentToEdit === undefined) {
                this.setShouldFocusNameInput(true);
            }
        });
    }

    // public shouldComponentUpdate(nextProps: StudentListEditorProps) {
    //     let shouldUpdate =
    //         nextProps.store.rootStore.activityStore.currentStudentList.details.totalStudentCount !==
    //         this.props.store.rootStore.activityStore.currentStudentList.details.totalStudentCount;
    //     log.verbose("Should update: ", shouldUpdate);
    //     return shouldUpdate;
    // }

    public render() {
        log.verbose("Rendering StudentListEditor");
        return (
            <>
                <SingleButtonFooter text={strings.done} onClick={this.onDoneClicked} />
                <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                    {this.renderTitle()}
                    <Row style={{ padding: 20 }}>
                        <SimpleInputForm
                            fontSize={28}
                            fontColor={Colors.white}
                            height={28 * 2}
                            hint={strings.addName}
                            shouldFocus={this.shouldFocusNameInput}
                            shouldClearOnEnter={true}
                            onEnterDown={this.onAddStudent}
                        />
                    </Row>
                    <Row style={{ paddingLeft: 20 }}>
                        {this.props.store.rootStore.activityStore.currentStudentList !== undefined &&
                        this.props.store.rootStore.activityStore.currentStudentList.students.length > 0 ? (
                            <Typography variant="h6" style={{ color: Colors.red }}>
                                {strings.absent}
                            </Typography>
                        ) : null}
                    </Row>
                    <Row>
                        <div
                            id="list"
                            style={{
                                overflowY: "auto",
                                overflowX: "hidden",
                                width: "98%",
                                maxHeight: this.props.listHeight - 120,
                                paddingLeft: 10,
                                paddingRight: 10,
                            }}
                        >
                            {this.renderStudents()}
                        </div>
                    </Row>
                </div>
            </>
        );
    }

    private renderTitle() {
        return (
            <Row center="xs">
                <Col>
                    <div
                        style={{
                            paddingTop: 60,
                            fontFamily: Fonts.BalsamiqSans,
                            fontSize: 36,
                            color: Colors.white,
                        }}
                    >
                        {this.props.store.itemToEdit.title}
                        <IconButton onClick={this.onEditDetailsClicked}>
                            <Icons.Edit
                                style={{
                                    color: Colors.white,
                                    width: 32,
                                    height: 32,
                                }}
                            />
                        </IconButton>
                    </div>
                </Col>
            </Row>
        );
    }

    private renderStudents() {
        let list = this.props.store.rootStore.activityStore.currentStudentList;
        if (list !== undefined && list.students !== undefined) {
            return list.students.map((student: Student, index) => (
                <StudentCard
                    key={student.key}
                    variant={StudentCardType.ClassListEditor}
                    student={student}
                    store={this.props.store}
                />
            ));
        }
        return null;
    }

    @action
    private setShouldFocusNameInput(shouldSet: boolean) {
        this.shouldFocusNameInput = shouldSet;
    }

    @action
    private onAddStudent = (name: string) => {
        if (!this.props.store.rootStore.authStore.isSignedIn) {
            this.props.store.rootStore.showLoginRequiredMessage(strings.loginToCreateLists);
        } else {
            let student = new Student();
            student.name = name;
            student.parentKey = this.props.store.rootStore.activityStore.currentStudentList.key;
            this.props.store.updateStudent(student);
        }
    };

    @action
    private onEditDetailsClicked = () => {
        this.props.store.classListToEditTitle = this.props.store.rootStore.activityStore.currentStudentList;
    };

    @action
    private onDoneClicked = () => {
        this.props.store.setItemToEdit(undefined);
    };
}
