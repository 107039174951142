import { log } from "../";

import * as React from "react";
import { observer } from "mobx-react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import RootStore from "../stores/RootStore";

import { strings } from "../core/localizedStrings";
import { Colors } from "../core/utils/Colors";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

export interface SignInFormProps extends React.Props<any> {
    rootStore: RootStore;
}

const styles = {
    authErrorMessageStyle: { color: Colors.red },
};

@observer
export default class SignInForm extends React.Component<SignInFormProps> {
    private username: string;
    private password: string;

    render() {
        return (
            <Col xs={12} style={{ maxWidth: 250, paddingTop: 20 }}>
                <Row center="xs">
                    <Typography variant="body1">{strings.signInUsernamePassword.toUpperCase()}</Typography>
                </Row>

                <Row>
                    <TextField
                        disabled={this.props.rootStore.authStore.isLoggingIn}
                        label={strings.emailOrUsername}
                        fullWidth={true}
                        id="usernameTextField"
                        onChange={this.onTextChanged}
                        onKeyDown={this.onKeyDown}
                    />
                </Row>
                <Row style={{ paddingTop: 10 }}>
                    <TextField
                        disabled={this.props.rootStore.authStore.isLoggingIn}
                        type="password"
                        label={strings.password}
                        fullWidth={true}
                        id="passwordTextField"
                        onChange={this.onTextChanged}
                        onKeyDown={this.onKeyDown}
                    />
                </Row>
                <Row center="xs" style={{ padding: "15px 0" }}>
                    {this.props.rootStore.authStore.isLoggingIn ? null : (
                        <Button fullWidth={true} variant="contained" color="primary" onClick={this.onSignInClicked}>
                            {strings.signIn}
                        </Button>
                    )}
                </Row>
                {this.props.rootStore.authStore.authErrorMessage && (
                    <Row style={styles.authErrorMessageStyle}>{this.props.rootStore.authStore.authErrorMessage}</Row>
                )}
            </Col>
        );
    }

    private onTextChanged = (event: any) => {
        switch (event.currentTarget.id) {
            case "usernameTextField":
                this.username = event.currentTarget.value;
                break;
            case "passwordTextField":
                this.password = event.currentTarget.value;
                break;
            default:
                log.verbose(`Unknown target ID: ${event.currentTarget.id}`);
                break;
        }
    };

    private onKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            this.onSignInClicked(event);
        }
    };

    private onSignInClicked = async (event: any) => {
        try {
            await this.props.rootStore.authStore.signInWithUsername(this.username, this.password);
        } catch (error) {
            await this.props.rootStore.fetchUnknownUser(this.username, this.password);
        }
    };
}
