import { RootStore } from "stores";
import { FlashcardActivity, Game, GameType, Tool, ToolType } from "models";

export const checkTeams = (rootStore: RootStore) => {
    let { activityStore } = rootStore;
    let teams = activityStore.teams;
    if (activityStore.currentActivity instanceof FlashcardActivity) {
        if (!teams || activityStore.numberOfTeams !== 1) {
            activityStore.setNumberOfTeams(1);
        }
    } else if (activityStore.currentActivity instanceof Game) {
        switch (rootStore.activityStore.currentActivity.activityType) {
            case GameType.Bingo:
                if (!teams || activityStore.numberOfTeams !== 1) {
                    activityStore.setNumberOfTeams(1);
                }
                break;

            case GameType.Englishopoly:
                if (!teams || activityStore.numberOfTeams !== 3) {
                    activityStore.setNumberOfTeams(3);
                }
                if (activityStore.teamNumbersLocked) {
                    activityStore.setTeamNumbersLocked(true);
                }
                if (teams && teams.length !== 3 && activityStore.currentStudentList) {
                    activityStore.setShouldShowTeamMaker(true);
                }
                break;

            case GameType.TicTacToe:
                if (!teams || activityStore.numberOfTeams !== 2) {
                    activityStore.setNumberOfTeams(2);
                }
                if (!activityStore.teamNumbersLocked) {
                    activityStore.setTeamNumbersLocked(true);
                }
                if (teams && teams.length !== 2 && activityStore.currentStudentList) {
                    activityStore.setShouldShowTeamMaker(true);
                }
                break;

            default:
                if (!teams) {
                    activityStore.setNumberOfTeams(2);
                } else if (teams.length < 2 || teams.length > 5) {
                    activityStore.setNumberOfTeams(2);
                    if (activityStore.currentStudentList) {
                        activityStore.setShouldShowTeamMaker(true);
                    }
                }
                break;
        }
    } else if (activityStore.currentActivity instanceof Tool) {
        switch (rootStore.activityStore.currentActivity.activityType) {
            case ToolType.DartboardSelector:
                if (!teams || activityStore.numberOfTeams !== 1) {
                    activityStore.setNumberOfTeams(1);
                }
                break;
            case ToolType.RandomNamePicker:
                if (!teams || activityStore.numberOfTeams !== 1) {
                    activityStore.setNumberOfTeams(1);
                }
                break;
            case ToolType.Scoreboard:
                if (!teams || activityStore.numberOfTeams === 1) {
                    activityStore.setNumberOfTeams(1);
                }
                break;
        }
    }
};
