import * as React from "react";

// Stores
import { ActivityStore } from "stores";

// Layout
import { Observer } from "mobx-react-lite";
import {
    CardTypeButtons,
    CasingType,
    CasingTypeButtons,
    PuzzleSizeButtons,
} from "components/activities/CommonActivityOptions";
import { CardType } from "models/customLists/CategoryItem";
import { WorksheetOptions } from "../WorksheetScene";

export interface WordsearchWorksheetOptions extends WorksheetOptions {
    puzzleSize: number;
    cardType: CardType;
    casingType: CasingType;
}

export const defaultWordsearchWorksheetOptions: WordsearchWorksheetOptions = {
    puzzleSize: 17,
    cardType: CardType.IMAGE_ONLY,
    casingType: CasingType.LOWER_CASE,
};

interface WordsearchWorksheetOptionsProps extends React.Props<any> {
    activityStore: ActivityStore;
}

const WordsearchWorksheetOptions = (props: WordsearchWorksheetOptionsProps) => {
    const handleCardTypeChanged = (cardType: CardType) => {
        props.activityStore.activityOptions.wordsearchWorksheetOptions.cardType = cardType;
        props.activityStore.updateOptions();
    };

    const handleCasingTypeChanged = (casingType: CasingType) => {
        props.activityStore.activityOptions.wordsearchWorksheetOptions.casingType = casingType;
    };

    const handleSizeDecremented = () => {
        const MIN_SIZE = 12;
        props.activityStore.activityOptions.wordsearchWorksheetOptions.puzzleSize -= 1;
        if (props.activityStore.activityOptions.wordsearchWorksheetOptions.puzzleSize < MIN_SIZE) {
            props.activityStore.activityOptions.wordsearchWorksheetOptions.puzzleSize = MIN_SIZE;
        }
    };

    const handleSizeIncremented = () => {
        const MAX_SIZE = 25;
        props.activityStore.activityOptions.wordsearchWorksheetOptions.puzzleSize += 1;
        if (props.activityStore.activityOptions.wordsearchWorksheetOptions.puzzleSize > MAX_SIZE) {
            props.activityStore.activityOptions.wordsearchWorksheetOptions.puzzleSize = MAX_SIZE;
        }
    };

    return (
        <Observer
            render={() => (
                <div style={{ paddingBottom: 15 }}>
                    <CasingTypeButtons onCasingTypeChanged={handleCasingTypeChanged} />
                    <PuzzleSizeButtons
                        onSizeDecremented={handleSizeDecremented}
                        onSizeIncremented={handleSizeIncremented}
                        currentValue={props.activityStore.activityOptions.wordsearchWorksheetOptions.puzzleSize}
                    />
                    <CardTypeButtons
                        activityStore={props.activityStore}
                        onCardTypeChanged={handleCardTypeChanged}
                        selectedType={props.activityStore.activityOptions.wordsearchWorksheetOptions.cardType}
                    />
                </div>
            )}
        />
    );
};

export default WordsearchWorksheetOptions;
