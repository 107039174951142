import { log } from "../../../../";

import * as React from "react";
import { findDOMNode } from "react-dom";
import { observable, action, autorun, runInAction } from "mobx";
import { observer, inject } from "mobx-react";
import { Motion, spring, presets } from "react-motion";

import Button from "@material-ui/core/Button";

import TeamNumberMenu from "../../../menus/TeamNumberMenu";
import StudentCard, { StudentCardType } from "../../../common/StudentCard";
import TeamHeaderCard from "../../../common/TeamHeaderCard";
import DraggableCard from "../../../DraggableCard";
import ActivityFooter from "components/activities/ActivityFooter";

import RootStore from "../../../../stores/RootStore";

import { StudentList, Student, TeamManager, ToolType } from "../../../../models";

import { strings } from "../../../../core/localizedStrings";
import Shadowbox from "components/Shadowbox";
import { TEAM_MAKER_INDEX } from "layers";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

export interface TeamMakerProps extends React.Props<any> {
    rootStore?: RootStore;
    isGroupMaker?: Boolean;
}

interface PendingMove {
    dragStudent: Student;
    hoverStudent: Student;
    dragTeam: number;
    hoverTeam: number;
    hoverIndex: number;
}

@inject("rootStore")
@observer
export default class TeamMaker extends React.Component<TeamMakerProps> {
    @observable listHeight: number;

    private wasShowingStudent: boolean = false;
    private pendingMove: PendingMove;

    constructor(props: TeamMakerProps) {
        super(props);
        let { rootStore } = props;
        let { activityStore } = rootStore;

        if (activityStore.shouldShowBrowseListsView) {
            activityStore.setShouldShowBrowseListsView(false);
        }

        if (activityStore.shouldShowStudentSnackbar) {
            this.wasShowingStudent = true;
            activityStore.setShouldShowStudentSnackbar(false);
        }
    }

    public componentWillUnmount() {
        this.props.rootStore.setAppBarTitle(undefined);
    }

    public componentDidMount() {
        let { rootStore } = this.props;
        let { activityStore } = rootStore;
        let { currentStudentList } = activityStore;

        runInAction(() => {
            autorun(() => {
                let bottomPadding = 100;
                let domNode = findDOMNode(document.getElementById("list")) as Element;
                if (domNode) {
                    var listBoundingRect = domNode.getBoundingClientRect();
                    runInAction(() => {
                        this.listHeight = rootStore.windowSize.height - listBoundingRect.top - bottomPadding;
                    });
                }
            });
        });

        if (this.props.isGroupMaker) {
            if (!currentStudentList || currentStudentList.students.length === 0) {
                activityStore.setShouldShowStudentListSelector(true);
            }
        } else {
            rootStore.setAppBarTitle(strings.createTeams);
        }
    }

    public render() {
        let { rootStore } = this.props;
        let { activityStore } = rootStore;
        let girlsVsBoysEnabled = !(activityStore.teamNumbersLocked && activityStore.numberOfTeams !== 2);

        if (activityStore.shouldShowStudentListSelector) {
            return null;
        }

        return (
            <Shadowbox
                zIndex={this.props.isGroupMaker ? 0 : TEAM_MAKER_INDEX}
                backgroundOpacity={this.props.isGroupMaker ? 0.2 : 0.9}
                onBackgroundClicked={this.onDoneClicked}
            >
                {!this.props.isGroupMaker && (
                    <ActivityFooter>
                        <Row end="xs" style={{ paddingRight: 10 }}>
                            <Button variant="contained" color="primary" onClick={this.onDoneClicked}>
                                {strings.done}
                            </Button>
                        </Row>
                    </ActivityFooter>
                )}
                <Col xs={12}>
                    <Row middle="xs" style={{ paddingLeft: 20, paddingTop: 90 }}>
                        {activityStore.teamNumbersLocked ? null : (
                            <div style={{ paddingLeft: 10 }}>
                                <TeamNumberMenu
                                    isGroupMaker={activityStore.currentActivity.activityType === ToolType.GroupMaker}
                                    numberOfTeams={activityStore.numberOfTeams}
                                    numberOptions={
                                        activityStore.currentActivity.activityType === ToolType.Scoreboard
                                            ? [2, 3, 4, 5, 6, 7, 8]
                                            : [2, 3, 4, 5]
                                    }
                                    onChangedCallback={this.onTeamsNumberChanged}
                                />
                            </div>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: 10, marginRight: 10, minHeight: 25 }}
                            onClick={this.onRandomizeClicked}
                        >
                            {strings.randomize}
                        </Button>
                        {girlsVsBoysEnabled ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.onGirlsVsBoysClicked}
                                style={{ minHeight: 25 }}
                            >
                                {strings.girlsVsBoys}
                            </Button>
                        ) : null}
                    </Row>
                    <Row id={"list"} style={{ paddingTop: 50, maxHeight: this.listHeight, overflowY: "auto" }}>
                        {this.renderCards()}
                    </Row>
                </Col>
            </Shadowbox>
        );
    }

    private renderCards = () => {
        let { numberOfTeams, teamManager } = this.props.rootStore.activityStore;
        let { teams } = teamManager;
        let allCards: any[] = [];

        let windowWidth = this.props.rootStore.windowSize.width;
        let totalCardWidth = windowWidth * 0.7;
        let cardWidth = totalCardWidth / numberOfTeams;
        let padding = (windowWidth - totalCardWidth) / (numberOfTeams + 1);

        if (teams) {
            teams.forEach((team, teamIndex) => {
                let xPosition = teamIndex * (cardWidth + padding) + padding;

                if (!team.name) {
                    if (this.props.isGroupMaker) {
                        team.name = strings.formatString(strings.groupNumberFormat, teamIndex + 1) as string;
                    } else {
                        team.name = strings.formatString(strings.teamNumberFormat, teamIndex + 1) as string;
                    }
                }

                allCards.push(
                    <Motion key={teamIndex} style={{ x: spring(xPosition, presets.gentle) }}>
                        {({ x }) => (
                            <div
                                style={{
                                    position: "absolute",
                                    WebkitTransform: `translate3d(${x}px, 0, 0)`,
                                    transform: `translate3d(${x}px, 0, 0)`,
                                }}
                            >
                                <TeamHeaderCard title={team.name} width={cardWidth} />
                            </div>
                        )}
                    </Motion>
                );

                team.students.forEach((student, index) => {
                    let position = { x: xPosition, y: index * 60 + 55 };
                    allCards.push(
                        <Motion
                            key={student.key}
                            style={{ x: spring(position.x, presets.gentle), y: spring(position.y, presets.gentle) }}
                        >
                            {({ x, y }) => (
                                <div
                                    style={{
                                        position: "absolute",
                                        WebkitTransform: `translate3d(${x}px, ${y}px, 0)`,
                                        transform: `translate3d(${x}px, ${y}px, 0)`,
                                    }}
                                >
                                    <DraggableCard
                                        key={student.key}
                                        moveCard={this.moveCard}
                                        index={index}
                                        metadata={{ teamIndex: teamIndex, student: student }}
                                        childObject={
                                            <StudentCard
                                                variant={StudentCardType.TeamMaker}
                                                width={cardWidth}
                                                student={student}
                                                store={this.props.rootStore.studentListStore}
                                            />
                                        }
                                        onItemDropped={this.itemMoved}
                                    />
                                </div>
                            )}
                        </Motion>
                    );
                });
            });
        }
        return allCards;
    };

    private moveCard = (dragIndex: number, hoverIndex: number, dragMetadata?: any, hoverMetadata?: any) => {
        this.pendingMove = {
            dragStudent: dragMetadata.student,
            hoverStudent: hoverMetadata.student,
            dragTeam: dragMetadata.teamIndex,
            hoverTeam: hoverMetadata.teamIndex,
            hoverIndex: hoverIndex,
        };
    };

    @action
    private itemMoved = () => {
        let { teams } = this.props.rootStore.activityStore.teamManager;
        if (this.pendingMove) {
            // Remove from existing
            teams[this.pendingMove.dragTeam].students = teams[this.pendingMove.dragTeam].students.filter((student) => {
                log.verbose(student.key);
                return student.key !== this.pendingMove.dragStudent.key;
            });

            // Move to new list
            teams[this.pendingMove.hoverTeam].students.splice(
                this.pendingMove.hoverIndex,
                0,
                this.pendingMove.dragStudent
            );
        }
        let teamManager = new TeamManager(this.props.rootStore.activityStore.teamManager.teams.length);
        teamManager.teams = teams.slice();
        this.props.rootStore.activityStore.setTeamManager(teamManager);
        this.pendingMove = undefined;
    };

    @action
    private onRecentClassListSelected = (classList: StudentList) => {
        let { activityStore } = this.props.rootStore;
        if (!classList) {
            activityStore.setShouldShowStudentListSelector(true);
            // activityStore.setShouldShowTeamMaker(false);
        } else {
            activityStore.setCurrentStudentList(classList.key);
        }
    };

    private onRandomizeClicked = () => {
        let teamManager = new TeamManager(this.props.rootStore.activityStore.teamManager.teams.length);
        teamManager.addStudentsToTeams(this.props.rootStore.activityStore.currentStudentList.presentStudents);
        this.props.rootStore.activityStore.setTeamManager(teamManager);
    };

    private onGirlsVsBoysClicked = () => {
        for (var student of this.props.rootStore.activityStore.currentStudentList.presentStudents) {
            if (!student.gender) {
                this.props.rootStore.showInfoMessage({
                    text: "Cannot create teams. Gender has not been specified for all students.",
                });
                return undefined;
            }
        }
        let teamManager = new TeamManager(2);
        teamManager.addStudentsToGirlsVsBoysTeams(
            this.props.rootStore.activityStore.currentStudentList.presentStudents
        );
        this.props.rootStore.activityStore.setTeamManager(teamManager);
    };

    private onTeamsNumberChanged = (teamsNumber: number) => {
        let teamManager = new TeamManager(teamsNumber);
        teamManager.addStudentsToTeams(this.props.rootStore.activityStore.currentStudentList.presentStudents);
        this.props.rootStore.activityStore.setTeamManager(teamManager);
        this.props.rootStore.activityStore.setNumberOfTeams(teamsNumber);
    };

    @action
    private onDoneClicked = (value: any = undefined) => {
        this.props.rootStore.activityStore.setShouldShowTeamMaker(false);

        if (this.wasShowingStudent) {
            this.props.rootStore.activityStore.nextStudent();
            this.props.rootStore.activityStore.setShouldShowStudentSnackbar(true);
        }
    };
}
