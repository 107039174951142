import { log } from "../";

import { observable, action, computed, runInAction } from "mobx";
import { persist } from "mobx-persist";

import PersistentStore from "./PersistentStore";

import * as CustomLists from "../models/customLists";
import FirestoreFolder from "../models/customLists/FirestoreFolder";
import CategoryFolder from "../models/customLists/CategoryFolder";
import { FolderItem, ListItem } from "../models/Folder";
import SearchResultFolder from "models/customLists/SearchResultFolder";

// Localization
import { strings } from "../core/localizedStrings";

export default class CategoryMenuStore extends PersistentStore {
    @persist @observable currentTab: CustomLists.TabSelection;
    @persist @observable sortByType: CustomLists.SortByType = CustomLists.SortByType.RecentlyCreated;

    @observable folderBrowserCategoryMenuFolder: FirestoreFolder;
    @observable itemToEdit: FolderItem;
    @observable itemToMove: FolderItem;
    @observable itemToCopy: FolderItem;
    @observable folderToEdit: CustomLists.Folder;
    @observable folderToMove: CustomLists.Folder;
    @observable shouldShowCategoryDetailsEditor: boolean = false;
    @observable searchText: string = undefined;

    @observable folderTabs = new Map<string, FirestoreFolder>();
    @observable searchTabs = new Map<string, SearchResultFolder>();

    // Getters/Setters
    // #region --------------------
    get audioStore() {
        return this.rootStore.audioStore;
    }

    @computed
    get isSearching() {
        return this.searchText !== undefined;
    }

    @computed
    get visibleItems(): ListItem[] {
        if (this.isSearching && this.searchTabs && this.searchTabs.get(this.currentTab)) {
            let searchFolder = this.searchTabs.get(this.currentTab);
            return searchFolder.visibleItems;
        } else if (this.folderTabs && this.folderTabs.get(this.currentTab)) {
            let items = this.folderTabs.get(this.currentTab).visibleItems;
            if (this.currentTab == CustomLists.TabSelection.PUBLIC) {
                // Filter out any empty categories
                items = items.filter((item) => {
                    return (item as CustomLists.Category).items.length > 0;
                });
            }
            return items;
        } else {
            return undefined;
        }
    }

    get visibleFolder(): CustomLists.Folder {
        if (this.isSearching && this.searchTabs && this.searchTabs.get(this.currentTab)) {
            return this.searchTabs.get(this.currentTab).currentFolder;
        } else if (this.folderTabs && this.folderTabs.get(this.currentTab)) {
            let folder = this.folderTabs.get(this.currentTab).currentFolder;
            return folder;
        } else {
            return undefined;
        }
    }

    get visibleCategoryMenuFolder(): CategoryFolder {
        if (this.searchText !== undefined) {
            return this.searchTabs.get(this.currentTab);
        } else {
            return this.folderTabs.get(this.currentTab);
        }
    }

    @computed
    get isLoading(): boolean {
        return this.visibleCategoryMenuFolder && this.visibleCategoryMenuFolder.isLoading;
    }

    @computed
    get isSearchQuerying(): boolean {
        let isQuerying = false;
        Array.from(this.searchTabs.values()).forEach((tab) => {
            if (tab.isQuerying) {
                isQuerying = true;
            }
        });
        return isQuerying;
    }

    @computed
    get breadcrumbs(): CustomLists.Folder[] {
        if (!this.isSearching) {
            let folder = this.folderTabs.get(this.currentTab);
            if (folder) {
                return folder.breadcrumbs;
            }
        }
        return undefined;
    }

    @action
    setFolderTitleToEdit = (folder: CustomLists.PublicListsFolder) => {
        this.folderToEdit = folder;
    };

    @action
    setItemToEdit = (item: FolderItem) => {
        this.itemToEdit = item;
    };

    @action
    setItemToMove = (item: FolderItem) => {
        this.itemToMove = item;
    };

    @action
    setFolderToMove = (folder: CustomLists.Folder) => {
        this.folderToMove = folder;
    };

    @action
    setItemToCopy = (item: FolderItem) => {
        this.itemToCopy = item;
    };

    @action
    setSearchText = (value: string) => {
        this.searchText = value;
    };

    // #endregion

    // UI Updates
    // #region ---------------------------------
    folderBrowserFolderSelected = (folder: CustomLists.Folder) => {
        this.folderBrowserCategoryMenuFolder.updateCurrentFolder(folder);
    };

    @action
    folderSelected = async (folder: CustomLists.Folder, breadcrumbsToInject: CustomLists.Folder[] = undefined) => {
        let firestoreFolder = this.visibleCategoryMenuFolder as FirestoreFolder;
        await firestoreFolder.updateCurrentFolder(folder);
        runInAction(() => {
            if (breadcrumbsToInject) {
                this.visibleCategoryMenuFolder.breadcrumbs = breadcrumbsToInject;
            }
        });
    };

    @action
    handleTabChange = async (tab: CustomLists.TabSelection) => {
        // Stop watching current folder and items
        // this.visibleCategoryMenuFolder.stopWatchingAll();

        // Update current
        this.updateCurrentTab(tab);

        // Mark folders as visible or not visible
        if (this.isSearching) {
            Array.from(this.searchTabs.values()).forEach((searchTab) => {
                searchTab.isVisible = searchTab.tabType === this.currentTab;
            });
        } else {
            Array.from(this.folderTabs.values()).forEach((folderTab) => {
                folderTab.isVisible = folderTab.tabType === this.currentTab;
            });
        }

        if (this.visibleFolder === undefined && !this.isSearching) {
            let folder = await this.fetchRootFolderForCurrentTab();
            (this.visibleCategoryMenuFolder as FirestoreFolder).updateCurrentFolder(folder);
        } else {
            if (
                this.currentTab === CustomLists.TabSelection.SITE_IMAGES &&
                this.searchText === undefined &&
                this.visibleItems.length === 0
            ) {
                this.searchEmpty();
            } else {
                await this.visibleCategoryMenuFolder.updateIfNecessary();
            }
        }
    };

    checkShouldFetchMore = () => {
        log.verbose("Check should fetch more.");
        this.visibleCategoryMenuFolder.checkShouldFetchMore();
    };

    @action
    updateCurrentTab = (tab: CustomLists.TabSelection) => {
        this.currentTab = tab;
    };

    // Fetch Data
    // #region ----------------------------
    protected fetchRootFolderForCurrentTab = () => {
        return this.fetchRootFolderForTab(this.currentTab);
    };

    async fetchRootFolderForTab(tab: CustomLists.TabSelection) {
        let ref = this.rootStore.refFactory.foldersRefForTab(tab);
        if (ref) {
            log.verbose("Loading root folder for ref: " + ref.path);
            try {
                let querySnapshot = await ref.where("isRoot", "==", true).limit(1).get();
                if (!querySnapshot.empty) {
                    let rootFolderDoc = querySnapshot.docs[0];
                    let key = rootFolderDoc.id;
                    let folder = new CustomLists.Folder(rootFolderDoc.data());
                    folder.key = key;
                    return folder;
                }
            } catch (error) {
                log.verbose(`Error fetching root folder. ${error}`);
            }
        }
        return null;
    }

    fetchRootFolderListForFolderBrowser = async (tab: CustomLists.TabSelection) => {
        runInAction(async () => {
            this.folderBrowserCategoryMenuFolder = new FirestoreFolder(tab, this.rootStore);
            this.folderBrowserCategoryMenuFolder.isFolderBrowser = true;

            let rootFolder = await this.fetchRootFolderForTab(tab);
            this.folderBrowserCategoryMenuFolder.updateCurrentFolder(rootFolder);
        });
    };
    // #endregion

    // Edit Data
    // #region ----------------
    updateFolder = (folder: CustomLists.Folder) => {
        if (folder.key === undefined) {
            // Create a new folder
            this.addFolderForTab(folder.title, this.visibleFolder, this.currentTab);
        } else {
            // Update existing title
            let foldersRef = this.rootStore.refFactory.foldersRefForTab(this.currentTab);
            foldersRef.doc(folder.key).update({ title: folder.title, searchTitle: folder.title.toLowerCase() });
        }
    };

    updateCurrentFolderBrowserFolder = (folder: CustomLists.Folder) => {
        this.folderBrowserCategoryMenuFolder.updateCurrentFolder(folder);
    };
    // #endregion

    // Insert data
    // #region ----------------
    addFolderForTab = async (folderTitle: string, parentFolder: CustomLists.Folder, tab: CustomLists.TabSelection) => {
        if (parentFolder === undefined) {
            await this.createAndSetRootFolderIfNecessary();
            parentFolder = this.visibleFolder;
        }

        let newFolder = new CustomLists.Folder();
        newFolder.title = folderTitle;
        newFolder.parentKey = parentFolder.key;
        this.createAndAddFolder(newFolder, this.rootStore.refFactory.foldersRefForTab(tab));
    };

    addFolderForFolderBrowser = (folderTitle: string) => {
        this.addFolderForTab(
            folderTitle,
            this.folderBrowserCategoryMenuFolder.currentFolder,
            this.folderBrowserCategoryMenuFolder.tabType
        );
    };

    async createAndSetRootFolderIfNecessary() {
        if (!this.visibleFolder || !this.visibleFolder.key) {
            let ref = this.rootStore.refFactory.foldersRefForTab(this.currentTab);
            let querySnapshot = await ref.where("isRoot", "==", true).get();
            let rootFolder: CustomLists.Folder;
            if (querySnapshot.empty) {
                let newFolder = await this.addRootFolderForTab(
                    this.rootStore.refFactory.foldersRefForTab(this.currentTab)
                );
                rootFolder = new CustomLists.Folder(newFolder);
            } else {
                rootFolder = new CustomLists.Folder(querySnapshot.docs[0].data());
                rootFolder.key = querySnapshot.docs[0].id;
            }

            await (this.visibleCategoryMenuFolder as FirestoreFolder).updateCurrentFolder(rootFolder);
        }

        if (!this.visibleFolder) {
            log.error("Error creating or setting root folder.");
        }
    }

    protected addRootFolderForTab(foldersRef: firebase.firestore.CollectionReference): Promise<CustomLists.Folder> {
        let rootFolder = new CustomLists.Folder({ title: this.rootFolderNameForTab(this.currentTab), isRoot: true });
        return this.createAndAddFolder(rootFolder, foldersRef);
    }

    protected async createAndAddFolder(folder: CustomLists.Folder, foldersRef: firebase.firestore.CollectionReference) {
        // Create a new folder
        let folderCopy: any = folder.objectForFirebase;
        folderCopy.searchTitle = folder.title.toLowerCase();
        let doc = await foldersRef.add(folderCopy);
        folderCopy.key = doc.id;
        return new CustomLists.Folder(folderCopy);
    }
    // #endregion

    // Move data
    // #region ----------------
    @action
    moveFolderToFolder = async (
        folder: CustomLists.Folder,
        parentFolder: CustomLists.Folder,
        tab: CustomLists.TabSelection
    ) => {
        let foldersRef = this.rootStore.refFactory.foldersRefForTab(tab);
        // If undefined, we need to insert a root folder
        if (parentFolder.key === undefined) {
            let doc = await foldersRef.add({ root: true });
            parentFolder.key = doc.id;
        }
        let folderToMoveKey = folder.key;
        let currentParentKey = this.visibleFolder.key;
        let newParentKey = parentFolder.key;

        // Don't move if same folder
        if (currentParentKey === newParentKey || folderToMoveKey === newParentKey || folder.isParentFolderLink) {
            return;
        }

        let folderRef = this.rootStore.refFactory.foldersRefForTab(tab);
        folderRef.doc(folderToMoveKey).update({ parentKey: newParentKey });
        this.folderToMove = undefined;
    };

    @action
    moveItemToFolder = (item: FolderItem, newParentFolder: CustomLists.Folder, tab: CustomLists.TabSelection) => {
        let itemRef = this.rootStore.refFactory.itemRefForTab(tab);
        itemRef.doc(item.key).update({ parentKey: newParentFolder.key });
        this.itemToMove = undefined;
    };
    // #endregion

    // Delete data
    // #region ----------------
    deleteFolder = async (folder: CustomLists.Folder) => {
        // Recursively delete all subfolders
        let foldersRef = this.rootStore.refFactory.foldersRefForTab(this.currentTab);
        let querySnapshot = await foldersRef.where("parentKey", "==", folder.key).get();
        for (var doc of querySnapshot.docs) {
            let data = doc.data();
            if (data !== undefined && data !== null) {
                let subfolder = new CustomLists.Folder(data);
                subfolder.key = doc.id;
                this.deleteFolder(subfolder);
            }
        }

        // Delete items
        this.deleteItemsForFolder(folder);

        // Delete folder
        log.verbose("Deleting category folder for key: " + folder.key);
        foldersRef.doc(folder.key).delete();
    };

    protected deleteItemsForFolder = (folder: CustomLists.Folder) => {
        // should be overrideen in subclass!!!
        log.error("deleteItemsForFolder() Should be overridden in subclass!!!!");
    };
    // #endregion

    // Queries
    // #region ----------------
    createQueryForTab = (text: string, tab: CustomLists.TabSelection, parentKey: string): string => {
        log.error("createQueryForTab() Should be implemented in subclass.");
        return "{}";
    };

    createRequestForTab(tab: CustomLists.TabSelection, requestKey: string, query: any): any {
        log.error("Should be implemented in subclass.");
        return undefined;
    }

    parseQueryResponse(value: any): any {
        log.error("Should be implemented in subclass.");
    }
    // #endregion

    // Search
    // #region ----------------
    public search = async (text: string) => {
        if (!text || text.trim().length === 0) {
            this.searchText = undefined;
            if (this.currentTab === CustomLists.TabSelection.SITE_IMAGES) {
                this.searchEmpty();
            }
            Array.from(this.searchTabs.values()).forEach((tab) => {
                tab.reset();
            });
            return;
        } else {
            this.searchText = text.trim();
        }

        // First search current tab
        let folder = this.visibleCategoryMenuFolder as SearchResultFolder;
        await folder.search(text);

        // Then others
        Array.from(this.searchTabs.values()).forEach((tab) => {
            if (this.currentTab !== tab.tabType) {
                tab.search(text);
            }
        });
    };

    public searchEmpty() {
        // First search current tab
        let folder = this.visibleCategoryMenuFolder as SearchResultFolder;
        folder.search(undefined);
    }
    // #endregion

    // Helpers
    // #region -------------------------
    private rootFolderNameForTab(tab: CustomLists.TabSelection): string {
        switch (tab) {
            case CustomLists.TabSelection.MY_LISTS:
                return strings.myLists;
            case CustomLists.TabSelection.MY_IMAGES:
                return strings.myImages;
            case CustomLists.TabSelection.FAVORITES:
                return strings.favorites;
            case CustomLists.TabSelection.CLASS_EDITOR:
                return strings.myClasses;
            default:
                return "";
        }
    }
    // #endregion
}
