import * as React from "react";

// Stores
import { ActivityStore } from "stores";

// Layout
import { Divider, Grid, TextField, Typography } from "@material-ui/core";
import SimpleRadio from "components/SimpleRadio";
import { Observer } from "mobx-react-lite";
import { strings } from "core/localizedStrings";
import { CardTypeButtons, worksheetOptionsBackgroundStyle } from "components/activities/CommonActivityOptions";
import { CardType } from "models/customLists/CategoryItem";

export enum GridSizeOptions {
    NINE = "9",
    SIXTEEN = "16",
    TWENTY_FIVE = "25",
}

export enum CardsPerPageOption {
    ONE = "1",
    TWO = "2",
}

export interface BingoCardsOptions {
    gridSize: GridSizeOptions;
    cardsPerPage: CardsPerPageOption;
    numberOfCardsToCreate: number;
    cardType: CardType;
}

export const defaultBingoCardsOptions: BingoCardsOptions = {
    gridSize: GridSizeOptions.SIXTEEN,
    cardsPerPage: CardsPerPageOption.ONE,
    numberOfCardsToCreate: 1,
    cardType: CardType.IMAGE_ONLY,
};

interface BingoCardsOptionsProps extends React.Props<any> {
    activityStore: ActivityStore;
}

const BingoCardsOptions = (props: BingoCardsOptionsProps) => {
    let gridSizeLabels = ["3 x 3", "4 x 4", "5 x 5"];
    let gridSizeValues = [GridSizeOptions.NINE, GridSizeOptions.SIXTEEN, GridSizeOptions.TWENTY_FIVE];
    let cardsPerPageLabels = ["One", "Two"];
    let cardsPerPageValues = [CardsPerPageOption.ONE, CardsPerPageOption.TWO];

    const handleCardTypeChanged = (cardType: CardType) => {
        props.activityStore.activityOptions.bingoCardsOptions.cardType = cardType;
        props.activityStore.updateOptions();
    };

    const handleGridSizeChanged = (value: any) => {
        props.activityStore.activityOptions.bingoCardsOptions.gridSize = value;
        props.activityStore.updateOptions();
    };

    const handleCardsPerPageChanged = (value: any) => {
        props.activityStore.activityOptions.bingoCardsOptions.cardsPerPage = value;
        props.activityStore.updateOptions();
    };

    const handleNumberOfCardsChanged = (event) => {
        let value = event.target.value;
        if (value < 1) {
            value = 1;
        }
        if (value > 40) {
            value = 40;
        }
        props.activityStore.activityOptions.bingoCardsOptions.numberOfCardsToCreate = value;
        props.activityStore.updateOptions();
    };

    return (
        <Observer
            render={() => (
                <>
                    <CardTypeButtons activityStore={props.activityStore} onCardTypeChanged={handleCardTypeChanged} />
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        style={worksheetOptionsBackgroundStyle}
                    >
                        <Grid container justify="center">
                            <Grid item>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography variant={"h6"}>{strings.gridSize}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <SimpleRadio
                                            labels={gridSizeLabels}
                                            values={gridSizeValues}
                                            defaultValue={
                                                props.activityStore.activityOptions.bingoCardsOptions.gridSize
                                            }
                                            direction="column"
                                            onValueChanged={handleGridSizeChanged}
                                        ></SimpleRadio>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item style={{ margin: "0px 20px 0px 35px" }}>
                                <Divider orientation="vertical" />
                            </Grid>
                            <Grid item>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography variant={"h6"}>{strings.cardsPerPage}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <SimpleRadio
                                            labels={cardsPerPageLabels}
                                            values={cardsPerPageValues}
                                            defaultValue={
                                                props.activityStore.activityOptions.bingoCardsOptions.cardsPerPage
                                            }
                                            direction="column"
                                            onValueChanged={handleCardsPerPageChanged}
                                        ></SimpleRadio>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        style={worksheetOptionsBackgroundStyle}
                    >
                        <Grid item>
                            <Grid container justify="center" alignItems="center">
                                <Grid item>
                                    <Typography variant={"h6"}>{"Number of cards to create:"}</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        onChange={handleNumberOfCardsChanged}
                                        inputProps={{
                                            style: { fontSize: 28, textAlign: "right", maxWidth: 70 },
                                            min: 1,
                                            max: 40,
                                            type: "number",
                                        }}
                                        style={{ paddingLeft: 10 }}
                                        value={
                                            props.activityStore.activityOptions.bingoCardsOptions.numberOfCardsToCreate
                                        }
                                    ></TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        />
    );
};

export default BingoCardsOptions;
