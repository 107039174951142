import Activity from "./Activity";

export enum InteractiveWorksheetType {
    Matching = "Matching",
    MissingLetters = "Missing Letters",
}

export default class InteractiveWorksheet extends Activity {
    // public static allItems = [
    //     new InteractiveWorksheet(InteractiveWorksheetType.Matching),
    //     new InteractiveWorksheet(InteractiveWorksheetType.MissingLetters)
    // ];
    public static allItems = [];

    public type: InteractiveWorksheetType;

    protected get prefix() {
        return "interactive-worksheets";
    }
}
