import * as React from "react";
import { Observer } from "mobx-react-lite";

// Material UI
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";

export interface SimpleRadioProps extends React.Props<any> {
    title?: string;
    labels: string[];
    values: string[];
    defaultValue?: string;
    onValueChanged: (value: string) => void;
    isDisabled?: boolean;
    direction?: "row" | "column";
}

const SimpleRadio = (props: SimpleRadioProps) => {
    let [selectedValue, setSelectedValue] = React.useState<string>(
        props.defaultValue ? props.defaultValue : props.values[0]
    );

    const handleOnChanged = (event: any, value: any) => {
        setSelectedValue(value);
        props.onValueChanged(value);
    };

    if (props.labels.length !== props.values.length) {
        return null;
    }

    let disabled = props.isDisabled === undefined ? false : props.isDisabled;
    let direction = props.direction === undefined ? "row" : props.direction;

    return (
        <Observer
            render={() => (
                <>
                    {props.title && <Typography variant="subtitle1">{props.title}</Typography>}
                    <div style={{ paddingLeft: 10 }}>
                        <RadioGroup
                            row={direction === "row" ? true : false}
                            name={props.title}
                            value={selectedValue}
                            onChange={handleOnChanged}
                        >
                            {props.labels.map((label, index) => (
                                <div key={index}>
                                    <FormControlLabel
                                        disabled={disabled}
                                        style={{ marginTop: -5 }}
                                        value={props.values[index]}
                                        control={<Radio />}
                                        label={label}
                                    />
                                </div>
                            ))}
                        </RadioGroup>
                    </div>
                </>
            )}
        />
    );
};

export default SimpleRadio;
