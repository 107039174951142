import * as React from "react";
import { observable, action, runInAction } from "mobx";
import { observer } from "mobx-react";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

// Stores
import RootStore from "../stores/RootStore";

// Utils
import { strings } from "../core/localizedStrings";
import { Colors } from "../core/utils/Colors";
import FirebaseFunctions from "../core/firebase/FirebaseFunctions";
import { PREVIEW_TIMER_INDEX } from "layers";
import { MY_ACCOUNT_PATH, REGISTRATION_PATH } from "../constants";
import { Paper } from "@material-ui/core";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

interface PreviewTimerProps {
    rootStore: RootStore;
    onUsedCreditCallback: () => void;
}

const PREVIEW_TOTAL_TIME = 90;

@observer
export default class PreviewTimer extends React.Component<PreviewTimerProps> {
    @observable private timeRemaining = PREVIEW_TOTAL_TIME;
    @observable private isTimerComplete = false;
    @observable private isUsingCredit = false;
    @observable private isVisible = true;

    private timer: any;

    constructor(props: PreviewTimerProps) {
        super(props);
        this.timer = setInterval(() => this.onTimerFired(), 1000);
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    public render() {
        if (this.isTimerComplete) {
            return null;
        }

        return (
            <Col
                xs={12}
                style={{
                    position: "absolute",
                    top: 100,
                    right: 10,
                    zIndex: PREVIEW_TIMER_INDEX,
                    opacity: this.props.rootStore.shouldShowPreviewTimer ? 1 : 0,
                }}
            >
                <Row center="xs">
                    {this.isUsingCredit ? (
                        <Col>
                            <Row center="xs">
                                <CircularProgress />
                            </Row>
                        </Col>
                    ) : (
                        this.renderContent()
                    )}
                </Row>
            </Col>
        );
    }

    private renderContent() {
        let message: string | React.ReactText[] = strings.activityPreviewMessage;
        let button: JSX.Element;
        if (
            !this.props.rootStore.userStore ||
            !this.props.rootStore.userStore.user ||
            !this.props.rootStore.userStore.user.uid
        ) {
            // Do nothing
        } else if (this.props.rootStore.shouldShowPreviewTimer) {
            if (this.props.rootStore.userStore.user.isAnonymous) {
                message = "";
                button = undefined;
            } else if (this.props.rootStore.userStore.user.remainingCredits === 0) {
                message = strings.noRemainingCredits;
                button = undefined;
            } else {
                message =
                    this.props.rootStore.userStore.user.remainingCredits === 1
                        ? (message = strings.oneRemainingCredit)
                        : strings.formatString(
                              strings.remainingCreditsFormat,
                              this.props.rootStore.userStore.user.remainingCredits
                          );
                button = (
                    <Button color="primary" onClick={this.onUseCreditClicked} style={{ marginRight: 5 }}>
                        {strings.useCredit}
                    </Button>
                );
            }
        }
        return (
            <Paper>
                <Col xs={12} style={{ margin: 5, marginLeft: 20 }}>
                    <Row middle="xs">
                        <Typography variant="subtitle1" style={{ marginRight: 10 }}>
                            <span style={{ color: Colors.red, textShadow: "black 1px 1px" }}>
                                {strings.activityPreview}:
                            </span>
                            <span>{` ${this.secondsToMinuteFormat(this.timeRemaining)}`} </span>
                        </Typography>
                        {button && (
                            <div style={{ marginRight: 5 }}>
                                {button}
                                <Button color="primary" onClick={this.onUpgradeClicked}>
                                    {strings.upgradeToPremium}
                                </Button>
                            </div>
                        )}
                    </Row>
                    {this.props.rootStore.userStore.user.isAnonymous && (
                        <Row style={{ marginRight: 5 }}>
                            <Button color="primary" onClick={this.onRegisterClicked}>
                                {strings.signUpForFree}
                            </Button>
                        </Row>
                    )}
                    <Row>
                        <Typography variant="subtitle1">{message}</Typography>
                    </Row>
                </Col>
            </Paper>
        );
    }

    @action
    private onTimerFired = () => {
        let { authStore } = this.props.rootStore;

        if (this.isVisible) {
            this.timeRemaining -= 1;
        }

        if (this.timeRemaining === 0) {
            clearInterval(this.timer);
            this.isTimerComplete = true;
            if (authStore.isSignedIn) {
                authStore.setShouldShowUseCreditDialog(true);
            } else {
                authStore.setShouldShowModalRegisterDialog(true);
            }
        }
    };

    @action
    private onUseCreditClicked = async () => {
        this.isUsingCredit = true;
        let success = await FirebaseFunctions.useCredit(
            this.props.rootStore.userStore.user,
            this.props.rootStore.activityStore.currentActivity.activityType
        );

        if (success) {
            runInAction(() => {
                this.isUsingCredit = false;
                clearInterval(this.timer);
                this.props.rootStore.authStore.setShouldShowModalRegisterDialog(false);
                this.props.onUsedCreditCallback();
            });
        } else {
            this.props.rootStore.showErrorMessage(strings.problemUsingCredit);
        }
    };

    private onRegisterClicked = () => {
        this.props.rootStore.setRoute(REGISTRATION_PATH);
    };

    private onUpgradeClicked = () => {
        this.props.rootStore.authStore.setShouldShowModalRegisterDialog(false);
        this.props.rootStore.setRoute(MY_ACCOUNT_PATH);
    };

    private secondsToMinuteFormat(totalSeconds: number) {
        let minutes = 0;
        let seconds = totalSeconds;
        if (seconds >= 60) {
            minutes = Math.floor(totalSeconds / 60);
            seconds = totalSeconds % 60;
        }
        if (seconds < 10) {
            return `${minutes}:0${seconds}`;
        } else {
            return `${minutes}:${seconds}`;
        }
    }
}
