import * as React from "react";
import {
    FlashcardActivity,
    FlashcardActivityType,
    Game,
    GameType,
    Quiz,
    Tool,
    ToolType,
    Worksheet,
    WorksheetType,
} from "models";
import HangmanOptions from "components/activities/games/Hangman/HangmanOptions";
import { ActivityStore } from "stores";
import CardMatchOptions from "./games/CardMatch/CardMatchOptions";
import AngryWordsOptions from "./games/AngryWords/AngryWordsOptions";
import InteractiveFlashcardsOptions from "./flashcards/InteractiveFlashcardsOptions";
import QuizOptions from "models/activities/QuizOptions";
import TicTacToeOptions from "./games/TicTacToe/TicTacToeOptions";
import CrazyCupsOptions from "models/activities/CrazyCupsOptions";
import BattleshipOptions from "./games/Battleship/BattleshipOptions";
import DartboardSelectorOptions from "./tools/dartboardSelector/DartboardSelectorOptions";
import RandomNamePickerOptions from "./tools/randomNamePicker/RandomNamePickerOptions";
import BingoCardsOptions from "./worksheets/bingoCards/BingoCardsOptions";
import MatchingWorksheetOptions from "./worksheets/matching/MatchingWorksheetOptions";
import ConnectTheLettersWorksheetOptions from "./worksheets/connectTheLetters/ConnectTheLettersWorksheetOptions";
import TracingWorksheetOptions from "./worksheets/tracing/TracingWorksheetOptions";
import ReadingWorksheetOptions from "./worksheets/reading/ReadingWorksheetOptions";
import SharkBaitOptions from "./games/SharkBait/SharkBaitOptions";
import PrintableFlashcardsOptions from "./flashcards/PrintableFlashcards/PrintableFlashcardsOptions";
import HotOrColdOptions from "./games/HotOrCold/HotOrColdOptions";
import WordsearchWorksheetOptions from "./worksheets/wordsearch/WordsearchWorksheetOptions";
import MissingLettersWorksheetOptions from "./worksheets/missingLetters/MissingLettersWorksheetOptions";
import TicTacToeWorksheetOptions from "./worksheets/ticTacToe/TicTacToeWorksheetOptions";
import QAndAWorksheetOptions from "./worksheets/qAndA/QAndAWorksheetOptions";
import SentenceDecoderWorksheetOptions from "./worksheets/sentenceDecoder/SentenceDecoderWorksheetOptions";
import SpeedOptions from "./games/Speed/SpeedGameOptions";
import MemoryWheelGameOptions from "./games/MemoryWheel/MemoryWheelGameOptions";
import SentenceScrambleWorksheetOptions from "./worksheets/sentenceScramble/SentenceScrambleWorksheetOptions";
import WordScrambleWorksheetOptions from "./worksheets/wordScramble/WordScrambleWorksheetOptions";
import BingoCallerOptions from "./games/BingoCaller/BingoCallerOptions";
import RacetrackWorksheetOptions from "./worksheets/racetrack/RacetrackWorksheetOptions";
import LaddersSlidesWorksheetOptions from "./worksheets/laddersSlides/LaddersSlidesWorksheetOptions";

export class ActivityOptionsFactory {
    static get(activityStore: ActivityStore): JSX.Element {
        let activity = activityStore.currentActivity;
        if (activity instanceof FlashcardActivity) {
            switch (activity.activityType) {
                case FlashcardActivityType.Interactive:
                    return <InteractiveFlashcardsOptions activityStore={activityStore} />;
                case FlashcardActivityType.Printable:
                    return <PrintableFlashcardsOptions activityStore={activityStore} />;
            }
        } else if (activity instanceof Quiz) {
            return <QuizOptions activityStore={activityStore} />;
        } else if (activity instanceof Game) {
            switch (activity.activityType) {
                case GameType.Hangman:
                    return <HangmanOptions activityStore={activityStore} />;
                case GameType.CardMatch:
                    return <CardMatchOptions activityStore={activityStore} />;
                case GameType.AngryWords:
                    return <AngryWordsOptions activityStore={activityStore} />;
                case GameType.TicTacToe:
                    return <TicTacToeOptions activityStore={activityStore} />;
                case GameType.CrazyCups:
                    return <CrazyCupsOptions activityStore={activityStore} />;
                case GameType.Battleship:
                    return <BattleshipOptions activityStore={activityStore} />;
                case GameType.SharkBait:
                    return <SharkBaitOptions activityStore={activityStore} />;
                case GameType.HotOrCold:
                    return <HotOrColdOptions activityStore={activityStore} />;
                case GameType.Speed:
                    return <SpeedOptions activityStore={activityStore} />;
                case GameType.MemoryWheel:
                    return <MemoryWheelGameOptions activityStore={activityStore} />;
                case GameType.Bingo:
                    return <BingoCallerOptions activityStore={activityStore} />;
            }
        } else if (activity instanceof Tool) {
            switch (activity.activityType) {
                case ToolType.RandomNamePicker:
                    return <RandomNamePickerOptions activityStore={activityStore} />;
                case ToolType.DartboardSelector:
                    return <DartboardSelectorOptions activityStore={activityStore} />;
            }
        } else if (activity instanceof Worksheet) {
            switch (activity.activityType) {
                case WorksheetType.Bingo:
                    return <BingoCardsOptions activityStore={activityStore} />;
                case WorksheetType.Matching:
                    return <MatchingWorksheetOptions activityStore={activityStore} />;
                case WorksheetType.ConnectTheLetters:
                    return <ConnectTheLettersWorksheetOptions activityStore={activityStore} />;
                case WorksheetType.Tracing:
                    return <TracingWorksheetOptions activityStore={activityStore} />;
                case WorksheetType.Reading:
                    return <ReadingWorksheetOptions activityStore={activityStore} />;
                case WorksheetType.Wordsearch:
                    return <WordsearchWorksheetOptions activityStore={activityStore} />;
                case WorksheetType.TicTacToe:
                    return <TicTacToeWorksheetOptions activityStore={activityStore} />;
                case WorksheetType.MissingLetters:
                    return <MissingLettersWorksheetOptions activityStore={activityStore} />;
                case WorksheetType.QAndA:
                    return <QAndAWorksheetOptions activityStore={activityStore} />;
                case WorksheetType.SentenceDecoder:
                    return <SentenceDecoderWorksheetOptions activityStore={activityStore} />;
                case WorksheetType.SentenceScramble:
                    return <SentenceScrambleWorksheetOptions activityStore={activityStore} />;
                case WorksheetType.WordScramble:
                    return <WordScrambleWorksheetOptions activityStore={activityStore} />;
                case WorksheetType.Racetrack:
                    return <RacetrackWorksheetOptions activityStore={activityStore} />;
                case WorksheetType.LaddersAndSlides:
                    return <LaddersSlidesWorksheetOptions activityStore={activityStore} />;
            }
        }
    }
}
