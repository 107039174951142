/* tslint:disable */
export const dutch = {
    absent: "Afwezig",
    absentStudentsFormat: "{0} afwezig",
    accountRegistration: "Account registratie",
    active: "Actief",
    activityPreview: "Activiteitsvoorbeeld",
    activityPreviewMessage: "Meld u aan of registreer u voor een gratis account.",
    add: "Toevoegen",
    addAccount: "Account toevoegen",
    addCard: "Voeg een kaart toe",
    addCards: "Voeg kaarten toe",
    addFolder: "Map toevoegen",
    addGroupStudentAccount: "Groepsaccount voor studenten toevoegen",
    addKeyword: "Voeg trefwoord toe",
    addName: "Voeg naam toe",
    addTeacherAccount: "Docentaccount toevoegen",
    addToFavorites: "Toevoegen aan favorieten",
    addWithPlus: "+ Toevoegen",
    additionalResources: "Aanvullende bronnen",
    admin: "beheerder",
    affirmative: "Bevestigend",
    afterTrial:
        "Na de proefperiode van 30 dagen kunt u de site blijven gebruiken als Basic-lid, of upgrade terug naar Premium.",
    all: "Allemaal",
    allFieldsRequired: "Alle velden zijn verplicht.",
    allowCardsToBeFlipped: "Laat kaarten worden omgedraaid",
    allowedToChangePassword: "leraar mag wachtwoord wijzigen",
    annual: "jaar-",
    annualSubscription: "Jaarlijks abonnement",
    answer: "Antwoord",
    audioClipLanguageLabel: "Taal voor audioclip",
    backToWorksheets: "Terug naar werkbladen",
    basic: "basis-",
    basicMemberCredits:
        "* Basisleden ontvangen 8 maandelijkse credits. Als alle credits zijn gebruikt, de toegang tot de site is beperkt tot de eerste dag van de volgende maand.",
    billedAnnually: "Jaarlijks gefactureerd",
    billedAnnuallyFormat: "Jaarlijks gefactureerd ({0})",
    billedMonthly: "Maandelijks gefactureerd",
    billedMonthlyFormat: "Gefactureerd maandelijks ({0})",
    billingFrequency: "Factuurfrequentie",
    boy: "Jongen",
    boys: "Jongens",
    browseAllCategories: "Blader / Bewerk categorieën",
    browseAllLists: "Blader / Bewerk lijsten",
    cancel: "annuleren",
    cancelSubscription: "Annuleer abonnement",
    cannotAddManagedAccountsInTrial: "Beheerde accounts kunnen niet worden toegevoegd tijdens de proefperiode.",
    cannotBeEmpty: "Kan niet leeg zijn.",
    card: "Kaart",
    cardLower: "kaart",
    cardsLower: "kaarten",
    category: "Categorie",
    category2: "categorie2",
    categoryCharactersNotSupported: "Deze categorie bevat tekens die nog niet door deze activiteit worden ondersteund.",
    categoryTypeNotSupported: "Dit categorietype wordt nog niet ondersteund met deze categorie.",
    changeCategory: "Verander van categorie",
    changeEditStudents: "Verander / Bewerk studenten",
    changePassword: "Wachtwoord wijzigen",
    checkEmailForVerifcation: "Controleer uw e-mail om de activering van uw account te voltooien.",
    chinese: "Chinese",
    className: "Naam van de klasse",
    clear: "Duidelijk",
    clearFilters: "Filters wissen",
    clearSelectedImage: "Geselecteerde afbeelding wissen",
    clearSelectedImages: "Wis geselecteerde afbeeldingen",
    clickHereTo: "Geen account? Klik hier om",
    confirmChangeCategory:
        "Als u de categorie wijzigt, wordt de activiteit opnieuw ingesteld. Weet je zeker dat je dit wilt wijzigen?",
    confirmChangeClassList:
        "Als u de leerlingenlijst wijzigt, wordt de activiteit opnieuw ingesteld. Weet je zeker dat je dit wilt wijzigen?",
    confirmChangeTeamNumber:
        "Als u het aantal teams wijzigt, wordt de activiteit opnieuw ingesteld. Weet je zeker dat je dit wilt wijzigen?",
    confirmRestartSubscription:
        "Weet je zeker dat je je abonnement opnieuw wilt starten? Uw creditcard wordt pas in rekening gebracht tot {0}.",
    contact: "Contact",
    contactAdminMessage: " Als dit probleem zich blijft voordoen, neem dan contact op met de sitebeheerder.",
    contactDescription:
        "Laat het ons weten als je vragen of problemen hebt. Stuur gewoon een bericht hieronder en we nemen zo snel mogelijk contact met u op.",
    contactFormSubmitted: "Bedankt! Je bericht is verzonden.",
    copy: "Kopiëren",
    copyLower: "kopiëren",
    createCardFromSelectedImage: "Maak een kaart van de geselecteerde afbeelding",
    createCardsFromSelectedImages: "Maak kaarten van {0} geselecteerde afbeeldingen",
    createCopyIn: "Maak een kopie in:",
    createCustomLists: "Maak aangepaste lijsten",
    createNewList: "Maak een nieuwe lijst",
    createSelectCategory: "Maak of selecteer een les",
    createSelectCategoryDescription: "Maak een aangepaste les in seconden of zoek uit duizenden bestaande lessen.",
    createSelectClassList: "Maak of selecteer een studentenlijst",
    createStudentNameLists: "Lijsten met studentnamen maken",
    createTeams: "Creëer teams",
    createdBy: "Gemaakt door",
    createdOn: "Gemaakt op",
    creditCard: "Kredietkaart",
    creditNeeded: "1 tegoed per gebruik",
    currentSubscriptionRequiredForManagedAccounts:
        "Een actief abonnement is vereist. Start uw abonnement opnieuw om extra accounts toe te voegen.",
    customizeHeader: "{{Aanpassen}} voor elke les",
    customizeText:
        "Neem een eenvoudige vocabulaire lijst of lijst met vragen en maak er een boeiend van activiteit in seconden. Zodra een lijst is gemaakt, kan deze worden gebruikt met elke activiteit op de hele site.",
    dataBeingMigratedFromBarryFunEnglish:
        "Uw accountgegevens worden gemigreerd vanuit BarryFunEnglish. Dit hoeft maar één keer gedaan te worden. Afhankelijk van de hoeveelheid gegevens, kan dit overal van een\n        paar seconden tot een paar uur. Als u na een paar uur al uw gegevens niet ziet, neemt u contact op met\n        de sitebeheerder.",
    delete: "Verwijder",
    deleteCategoryConfirmation: "Weet je zeker dat je deze categorie permanent wilt verwijderen?",
    deleteFavoriteConfirmation: "Weet je zeker dat je dit van je favorieten wilt verwijderen?",
    deleteFolderConfirmation: "Weet je zeker dat je deze map en de gehele inhoud ervan definitief wilt verwijderen?",
    deleteStudentListConfirmation: "Weet je zeker dat je deze lijst wilt verwijderen?",
    discount: "Korting ({0}%):",
    discounts: "Kortingen:",
    done: "Gedaan",
    dutch: "Nederlands",
    edit: "Bewerk",
    editProfile: "Bewerk profiel",
    email: "E-mail",
    emailAlreadyRegistered: "Email adres is al geregistreerd",
    emailOrUsername: "E-mail of gebruikersnaam",
    emailRequiredError: "Gebruikersnaam is vereist.",
    emailSentTo: "E-mail verzonden naar {0}.",
    endGame: "Einde spel",
    english: "Engels",
    enterEmailUsedToRegister: "Voer het e-mailadres in dat u hebt gebruikt om u voor de site te registreren:",
    enterIncorrectAnswers: "Voer mijn eigen foute antwoorden in",
    enterQuestion: "Voer vraag in",
    enterText: "Tekst invoeren:",
    enterValidEmail: "Vul een geldig e-mailadres in.",
    enterVocabAndQuestion: "Voer woorden en vraagwoorden in",
    enterVocabulary: "Voer woordenschat in",
    existingAccount: " bestaand account",
    expirationDateFormat: "Vervaldatum: {0}",
    expired: "Verlopen",
    expires: "verloopt",
    expressionInputTextField1Label: "Tekstveld 1 (vraag)",
    expressionInputTextField2Label: "Tekstveld 2 (antwoord)",
    favorites: "favorieten",
    flashcards: "flashcards",
    folderName: "Naam van de map:",
    forClassroomHeader: "Ontworpen voor het {{classroom}}",
    forClassroomText:
        "Games en activiteiten zijn ontworpen voor gebruik in de klas en werk geweldig met grote of kleine klassen. Games kunnen tussen twee tot vijf teams hebben en studentnamenlijsten kunnen voor willekeurige selectie worden ingevoerd.",
    forgotPassword: "Wachtwoord vergeten?",
    free: "Gratis",
    french: "Frans",
    games: "Spellen",
    gender: "Geslacht",
    german: "Duitse",
    girl: "Meisje",
    girls: "meisjes",
    girlsVsBoys: "Girls vs Boys",
    greek: "Grieks",
    groupNumberFormat: "Groep {0}",
    homePageContactMessage: "Heeft u {{vragen}} voor ons?",
    imageOverlayGraphic: "Afbeelding overlay afbeelding",
    imageWithText: "Afbeelding met enkel tekstveld",
    imageWithTextDescription:
        "Deze lijsten zijn ideaal voor het oefenen van woordenschat, lezen en spreken. Ze werken met bijna elke activiteit op de site.",
    imageWithTwoText: "Afbeelding met twee tekstvelden",
    imageWithTwoTextDescription:
        "Deze lijsten worden gebruikt voor het stellen van vragen over een afbeelding of voor het vertalen tussen twee talen",
    imagesSelectorInstructions: "Selecteer of upload afbeeldingen om kaarten te maken van:",
    interactive: "Interactieve",
    interactiveFlashcards: "Interactieve Flashcards",
    introduce: "Voorstellen",
    invalidEmail: "Ongeldig email adres.",
    invalidPassword: "Wachtwoorden moeten minimaal 6 tekens lang zijn.",
    invalidUsername: "Ongeldige gebruikersnaam.",
    invalidUsernameOrPassword: "U hebt een ongeldige gebruikersnaam of wachtwoord ingevoerd",
    italian: "Italiaans",
    japanese: "Japans",
    keywordsLabel: "trefwoorden",
    korean: "Koreaans",
    language: "Taal",
    languageTitle: "Taal",
    learn: "Leren",
    learnDescription: "Introduceer een nieuwe les of recensie met interactieve of afdrukbare flash-kaarten.",
    lesson: "Les",
    lessonDescription: "Mix van één afbeelding en afbeelding met twee tekstvelden",
    lessonTypeSelectorTitle: "Zou je woordenschat of vragen uit deze les willen oefenen?",
    listAccessibility: "Lijst toegankelijkheid",
    listTitleLabel: "Lijst titel",
    listTypeTitle: "Lijsttype",
    loggingIn: "Inloggen",
    loginToCopy: "U moet zijn aangemeld om lijsten te kopiëren.",
    loginToCreateFolders: "U moet zijn aangemeld om mappen te kunnen maken.",
    loginToCreateLists: "U moet zijn aangemeld om aangepaste lijsten te maken.",
    loginToEditLists: "U moet zijn aangemeld om lijsten te bewerken.",
    loginToFavorite: "Je moet ingelogd zijn om favorieten op te slaan.",
    logout: "Uitloggen",
    mainPageText: "Aangepaste taalleeractiviteiten voor elke les",
    managedAccounts: "Beheerde accounts",
    managedAccountsDescription:
        "Voeg beheerde accounts toe als u meerdere lidmaatschappen onder één abonnement wilt hebben. Kortingen worden aangeboden op basis van het aantal totale accounts.",
    maxFilesUploadAttempt: "U kunt maximaal {0} bestanden tegelijkertijd uploaden.",
    membershipTypes: "Lidmaatschapstypes",
    message: "Bericht",
    misses: "Misses",
    monthly: "Maandelijks",
    monthlySubscription: "Maandelijks abonnement",
    move: "verhuizing",
    moveFolderTo: "Verplaats de map naar:",
    moveListTo: "Verplaats lijst naar:",
    multipleChoiceInstructions: "Wat wil je met multiple choice-activiteiten gebruiken voor foute antwoorden?",
    multipleLanguageSupport: "Meerdere talen ondersteund",
    mustHaveOneIncorrectAnswers: "U moet minimaal één onjuist antwoord invoeren.",
    myAccount: "Mijn rekening",
    myClasses: "Mijn lessen",
    myImages: "Mijn afbeeldingen",
    myLists: "Mijn lijsten",
    name: "Naam",
    negative: "Negatief",
    newFolder: "Nieuwe map",
    nextBillingDate: "Volgende factuurdatum: {0} ({1})",
    no: "Nee",
    noAcceptableFilesFound:
        "Geen geldige bestanden gevonden. Bestanden zijn beperkt tot een maximale grootte van {0} MB.",
    noCardOnFile: "Geen kaart in het dossier",
    noCreditCardRequired: "Geen kredietkaart nodig.",
    noRemainingCredits:
        "Geen resterende credits deze maand. Registreer voor een premium account voor onbeperkte toegang.",
    none: "Geen",
    notActivated: "Niet geactiveerd",
    notActive: "Niet actief",
    notChargedUntilFormat: "De creditcard wordt niet uitbetaald tot {0}.",
    notChargedUntilTrialComplete: "Uw creditcard wordt pas belast aan het einde van de proefperiode.",
    numberOfTeams: "Aantal teams",
    off: "Uit",
    ok: "OK",
    on: "Op",
    oneRemainingCredit: "U heeft deze maand nog 1 tegoed.",
    options: "opties",
    or: "of",
    page: "Pagina",
    pageNotFound: "Pagina niet gevonden",
    password: "Wachtwoord",
    passwordLengthError: "Wachtwoord moet bestaan uit minstens 6 karakters.",
    passwordRequiredError: "Een wachtwoord is verplicht",
    passwordResetEmailHasBeenSendMessage: "Controleer uw e-mail op een link om uw wachtwoord opnieuw in te stellen.",
    pay: "Betalen",
    payBalance: "Betaal balans",
    paymentMethod: "Betalingsmiddel",
    pendingSubscription: "In afwachting van abonnement",
    play: "Spelen",
    playDescription: "Spannende spellen voor grote en kleine klassen",
    pleaseAddCardForRestart: "U moet een creditcard toevoegen voordat u uw abonnement opnieuw start.",
    portuguese: "Portugees",
    practice: "Praktijk",
    practiceDescription: "Interactieve en afdrukbare werkbladen",
    premium: "Premie",
    premiumPriceFormatMonth: "$ {0} / maand",
    premiumPriceFormatYear: "$ {0} / jaar",
    premiumTrial: "Premium (proef)",
    preview: "Voorbeeld",
    price: "Prijs",
    print: "Afdrukken",
    printDescription: "Druk flashcards of werkbladen af voor offline oefenen",
    printable: "Printable",
    printableFlashcards: "Afdrukbare Flashcards",
    private: "Privaat",
    problemSubmittingContactForm: "Er is een probleem opgetreden bij het verzenden van uw bericht.",
    problemUpdatingUserErrorMessage: "Er is een fout opgetreden bij het bijwerken van gebruikersgegevens.",
    problemUsingCredit: "Er was een probleem met het gebruik van uw credits.",
    profile: "Profiel",
    proratedAmountDue: "Nu verschuldigd bedrag: {0}",
    public: "Openbaar",
    publicLists: "Community-lijsten",
    quellMessage: "Laat dit bericht niet meer zien",
    question: "Vraag",
    questions: "vragen",
    quiz: "Quiz",
    randomize: "willekeurig",
    randomizeAndReset: "Willekeurig maken en opnieuw instellen",
    recent: "recent",
    register: "registreren",
    registerUsernamePassword: "Registreer met gebruikersnaam / wachtwoord",
    rejectedFileFormat:
        "{0} bestand is niet geüpload. Bestand is mogelijk te groot of van het bestand verkeerd bestandstype. Alleen afbeeldingsbestanden van 500 KB of kleiner worden geaccepteerd.",
    rejectedFilesFormat:
        "{0} bestanden zijn niet geüpload. Bestanden zijn mogelijk te groot of van de verkeerd bestandstype. Alleen afbeeldingsbestanden van 500 KB of kleiner worden geaccepteerd.",
    remainingCreditsFormat: "Je hebt deze maand nog {0} credits.",
    removeAllLetters: "Verwijder alle letters",
    removeFromFavorites: "Verwijder van favorieten",
    removeManagedUserAccountConfirmation:
        "Dit account wordt verwijderd en de gebruiker niet meer toegestaan om in te loggen. Weet je zeker dat je dit account permanent wilt verwijderen?",
    resendEmail: "Email opnieuw verzenden",
    reset: "Reset",
    restartSubscription: "Start Abonnement opnieuw",
    restartSubscriptionMessage: "Selecteer een factuurfrequentie voor uw abonnement.",
    review: "Beoordeling",
    reviewDescription: "Meerkeuze quizzen voor luisteren, lezen, schrijven en spreken",
    russian: "Russisch",
    sameAsTextField1Label: "Hetzelfde als tekstveld 1",
    sampleClass: "Voorbeeldklasse",
    save: "Opslaan",
    search: "Zoeken",
    select: "Selecteer ...",
    selectACategory: "Kies een categorie",
    selectCategoryBeforeActivity: "U moet een categorie selecteren voordat u een activiteit selecteert.",
    selectFolderToAddFavoriteTo: "Selecteer een map om een favoriet toe te voegen aan:",
    selectStudentList: "Selecteer een studentenlijst",
    showCategoryDetails: "toon details",
    signIn: "Log in",
    signInRegister: "Inloggen / Registreren",
    signInUsernamePassword: "Inloggen met gebruikersnaam / wachtwoord",
    signUpForFree: "Gratis inschrijven",
    signUpForFreeMessage: "Registreer nu voor een gratis Premium-account voor 30 dagen!",
    singleTeacher: "Enkele leraar",
    singleText: "Enkele tekstveld",
    singleTextDescription:
        "Deze lijsten zijn geweldig voor het lezen en spellen. Zij zijn ook nuttig voor activiteiten waarvoor geen afbeelding nodig is.",
    siteImages: "Site afbeeldingen",
    siteLists: "Sitelijsten",
    sortByPopularity: "populariteit",
    sortByRecentlyCreated: "Recent gemaakt",
    sortByRelevance: "Relevantie",
    sortByTitle: "Titel",
    sortByTypeTitle: "Sorteer op",
    spanish: "Spaans",
    start: "Begin",
    startSubscription: "Begin een abonnement",
    startSubscriptionAfterTrial:
        "Start uw abonnement om uw 'Premium'-lidmaatschap verder te zetten de proef is voltooid.",
    studentGroup: "studentengroep",
    studentGroups: "studentengroepen",
    studentList: "Studentenlijst",
    subject: "Onderwerpen",
    submit: "voorleggen",
    subscribe: "abonneren",
    subscription: "Abonnement",
    subscriptionDescriptionFormat: "Abonnementstype: {0}",
    subscriptionType: "Abonnementstype",
    subtotal: "Subtotaal:",
    support: "Contact opnemen met ondersteuning",
    teacher: "leraar",
    teacherCountFormat: "Aantal docenten: {0}",
    teachers: "leraren",
    teachingTools: "Leermiddelen",
    teamNumberFormat: "Team {0}",
    tenOrMore: "Tien of meer",
    textField1Label: "(Vraag)",
    textField2Label: "(Antwoord)",
    textToSpeechSupportFormat: "Audioclips automatisch gemaakt met behulp van tekst-naar-spraaktechnologie",
    thousandsOfHeader: "{{Duizenden}} categorieën waaruit u kunt kiezen",
    thousandsOfText:
        "Wil je geen eigen categorieën maken? Bladeren door duizenden gemaakt door de KinteractiveLearning-community. Elke dag worden er meer toegevoegd!",
    threeOrMore: "Drie of meer",
    titleCannotBeEmptyInfoMessage: "Titel mag niet leeg zijn. Vul alstublieft een titel in",
    tools: "Hulpmiddelen",
    toolsDescription: "Groeps- en teammakers, willekeurige studentenselectie, stopwatch en scorebord",
    totalDue: "Totaal verschuldigd:",
    totalManagedAccounts: "Totaal beheerde accounts:",
    totalStudentsFormat: "{0} studenten",
    turkish: "Turks",
    twoTextOnly: "Twee tekstvelden",
    twoTextOnlyDescription:
        "Deze lijsten worden gebruikt voor het stellen van vragen die niet nodig zijn en beeld, of voor het vertalen van twee talen",
    type: "Type",
    unlimited: "Onbeperkt",
    update: "Bijwerken",
    updateEmailError: "Er is een fout opgetreden bij het updaten van het e-mailadres.",
    uploadImages: "upload afbeeldingen",
    uploadImagesDropzoneInstructions: "Zet afbeelding (en) neer, of klik om door uw computer te bladeren.",
    uploadMessage: "{0} afbeeldingen uploaden.",
    uploadProfileImageError: "Er is een fout opgetreden bij het uploaden van een profielafbeelding.",
    useAsVocabulary: "Toevoegen aan woordenlijst",
    useCredit: "Gebruik tegoed",
    useOtherAnswersAsIncorrect: "Gebruik andere kaarten juiste antwoorden",
    username: "Gebruikersnaam",
    usernameOptional: "Gebruikersnaam (optioneel)",
    usernameRequiredError: "Gebruikersnaam is vereist.",
    usernameTaken: "Gebruikersnaam is al bezet",
    usernameUnavailable: "gebruikersnaam is niet beschikbaar",
    vocabulary: "Woordenschat",
    worksheetCreators: "Worksheet Creators",
    worksheets: "werkbladen",
    yes: "Ja",
    getStarted: "",
    requiresFlash: "",
    onlySupportsLatinBasedLanguages: "",
    upgradeToPremium: "",
    startReview: "",
    numberOfGroups: "",
    hideAnswer: "",
    autoPlayAudio: "",
    imageBank: "",
    doubleSidedCards: "",
    ignoreFlippedCardsForReview: "",
    soft: "",
    medium: "",
    strong: "",
    continuePlaying: "",
    languageNotSupported: "",
    createLink: "",
    pinActivityToDashboard: "",
    enterName: "",
    createStudentLink: "",
    expiration: "",
    oneDay: "",
    oneWeek: "",
    never: "",
    link: "",
    copyToClipboard: "",
    pinnedActivities: "",
    studentActivityLinks: "",
    numberOfCards: "",
    matchType: "",
    imageType: "",
    otherOptions: "",
    wordImage: "",
    imageImage: "",
    wordWord: "",
    activity: "",
    seeLinkVisitors: "",
    recentHistory: "",
    startTime: "",
    completedTime: "",
    results: "",
    iDontKnow: "",
    iKnowTheAnswer: "",
    seconds: "",
    minutes: "",
    hours: "",
    pause: "",
    set: "",
    audioHeading: "",
    playAudioOnCorrect: "",
    playAudioOnWrong: "",
    answerTypeHeading: "",
    multipleChoiceAnswerTypeOption: "",
    rightOrWrongAnswerTypeOption: "",
    showNumbersHeading: "",
    showNumbersMultipleChoice: "",
    timerHeading: "",
    showTimerMultipleChoice: "",
    game: "",
    correct: "",
    wrong: "",
    teams: "",
    imagesInGrid: "",
    multipleChoiceOptions: "",
    playAudioOnSelection: "",
    classicModeOptions: "",
    selectAll: "",
    flipCardsToFront: "",
    flipCardsToBack: "",
    shuffle: "",
    showAllCards: "",
    bonusRound: "",
    crazyCupsIntroMessage: "",
    crazyCupsBonusRoundMessage: "",
    goodJob: "",
    sorryYouMissedIt: "",
    teamReadyFormat: "",
    readyToTryAgain: "",
    cupsSpeedAdjustment: "",
    textOnly: "",
    imageText: "",
    imageOnly: "",
    hideShips: "",
    useClassicMode: "",
    classicModeDescription: "",
    stop: "",
    bullsEye: "",
    removeNameOnceSelected: "",
    clearDartAfterThrow: "",
    stopBoardAfterThrow: "",
    playSoundEffect: "",
    spinForPoints: "",
    showImageProfileImage: "",
    cardType: "",
    gridSize: "",
    cardsPerPage: "",
    noListsGetStarted: "",
    noImagesGetStarted: "",
    isTraceable: "",
    twoColumns: "",
    randomizeWorksheet: "",
    createPDF: "",
    fontSize: "",
    goodJobReadyForNext: "",
    whoGoesFirst: "",
    teamGoesFirstFormat: "",
    popBalloonsWith: "",
    dartMeterSpeed: "",
    fontStyle: "",
    cardSensitivity: "",
    points: "",
    hotOrColdInstructions: "",
    inviteUsers: "",
    availableInvitesFormat: "",
    numberOfAccountsToAdd: "",
    removeSubscriptionManagerConfirmation: "",
    managedSubscription: "",
    disconnectFromAccountManager: "",
    confirmToBeManagedConfirmationFormat: "",
    pendingManagerNotFound: "",
    steal: "",
    newWord: "",
    canYouSpell: "",
    wouldLikeToStealFormat: "",
    selectSecondCategory: "",
    clearSecondCategory: "",
    borderThickness: "",
    borderColor: "",
    subscriptionIsPastDue: "",
};
