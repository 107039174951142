import { log } from "../";

import * as React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

import TextField from "@material-ui/core/TextField";

import { Colors } from "../core/utils/Colors";

var debounce = require("throttle-debounce").debounce;

interface SimpleInputFormProps {
    initialValue?: string;
    disableUnderline?: boolean;
    fontSize: number;
    fontColor?: string;
    height: number;
    hint: string;
    shouldFocus?: boolean;
    hintColor?: string;
    shouldClearOnEnter?: boolean;
    onEnterDown?: (text: string) => void;
    onChange?: (text: string) => void;
}

interface SimpleInputFormState {
    value: string;
}

@observer
export class SimpleInputForm extends React.Component<SimpleInputFormProps, SimpleInputFormState> {
    @observable
    private text: string = "";
    private textInput: any;

    constructor(props: any, context: any) {
        super(props, context);

        let wait = 1000;
        this.callOnChangeCallback = debounce(wait, this.callOnChangeCallback);

        if (this.props.initialValue) {
            this.text = this.props.initialValue;
        }
    }

    componentWillReceiveProps(newProps: SimpleInputFormProps) {
        if (this.props.shouldFocus !== newProps.shouldFocus && newProps.shouldFocus) {
            setTimeout(() => {
                this.textInput = document.getElementById("textField");
                if (this.textInput !== undefined && this.textInput !== null) {
                    let value = this.textInput.value;
                    this.textInput.value = "";
                    this.textInput.focus();
                    this.textInput.value = value; // Hack to get cursor at the end of text
                }
            }, 200);
            log.verbose("setting focus!");
        }
    }

    public render(): any {
        const styles = {
            containerStyle: { width: "100%", marginBottom: -10 } as React.CSSProperties,
            placeholderStyle: {
                color: this.props.hintColor ? this.props.hintColor : Colors.lightGrey
            } as React.CSSProperties,
            textFieldStyle: {
                fontSize: this.props.fontSize.toString() + "px",
                height: this.props.height.toString() + "px"
            } as React.CSSProperties
        };

        let disableUnderline = this.props.disableUnderline !== undefined ? this.props.disableUnderline : false;
        var inputProps = {
            disableUnderline: disableUnderline,
            style: {
                width: "100%",
                fontSize: this.props.fontSize,
                color: this.props.fontColor,
                borderBottom: "1px solid"
            }
        };
        return (
            <div style={styles.containerStyle}>
                <TextField
                    autoComplete="off"
                    id="textField"
                    InputProps={inputProps}
                    placeholder={this.props.hint}
                    fullWidth={true}
                    onChange={this.onChange}
                    onKeyDown={this.onKeyDown}
                    style={styles.textFieldStyle}
                    value={this.text}
                />
            </div>
        );
    }

    @action
    public clearInput = () => {
        this.text = "";
    };

    @action
    private onChange = (event: any) => {
        this.text = event.currentTarget.value;
        this.callOnChangeCallback(event.currentTarget.value);
    };

    private callOnChangeCallback = (value: any) => {
        if (this.props.onChange !== undefined && this.props.onChange !== null) {
            this.props.onChange(value);
        }
    };

    @action
    private submit = () => {
        const text = this.text.trim();
        if (text.length) {
            if (this.props.onEnterDown) {
                this.props.onEnterDown(text);
            }

            if (this.props.shouldClearOnEnter) {
                this.text = "";
            }
        }
    };

    @action
    private onKeyDown = (event: any) => {
        if (event.keyCode === 27) {
            this.clearInput();
        } else if (event.keyCode === 13) {
            this.submit();
        }
    };
}
