import * as React from "react";

import Button from "@material-ui/core/Button";

import { strings } from "../../core/localizedStrings";

import { BillingFrequency } from "../../models";

import { STRIPE_PUBLISHABLE_KEY, STRIPE_CHECKOUT_JS } from "../../constants";
import IconButton from "@material-ui/core/IconButton";

declare var StripeCheckout;

export interface StripeCheckoutButtonProps extends React.Props<any> {
    label?: string;
    isUpdate: boolean;
    onToken: (token: string) => void;
    totalAmount?: number;
    billingFrequency?: BillingFrequency;
    email: string;
    icon?: JSX.Element;
}

export class StripeCheckoutButton extends React.Component<StripeCheckoutButtonProps> {
    private stripeHandler: any;

    constructor(props: any, context: any) {
        super(props, context);
    }

    public componentDidMount() {
        const script = document.createElement("script");

        script.src = STRIPE_CHECKOUT_JS;
        script.async = true;
        script.onload = this.onScriptLoaded;

        document.body.appendChild(script);
    }

    public render() {
        if (this.props.icon !== undefined) {
            return <IconButton onClick={this.onButtonClick}>{this.props.icon}</IconButton>;
        } else {
            return (
                <Button variant="contained" onClick={this.onButtonClick}>
                    {this.props.label}
                </Button>
            );
        }
    }

    private onScriptLoaded = () => {
        this.stripeHandler = StripeCheckout.configure({
            key: STRIPE_PUBLISHABLE_KEY,
            email: this.props.email,
            name: "",
            image: require("../../assets/images/icon.png"),
            allowRememberMe: false,
            locale: "auto",
            token: this.onToken,
        });
    };

    private descriptionForType(billingFrequency: BillingFrequency): string {
        switch (billingFrequency) {
            case BillingFrequency.Annually:
                return strings.annualSubscription;

            case BillingFrequency.Monthly:
                return strings.monthlySubscription;

            default:
                return "";
        }
    }

    private onButtonClick = (event: any) => {
        let totalAmount = this.props.isUpdate ? 0 : this.props.totalAmount;
        let description = this.props.isUpdate ? "" : this.descriptionForType(this.props.billingFrequency);
        let panelLabel = this.props.isUpdate ? strings.update : strings.pay;

        this.stripeHandler.open({
            name: "KinteractiveLearning",
            description: description,
            amount: totalAmount,
            panelLabel: panelLabel,
        });
    };

    private onToken = (token: string) => {
        console.log(token);
        this.props.onToken(token);
    };
}
