import * as CustomLists from "../../models/customLists";
import { LanguageType } from "../../models";

export class ElasticsearchQueryBuilder {
    private uid: string;

    constructor(uid: string) {
        this.uid = uid;
    }

    // Category Search
    // -----------------------------
    public createCategoryQueryForTab(
        lastResultIndex: number,
        pageSize: number,
        searchText: string,
        tab: CustomLists.TabSelection,
        sortByType: CustomLists.SortByType,
        languageFilter: LanguageType,
        listTypeFilter?: CustomLists.CategoryType
    ): any {
        let languageFilterObject = this.createLanguageFilterObject(languageFilter);
        let matches: any = this.createCategoryMatches(searchText);
        let filterObject: any = this.createCategoryFilterObject(tab, listTypeFilter);
        let sortObject: any = this.createCategorySortObject(sortByType);
        let query = {
            from: lastResultIndex,
            size: pageSize,
            query: {
                bool: { must: languageFilterObject, should: matches, filter: filterObject, minimum_should_match: 1 },
            },
            _source: ["created", "favoriteCount", "title"],
            sort: sortObject,
        };
        return query;
    }

    public createImageQueryForTab(
        lastResultIndex: number,
        pageSize: number,
        searchText: string,
        tab: CustomLists.TabSelection
    ): any {
        let matches: any = this.createImageMatches(searchText);
        let filterObject: any = this.createImageFilterObject(tab);
        let sortObject: any =
            searchText === undefined ? { "title.keyword": { order: "asc" } } : { _score: { order: "desc" } };
        let query = {
            from: lastResultIndex,
            size: pageSize,
            query: { bool: { should: matches, filter: filterObject, minimum_should_match: 1 } },
            sort: sortObject,
        };
        return query;
    }

    private createCategoryMatches(searchText: string): any {
        if (searchText !== undefined && searchText.length > 1) {
            let titlePhraseMatch = {
                match_phrase_prefix: { title: { query: searchText, boost: 20.0 } },
            };
            let titleAndMatch = { match: { title: { query: searchText, operator: "and", boost: 1.0 } } };
            let keywordMatch = {
                match_phrase_prefix: { keywords: { query: searchText, boost: 3.0 } },
            };
            let keywordAndMatch = { match: { keywords: { query: searchText, operator: "and", boost: 3.0 } } };
            let wordsMatch = { match_phrase_prefix: { items: { query: searchText, boost: 2.0 } } };
            return [titlePhraseMatch, titleAndMatch, keywordMatch, keywordAndMatch, wordsMatch];
        } else {
            return [{ match_all: {} }];
        }
    }

    private createImageMatches(searchText: string): any {
        if (searchText !== undefined && searchText.length > 1) {
            let titlePhraseMatch = { match_phrase_prefix: { title: { query: searchText, boost: 5.0 } } };
            let titleMatch = { match: { title: { query: searchText, operator: "and", boost: 2.0 } } };
            let parentFolderPhraseMatch = { match_phrase_prefix: { parentTitle: { query: searchText, boost: 4.0 } } };
            let parentFolderMatch = { match: { parentTitle: { query: searchText, operator: "and", boost: 2.0 } } };
            return [titlePhraseMatch, titleMatch, parentFolderMatch, parentFolderPhraseMatch];
        } else {
            return [{ match_all: {} }];
        }
    }

    // Filter objects
    private createLanguageFilterObject(language: LanguageType) {
        let languageFilters: any[] = [];
        languageFilters.push(this.createTermFilter("language", language));
        languageFilters.push(this.createTermFilter("answersLanguage", language));

        return [{ bool: { should: languageFilters } }];
    }

    private createCategoryFilterObject(tab: CustomLists.TabSelection, listType: CustomLists.CategoryType): any {
        let filterObject = [];
        switch (tab) {
            case CustomLists.TabSelection.SITE:
                filterObject.push(this.createTermFilter("uid", "site"));
                break;
            case CustomLists.TabSelection.PUBLIC:
                filterObject.push(this.createTermFilter("public", true));
                break;
            case CustomLists.TabSelection.MY_LISTS:
                filterObject.push(this.createTermFilter("uid.keyword", this.uid));
                break;
            case CustomLists.TabSelection.FAVORITES:
                filterObject.push(this.createTermFilter("favoritedBy.keyword", this.uid));
                break;
            default:
                break;
        }

        if (listType !== undefined) {
            filterObject.push(this.createTermFilter("type", listType));
        }

        return filterObject;
    }

    private createImageFilterObject(tab: CustomLists.TabSelection): any {
        let filterObject = { bool: { must: [] } };
        switch (tab) {
            case CustomLists.TabSelection.SITE_IMAGES:
                filterObject.bool.must.push(this.createTermFilter("uid.keyword", "site"));
                break;
            case CustomLists.TabSelection.MY_IMAGES:
                filterObject.bool.must.push(this.createTermFilter("uid.keyword", this.uid));
                break;
            default:
                break;
        }

        return filterObject;
    }

    // Sort objects
    private createCategorySortObject(sortByType: CustomLists.SortByType): any {
        let sortObject: any;
        switch (sortByType) {
            case CustomLists.SortByType.RecentlyCreated:
                sortObject = { created: { order: "desc" } };
                break;
            case CustomLists.SortByType.Popularity:
                sortObject = { favoriteCount: { order: "desc" } };
                break;
            case CustomLists.SortByType.Title:
                sortObject = { "searchTitle.keyword": { order: "asc" } };
                break;
            case CustomLists.SortByType.Relevence:
                sortObject = { _score: { order: "desc" } };
                break;
            default:
                break;
        }
        return sortObject;
    }

    // Helpers
    private createTermFilter(key: string, value: string | boolean | number) {
        let term = {};
        term[key] = value;
        return { term: term };
    }
}
