import { Colors } from "../core/utils/Colors";
import { Fonts } from "../core/utils/Fonts";

export const GlobalStyles = {
    paper: { minHeight: 250, maxWidth: 1200, margin: "20px auto", padding: 40 },
    pageHeading: { color: Colors.black, paddingTop: 10, fontSize: 26, fontFamily: Fonts.BalsamiqSans },
    secondaryHeading: { color: Colors.black, paddingTop: 10, fontSize: 18, fontFamily: Fonts.BalsamiqSans },
    pageDescription: { fontSize: 14 },
    truncatedText: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
};
