import { log } from "../../";

import * as React from "react";

import { DragSource, DropTarget } from "react-dnd";
import flow from "lodash.flow";

export interface DragDropFolderProps extends React.Props<any> {
    childObject: any;
    index: number;
    onDroppedOnTarget: (sourceChildObject: any, targetChildObject: any) => void;
    connectDragSource?: (source: any) => any;
    connectDropTarget?: (target: any) => any;
    isDragging?: boolean;
    isOver?: boolean;
    didDrop?: boolean;
}

class DragDropFolder extends React.Component<DragDropFolderProps> {
    render() {
        const opacity = this.props.isDragging ? 0 : 1;
        if (this.props.isOver) {
            log.verbose("IS OVER!");
        }
        return this.props.connectDragSource(
            this.props.connectDropTarget(
                <div style={{ cursor: "move", opacity: opacity }}>{this.props.childObject}</div>
            )
        );
    }
}

const cardSource = {
    beginDrag(props: DragDropFolderProps) {
        log.verbose("Begin drag");
        return {
            childObject: props.childObject,
        };
    },
    endDrag(props: DragDropFolderProps) {
        log.verbose("End drag");
    },
};

const cardTarget = {
    hover(props: DragDropFolderProps, monitor: any, component: any) {
        // const dragIndex = monitor.getItem().index;
        // const hoverIndex = props.index;
    },
    drop(props: DragDropFolderProps, monitor: any, component: any) {
        props.onDroppedOnTarget(monitor.getItem().childObject, props.childObject);
    },
};

function collect(connect: any, monitor: any) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
}

function collectTarget(connect: any, monitor: any) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
    };
}

var source = DragSource("draggableFolderItem", cardSource, collect);
var target = DropTarget("draggableFolderItem", cardTarget, collectTarget);

export default flow(source, target)(DragDropFolder);
