import * as React from "react";
import { action } from "mobx";
import { observer } from "mobx-react";

// Material UI
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// Components
import AuthProviderButtons from "./AuthProviderButtons";
import SignInForm from "./SignInForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { DialogButtons, DialogButtonsType } from "./DialogButtons";

// Stores
import RootStore from "../stores/RootStore";

// Utils
import { Colors } from "../core/utils/Colors";
import { strings } from "../core/localizedStrings";

// Constants
import { REGISTRATION_PATH } from "../constants";
import withWindowSize, { WithWindowSizeProps } from "core/utils/withWindowSize";
import { MODAL_SIGN_IN_DIALOG_INDEX } from "layers";
import { UseCreditForm } from "./UseCreditForm";

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

export class ConfirmationMessage {
    public text: string;
    public buttonsType: DialogButtonsType;
    public quellText: string;
    public onComplete: (result: string, didQuell: boolean) => void;
    constructor(text: string, quellText: string, onComplete: (result: string, didQuell: boolean) => void) {
        this.text = text;
        this.quellText = quellText;
        this.onComplete = onComplete;
        this.buttonsType = DialogButtonsType.YesNo;
    }
}

export interface InfoMessage {
    text: string;
    disableBackdropClick?: boolean;
    onClose?: () => void;
}

export interface MessageBoxDialogsProps extends WithWindowSizeProps {
    rootStore: RootStore;
}

@observer
class MessageBoxDialogs extends React.Component<MessageBoxDialogsProps> {
    private shouldQuellMessage = false;

    render() {
        let { rootStore } = this.props;
        let {
            shouldShowForgotPassword,
            shouldShowModalSignInDialog,
            shouldShowSignInDialog,
            shouldShowUseCreditDialog,
        } = rootStore.authStore;

        let showInfoMessage = rootStore.infoMessage !== undefined;
        let showErrorMessage = rootStore.errorMessage !== undefined;
        let showLoginRequiredMessage = rootStore.loginRequiredMessage !== undefined;
        let showConfirmationMessage = rootStore.confirmationMessage !== undefined;

        let dialogBoxStyle = { minHeight: 400, overflow: "hidden" } as React.CSSProperties;
        let contentWrapperStyle = { margin: 20, minWidth: 300 } as React.CSSProperties;
        let isMobile = this.props.windowWidth < 400;

        return (
            <div>
                {showInfoMessage && (
                    <Dialog
                        open={true}
                        disableBackdropClick={rootStore.infoMessage.disableBackdropClick}
                        onClose={this.onInfoMessageClose}
                        style={dialogBoxStyle}
                        BackdropProps={{ style: { backgroundColor: "transparent" } }}
                    >
                        <Col xs={12} style={contentWrapperStyle}>
                            <Row center="xs">
                                <Typography variant="body1">{rootStore.infoMessage.text}</Typography>
                            </Row>
                            {showConfirmationMessage && rootStore.confirmationMessage.quellText && (
                                <Row center="xs" style={{ paddingTop: 10 }}>
                                    <FormControlLabel
                                        control={<Checkbox onChange={this.handleQuellCheckbox} />}
                                        label={rootStore.confirmationMessage.quellText}
                                    />
                                </Row>
                            )}
                            <Row end="xs" style={{ paddingTop: 20 }}>
                                <Button
                                    variant="contained"
                                    onClick={this.onInfoMessageClose}
                                    style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "pre" }}
                                >
                                    {strings.ok}
                                </Button>
                            </Row>
                        </Col>
                    </Dialog>
                )}

                {showErrorMessage && (
                    <Dialog
                        open={true}
                        onClose={this.onInfoMessageClose}
                        style={dialogBoxStyle}
                        BackdropProps={{ style: { backgroundColor: "transparent" } }}
                    >
                        <Col xs={12} style={contentWrapperStyle}>
                            <Row center="xs">
                                <Typography variant="body1" style={{ color: Colors.red }}>
                                    {this.props.rootStore.errorMessage + strings.contactAdminMessage}
                                </Typography>
                            </Row>
                            <Row end="xs" style={{ paddingTop: 20 }}>
                                <Button
                                    variant="contained"
                                    onClick={this.onInfoMessageClose}
                                    style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "pre" }}
                                >
                                    {strings.ok}
                                </Button>
                            </Row>
                        </Col>
                    </Dialog>
                )}

                {(shouldShowSignInDialog || showLoginRequiredMessage) && (
                    <Dialog open={true} fullScreen={isMobile} onClose={this.onSignInDialogClose} style={dialogBoxStyle}>
                        <Col xs={12} style={contentWrapperStyle}>
                            <Row middle="xs" style={{ height: "100%", padding: 20, backgroundColor: Colors.white }}>
                                {this.renderSignInForm(rootStore.loginRequiredMessage)}
                            </Row>
                        </Col>
                    </Dialog>
                )}

                {showConfirmationMessage && (
                    <Dialog
                        open={true}
                        onClose={this.onConfirmationMessageClose}
                        BackdropProps={{ style: { backgroundColor: "transparent" } }}
                    >
                        <Col xs={12} style={contentWrapperStyle}>
                            <Row center="xs" style={{ paddingTop: 20 }}>
                                <Typography variant="h6">{rootStore.confirmationMessage.text}</Typography>
                            </Row>
                            {rootStore.confirmationMessage.quellText && (
                                <Row center="xs" style={{ paddingTop: 20 }}>
                                    <FormControlLabel
                                        control={<Checkbox onChange={this.handleQuellCheckbox} />}
                                        label={rootStore.confirmationMessage.quellText}
                                    />
                                </Row>
                            )}
                            <Row center="xs" style={{ width: "100%", paddingTop: 10 }}>
                                <DialogButtons
                                    variant={rootStore.confirmationMessage.buttonsType}
                                    onConfirmCallback={this.onConfirmationMessageYes}
                                    onDenyCallback={this.onConfirmationMessageNo}
                                />
                            </Row>
                        </Col>
                    </Dialog>
                )}

                {shouldShowForgotPassword && (
                    <Dialog open={true} onClose={this.onForgotPasswordClose}>
                        <ForgotPasswordForm rootStore={rootStore} />
                    </Dialog>
                )}

                {shouldShowUseCreditDialog && (
                    <Dialog open={true} onClose={this.onForgotPasswordClose}>
                        <UseCreditForm rootStore={rootStore} />
                    </Dialog>
                )}

                {shouldShowModalSignInDialog && (
                    <Dialog
                        open={true}
                        fullScreen={isMobile}
                        disableBackdropClick={true}
                        disableEscapeKeyDown={true}
                        onClose={this.onModalRegisterDialogClose}
                        style={Object.assign(dialogBoxStyle, { zIndex: MODAL_SIGN_IN_DIALOG_INDEX })}
                    >
                        <Col xs={12} style={contentWrapperStyle}>
                            <Row middle="xs" style={{ height: "100%", padding: 20, backgroundColor: Colors.white }}>
                                {this.renderSignInForm()}
                            </Row>
                        </Col>
                    </Dialog>
                )}
            </div>
        );
    }

    private renderSignInForm = (formMessage: string = undefined) => {
        let padding = { paddingTop: 20 };
        let registerLink = (
            <a onClick={this.onRegisterLinkClicked} href="#">
                {strings.register}
            </a>
        );
        return (
            <Col xs={12} style={{ padding: 10, minWidth: 150 }}>
                {formMessage && (
                    <Row center="xs">
                        <Typography variant="body1" style={{ color: Colors.red }}>
                            {this.props.rootStore.loginRequiredMessage}
                        </Typography>
                    </Row>
                )}
                <Row style={padding}>
                    <AuthProviderButtons providerButtonPrefix={"Login with"} rootStore={this.props.rootStore} />
                </Row>
                <Row style={padding} center="xs">
                    <Typography>{strings.or}</Typography>
                </Row>
                <Row style={padding} center="xs">
                    <SignInForm rootStore={this.props.rootStore} />
                </Row>
                <Row center="xs" style={padding}>
                    <a onClick={this.onForgotPasswordLinkClicked} href="#">
                        <Typography variant="body1">{strings.forgotPassword}</Typography>
                    </a>
                </Row>
                <Row center="xs" style={{ paddingBottom: 20 }}>
                    <Typography variant="body1">
                        {strings.clickHereTo}&nbsp;
                        {registerLink}.
                    </Typography>
                </Row>
            </Col>
        );
    };

    private onInfoMessageClose = () => {
        if (this.props.rootStore.infoMessage && this.props.rootStore.infoMessage.onClose) {
            this.props.rootStore.infoMessage.onClose();
        }

        this.props.rootStore.showInfoMessage(undefined);
        this.props.rootStore.showErrorMessage(undefined);
    };

    private onConfirmationMessageClose = () => {
        this.props.rootStore.showConfirmationMessage(undefined);
    };

    @action
    private onSignInDialogClose = () => {
        this.props.rootStore.showLoginRequiredMessage(undefined);
        this.props.rootStore.authStore.shouldShowSignInDialog = false;
    };

    @action
    private onForgotPasswordClose = () => {
        this.props.rootStore.authStore.shouldShowForgotPassword = false;
    };

    @action
    private onModalRegisterDialogClose = () => {
        this.props.rootStore.authStore.shouldShowModalSignInDialog = false;
    };

    @action
    private onForgotPasswordLinkClicked = (event: any) => {
        this.props.rootStore.authStore.shouldShowForgotPassword = true;
        this.props.rootStore.authStore.shouldShowSignInDialog = false;
        this.props.rootStore.authStore.shouldShowModalSignInDialog = false;
    };

    @action
    private onRegisterLinkClicked = (event: any) => {
        this.props.rootStore.showLoginRequiredMessage(undefined);
        this.props.rootStore.authStore.shouldShowForgotPassword = false;
        this.props.rootStore.authStore.shouldShowSignInDialog = false;
        this.props.rootStore.authStore.shouldShowModalSignInDialog = false;
        this.props.rootStore.setRoute(REGISTRATION_PATH);
    };

    private handleQuellCheckbox = (event: any, value: any) => {
        this.shouldQuellMessage = value;
    };

    private onConfirmationMessageYes = () => {
        let store = this.props.rootStore;
        if (store.confirmationMessage.onComplete !== undefined) {
            store.confirmationMessage.onComplete("yes", this.shouldQuellMessage);
        }
        this.props.rootStore.showConfirmationMessage(undefined);
    };

    private onConfirmationMessageNo = () => {
        let store = this.props.rootStore;
        if (store.confirmationMessage.onComplete !== undefined) {
            store.confirmationMessage.onComplete("no", this.shouldQuellMessage);
        }
        this.props.rootStore.showConfirmationMessage(undefined);
    };
}

export default withWindowSize(MessageBoxDialogs);
