import { serializable, object, primitive, map, list } from "serializr";

import { DOWNLOAD_URL_PREFIX, IMAGES_STORAGE_PATH, AUDIO_STORAGE_PATH } from "../../core/firebase/constants";
import { CategoryType } from "./Category";
import { removeUndefinedFields } from "../../core/utils/ObjectUtils";

export enum CardType {
    IMAGE_ONLY = "imageOnly",
    WORD_ONLY = "wordOnly",
    IMAGE_WORD = "imageWord",
}

export enum AudioSourceType {
    Google = "google",
    iSpeech = "ispeech",
}

export interface TextAudioPair {
    text: string;
    audio: string;
}

export default class CategoryItem {
    @serializable key: string;
    @serializable type: CategoryType;
    @serializable imageKey: string;
    @serializable image?: string;
    @serializable audioSource?: AudioSourceType;
    // @serializable imageOverlay?: ImageOverlayType;
    @serializable audio?: string;
    @serializable text?: string;
    @serializable question?: string;
    @serializable answer?: string;
    @serializable wrong1?: string;
    @serializable wrong2?: string;
    @serializable wrong3?: string;

    @serializable questionAudio?: string;
    @serializable answerAudio?: string;
    @serializable wrongAudio1?: string;
    @serializable wrongAudio2?: string;
    @serializable wrongAudio3?: string;

    get imageName(): string {
        return this.image ? this.image.split("?")[0] : undefined;
    }

    get imageDownloadURL(): string {
        return this.image ? DOWNLOAD_URL_PREFIX + encodeURIComponent(IMAGES_STORAGE_PATH) + this.image : undefined;
    }

    get thumbnailDownloadURL(): string {
        return this.image
            ? DOWNLOAD_URL_PREFIX + encodeURIComponent(IMAGES_STORAGE_PATH) + "thumb_" + this.image
            : undefined;
    }

    get objectForFirebase() {
        return Object.assign({}, removeUndefinedFields(this));
    }

    get textAudioURL(): string {
        return CategoryItem.createAudioDownloadURL(this.audio);
    }

    get questionAudioURL(): string {
        return CategoryItem.createAudioDownloadURL(this.questionAudio);
    }

    get answerAudioURL(): string {
        return CategoryItem.createAudioDownloadURL(this.answerAudio);
    }

    get wrongAudio1URL(): string {
        return CategoryItem.createAudioDownloadURL(this.wrongAudio1);
    }

    get wrongAudio2URL(): string {
        return CategoryItem.createAudioDownloadURL(this.wrongAudio2);
    }

    get wrongAudio3URL(): string {
        return CategoryItem.createAudioDownloadURL(this.wrongAudio3);
    }

    public static createAudioDownloadURL(path: string): string {
        if (!path) {
            return undefined;
        }
        return DOWNLOAD_URL_PREFIX + encodeURIComponent(AUDIO_STORAGE_PATH) + path;
    }

    constructor(obj: any = undefined) {
        if (obj) {
            Object.assign(this, obj);
        }

        if (!this.key) {
            this.key = this.createKey();
        }
    }

    public createKey() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    public isVocabulary(lessonSelection?: CategoryType) {
        if (lessonSelection && this.type === CategoryType.Lesson) {
            return lessonSelection === CategoryType.Vocabulary;
        } else {
            return this.type === CategoryType.Vocabulary || this.type === CategoryType.VocabularyTextOnly;
        }
    }

    //prettier
    public toActivityItem(lessonSelection: CategoryType = undefined) {
        let activityItem = new ActivityCategoryItem();
        activityItem.key = this.key;
        activityItem.categoryItem = this;

        if (lessonSelection) {
            activityItem.type = lessonSelection;
        } else {
            activityItem.type = this.type;
        }

        switch (activityItem.type) {
            case CategoryType.Vocabulary:
                activityItem.imageURL = this.imageDownloadURL;

            case CategoryType.VocabularyTextOnly: // eslint-disable-line no-fallthrough
                activityItem.answer = {
                    text: this.text,
                    audio: CategoryItem.createAudioDownloadURL(this.audio),
                };
                break;
            case CategoryType.Lesson:
                activityItem.vocab = {
                    text: this.text,
                    audio: CategoryItem.createAudioDownloadURL(this.audio),
                };
            case CategoryType.Expression: // eslint-disable-line no-fallthrough
                activityItem.imageURL = this.imageDownloadURL;
            case CategoryType.ExpressionTextOnly: // eslint-disable-line no-fallthrough
                activityItem.question = {
                    text: this.question,
                    audio: CategoryItem.createAudioDownloadURL(this.questionAudio),
                };
                activityItem.answer = {
                    text: this.answer,
                    audio: CategoryItem.createAudioDownloadURL(this.answerAudio),
                };
                activityItem.wrong1 = {
                    text: this.wrong1,
                    audio: CategoryItem.createAudioDownloadURL(this.wrongAudio1),
                };
                activityItem.wrong2 = {
                    text: this.wrong2,
                    audio: CategoryItem.createAudioDownloadURL(this.wrongAudio2),
                };
                activityItem.wrong3 = {
                    text: this.wrong3,
                    audio: CategoryItem.createAudioDownloadURL(this.wrongAudio3),
                };
                break;
            default:
                break;
        }
        return activityItem;
    }
}

export class VocabularyCategoryItem extends CategoryItem {}

export class ExpressionCategoryItem extends CategoryItem {
    constructor(obj: any = undefined) {
        super(obj);

        if (obj && obj.text && !obj.question) {
            this.question = obj.text;
        }

        if (obj && obj.audio && !obj.questionAudio) {
            this.questionAudio = obj.audio;
        }
    }
}

export class LessonCategoryItem extends ExpressionCategoryItem {
    @serializable
    useImageAsVocabularyItem: boolean;

    constructor(obj: any = undefined) {
        super(obj);

        if (obj && obj.useImageAsVocabularyItem !== undefined) {
            this.useImageAsVocabularyItem = obj.useImageAsVocabularyItem;
        } else {
            this.useImageAsVocabularyItem = true;
        }
    }

    public toActivityItem(lessonSelection: CategoryType = undefined) {
        let item = super.toActivityItem(lessonSelection);
        if (this.useImageAsVocabularyItem) {
            item.useImageAsVocabularyItem = this.useImageAsVocabularyItem;
        }
        return item;
    }

    public toVocabularyItem(): VocabularyCategoryItem {
        let item = Object.assign({}, this);
        item.type = CategoryType.Vocabulary;
        delete item.question;
        delete item.answer;
        return new VocabularyCategoryItem(item);
    }

    public toExpressionItem(): ExpressionCategoryItem {
        let item = Object.assign({}, this);
        item.type = CategoryType.Expression;
        if (!item.question && item.text) {
            item.question = item.text;
            delete item.text;
        }

        if (!item.questionAudio && item.audio) {
            item.questionAudio = item.audio;
            delete item.audio;
        }

        return new ExpressionCategoryItem(item);
    }
}

export class ActivityCategoryItem {
    @serializable key: string;
    @serializable type: CategoryType;
    @serializable lessonSelectionType: CategoryType;
    @serializable(map(primitive())) vocab: TextAudioPair;
    @serializable(map(primitive())) _answer: TextAudioPair;
    @serializable imageURL?: string;
    @serializable(map(primitive())) question?: TextAudioPair;
    @serializable(map(primitive())) wrong1?: TextAudioPair;
    @serializable(map(primitive())) wrong2?: TextAudioPair;
    @serializable(map(primitive())) wrong3?: TextAudioPair;
    @serializable(list(map(primitive()))) wrongAnswers?: TextAudioPair[] = [];
    @serializable(object(CategoryItem)) categoryItem: CategoryItem;
    @serializable useImageAsVocabularyItem: boolean;

    public get answer() {
        if (
            (this.vocab !== undefined && this.lessonSelectionType !== CategoryType.Expression) ||
            (this.type === CategoryType.Lesson && this.lessonSelectionType === CategoryType.Vocabulary)
        ) {
            return this.vocab;
        }
        return this._answer;
    }

    public get questionsAnswer() {
        return this._answer;
    }

    public set answer(answer: TextAudioPair) {
        this._answer = answer;
    }

    public typeForLessonSelection(lessonSelection?: CategoryType) {
        this.lessonSelectionType = lessonSelection;
        if (lessonSelection && this.type === CategoryType.Lesson) {
            return lessonSelection;
        } else {
            return this.type;
        }
    }

    public isExpression(lessonSelection?: CategoryType) {
        let type = this.typeForLessonSelection(lessonSelection);
        return type === CategoryType.Expression || type === CategoryType.ExpressionTextOnly;
    }

    // Helpers
    public updatedItemForCardType = (cardType: CardType, lessonSelection: CategoryType) => {
        let itemCopy: ActivityCategoryItem = Object.assign(new ActivityCategoryItem(), this);
        if (
            this.type === CategoryType.Vocabulary ||
            (this.type === CategoryType.Lesson && lessonSelection === CategoryType.Vocabulary)
        ) {
            switch (cardType) {
                case CardType.WORD_ONLY:
                    itemCopy.type = CategoryType.VocabularyTextOnly;
                    break;
                case CardType.IMAGE_ONLY:
                    itemCopy.type = CategoryType.VocabularyImageOnly;
                    break;
            }
        } else if (
            this.type === CategoryType.Expression ||
            (this.type === CategoryType.Lesson && lessonSelection === CategoryType.Expression)
        ) {
            switch (cardType) {
                case CardType.IMAGE_WORD:
                    itemCopy.type = CategoryType.Expression;
                    break;
                case CardType.WORD_ONLY:
                    itemCopy.type = CategoryType.ExpressionTextOnly;
                    break;
                case CardType.IMAGE_ONLY:
                    itemCopy.type = CategoryType.VocabularyImageOnly;
                    break;
            }
        }

        return itemCopy;
    };
}
