import * as React from "react";

// Components
import { DynamicTypography, DynamicTypographyType } from "core/utils/DynamicTypography";

// Hooks
import { useDynamicSize } from "hooks/useDynamicSize";

// Layout
import { GlobalStyles } from "styles/GlobalStyles";
import { Col, Row } from "layout";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { Student } from "models";
import { Fonts } from "core/utils/Fonts";

interface ActivityStudentCardProps extends React.Props<any> {
    student: Student;
}

const ActivityStudentCard = React.forwardRef((props: ActivityStudentCardProps, ref: any) => {
    let containerMinWidth = useDynamicSize(300);
    let padding = useDynamicSize(5);
    let avatarSize = useDynamicSize(40);

    let titleStyles: React.CSSProperties = {
        padding: padding,
        fontFamily: Fonts.BalsamiqSans,
    };
    titleStyles = Object.assign(titleStyles, GlobalStyles.truncatedText);

    if (!props.student) {
        return null;
    }

    let content;
    if (props.student.profileImageDownloadURL) {
        content = (
            <Col xs={12}>
                <Row center="xs" middle="xs">
                    <Avatar
                        style={{ height: avatarSize, width: avatarSize }}
                        src={props.student.profileImageDownloadURL}
                    />
                    <DynamicTypography variant={DynamicTypographyType.H3} style={titleStyles}>
                        {props.student.name}
                    </DynamicTypography>
                </Row>
            </Col>
        );
    } else {
        content = (
            <Col xs={12}>
                <Row center="xs" style={titleStyles}>
                    {props.student.name}
                </Row>
            </Col>
        );
    }
    return (
        <Paper style={{ width: "100%" }}>
            <div style={{ position: "relative" }}>
                <Row style={{ padding: padding / 2, minWidth: containerMinWidth }}>{content}</Row>
            </div>
        </Paper>
    );
});

export default ActivityStudentCard;
