import Student from "./Student";
import { serializable, list, object } from "serializr";
import { persist } from "mobx-persist";
import { observable } from "mobx";

export default class StudentList {
    @serializable
    key?: string;

    @persist("map")
    @observable
    details: StudentListDetails;

    @serializable(list(object(Student)))
    @observable
    students: Student[] = [];

    constructor(details: StudentListDetails) {
        this.details = details;
    }

    get title() {
        return this.details ? this.details.title : undefined;
    }

    set title(title: string) {
        this.details.title = title;
        this.details.searchTitle = title.toLowerCase();
    }

    get presentStudents(): Student[] {
        return this.students.filter(student => {
            return !student.isAbsent;
        });
    }

    get absentStudents(): Student[] {
        return this.students.filter(student => {
            return student.isAbsent;
        });
    }
}

export interface StudentListDetails {
    title?: string;
    searchTitle?: string;
    parentKey?: string;
    totalStudentCount?: number;
    absentStudentCount?: number;
    deleted?: boolean;
}
