import * as React from "react";
import { Observer } from "mobx-react-lite";
import { RootStore } from "stores";

// Material UI
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// Components
import { ActivityLink } from "models/activities/ActivityLink";
import { Firestore } from "core/firebase";

// Utils/Layout
import { Row } from "layout";
import * as Icons from "core/utils/Icons";
import { Colors } from "core/utils/Colors";
import { ConfirmationMessage } from "components/MessageBoxDialogs";
import ActivityLinkVisitors from "./ActivityLinkVisitors";
import Dialog from "@material-ui/core/Dialog";
import { strings } from "core/localizedStrings";
import { Divider } from "@material-ui/core";

export interface ActivityLinkBlockProps extends React.Props<any> {
    rootStore: RootStore;
}

const ActivityLinkBlock = (props: ActivityLinkBlockProps) => {
    let [activityLinkItems, setActivityLinkItems] = React.useState<ActivityLink[]>(undefined);
    let [activityLinkToShowVisitors, setActivityLinkToShowVisitors] = React.useState<ActivityLink>(undefined);

    React.useEffect(() => {
        let unsubscribe = Firestore.activityLinksCollection()
            .where("uid", "==", props.rootStore.userStore.user.uid)
            .orderBy("created", "desc")
            .onSnapshot((snapshot) => {
                if (snapshot !== undefined && snapshot.docs) {
                    let items: ActivityLink[] = [];
                    snapshot.docs.forEach((item) => {
                        let itemData = item.data();
                        itemData.key = item.id;
                        items.push(itemData);
                    });
                    setActivityLinkItems(items);
                }
            });

        // Called on unmount
        return unsubscribe;
    }, []);

    const handleShowVisitors = (activityLink: ActivityLink) => {
        setActivityLinkToShowVisitors(activityLink);
    };

    return (
        <Observer
            render={() => (
                <Row style={{ width: "100%", paddingTop: 10, paddingRight: 40 }}>
                    {activityLinkItems && (
                        <>
                            <Typography variant="h5">{strings.studentActivityLinks}</Typography>
                            <Divider style={{ marginRight: 20 }} flexItem />
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{strings.activity}</TableCell>
                                        <TableCell>{strings.createdOn}</TableCell>
                                        <TableCell>{strings.expiration}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {activityLinkItems.map((item, index) => (
                                        <ActivityLinkItem
                                            key={index}
                                            rootStore={props.rootStore}
                                            item={item}
                                            onShowVisitors={handleShowVisitors}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </>
                    )}
                    <Dialog
                        open={activityLinkToShowVisitors !== undefined}
                        onClose={() => {
                            setActivityLinkToShowVisitors(undefined);
                        }}
                        onBackdropClick={() => {
                            setActivityLinkToShowVisitors(undefined);
                        }}
                    >
                        <ActivityLinkVisitors activityLink={activityLinkToShowVisitors} />
                    </Dialog>
                </Row>
            )}
        />
    );
};

export default ActivityLinkBlock;

interface ActivityLinkItemProps {
    rootStore: RootStore;
    item: ActivityLink;
    onShowVisitors: (activityLink: ActivityLink) => void;
}

const ActivityLinkItem = (props: ActivityLinkItemProps) => {
    let { item } = props;
    let { expiration } = item;
    let location = window.location;
    let link = `${location.protocol}//${location.host}/a/${item.key}`;
    let buttonStyle = { margin: -6 };
    let isExpired =
        expiration === undefined || expiration === null ? false : expiration.toDate().getTime() < Date.now();
    return (
        <TableRow>
            <TableCell>
                <Grid container direction="column">
                    <Grid item>
                        <Typography variant="subtitle2">{item.activityName}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{item.categoryName}</Typography>
                    </Grid>
                    <Grid item>
                        <Link href={link}>{link}</Link>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>{item.created.toDate().toLocaleDateString()}</TableCell>
            {expiration === undefined || expiration === null ? (
                <TableCell />
            ) : isExpired ? (
                <TableCell style={{ color: Colors.red }}>{item.expiration.toDate().toLocaleDateString()}</TableCell>
            ) : (
                <TableCell style={{}}>{item.expiration.toDate().toLocaleDateString()}</TableCell>
            )}

            <TableCell>
                <Tooltip title={strings.copyToClipboard}>
                    <IconButton style={buttonStyle}>
                        <Icons.Copy />
                    </IconButton>
                </Tooltip>
                <Tooltip title={strings.seeLinkVisitors}>
                    <IconButton style={buttonStyle} onClick={() => props.onShowVisitors(item)}>
                        <Icons.People />
                    </IconButton>
                </Tooltip>
                <Tooltip title={strings.delete}>
                    <IconButton
                        style={buttonStyle}
                        onClick={() => {
                            props.rootStore.showConfirmationMessage(
                                new ConfirmationMessage(
                                    "Are you sure you want to permanently delete this link. It will no longer be able to be used to access the activity.",
                                    undefined,
                                    (result) => {
                                        if (result === "yes") {
                                            Firestore.activityLinksCollection().doc(item.key).delete();
                                        }
                                    }
                                )
                            );
                        }}
                    >
                        <Icons.Delete />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};
