import { serializable } from "serializr";
import { computed, observable } from "mobx";
import { DefaultProfileImages } from "../assets";
import { DOWNLOAD_URL_PREFIX, PROFILE_IMAGES_PATH } from "../core/firebase/constants";
import { removeUndefinedFields } from "../core/utils/ObjectUtils";

export enum StudentGender {
    Boy = "boy",
    Girl = "girl",
}

export default class Student {
    @serializable key: string;
    @serializable parentKey: string;
    @serializable name: string;
    @serializable gender: StudentGender;
    @serializable isAbsent: boolean;

    @observable @serializable profileImage: string;

    constructor(obj: any = undefined) {
        if (obj !== undefined) {
            Object.assign(this, obj);
        }
    }

    get objectForFirebase() {
        let copy: any = Object.assign({}, this);

        // Profile image path should only be set by firebase functions when image has been uploaded
        delete copy.profileImage;

        return removeUndefinedFields(copy);
    }

    @computed
    get profileImageDownloadURL(): string {
        if (!this.profileImage) {
            return DefaultProfileImages.profileImageForStudentGender(this.gender);
        }

        if (this.profileImage.indexOf("http") === 0) {
            return this.profileImage;
        }
        return DOWNLOAD_URL_PREFIX + encodeURIComponent(PROFILE_IMAGES_PATH) + this.profileImage;
    }

    @computed
    get thumbnailDownloadURL(): string {
        if (!this.profileImage) {
            return DefaultProfileImages.profileImageForStudentGender(this.gender);
        }

        if (this.profileImage.indexOf("http") === 0) {
            return this.profileImage;
        }
        return DOWNLOAD_URL_PREFIX + encodeURIComponent(PROFILE_IMAGES_PATH) + "thumb_" + this.profileImage;
    }
}
