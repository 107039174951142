import * as React from "react";
import Dropzone from "react-dropzone";
import { observable, action, runInAction } from "mobx";
import { observer } from "mobx-react";

// Material UI
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";

// Stores
import RootStore from "../stores/RootStore";

// Utils
import { strings } from "../core/localizedStrings";
import * as loadImage from "blueimp-load-image";
import { Typography } from "@material-ui/core";

var dataURLtoBlob = require("blueimp-canvas-to-blob");

var Row = require("react-flexbox-grid").Row;
var Col = require("react-flexbox-grid").Col;

const MAX_FILE_SIZE = 2000 * 1000; // 2 MB
const MAX_FILES = 1;

export interface ProfileImageDropZoneProps extends React.Props<any> {
    onImageDroppedCallback: (file: File) => void;
    imageProfileUploadProgress: number;
    profileImageURL: string;
    rootStore: RootStore;
}

@observer
export default class ProfileImageDropZone extends React.Component<ProfileImageDropZoneProps, {}> {
    @observable
    private isUploading: boolean = false;

    render() {
        let style = {
            width: 150,
            height: 150,
            borderStyle: "dashed",
            borderRadius: 5,
            cursor: "pointer",
            margin: "0 auto",
            paddingBottom: 20,
        };

        return (
            <div className="">
                <Dropzone
                    accept="image/*"
                    disableClick={this.isUploading}
                    maxSize={MAX_FILE_SIZE}
                    style={style}
                    onDrop={this.onDropImage}
                >
                    <Col xs={12} style={{ height: 150 }}>
                        <Row center="xs" middle="xs" style={{ height: "100%" }}>
                            {this.renderImage()}
                        </Row>
                    </Col>
                </Dropzone>
                <Typography style={{ paddingTop: 10 }}>{strings.uploadImagesDropzoneInstructions}</Typography>
            </div>
        );
    }

    @action
    public componentWillReceiveProps(newProps: ProfileImageDropZoneProps) {
        this.isUploading = false;
    }

    private onDropImage = (acceptedFiles: Array<File>, rejectedFiles: Array<File>) => {
        if (this.isUploading) {
            return;
        }

        if (acceptedFiles.length === 0) {
            let errorMessage = strings.formatString(strings.noAcceptableFilesFound, MAX_FILE_SIZE / 1000 / 1000);
            this.props.rootStore.showInfoMessage({ text: errorMessage as string });
            return;
        }

        if (acceptedFiles.length > MAX_FILES) {
            let errorMessage = strings.formatString(strings.maxFilesUploadAttempt, MAX_FILES);
            this.props.rootStore.showInfoMessage({ text: errorMessage as string });
            return;
        }

        if (rejectedFiles !== undefined && rejectedFiles.length > 1) {
            let errorMessage: string | React.ReactText[] = (rejectedFiles.length = 1)
                ? strings.rejectedFileFormat
                : strings.rejectedFilesFormat;
            errorMessage = strings.formatString(errorMessage, rejectedFiles.length);
            this.props.rootStore.showInfoMessage({ text: errorMessage as string });
            return;
        }

        runInAction(() => {
            this.isUploading = true;
        });

        for (var file of acceptedFiles) {
            const loadImageOptions: any = { canvas: true };
            loadImage.parseMetaData(file, (data) => {
                if (data.exif && data.exif.get("Orientation")) {
                    let orientation = data.exif.get("Orientation");
                    if (orientation !== 1) {
                        loadImageOptions.orientation = orientation;
                        loadImage(
                            file,
                            (canvas) => {
                                let blob = dataURLtoBlob(canvas.toDataURL(file.type));
                                let rotatedFile = new File([blob], file.name, { type: file.type });
                                this.props.onImageDroppedCallback(rotatedFile);
                            },
                            loadImageOptions
                        );
                    } else {
                        this.props.onImageDroppedCallback(file);
                    }
                } else {
                    this.props.onImageDroppedCallback(file);
                }
            });
        }
    };

    private renderImage() {
        if (!this.isUploading) {
            return <Avatar style={{ width: 140, height: 140 }} src={this.props.profileImageURL} />;
        } else {
            return <CircularProgress variant="indeterminate" />;
        }
    }
}
