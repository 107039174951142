import * as React from "react";
import { BACKGROUND_IMAGE_INDEX } from "layers";
const background = require("../../assets/images/deskBackground.jpg");

export class BackgroundImage extends React.Component<any, {}> {
    render() {
        return (
            <div
                style={{
                    backgroundImage: "url(" + background + ")",
                    backgroundRepeat: "repeat-x",
                    backgroundColor: "#2D1709",
                    position: "fixed",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    zIndex: BACKGROUND_IMAGE_INDEX
                }}
            />
        );
    }
}
