import { date, object, primitive, list, serializable, map } from "serializr";
import { LanguageType, AudioClipVoice } from "../../models/Languages";
import CategoryItem, { ActivityCategoryItem } from "./CategoryItem";

import { VocabularyCategoryItem, ExpressionCategoryItem, LessonCategoryItem } from "./CategoryItem";
import { removeUndefinedFields } from "../../core/utils/ObjectUtils";

export enum CategoryType {
    Lesson = "lesson",
    ImageOnly = "imageOnly",
    Vocabulary = "vocab",
    VocabularyTextOnly = "vocabTextOnly",
    VocabularyImageOnly = "vocabImageOnly",
    Expression = "expression",
    ExpressionTextOnly = "expressionTextOnly",
}

export default class Category {
    @serializable key: string;
    @serializable parentKey: string;
    @serializable title: string;
    @serializable deleted: boolean = false;
    @serializable profileImage: string = "";
    @serializable uid: string;
    @serializable mysqlID: number;
    @serializable searchTitle: string;
    @serializable language: LanguageType;
    @serializable answersLanguage: LanguageType;
    @serializable voice: AudioClipVoice;
    @serializable answersVoice: AudioClipVoice;
    @serializable public: boolean = true;
    @serializable uidTitle: string;
    @serializable copyOf: string;
    @serializable englishCategoryKey: string;
    @serializable type: CategoryType;
    @serializable indexed: boolean = false;
    @serializable hasGoogleAudio: boolean = false;

    @serializable(list(object(CategoryItem)))
    items: CategoryItem[] = [];

    @serializable(list(object(ActivityCategoryItem)))
    activityItems: ActivityCategoryItem[] = [];

    @serializable(date())
    created: Date;

    @serializable(date())
    modified?: Date;

    @serializable(map(primitive()))
    favoritedByFolders: Map<string, string> = new Map<string, string>();

    @serializable(list(primitive()))
    keywords: string[] = [];

    private cachedLongestItem?: number;

    public get itemsForFirebase() {
        return this.items.map((element) => {
            return element.objectForFirebase;
        });
    }

    public get longestItem() {
        if (!this.cachedLongestItem) {
            let longest = 0;
            for (let item of this.items) {
                if (item.text && item.text.length > longest) {
                    longest = item.text.length;
                }
                if (item.question && item.question.length > longest) {
                    longest = item.question.length;
                }
                if (item.answer && item.answer.length > longest) {
                    longest = item.answer.length;
                }
            }
            this.cachedLongestItem = longest;
        }
        return this.cachedLongestItem;
    }

    public constructor(obj: any = undefined) {
        if (obj) {
            if (obj.favoritedByCount !== undefined) {
                // Favorited by count should be derived from favoritedBy array
                delete obj.favoritedByCount;
            }
            Object.assign(this, obj);

            if (obj.items) {
                this.items = [];
                this.activityItems = [];
                for (let categoryItemKey of Object.keys(obj.items)) {
                    let item = obj.items[categoryItemKey];

                    if (!item.type && obj.type) {
                        item.type = obj.type;
                    }

                    let categoryItem;
                    switch (item.type as CategoryType) {
                        case CategoryType.Vocabulary:
                        case CategoryType.VocabularyTextOnly:
                            categoryItem = new VocabularyCategoryItem(item);
                            break;
                        case CategoryType.Expression:
                        case CategoryType.ExpressionTextOnly:
                            categoryItem = new ExpressionCategoryItem(item);
                            break;
                        case CategoryType.Lesson:
                            categoryItem = new LessonCategoryItem(item);
                            break;
                        default:
                            categoryItem = new VocabularyCategoryItem(item);
                    }

                    this.items.push(categoryItem);
                    this.activityItems.push((categoryItem as CategoryItem).toActivityItem());
                }
            }

            if (this.isExpressionCategory || this.type === CategoryType.Lesson) {
                if (this.voice && !this.answersVoice) {
                    this.answersVoice = this.voice;
                }
                if (this.language && !this.answersLanguage) {
                    this.answersLanguage = this.language;
                }
            }

            if (obj.keywords !== undefined) {
                if (!Array.isArray(obj.keywords)) {
                    this.keywords = [];
                    for (let keyword of Object.keys(obj.keywords)) {
                        this.keywords.push(keyword);
                    }
                }
            }

            // Dates are now stored as timestamps
            if (obj.created && !(obj.created instanceof Date)) {
                if (obj.created.toDate !== undefined) {
                    this.created = obj.created.toDate();
                }
            }
            if (obj.modified && !(obj.modified instanceof Date)) {
                if (obj.modified.toDate !== undefined) {
                    this.modified = obj.modified.toDate();
                }
            }
        }
    }

    public get favoritedByCount() {
        if (!this.favoritedByFolders) {
            return 0;
        }

        return Object.keys(this.favoritedByFolders).length;
    }

    public get objectForFirebase() {
        let copy: any = Object.assign({}, this);
        copy.items = this.itemsForFirebase;

        let keywords = {};
        this.keywords.map((item) => {
            keywords[item] = true;
        });
        copy.keywords = keywords;

        // Don't need this in the database
        delete copy.cachedLongestItem;
        delete copy.activityItems;

        // Need the count in firebase for filtering by popularity.
        copy.favoritedByCount = this.favoritedByCount;

        copy.favoritedByFolders = {};
        if (this.favoritedByFolders && this.favoritedByFolders.keys) {
            for (let key in this.favoritedByFolders.keys) {
                copy.favoritedByFolders[key] = this.favoritedByFolders[key];
            }
        }

        return removeUndefinedFields(copy);
    }

    public get objectForElasticsearch() {
        let copy: any = Object.assign({}, this);
        copy.items = [];
        this.items.forEach((element) => {
            if (element.text) {
                copy.items.push(element.text);
            }
            if (element.question && element.question !== element.text) {
                copy.items.push(element.question);
            }
        });

        copy.favoritedByCount = this.favoritedByCount;

        // Convert map to an array of UIDs
        if (this.favoritedByFolders) {
            let array = [];
            for (let key of Object.keys(this.favoritedByFolders)) {
                array.push(this.favoritedByFolders[key]);
            }
            copy.favoritedBy = array;
        }

        delete copy.favoritedByFolders;
        delete copy.profileImage;
        delete copy.deleted;
        delete copy.indexed;
        delete copy.key;
        delete copy.mysqlID;
        delete copy.parentID;

        return copy;
    }

    public shouldUpdateIndex(oldCategory: Category) {
        if (
            this.title !== oldCategory.title ||
            this.items.length !== oldCategory.items.length ||
            this.keywords.length !== oldCategory.keywords.length ||
            this.public !== oldCategory.public ||
            this.language !== oldCategory.language ||
            this.answersLanguage !== oldCategory.answersLanguage ||
            (this.favoritedByFolders &&
                Array.from(this.favoritedByFolders).length !== Array.from(oldCategory.favoritedByFolders).length)
        ) {
            return true;
        } else {
            return false;
        }
    }

    public isFavoritedBy(uid: string): boolean {
        if (this.favoritedByFolders === undefined) {
            return false;
        } else {
            for (var key in this.favoritedByFolders) {
                if (this.favoritedByFolders[key] === uid) {
                    return true;
                }
            }
        }

        return false;
    }

    public updateItem(itemToUpdate: CategoryItem) {
        this.items = this.items.map((item) => {
            return item.key === itemToUpdate.key ? itemToUpdate : item;
        });
    }

    public getFavoritedByKeyForUID(uid: string): string {
        for (var key in this.favoritedByFolders) {
            if (this.favoritedByFolders[key] === uid) {
                return key;
            }
        }
        return undefined;
    }

    public isVocabularyCategory(): Boolean {
        return this.type === CategoryType.Vocabulary || this.type === CategoryType.VocabularyTextOnly ? true : false;
    }

    public isExpressionCategory(): Boolean {
        return !this.isVocabularyCategory();
    }

    public isImageCategory(): Boolean {
        return this.type === CategoryType.Vocabulary ||
            this.type === CategoryType.Expression ||
            this.type === CategoryType.Lesson
            ? true
            : false;
    }
}
