/* tslint:disable */
export let greek = {
    absent: "Απών",
    absentStudentsFormat: " {0} απούσα",
    accountRegistration: "Εγγραφή λογαριασμού",
    active: "Ενεργός",
    activityPreview: "Προεπισκόπηση δραστηριότητας",
    activityPreviewMessage: "Παρακαλούμε συνδεθείτε ή εγγραφείτε για έναν δωρεάν λογαριασμό.",
    add: "Προσθέτω",
    addAccount: "Προσθήκη λογαριασμού",
    addCard: "Προσθήκη κάρτας",
    addCards: "Προσθέστε κάρτες",
    addFolder: "Προσθήκη φακέλου",
    addGroupStudentAccount: "Προσθέστε φοιτητικό λογαριασμό ομάδας",
    addKeyword: "Προσθήκη λέξης-κλειδιού",
    addName: "Προσθήκη ονόματος",
    addTeacherAccount: "Προσθέστε το λογαριασμό εκπαιδευτικών",
    addToFavorites: "Προσθήκη στα αγαπημένα",
    addWithPlus: "+ Προσθήκη",
    additionalResources: "Επιπρόσθετοι πόροι",
    admin: "διαχειριστής",
    affirmative: "Καταφατικός",
    afterTrial:
        "Μετά τη δοκιμή 30 ημερών, μπορείτε είτε να συνεχίσετε να χρησιμοποιείτε τον ιστότοπο ως βασικό μέλος, ή αναβαθμίστε πάλι στο Premium.",
    all: "Ολα",
    allFieldsRequired: "Ολα τα πεδία είναι υποχρεωτικά.",
    allowCardsToBeFlipped: "Να επιτρέπεται η αναστροφή των καρτών",
    allowedToChangePassword: "ο δάσκαλος μπορεί να αλλάξει κωδικό πρόσβασης",
    annual: "Ετήσιος",
    annualSubscription: "Ετήσια συνδρομή",
    answer: "Απάντηση",
    audioClipLanguageLabel: "Γλώσσα ήχου κλιπ",
    backToWorksheets: "Επιστροφή στα φύλλα εργασίας",
    basic: "Βασικός",
    basicMemberCredits:
        "* Τα βασικά μέλη λαμβάνουν 8 μηνιαίες πιστώσεις. Μόλις χρησιμοποιηθούν όλες οι πιστώσεις, η πρόσβαση στον ιστότοπο θα περιοριστεί μέχρι την πρώτη ημέρα του επόμενου μήνα.",
    billedAnnually: "Χρεώνεται ετησίως",
    billedAnnuallyFormat: "Χρεώνονται ετησίως ({0})",
    billedMonthly: "Τιμολογείται μηνιαίως",
    billedMonthlyFormat: "Τιμολογημένο μηνιαίο ({0})",
    billingFrequency: "Συχνότητα χρέωσης",
    boy: "Αγόρι",
    boys: "Αγόρια",
    browseAllCategories: "Αναζήτηση / Επεξεργασία κατηγοριών",
    browseAllLists: "Αναζήτηση / Επεξεργασία λιστών",
    cancel: "Ματαίωση",
    cancelSubscription: "Ακύρωση συνδρομής",
    cannotAddManagedAccountsInTrial:
        "Οι διαχειριζόμενοι λογαριασμοί δεν μπορούν να προστεθούν κατά τη δοκιμαστική περίοδο.",
    cannotBeEmpty: "Δεν μπορεί να είναι άδειο.",
    card: "Κάρτα",
    cardLower: "κάρτα",
    cardsLower: "καρτέλλες",
    category: "Κατηγορία",
    category2: "Κατηγορία2",
    categoryCharactersNotSupported:
        "Αυτή η κατηγορία περιέχει χαρακτήρες που δεν υποστηρίζονται ακόμη από αυτή τη δραστηριότητα.",
    categoryTypeNotSupported: "Αυτός ο τύπος κατηγορίας δεν υποστηρίζεται ακόμη από αυτήν την κατηγορία.",
    changeCategory: "Αλλαγή κατηγορίας",
    changeEditStudents: "Αλλαγή / Επεξεργασία Φοιτητών",
    changePassword: "Αλλαξε κωδικό",
    checkEmailForVerifcation: "Ελέγξτε το email σας για να ολοκληρώσετε την ενεργοποίηση του λογαριασμού σας.",
    chinese: "κινέζικα",
    className: "Ονομα τάξης",
    clear: "Σαφή",
    clearFilters: "Διαγραφή φίλτρων",
    clearSelectedImage: "Διαγραφή επιλεγμένης εικόνας",
    clearSelectedImages: "Διαγράψτε τις επιλεγμένες εικόνες",
    clickHereTo: "Δεν υπάρχει λογαριασμός; Κάντε κλικ εδώ για",
    confirmChangeCategory:
        "Η αλλαγή της κατηγορίας θα επαναφέρει τη δραστηριότητα. Είστε βέβαιοι ότι θέλετε να το αλλάξετε;",
    confirmChangeClassList:
        "Η αλλαγή της λίστας μαθητών θα επαναφέρει τη δραστηριότητα. Είστε βέβαιοι ότι θέλετε να το αλλάξετε;",
    confirmChangeTeamNumber:
        "Η αλλαγή του αριθμού των ομάδων θα επαναφέρει τη δραστηριότητα. Είστε βέβαιοι ότι θέλετε να το αλλάξετε;",
    confirmRestartSubscription:
        "Είστε βέβαιοι ότι θέλετε να κάνετε επανεκκίνηση της συνδρομής σας; Η πιστωτική σας κάρτα δεν θα χρεωθεί μέχρι το {0}.",
    contact: "Επικοινωνία",
    contactAdminMessage: " Εάν το πρόβλημα παραμένει, επικοινωνήστε με τον διαχειριστή του ιστότοπου.",
    contactDescription:
        "Ενημερώστε μας για τυχόν ερωτήσεις ή προβλήματα που έχετε. Απλά στείλτε ένα μήνυμα παρακάτω και θα επικοινωνήσουμε μαζί σας το συντομότερο δυνατόν.",
    contactFormSubmitted: "Σας ευχαριστούμε, το μήνυμά σας έχει σταλεί.",
    copy: "αντίγραφο",
    copyLower: "αντίγραφο",
    createCardFromSelectedImage: "Δημιουργία κάρτας από την επιλεγμένη εικόνα",
    createCardsFromSelectedImages: "Δημιουργήστε κάρτες από τις {0} επιλεγμένες εικόνες",
    createCopyIn: "Δημιουργία αντιγράφου σε:",
    createCustomLists: "Δημιουργία προσαρμοσμένων λιστών",
    createNewList: "Δημιουργία νέας λίστας",
    createSelectCategory: "Δημιουργήστε ή επιλέξτε ένα μάθημα",
    createSelectCategoryDescription:
        "Δημιουργήστε ένα προσαρμοσμένο μάθημα σε δευτερόλεπτα ή αναζητήστε από χιλιάδες υπάρχοντες.",
    createSelectClassList: "Δημιουργήστε ή επιλέξτε μια λίστα σπουδαστών",
    createStudentNameLists: "Δημιουργία λίστας ονομάτων μαθητών",
    createTeams: "Δημιουργήστε ομάδες",
    createdBy: "Δημιουργήθηκε από",
    createdOn: "Δημιουργήθηκε στο",
    creditCard: "Πιστωτική κάρτα",
    creditNeeded: "1 πίστωση ανά χρήση",
    currentSubscriptionRequiredForManagedAccounts:
        "Απαιτείται ενεργή συνδρομή. Κάντε επανεκκίνηση του υποείδους σας για να προσθέσετε πρόσθετους λογαριασμούς.",
    customizeHeader: "{{Προσαρμογή}} για να χωρέσει σε οποιοδήποτε μάθημα",
    customizeText:
        "Πάρτε μια απλή λίστα λεξιλογίου ή μια λίστα ερωτήσεων και μετατρέψτε την σε μια συναρπαστική δραστηριότητα σε δευτερόλεπτα. Μόλις δημιουργηθεί μια λίστα, μπορεί να χρησιμοποιηθεί με οποιαδήποτε δραστηριότητα σε ολόκληρο τον ιστότοπο.",
    dataBeingMigratedFromBarryFunEnglish:
        "Τα δεδομένα του λογαριασμού σας μεταφέρονται από το BarryFunEnglish. Αυτό πρέπει να γίνει μόνο μία φορά. Ανάλογα με την ποσότητα των δεδομένων, αυτό μπορεί να πάρει οπουδήποτε από ένα\n        λίγα δευτερόλεπτα έως μερικές ώρες. Εάν δεν βλέπετε όλα τα δεδομένα σας μετά από λίγες ώρες, επικοινωνήστε μαζί μας\n        ο διαχειριστής του ιστότοπου.",
    delete: "Διαγράφω",
    deleteCategoryConfirmation: "Είστε βέβαιοι ότι θέλετε να διαγράψετε οριστικά αυτήν την κατηγορία;",
    deleteFavoriteConfirmation: "Είστε βέβαιοι ότι θέλετε να καταργήσετε αυτό το θέμα από τα αγαπημένα σας;",
    deleteFolderConfirmation:
        "Είστε βέβαιοι ότι θέλετε να διαγράψετε οριστικά αυτόν τον φάκελο και όλο το περιεχόμενό του;",
    deleteStudentListConfirmation: "Είστε βέβαιοι ότι θέλετε να καταργήσετε αυτήν τη λίστα;",
    discount: "Έκπτωση ({0}%):",
    discounts: "Εκπτώσεις:",
    done: "Εγινε",
    dutch: "Ολλανδός",
    edit: "Επεξεργασία",
    editProfile: "Επεξεργασία προφίλ",
    email: "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
    emailAlreadyRegistered: "Η διεύθυνση ηλεκτρονικού ταχυδρομείου έχει ήδη καταχωριστεί",
    emailOrUsername: "E-mail ή το όνομα χρήστη",
    emailRequiredError: "Όνομα χρήστη απαιτείται.",
    emailSentTo: "Το μήνυμα ηλεκτρονικού ταχυδρομείου στάλθηκε στο {0}.",
    endGame: "Τέλος παιχνιδιού",
    english: "Αγγλικά",
    enterEmailUsedToRegister:
        "Εισαγάγετε τη διεύθυνση ηλεκτρονικού ταχυδρομείου που χρησιμοποιήσατε για να εγγραφείτε στην ιστοσελίδα:",
    enterIncorrectAnswers: "Εισάγετε τις εσφαλμένες απαντήσεις μου",
    enterQuestion: "Εισάγετε ερώτηση",
    enterText: "Εισαγάγετε κείμενο:",
    enterValidEmail: "Παρακαλώ εισάγετε μια έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου.",
    enterVocabAndQuestion: "Πληκτρολογήστε λέξη και ερώτηση λεξιλογίου",
    enterVocabulary: "Πληκτρολογήστε το λεξιλόγιο",
    existingAccount: " υπάρχων λογαριασμός",
    expirationDateFormat: "Ημερομηνία λήξης: {0}",
    expired: "έχει λήξει",
    expires: "Λήγει",
    expressionInputTextField1Label: "Πεδίο κειμένου 1 (ερώτηση)",
    expressionInputTextField2Label: "Πεδίο κειμένου 2 (Απάντηση)",
    favorites: "Αγαπημένα",
    flashcards: "Flashcards",
    folderName: "Ονομα φακέλου:",
    forClassroomHeader: "Σχεδιασμένο για την {{classroom}}",
    forClassroomText:
        "Τα παιχνίδια και οι δραστηριότητες σχεδιάζονται για χρήση στην τάξη και να δουλεύει σπουδαία με μεγάλες ή μικρές τάξεις.\n            Τα παιχνίδια μπορούν να έχουν μεταξύ δύο έως πέντε ομάδων και μπορούν να εισαχθούν λίστες μαθητών για τυχαία επιλογή.",
    forgotPassword: "Ξεχάσατε τον κωδικό?",
    free: "Ελεύθερος",
    french: "γαλλική γλώσσα",
    games: "Παιχνίδια",
    gender: "Γένος",
    german: "Γερμανός",
    girl: "Κορίτσι",
    girls: "Κορίτσια",
    girlsVsBoys: "Κορίτσια και αγόρια",
    greek: "Ελληνικά",
    groupNumberFormat: "Ομάδα {0}",
    homePageContactMessage: "Έχετε {{questions}} για εμάς;",
    imageOverlayGraphic: "Γραφικό επικάλυψης εικόνων",
    imageWithText: "Εικόνα με πεδίο ενιαίου κειμένου",
    imageWithTextDescription:
        "Αυτοί οι κατάλογοι είναι εξαιρετικοί για την εξάσκηση λεξιλογίου, την ανάγνωση και την ομιλία. Δουλεύουν σχεδόν με οποιαδήποτε δραστηριότητα στο χώρο.",
    imageWithTwoText: "Εικόνα με δύο πεδία κειμένου",
    imageWithTwoTextDescription:
        "Αυτές οι λίστες χρησιμοποιούνται για να κάνουν ερωτήσεις σχετικά με μια εικόνα ή για μετάφραση μεταξύ δύο γλωσσών",
    imagesSelectorInstructions: "Επιλέξτε ή ανεβάστε εικόνες για να δημιουργήσετε κάρτες από:",
    interactive: "Διαδραστικό",
    interactiveFlashcards: "Διαδραστικές κάρτες Flash",
    introduce: "Παρουσιάζω",
    invalidEmail: "Μη έγκυρη διεύθυνση e-mail.",
    invalidPassword: "Οι κωδικοί πρόσβασης πρέπει να έχουν μήκος τουλάχιστον 6 χαρακτήρων.",
    invalidUsername: "Μη έγκυρο όνομα χρήστη.",
    invalidUsernameOrPassword: "Έχετε εισάγει ένα μη έγκυρο όνομα χρήστη ή κωδικό πρόσβασης",
    italian: "ιταλικός",
    japanese: "Ιαπωνικά",
    keywordsLabel: "Λέξεις κλειδιά",
    korean: "κορεάτης",
    language: "Γλώσσα",
    languageTitle: "Γλώσσα",
    learn: "Μαθαίνω",
    learnDescription: "Εισαγάγετε ένα νέο μάθημα ή μια κριτική με αλληλεπιδραστικές κάρτες ή εκτυπώσιμες κάρτες flash.",
    lesson: "Μάθημα",
    lessonDescription: "Μίξη μιας εικόνας και εικόνας με δύο πεδία κειμένου",
    lessonTypeSelectorTitle: "Θα θέλατε να ασκήσετε λεξιλόγιο ή ερωτήσεις από αυτό το μάθημα;",
    listAccessibility: "Κατάλογος προσβασιμότητας",
    listTitleLabel: "Τίτλος λίστας",
    listTypeTitle: "Τύπος λίστας",
    loggingIn: "Σύνδεση",
    loginToCopy: "Πρέπει να είστε συνδεδεμένοι για να αντιγράψετε λίστες.",
    loginToCreateFolders: "Πρέπει να είστε συνδεδεμένοι για να δημιουργήσετε φακέλους.",
    loginToCreateLists: "Πρέπει να είστε συνδεδεμένοι για να δημιουργήσετε προσαρμοσμένες λίστες.",
    loginToEditLists: "Πρέπει να είστε συνδεδεμένοι για να επεξεργαστείτε λίστες.",
    loginToFavorite: "Πρέπει να είστε συνδεδεμένοι για να αποθηκεύσετε τα αγαπημένα.",
    logout: "Αποσυνδέση",
    mainPageText: "Προσαρμοσμένες δραστηριότητες εκμάθησης γλωσσών για κάθε μάθημα",
    managedAccounts: "Διαχείριση λογαριασμών",
    managedAccountsDescription:
        "Προσθέστε τους διαχειριζόμενους λογαριασμούς αν θέλετε πολλαπλές συμμετοχές σε μία συνδρομή. Εκπτώσεις προσφέρονται με βάση τον αριθμό των συνολικών λογαριασμών.",
    maxFilesUploadAttempt: "Μπορείτε να μεταφορτώσετε μόνο ένα μέγιστο {0} αρχεία κάθε φορά.",
    membershipTypes: "Τύποι μελών",
    message: "Μήνυμα",
    misses: "Λείπει",
    monthly: "Μηνιαίος",
    monthlySubscription: "Μηνιαία συνδρομή",
    move: "Κίνηση",
    moveFolderTo: "Μετακίνηση φακέλου σε:",
    moveListTo: "Μετακίνηση της λίστας σε:",
    multipleChoiceInstructions:
        "Με δραστηριότητες πολλαπλών επιλογών, τι θέλετε να χρησιμοποιήσετε για εσφαλμένες απαντήσεις;",
    multipleLanguageSupport: "Υποστηρίζονται πολλές γλώσσες",
    mustHaveOneIncorrectAnswers: "Πρέπει να εισαγάγετε τουλάχιστον μία εσφαλμένη απάντηση.",
    myAccount: "Ο λογαριασμός μου",
    myClasses: "Οι ταξεις μου",
    myImages: "Οι εικόνες μου",
    myLists: "Οι λίστες μου",
    name: "Ονομα",
    negative: "Αρνητικός",
    newFolder: "Νέος φάκελος",
    nextBillingDate: "Επόμενη ημερομηνία χρέωσης: {0} ({1})",
    no: "Οχι",
    noAcceptableFilesFound: "Δεν βρέθηκαν έγκυρα αρχεία. Τα αρχεία περιορίζονται σε ένα μέγιστο μέγεθος {0} MB.",
    noCardOnFile: "Δεν υπάρχει κάρτα στο αρχείο",
    noCreditCardRequired: "Δεν απαιτείται πιστωτική κάρτα.",
    noRemainingCredits:
        "Δεν υπάρχουν υπόλοιπα πιστώσεων αυτόν τον μήνα. Εγγραφείτε για έναν λογαριασμό premium για απεριόριστη πρόσβαση.",
    none: "Κανένας",
    notActivated: "Μη ενεργοποιημένο",
    notActive: "Ανενεργός",
    notChargedUntilFormat: "Η πιστωτική κάρτα δεν θα χρεωθεί μέχρι το {0}.",
    notChargedUntilTrialComplete: "Η πιστωτική σας κάρτα δεν χρεώνεται μέχρι το τέλος της δοκιμαστικής περιόδου.",
    numberOfTeams: "Αριθμός ομάδων",
    off: "Μακριά από",
    ok: "Εντάξει",
    on: "Επί",
    oneRemainingCredit: "Έχετε 1 υπόλοιπο πίστωσης αυτό το μήνα.",
    options: "Επιλογές",
    or: "ή",
    page: "Σελίδα",
    pageNotFound: "Η σελίδα δεν βρέθηκε",
    password: "Κωδικός πρόσβασης",
    passwordLengthError: "Ο κωδικός πρόσβασης πρέπει να είναι τουλάχιστον 6 χαρακτήρες.",
    passwordRequiredError: "Απαιτείται κωδικός",
    passwordResetEmailHasBeenSendMessage:
        "Ελέγξτε το μήνυμα ηλεκτρονικού ταχυδρομείου σας για έναν σύνδεσμο για να επαναφέρετε τον κωδικό πρόσβασης.",
    pay: "Πληρωμή",
    payBalance: "Πληρωμή υπολοίπου",
    paymentMethod: "Μέθοδος πληρωμής",
    pendingSubscription: "Εκκρεμεί η εγγραφή",
    play: "Παίζω",
    playDescription: "Συναρπαστικά παιχνίδια για μεγάλες και μικρές τάξεις",
    pleaseAddCardForRestart: "Πρέπει να προσθέσετε μια πιστωτική κάρτα πριν την επανεκκίνηση της συνδρομής σας.",
    portuguese: "Πορτογαλικά",
    practice: "Πρακτική",
    practiceDescription: "Διαδραστικά και εκτυπώσιμα φύλλα εργασίας",
    premium: "Ασφάλιστρο",
    premiumPriceFormatMonth: "$ {0} / μήνα",
    premiumPriceFormatYear: "$ {0} / έτος",
    premiumTrial: "Premium (δοκιμή)",
    preview: "Προεπισκόπηση",
    price: "Τιμή",
    print: "Τυπώνω",
    printDescription: "Εκτύπωση καρτών flash ή φύλλων εργασίας για πρακτική εκτός σύνδεσης",
    printable: "Εκτυπώσιμος",
    printableFlashcards: "Εκτύπωση Flashcards",
    private: "Ιδιωτικός",
    problemSubmittingContactForm: "Παρουσιάστηκε πρόβλημα κατά την αποστολή του μηνύματός σας.",
    problemUpdatingUserErrorMessage: "Παρουσιάστηκε σφάλμα στην ενημέρωση των λεπτομερειών του χρήστη.",
    problemUsingCredit: "Παρουσιάστηκε πρόβλημα με τη χρήση των πιστώσεών σας.",
    profile: "Προφίλ",
    proratedAmountDue: "Ποσό που οφείλεται τώρα: {0}",
    public: "Δημόσιο",
    publicLists: "Κοινοτικές λίστες",
    quellMessage: "Να μην εμφανιστεί ξανά αυτό το μήνυμα",
    question: "Ερώτηση",
    questions: "Ερωτήσεις",
    quiz: "Κουίζ",
    randomize: "Τυχαία",
    randomizeAndReset: "Τυχαία και επαναφορά",
    recent: "Πρόσφατος",
    register: "κανω ΕΓΓΡΑΦΗ",
    registerUsernamePassword: "Εγγραφείτε με όνομα χρήστη / κωδικό πρόσβασης",
    rejectedFileFormat:
        "Το αρχείο {0} δεν φορτώθηκε. Το αρχείο μπορεί να ήταν υπερβολικά μεγάλο ή το αρχείο λάθος τύπο αρχείου. Επιτρέπονται μόνο αρχεία εικόνας 500 KB ή μικρότερα.",
    rejectedFilesFormat:
        "Τα αρχεία {0} δεν φορτώθηκαν. Τα αρχεία μπορεί να ήταν υπερβολικά μεγάλα ή τα αρχεία λάθος τύπο αρχείου. Επιτρέπονται μόνο αρχεία εικόνας 500 KB ή μικρότερα.",
    remainingCreditsFormat: "Έχετε υπόλοιπα {0} αυτό το μήνα.",
    removeAllLetters: "Αφαιρέστε όλα τα γράμματα",
    removeFromFavorites: "Κατάργηση από τα Αγαπημένα",
    removeManagedUserAccountConfirmation:
        "Αυτός ο λογαριασμός θα καταργηθεί και ο χρήστης δεν θα είναι πλέον επιτρέπεται η σύνδεση. Είστε βέβαιοι ότι θέλετε να καταργήσετε οριστικά αυτόν τον λογαριασμό;",
    resendEmail: "Ξαναστείλτε ηλεκτρονικό μήνυμα",
    reset: "Επαναφορά",
    restartSubscription: "Κάντε επανεκκίνηση της συνδρομής",
    restartSubscriptionMessage: "Επιλέξτε μια συχνότητα χρέωσης για τη συνδρομή σας.",
    review: "Ανασκόπηση",
    reviewDescription: "Πολλαπλές κουίζ επιλογής για ακρόαση, ανάγνωση, γραφή και ομιλία",
    russian: "Ρωσική",
    sameAsTextField1Label: "Το ίδιο με το πεδίο κειμένου 1",
    sampleClass: "Δείγμα κλάσης",
    save: "Αποθηκεύσετε",
    search: "Ψάξιμο",
    select: "Επιλέγω...",
    selectACategory: "Επιλέξτε μία κατηγορία",
    selectCategoryBeforeActivity: "Πρέπει να επιλέξετε μια κατηγορία πριν επιλέξετε μια δραστηριότητα.",
    selectFolderToAddFavoriteTo: "Επιλέξτε φάκελο για να προσθέσετε αγαπημένα στο:",
    selectStudentList: "Επιλέξτε μια λίστα σπουδαστών",
    showCategoryDetails: "Δείξε λεπτομέρειες",
    signIn: "Σύνδεση",
    signInRegister: "Σύνδεση / Εγγραφή",
    signInUsernamePassword: "Σύνδεση με όνομα χρήστη / κωδικό πρόσβασης",
    signUpForFree: "ΕΓΓΡΑΦΕΙΤΕ ΔΩΡΕΑΝ",
    signUpForFreeMessage: "Εγγραφείτε τώρα για έναν δωρεάν λογαριασμό Premium για 30 ημέρες!",
    singleTeacher: "Ενιαίος δάσκαλος",
    singleText: "Ένα πεδίο κειμένου",
    singleTextDescription:
        "Οι κατάλογοι αυτοί είναι εξαιρετικοί για την πρακτική ανάγνωσης και ορθογραφίας. Είναι επίσης χρήσιμο για δραστηριότητες που δεν απαιτούν εικόνα.",
    siteImages: "Εικόνες ιστότοπου",
    siteLists: "Λίστες ιστοτόπων",
    sortByPopularity: "Δημοτικότητα",
    sortByRecentlyCreated: "Δημιουργήθηκε πρόσφατα",
    sortByRelevance: "Συνάφεια",
    sortByTitle: "Τίτλος",
    sortByTypeTitle: "Ταξινόμηση κατά",
    spanish: "Ισπανικά",
    start: "Αρχή",
    startSubscription: "Ξεκινήστε τη συνδρομή",
    startSubscriptionAfterTrial:
        "Ξεκινήστε τη συνδρομή σας για να συνεχίσετε την εγγραφή 'Premium' μετά η δοκιμή έχει ολοκληρωθεί.",
    studentGroup: "ομάδα σπουδαστών",
    studentGroups: "φοιτητικές ομάδες",
    studentList: "Λίστα σπουδαστών",
    subject: "Θέμα",
    submit: "υποβάλλουν",
    subscribe: "Εγγραφείτε",
    subscription: "Συνδρομή",
    subscriptionDescriptionFormat: "Τύπος συνδρομής: {0}",
    subscriptionType: "Τύπος συνδρομής",
    subtotal: "ΜΕΡΙΚΟ ΣΥΝΟΛΟ:",
    support: "Επικοινωνήστε με την υποστήριξη",
    teacher: "δάσκαλος",
    teacherCountFormat: "Αριθμός διδασκόντων: {0}",
    teachers: "καθηγητές",
    teachingTools: "Εργαλεία διδασκαλίας",
    teamNumberFormat: "Ομάδα {0}",
    tenOrMore: "Δέκα ή περισσότερα",
    textField1Label: "(Ερώτηση)",
    textField2Label: "(Απάντηση)",
    textToSpeechSupportFormat: "Τα ηχητικά κλιπ δημιουργούνται αυτόματα χρησιμοποιώντας τεχνολογία κειμένου σε ομιλία",
    thousandsOfHeader: "{{Χιλιάδες}} κατηγοριών για να διαλέξετε",
    thousandsOfText:
        "Δεν θέλετε να δημιουργήσετε τις δικές σας κατηγορίες; Περιηγηθείτε χιλιάδες δημιουργούνται από την κοινότητα KinteractiveLearning. Περισσότερα προστίθενται καθημερινά!",
    threeOrMore: "Τρεις ή περισσότεροι",
    titleCannotBeEmptyInfoMessage: "Ο τίτλος δεν μπορεί να είναι κενός. Εισαγάγετε έναν τίτλο.",
    tools: "Εργαλεία",
    toolsDescription: "Όμιλοι ομάδας και ομάδας, τυχαίος επιλογέας σπουδαστών, χρονόμετρο και πίνακας αποτελεσμάτων",
    totalDue: "Συνολικό οφειλόμενο ποσό:",
    totalManagedAccounts: "Συνολικοί λογαριασμοί:",
    totalStudentsFormat: "{0} φοιτητές",
    turkish: "τούρκικος",
    twoTextOnly: "Δύο πεδία κειμένου",
    twoTextOnlyDescription:
        "Οι λίστες αυτές χρησιμοποιούνται για την αποστολή ερωτήσεων που δεν απαιτούν και την εικόνα, ή για μετάφραση μεταξύ δύο γλωσσών",
    type: "Τύπος",
    unlimited: "Απεριόριστος",
    update: "Εκσυγχρονίζω",
    updateEmailError: "Παρουσιάστηκε σφάλμα στην ενημέρωση της διεύθυνσης email.",
    uploadImages: "Μεταφόρτωση εικόνων",
    uploadImagesDropzoneInstructions: "Καταργήστε τις εικόνες ή κάντε κλικ για να περιηγηθείτε στον υπολογιστή σας.",
    uploadMessage: "Μεταφόρτωση εικόνων {0}.",
    uploadProfileImageError: "Παρουσιάστηκε σφάλμα κατά τη μεταφόρτωση εικόνας προφίλ.",
    useAsVocabulary: "Προσθήκη στη λίστα λεξιλογίου",
    useCredit: "Χρησιμοποιήστε την πίστωση",
    useOtherAnswersAsIncorrect: "Χρησιμοποιήστε σωστές απαντήσεις σε άλλες κάρτες",
    username: "Όνομα χρήστη",
    usernameOptional: "Όνομα χρήστη (προαιρετικό)",
    usernameRequiredError: "Όνομα χρήστη απαιτείται.",
    usernameTaken: "Το όνομα χρήστη χρησιμοποιείται ήδη",
    usernameUnavailable: "Το όνομα χρήστη δεν είναι διαθέσιμο",
    vocabulary: "Λεξιλόγιο",
    worksheetCreators: "Δημιουργοί φύλλου εργασίας",
    worksheets: "Φύλλα εργασίας",
    yes: "Ναί",
    getStarted: "",
    requiresFlash: "",
    onlySupportsLatinBasedLanguages: "",
    upgradeToPremium: "",
    startReview: "",
    numberOfGroups: "",
    hideAnswer: "",
    autoPlayAudio: "",
    imageBank: "",
    doubleSidedCards: "",
    ignoreFlippedCardsForReview: "",
    soft: "",
    medium: "",
    strong: "",
    continuePlaying: "",
    languageNotSupported: "",
    createLink: "",
    pinActivityToDashboard: "",
    enterName: "",
    createStudentLink: "",
    expiration: "",
    oneDay: "",
    oneWeek: "",
    never: "",
    link: "",
    copyToClipboard: "",
    pinnedActivities: "",
    studentActivityLinks: "",
    numberOfCards: "",
    matchType: "",
    imageType: "",
    otherOptions: "",
    wordImage: "",
    imageImage: "",
    wordWord: "",
    activity: "",
    seeLinkVisitors: "",
    recentHistory: "",
    startTime: "",
    completedTime: "",
    results: "",
    iDontKnow: "",
    iKnowTheAnswer: "",
    seconds: "",
    minutes: "",
    hours: "",
    pause: "",
    set: "",
    audioHeading: "",
    playAudioOnCorrect: "",
    playAudioOnWrong: "",
    answerTypeHeading: "",
    multipleChoiceAnswerTypeOption: "",
    rightOrWrongAnswerTypeOption: "",
    showNumbersHeading: "",
    showNumbersMultipleChoice: "",
    timerHeading: "",
    showTimerMultipleChoice: "",
    game: "",
    correct: "",
    wrong: "",
    teams: "",
    imagesInGrid: "",
    multipleChoiceOptions: "",
    playAudioOnSelection: "",
    classicModeOptions: "",
    selectAll: "",
    flipCardsToFront: "",
    flipCardsToBack: "",
    shuffle: "",
    showAllCards: "",
    bonusRound: "",
    crazyCupsIntroMessage: "",
    crazyCupsBonusRoundMessage: "",
    goodJob: "",
    sorryYouMissedIt: "",
    teamReadyFormat: "",
    readyToTryAgain: "",
    cupsSpeedAdjustment: "",
    textOnly: "",
    imageText: "",
    imageOnly: "",
    hideShips: "",
    useClassicMode: "",
    classicModeDescription: "",
    stop: "",
    bullsEye: "",
    removeNameOnceSelected: "",
    clearDartAfterThrow: "",
    stopBoardAfterThrow: "",
    playSoundEffect: "",
    spinForPoints: "",
    showImageProfileImage: "",
    cardType: "",
    gridSize: "",
    cardsPerPage: "",
    noListsGetStarted: "",
    noImagesGetStarted: "",
    isTraceable: "",
    twoColumns: "",
    randomizeWorksheet: "",
    createPDF: "",
    fontSize: "",
    goodJobReadyForNext: "",
    whoGoesFirst: "",
    teamGoesFirstFormat: "",
    popBalloonsWith: "",
    dartMeterSpeed: "",
    fontStyle: "",
    cardSensitivity: "",
    points: "",
    hotOrColdInstructions: "",
    inviteUsers: "",
    availableInvitesFormat: "",
    numberOfAccountsToAdd: "",
    removeSubscriptionManagerConfirmation: "",
    managedSubscription: "",
    disconnectFromAccountManager: "",
    confirmToBeManagedConfirmationFormat: "",
    pendingManagerNotFound: "",
    steal: "",
    newWord: "",
    canYouSpell: "",
    wouldLikeToStealFormat: "",
    selectSecondCategory: "",
    clearSecondCategory: "",
    borderThickness: "",
    borderColor: "",
    subscriptionIsPastDue: "",
};
