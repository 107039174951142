import * as React from "react";

// Stores
import { ActivityStore } from "stores";

// Layout
import { Observer } from "mobx-react-lite";
import { CardTypeButtons } from "components/activities/CommonActivityOptions";
import { CardType } from "models/customLists/CategoryItem";
import { WorksheetOptions } from "../WorksheetScene";

export interface LaddersSlidesWorksheetOptions extends WorksheetOptions {
    cardType: CardType;
}

export const defaultLaddersSlidesWorksheetOptions: LaddersSlidesWorksheetOptions = {
    cardType: CardType.IMAGE_ONLY,
};

interface LaddersSlidesWorksheetOptionsProps extends React.Props<any> {
    activityStore: ActivityStore;
}

const LaddersSlidesWorksheetOptions = (props: LaddersSlidesWorksheetOptionsProps) => {
    const handleCardTypeChanged = (cardType: CardType) => {
        props.activityStore.activityOptions.laddersSlidesWorksheetOptions.cardType = cardType;
        props.activityStore.updateOptions();
    };

    return (
        <Observer
            render={() => (
                <>
                    <CardTypeButtons
                        activityStore={props.activityStore}
                        onCardTypeChanged={handleCardTypeChanged}
                        selectedType={props.activityStore.activityOptions.laddersSlidesWorksheetOptions.cardType}
                    />
                </>
            )}
        />
    );
};

export default LaddersSlidesWorksheetOptions;
